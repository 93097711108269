import { useState } from "react";

import Loader from "../../../Components/Atoms/Loader/Loader.js";
import FreelancersListTemplate from "../../../Templates/Main/Freelancers/FreelancersList.Template.jsx";
import { getFreelancersList } from "../../../Contexts/APIs/Freelancers/Freelancers.js";
import { toast } from "react-toastify";

const FreelancersListPage = ({ freelancersPrivilages, roles }) => {
  const [count, setCount] = useState();
  const [openLoader, setOpenLoader] = useState(false);
  const [freelancers, setFreelancers] = useState([]);

  const getFreelancers = async (filter) => {
    setOpenLoader(true);
    const { res, err } = await getFreelancersList(filter);
    if (res) {
      setCount(res?.extra?.total_count);
      setFreelancers(res?.data?.dive_experts);
    }
    if (err) toast.error(err);
    setOpenLoader(false);
  };

  return (
    <>
      <Loader open={openLoader} />
      <FreelancersListTemplate
        count={count}
        freelancers={freelancers}
        getFreelancers={getFreelancers}
        freelancersPrivilages={freelancersPrivilages}
        roles={roles}
        setList={setFreelancers}
      />
    </>
  );
};

export default FreelancersListPage;

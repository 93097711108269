import { useState, useEffect } from "react";
import FreelancersHeaders from "../../Molecules/Freelancers/FreelancersHeaders";
import ListFreelancers from "../../Molecules/Freelancers/Lists/ListFreelancers";
import "./Freelancers.css";
import { useSearchParams } from "react-router-dom";
import usePageFilter from "../../Atoms/Hooks/usePageFilter";
import { handlePageChange } from "../../../Contexts/APIs/Helper/Helper";

const FreelancersList = ({
  count,
  freelancers,
  getFreelancers,
  freelancersPrivilages,
  roles,
  setList,
}) => {
  const {
    page,
    filterObj,
    handlePageChange: onPageChange,
    setFilterObj,
    setPage,
  } = usePageFilter();

  const handleChange = (name, value) => {
    onPageChange && handlePageChange(1, onPageChange);
    setFilterObj({
      ...filterObj,
      [name]: name == "keyword" ? value.trim() : value,
    });
  };
  useEffect(() => {
    const timeId = setTimeout(() => {
      getFreelancers(filterObj);
    }, 1000);
    return () => clearTimeout(timeId);
  }, [filterObj]);

  return (
    <>
      <FreelancersHeaders
        handleChange={handleChange}
        filterObj={filterObj}
        setFilterObj={setFilterObj}
        setPage={setPage}
        handlePaginationPageChange={onPageChange}
      />
      <ListFreelancers
        count={count}
        freelancers={freelancers}
        handleChange={onPageChange}
        freelancersPrivilages={freelancersPrivilages}
        filterObj={filterObj}
        setFilterObj={setFilterObj}
        page={page}
        setPage={setPage}
        handlePageChange={onPageChange}
        getFreelancers={getFreelancers}
        roles={roles}
        setList={setList}
      />
    </>
  );
};

export default FreelancersList;

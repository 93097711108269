
import React from 'react'
import Loader from '../../../Components/Atoms/Loader/Loader';
import { toast } from 'react-toastify';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import JobOfferDetailsTemplate from '../../../Templates/Main/JobOffers/JobOfferDetails.template';
import { getJobOfferDetails } from '../../../Contexts/APIs/JobOffers/JobOffers';
import { getSupportedLanguages } from '../../../Contexts/APIs/Lookups/SupportedLanguages';

function JobOfferDetailsPage({roles}) {
    const { jobOfferId } = useParams();
    const [jobOfferData, setJobOfferData] = useState({});
    const [openLoader, setOpenLoader] = useState(false);
    const [supportedLanguages, setSupportedLanguages] = useState([]);
    const [supportLang, setSupportLang] = useState("en");

    const getJobOffer = async (id) => {
        setOpenLoader(true);
        const { res, err } = await getJobOfferDetails(id);
        if (res) { setJobOfferData(res?.data?.job_offer); }
        if (err) toast.error(err)
        setOpenLoader(false);
    };

    const getLanguages = async () => {
      const { res, err } = await getSupportedLanguages();
      if (res) {
        setSupportedLanguages(res?.data?.supported_locales);
      }
    };
    const updateSupportedLang = (e, languages) => {
      setSupportLang(e.target.value);
    };

    useEffect(() => {
        getJobOffer(jobOfferId);
        getLanguages();
    }, []);

  return (
    <>
        <Loader open={openLoader} />
        <JobOfferDetailsTemplate
          jobOfferData={jobOfferData}
        //   getJobOffer={getJobOffer}
          supportedLanguages={supportedLanguages}
          updateSupportedLang={updateSupportedLang}
          supportLang={supportLang}
          roles={roles}
        />
    </>
  )
}

export default JobOfferDetailsPage
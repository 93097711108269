import { axiosFn, handleError } from "..";
import { makeFilterString } from "../Helper/Helper";
import * as URL from "../URLs";

export async function getCountryList(filter_obj) {
  return await axiosFn("get", URL.Country + makeFilterString(filter_obj))
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}

export async function createCountry({ data, action }) {
  return await axiosFn("post", URL.Country, data)
    .then((res) => {
      action && action();

      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}

export async function getCountryDetails(organization_id) {
  return await axiosFn("get", `${URL.Country}/${organization_id}`)
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}

export async function deleteCountry(id, action) {
  return await axiosFn("delete", `${URL.Country}/${id}`)
    .then((res) => {
      action && action(res?.data);
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}

export async function updateCountry({ id, data, action }) {
  return await axiosFn("put", `${URL.Country}/${id}`, data)
    .then((res) => {
      action && action();
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}

import { Grid } from '@mui/material';
import React from 'react'
import { useNavigate } from 'react-router-dom';
import CustomPagination from '../../Atoms/Pagination/Pagination';
import './ActivityOffersList.css'

function ActivityOffersList(props) {
    const { activityOffersList, count, page, handlePageChange, getActivityOffers, filterObj, setPage ,setFilterObj} = props;
  return (
    <Grid container>
        <Grid container className="table-header activity-header mb-20px">
            <Grid item md={0.75} textAlign="center">ID</Grid>
            <Grid item sm={1.5}>Dive activity  name</Grid>
            <Grid item sm={1.45}>Offer type</Grid>
            <Grid item sm={1.45}>Dive center</Grid>
            <Grid item sm={1.45}>Branch</Grid>
            <Grid item sm={1.3}>Discount %</Grid>
            <Grid item sm={1.3}>Start date</Grid>
            <Grid item sm={1.3}>End date</Grid>
            <Grid item sm={1.3}>Status</Grid>            
        </Grid>
  
        {activityOffersList?.length === 0 && (
            <Grid container display="block" textAlign="center">
                There are no activity offers
            </Grid>
        )}
  
        {
          activityOffersList?.length >= 1 && <>
              {
                  activityOffersList?.map((item, index) => {
                      return (
                          <Grid
                              key={index}
                              container item
                              lg={12}
                              className="activity-offer-item mb-20px"
                          >
                              <Grid item md={0.75} className="activity-offer-item-no" textAlign="center">{item?.id}</Grid>                            
                              <Grid item sm={1.5} className="activity-offer-item-name">{item?.dive_activity?.name}</Grid>
                              <Grid item sm={1.45}>{item?.offer_type?.replaceAll('_', ' ')}</Grid>
                              <Grid item sm={1.45}>{item?.dive_activity?.dive_center?.name}</Grid>
                              <Grid item sm={1.45}>{item?.dive_activity?.branch?.name}</Grid>
                              <Grid item sm={1.3}>{item?.discount_percentage?.toFixed(1)}</Grid>
                              <Grid item sm={1.3}>{item?.start_date?.split('-').reverse().join('-')}</Grid>
                              <Grid item sm={1.3}>{item?.end_date?.split('-').reverse().join('-')}</Grid>
                              <Grid item sm={1.3}>{item?.status}</Grid>  
                          </Grid>
                      )
                  })
              }
              {count > 10 &&
                  <Grid container item justifyContent={'center'} xs={12} mb={7.5} mt={5}>
                      <CustomPagination
                          count={Math.ceil(count / 10)}
                          size='large'
                          shape="rounded"
                          page={page}
                          handleChange={handlePageChange}
                          className='pagination'
                      />
                  </Grid>
              }
          </>
        }       
    </Grid>
  )
}

export default ActivityOffersList
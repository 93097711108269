import { Box, Checkbox, FormControlLabel } from "@mui/material";
import React from "react";
import CFormErrors from "./CFormErrors";

const CCheckbox = ({ label, name, formik, changeAction }) => {
  const errorsMsg = formik.errors[name];
  const value = formik.values[name];
  console.log(value);
  return (
    <Box mb="5">
      <FormControlLabel
        control={
          <Checkbox
            // lineHeight="1.25"            
            // alignItems="flex-start"
            fontWeight="normal"
            size="md"
            name={name}
            checked={value ? value : false}
            onBlur={formik.handleBlur}
            onChange={(e) => changeAction(e.target.checked)}
          />
        }
        label={label}
      />
      <CFormErrors errorsMsg={errorsMsg} />
    </Box>
  );
};

export default CCheckbox;

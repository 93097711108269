import {
  Box,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import "../../../../Components/Atoms/Shared/Styles/details-page.css";
import CustomSeparator from "../../../../Components/Atoms/BreadCrumb";
import FormHead from "../../../../Components/Atoms/FormHead/FormHead";
import LanguagesSelect from "../../../../Components/Molecules/Forms/LanguagesSelect";
import OrganizationDetailsHeader from "../../../../Components/Molecules/Organizations/OrganizationDetailsHeader";
import { deleteFeatureOption } from "../../../../Contexts/APIs/FeatureOptions/FeatureOptions";
import moment from "moment";
import DeleteModal from "../../../../Components/Molecules/General/Models/DeleteModal";
import { icons } from "../../../../Assets/AssetHelper";
import "./styles.css";
function DocumentDetailsTemplate({
  data,
  supportLang,
  supportedLanguages,
  updateSupportedLang,
  openDeleteModal,
  setOpenDeleteModal,
  roles
}) {
  const navigate = useNavigate();
  let [searchParams] = useSearchParams();

  const id = searchParams.get("id");
  const index = searchParams.get("index");
  const breadcrumbs = [
    {
      path: "/documents",
      active: true,
      title: "Documents",
    },

    {
      active: false,
      title: "Document details",
    },
  ];
  return (
    <Stack spacing={3}>
      <Grid container item sm={12}>
        <CustomSeparator breadcrumbs={breadcrumbs} />
      </Grid>

      <Stack spacing={2}>
        <Stack
          alignItems="center"
          justifyContent="space-between"
          flexDirection="row"
        >
          <Grid container item sm={12} className="select-lang-wrap">
            <LanguagesSelect
              value={supportLang}
              supportedLanguages={supportedLanguages}
              onChange={(e) => updateSupportedLang(e, supportedLanguages)}
              LanguagesSelectHead={"Data Language"}
              LanguagesSelectTip={false}
            />
          </Grid>

          {
            ((roles?.can_read && roles?.can_update) || !roles) &&
            <IconButton
              onClick={() =>
                navigate(`/documents?type=form&id=${data?.id}&index=${index}`)
              }
            >
              <img width="35" src={icons.EditIcon} alt="edit" />
            </IconButton>
          }
        </Stack>

        <Stack
          mt="-25px"
          bgcoloe="white"
          className="details-panel"
          flexDirection="row"
          justifyContent="space-between"
          gap="1rem"
          boxShadow="0 2px 15px 0 rgba(0, 0, 0, 0.1)"
          overflow="auto"
          position="relative"
          borderRadius="8px"
        >
          <Stack gap={2} width="100%">
            <Stack px="53.8px" py="20px">
              <Typography fontSize="30px" fontWeight="bold">
                "{data?.name}"
              </Typography>
              <Typography fontSize="20px" fontWeight="bold">
                ID number {data?.id}
              </Typography>
            </Stack>

            <Divider
              style={{ background: "#d9d9d9", width: "100%", padding: 0 }}
            />

            <Stack alignItems="center" justifyContent="center">
              <Box
                position="relative"
                bgcolor="#fbfbfb"
                borderRadius="20px"
                px="20px"
                py="20px"
                width="85%"
              >
                <Grid container gap={1}>
                  <Grid item xs={8}>
                    <FormHead text={"Document details"} />
                    {/*documentDetailsQuill : used to remove default margins for html elements return from react quill editor */}
                    <Typography color="#333132" className="details-text-bold documentDetailsQuill">
                      <div
                        dangerouslySetInnerHTML={{ __html: data?.content }}
                      ></div>
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Stack>

            <Stack
              flexDirection="row"
              gap="1rem 3rem"
              flexWrap="wrap"
              px="53.8px"
              py="20px"
            >
              <Stack flexDirection="row" gap={1}>
                <Typography color="#333132" className="details-text-bold">
                  Created at :
                  {moment(data?.created_at).format("DD-MM-YYYY  HH:mm")}
                </Typography>
              </Stack>
              <Stack flexDirection="row" gap={1}>
                <Typography color="#333132" className="details-text-bold">
                  Updated at :
                  {moment(data?.updated_at).format("DD-MM-YYYY  HH:mm")}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Stack>

      <DeleteModal
        open={openDeleteModal}
        setOpen={setOpenDeleteModal}
        handelDeleteRequest={() => {
          deleteFeatureOption(id);
        }}
        handleCancelDelete={() => setOpenDeleteModal(false)}
        customClass="organizations-delete-modal"
        text={[
          `Delete ${data?.name}`,
          `Are you sure that you want to delete ${data?.name} ?`,
        ]}
      />
    </Stack>
  );
}

export default DocumentDetailsTemplate;

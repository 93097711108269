export const user_type = [
  {
    id: "all",
    name: "All",
  },
  {
    id: "customer",
    name: "Customer",
  },
  {
    id: "dive_club",
    name: "Dive Club",
  },
  {
    id: "dive_center",
    name: "Dive Center",
  },
  {
    id: "freelancer",
    name: "Freelancer",
  },
];

export const point_type = [
  {
    id: "system",
    name: "System",
  },
  {
    id: "customer_signup",
    name: "Customer signup",
  },
  {
    id: "customer_signup_invitaion",
    name: "Customer signup invitaion",
  },
  {
    id: "freelancer",
    name: "Freelancer",
  },
  {
    id: "customer_reservation",
    name: "Customer reservation",
  },
  {
    id: "dive_club_signup",
    name: "Dive club signup",
  },
  {
    id: "dive_club_signup_invitation",
    name: "Dive club signup invitation",
  },
  {
    id: "dive_club_reservation",
    name: "Dive club reservation",
  },
  {
    id: "dive_center_client_reservation",
    name: "Dive center client reservation",
  },
  {
    id: "dive_center_freelancer_work",
    name: "Dive center freelancer work",
  },
  {
    id: "freelancer_signup",
    name: "Freelancer signup",
  },
  {
    id: "freelancer_work_day",
    name: "Freelancer work day",
  },
  {
    id: "freelancer_signup_invitation",
    name: "Freelancer signup invitation",
  },
  {
    id: "freelancer_pro_team_invitation",
    name: "Freelancer pro team invitation",
  },
  {
    id: "freelancer_team_member_work",
    name: "Freelancer team member work",
  },
  {
    id: "freelancer_collective_team_work",
    name: "Freelancer collective team work",
  },
  {
    id: "freelancer_cancel_job_offer",
    name: "Freelancer cancel job offer",
  },
  {
    id: "redeem_order_points",
    name: "Redeem order points",
  },
];

export const action_type = [
  {
    id: "add",
    name: "Add",
  },
  {
    id: "subtract",
    name: "Subtract",
  },
];

export const per_type = [
  {
    id: "day",
    name: "Day",
  },
  {
    id: "usd",
    name: "Usd",
  },
  {
    id: "user",
    name: "User",
  },
];

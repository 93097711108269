import { Grid } from '@mui/material'
import React from 'react'
import CustomPagination from '../../../Atoms/Pagination/Pagination';
import './DiveClubsList.css';
import ActionsMenu from '../ActionsMenu/ActionsMenu';
import { updateDiveClub } from '../../../../Contexts/APIs/DiveClubs/DiveClubs';
import PhoneInput from 'react-phone-input-2'

function DiveClubsList(props) {
    const { diveClubsList, count, page, handlePageChange, getDiveClubs,setDiveClubsList, filterObj, roles } = props;
    return (
      <Grid container>
        <Grid container className="table-header dive-clubs-header mb-20px">
            <Grid item md={1} textAlign="center">ID</Grid>
            <Grid item sm={2}>Name</Grid>
            <Grid item sm={2.5}>Email</Grid>
            <Grid item sm={2}>Phone number</Grid>
            <Grid item sm={1.5}>Status</Grid>
            <Grid item sm={1.5}>Is verified</Grid>
            {
              !roles?.can_read && !roles?.can_update 
              ? null
              : <Grid item sm={1.5} sx={{ pl: 2 }}>Action</Grid>
            }
        </Grid>
  
        {diveClubsList?.length === 0 && (
            <Grid container display="block" textAlign="center">
                There are no dive clubs
            </Grid>
        )}
  
        {
          diveClubsList?.length >= 1 && <>
              {
                  diveClubsList?.map((item, index) => {
                    let countryCode = item.country_code;
                      return (
                            <Grid
                                key={index}
                                container item
                                lg={12}
                                className="dive-club-item mb-20px"
                            >
                              <Grid item md={1} className="dive-club-item-no" textAlign="center">{item?.id}</Grid>                            
                              <Grid item sm={2} className="dive-club-item-name">{item?.name}</Grid>
                              <Grid item sm={2.5}>{item?.email}</Grid>
                              <Grid item sm={2}>{ 
                                item?.phone_number ? <PhoneInput
                                  inputStyle={{
                                    border: "none",
                                    width: "100%",
                                    padding: "0px",
                                    direction: "ltr",
                                    fontSize: '18px',                      
                                    color: '#333132',
                                    backgroundColor: 'white',
                                    wordBreak: 'break-word',
                                    fontWeight: '500'
                                  }}
                                  defaultMask={`${countryCode === "" ? '...........' : '... ... ....'}`}
                                  value={`${countryCode === "" ? '  ' : item.country_code}${item.phone_number}`}
                                  disabled={true}
                                  disableDropdown={true}
                                  specialLabel=""
                                  placeholder=""
                                  disableCountryCode={countryCode === "" ? true : false}
                                /> : null
                              }</Grid>                              
                              <Grid item sm={1.5} sx={{textTransform: 'capitalize', color: '#333132', display: 'flex', flexDirection: 'column'}}>
                                { item?.status === 'pending_approval' ? 'pending approval'
                                    : item?.status
                                }
                              </Grid>
                              <Grid item sm={1.5}>
                                <div className={`${item?.is_verified ? 'dive-club-verified' : 'dive-club-not-verified'}`}>{item?.is_verified ? 'Yes' : 'No'}</div>
                              </Grid>
  
                              {
                                (!roles?.can_read && !roles?.can_update && !roles?.can_delete) 
                                ? null
                                : <Grid item sm={1.5} className='dive-club-menu-button-wrap'>
                                  {
                                    item?.status !== 'deleted' &&
                                      <ActionsMenu
                                        user={item} 
                                        statusAttribute={'status'}
                                        userPath={'dive-clubs'}
                                        userPathLength={10}
                                        handleUpdate={ updateDiveClub }
                                        getUsers={getDiveClubs}
                                        filterObj={filterObj}
                                        roles={roles}
                                        setList={setDiveClubsList}
                                      />
                                  }
                                </Grid>
                              }
                            </Grid>
                      )
                  })
              }
              {count > 10 &&
                  <Grid container item justifyContent={'center'} xs={12} mb={7.5} mt={5}>
                      <CustomPagination
                          count={Math.ceil(count / 10)}
                          size='large'
                          shape="rounded"
                          page={page}
                          handleChange={handlePageChange}
                          className='pagination'
                      />
                  </Grid>
              }
          </>
        }
    </Grid>
    )
}

export default DiveClubsList
import {
  Breadcrumbs,
  ButtonGroup,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import PrimaryButton from "../../Atoms/Buttons/Primary/PrimaryButton";
import TextFieldInput from "../../Atoms/Input/TextField/TextFieldInput";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";

import "./PagesHead.css";
import Filter from "../General/Crud/filter";
import { useSearchParams } from "react-router-dom";
import { handlePageChange } from "../../../Contexts/APIs/Helper/Helper";

const PagesHead = ({
  title,
  searchInput,
  primaryButtons,
  breadCrumb,
  handelSubmit,
  filterObj,
  setFilterObj,
  filter,
  handleChange,
}) => {
  const [searchKeywords, setSearchKeywords] = useState("");
  const [searchParams] = useSearchParams();
  const pageNumber = searchParams.get("page_number") || 1;

  const getKeywords = (keyword, type) => {
    setSearchKeywords({ keyword, type });
    setFilterObj({
      ...filterObj,
      page_number: keyword?.length == 0 ? pageNumber : 1,
      keyword: keyword.trim(),
    });
  };

  return (
    <Grid
      container
      item
      lg={12}
      style={{ borderBottom: "1px solid #d9d9d9" }}
      sx={{ pb: 1, mb: 2 }}
      className="user-pages-head"
    >
      {breadCrumb && (
        <Grid item sm={12}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link color="inherit" href={breadCrumb.path}>
              {breadCrumb.link}
            </Link>
            <Typography color="text.primary">{breadCrumb.text}</Typography>
          </Breadcrumbs>
        </Grid>
      )}
      <Grid container item lg={4}>
        <Grid item lg={6}>
          <Typography
            component="h1"
            style={{
              fontSize: "30px",
              fontWeight: "bold",
              textAlign: "left",
              color: "#333132",
            }}
          >
            {title}
          </Typography>
        </Grid>
        <Grid item lg={6}>
          {searchInput && (
            <TextFieldInput
              type={searchInput.type}
              onChange={(e) => {
                handleChange && handlePageChange(1, handleChange);
                getKeywords(e.target.value, e?.type);
              }}
              placeholder={searchInput.placeholder}
              StartAdornment={<SearchIcon />}
              className={searchInput.className}
              value={searchKeywords?.keyword}
            />
          )}
        </Grid>
      </Grid>

      <Grid
        container
        item
        lg={7}
        flexDirection="row"
        gap={0.5}
        justifyContent="end"
      >
        {filter && (
          <Grid item lg={2}>
            <Filter {...filter} handleChange={handleChange} />
          </Grid>
        )}
        {primaryButtons.map((btn, index) => {
          return (
            <PrimaryButton
              key={index}
              fontSize={btn.fontSize}
              startIcon={btn.startIcon && <AddIcon />}
              text={btn.text}
              classBtn={btn.classBtn}
              style={btn.style}
              click={() => {
                btn.click && btn.click();
                btn.btnType === "submit" && handelSubmit();
              }}
            />
          );
        })}
      </Grid>
    </Grid>
  );
};

export default PagesHead;

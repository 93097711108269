import { useEffect, useId } from "react";
import { useState } from "react";
import MainTemplate from "../../Templates/Main/Main.template.jsx";
import { toast } from "react-toastify";
import { getProfileDetails } from "../../Contexts/APIs/Profile/Profile.js";
import { ShowUser, checkToken } from "../../Contexts/APIs/Auth/Login.js";
import { getPermissionList } from "../../Contexts/APIs/Users/Roles.js";
import Loader from "../../Components/Atoms/Loader/Loader.js";
import { Firebase, auth } from "../../Firebase/Firebase.js";
// import { CreateAuthToken } from "../../Contexts/APIs/Auth/CreateAuthToken.js";
import { SetDeviceFCMAPI } from "../../Contexts/APIs/Auth/SetDeviceFCM.js";
// import dayjs from "dayjs";
// import { date } from "yup";
const Main = (props) => {
  const { t } = props;
  const [data, setData] = useState({});
  const [privilages, setPrivilages] = useState([]);
  const userType = localStorage.getItem("userType");
  const userId = localStorage.getItem("userId");
  const [openLoader, setOpenLoader] = useState(true);

  const token = localStorage.getItem("Token");
  const fcm_token = localStorage.getItem("fcm_token");
  const id = useId();

  const profileDetails = async () => {
    const { res, err } = await getProfileDetails(userId);
    if (res) {
      setData(res?.data.user);
      // Firebase.getFCMToken();
    }
  };

  const getDescendentDetails = async () => {
    const { res, err } = await ShowUser(userId);
    if (res) {
      setData(res?.data.descendant_user);
      setPrivilages(res?.data.descendant_user?.permissions);
      localStorage.setItem(
        "roles",
        JSON?.stringify(res?.data?.descendant_user?.permissions)
      );
    }
    if (err) toast.error(err);
  };

  const handleCheckToken = async () => {
    if (localStorage.getItem("Token")) {
      setOpenLoader(true);
      const { res, err } = await checkToken();
      if (res?.message == "success") {
        setOpenLoader(false);
        if (userType == "descendant_user") {
          getDescendentDetails(userId);
        } else {
          profileDetails();
        }
      }
    }
  };

  const getPermissions = async () => {
    const { res, error } = await getPermissionList({
      for: "all",
      page_size: 50,
    });
    if (res) {
      let rolesData = {};

      res?.data?.resources?.map((privilage) => {
        rolesData[`${privilage?.name}`] = {
          title: privilage.presentation,
          resource_id: privilage.id,
          can_create: true,
          can_read: true,
          can_update: true,
          can_delete: true,
        };
      });

      localStorage.setItem("roles", JSON.stringify(rolesData));
    }
  };

  useEffect(() => {
    let rolesData = {};
    const defaultdata =
      userType === "descendant_user" &&
      JSON.parse(localStorage.getItem("roles"));

    if (userType === "descendant_user") {
      defaultdata?.map((privilage) => {
        const { resource, ...clone } = privilage;
        rolesData[`${privilage?.resource?.name}`] = clone;
      });
      localStorage.setItem("descendant_user_roles", JSON.stringify(rolesData));
    } else {
      getPermissions();
    }
  }, [privilages]);

  useEffect(() => {
    handleCheckToken();
  }, []);

  // const createFirebaseAuthToken = async () => {
  //   const { res, err } = await CreateAuthToken();
  //   if (res) {
  //     localStorage.setItem("firebase_auth_token", res?.data?.firebase_auth_token);
  //     await Firebase.SignInFirebase();
  //   }
  // };

  // fcm
  const getFCMToken = async () => {
    const updateNewFCMToken = (newFCMToken) => {
      const new_fcm_token = newFCMToken || id;
      SetDeviceFCMAPI({
        device: {
          fcm_token: new_fcm_token,
        },
      });
      localStorage.setItem("fcm_token", new_fcm_token);
    };

    const newFCMToken = await Firebase.requestForFCMToken();

    if (fcm_token !== newFCMToken) {
      updateNewFCMToken(newFCMToken);
    }
  };

  useEffect(() => {
    if ("Notification" in window) {
      Notification.requestPermission().then((permission) => {
        if (permission === "granted" && !fcm_token && token) {
          getFCMToken();
        } else {
          getFCMToken();
        }
      });
    }
  }, []);

  useEffect(() => {
    const unsubscribe = auth.onIdTokenChanged(async (user) => {
      if (user) {
        const refreshedToken = await user.getIdToken();
        localStorage.setItem("firebase_auth_token", refreshedToken);
      } else {
        Firebase.SignInFirebase();
      }
    });
    return () => unsubscribe();
  }, []);

  return (
    <>
      {openLoader ? (
        <Loader open={openLoader} />
      ) : (
        <MainTemplate
          profileData={data}
          privilages={privilages}
          // rolesData={roles}
        />
      )}
    </>
  );
};

export default Main;

// {
//   activitiesPrivilages
//   offersPrivilages
//   freelancersPrivilages
//   branchesPrivilages
//   joboffersPrivilages
//   ordersPrivilages
//   usersPrivilages

// }

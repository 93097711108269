import * as Yup from "yup";
export const initialValues = (data, lang) => ({
  ...data,
  ...data?.localized_data,
});

export const structure = (lang) => [
  {
    head: "Price include information",
    list: [
      {
        name: `${lang}.name`,
        kind: "input",
        type: "text",
        label: "price include  name",
        width: "100%",
      },
    ],
  },
];

export const validationSchema = () =>
  Yup.object({
    en: Yup.object().shape({
      name: Yup.string().required("required"),
    }),
  });

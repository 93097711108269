import React from 'react'
import SearchIcon from "@mui/icons-material/Search";
import ApplicantsList from '../../Molecules/JobOffers/ApplicantsList/ApplicantsList';
import ApplicantsHeader from '../../Molecules/JobOffers/ApplicantsHeader/ApplicantsHeader';

function Applicants(props) {
    const { filterObj, applicantsList, setApplicants, jobOfferData, count, setCount, page, setPage, handlePageChange, openLoader, setOpenLoader, roles } = props;

    return (
      <>
          <ApplicantsHeader            
              searchInput={{
                  type: "search",
                  placeholder: "Search applicants",
                  StartAdornment: { SearchIcon },
                  className: "Search-applicant"
              }}              
              setPage={setPage}
              data={jobOfferData}              
              setApplicants={setApplicants}
              setCount={setCount}
              openLoader={openLoader}
              setOpenLoader={setOpenLoader}
          />
          <ApplicantsList
              applicantsList={applicantsList}
              count={count} page={page}
              handlePageChange={handlePageChange}
              filterObj={filterObj}
              jobOfferTitle={jobOfferData.title}
              roles={roles}
          />
      </>
  )
}

export default Applicants
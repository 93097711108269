import React, { useEffect, useState } from "react";
import Loader from "../../../../Components/Atoms/Loader/Loader";
import CountryTemplate from "../../../../Templates/Main/Geolocations/Country/Country.template";
import {
  deleteCountry,
  getCountryDetails,
  getCountryList,
} from "../../../../Contexts/APIs/Country/Country";
import usePageFilter from "../../../../Components/Atoms/Hooks/usePageFilter";

function CountryPage({ roles }) {
  // let [searchParams] = useSearchParams();
  const {
    page,
    filterObj,
    handlePageChange: onPageChange,
    setFilterObj,
    setPage,
    searchParams,
  } = usePageFilter();
  const id = searchParams.get("id");

  const [openLoader, setOpenLoader] = useState(false);
  const [countriesList, setCountriesList] = useState([]);

  const [count, setCount] = useState(0);
  // const [page, setPage] = useState(1);

  // const [filterObj, setFilterObj] = useState(null);

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [countryData, setCountryData] = useState({});

  // const updatePagination = (page) => {
  //   setFilterObj({ ...filterObj, page_number: page });
  // };

  // const handlePageChange = (event, value) => {
  //   setPage(value);
  //   updatePagination(value);
  // };

  const getCountry = async (filterObj) => {
    setOpenLoader(true);
    const defaultFilterObj = {
      page_size: 10,
      page_number: 1,
      keyword: "",
      sort_by: "id",
    };
    const { res, error } = await getCountryList({
      ...defaultFilterObj,
      ...filterObj,
    });
    if (res) {
      setOpenLoader(false);
      setCountriesList(res?.data?.countries);
      setCount(res?.extra?.total_count);
    }
    if (error) {
      setOpenLoader(false);
    }
  };
  const getCountryData = async () => {
    setOpenLoader(true);
    const { res, error } = await getCountryDetails(id);
    if (res) {
      setCountryData(res.data.country);
      setOpenLoader(false);
    }
    if (error) {
      setOpenLoader(false);
    }
  };

  const handleDeleteRequest = async () => {
    const action = (data) => {
      setOpenDeleteModal(false);
      console.log(data,'data');
      setCountriesList((prevState) =>
      prevState?.filter((ele) => ele?.id !== data?.data?.country?.id))
    };
    deleteCountry(selectedRow?.id, action);
  };

  useEffect(() => {
    if (filterObj) getCountry(filterObj);
  }, [filterObj]);
  useEffect(() => {
    id && getCountryData({});
  }, [id]);
  const rowData = (data) => {
    setSelectedRow(data?.rowData);
  };
  const breadcrumbs = [
    {
      active: true,
      path: "/geolocations/countries",
      title: "Countries",
    },
    {
      active: false,
      title: id ? "Edit country" : "Add new country",
    },
  ];

  // mappedData use for create custom cell to concat multi values or navigate button
  const mappedData = countriesList?.map((data) => {
    return {
      ...data,
      id_data: <div style={{ width: "100px" }}>{data?.id}</div>,
      lookup_data: (
        <div style={{ width: "200px" }}>{data?.lookup_key || "-"}</div>
      ),
    };
  });
  return (
    <>
      <Loader open={openLoader} />
      <CountryTemplate
        filterObj={filterObj}
        setFilterObj={setFilterObj}
        data={mappedData}
        count={count}
        paginationPage={page}
        setPaginationPage={setPage}
        handlePageChange={onPageChange}
        openDeleteModal={openDeleteModal}
        setOpenDeleteModal={setOpenDeleteModal}
        handleDeleteRequest={handleDeleteRequest}
        rowData={rowData}
        countryData={countryData}
        breadcrumbs={breadcrumbs}
        roles={roles}
      />
    </>
  );
}

export default CountryPage;

import { Breadcrumbs, Grid, Link, Typography } from "@mui/material";
import React, { useState } from "react";
import PrimaryButton from "../../../Atoms/Buttons/Primary/PrimaryButton";
import TextFieldInput from "../../../Atoms/Input/TextField/TextFieldInput";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";

import "./PagesHead.css";
import FilterMenu from "./Menu/FilterMenu";
import { useSearchParams } from "react-router-dom";
import { handlePageChange } from "../../../../Contexts/APIs/Helper/Helper";

const PagesHead = ({
  title,
  subTitle,
  searchInput,
  primaryButtons,
  breadCrumb,
  handelSubmit,
  filterObj,
  setFilterObj,
  filterMenus,
  setPage,
  handleChange,
}) => {
  const [searchKeywords, setSearchKeywords] = useState("");
  const [searchParams] = useSearchParams();
  const pageNumber = searchParams.get("page_number") || 1;

  const getKeywords = (keyword, type) => {
    setSearchKeywords({ keyword, type });
    setFilterObj({
      ...filterObj,
      page_number: keyword?.length == 0 ? pageNumber : 1,
      keyword: encodeURIComponent(keyword.trim()),
    });
    setPage(1);
  };

  return (
    <Grid
      container
      item
      xs={12}
      style={{ borderBottom: "1px solid #d9d9d9" }}
      sx={{ pb: 1, mb: 2 }}
      className="user-pages-head"
    >
      {breadCrumb && (
        <Grid item sm={12}>
          <Breadcrumbs aria-label="breadcrumb" separator="›">
            <Link color="inherit" href={breadCrumb.path}>
              {breadCrumb.link}
            </Link>
            {/* <Typography color="text.primary">{breadCrumb.text}</Typography> */}
            <Typography>{breadCrumb.text}</Typography>
          </Breadcrumbs>
        </Grid>
      )}
      <Grid
        container
        item
        lg={6}
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item lg={subTitle && searchInput ? 3 : "auto"}>
          <Typography
            component="h1"
            style={{
              fontSize: "30px",
              fontWeight: "bold",
              textAlign: "left",
              color: "#333132",
            }}
          >
            {title}
          </Typography>
        </Grid>
        {subTitle && (
          <Grid item lg={2} sx={{ display: "flex", alignItems: "center" }}>
            <Typography
              component="p"
              style={{ fontSize: "18px", textAlign: "left", color: "#bebebe" }}
            >
              {subTitle}
            </Typography>
          </Grid>
        )}
        <Grid item lg={7}>
          {searchInput && (
            <TextFieldInput
              type={searchInput.type}
              onChange={(e) => {
                handleChange && handlePageChange(1, handleChange);
                getKeywords(e.target.value, e?.type);
              }}
              placeholder={searchInput.placeholder}
              StartAdornment={<SearchIcon />}
              className={searchInput.className}
              value={searchKeywords?.keyword}
            />
          )}
        </Grid>
      </Grid>

      <Grid
        container
        item
        lg={5.5}
        flexDirection="row"
        alignItems="center"
        justifyContent={filterMenus ? "space-between" : "end"}
      >
        {filterMenus &&
          filterMenus.map((filterMenu, index) => {
            return (
              <Grid item lg={4} className="filter-menu-wrap" key={index}>
                <FilterMenu
                  head={filterMenu.head}
                  dataList={filterMenu.dataList}
                  handleFilter={filterMenu.handleFilter}
                  handleChange={handleChange}
                />
              </Grid>
            );
          })}

        <Grid
          item
          lg={filterMenus ? 12 - filterMenus.length * 4 : "auto"}
          textAlign="end"
        >
          {primaryButtons.map((btn, index) => {
            return (
              <PrimaryButton
                key={index}
                fontSize={btn.fontSize}
                startIcon={btn.startIcon && <AddIcon />}
                text={btn.text}
                classBtn={btn.classBtn}
                style={btn.style}
                click={() => {
                  btn.click && btn.click();
                  // btn.btnType === 'submit' && handelSubmit();
                }}
              />
            );
          })}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PagesHead;

import React from 'react'
import Branches from '../../../Components/Organisms/Branches/Branches';

function BranchesListTemplate(props) {
    const { filterObj, setFilterObj, branchesList, openLoader, count, page, setPage, handlePageChange, getBranches, roles ,setBranchesList} = props;
    return ( 
        <Branches
            filterObj={filterObj} setFilterObj={setFilterObj}
            branchesList={branchesList}
            openLoader={openLoader}
            count={count} page={page}
            setPage={setPage}
            handlePageChange={handlePageChange}
            getBranches={getBranches}
            roles={roles}
            setBranchesList={setBranchesList}
        />
  )
}

export default BranchesListTemplate
import React, { useEffect, useState } from "react";
import Loader from "../../../../Components/Atoms/Loader/Loader";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import SalesmanTemplate from "../../../../Templates/Main/Operators/Salesman/Salesman.template";
import { Typography } from "@mui/material";
import SalesmanReservationsTemplate from "../../../../Templates/Main/Operators/SalesmanReservation/SalesmanReservation.template";
import { getBranchesList } from "../../../../Contexts/APIs/Branches/Branches";
import {
  getSalesmanReservations,
  getSalesmenList,
} from "../../../../Contexts/APIs/Operators/Operators";
import dayjs from "dayjs";

function SalesmanReservationsPage() {
  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();

  const id = searchParams.get("id");
  const type = searchParams.get("type");
  const { operatorId, salesmanId } = useParams();

  const [openLoader, setOpenLoader] = useState(false);
  const [reservationsList, setReservations] = useState([]);
  const [salesmanList, setSalesmanList] = useState([]);

  const [branchesList, setBranchesList] = useState([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [filterObj, setFilterObj] = useState(null);
  const [selectedRow, setSelectedRow] = useState({});
  const getBranches = async (filterObj) => {
    const { res, error } = await getBranchesList({
      page_size: 100,
      ...filterObj,
    });
    if (res) {
      setBranchesList(res?.data?.branches);
    }
    if (error) {
    }
  };
  const updatePagination = (page) => {
    setFilterObj({ ...filterObj, page_number: page });
  };

  const handlePageChange = (event, value) => {
    setPage(value);
    updatePagination(value);
  };

  const getSalesmanReservationsList = async (filterObj) => {
    const defaultFilter = {
      page_size: 10,
      page_number: 1,
      keyword: "",
      sort_by: "id",
    };
    setOpenLoader(true);
    const { res, error } = await getSalesmanReservations({
      ...defaultFilter,
      ...filterObj,
    });
    if (res) {
      setOpenLoader(false);
      setReservations(res?.data?.salesman_reservations);
      setCount(res?.extra?.total_count);
    }
    if (error) {
      setOpenLoader(false);
    }
  };

  const getSalesmanList = async () => {
    const { res, error } = await getSalesmenList({
      page_size: 100,
      dive_center_id: operatorId,
    });
    if (res) {
      setSalesmanList(res?.data?.salesmen);
      setCount(res?.extra?.total_count);
    }
    if (error) {
    }
  };
  useEffect(() => {
    getSalesmanList();
  }, []);
  const getList = () => {
    salesmanId == "All"
      ? filterObj && getSalesmanReservationsList(filterObj)
      : filterObj &&
        getSalesmanReservationsList({ salesman_id: salesmanId, ...filterObj });
  };
  useEffect(() => {
    const timeId = setTimeout(() => {
      getList();
    }, 700);
    return () => clearTimeout(timeId);
  }, [filterObj, salesmanId]);

  const rowData = (data) => {
    setSelectedRow(data);
  };
  const statusList = {
    pending: "#333132",
    reservation_confirmed: "#f6b119",
    checkin_pending: "#00b906",
    completed: "#00b906",
    checked_in: "#00b906",
    payment_pending: "#f6b119",
    cancelled: "#e10007",
    user_cancelled_resrvation: "#e10007",
    reservation_auto_rejected: "#e10007",
    payment_expired: "#e10007",
    rejected: "#e10007",
  };
  const mappedData = reservationsList?.map((val) => {
    return {
      ...val,
      title_data: val?.title,
      date: dayjs(val?.created_at).format("MMM DD ,YYYY ,hh A"),
      activityDate: dayjs(
        val?.inhouse_activity_group?.inhouse_activity?.created_at
      ).format("MMM DD ,YYYY ,hh A"),
      status: (
        <Typography sx={{ color: `${statusList[val?.status]} !important` }}>
          {val?.status}
        </Typography>
      ),
    };
  });
  return (
    <>
      <Loader open={openLoader} />
      <SalesmanReservationsTemplate
        setFilterObj={setFilterObj}
        data={mappedData}
        count={count}
        paginationPage={page}
        setPaginationPage={setPage}
        handlePageChange={handlePageChange}
        rowData={rowData}
        branchesList={branchesList}
        salesmanList={salesmanList}
        getSalesmanList={getSalesmanList}
        getBranches={getBranches}
      />
    </>
  );
}

export default SalesmanReservationsPage;

import React, { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import FormHead from "../../../../FormHead/FormHead";
import { Stack } from "@mui/material";
import CFormErrors from "./CFormErrors";
export function EditorInput({ label, name, formik, changeAction }) {
  const [value, setValue] = useState("");
  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
        { align: "right" },
        { align: "center" },
        { align: "right" },
      ],
      ["link", "image"],
      ["clean"],
    ],
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "align",
    "link",
    "image",
    "table",
  ];

  return (
    <Stack>
      <FormHead text={label} />
      <ReactQuill
        theme="snow"
        value={formik?.values[name]}
        onChange={changeAction}
        formats={formats}
        modules={modules}
      />
      <CFormErrors errorsMsg={formik?.errors[name]} />
    </Stack>
  );
}

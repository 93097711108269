import React from 'react'
import { getOperatorDetails } from '../../../Contexts/APIs/Operators/Operators';
import Loader from '../../../Components/Atoms/Loader/Loader';
import { toast } from 'react-toastify';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import OperatorDetailsTemplate from '../../../Templates/Main/Operators/OperatorDetails.Template';

function OperatorDetailsPage({roles}) {
    const { operatorId } = useParams();
    const [operatorData, setOperatorData] = useState({});
    const [openLoader, setOpenLoader] = useState(false);

    const getOperator = async (id) => {
        setOpenLoader(true);
        const { res, err } = await getOperatorDetails(id);
        if (res) { setOperatorData(res?.data?.user); }
        if (err) toast.error(err)
        setOpenLoader(false);
    };

    useEffect(() => {
        getOperator(operatorId)
    }, []);

  return (
    <>
        <Loader open={openLoader} />
        <OperatorDetailsTemplate 
          operatorData={operatorData}
          getOperator={getOperator}
          roles={roles}
        />
    </>
  )
}

export default OperatorDetailsPage
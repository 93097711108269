import { IconButton, Menu, MenuItem } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import DeleteModal from "../../General/Models/DeleteModal";

function ActionsMenu(props) {
  const {
    user,
    statusAttribute,
    userPath,
    userPathLength,
    handleUpdate,
    getUsers,
    filterObj,
    getUser,
    userCount,
    roles,
    setList,
  } = props;
  const navigate = useNavigate();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => setAnchorEl(event.currentTarget);

  const handleClose = () => setAnchorEl(null);

  // hide in view details button in details page
  const hrf = window.location.href;
  const checkDetails =
    typeof parseInt(
      hrf.slice(hrf.indexOf(userPath) + (userPathLength + 1), hrf.length)
    ) === "number" &&
    hrf.slice(hrf.indexOf(userPath) + (userPathLength + 1), hrf.length)
      .length != 0
      ? true
      : false;
  // hide in view details button in details page
  const handelDeleteRequest = () => {
    (async () => {
      const { res, err } = await handleUpdate({
        customer_id: user?.id,
        user: {
          status: "deleted",
        },
      });

      if (res) {
        setOpenDeleteModal(false);
        handleClose();
        userCount === "single"
          ? navigate(`/${userPath}`)
          : setList((prevState) =>
              prevState?.filter((ele) => ele?.id !== user?.id)
            );
      }
    })();
  };
  return (
    <>
      <IconButton
        id={`demo-positioned-btn${user?.id}`}
        aria-controls={open ? `demo-positioned-menu${user?.id}` : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <MoreHorizIcon style={{ color: "black" }} />
      </IconButton>
      {
        <Menu
          id={`demo-positioned-menu${user?.id}`}
          aria-labelledby={`demo-positioned-btn${user?.id}`}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          {!checkDetails && roles?.can_read && (
            <MenuItem
              onClick={() => {
                navigate(`/${userPath}/` + user?.id);
                handleClose();
              }}
            >
              View details
            </MenuItem>
          )}

          {console.log("user[statusAttribute]", user[statusAttribute])}

          {(user[statusAttribute] === "active" ||
            user[statusAttribute] === "deactivated") &&
            user[statusAttribute] !== "deleted" &&
            roles?.can_update &&
            roles?.can_read && (
              <MenuItem
                onClick={() => {
                  (async () => {
                    const { res, err } = await handleUpdate({
                      customer_id: user?.id,
                      user: {
                        status: "suspended",
                      },
                    });

                    if (res) {
                      console.log(res, "res");
                      handleClose();
                      userCount === "single"
                        ? setList(user)
                        : setList((prevState) =>
                            prevState?.map((user) =>
                              user?.id == res?.data?.user?.id
                                ? res?.data?.user
                                : user
                            )
                          );
                    }
                  })();
                }}
              >
                Suspend
              </MenuItem>
            )}

          {user[statusAttribute] === "suspended" &&
            roles?.can_update &&
            roles?.can_read &&
            user[statusAttribute] !== "deleted" &&
            roles?.can_update &&
            roles?.can_read && (
              <MenuItem
                onClick={() => {
                  (async () => {
                    const { res, err } = await handleUpdate({
                      customer_id: user?.id,
                      user: {
                        status: "active",
                      },
                    });

                    if (res) {
                      console.log(res, "res");
                      handleClose();
                      userCount === "single"
                        ? setList(user)
                        : setList((prevState) =>
                            prevState?.map((user) =>
                              user?.id == res?.data?.user?.id
                                ? res?.data?.user
                                : user
                            )
                          );
                    }

                    // prevState?.map((user) => {
                    //     if (user.id === payload?.data?.user?.id) {
                    //       return payload?.data?.user;
                    //     }
                  })();
                }}
              >
                Active
              </MenuItem>
            )}

          {(user[statusAttribute] === "active" ||
            user[statusAttribute] === "deactivated") &&
            user[statusAttribute] !== "deleted" &&
            roles?.can_delete &&
            roles?.can_read && (
              <MenuItem onClick={() => setOpenDeleteModal(true)}>
                Delete
              </MenuItem>
            )}
        </Menu>
      }
      {openDeleteModal && (
        <DeleteModal
          open={openDeleteModal}
          setOpen={setOpenDeleteModal}
          handelDeleteRequest={handelDeleteRequest}
          handleCancelDelete={() => setOpenDeleteModal(false)}
          text={[
            `Delete ${user.name}`,
            `are you sure that you want to delete ${user.name}`,
          ]}
        />
      )}
    </>
  );
}

export default ActionsMenu;

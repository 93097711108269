import * as Yup from "yup";
import { icons } from "../../../../Assets/AssetHelper";
export const initialValues = (data, lang) => ({
  ...data,
  ...data?.localized_data,
  country_id: {
    value: data?.city?.country?.id,
    label: data?.city?.country?.name,
  },
  lookup_key: data?.lookup_key,
  city_id: data?.city?.id,
});
export const structure = (lang, countriesList, countriesCount, getCountry, country, setCountry, 
  provincesList, setProvincesList, provincesCount, getProvinces, provinice, setProvinice) => [
  {
    head: "City information",
    list: [
      {
        name: `${lang}.name`,
        kind: "input",
        type: "text",
        label: "Area  name",
        width: "45%",
      },
      {
        name: `country_id`,
        kind: "select",
        type: "text",
        label: "Country",
        width: "45%",
        options: countriesList,
        count: countriesCount,
        getList: getCountry,
        value: country,
        onChange: (val) => {
          setCountry(val);
          setProvinice(null);
          setProvincesList([]);
        },
        onClear: () => {
          setCountry(null);
          setProvinice(null);
          setProvincesList([]);
        },
        onOpen: () => countriesList.length === 0 && getCountry()
      },
      {
        name: `lookup_key`,
        kind: "input",
        type: "text",
        label: "Lookup key",
        width: "45%",
      },
      {
        name: `city_id`,
        kind: "select",
        type: "text",
        label: "City",
        width: "45%",
        options: provincesList,
        count: provincesCount,
        getList: getProvinces,
        moreFilterData: {country_id: country?.value},
        value: provinice,
        onChange: (val) => {setProvinice(val)},
        onClear: () => {setProvinice(null)},
        onOpen: () => {(provincesList.length === 0 && country?.value) && getProvinces({page_number: 1, country_id: country.value})}
      },
      {
        name: `min_rate`,
        kind: "input",
        type: "text",
        label: "Freelancer Min rate",
        width: "45%",
      },
    ],
  },
  {
    head: "City image",
    list: [
      {
        name: `logo`,
        kind: "upload",
        uploadIcon:icons.addImage,
        sx:{p:0},
        sxDelete:{
          bottom: '-5px',
          right: '-5px',
        },
        type: "text",
        label: "Add image",
        width: "45%",
      },
    ],
  },
];

export const validationSchema = () =>
  Yup.object({
    en: Yup.object().shape({
      name: Yup.string().required("required"),
    }),
    country_id: Yup.mixed().required("required"),
    lookup_key: Yup.string().required("required"),
    city_id: Yup.mixed().required("required"),
    min_rate: Yup.string().required("required"),
  });

import React from "react";
import Users from "../../../Components/Organisms/Users/Users.jsx";

function UsersTemplate(props) {
  const {
    filterObj,
    setFilterObj,
    usersList,
    setUsersList,
    openLoader,
    setOpenLoader,
    count,
    page,
    setPage,
    handlePageChange,
    usersPrivilages,
    rolesPrivilages,
    rolesData,
    getRolesData,
    rolesCount
  } = props;

  const filterList = [
    {
      key: "role_id",
      title: "User role",
      list: [{ id: "", name: "All" }, ...rolesData],
      getList: getRolesData,
      count: rolesCount,
      onMenuOpen: () => rolesData.length === 0 && getRolesData()
    },
  ];
  const handleApplyFilterMenu = (obj) => {
    setFilterObj((prev) => {
      return { ...prev, ...obj, page_number: 1 };
    });
    setPage(1);
  };
  const handleResetFilterMenu = () => {
    setFilterObj({
      page_size: 10,
      page_number: 1,
      keyword: "",
      sort_by: "id",
    });
    setPage(1);
  };

  return (
    <>
      <Users
        filterObj={filterObj}
        setFilterObj={setFilterObj}
        usersList={usersList}
        setUsersList={setUsersList}
        openLoader={openLoader}
        setOpenLoader={setOpenLoader}
        count={count}
        page={page}
        handlePageChange={handlePageChange}
        usersPrivilages={usersPrivilages}
        rolesPrivilages={rolesPrivilages}
        filterList={filterList}
        handleApplyFilterMenu={handleApplyFilterMenu}
        handleResetFilterMenu={handleResetFilterMenu}
      />
    </>
  );
}

export default UsersTemplate;

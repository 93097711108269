import { axiosFn, handleError } from "../index";
import * as URL from "../URLs";
import { makeFilterString } from "../Helper/Helper.js";

export async function getNotificationsList(filter_obj = {}) {
  return await axiosFn("get", URL.notifications + makeFilterString(filter_obj))
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}

export async function notificationsCount() {
  return await axiosFn("get", URL.notifications + `/count`)
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}

export async function createNotifications({ data, action }) {
  return await axiosFn("post", URL.notifications, data)
    .then((res) => {
      action && action();

      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}

export async function getNotificationsDetails(id) {
  return await axiosFn("get", `${URL.notifications}/${id}`)
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}

export async function deleteNotifications(id) {
  return await axiosFn("delete", `${URL.notifications}/${id}`)
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}

export async function updateNotifications({ id, data, action }) {
  return await axiosFn("put", `${URL.notifications}/${id}`, data)
    .then((res) => {
      action && action();
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}

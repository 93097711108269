import { Grid, Typography, Divider } from "@mui/material";
import "./Form.css";
import FileInput from "../../Atoms/Input/FileInput/FileInput.jsx";
import { icons } from "../../../Assets/AssetHelper.js";
import PrimaryButton from "../../Atoms/Buttons/Primary/PrimaryButton.jsx";

const UploadImagesForm = (props) => {
    const { uploadImages, formTitle, Images, RemoveImage, type = 'multi', uploadText = "Add images" } = props;
    return (
        <Grid container className="activity-form" gap={1}>
            <Grid container >
                <Typography
                    component="h1"
                    style={{
                        fontSize: "20px",
                        fontWeight: "bold",
                        borderBottom: "2px solid var(--primary-color)"
                    }}
                    className="mb-20px"
                >
                    {formTitle}
                </Typography>
            </Grid>
            {(type == 'single' && !Images?.url) && <Grid width='100%'>
                <FileInput
                    icon={icons.addImage}
                    uploadText={uploadText}
                    btnStyle="add-activity-img"
                    change={(e) => uploadImages(e)}
                    accept="image/*"
                />
            </Grid>}
            {type == 'multi' && <Grid item md={3} className="mb-20px">
                <FileInput
                    icon={icons.addImage}
                    uploadText={uploadText}
                    btnStyle="add-activity-img"
                    change={(e) => uploadImages(e)}
                    accept="image/*"
                />
            </Grid>}
            {type == 'single' ?
                Images?.url && <Grid item md={3} className="mb-20px">
                    <Grid className="activity-img-container" position="relative">
                        <img src={Images?.url} alt="" />
                        <PrimaryButton
                            btnType="icon"
                            startIcon={<img src={icons.DeleteIcon} alt="delete" />}
                            style={{
                                position: "absolute",
                                bottom: "0",
                                right: "0"
                            }}
                            click={() => RemoveImage()}
                        />
                    </Grid>
                </Grid>
                : Images?.map((img, index) => (
                    <Grid item md={3} className="mb-20px" key={index} >
                        <Grid className="activity-img-container" position="relative">
                            <img src={img?.url} alt="" />
                            <PrimaryButton
                                btnType="icon"
                                startIcon={<img src={icons.DeleteIcon} alt="delete" />}
                                style={{
                                    position: "absolute",
                                    bottom: "0",
                                    right: "0"
                                }}
                                click={() => RemoveImage(index)}
                            />
                        </Grid>
                    </Grid>
                ))
            }
            <Grid container>
                <Typography sx={{color:'black'}}>
                    {window.fileSize}
                </Typography>
            </Grid>
        </Grid>
    )
}

export default UploadImagesForm
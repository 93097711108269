import React, { useState } from "react";
import { Rating, Stack, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import CircleIcon from "@mui/icons-material/Circle";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import "./OperatorDetailsHeader.css";
import {
  approveOperator,
  deleteOperator,
  updateOperator,
  updateRejectionReason,
} from "../../../../Contexts/APIs/Operators/Operators";
import PrivilagesMenu from "../../General/PagesHead/Menu/PrivilagesMenu";
import { useNavigate } from "react-router-dom";

function OperatorDetailsHeader({ data, getOperator, roles }) {
  const navigate = useNavigate();
  const isBranchExist = data?.branch_locations?.length > 0;
  const StyledRating = styled(Rating)({
    "& .MuiRating-iconFilled": {
      color: "#3ed181",
    },
    "& .MuiRating-iconHover": {
      color: "#3ed181",
    },
  });

  const [errorListener, setErrorListener] = useState(false);

  return (
    <Stack
      alignItems="center"
      bgcolor="white"
      boxShadow="0 2px 15px 0 rgba(0, 0, 0, 0.1)"
      borderRadius="10px"
      padding={2}
      flexDirection="row"
      justifyContent="space-between"
    >
      <Stack flexDirection="row" gap={2} width="100%">
        <img
          src={data?.logo}
          width="90px"
          height="90px"
          style={{ borderRadius: "10px" }}
        />
        <Stack
          flexDirection="row"
          justifyContent="space-between"
          width="100%"
          className="details-header-content"
        >
          <Stack flexDirection="column" justifyContent="space-evenly">
            <Stack flexDirection="row" gap={2} alignItems="center">
              <Stack flexDirection="row" gap={"50px"} alignItems="center">
                <Typography
                  fontSize="30px"
                  fontWeight="bold"
                  color="#333132"
                  textTransform="capitalize"
                >
                  {data?.name}
                </Typography>
                <Typography
                  fontSize="16px"
                  fontWeight="900"
                  color="#f6b119"
                  textTransform="capitalize"
                  sx={{
                    textDecoration: isBranchExist && "underline",
                    cursor: isBranchExist && "pointer",
                  }}
                  onClick={(_) =>
                    isBranchExist &&
                    navigate(`/operators/branches?dive_center_id=${data?.id}`)
                  }
                >
                  {data?.branch_locations?.length}{" "}
                  {data?.branch_locations?.length === 1 ? "Branch" : "Branches"}
                </Typography>
              </Stack>
            </Stack>
            <Stack flexDirection="row" gap={2} alignItems="center">
              <Typography
                fontSize="20px"
                fontWeight="500"
                color="#333132"
                mr="104px"
              >
                Operator ID : {data?.id}
              </Typography>
              {/* <StyledRating
                name="customized-color"
                value={data?.avg_rate}
                readOnly={true}
                icon={
                  <CircleIcon fontSize="inherit" style={{ fontSize: "12px" }} />
                }
                emptyIcon={
                  <CircleOutlinedIcon
                    fontSize="inherit"
                    style={{ fontSize: "12px", color: "#3ed181" }}
                  />
                }
              />
              <Typography fontSize="20px" fontWeight="600" color="#3ed181">
                ({data?.number_of_reviews} reviews)
              </Typography> */}
            </Stack>
          </Stack>
          <Stack flexDirection="row" gap={2}>
            <Stack
              flexDirection="column"
              py="4px"
              justifyContent="space-between"
            >
              <Typography
                fontSize="16px"
                fontWeight="bold"
                color="#333132"
                display="block"
                textTransform="capitalize"
              >
                Account Status : {data?.status}
              </Typography>
              <Stack flexDirection="row" gap={"4px"} alignItems="center">
                <Typography fontSize="16px" fontWeight="bold" color="#333132">
                  Approval Status :{" "}
                </Typography>
                <Typography
                  sx={{
                    textTransform: "capitalize",
                    color: `${
                      data.profile_status === "approved"
                        ? "#00b906"
                        : data.profile_status === "pending_approval" ||
                          data.profile_status === "incomplete"
                        ? "#f6b119"
                        : data.profile_status === "rejected"
                        ? "#e10007"
                        : "#333132"
                    }`,
                    fontSize: "16px",
                    fontWeight: "bold",
                  }}
                >
                  {data.profile_status === "pending_approval"
                    ? "pending approval"
                    : data.profile_status}
                </Typography>
              </Stack>
            </Stack>
            {!roles?.can_read &&
            !roles?.can_update &&
            !roles?.can_delete ? null : (
              <Stack
                flexDirection="row"
                justifyContent="center"
                alignItems="start"
              >
                <PrivilagesMenu
                  user={data}
                  statusAttribute={"status"}
                  profileStatusAttribute={"profile_status"}
                  userPath={"operators"}
                  userPathLength={9}
                  handleApprove={approveOperator}
                  handleUpdate={updateOperator}
                  handleDelete={deleteOperator}
                  handleRejection={updateRejectionReason}
                  getUser={getOperator}
                  userCount={"single"}
                  setErrorListener={setErrorListener}
                  errorListener={errorListener}
                  roles={roles}
                />
              </Stack>
            )}
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}

export default OperatorDetailsHeader;

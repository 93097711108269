import { Box, Modal, Stack, Typography } from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
const ImgModel = ({ open, setOpen, data }) => {
  const handleClose = () => setOpen(false);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    borderRadius: "24px",
    boxShadow: 24,
  };
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        "&:focuse": {
          border: "0",
        },
      }}
    >
      <Box sx={style}>
        <Stack
          flexDirection="row"
          justifyContent="space-between"
          py={2}
          px={1}
          bgcolor="white"
          boxShadow="0 2px 15px 0 rgba(0, 0, 0, 0.1)"
          borderTopLeftRadius="24px"
          borderTopRightRadius="24px"
        >
          <Typography fontSize="24px" fontWeight="bold">
            {data?.organization_certificate?.name}
          </Typography>
          <CloseIcon onClick={handleClose} />
        </Stack>
        <Box
          as="img"
          src={data?.image}
          width="100%"
          height="100%"
          borderRadius="24px"
        />
      </Box>
    </Modal>
  );
};

export default ImgModel;

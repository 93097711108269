import { Grid, Typography } from "@mui/material";
import { useFormik } from "formik";
import React from "react";
import { icons } from "../../../../Assets/AssetHelper";
import PrimaryButton from "../../../Atoms/Buttons/Primary/PrimaryButton";
import TextFieldInput from "../../../Atoms/Input/TextField/TextFieldInput";
import * as yup from "yup";

const ResetPasswordForm = ({ ChangePassword }) => {
  const ChangePasswordIntialValues = {
    current_password: "",
    password: "",
    password_confirmation: "",
  };
  const fcm_token = localStorage.getItem("fcm_token");
  const ChangePasswordValidationSchema = yup.object({
    current_password: yup
      .string()
      .trim()
      .required("Current Password is Required")
      /* .min(8, "Current Password must be at least 8 characters")
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*?&]{8,}$/,
        "Current Password must contain at least one letter and one number"
      ) */,
    password: yup
      .string()
      .trim()
      .required("New Password is Required")
      .min(8, "New Password must be at least 8 characters")
      .matches(
        /[a-zA-Z]/,
        "New Password must contain at least one letter"
    )
      .matches(
        /[0-9]/,
        "New Password must contain at least one number"
      ),
    password_confirmation: yup
      .string()
      .oneOf([yup.ref("password"), null], "Passwords must match")
      .required("Password Confirmation is Required"),
  });
    const formik = useFormik({
    initialValues: ChangePasswordIntialValues,
    validationSchema: ChangePasswordValidationSchema,
    onSubmit: (values) => {
      const data = {
        user: {
          current_password: values.current_password,
          password: values.password,
          password_confirmation: values.password_confirmation,
        },
        device: {
          uuid: "dummy",
          device_type: "web",
          fcm_token: fcm_token,
        },
      };
      const userType = localStorage.getItem("userType");
      ChangePassword(userType==="descendant_user"?"descendant_user":"admin_user", data); // is this correct ? it's working now 
    },
  });
  return (
    <Grid container lg={12} md={12} sm={12} xs={12}>
      <Grid className="mb-20px" xs={8}>
        <TextFieldInput
          name="current_password"
          id="current_password"
          placeholder={"Current password"}
          className="login-input"
          type="password"
          onChange={formik.handleChange}
          value={formik.values.current_password}
          error={
            formik.touched.current_password &&
            Boolean(formik.errors.current_password)
          }
          helperText={
            formik.touched.current_password && formik.errors.current_password
          }
          clickEnter={formik.handleSubmit}
        />
      </Grid>
      <Grid className="mb-20px" xs={8}>
        <TextFieldInput
          name="password"
          id="password"
          placeholder={"New password"}
          className="login-input"
          type="password"
          onChange={formik.handleChange}
          value={formik.values.password}
          error={formik.touched.password && Boolean(formik.errors.password)}
          helperText={formik.touched.password && formik.errors.password}
          clickEnter={formik.handleSubmit}
        />
      </Grid>
      <Grid className="mb-20px" xs={8}>
        <TextFieldInput
          name="password_confirmation"
          id="password_confirmation"
          placeholder={"Confirm new password"}
          className="login-input"
          type="password"
          onChange={formik.handleChange}
          value={formik.values.password_confirmation}
          error={
            formik.touched.password_confirmation &&
            Boolean(formik.errors.password_confirmation)
          }
          helperText={
            formik.touched.password_confirmation &&
            formik.errors.password_confirmation
          }
          clickEnter={formik.handleSubmit}
        />
      </Grid>
      <Grid className="mb-20px" container>
        <PrimaryButton
          text={"Submit"}
          classBtn="primary"
          style={{ padding: "10px 50px" }}
          click={formik.handleSubmit}
        />
      </Grid>
    </Grid>
  );
};

export default ResetPasswordForm;

import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
// import PhoneInput from "../../Atoms/Input/PhoneInput/PhoneInput";
import TextFieldInput from "../../Atoms/Input/TextField/TextFieldInput";
import UploadImagesForm from "./UploadImagesForm";
import en from "react-phone-number-input/locale/en.json";
import SelectBox from "../../Atoms/Select/Select";
import Loader from "../../Atoms/Loader/Loader";
import "./UserForm.css";
import SearchableSelect from "../../Atoms/Select/SearchableSelect";
import PhoneInput from 'react-phone-input-2'
import { ErrorMessage } from "formik";

const UserForm = (props) => {
  const {
    formik,
    openLoader,
    rolesData,
    getRolesData, 
    rolesCount,
    handleFormSubmit,
    validationMethod,
    userImage,
    uploadUserImages,
    removeUserImage,
    formType,
  } = props;

  const href = window.location.href;
  const editPage = href.indexOf("/edit/") !== -1,
    editPasswordPage = href.indexOf("/edit-password/") !== -1;  
  // console.log(formik.values);
  // const [role, setRole] = useState({});
  // const [countryCode, setCountryCode] = useState("");

  useEffect(() => {
    handleFormSubmit.current = validationMethod;
    console.log(rolesData.find(item => item.id === formik.values.role_id))
    // setRole({value: formik.values.role_id, id: formik.values})
  }, [
    formik.values.name,
    formik.values.role_id,
    formik.values.email,
    formik.values.phone_number,
    formik.values.password,
    formik.values.password_confirmation,
    userImage,
  ]);

  return (
    <>
      <Loader open={openLoader} />

      {!openLoader && (
        <Grid
          container
          item
          lg={12}
          flexDirection="row"
          justifyContent="space-between"
        >
          <Grid
            item
            xs={12}
            lg={5.75}
            minWidth={"calc((5.75/12)*100%)"}
            className="user-form"
          >
            {!editPasswordPage ? (
              <>
                <Grid container item md={12}>
                  <Typography
                    component="h1"
                    style={{
                      fontSize: "20px",
                      fontWeight: "bold",
                      borderBottom: "2px solid var(--primary-color)",
                    }}
                    className="mb-20px"
                  >
                    User information
                  </Typography>
                </Grid>
                <Grid container item md={12} className="mb-20px">
                  <Grid item sm={5.8}>
                    <TextFieldInput
                      name="name"
                      id="name"
                      placeholder={"Full name"}
                      className="user-name"
                      type="text"
                      value={formik.values.name}
                      error={formik.touched.name && Boolean(formik.errors.name)}
                      helperText={formik.touched.name && formik.errors.name}
                      onChange={formik.handleChange}
                    />
                  </Grid>
                  <Grid item sm={0.4}></Grid>
                  {/* <Grid item sm={5.8}>
                    <SelectBox
                      id="role_id"
                      name="role_id"
                      items={rolesData}
                      title={"User role"}
                      className="select"
                      value={
                        formik.values.role_id ? `${formik.values.role_id}` : ""
                      }
                      error={
                        formik.touched.role_id && Boolean(formik.errors.role_id)
                      }
                      helperText={
                        formik.touched.role_id && formik.errors.role_id
                      }
                      onchange={(val) =>
                        formik.setValues({
                          ...formik?.values,
                          role_id: val.target.value,
                        })
                      }
                      //   onclick={(val) =>
                      //     formik.setValues({
                      //       ...formik?.values,
                      //       role_id: val.target.value,
                      //     })
                      //   }
                    />
                  </Grid> */}
                  <Grid item xs={5.8}>
                    <SearchableSelect
                      name="role_id"
                      options={rolesData}
                      getList={getRolesData}
                      count={rolesCount}
                      label={"User role"}
                      className="searchable-select role-select"
                      value={formik.values.role_id ?{
                        label: rolesData.find(item => item.id === formik.values.role_id)?.name,
                        value: formik?.values?.role_id
                      }: ""}
                      error={formik.touched.role_id && Boolean(formik.errors.role_id)}
                      helperText={formik.touched.role_id && formik.errors.role_id}
                      onChange={(val) => formik.setValues({...formik?.values, role_id: val.value})}
                      onClear={() => formik.setValues({...formik?.values, role_id: null})}
                      onMenuOpen={() => rolesData.length === 0 && getRolesData()}
                    />
                  </Grid>
                </Grid>
                <Grid container item md={12} className="mb-20px">
                  <Grid item sm={5.8}>
                    <TextFieldInput
                      name="email"
                      id="email"
                      placeholder={"Email address"}
                      className="user-email"
                      type="email"
                      value={formik.values.email}
                      error={
                        formik.touched.email && Boolean(formik.errors.email)
                      }
                      helperText={formik.touched.email && formik.errors.email}
                      onChange={formik.handleChange}
                    />
                  </Grid>
                  <Grid item sm={0.4}></Grid>
                  <Grid item sm={5.8}>
                    {/* <PhoneInput
                      id={"phone_number"}
                      name={"phone_number"}
                      className="normal-textField user-phone"
                      selectId={"search-select"}
                      inputValue={formik.values.phone_number}
                      inputType={"number"}
                      setPhone={(e) =>
                        formik.setValues({
                          ...formik.values,
                          phone_number: e.target.value,
                        })
                      }
                      placeholder={"Phone number"}
                      labels={en}
                      value={formik.values.country_code}
                      onChange={(value) =>
                        formik.setValues({
                          ...formik.values,
                          country_code: value,
                        })
                      }
                      codePlus={true}
                      error={
                        formik.touched.phone_number &&
                        Boolean(formik.errors.phone_number)
                      }
                      helperText={
                        formik.touched.phone_number &&
                        formik.errors.phone_number
                      }
                    /> */}

                <PhoneInput
                  inputProps={{
                    name: 'phone_number',
                    required: true,
                    autoFocus: true,
                    id: 'phone_number'
                  }}
                  inputStyle={{
                    color: 'black',
                    border: 'none',
                    width: 'calc(100% - 40px)',
                    height: '44.88px',
                    padding: '7px 15px',
                    direction: 'ltr',
                    fontSize: '1rem'
                  }}
                  inputClass='phone-input-class login-input'
                  specialLabel=""
                  placeholder="Phone number"
                  defaultMask={ ((editPage && parseInt(formik.values.country_code) != NaN 
                    && parseInt(formik.values.country_code) == 20) 
                    || (!editPage && parseInt(formik.values.country_code) == 20)
                  )? '... ... ....' : '' }                  
                  value={`${formik.values.country_code}${formik.values.phone_number}`}
                  onChange={(value, data) => { 
                    formik.setValues({
                      ...formik.values,
                      phone_number: value.slice(data?.dialCode?.length),
                      country_code: data.dialCode
                    });
                  }}
                />
                {
                  (formik?.errors && formik?.errors?.phone_number) &&                  
                  <p
                    style={{
                      fontSize: "0.75rem",
                      color: "#d32f2f",
                      textAlign: "left",
                      margin: "3px 14px 0px"
                    }}
                  >
                    {formik?.errors?.phone_number}
                  </p>
                }
                  </Grid>
                </Grid>
              </>
            ) : null}
            {
              // formType === "Add" ? <Grid container item md={12} className="mb-20px">
              !editPage ? (
                <Grid container item md={12} className="mb-20px">
                  <Grid item sm={5.8}>
                    <TextFieldInput
                      name="password"
                      id="password"
                      placeholder={"Password"}
                      type="password"
                      onChange={formik.handleChange}
                      value={formik.values.password}
                      error={
                        formik.touched.password &&
                        Boolean(formik.errors.password)
                      }
                      helperText={
                        formik.touched.password && formik.errors.password
                      }
                      clickEnter={formik.handleSubmit}
                    />
                  </Grid>
                  <Grid item sm={0.4}></Grid>
                  <Grid item sm={5.8}>
                    <TextFieldInput
                      name="password_confirmation"
                      id="password_confirmation"
                      placeholder={"Confirm password"}
                      type="password"
                      onChange={formik.handleChange}
                      value={formik.values.password_confirmation}
                      error={
                        formik.touched.password_confirmation &&
                        Boolean(formik.errors.password_confirmation)
                      }
                      helperText={
                        formik.touched.password_confirmation &&
                        formik.errors.password_confirmation
                      }
                      clickEnter={formik.handleSubmit}
                    />
                  </Grid>
                </Grid>
              ) : null
            }
          </Grid>
          {!editPasswordPage ? (
            <Grid
              item
              xs={12}
              lg={5.75}
              sx={{
                minWidth: "calc((5.75/12)*100%)",
                boxShadow: "0 2px 15px 0 rgba(0, 0, 0, 0.1)",
                background: "#fff",
                borderRadius: "10px",
              }}
              className="user-form-image"
            >
              <UploadImagesForm
                formTitle={"Image"}
                uploadImages={uploadUserImages}
                Images={userImage}
                RemoveImage={removeUserImage}
                type="single"
                uploadText="Add image"
              />
            </Grid>
          ) : null}
        </Grid>
      )}
    </>
  );
};

export default UserForm;

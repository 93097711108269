import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import CCrud from "../../../../Components/Molecules/General/Crud/CCrud";
import { initialValues, structure, validationSchema } from "./structure";
import { Stack } from "@mui/material";
import CustomSeparator from "../../../../Components/Atoms/BreadCrumb";
import "../../../../Components/Atoms/Shared/Styles/list-page.css";
import "./SystemConfigurations.css";

const SystemConfigurationsTemplate = ({
  setFilterObj,
  data,
  count,
  paginationPage,
  setPaginationPage,
  handlePageChange,
  handleSubmit,
  rowData,
  systemConfig,
  formik, //formik isn't used ?
  setPage
}) => {
  let [searchParams] = useSearchParams();
  const type = searchParams.get("type");
  const index = searchParams.get("index");
  const key = searchParams.get("id");
  const navigate = useNavigate();
  const breadcrumbs = [
    {
      path: "/system-configurations/main",
      active: true,
      title: " Configurations",
    },
    {
      path: "/system-configurations/main",
      active: true,
      title: "System configurations",
    },
    {
      active: false,
      title: index === null ? "Add System Configurations" : `Edit ${key}`,
    },
  ];

  return (
    <>
      {type === "form" ? (
        <Stack
          direction="row"
          alignSelf="start"
          justifyContent="start"
          gap={2}
          width="100%"
          mb={1}
        >
          <CustomSeparator breadcrumbs={breadcrumbs} />
        </Stack>
      ) : null}
      <CCrud
        table={{
          list: data,
          listTitle: "System configurations",
          emptyListMsg: "There are no System configurations",

          columns: [
            { accessor: "system_id", Header: "Id" },
            { accessor: "system_key", Header: "Key" },
            { accessor: "system_value", Header: "Value" },
            { accessor: "system_unit", Header: "Unit" },
            { accessor: "system_describtion", Header: "Describtion" },
            { accessor: "action", Header: "Actions" },
          ],
          isFilter: false,
          isSearch: false,
          isChecked: false,
          isSort: false,
          total_count: count,
          setFilterObj,
          className:
            "organization-table system-configurations-table system-config-organization-table",
          tableContainerOverflow: {overflow: 'auto'},
          paginationPage: paginationPage,
          setPaginationPage: setPaginationPage,
          handlePageChange,
          rowData: rowData,
          setPage
        }}
        form={{
          structure: () => structure(key, systemConfig),
          initialValues: !index ? {} : initialValues(systemConfig),
          validationSchema: validationSchema(key),
          onSubmit: handleSubmit,
          title: index === null ? "Add System Configurations" : `Edit ${key}`,
          className: { form: "flow-form", actionButton: "flow-form-buttons" },
        }}
      />
    </>
  );
};

export default SystemConfigurationsTemplate;

import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useNavigate, NavLink } from "react-router-dom";
import PrimaryButton from "../../Atoms/Buttons/Primary/PrimaryButton.jsx";
import { Box, Stack } from "@mui/material";


const AccordionTab = ({ btn, index, accordionToggle, setAccordionToggle, setAccordionListener }) => {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
    setAccordionToggle(isExpanded ? panel : false);
    setAccordionListener(isExpanded ? panel : false);
  };
  return (
    <Accordion
      className="side-bar-btn mb-10px "
      sx={{
        background: expanded ? "#f6b119" : "transparent",
        p: "0 !important",
        borderRadius: "10px",
      }}
      // expanded={expanded === index}
      expanded={accordionToggle === false ? accordionToggle : expanded === index}
      onChange={handleChange(index)}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        sx={{
          // background: expanded ? "#f6b119" : "transparent",
          background: (accordionToggle && expanded) ? "#f6b119" : "transparent",
          borderTopRightRadius: "10px",
          borderTopLeftRadius: "10px",
        }}
      >
        <Stack
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "row",
            gap: 1.5,
          }}
        >
          <Box sx={{ width: '25px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}> {btn?.icon} </Box>
          <Typography
            sx={{
              fontSize: "18px",
              fontWeight: "500",
            }}
          >
            {btn?.text}
          </Typography>
        </Stack>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          // background: expanded ? "#f6b119" : "transparent",
          background: (accordionToggle && expanded) ? "#f6b119" : "transparent",
          borderBottomRightRadius: "10px",
          borderBottomLeftRadius: "10px",
        }}
      >
        <Stack>
          {btn?.subLinks?.map((item, i) =>
            item?.isAllowed ? (
              <NavLink
                className="sub-link-active"
                to={item?.action}
                key={i}
                style={({ isActive }) => ({
                  color: "white",
                  background: isActive ? " #ae7000" : "transparent",
                  padding: "5px",
                  borderRadius: "10px",
                })}
              >
                {item.link}
              </NavLink>
            ) : (
              <></>
            )
          )}
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
};

export default AccordionTab;

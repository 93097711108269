import { axiosFn, handleError } from "../index";
import * as URL from "../URLs";

export async function SetDeviceFCMAPI(data) {
  return await axiosFn("patch", URL.FCM, data)
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}

import { makeFilterString } from "../Helper/Helper.js";
import { axiosFn, handleError } from "../index.js";
import * as URL from "../URLs.js";

export async function getLogHistory(filter_obj) {
    return await axiosFn("get", URL.Log_History + makeFilterString(filter_obj))
      .then((res) => {
        return {
          res: res.data,
          err: null,
        };
      })
      .catch(handleError);
}


export async function downloadLogHistoryCSV(filter_obj) {
  return await axiosFn("get", URL.Log_History_toCSV + makeFilterString(filter_obj), null, true, "application/json", "blob")
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}

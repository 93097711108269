import React, { useEffect, useState } from "react";
import Loader from "../../../../Components/Atoms/Loader/Loader";
import { useNavigate, useSearchParams } from "react-router-dom";
import DocumentsTemplate from "../../../../Templates/Main/Documents/Documents/Documents.template";
import {
  deleteDocuments,
  getDocumentsDetails,
  getDocumentsList,
  updateDocuments,
} from "../../../../Contexts/APIs/Documents/Documents";

function DocumentsPage({roles}) {
  let [searchParams, setSearchParams] = useSearchParams();
  const id = searchParams.get("id");

  const [openLoader, setOpenLoader] = useState(false);
  const [documentsList, setDocumentsList] = useState([]);
  const [documentData, setDocumentData] = useState({});
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [filterObj, setFilterObj] = useState(null);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});

  const updatePagination = (page) => {
    setFilterObj({ ...filterObj, page_number: page });
  };

  const handlePageChange = (event, value) => {
    setPage(value);
    updatePagination(value);
  };

  const getDocuments = async (filterObj) => {
    const defaultFilter = {
      page_size: 10,
      page_number: 1,
      keyword: "",
      sort_by: "id",
    };
    setOpenLoader(true);
    const { res, error } = await getDocumentsList({
      ...defaultFilter,
      ...filterObj,
    });
    if (res) {
      setOpenLoader(false);
      setDocumentsList(res?.data?.documents);
      setCount(res?.extra?.total_count);
    }
    if (error) {
      setOpenLoader(false);
    }
  };
  const getDocumentData = async () => {
    // setOpenLoader(true);
    const { res, error } = await getDocumentsDetails(id);
    if (res) {
      setDocumentData(res.data.document);
      setOpenLoader(false);
    }
    if (error) {
      setOpenLoader(false);
    }
  };
  // mappedData use for create custom cell to concat multi values or navigate button
  const mappedData = documentsList?.map((data) => {
    return {
      ...data,
      id_data: <div style={{ width: "200px" }}>{data?.id}</div>,
      name_data: <div>{data?.name?.split("_")?.join(" ")}</div>,
    };
  });

  const handleDeleteRequest = async () => {
    const action = () => {
      setFilterObj({ ...filterObj, page_number: 1 });
      setPage(1);
      window.location.reload();
    };
    updateDocuments({
      id: selectedRow?.id,
      data: {
        document: { is_deleted: true },
      },
      action,
    });
  };

  useEffect(() => {
    if (filterObj) getDocuments(filterObj);
  }, [filterObj]);

  useEffect(() => {
    id && getDocumentData({});
  }, [id]);
  const rowData = (data) => {
    setSelectedRow(data?.rowData);
  };
  const breadcrumbs = [
    { active: true, path: "/documents", title: "Documents" },
    { active: false, title: id ? "Edit document" : "Add new document" },
  ];

  return (
    <>
      <Loader open={openLoader} />
      <DocumentsTemplate
        filterObj={filterObj}
        setFilterObj={setFilterObj}
        data={mappedData}
        count={count}
        paginationPage={page}
        setPaginationPage={setPage}
        handlePageChange={handlePageChange}
        openDeleteModal={openDeleteModal}
        setOpenDeleteModal={setOpenDeleteModal}
        handleDeleteRequest={handleDeleteRequest}
        rowData={rowData}
        details={documentData}
        breadcrumbs={breadcrumbs}
        roles={roles}
      />
    </>
  );
}

export default DocumentsPage;

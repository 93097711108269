import React, { useState } from "react";
import { Button, Divider, Grid, Menu, Typography } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import TextFieldInput from "../../../Atoms/Input/TextField/TextFieldInput";
import "./DiveClubsHeader.css";
import PrimaryButton from "../../../Atoms/Buttons/Primary/PrimaryButton";
import SearchableSelect from "../../../Atoms/Select/SearchableSelect.jsx";
import { useSearchParams } from "react-router-dom";
import { handlePageChange } from "../../../../Contexts/APIs/Helper/Helper.js";

function DiveClubsHeader({
  title,
  searchInput,
  filterObj,
  setFilterObj,
  setPage,
  handleChange
}) {
  const isVerified = [
    { id: 1, name: "yes" },
    { id: 2, name: "no" },
  ];

  const StatusList = [
    { id: 1, name: "active" },
    { id: 4, name: "suspended" },
  ];

  const [searchKeywords, setSearchKeywords] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [verified, setVerified] = useState({});
  const [status, setStatus] = useState({});
  const [searchEmail, setSearchEmail] = useState("");
  const [searchParams] = useSearchParams();
  const pageNumber = searchParams.get("page_number") || 1;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const getKeywords = (keyword) => {
    setSearchKeywords(keyword);
    setFilterObj({ ...filterObj, page_number: 1, keyword: keyword.trim() });
    setPage(1);
  };

  const handleApplyFilterMenu = () => {
    handleChange && handlePageChange(1, handleChange);
    setFilterObj({
      ...filterObj,
      page_number: 1,
      is_verified:
        typeof verified?.label === "undefined"
          ? null
          : verified?.label?.toLowerCase() === "yes"
          ? "true"
          : "false",
      status:
        typeof status?.label === "undefined"
          ? null
          : status?.label?.toLowerCase(),
      email: searchEmail,
    });
    setPage(1);
    handleClose();
  };

  const handleResetFilterMenu = () => {
    handleChange && handlePageChange(1, handleChange);
    setVerified({});
    setStatus({});
    setSearchEmail("");
    setFilterObj({ page_number: 1, keyword: searchKeywords.trim() });
    setPage(1);
    handleClose();
  };

  return (
    <Grid
      container
      item
      lg={12}
      style={{ borderBottom: "1px solid #d9d9d9" }}
      sx={{ pb: 1, mb: 2 }}
      gap={"16px 0px"}
      justifyContent={"space-between"}
      className="dive-clubs-head"
    >
      <Grid
        container
        item
        xs={12}
        sm={"auto"}
        lg={"auto"}
        flexDirection="row"
        justifyContent="start"
        gap={"1rem 2rem"}
        marginRight={1}
        alignItems="center"
      >
        <Grid item lg={"auto"}>
          <Typography
            component="h1"
            style={{
              fontSize: "30px",
              fontWeight: "bold",
              textAlign: "left",
              color: "#333132",
            }}
          >
            {title}
          </Typography>
        </Grid>

        <Grid item lg={"auto"}>
          {searchInput && (
            <TextFieldInput
              type={searchInput.type}
              onChange={(e) => {
                handleChange && handlePageChange(1, handleChange);

                getKeywords(e.target.value);
              }}
              placeholder={searchInput.placeholder}
              StartAdornment={<SearchIcon />}
              className={searchInput.className}
              value={searchKeywords}
            />
          )}
        </Grid>
      </Grid>

      <Grid
        container
        item
        xs={12}
        sm={"auto"}
        lg={"auto"}
        alignItems={"center"}
      >
        <Grid item lg={"auto"} display={"flex"}>
          <Button
            aria-label="filter"
            id="filter-button"
            aria-controls={Boolean(anchorEl) ? "filter-menu" : undefined}
            aria-expanded={Boolean(anchorEl) ? "true" : undefined}
            aria-haspopup="true"
            variant="outlined"
            startIcon={<FilterAltIcon sx={{ color: "#bebebe" }} />}
            sx={{
              width: "100%",
              height: "auto",
              color: "#333132",
              borderRadius: "10px",
              border: "solid 1px #ececec",
              fontSize: "16px",
              fontWeight: 500,
              textTransform: "capitalize",
              lineHeight: "unset",
              margin: "auto",
              padding: "7px 15px 8px",
            }}
            onClick={handleClick}
          >
            Filter
          </Button>
          <Menu
            id="filter-menu"
            MenuListProps={{
              "aria-labelledby": "filter-button",
            }}
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            PaperProps={{
              style: {},
            }}
          >
            <Typography
              sx={{
                paddingInlineStart: "16px",
                fontSize: "16px",
                fontWeight: 500,
                mt: 1,
                color: "#333132",
              }}
            >
              Filter by
            </Typography>
            <Divider sx={{ width: "100%", margin: "12px 0" }} />
            <Grid container sx={{ padding: "0 16px" }}>
              <Grid item xs={12}>
                <Typography
                  sx={{ fontSize: "16px", fontWeight: 500, color: "#333132" }}
                >
                  Email
                </Typography>
              </Grid>
              <Grid item xs={12} mb={2}>
                <TextFieldInput
                  type={"search"}
                  onChange={(e) => setSearchEmail(e.target.value)}
                  placeholder={"Type email"}
                  StartAdornment={<SearchIcon />}
                  className={"search-email"}
                  value={searchEmail}
                />
              </Grid>

              <Grid item xs={12}>
                <Typography
                  sx={{ fontSize: "16px", fontWeight: 500, color: "#333132" }}
                >
                  Status
                </Typography>
              </Grid>
              <Grid item xs={12} mb={2}>
                <SearchableSelect
                  name="status_id"
                  options={StatusList}
                  label="Select option"
                  className="searchable-select"
                  onChange={(val) =>
                    setStatus({
                      ...val,
                      label: val.label?.replaceAll(" ", "_"),
                    })
                  }
                  value={
                    status
                      ? {
                          ...status,
                          label: status.label?.replaceAll("_", " "),
                        }
                      : null
                  }
                  onClear={() => setStatus(null)}
                />
              </Grid>

              <Grid item xs={12}>
                <Typography
                  sx={{ fontSize: "16px", fontWeight: 500, color: "#333132" }}
                >
                  Is verified
                </Typography>
              </Grid>
              <Grid item xs={12} mb={2}>
                <SearchableSelect
                  name="verified_id"
                  options={isVerified}
                  label="Select option"
                  className="searchable-select"
                  onChange={(val) =>
                    setVerified({
                      ...val,
                      label: val.label?.replaceAll(" ", "_"),
                    })
                  }
                  value={
                    verified
                      ? {
                          ...verified,
                          label: verified.label?.replaceAll("_", " "),
                        }
                      : null
                  }
                  onClear={() => setVerified(null)}
                />
              </Grid>

              <Grid
                item
                xs={6}
                mt={2}
                mb={2.25}
                sx={{ paddingInlineEnd: "10px" }}
              >
                <PrimaryButton
                  text="Reset"
                  classBtn="reset-btn"
                  click={handleResetFilterMenu}
                />
              </Grid>
              <Grid
                item
                xs={6}
                mt={2}
                mb={2.25}
                sx={{ paddingInlineStart: "10px" }}
              >
                <PrimaryButton
                  text="Apply"
                  classBtn="apply-btn"
                  click={handleApplyFilterMenu}
                />
              </Grid>
            </Grid>
          </Menu>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default DiveClubsHeader;

import { axiosFn, handleError } from "..";
import { makeFilterString } from "../Helper/Helper";
import * as URL from "../URLs";

export async function getJobOffersList(filter_obj) {
  return await axiosFn("get", URL.JOB_OFFERS + makeFilterString(filter_obj))
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}

export async function getJobOfferDetails(jobOffer_id, filter_obj) {
  return await axiosFn("get", filter_obj ? `${URL.JOB_OFFERS}/${jobOffer_id}` + makeFilterString(filter_obj) : `${URL.JOB_OFFERS}/${jobOffer_id}`)
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}


export async function getApplicantsList(filter_obj) {
  return await axiosFn("get", URL.JOB_REQUESTS + makeFilterString(filter_obj))
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}


export async function getApplicantDetails(freelancer_id) {
  return await axiosFn("get", `${URL.FREELANCERS}/${freelancer_id}`)
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}
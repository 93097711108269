import { axiosFn, handleError } from "../index";
import * as URL from "../URLs.js";
import { makeFilterString } from "../Helper/Helper.js";

export async function getOrderStatistics(filter_obj) {
  return await axiosFn(
    "get",
    URL.OrderStatistics + makeFilterString(filter_obj)
  )
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}



export async function getEarningsStatistics(filter_obj) {
  return await axiosFn(
    "get",
    URL.EarningsStatistics + makeFilterString(filter_obj)
  )
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}


export async function getActiveUserStatistics(filter_obj) {
  return await axiosFn(
    "get",
    URL.ActiveUserStatistics + makeFilterString(filter_obj)
  )
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}

import { useEffect, useState } from "react";
import DiveClubsTemplate from "../../../Templates/Main/DiveClubs/DiveClubs.Template";
import { getDiveClubsList } from "../../../Contexts/APIs/DiveClubs/DiveClubs";
import usePageFilter from "../../../Components/Atoms/Hooks/usePageFilter";

const DiveClubsPage = ({ roles }) => {
  const [openLoader, setOpenLoader] = useState(false);
  const [diveClubsList, setDiveClubsList] = useState([]);
  const { page, filterObj, handlePageChange, setFilterObj, setPage } =
    usePageFilter();
  const [count, setCount] = useState(0);
  
  
  const getDiveClubs = async (filterObj) => {
    const defaultFilterObj = {
      page_size: 10,
      page_number: 1,
      keyword: "",
      sort_by: "id",
    };
    setOpenLoader(true);
    const { res, error } = await getDiveClubsList({
      ...defaultFilterObj,
      ...filterObj,
    });
    if (res) {
      setOpenLoader(false);
      setDiveClubsList(res?.data?.users);
      setCount(res?.extra?.total_count);
    }
    if (error) {
      setOpenLoader(false);
    }
  };

  useEffect(() => {
    const timeId = setTimeout(() => {
      getDiveClubs(filterObj);
    }, 500);
    return () => clearTimeout(timeId);
  }, [filterObj]);
  return (
    <DiveClubsTemplate
      filterObj={filterObj}
      setFilterObj={setFilterObj}
      diveClubsList={diveClubsList}
      openLoader={openLoader}
      count={count}
      page={page}
      setPage={setPage}
      handlePageChange={handlePageChange}
      getDiveClubs={getDiveClubs}
      roles={roles}
      setDiveClubsList={setDiveClubsList}
    />
  );
};

export default DiveClubsPage;

import { Grid, IconButton, Stack, Typography } from "@mui/material";
import React from "react";
import CustomSeparator from "../../Atoms/BreadCrumb";
import FormHead from "../../Atoms/FormHead/FormHead";
import moment from "moment";
import { icons } from "../../../Assets/AssetHelper";
import { useState } from "react";
import DeleteModal from "../../Molecules/General/Models/DeleteModal";
import PhoneInput from "react-phone-input-2";

function UserDetails({ userData, handleEditUser, handelDeleteUser, usersPrivilages }) {
  const created = moment(userData?.created_at).format("DD-MM-YYYY HH:mm"),
    updated = moment(userData?.updated_at).format("DD-MM-YYYY HH:mm");

  const breadcrumbs = [
    { path: "/users", active: true, title: "Users" },

    {
      active: false,
      title: "User details",
    },
  ];

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  
  return (
    <>
    <Stack gap={3}>
      <Grid container item sm={12}>
        <CustomSeparator breadcrumbs={breadcrumbs} />
      </Grid>

      <Stack alignItems='center' bgcolor='white' boxShadow='0 2px 15px 0 rgba(0, 0, 0, 0.1)' 
        borderRadius='10px' padding={2} flexDirection='row' justifyContent='space-between'>
        <Stack flexDirection='row' gap={2} width='100%'>
            <img src={userData?.avatar} width='90px' height='90px' style={{ borderRadius: '10px' }} />
            <Stack flexDirection='row' justifyContent='space-between' width='100%' className='details-header-content'>
                <Stack flexDirection='column' justifyContent='space-evenly'>
                    <Stack flexDirection='row' gap={2} alignItems='center'>
                        <Stack flexDirection='row' gap={'50px'} alignItems='center'>
                            <Typography fontSize='30px' fontWeight='500' color='#333132' textTransform='capitalize'>{userData?.email}</Typography>
                        </Stack>
                    </Stack>
                    <Stack flexDirection='row' gap={2} alignItems='center'>
                        <Typography fontSize='20px' fontWeight='bold' color='#333132' mr='104px'>{userData?.role?.name}</Typography>
                    </Stack>
                </Stack>
                <Stack
                    flexDirection="row"
                    gap={"20px"}
                    justifyContent="right"
                    alignItems="center"
                    flexWrap="wrap"
                >
                <IconButton onClick={handleEditUser}>
                    <img width="35" src={icons.EditIcon} alt="edit" />
                </IconButton>
                {handelDeleteUser && (
                    <IconButton onClick={()=> usersPrivilages?.can_read && usersPrivilages?.can_delete ? setOpenDeleteModal(true) : null}>
                    <img width="35" src={icons.DeleteIcon} alt="delete" />
                    </IconButton>
                )}
                </Stack>
            </Stack>
        </Stack>
    </Stack>

      <Stack
        mt="-25px"
        bgcoloe="white"
        padding="53.8px"
        className="details-panel"
        height="48vh"
        justifyContent="space-between"
        gap="1rem"
        boxShadow="0 2px 15px 0 rgba(0, 0, 0, 0.1)"
        overflow="auto"
      >
          <Stack bgcolor="rgba(0, 0, 0, 0.01)" borderRadius="10px" p={2}>
            <FormHead text={"Admin user info"} />
            <Stack gap={2}>
                <Stack flexDirection="column">
                    <Typography fontSize="13px" color="#aaa"> Email </Typography>
                    <Typography className="details-text-bold"> {userData?.email} </Typography>
                </Stack>
                <Stack flexDirection="column">
                    <Typography fontSize="13px" color="#aaa"> Phone number </Typography>
                    <Typography className="details-text-bold"> 
                      {userData?.phone_number ? <PhoneInput
                        inputStyle={{
                            border: "none",
                            width: "100%",
                            padding: "0px",
                            direction: "ltr",
                            fontSize: '18px',                      
                            color: '#282828',
                            backgroundColor: '#f9f9f9',
                            wordBreak: 'break-word',
                            fontWeight: 'bold'
                        }}
                        defaultMask={ parseInt(userData.country_code) == 20 ? '... ... ....' 
                        : parseInt(userData.country_code) == 93 ? '.. ... ....' 
                        : null}
                        value={`${userData.country_code} ${userData?.phone_number}`}
                        disabled={true}
                        disableDropdown={true}
                        specialLabel=""
                        placeholder=""
                      /> : null}
                    </Typography>
                </Stack>
            </Stack>
          </Stack>

          <Stack
            flexDirection="row"
            gap="1rem 3rem"            
            className="details-time"
            flexWrap="wrap"
            width="100%"
          >
            <Stack flexDirection="row" gap={1}>
              <Typography color="#333132" className="details-text-bold">
                {`Created at : ${created}`}
              </Typography>
            </Stack>
            <Stack flexDirection="row" gap={1}>
              <Typography color="#333132" className="details-text-bold">
                {`Updated at : ${updated}`}
              </Typography>
            </Stack>
          </Stack>
      </Stack>
    </Stack>

    {
        openDeleteModal && <DeleteModal
            open={openDeleteModal} setOpen={setOpenDeleteModal}
            handelDeleteRequest={handelDeleteUser}
            handleCancelDelete={() => setOpenDeleteModal(false)}
            text={[`Delete ${userData?.name}`, `are you sure that you want to delete ${userData?.name}`]}
        />
    }
    </>
  );
}

export default UserDetails;

import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import Languages from "../../../Components/Atoms/Shared/language/Language";
import CCrud from "../../../Components/Molecules/General/Crud/CCrud";
import "./Reservations.css";
import moment from "moment";
import dayjs from "dayjs";
import { getBranchesList } from "../../../Contexts/APIs/Branches/Branches";

const ReservationsTemplate = ({
  filterObj,
  setFilterObj,
  data,
  count,
  paginationPage,
  setPaginationPage,
  handlePageChange,
  modalComponents,
  rowData,
  setPage,
}) => {
  let [searchParams, setSearchParams] = useSearchParams();

  const searchInputPlaceholder = "Search reservation by number";
  const type = searchParams.get("type");
  const pageNumber = searchParams.get("page_number") || 1;

  const [supportedLang, setSupportedLang] = useState("en");
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);
  const [branches, setBranches] = useState([]);
  const [branchesCount, setBranchesCount] = useState(0);

  const getBranches = async (filter) => {
    const { res, error } = await getBranchesList({
      page_size: 10,
      page_number: 1,
      dive_center_id: filter?.dive_center_id,
      ...filter,
    });
    if (res) {
      filter?.page_number === 1 || filter?.page_number === undefined
        ? setBranches(res?.data?.branches)
        : setBranches((prev) => [...prev, ...res?.data?.branches]);
      setBranchesCount(res?.extra?.total_count);
    }
  };

  const filterExtra = [
    {
      key: "date_from",
      setExtraState: setDateFrom,
      extraState: dateFrom,
      handleChange: (e) => {
        setDateFrom(dayjs(new Date(e).toLocaleDateString()));
      },
      placeholder: "From",
      customClass: "border-r-10px basic-date-picker",
    },
    {
      key: "date_to",
      setExtraState: setDateTo,
      extraState: dateTo,
      handleChange: (e) => {
        setDateTo(dayjs(new Date(e).toLocaleDateString()));
      },
      placeholder: "To",
      customClass: "border-r-10px basic-date-picker",
    },
  ];

  const filterList = [
    {
      key: "branch_id",
      title: "Branch",
      list: branches,
      onMenuOpen: getBranches,
      getList: getBranches,
      count: branchesCount,
    },
    {
      key: "status",
      title: "Status",
      list: [
        { id: "pending", name: "Wait for confirm reservation " },
        { id: "payment_pending", name: "Wait for payment confirmation " },
        { id: "checkin_pending", name: "Waiting for checkin" },
        { id: "checked_in", name: "Customer checked in" },
        { id: "completed", name: "Activity completed" },
        { id: "cancelled", name: "Canceled" },
        { id: "payment_expired", name: "Payment allowance period has expired" },
        { id: "rejected", name: "Confirmation rejected" },
      ],
    },
    {
      key: "is_paid",
      title: "Payment",
      list: [
        { id: true, name: "Paid" },
        { id: false, name: "Unpaid" },
      ],
    },
  ];

  const handleApplyFilterMenu = (obj) => {
    setFilterObj((prev) => {
      return { ...prev, page_number: 1, ...obj };
    });
    setPaginationPage(1);
  };

  const handleResetFilterMenu = () => {
    setFilterObj({
      page_number: 1,
      is_paid: null,
      status: null,
      reservation_date_from:
        dateFrom !== null
          ? moment(new Date(dateFrom), "YYYY/MM/DD").format("YYYY-MM-DD")
          : null,
      reservation_date_to:
        dateTo !== null
          ? moment(new Date(dateTo), "YYYY/MM/DD").format("YYYY-MM-DD")
          : null,
    });
    setPaginationPage(1);
  };

  useEffect(() => {
    if (
      (dateFrom !== null && new Date(dateFrom) != "Invalid Date") ||
      (dateTo !== null && new Date(dateTo) != "Invalid Date")
    ) {
      setFilterObj({
        ...filterObj,
        page_number: 1,
        reservation_date_from:
          dateFrom !== null
            ? moment(new Date(dateFrom), "YYYY/MM/DD").format("YYYY-MM-DD")
            : null,
        reservation_date_to:
          dateTo !== null
            ? moment(new Date(dateTo), "YYYY/MM/DD").format("YYYY-MM-DD")
            : null,
      });
    } else {
      setFilterObj({
        ...filterObj,
        reservation_date_from:
          dateFrom !== null
            ? moment(new Date(dateFrom), "YYYY/MM/DD").format("YYYY-MM-DD")
            : null,
        reservation_date_to:
          dateTo !== null
            ? moment(new Date(dateTo), "YYYY/MM/DD").format("YYYY-MM-DD")
            : null,
        page_number: pageNumber,
      });
    }
  }, [dateFrom, dateTo]);

  return (
    <>
      {type == "form" && <Languages setLanguage={setSupportedLang} />}
      <CCrud
        table={{
          list: data,
          listTitle: "Reservations",
          emptyListMsg: "There are no reservations",
          searchAttribute: "reservation_number",
          columns: [
            { accessor: "id", Header: "Id" },
            { accessor: "customer_name", Header: "Customer name" },
            { accessor: "activity_name", Header: "Activity name" },
            { accessor: "activity_date", Header: "Activity date" },
            { accessor: "branch_name", Header: "Branch name" },
            { accessor: "reservation_type", Header: "Reservation type" },
            {
              accessor: "reservation_date_time",
              Header: "Reservation date /time",
            },
            { accessor: "reservation_number", Header: "Res number" },
            { accessor: "seats", Header: "Seats" },
            { accessor: "total_price", Header: "Total price" },
            { accessor: "payment_paid", Header: "Payment" },
            { accessor: "payment_date_time", Header: "Payment date / time" },
            { accessor: "status", Header: "Statues" },
          ],
          isFilter: true,
          isSearch: true,
          isChecked: false,
          isSort: false,
          total_count: count,
          filterList,
          filterExtra,
          setFilterObj,
          className: "reservation-table",
          tableContainerOverflow: { overflowX: "auto" },
          paginationPage: paginationPage,
          setPaginationPage: setPaginationPage,
          searchInputPlaceholder: searchInputPlaceholder,
          handleApplyFilterMenu,
          handleResetFilterMenu,
          handlePageChange: handlePageChange,
          setPage,
          rowData: rowData,
        }}
        modalComponents={modalComponents}
      />
    </>
  );
};

export default ReservationsTemplate;

import React from 'react'
import ActivityOffers from '../../../Components/Organisms/ActivityOffers/ActivityOffers';

function ActivityOffersTemplate(props) {
    const { filterObj, setFilterObj, activityOffersList, count, page, setPage, handlePageChange } = props;
    return ( 
        <ActivityOffers
            filterObj={filterObj} setFilterObj={setFilterObj}
            activityOffersList={activityOffersList}
            count={count} page={page}
            setPage={setPage}
            handlePageChange={handlePageChange}
        />
  )
}

export default ActivityOffersTemplate
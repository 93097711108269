import * as Yup from "yup";
export const initialValues = (data) => ({
  ...data,
  ...data?.localized_data,
});

export const structure = () => [
  {
    head: "Social media information",
    list: [
      {
        name: `name`,
        kind: "input",
        type: "text",
        label: "Social media name",
        width: "100%",
      },
    ],
  },
];

export const validationSchema = () =>
  Yup.object({
    name: Yup.string().required("required"),
  });

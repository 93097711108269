import React, { useEffect, useState } from "react";
import Loader from "../../../Components/Atoms/Loader/Loader";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { getSupportedLanguages } from "../../../Contexts/APIs/Lookups/SupportedLanguages";
import { toast } from "react-toastify";
import {
  createCertificate,
  deleteCertificate,
  editCertificate,
  getCertificateDetails,
  getCertificatesList,
} from "../../../Contexts/APIs/Organizations/OrganizationsCertificates";
import OrganizationCertificatesTemplate from "../../../Templates/Main/Organizations/OrganizationCertificates/OrganizationCertificates.template";

function OrganizationsCertificatesPage({ roles }) {
  const navigate = useNavigate();
  let [searchParams] = useSearchParams();
  const type = searchParams.get("type");
  const index = searchParams.get("index");
  const certificateId = searchParams.get("id");
  const { organizationId } = useParams();
  const organizationName = searchParams.get("orgName");

  const [openLoader, setOpenLoader] = useState(false);
  const [certificatesList, setCertificatesList] = useState([]);

  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);

  const [filterObj, setFilterObj] = useState(null);

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [supportedLanguages, setSupportedLanguages] = useState([]);
  const [supportLang, setSupportLang] = useState("en");
  const [certificateData, setCertificateData] = useState({});

  const mappedData = certificatesList?.map((data) => {
    return {
      ...data,
      is_professional: (
        <div
          className={`${
            data.is_professional ? "certificate-success" : "certificate-failed"
          }`}
        >
          {data.is_professional ? "Yes" : "No"}
        </div>
      ),
      is_hidden: (
        <div
          className={`${
            data.is_hidden ? "certificate-success" : "certificate-failed"
          }`}
        >
          {data.is_hidden ? "Yes" : "No"}
        </div>
      ),
    };
  });

  const updatePagination = (page) => {
    setFilterObj({ ...filterObj, page_number: page });
  };
  const handlePageChange = (event, value) => {
    setPage(value);
    updatePagination(value);
  };

  const getLanguages = async () => {
    const { res, err } = await getSupportedLanguages();
    if (res) {
      setSupportedLanguages(res?.data?.supported_locales);
    }
  };
  const updateSupportedLang = (e, languages) => {
    setSupportLang(e.target.value);
  };

  const handleDeleteRequest = async () => {
    const { res, err } = await deleteCertificate(selectedRow?.id);
    if (res) {
      setOpenDeleteModal(false);
      getCertificates(filterObj);
      setFilterObj({ ...filterObj, page_number: 1 });
      setPage(1);
    }
  };

  const rowData = (data) => {
    setSelectedRow(data?.rowData);
  };

  const getCertificate = async () => {
    const { res, err } = await getCertificateDetails(certificateId);
    if (res) {
      setCertificateData(res?.data?.organization_certificate);
    }
    if (err) {
      toast.error(err);
    }
  };

  const getCertificates = async (filterObj) => {
    setOpenLoader(true);
    const defaultFilterObj = {
        page_size: 10,
        page_number: 1,
        keyword: "",
        sort_by: "id",
        organization_id: organizationId,
      }
    const { res, error } = await getCertificatesList({...defaultFilterObj,...filterObj});
    if (res) {
      setOpenLoader(false);
      setCertificatesList(res?.data?.organization_certificates);
      setCount(res?.extra?.total_count);
    }
    if (error) {
      setOpenLoader(false);
    }
  };

  const handleCreateCertificate = async (values) => {
    const { res, err } = await createCertificate({
      organization_certificate: {
        organization_id: organizationId,
        is_professional: values.is_professional ? "true" : "false",
        is_hidden: values.is_hidden ? "true" : "false",
        ...values,
      },
    });
    if (res) {
      navigate(
        `/organizations/${organizationId}/certificates?orgName=${organizationName}`
      );
    }
  };
  const handleEditCertificate = async (values) => {
    const { res, err } = await editCertificate(certificateId, {
      organization_certificate: {
        organization_id: organizationId,
        is_professional: values.is_professional ? "true" : "false",
        is_hidden: values.is_hidden ? "true" : "false",
        ...values,
      },
    });
    if (res) {
      navigate(
        `/organizations/${organizationId}/certificates?orgName=${organizationName}`
      );
    }
  };
  const handleSubmit = (data, formik) => {
    const action = () => {
      searchParams.delete("id");
      searchParams.delete("type");
      searchParams.delete("index");
      formik.resetForm();
    };
    if (index) {
      handleEditCertificate(data);
      action();
    } else {
      handleCreateCertificate(data);
      action();
    }
  };

  useEffect(() => {
    getLanguages();
  }, []);

  useEffect(() => {
      certificateId && getCertificate(certificateId);
  }, [certificateId]);

  useEffect(() => {
    filterObj && getCertificates(filterObj);
  }, [filterObj]);

  return (
    <>
      <Loader open={openLoader} />
      <OrganizationCertificatesTemplate
        filterObj={filterObj}
        setFilterObj={setFilterObj}
        data={mappedData}
        count={count}
        paginationPage={page}
        setPaginationPage={setPage}
        handlePageChange={handlePageChange}
        openDeleteModal={openDeleteModal}
        setOpenDeleteModal={setOpenDeleteModal}
        handleDeleteRequest={handleDeleteRequest}
        selectedRow={selectedRow}
        setSelectedRow={setSelectedRow}
        supportedLanguages={supportedLanguages}
        updateSupportedLang={updateSupportedLang}
        setSupportLang={setSupportLang}
        supportLang={supportLang}
        handleSubmit={handleSubmit}
        rowData={rowData}
        certificateData={certificateData}
        roles={roles}
      />
    </>
  );
}

export default OrganizationsCertificatesPage;

import React, { useEffect, useState } from "react";
import Loader from "../../../../Components/Atoms/Loader/Loader";
import moment from "moment";
import PriceIncludeTemplate from "../../../../Templates/Main/PredefinedLookups/PriceInclude/PriceInclude.template";
import {
  deletePriceInclude,
  getPriceIncludeDetails,
  getPriceIncludeList,
} from "../../../../Contexts/APIs/PriceInclude/PriceInclude";
import { getSupportedLanguages } from "../../../../Contexts/APIs/Lookups/SupportedLanguages";
import usePageFilter from "../../../../Components/Atoms/Hooks/usePageFilter";

function PriceIncludePage({roles}) {
  // let [searchParams, setSearchParams] = useSearchParams();
  const {
    page,
    filterObj,
    handlePageChange: onPageChange,
    setFilterObj,
    setPage,
    searchParams,
  } = usePageFilter();
  const id = searchParams.get("id");
  const type = searchParams.get("type");

  const [openLoader, setOpenLoader] = useState(false);
  const [priceIncludesList, setPriceIncludesList] = useState([]);
  const [priceIncludeData, setPriceIncludeData] = useState({});
  const [count, setCount] = useState(0);
  // const [page, setPage] = useState(1);
  // const [filterObj, setFilterObj] = useState(null);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [supportedLanguages, setSupportedLanguages] = useState([]);

  // const updatePagination = (page) => {
  //   setFilterObj({ ...filterObj, page_number: page });
  // };

  // const handlePageChange = (event, value) => {
  //   setPage(value);
  //   updatePagination(value);
  // };

  const getLanguages = async () => {
    const { res, err } = await getSupportedLanguages();
    if (res) {
      setSupportedLanguages(res?.data?.supported_locales);
    }
  };
  // const updateSupportedLang = (e, languages) => {
  //   setSupportLang(e.target.value);
  // };

  const getPriceInclude = async (filterObj) => {
    setOpenLoader(true);
    const defaultFilter = {
      page_size: 10,
      page_number: 1,
      keyword: "",
      sort_by: "id",
    };
    const { res, error } = await getPriceIncludeList({
      ...defaultFilter,
      ...filterObj,
    });
    if (res) {
      setOpenLoader(false);
      setPriceIncludesList(res?.data?.price_includes);
      setCount(res?.extra?.total_count);
    }
    if (error) {
      setOpenLoader(false);
    }
  };
  const getPriceIncludeData = async () => {
    setOpenLoader(true);

    const { res, error } = await getPriceIncludeDetails(id);
    if (res) {
      setPriceIncludeData(res.data.price_include);
      setOpenLoader(false);
    }
    if (error) {
      setOpenLoader(false);
    }
  };
  // mappedData use for create custom cell to concat multi values or navigate button
  const mappedData = priceIncludesList?.map((data) => {
    return {
      ...data,
      created_at_data: moment(data?.created_at).format("DD-MM-YYYY  hh:mm"),
      updated_at_data: moment(data?.updated_at).format("DD-MM-YYYY  hh:mm"),
    };
  });

  const handleDeleteRequest = async () => {
    const action = (data) => {
      setOpenDeleteModal(false);
      setPriceIncludesList((prevState) =>
      prevState?.filter((ele) => ele?.id !== data?.data?.price_include?.id))
    };
    deletePriceInclude(selectedRow?.id, action);
  };

  useEffect(() => {
    if (type == "form") getLanguages();
  }, [type]);

  useEffect(() => {
    if (filterObj) getPriceInclude(filterObj);
  }, [filterObj]);

  useEffect(() => {
    id && getPriceIncludeData({});
  }, [id]);

  const rowData = (data) => {
    setSelectedRow(data?.rowData);
  };
  const breadcrumbs = [
    {
      active: true,
      path: "/predefined-lookups/price-includes",
      title: "Price includes",
    },
    {
      active: false,
      title: id ? "Edit price includes" : "Add new price includes",
    },
  ];
  return (
    <>
      <Loader open={openLoader} />
      <PriceIncludeTemplate
        filterObj={filterObj}
        setFilterObj={setFilterObj}
        data={mappedData}
        count={count}
        paginationPage={page}
        setPaginationPage={setPage}
        handlePageChange={onPageChange}
        supportedLanguages={supportedLanguages}
        openDeleteModal={openDeleteModal}
        setOpenDeleteModal={setOpenDeleteModal}
        handleDeleteRequest={handleDeleteRequest}
        rowData={rowData}
        details={priceIncludeData}
        breadcrumbs={breadcrumbs}
        roles={roles}
      />
    </>
  );
}

export default PriceIncludePage;

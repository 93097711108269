import * as Yup from "yup";
export const initialValues = (data) => ({
  ...data?.localized_data,
  lookup_key: data.lookup_key,
  source: data.source,
  enable_dive_trip: data.enable_dive_trip,
  enable_dive_course: data.enable_dive_course,
  enable_live_aboard: data.enable_live_aboard,
});

export const structure = (lang) => [
  {
    head: "Payment method information",
    list: [
      {
        name: `${lang}.name`,
        kind: "input",
        type: "text",
        label: "Payment method  name",
        width: "100%",
      },
      {
        name: "lookup_key",
        kind: "input",
        type: "text",
        width: "100%",
        disabled: "true",
        className: "paymentLookupKey",
      },
      {
        name: "source",
        kind: "input",
        type: "text",
        width: "100%",
        disabled: "true",
        className: "paymentSource",
      },
      {
        name: `enable_dive_trip`,
        kind: "checkbox",
        label: "Enable dive trip",
        width: "100%",
      },
      {
        name: `enable_dive_course`,
        kind: "checkbox",
        label: "Enable dive course",
        width: "100%",
      },
      {
        name: `enable_live_aboard`,
        kind: "checkbox",
        label: "Enable live aboard",
        width: "100%",
      },
    ],
  },
];

export const validationSchema = () =>
  Yup.object({
    en: Yup.object().shape({
      name: Yup.string().required("required"),
    }),
  });

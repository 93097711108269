import moment from "moment";

var En = false;
function getEnglishLanguage() {
  if (localStorage.getItem("lang") === "en") {
    En = true;
  }
}
getEnglishLanguage();

let searchParams = new URLSearchParams(window.location.search);

export const handlePageChange = (newPage, setPage) => {
  setPage(newPage); // Update the state immediately
  // Update the search parameters without reloading the page
  const newSearchParams = new URLSearchParams(searchParams);
  newSearchParams.set("page_number", newPage);
  // Update the URL using history.pushState
  const newUrl = `${window.location.pathname}?${newSearchParams.toString()}`;
  if (window.history.pushState) {
    window.history.pushState({ path: newUrl }, "", newUrl);
  }
};
export function makeFilterString(filter_obj) {
  var filterString = "?";
  Object.keys(filter_obj)?.map(function (key) {
    if (filter_obj[key] || filter_obj[key] === false) {
      filterString += key + "=" + filter_obj[key] + "&";
    }
  });
  if (filterString.substr(filterString.length - 1) === "&") {
    filterString = filterString.slice(0, -1);
  }
  return filterString;
}

export const DaysList = [
  {
    name: "Saturday",
    id: "saturday",
  },
  {
    name: "Sunday",
    id: "sunday",
  },
  {
    name: "Monday",
    id: "monday",
  },
  {
    name: "Tuesday",
    id: "tuesday",
  },
  {
    name: "Wednesday",
    id: "wednesday",
  },
  {
    name: "Thursday",
    id: "thursday",
  },
  {
    name: "Friday",
    id: "friday",
  },
];

export const ActivityTypes = [
  {
    name: "Dive Trip",
    id: "dive_trip",
  },
  {
    name: "Dive Course",
    id: "dive_course",
  },
  {
    name: "Live Aboard",
    id: "live_aboard",
  },
];

export const GroupsStatus = [
  {
    name: "All",
    id: "all",
  },
  {
    name: "Completed",
    id: "completed",
  },
  {
    name: "Started",
    id: "started",
  },
  {
    name: "Upcoming",
    id: "upcoming",
  },
];

export function getObjectWithSupportedLanguages(
  obj,
  supportedLang,
  objOfKeys,
  key
) {
  for (let i = 0; i < supportedLang.length; i++) {
    obj[key][supportedLang[i].id] = objOfKeys[i];
  }
  return obj;
}

export const findInObject = (object, attr) => {
  const res = Object.keys(object)
    .filter((key) => key.includes(attr))
    .reduce((obj, key) => {
      return Object.assign(obj, {
        item: key.split("_").join(" "),
        value: object[key],
      });
    }, {});
  return res;
};

export const updateLocalizationsValues = (event, i, initArray) => {
  let tempArray = [...initArray];
  let tempIndex = tempArray[i];
  tempIndex = event.target.value;
  const newList = initArray.map((obj, index) => {
    if (index === i) {
      return tempIndex;
    }
    return obj;
  });
  return newList;
};

export const removeObjectWithId = (arr, id, key) => {
  const objWithIdIndex = arr.findIndex((obj) => obj[key] === id);
  arr.splice(objWithIdIndex, 1);
  return arr;
};

export const mergeDateTime = (date = new Date(), time) => {
  const parsedTime = moment(time, ["h:mm A"]);
  const merged = moment(date).set({
    hours: parsedTime.get("hours"),
    minutes: parsedTime.get("minutes"),
  });

  return merged.toDate();
};

export const removeDuplicateObjects = (array, identifier) => {
  const uniqueObjects = {};
  array.forEach((obj) => {
    uniqueObjects[obj[identifier]] = obj;
  });

  return Object.values(uniqueObjects);
};
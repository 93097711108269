import { Box, Stack } from "@mui/material";
import React from "react";
import CInput from "../main/components/CInput";
import CPasswordInput from "../main/components/CTextArea";
import CTextArea from "../main/components/CTextArea";
import SelectOptions from "./components/CSelect";
import CCheckbox from "./components/CCheckbox";
import CUpload from "./components/CUpload";
import FormHead from "../../../FormHead/FormHead";
import { EditorInput } from "./components/CEditableInput";

const FormIndex = ({ formStructures, formik, children, className }) => {
  const renderInputs = (data, index) => {
    const generalProps = {
      ...data,
      formik: formik,
      colSpan: data?.colSpan,
      index: index,
    };
    if (data?.kind === "input") return <CInput {...generalProps} />;
    if (data?.kind === "passwordInput")
      return <CPasswordInput {...generalProps} />;
    if (data?.kind === "textArea") return <CTextArea {...generalProps} />;
    if (data?.kind === "select")
      return (
        <SelectOptions
          {...generalProps}
          changeAction={(value) =>
            formik.setValues({ ...formik?.values, [data?.name]: value?.value })
          }
        />
      );
    if (data?.kind === "checkbox")
      return (
        <CCheckbox
          {...generalProps}
          changeAction={(value) =>
            formik.setValues({ ...formik?.values, [data?.name]: value })
          }
        />
      );
    if (data?.kind === "upload")
      return (
        <CUpload
          {...generalProps}
          changeAction={(value) =>
            formik.setValues({ ...formik?.values, [data?.name]: value })
          }
        />
      );
    if (data?.kind === "editorInput")
      return (
        <EditorInput
          {...generalProps}
          changeAction={(value) =>
            formik.setValues({ ...formik?.values, [data?.name]: value })
          }
        />
      );
  };

  return (
    <form onSubmit={formik?.handleSubmit}>
      <Stack gap={2} width="100%" flexWrap="wrap" flexDirection="row">
        {formStructures?.map((data, ndx) => {
          return (
            <Box className={className} key={ndx}>
              <FormHead text={data?.head} />
              <Stack gap={3} width="100%" flexWrap="wrap" flexDirection="row">
                {data?.list?.map((val, index) => {
                  if (val)
                    return (
                      <Box width={val?.width || "100%"} key={index}>
                        {renderInputs(val, index)}
                      </Box>
                    );
                })}
              </Stack>
            </Box>
          );
        })}
      </Stack>
      {children}
    </form>
  );
};

export default FormIndex;

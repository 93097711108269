import React, { useEffect, useState } from "react";
import Loader from "../../../../Components/Atoms/Loader/Loader";
import CitiesTemplate from "../../../../Templates/Main/Geolocations/Cities/Cities.template";
import {
  deleteCities,
  getCitiesDetails,
  getCitiessList,
} from "../../../../Contexts/APIs/Cities/Cities";
import { icons } from "../../../../Assets/AssetHelper";
import { getCountryList } from "../../../../Contexts/APIs/Country/Country";
import { getProvincesList } from "../../../../Contexts/APIs/Provinces/Provinces";
import { Typography, Box } from "@mui/material";
import ImagesPreviewModel from "../../../../Components/Molecules/General/Models/ImagesPreviewModel";
import usePageFilter from "../../../../Components/Atoms/Hooks/usePageFilter";

function CitiesPage({ roles }) {
  // let [searchParams, setSearchParams] = useSearchParams();
  const {
    page,
    filterObj,
    handlePageChange: onPageChange,
    setFilterObj,
    setPage,
    searchParams,
  } = usePageFilter();
  const id = searchParams.get("id");
  const [filterData, setFilterData] = useState({});
  // const countryId = searchParams.get("country_id");
  const [openLoader, setOpenLoader] = useState(false);
  const [openModel, setOpenModel] = useState({
    status: false,
    data: null,
  });
  console.log(id, "id");
  const [citiessList, setCitiessList] = useState([]);
  const [countriesList, setCountriesList] = useState([]);
  const [provincesList, setProvincesList] = useState([]);
  const [count, setCount] = useState(0);
  // const [page, setPage] = useState(1);
  // const [filterObj, setFilterObj] = useState(null);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [cityData, setCityData] = useState({});
  const [provincesCount, setProvincesCount] = useState(0);
  const [countriesCount, setCountriesCount] = useState(0);
  const [provincesMoreFilterData, setProvincesMoreFilterData] = useState({});
  // const [provincesResetFilterData, setProvincesResetFilterData] = useState({});
  // const updatePagination = (page) => {
  //   setFilterObj({ ...filterObj, page_number: page });
  // };

  // const handlePageChange = (event, value) => {
  //   setPage(value);
  //   updatePagination(value);
  // };

  const getCities = async (filterObj) => {
    const defaultFilter = {
      page_size: 10,
      page_number: 1,
      keyword: "",
      sort_by: "id",
    };
    setOpenLoader(true);
    const { res, error } = await getCitiessList({
      ...defaultFilter,
      ...filterObj,
    });
    if (res) {
      setOpenLoader(false);
      setCitiessList(res?.data?.areas);
      setCount(res?.extra?.total_count);
    }
    if (error) {
      setOpenLoader(false);
    }
  };

  const getCityData = async () => {
    setOpenLoader(true);
    const { res, error } = await getCitiesDetails(id);
    if (res) {
      setCityData(res?.data?.area);
      setOpenLoader(false);
    }
    if (error) {
      setOpenLoader(false);
    }
  };

  // mappedData use for create custom cell to concat multi values or navigate button
  const mappedData = citiessList?.map((data) => {
    return {
      ...data,
      // space: <div style={{ width: "100px", padding: "10px" }}></div>,

      lookup_key: (
        <div style={{ width: "200px !important" }}>
          {data?.lookup_key ?? "-"}
        </div>
      ),
      image: data?.logo ? (
        <Box sx={{ width: "100px" }}>
          <img
            src={data.logo}
            width="53px"
            height="53px"
            alt="logo"
            onClick={() =>
              setOpenModel({
                status: true,
                data: { logo: data?.logo, name: data?.name },
              })
            }
          />
        </Box>
      ) : (
        <Box>
          <img src={icons.imagePlaceHolder} />
        </Box>
      ),
      geoNames: (
        <Typography>
          {data?.city?.name}/{data?.city?.country?.name}
        </Typography>
      ),
    };
  });

  /*const handleDeleteRequest = async () => {
    const action = () => {
      console.log(selectedRow, citiessList, selectedRow?.rowData?.id);

      const newList = citiessList;
      const filterdData = newList?.filter(
        (data) => data?.id != selectedRow?.rowData?.id
      );
      setCitiessList(filterdData);
      setOpenDeleteModal(false);
      if (filterdData?.length == 0) {
        setFilterObj({ ...filterObj, page_number: 1 });
        setPage(1);
      }
    };
    updateCities({
      id: selectedRow?.rowData?.id,
      data: {
        area: {
          is_deleted: true,
        },
      },
      action,
    });
  };*/
  const handleDeleteRequest = async () => {
    const action = (data) => {
      setOpenDeleteModal(false);
      setCitiessList((prevState) =>
      prevState?.filter((ele) => ele?.id !== data?.data?.area?.id))
    };
    deleteCities(selectedRow?.rowData?.id, action);
  };
  useEffect(() => {
    if (filterObj) getCities(filterObj);
  }, [filterObj]);
  
  const getCountry = async(data) => {
    setOpenLoader(true);
    const prevCountries = countriesList;
    const DataKey = data?.keyword;
    if((data?.page_number === 1 || data?.page_number === undefined) && !DataKey && countriesList.length === 10){
      setCountriesList(prevCountries);      
    }else{
      const { res, error } = (countriesCount !== 10 || DataKey)? await getCountryList({ page_size: 10, ...data }) : {};
      if (res) {      
        (data?.page_number == 1 || data?.page_number == undefined)
        ? setCountriesList(res?.data?.countries)
        : setCountriesList((s) => [...s, ...res?.data?.countries]);
        setCountriesCount(res?.extra?.total_count);
      }
    }
    setOpenLoader(false);
  };

  const getProvinces = async (data) => {    
    setOpenLoader(true);
    const prevProvinces = provincesList;
    if((data?.page_number === 1 || data?.page_number === undefined) && !data?.keyword && !data?.country_id && prevProvinces.length !== 0){
      setProvincesList(prevProvinces);      
    }else{
      const { res, error } = (provincesCount !== 10 || data?.keyword)? await getProvincesList({ page_size: 10, country_id: data?.country_id, ...data }) : {};
      if (res) {
        (data?.page_number == 1 || data?.page_number == undefined)
        ? setProvincesList(res?.data?.cities)
        : setProvincesList((s) => [...s, ...res?.data?.cities]);
        setProvincesCount(res?.extra?.total_count);
      }
    }
    setOpenLoader(false);
  };

  useEffect(() => {
    id && getCityData({});
  }, [id]);

  const rowData = (data) => {
    setSelectedRow(data);
  };

  const handleApplyFilterMenu = (obj) => {
    setFilterObj((prev) => {
      return { ...prev, ...obj, page_number: 1 };
    });
    setPage(1);
  };

  const handleResetFilterMenu = () => {
    setFilterObj({
      page_size: 10,
      page_number: 1,
      keyword: "",
      sort_by: "id",
    });
    setPage(1);
  };
  const breadcrumbs = [
    {
      active: true,
      path: `/geolocations/cities`,
      title: "Cities",
    },
    {
      active: false,
      title: id ? "Edit city" : "Add new city",
    },
  ]; 
  return (
    <>
      <Loader open={openLoader} />
      <CitiesTemplate
        filterObj={filterObj}
        setFilterObj={setFilterObj}
        countriesList={countriesList}
        provincesList={provincesList}
        setProvincesList={setProvincesList}
        data={mappedData}
        count={count}
        paginationPage={page}
        setPaginationPage={setPage}
        handlePageChange={onPageChange}
        openDeleteModal={openDeleteModal}
        setOpenDeleteModal={setOpenDeleteModal}
        handleDeleteRequest={handleDeleteRequest}
        rowData={rowData}
        cityData={cityData}
        handleApplyFilterMenu={handleApplyFilterMenu}
        handleResetFilterMenu={handleResetFilterMenu}
        breadcrumbs={breadcrumbs}
        setFilterData={setFilterData}
        getCountry={getCountry}
        getProvinces={getProvinces}
        roles={roles}
        selectedRow={selectedRow}
        provincesCount={provincesCount}
        countriesCount={countriesCount}
      />
      <ImagesPreviewModel open={openModel} setOpen={setOpenModel} />
    </>
  );
}

export default CitiesPage;

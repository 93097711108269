import { Box, Grid, IconButton, Stack, Typography } from "@mui/material";
import React from "react";
import CustomSeparator from "../../../Atoms/BreadCrumb";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import FormHead from "../../../Atoms/FormHead/FormHead";
import LanguagesSelect from "../../../Molecules/Forms/LanguagesSelect";
import { icons } from "../../../../Assets/AssetHelper";
import moment from "moment";

function BadgeDetails(props) {
  const {
    badgeId,
    badgeData,
    supportedLanguages,
    updateSupportedLang,
    supportLang,
    setOpenDeleteModal,
    roles
  } = props;


  const created = badgeData?.created_at ? moment(badgeData?.created_at).format("DD-MM-YYYY HH:mm") : 'N/A',
    updated = badgeData?.updated_at ? moment(badgeData?.updated_at).format("DD-MM-YYYY HH:mm") : 'N/A',
    navigate = useNavigate();
  let [searchParams] = useSearchParams();
  const index = searchParams.get("index");

  const breadcrumbs = [
    { 
        active: true, 
        path: "/predefined-lookups/currency", 
        title: "Predefined lookups"               
    },
    { 
        active: true, 
        path: "/predefined-lookups/badges", 
        title: "Badges"               
    },
    {
      active: false,
      title: "Badge details",
    },
];

  return (
    <Stack gap={3}>
      <Grid container item sm={12}>
        <CustomSeparator breadcrumbs={breadcrumbs} />
      </Grid>

      <Stack
        alignItems="center"
        bgcolor="white"      
        borderBottom="solid 1px #d9d9d9"      
        padding={"47px 54px"}
      >
        <Stack
          flexDirection="row"
          justifyContent="space-between"
          width="100%"
          className="details-header-content"
          alignItems="center"
        >
          <Stack flexDirection="row" alignItems="center" gap={1}>
            <Typography
              fontSize="30px"
              fontWeight="bold"
              color="#333132"
              textTransform="capitalize"
            >
              Badge details
            </Typography>
          </Stack>
          <Stack
            flexDirection="row"
            gap={"20px"}
            justifyContent="right"
            alignItems="center"
            flexWrap="wrap"
          >
            {
              (roles?.can_read && roles?.can_update) &&
              <IconButton onClick={() => navigate(
                `/predefined-lookups/badges?type=form&id=${badgeId}&index=${index}`
              )}>
                <img width="35" src={icons.EditIcon} alt="edit" />
              </IconButton>
            }
            { 
              (roles?.can_read && roles?.can_delete) &&
              <IconButton onClick={() => setOpenDeleteModal(true)}>
                <img width="35" src={icons.DeleteIcon} alt="delete" />
              </IconButton>
            }
          </Stack>
        </Stack>
      </Stack>

      <Grid container item sm={12} className="select-lang-wrap">
        <LanguagesSelect
          value={supportLang}
          supportedLanguages={supportedLanguages}
          onChange={(e) => updateSupportedLang(e, supportedLanguages)}
          LanguagesSelectHead={"Data Language"}
          LanguagesSelectTip={false}
        />
      </Grid>

      <Stack bgcolor="rgba(0, 0, 0, 0.01)" borderRadius="10px" boxShadow="0 2px 15px 0 rgba(0, 0, 0, 0.1)" p={'40px 30px'}>
        <Stack p={2} mb={5}>
          <FormHead text={"Badge  details"} />
          <Box position="relative">
            <Grid container gap={"30px 56px"} pl={2}>
              <Grid item xs={'auto'}>
                <img src={badgeData?.image} style={{
                  borderRadius:'50%', width:"107px", height:"107px", display: 'block'
                }} />
              </Grid>
              <Grid item xs={12} md={8} display='flex' flexDirection='column' justifyContent='center' >
                <Grid container gap={"8px 0px"}>
                  <Grid item xs={6} md={4} lg={2}>
                    <Typography
                      fontSize="18px"
                      fontWeight="500"
                      color="#333132"
                    >
                      ID number
                    </Typography>
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <Typography className='details-text-bold'>{badgeData?.id}</Typography>
                  </Grid>
                </Grid>                    

                <Grid container gap={"8px 0px"}>
                  <Grid item xs={6} md={4} lg={2}>
                    <Typography
                      fontSize="18px"
                      fontWeight="500"
                      color="#333132"
                    >
                      Badge  name
                    </Typography>
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <Typography className="details-text-bold">
                      {badgeData?.localized_data
                        ? badgeData?.localized_data[`${supportLang}`][
                            "name"
                          ]
                        : badgeData?.name}
                    </Typography>
                  </Grid>
                </Grid>                    
              </Grid>
            </Grid>
          </Box>
        </Stack>

        <Stack
          flexDirection="row"
          gap="1rem 3rem"
          padding="5rem 0rem 0rem"
          className="details-time details-time-sm"
        >
          <Stack flexDirection="row" gap={1}>
            <Typography color="#333132" className="details-text-bold"> {`Created at : ${created}`} </Typography>            
          </Stack>
          <Stack flexDirection="row" gap={1}>
            <Typography color="#333132" className="details-text-bold"> {`Updated at : ${updated}`} </Typography>
          </Stack>
        </Stack>
      </Stack>  
    </Stack>
  );
}

export default BadgeDetails;

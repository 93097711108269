import React from 'react'
import ActivityGroups from '../../../Components/Organisms/Activities/ActivityGroups';

function ActivityGroupsTemplate(props) {
  const { count, activityGroups, activityInfo, filterObj, setFilterObj, page, setPage, handlePageChange } = props;
  return (
    <ActivityGroups
        count={count}
        activityGroups={activityGroups}
        activityInfo={activityInfo}
        page={page}
        setPage={setPage}
        filterObj={filterObj}
        setFilterObj={setFilterObj}
        handlePageChange={handlePageChange}
    />
  )
}

export default ActivityGroupsTemplate
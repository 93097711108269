import { Box, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import "../../../Components/Atoms/Shared/Styles/details-page.css";
import CustomSeparator from "../../../Components/Atoms/BreadCrumb";
import FormHead from "../../../Components/Atoms/FormHead/FormHead";
import LanguagesSelect from "../../../Components/Molecules/Forms/LanguagesSelect";
import moment from "moment";
function NotificationDetailsTemplate({
  notificationData,
  supportLang,
  supportedLanguages,
  updateSupportedLang,
}) {
  const breadcrumbs = [
    { path: "/Notifications", active: true, title: "Notifications" },

    {
      active: false,
      title: "Notifications Details",
    },
  ];
  return (
    <Stack spacing={3}>
      <Grid container item sm={12}>
        <CustomSeparator breadcrumbs={breadcrumbs} />
      </Grid>
      <Grid container item sm={12} className="select-lang-wrap">
        <LanguagesSelect
          value={supportLang}
          supportedLanguages={supportedLanguages}
          onChange={(e) => updateSupportedLang(e, supportedLanguages)}
          LanguagesSelectHead={"Data Language"}
          LanguagesSelectTip={false}
        />
      </Grid>
      <Stack
        mt="-25px"
        bgcoloe="white"
        padding="53.8px"
        className="details-panel"
        flexDirection="row"
        justifyContent="space-between"
        gap="1rem"
        boxShadow="0 2px 15px 0 rgba(0, 0, 0, 0.1)"
        overflow="auto"
      >
        <Stack gap={2} width="70%">
          <Stack>
            <Typography style={{ fontSize: "30px", fontWeight: "bold" }}>
              "{
                notificationData?.localized_data
                ? notificationData?.localized_data[`${supportLang}`]["title"]
                : notificationData?.title
              }"
            </Typography>
            <Typography style={{ fontSize: "20px", fontWeight: "bold" }}>
              ID number {notificationData?.id}
            </Typography>
          </Stack>
          <Stack bgcolor="rgba(0, 0, 0, 0.01)" borderRadius="10px" p={2}>
            <FormHead text={"Notification info"} />
            <Box position="relative">
              <Grid container gap={1}>
                <Grid item xs={12}>
                  <Grid container gap={"8px 0px"} mb="21px">
                    <Grid item xs={12} md={3}>
                      <Typography
                        fontSize="18px"
                        fontWeight="500"
                        color="#333132"
                      >
                        Notification type
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <Typography className="details-text-bold">
                        {notificationData?.notification_type}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container gap={"8px 0px"} mb="21px">
                    <Grid item xs={12} md={3}>
                      <Typography
                        fontSize="18px"
                        fontWeight="500"
                        color="#333132"
                      >
                        Notification message
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <Typography className="details-text-bold">
                        {
                          notificationData?.localized_data
                          ? notificationData?.localized_data[`${supportLang}`]["message"]
                          : notificationData?.message
                        }
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Stack>

          <Stack
            flexDirection="row"
            gap="1rem 3rem"
            padding="1rem 0rem 0rem"
            mt={8}
          >
            <Stack flexDirection="row" gap={1}>
              <Typography color="#333132" className="details-text-bold">
                Created at :{" "}
                {moment(notificationData?.sent_at).format("DD-MM-YYYY  HH:mm")}
              </Typography>
            </Stack>
            <Stack flexDirection="row" gap={1}>
              <Typography color="#333132" className="details-text-bold">
                Updated at :{" "}
                {moment(notificationData?.sent_at).format("DD-MM-YYYY  HH:mm")}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}

export default NotificationDetailsTemplate;

import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { createDescendantUser, editDescendantUser, showDescendantUser } from '../../../Contexts/APIs/Users/UsersList';
import UserFormTemplate from '../../../Templates/Main/Users/UserForm.template';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import { getRoleList } from '../../../Contexts/APIs/Users/Roles';
import { getPresignedLinkAPI, uploadFileAPI } from '../../../Contexts/APIs/General/upload';

function UserFormPage(props) {
    const {formType} = props;
    const navigate = useNavigate();
    const [userImage, setUserImage] = useState({});
    const [openLoader, setOpenLoader] = useState(false);
    const [userData, setUserData] = useState([]);
    const [rolesData, setRolesData] = useState([]);
    const [rolesCount, setRolesCount] = useState(0);

    const removeProperties = (obj, ...properties) => {
        const result = { ...obj };
        properties.forEach(function(property) {
          delete result[property];
        });
        return result;
    }

    const href = window.location.href;
    const notEditPage = href.indexOf('/edit/') === -1,
        notEditPasswordPage = href.indexOf('/edit-password/') === -1;

    const validationSchemaObject = yup.object({
        name: notEditPasswordPage && yup.string().trim().required('User name required').min(8),        
        role_id: notEditPasswordPage && yup.string().required('User Role required'),
        email: notEditPasswordPage && yup.string().trim().required('Email required').email(),
        country_code: notEditPasswordPage && yup.string().required('Country code required'),
        phone_number: notEditPasswordPage && yup.number().positive('Numbers only allowed').required('Phone number required'),
        password: notEditPage && yup.string().trim().required('Password required').min(8),
        password_confirmation: notEditPage && yup.string().oneOf([yup.ref("password"), null], "Passwords must match").required('Confirm required')
    });

    const handleCreateUser = async(values) => {
        const {res, err} = await createDescendantUser({
            "descendant_user":{
                ...values,
                country_code: `+${formik.values.country_code}`,
                avatar: userImage.url                    
            }
        });
        if(res){
            setUserImage({});
            navigate('/users');
        }
    }

    const handleEditUser = async(values) => {
        const descendantUserId = notEditPage 
        ? href.slice(href.indexOf('edit-password/')+14, href.length)
        : href.slice(href.indexOf('edit/')+5, href.length);
        
        const {res, err} = await editDescendantUser(
            descendantUserId, {
            "descendant_user":{
                ...values,
                country_code: `+${formik.values.country_code}`,
                avatar: userImage.url                    
            }
        });
        if(res){
            navigate('/users');            
        }
    }

    const formik = useFormik({
        initialValues: {            
            name: '',
            role_id: '',
            email: '',
            country_code: '20',
            phone_number: '',
            password: '',
            password_confirmation: ''
        },
        validationSchema: validationSchemaObject,
        onSubmit: (values) => {            
            formType ===  'Add' && handleCreateUser(values);
            // formType ===  'Edit' && handleEditUser(values);
            formType ===  'Edit' && href.indexOf('/edit/') !== -1 && Object.keys(userImage).length !== 0 
                && handleEditUser(removeProperties(values, 'password', 'password_confirmation'));
            formType ===  'Edit' && href.indexOf('/edit-password/') !== -1 
                && handleEditUser(removeProperties(values, 'name', 'role_id', 'email', 'country_code', 'phone_number'));
        }
    });

    const validationMethod = () => {
        if( Object.keys(userImage).length === 0 && notEditPasswordPage ){
            toast.error('Please upload user image');
            Object.values(formik.values).some(val => val === '') && formik.handleSubmit();
        }else{ formik.handleSubmit(); }
    }

    const getUser = async (id) => {
        setOpenLoader(true);
        const {res,error} = await showDescendantUser(id);
        if(res){
            setUserData(res?.data);

            formik.setValues({
                name: res?.data['descendant_user']['name'],
                role_id: res?.data['descendant_user']['role']['id'],
                email: res?.data['descendant_user']['email'], 
                country_code: res?.data['descendant_user']['country_code'].indexOf('+') === 0 
                              ? res?.data['descendant_user']['country_code'].slice(1, res?.data['descendant_user']['country_code'].length)
                              : res?.data['descendant_user']['country_code'],
                phone_number: res?.data['descendant_user']['phone_number'],
                password: '',
                password_confirmation: ''
            })
        }
        setOpenLoader(false);
    }
    
    const getRolesData = async (filter) => {
        const prevRoles = rolesData;
        const DataKey = filter?.keyword;
        if((filter?.page_number === 1 || filter?.page_number === undefined) && !DataKey && rolesData.length === 10){
          setRolesData(prevRoles);      
        }else{
          const { res, error } = (rolesCount !== 10 || DataKey)? await getRoleList({ page_size: 10, ...filter }) : {};
          if (res) {      
            (filter?.page_number === 1 || filter?.page_number === undefined)
            ? setRolesData(res?.data?.roles)
            : setRolesData((prev) => [...prev, ...res?.data?.roles]);
            setRolesCount(res?.extra?.total_count);
          }
        }
    };

    useEffect( () => {
        const href = window.location.href;        
        // formType === 'Edit' && getUser(href.slice(href.indexOf('edit/')+5, href.length));
        if(formType === 'Edit' && href.indexOf('edit/') > 0){
            getUser(href.slice(href.indexOf('edit/')+5, href.length));
        }else if(formType === 'Edit' && href.indexOf('edit-password/') > 0){
            getUser(href.slice(href.indexOf('edit-password/')+14, href.length));
        }        
    },[window.location.href.indexOf("edit")] )

    useEffect( () => {
        userData?.descendant_user?.avatar && setUserImage({
            media_type: "photo",
            url: userData.descendant_user.avatar
        });
    },[userData] )

    const uploadUserImages = async (event) => {
        const file = event.target.files[0];
        const fileSize = file.size / window.maxFileSize; // in MiB
        if (fileSize > 1) {
            toast.warning(window.fileSize);
        } else {
            setOpenLoader(true)
            const files = [{ extension: "png" }];
            const res = await getPresignedLinkAPI(files);
            const presignedUrl = res?.data[0].presigned_url;
            const upload = await uploadFileAPI(presignedUrl, file);
            if (upload) {
                setUserImage(                    
                    {
                        media_type: "photo",
                        url: res?.data[0]?.file_url,
                    },
                );
            }
            setOpenLoader(false)
        }
    };

    const removeUserImage = () => {
        Object.keys(userImage).forEach(key => delete userImage[key]);
        setUserImage({});
    };

  return (
    <UserFormTemplate 
        formik={formik}
        openLoader={openLoader} 
        rolesData={rolesData}
        getRolesData={getRolesData}
        rolesCount={rolesCount}
        validationMethod={validationMethod}
        userImage={userImage}
        uploadUserImages={uploadUserImages}
        removeUserImage={removeUserImage}
        formType={formType}
    />
  )
}

export default UserFormPage
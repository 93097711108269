import {
  Box,
  Grid,
  LinearProgress,
  linearProgressClasses,
  Rating,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import CustomSeparator from "../../../Components/Atoms/BreadCrumb";
import FreelancerReviewsList from "../../../Components/Molecules/Freelancers/Lists/FreelancerReviewsList";
import { styled } from "@mui/material/styles";
import CircleIcon from "@mui/icons-material/Circle";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import NormalTabs from "../../../Components/Atoms/Tabs/NormalTabs";
import { useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";

const ActivitiesReviewsListTemplate = ({
  reviewsList,
  reviewsDetails,
  count,
  data,
  handleChange,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [option, setOption] = useState(0);
  const options = [
    {
      id: 1,
      label: "Dive center",
      value: "DiveCenter",
    },
    {
      id: 2,
      label: "Activity",
      value: "diveActivity",
    },
    {
      id: 3,
      label: "Instructor",
      value: "DiveExpert",
    }
  ];


  const handelTabClick = (value) => {
    setOption(value);
    // value == 0 ? setSearchParams({ type: 1 }) 
    // : value == 1 ? setSearchParams({ type: 2 })
    // : value == 2 ? setSearchParams({ type: 3 }) : null;
    if(value == 0){
      setSearchParams({ type: 1 }) 
    }else if(value == 1){
      setSearchParams({ type: 2 })
    }else if(value == 2){
      setSearchParams({ type: 3 })
    }
  };
  
  useEffect(() => {
    // searchParams.get("type") == 1 ? setOption(0)
    // : searchParams.get("type") == 2 ? setOption(1)
    // : searchParams.get("type") == 3 ? setOption(2) : null;
    if(searchParams.get("type") == 1){
      setOption(0)
    }else if(searchParams.get("type") == 2){
      setOption(1)
    }else if(searchParams.get("type") == 3){
      setOption(2)
    }
  }, [searchParams]);

  const StyledRating = styled(Rating)({
    "& .MuiRating-iconFilled": {
      color: "#3ed181",
    },
    "& .MuiRating-iconHover": {
      color: "#3ed181",
    },
  });
  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === "light" ? "#3ed181" : "#308fe8",
    },
  }));
  const arr = [5, 4, 3, 2, 1];

  const breadcrumbs = [
    { path: "/operators", active: true, title: "Operators" },
    { path: "/operators/activities", active: true, title: "Dive activites" },
    { path: `/operators/activities/${data?.id}`, active: true, title: data?.name + "details" },
    { active: false, title: "Rating and review" },
  ];

  return (
    <Stack gap={3}>
      <Grid sm={12} container>
        <CustomSeparator breadcrumbs={breadcrumbs} />
      </Grid>
      <Stack gap={3} flexDirection="row">
        <Typography as="h1" style={{ fontSize:"30px", fontWeight:"bold" }}> Rating and review
          <span style={{ fontSize: "20px", fontWeight: "600", color: "#3ed181", marginLeft: "14px" }}>
            ( {reviewsDetails?.total_reviews} reviews )
          </span>
        </Typography>
        <Grid item lg={"auto"}>
          <NormalTabs
            type={"add"}
            TabsOptions={options}
            value={option}
            change={(data) => setOption(data)}
            ContainerStyle="switch-container"
            TabStyle="switch-btn mb-20px"
            onclick={handelTabClick}
          />
        </Grid>
      </Stack>
      <Stack
        flexDirection="row"
        justifyContent="space-between"
        width="100%"
        flexWrap="wrap"
        gap={1}
      >
        <Box
          width={{ xs: "100%", md: "50%" }}
          borderRadius="10px"
          boxShadow="0 2px 15px 0 rgba(0, 0, 0, 0.1)"
          background="white"
          padding={2}
        >
          <FreelancerReviewsList
            reviewsList={reviewsList}
            count={count}
            handleChange={handleChange}
          />
        </Box>
        <Stack
          width={{ xs: "100%", md: "40%" }}
          height="170px"
          borderRadius="10px"
          boxShadow="0 2px 15px 0 rgba(0, 0, 0, 0.1)"
          background="white"
          flexDirection="row"
          justifyContent="space-between"
          padding={2}
        >
          <Stack
            bgcolor="#f8f8f8"
            alignItems="center"
            justifyContent="center"
            p={2}
            width="40%"
          >
            <Typography fontSize="34px" fontWeight="bold">
              {reviewsDetails?.total_reviews} / 5
            </Typography>
            <StyledRating
              name="customized-color"
              value={reviewsDetails?.total_reviews}
              readOnly={true}
              icon={
                <CircleIcon fontSize="inherit" style={{ fontSize: "12px" }} />
              }
              emptyIcon={
                <CircleOutlinedIcon
                  fontSize="inherit"
                  style={{ fontSize: "12px", color: "#3ed181" }}
                />
              }
            />
            <Typography color="#3ed181">
              {reviewsDetails?.total_reviews}
            </Typography>
          </Stack>
          <Stack>
            {arr?.map((val, index) => {
              return (
                <Stack
                  key={index}
                  style={{ flexGrow: 1, width: "200px" }}
                  flexDirection="row"
                  gap={1}
                  alignItems="center"
                >
                  <Typography style={{ color: "#424242" }}>{val}</Typography>
                  <Box style={{ width: "180px" }}>
                    <BorderLinearProgress
                      variant="determinate"
                      value={(reviewsDetails[val] * 100) / 5}
                    />
                  </Box>
                  <Typography style={{ color: "#424242" }}>
                    ({reviewsDetails[val]})
                  </Typography>
                </Stack>
              );
            })}
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default ActivitiesReviewsListTemplate;

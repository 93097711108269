import React from 'react'
import BadgeDetails from '../../../../Components/Organisms/PredefinedLookups/Badges/BadgeDetails'
import DeleteModal from '../../../../Components/Molecules/General/Models/DeleteModal'

function BadgeDetailsTemplate({badgeId, badgeData, supportedLanguages, updateSupportedLang, supportLang,
  openDeleteModal, setOpenDeleteModal, handleDeleteRequest, handleCancelDelete, handleEditBadge, roles
}) {
  return (
    <>
      <BadgeDetails
          badgeId={badgeId}
          badgeData={badgeData} 
          supportedLanguages={supportedLanguages}
          updateSupportedLang={updateSupportedLang}
          supportLang={supportLang}
          setOpenDeleteModal={setOpenDeleteModal}
          handleEditBadge={handleEditBadge}
          roles={roles}
      />
      {
        openDeleteModal && <DeleteModal
            open={openDeleteModal} setOpen={setOpenDeleteModal} 
            handelDeleteRequest={handleDeleteRequest}
            handleCancelDelete={handleCancelDelete}
            customClass='badges-delete-modal'
            text={[`Delete ${badgeData?.name}`, `Are you sure that you want to delete ${badgeData?.name} ?`]}
        />
      }
    </>
  )
}

export default BadgeDetailsTemplate
import { Box, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect } from "react";
import CustomSeparator from "../../Atoms/BreadCrumb";
import FormHead from "../../Atoms/FormHead/FormHead";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import "./CustomerDetails.css";
import FormHeadIcon from "../../Atoms/FormHead/FormHeadIcon";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import CustomerDetailsHeader from "../../Molecules/Customers/CustomerDetailsHeader";
import Carousel from "react-multi-carousel";
import moment from "moment";
import { useState } from "react";
import ImgModel from "./ImgModel";
import { deleteCustomer } from "../../../Contexts/APIs/Customer/Customer";
import { useNavigate, useParams } from "react-router-dom";
import PhoneInput from "react-phone-input-2";

function CustomerDetails(props) {
  const { customerData, roles } = props;
  const [countryCode, setCountryCode] = useState(null);
  const { customerId } = useParams();
  const navigate = useNavigate();
  const created = customerData?.created_at
      ? moment(customerData?.created_at).format("DD-MM-YYYY HH:mm")
      : "N/A",
    updated = customerData?.updated_at
      ? moment(customerData?.updated_at).format("DD-MM-YYYY HH:mm")
      : "N/A";

  const certificates = customerData?.certificates;

  const certificatesResponsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  const [data, setData] = useState();
  const [open, setOpen] = React.useState(false);

  const breadcrumbs = [
    { path: "/customers", active: true, title: "Customers" },

    {
      active: false,
      title: `${customerData.firstname} ${customerData.lastname} details`,
    },
  ];

  useEffect(()=>{
    setTimeout(() => {
      setCountryCode(customerData.country_code);      
    }, 1000);
  },[customerData])

  return (
    <Stack gap={3}>
      <Grid container item sm={12}>
        <CustomSeparator breadcrumbs={breadcrumbs} />
      </Grid>

      <Stack
        gap="1rem"
        boxShadow="0 2px 15px 0 rgba(0, 0, 0, 0.1)"
        overflow="auto"
      >
        <CustomerDetailsHeader
          data={customerData}
          roles={roles}
        />
        <Stack
          mt="-25px"
          bgcoloe="white"
          padding="53.8px"
          className="details-panel"
          flexDirection="row"
          justifyContent="space-between"
        >
          <Stack gap={2} width="calc(55% - 1.5rem)">
            <Stack bgcolor="rgba(0, 0, 0, 0.01)" borderRadius="10px" p={2}>
              <FormHead text={"Customer info"} />
              <Box position="relative">
                <Grid container gap={1}>
                  <Grid item md={5}>
                    <Stack flexDirection="column" mb="21px">
                      <Typography fontSize="13px" color="#aaa">
                        Customer ID
                      </Typography>
                      <Typography className="details-text-bold">
                        {customerData?.id}
                      </Typography>
                    </Stack>
                    <Stack flexDirection="column" mb="21px">
                      <Typography fontSize="13px" color="#aaa">
                        Customer mobile
                      </Typography>
                      <Typography className="details-text-bold">
                        {/* {customerData?.phone_number || "-"} */}
                        {customerData?.phone_number ? <PhoneInput
                          inputStyle={{
                              border: "none",
                              width: "100%",
                              padding: "0px",
                              direction: "ltr",
                              fontSize: '18px',                      
                              color: '#282828',
                              backgroundColor: '#f9f9f9',
                              wordBreak: 'break-word',
                              fontWeight: 'bold'
                          }}
                          defaultMask={`${countryCode === "" ? '...........' : '... ... ....'}`}
                          value={`${countryCode === "" ? '  ' : customerData.country_code}${customerData.phone_number}`}
                          disabled={true}
                          disableDropdown={true}
                          specialLabel=""
                          placeholder=""
                          disableCountryCode={countryCode === "" ? true : false}
                        /> : "-"}
                      </Typography>
                    </Stack>
                    <Stack flexDirection="column" mb="21px">
                      <Typography fontSize="13px" color="#aaa">
                        Primary language
                      </Typography>
                      <Typography className="details-text-bold">
                        {(typeof customerData?.languages !== "undefined" &&
                          customerData?.languages[0]?.language?.name) ||
                          "-"}
                      </Typography>
                    </Stack>
                    <Stack flexDirection="column" gap={1}>
                      {customerData?.is_verified ? (
                        <Stack className="about-dive-club-wrap">
                          <FormHeadIcon text={"Profile is verified"}>
                            <CheckOutlinedIcon
                              fontSize="1.1rem"
                              style={{ color: "#f6b119" }}
                            />
                          </FormHeadIcon>
                        </Stack>
                      ) : (
                        <Stack className="about-dive-club-wrap">
                          <FormHeadIcon text={"Profile is not verified"}>
                            <CloseOutlinedIcon
                              fontSize="1.1rem"
                              style={{ color: "#e10007" }}
                            />
                          </FormHeadIcon>
                        </Stack>
                      )}
                    </Stack>
                  </Grid>
                  <Grid item md={6}>
                    <Stack flexDirection="column" mb="21px">
                      <Typography fontSize="13px" color="#aaa">
                        Customer email
                      </Typography>
                      <Typography
                        className="details-text-bold"
                        style={{ overflowWrap: "anywhere" }}
                      >
                        {customerData?.email || "-"}
                      </Typography>
                    </Stack>
                    <Stack flexDirection="column">
                      <Typography fontSize="13px" color="#aaa">
                        Date of birth
                      </Typography>
                      <Typography className="details-text-bold">
                        {customerData?.birthdate || "-"}
                      </Typography>
                    </Stack>
                    <Stack flexDirection="column" my="21px">
                      <Typography fontSize="13px" color="#aaa">
                        Gender
                      </Typography>
                      <Typography className="details-text-bold">
                        {customerData?.gender}
                      </Typography>
                    </Stack>
                  </Grid>
                </Grid>
              </Box>
            </Stack>

            {certificates?.length > 0 && (
              <Stack
                bgcolor="rgba(0, 0, 0, 0.01)"
                borderRadius="10px"
                p={2}
                className="customer-carousel"
              >
                <FormHead text={"Certificates"} />
                {certificates?.length >= 1 ? (
                  <Carousel
                    swipeable={true}
                    draggable={true}
                    showDots={false}
                    responsive={certificatesResponsive}
                    autoPlaySpeed={1000}
                    containerClass={"carousel-certificate"}
                    itemClass={"carousel-certificate-item"}
                    partialVisible={true}
                    arrows={false}
                  >
                    {certificates.map((data, index) => {
                      return (
                        <Stack
                          key={index}
                          alignItems="center"
                          justifyContent="center"
                          borderRadius="10px"
                          textAlign="center"
                          onClick={() => {
                            setData(data);
                            setOpen(true);
                          }}
                        >
                          <img
                            src={data?.image}
                            width="100%"
                            height="124px"
                            style={{ borderRadius: "15px" }}
                          />
                          <Typography
                            color="#333132"
                            fontSize="14px"
                            fontWeight="bold"
                            px={2}
                            mt="5px"
                          >
                            {data?.organization_certificate?.name}
                          </Typography>
                          <Typography color="#bebebe" fontSize="14px" px={2}>
                            3rd level diver
                          </Typography>
                        </Stack>
                      );
                    })}
                  </Carousel>
                ) : null}
              </Stack>
            )}

            <ImgModel data={data} open={open} setOpen={setOpen} />
            <Stack
              flexDirection="row"
              gap={5}
              className="details-time details-time-lg"
            >
              <Stack flexDirection="row" gap={1}>
                <Typography color="#333132" className="details-text-bold">
                  Created at :{" "}
                </Typography>
                <Typography color="#333132" className="details-text-bold">
                  {created}
                </Typography>
              </Stack>
              <Stack flexDirection="row" gap={1}>
                <Typography color="#333132" className="details-text-bold">
                  Updated at :{" "}
                </Typography>
                <Typography color="#333132" className="details-text-bold">
                  {updated}
                </Typography>
              </Stack>
            </Stack>
          </Stack>

          <Stack
            width="calc(45% - 1.5rem)"
            bgcolor="rgba(0, 0, 0, 0.01)"
            p={2}
            height="fit-content"
          >
            <FormHead text={"Location info"} />
            <Box position="relative">
              <Stack flexDirection="column" mb="21px">
                <Typography fontSize="13px" color="#aaa">
                  Location
                </Typography>
                <Typography className="details-text-bold">
                  {" "}
                  {!customerData?.area &&
                  !customerData?.city &&
                  !customerData?.country
                    ? "-"
                    : customerData?.area &&
                      customerData?.area?.name +
                        "," +
                        customerData?.city?.name +
                        "," +
                        customerData?.country?.name}
                </Typography>
              </Stack>
            </Box>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}

export default CustomerDetails;

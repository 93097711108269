import React from 'react'
import OrganizationDetails from '../../../Components/Organisms/Organizations/OrganizationDetails'
import DeleteModal from '../../../Components/Molecules/General/Models/DeleteModal';
function OrganizationDetailsTemplate({organizationId, organizationData, supportedLanguages, updateSupportedLang, supportLang,
  openDeleteModal, setOpenDeleteModal, handleDeleteRequest, handleCancelDelete, roles
}) {

  return (
    <>
      <OrganizationDetails 
          organizationId={organizationId}
          organizationData={organizationData} 
          supportedLanguages={supportedLanguages}
          updateSupportedLang={updateSupportedLang}
          supportLang={supportLang}
          setOpenDeleteModal={setOpenDeleteModal}
          roles={roles}
      />
      {
        openDeleteModal && <DeleteModal
            open={openDeleteModal} setOpen={setOpenDeleteModal} 
            handelDeleteRequest={handleDeleteRequest}
            handleCancelDelete={handleCancelDelete}
            customClass='organizations-delete-modal'
            text={[`Delete ${organizationData?.name}`, `Are you sure that you want to delete ${organizationData?.name} ?`]}
        />
      }
    </>
  )
}

export default OrganizationDetailsTemplate
import React from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { changePassword } from "../../../Contexts/APIs/Auth/Changepassword";
import ResetPasswordTemplate from "../../../Templates/Main/Account/ResetPassword.Template";

const ResetPasswordPage = ({ profileData }) => {
  const navigate = useNavigate();
  const ChangePassword = async (user_type, data) => {
    const { res, err } = await changePassword(user_type, data);
    if (res) {
      navigate("/profile");
      toast.success("Password has been changed successfully")
    }
    if (err) {
      toast.error(err);
    }
  };
  return <ResetPasswordTemplate ChangePassword={ChangePassword} />;
};

export default ResetPasswordPage;

import React from 'react'
import Applicants from '../../../Components/Organisms/JobOffers/Applicants';

function ApplicantsTemplate(props) {
    const { filterObj, applicantsList, setApplicants, jobOfferData, count, setCount, page, setPage, handlePageChange, openLoader, setOpenLoader, roles } = props;
    return ( 
        <Applicants
            filterObj={filterObj}
            applicantsList={applicantsList}
            setApplicants={setApplicants}
            jobOfferData={jobOfferData}
            count={count} setCount={setCount}
            page={page} setPage={setPage}
            handlePageChange={handlePageChange}
            openLoader={openLoader}
            setOpenLoader={setOpenLoader}
            roles={roles}
        />
  )
}

export default ApplicantsTemplate
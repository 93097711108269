import React from "react";
import Menu from "@mui/material/Menu";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import SearchIcon from "@mui/icons-material/Search";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import BasicDatePicker from "../../../Atoms/Input/DatePicker/DatePicker.js";
import TextFieldInput from "../../../Atoms/Input/TextField/TextFieldInput";
import {
  Button,
  Divider,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import PrimaryButton from "../../../Atoms/Buttons/Primary/PrimaryButton";
import ClearIcon from "@mui/icons-material/Clear";
import { useState } from "react";
import Filterlist from "./filterlist";
import { handlePageChange } from "../../../../Contexts/APIs/Helper/Helper.js";

const Filter = ({
  filterList,
  filterSearch,
  filterExtra,
  handleApplyFilterMenu,
  handleResetFilterMenu,
  setFilterData,
  setFilterObj,
  handleChange,
}) => {
  const [filtersSelectedOptions, setFiltersSelectedOptions] = useState();

  return (
    <PopupState
      variant="popover"
      popupId="demo-popup-menu"
      style={{ flexWrap: "wrap" }}
    >
      {(popupState) => (
        <React.Fragment>
          <Stack flexDirection="row">
            {filterExtra?.map((item, index) => {
              return (
                <div
                  style={{
                    position: "relative",
                    display: "inline-block",
                    marginInline: "10px",
                    boxShadow: "0px 1px 4px 0px #0000001A",
                    borderRadius: "8px",
                  }}
                  key={index}
                >
                  <BasicDatePicker
                    placeholder={item.placeholder}
                    value={item.extraState}
                    className={item.customClass}
                    setValue={item.setExtraState}
                    setFilterObj={setFilterObj}
                  />
                </div>
              );
            })}
          </Stack>

          <Button
            variant="outlined"
            startIcon={<FilterAltIcon sx={{ color: "#bebebe" }} />}
            sx={{
              width: "100%",
              height: "auto",
              color: "#333132",
              borderRadius: "10px",
              border: "solid 1px #ececec",
              fontSize: "16px",
              fontWeight: 500,
              textTransform: "capitalize",
              lineHeight: "unset",
              margin: "auto",
              padding: "7px 15px 8px",
            }}
            {...bindTrigger(popupState)}
          >
            Filter
          </Button>

          <Menu
            {...bindMenu(popupState)}
            style={{ padding: 0 }}
            id="filter-menu"
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <Paper>
              <Typography px={2} py={1} className="filter-menu-item-head">
                filter by
              </Typography>
              <Divider />
              {filterSearch?.map((data) => {
                return (
                  <Stack px={2} py={1} gap={1} key={data?.key}>
                    <Typography className="filter-menu-item-head">
                      {data?.title}
                    </Typography>
                    <FormControl fullWidth>
                      <TextFieldInput
                        type={"search"}
                        onChange={(e) =>
                          setFiltersSelectedOptions((prev) => {
                            return {
                              ...prev,
                              [data?.key]: e.target.value,
                            };
                          })
                        }
                        placeholder={data?.placeholder}
                        StartAdornment={<SearchIcon />}
                        className={data?.customClass}
                        // value={data?.searchState}
                      />
                    </FormControl>
                  </Stack>
                );
              })}
              {filterList?.map((data, index) => {
                return (
                  <React.Fragment key={data?.key}>
                    <Filterlist
                      data={data}
                      setFiltersSelectedOptions={setFiltersSelectedOptions}
                      filtersSelectedOptions={filtersSelectedOptions}
                      setFilterData={setFilterData}
                      onMenuOpen={data?.onMenuOpen}
                      getList={data?.getList && data?.getList}
                      {...data}
                    />
                  </React.Fragment>
                );
              })}
              <Grid container px={2}>
                <Grid
                  item
                  xs={6}
                  mt={2}
                  mb={2.25}
                  sx={{ paddingInlineEnd: "17px" }}
                >
                  <PrimaryButton
                    text="Reset"
                    classBtn="reset-btn"
                    click={() => {
                      handleChange && handlePageChange(1, handleChange);
                      setFiltersSelectedOptions({});
                      popupState.close();
                      handleResetFilterMenu();
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={6}
                  mt={2}
                  mb={2.25}
                  sx={{ paddingInlineStart: "17px" }}
                >
                  <PrimaryButton
                    text="Apply"
                    classBtn="apply-btn"
                    click={() => {
                      handleChange && handlePageChange(1, handleChange);
                      handleApplyFilterMenu(filtersSelectedOptions);
                      popupState.close();
                    }}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Menu>
        </React.Fragment>
      )}
    </PopupState>
  );
};

export default Filter;

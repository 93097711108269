import { axiosFn, handleError } from "..";
import { makeFilterString } from "../Helper/Helper";
import * as URL from "../URLs";

export async function getIsoCodesList(filter_obj) {
  return await axiosFn("get", URL.IsoCodes + makeFilterString(filter_obj))
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}
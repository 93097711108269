import { Box, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import "../../../../Components/Atoms/Shared/Styles/details-page.css";
import CustomSeparator from "../../../../Components/Atoms/BreadCrumb";
import FormHead from "../../../../Components/Atoms/FormHead/FormHead";
import LanguagesSelect from "../../../../Components/Molecules/Forms/LanguagesSelect";
import OrganizationDetailsHeader from "../../../../Components/Molecules/Organizations/OrganizationDetailsHeader";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from '@mui/icons-material/Close';
import moment from "moment";
import CustomerLevalDetailsHeader from "../../../../Components/Molecules/Levels/detailsHeader";
function FreelancerLevelsDetailsTemplate({
  freelancersLevelsData,
  supportLang,
  supportedLanguages,
  updateSupportedLang,
  roles
}) {
  const navigate = useNavigate();
  let [searchParams] = useSearchParams();

  const id = searchParams.get("id");

  const list = [
    { label: "ID number", value: freelancersLevelsData?.id },
    { label: "Rank", value: freelancersLevelsData?.rank },
    {
      label: "Days worked required",
      value: freelancersLevelsData?.days_worked_required,
    },
    {
      label: "cert customer invitations req",
      value: freelancersLevelsData?.cert_customer_invitations_required,
    },
    {
      label: "Min rating required",
      value: freelancersLevelsData?.min_rating_required,
    },
    {
      label: "Min badges required",
      value: freelancersLevelsData?.min_badges_required,
    },
    {
      label: "Max no of team members",
      value: freelancersLevelsData?.max_no_of_team_members,
    },
    {
      label: "Flat commission reduction amount",
      value: freelancersLevelsData?.flat_commission_reduction_amount,
    },
    {
      label: "Wallet debit limit amount",
      value: freelancersLevelsData?.wallet_debt_limit,
    },
  ];
  const breadcrumbs = [
    { path: "/levels/freelancer", active: true, title: "Freelancer levels" },

    {
      active: false,
      title: "freelancer level details",
    },
  ];
  return (
    <Stack spacing={3}>
      <Grid container item sm={12}>
        <CustomSeparator breadcrumbs={breadcrumbs} />
      </Grid>
      <Grid container item sm={12} className="select-lang-wrap">
        <LanguagesSelect
          value={supportLang}
          supportedLanguages={supportedLanguages}
          onChange={(e) => updateSupportedLang(e, supportedLanguages)}
          LanguagesSelectHead={"Data Language"}
          LanguagesSelectTip={false}
        />
      </Grid>
      <Stack spacing={0.3}>
        <CustomerLevalDetailsHeader
          data={freelancersLevelsData}
          supportLang={supportLang}
          isTitle={false}
          icon={false}
          handleEdit={() =>
            navigate(`/levels/freelancer?type=form&id=${id}&index=1`)
          }
          roles={roles}
        />
        <Stack
          mt="-25px"
          bgcoloe="white"
          padding="53.8px"
          className="details-panel"
          flexDirection="row"
          justifyContent="space-between"
          gap="1rem"
          boxShadow="0 2px 15px 0 rgba(0, 0, 0, 0.1)"
          overflow="auto"
          position="relative"
        >
          <Stack gap={2} width="60%">
            <Stack
              bgcolor="rgba(0, 0, 0, 0.01)"
              borderRadius="10px"
              p={2}
              width="100%"
            >
              <FormHead text={"Level  information"} />
              <Box position="relative">
                <Grid container gap={1}>
                  <Grid item xs={12}>
                    {list?.map((data) => {
                      return (
                        <Grid container gap={"8px 0px"} mb="21px">
                          <Grid item xs={12} md={6}>
                            <Typography
                              fontSize="18px"
                              fontWeight="500"
                              color="#333132"
                            >
                              {data?.label}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} md={5}>
                            <Typography className="details-text-bold">
                              {data?.value}
                            </Typography>
                          </Grid>
                        </Grid>
                      );
                    })}
                    <Grid container gap={"8px 0px"} mb="21px">
                      <Grid item>
                        <Typography
                          fontSize="18px"
                          fontWeight="500"
                          color="#333132"
                        >
                          {
                            freelancersLevelsData?.is_wallet_debit_limit_enabled
                            ? <CheckIcon style={{ color: "#f6b119" }} />
                            : <CloseIcon style={{ color: "#cc4d49" }} />
                          }
                        </Typography>
                      </Grid>
                      <Grid item xs={11}>
                        <Typography
                          fontSize="18px"
                          fontWeight="500"
                          color="#333132"
                        >
                          Enable wallet debit limt
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container gap={"8px 0px"} mb="21px">
                      <Grid item>
                        <Typography
                          fontSize="18px"
                          fontWeight="500"
                          color="#333132"
                        >
                          {
                            freelancersLevelsData?.can_create_team
                            ? <CheckIcon style={{ color: "#f6b119" }} />
                            : <CloseIcon style={{ color: "#cc4d49" }} />
                          }
                        </Typography>
                      </Grid>
                      <Grid item xs={11}>
                        <Typography
                          fontSize="18px"
                          fontWeight="500"
                          color="#333132"
                        >
                          Can create team
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Stack>

            <Stack
              flexDirection="row"
              gap="1rem 3rem"
              padding="1rem 0rem 2rem"
              className="details-time details-time-lg"
            >
              <Stack flexDirection="row" gap={1}>
                <Typography color="#333132" className="details-text-bold">
                  Created at :{" "}
                  {moment(freelancersLevelsData?.created_at).format(
                    "DD-MM-YYYY  HH:mm"
                  )}
                </Typography>
              </Stack>
              <Stack flexDirection="row" gap={1}>
                <Typography color="#333132" className="details-text-bold">
                  Updated at :{" "}
                  {moment(freelancersLevelsData?.updated_at).format(
                    "DD-MM-YYYY  HH:mm"
                  )}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}

export default FreelancerLevelsDetailsTemplate;

import { axiosFn, handleError } from "../index"
import { makeFilterString } from "../Helper/Helper"
import * as URL from "../URLs"

export async function AreaAPI(filter = {}, city_id) {
    return await axiosFn("get", URL.lookups + URL.areas + makeFilterString(filter))
        .then((res) => {
            return {
                res: res.data,
                err: null,
            }
        })
        .catch(handleError)
}

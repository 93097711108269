import { Box, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import "../../../../Components/Atoms/Shared/Styles/details-page.css";
import CustomSeparator from "../../../../Components/Atoms/BreadCrumb";
import FormHead from "../../../../Components/Atoms/FormHead/FormHead";
import LanguagesSelect from "../../../../Components/Molecules/Forms/LanguagesSelect";
import OrganizationDetailsHeader from "../../../../Components/Molecules/Organizations/OrganizationDetailsHeader";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";
import DeleteModal from "../../../../Components/Molecules/General/Models/DeleteModal";
function FAQDetailsTemplate({
  data,
  supportLang,
  supportedLanguages,
  updateSupportedLang,
  openDeleteModal,
  setOpenDeleteModal,
  handleDeleteRequest,
  roles,
}) {
  const navigate = useNavigate();
  let [searchParams] = useSearchParams();
  console.log(data);
  const id = searchParams.get("id");

  const breadcrumbs = [
    {
      path: "/faqs",
      active: true,
      title: "Frequently asked question",
    },

    {
      active: false,
      title: " Frequently asked question details",
    },
  ];
  return (
    <Stack spacing={3}>
      <Grid container item sm={12}>
        <CustomSeparator breadcrumbs={breadcrumbs} />
      </Grid>
      <Grid container item sm={12} className="select-lang-wrap">
        <LanguagesSelect
          value={supportLang}
          supportedLanguages={supportedLanguages}
          onChange={(e) => updateSupportedLang(e, supportedLanguages)}
          LanguagesSelectHead={"Data Language"}
          LanguagesSelectTip={false}
        />
      </Grid>
      <Stack
        sx={{
          boxShadow: "0 2px 15px 0 rgba(0, 0, 0, 0.1)",
          backgroundColor: "#fff",
          borderRadius: "8px",
        }}
      >
        <OrganizationDetailsHeader
          data={{
            name: data?.localized_data
              ? data?.localized_data[`${supportLang}`]["question"]
              : data?.question,
            id: data?.id,
            nfor: data?.for,
          }}
          supportLang={supportLang}
          isTitle={false}
          handleEdit={() => navigate(`/faqs?type=form&id=${id}&index=1`)}
          handleDelete={() => setOpenDeleteModal(id)}
          roles={roles}
        />

        <Stack
          bgcolor="white"
          padding="53.8px"
          className="details-panel"
          flexDirection="row"
          justifyContent="space-between"
          gap="1rem"
          overflow="auto"
          position="relative"
        >
          <Stack gap={2} width="70%">
            <Stack bgcolor="#fbfbfb" borderRadius="20px" p={2}>
              <Box position="relative">
                <Grid container gap={1}>
                  <Grid item xs={8}>
                    <FormHead text={"Answer"} />
                    <Typography color="#333132" className="details-text-bold">
                      {
                        data?.localized_data
                        ? data?.localized_data[`${supportLang}`]["answer"]
                        : data?.answer
                      }
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Stack>
            <Grid container gap={"8px 0px"} my="21px">
              <Stack flexDirection="row" gap={1} alignItems="center">
                <Typography fontSize="18px" fontWeight="500" color="#333132">
                  {data?.is_hidden ? (
                    <CheckIcon fontSize="1.1rem" style={{ color: "#f6b119" }} />
                  ) : (
                    <CloseIcon fontSize="1.1rem" style={{ color: "#e10007" }} />
                  )}
                </Typography>
                <Typography className="details-text-bold" mt="-5px">
                  Is Hidden
                </Typography>
              </Stack>
            </Grid>
            <Stack flexDirection="row" gap="1rem 3rem" padding="1rem 0rem 2rem">
              <Stack flexDirection="row" gap={1}>
                <Typography color="#333132" className="details-text-bold">
                  Created at :
                  {moment(data?.created_at).format("DD-MM-YYYY  HH:mm")}
                </Typography>
              </Stack>
              <Stack flexDirection="row" gap={1}>
                <Typography color="#333132" className="details-text-bold">
                  Updated at :
                  {moment(data?.updated_at).format("DD-MM-YYYY  HH:mm")}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Stack>

      <DeleteModal
        open={openDeleteModal}
        setOpen={setOpenDeleteModal}
        handelDeleteRequest={handleDeleteRequest}
        handleCancelDelete={() => setOpenDeleteModal(false)}
        customClass="organizations-delete-modal"
        text={[
          `Delete ${data?.name}`,
          `Are you sure that you want to delete ${data?.name} ?`,
        ]}
      />
    </Stack>
  );
}

export default FAQDetailsTemplate;

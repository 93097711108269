import { Box, TextField, Heading } from "@mui/material";
import React from "react";
import CFormErrors from "./CFormErrors";
import CFormLabels from "./CFormLabels";

const CTextArea = ({
  label,
  placeholder,
  type = "text",
  name,
  formik,
  ...rest
}) => {
  const multinames = name?.split(".")?.map((data) => data);
  const value =
    multinames?.length == 2
      ? formik?.values?.[multinames[0]]?.[multinames[1]]
        ? formik?.values?.[multinames[0]]?.[multinames[1]]
        : ""
      : formik?.values[name];
  const errorsMsg =
    multinames?.length == 2
      ? formik?.errors?.[multinames[0]]?.[multinames[1]]
        ? formik?.errors?.[multinames[0]]?.[multinames[1]]
        : ""
      : formik.errors[name];
  return (
    <Box mb="5" id="textArea">
      <CFormLabels label={label} />

      <TextField
        sx={{ width: "100%", background: "white" }}
        placeholder={placeholder}
        type={type}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={value}
        name={name}
        multiline
        {...rest}
      />

      <CFormErrors errorsMsg={errorsMsg} />
    </Box>
  );
};

export default CTextArea;

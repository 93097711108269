import React, { useEffect } from "react";
import { useState } from "react";
import {
  Breadcrumbs,
  Button,
  Divider,
  Grid,
  Link,
  Menu,
  Typography,
} from "@mui/material";

import TextFieldInput from "../../../Atoms/Input/TextField/TextFieldInput";
import SearchIcon from "@mui/icons-material/Search";
import NormalTabs from "../../../Atoms/Tabs/NormalTabs";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import SearchableSelect from "../../../Atoms/Select/SearchableSelect.jsx";
import PrimaryButton from "../../../Atoms/Buttons/Primary/PrimaryButton";
import "./ActivitiesHeader.css";
import { getBranchesList } from "../../../../Contexts/APIs/Branches/Branches";
import { getOperatorsList } from "../../../../Contexts/APIs/Operators/Operators";
import { toast } from "react-toastify";
import { useSearchParams } from "react-router-dom";
import { handlePageChange } from "../../../../Contexts/APIs/Helper/Helper.js";

function ActivitiesHeader({
  breadCrumb,
  title,
  subTitle,
  searchInput,
  filterObj,
  setFilterObj,
  options,
  ActivityValue,
  handleActivityValue,
  getActivityType,
  setPage,
  handleChange,
}) {
  const [searchKeywords, setSearchKeywords] = useState("");
  const [diveCenters, setDiveCenters] = useState([]);
  const [diveCenter, setDiveCenter] = useState({});
  const [branches, setBranches] = useState([]);
  const [branch, setBranch] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const [activityStatus, setActivityStatus] = useState({});
  const [searchParams, setSearchParams] = useSearchParams();
  const pageNumber = searchParams.get("page_number") || 1;
  const [diveCentersCount, setDiveCentersCount] = useState(0);
  const [branchesCount, setBranchesCount] = useState(0);

  const activityStatusList = [
    { id: 1, name: "Active" },
    { id: 2, name: "Deactivate" },
  ];

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const getKeywords = (keyword, type) => {
    setSearchKeywords({ keyword, type });
  };
  useEffect(() => {
    const timeId = setTimeout(() => {
      setFilterObj &&
        searchKeywords?.type &&
        setFilterObj((data) => {
          return {
            ...data,
            page_number: searchKeywords?.keyword?.length == 0 ? pageNumber : 1,
            keyword: searchKeywords?.keyword?.trim(),
          };
        });
    }, 500);
    setPage(1);
    return () => clearTimeout(timeId);
  }, [searchKeywords]);

  const getDiveCenters = async (filter) => {
    const prevDiveCenters = diveCenters;
    const DataKey = filter?.keyword;
    if (
      (filter?.page_number === 1 || filter?.page_number === undefined) &&
      !DataKey &&
      diveCenters.length === 10
    ) {
      setDiveCenters(prevDiveCenters);
    } else {
      const { res, error } =
        diveCentersCount !== 10 || DataKey
          ? await getOperatorsList({ page_size: 10, ...filter })
          : {};
      if (res) {
        filter?.page_number === 1 || filter?.page_number === undefined
          ? setDiveCenters(res?.data?.dive_centers)
          : setDiveCenters((prev) => [...prev, ...res?.data?.dive_centers]);
        setDiveCentersCount(res?.extra?.total_count);
      }
    }
  };

  const getBranches = async (filter) => {
    const prevBranches = branches;
    const DataKey = filter?.keyword;
    if (
      (filter?.page_number === 1 || filter?.page_number === undefined) &&
      !DataKey &&
      !filter?.dive_center_id
    ) {
      setBranches(prevBranches);
    } else {
      const { res, error } =
        branchesCount !== 10 || DataKey
          ? await getBranchesList({
              page_size: 10,
              page_number: 1,
              dive_center_id: filter?.dive_center_id,
              ...filter,
            })
          : {};
      if (res) {
        filter?.page_number === 1 || filter?.page_number === undefined
          ? setBranches(res?.data?.branches)
          : setBranches((prev) => [...prev, ...res?.data?.branches]);
        setBranchesCount(res?.extra?.total_count);
      }
    }
  };

  const handleApplyFilterMenu = () => {
    if (from < 0 || to < 0) {
      toast.error("Price range can't be negative number");
    } else {
      handleChange && handlePageChange(1, handleChange);
      setFilterObj({
        ...filterObj,
        page_number: 1,
        dive_center_id: diveCenter?.value,
        branch_id: branch?.value,
        is_available: activityStatus?.available,
        "price[from]": from,
        "price[to]": to,
      });
      handleClose();
      setPage(1);
    }
  };

  const handleResetFilterMenu = () => {
    handleChange && handlePageChange(1, handleChange);
    setDiveCenter({});
    setBranch({});
    setActivityStatus({});
    setFrom(null);
    setTo(null);
    setFilterObj({ page_number: 1 });
    handleClose();
    setPage(1);
  };

  return (
    <Grid
      container
      item
      lg={12}
      style={{ borderBottom: "1px solid #d9d9d9" }}
      sx={{ pb: 1, mb: 2 }}
      gap={"16px 0px"}
      className="activity-head"
    >
      {breadCrumb && (
        <Grid item sm={12}>
          <Breadcrumbs aria-label="breadcrumb" separator="›">
            <Link color="inherit" href={breadCrumb.path}>
              {breadCrumb.link}
            </Link>
            <Typography>{breadCrumb.text}</Typography>
          </Breadcrumbs>
        </Grid>
      )}
      <Grid container item xs={12}>
        <Grid
          container
          item
          xs={12}
          lg={8}
          flexDirection="row"
          justifyContent="start"
          gap={"1rem 2rem"}
          alignItems="center"
        >
          <Grid item lg={"auto"}>
            <Typography
              component="h1"
              style={{
                fontSize: "30px",
                fontWeight: "bold",
                textAlign: "left",
                color: "#333132",
              }}
            >
              {title}
            </Typography>
          </Grid>
          {subTitle && (
            <Grid
              item
              lg={"auto"}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Typography
                component="p"
                style={{
                  fontSize: "18px",
                  textAlign: "left",
                  color: "#bebebe",
                }}
              >
                {subTitle}
              </Typography>
            </Grid>
          )}

          <Grid item lg={"auto"}>
            <NormalTabs
              TabsOptions={options}
              value={ActivityValue}
              change={handleActivityValue}
              ContainerStyle="switch-container"
              TabStyle="switch-btn mb-20px"
              onclick={(value) => getActivityType(value)}
            />
          </Grid>
        </Grid>

        <Grid
          container
          item
          xs={12}
          lg={4}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Grid item lg={9}>
            {searchInput && (
              <TextFieldInput
                type={searchInput.type}
                onChange={(e) => {
                  handleChange && handlePageChange(1, handleChange);
                  getKeywords(e.target.value, e?.type);
                }}
                value={searchKeywords?.keyword}
                placeholder={searchInput.placeholder}
                StartAdornment={<SearchIcon />}
                className={searchInput.className}
              />
            )}
          </Grid>

          <Grid item lg={"auto"} display={"flex"}>
            <Button
              aria-label="filter"
              id="filter-button"
              aria-controls={Boolean(anchorEl) ? "filter-menu" : undefined}
              aria-expanded={Boolean(anchorEl) ? "true" : undefined}
              aria-haspopup="true"
              variant="outlined"
              startIcon={<FilterAltIcon sx={{ color: "#bebebe" }} />}
              sx={{
                width: "100%",
                height: "auto",
                color: "#333132",
                borderRadius: "10px",
                border: "solid 1px #ececec",
                fontSize: "16px",
                fontWeight: 500,
                textTransform: "capitalize",
                lineHeight: "unset",
                margin: "auto",
                padding: "7px 15px 8px",
              }}
              onClick={handleClick}
            >
              Filter
            </Button>
            <Menu
              id="filter-menu"
              MenuListProps={{
                "aria-labelledby": "filter-button",
              }}
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              PaperProps={{
                style: {
                  width: "330px",
                },
              }}
            >
              <Typography
                sx={{
                  paddingInlineStart: "16px",
                  fontSize: "16px",
                  fontWeight: 500,
                  mt: 1,
                }}
              >
                Filter by
              </Typography>
              <Divider sx={{ width: "100%", margin: "12px 0" }} />
              <Grid container sx={{ padding: "0 16px" }}>
                <Grid item xs={12}>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: 500,
                    }}
                  >
                    Dive center:
                  </Typography>
                </Grid>
                <Grid item xs={12} mb={2}>
                  <SearchableSelect
                    name="dive_center_id"
                    options={diveCenters}
                    getList={getDiveCenters}
                    count={diveCentersCount}
                    label="Select option"
                    className="searchable-select"
                    onChange={(val) => {
                      setDiveCenter(val);
                      setBranch(null);
                      setBranches([]);
                    }}
                    value={diveCenter}
                    onClear={() => {
                      setDiveCenter(null);
                      setBranch(null);
                      setBranches([]);
                    }}
                    onMenuOpen={() =>
                      diveCenters.length === 0 && getDiveCenters()
                    }
                  />
                </Grid>

                <Grid item xs={12}>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: 500,
                    }}
                  >
                    Branch
                  </Typography>
                </Grid>
                <Grid item xs={12} mb={2}>
                  <SearchableSelect
                    name="branch_id"
                    options={branches}
                    getList={getBranches}
                    count={branchesCount}
                    label="Select option"
                    className="searchable-select"
                    onChange={(val) => setBranch(val)}
                    value={branch}
                    onClear={() => setBranch(null)}
                    moreFilterData={{ dive_center_id: diveCenter?.value }}
                    onMenuOpen={() => {
                      branches.length === 0 &&
                        diveCenter?.value &&
                        getBranches({
                          page_number: 1,
                          dive_center_id: diveCenter.value,
                        });
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: 500,
                    }}
                  >
                    Price range (in USD)
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <TextFieldInput
                    placeholder="Min"
                    type="number"
                    className="reservation-search"
                    value={from ? from : ""}
                    onChange={(e) => setFrom(e.target.value)}
                    style={{ paddingInlineEnd: "4px" }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextFieldInput
                    placeholder="Max"
                    type="number"
                    className="reservation-search"
                    value={to ? to : ""}
                    onChange={(e) => setTo(e.target.value)}
                    style={{ paddingInlineStart: "4px" }}
                  />
                </Grid>

                <Grid item xs={12} mt={2}>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: 500,
                    }}
                  >
                    Status:
                  </Typography>
                </Grid>
                <Grid item xs={12} mb={2}>
                  <SearchableSelect
                    name="account_status_id"
                    options={activityStatusList}
                    label="Select option"
                    className="searchable-select"
                    onChange={(val) =>
                      setActivityStatus({
                        ...val,
                        available:
                          val?.label === "Deactivate" ? "false" : "true",
                      })
                    }
                    onClear={() => setActivityStatus({})}
                    value={activityStatus}
                  />
                </Grid>

                <Grid
                  item
                  xs={6}
                  mt={2}
                  mb={2.25}
                  sx={{ paddingInlineEnd: "10px" }}
                >
                  <PrimaryButton
                    text="Reset"
                    classBtn="reset-btn"
                    click={handleResetFilterMenu}
                  />
                </Grid>
                <Grid
                  item
                  xs={6}
                  mt={2}
                  mb={2.25}
                  sx={{ paddingInlineStart: "10px" }}
                >
                  <PrimaryButton
                    text="Apply"
                    classBtn="apply-btn"
                    click={() => handleApplyFilterMenu()}
                  />
                </Grid>
              </Grid>
            </Menu>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default ActivitiesHeader;

import React from 'react'
import ApplicantDetails from '../../../Components/Organisms/JobOffers/ApplicantDetails'

function ApplicantDetailsTemplate({applicantData, jobOfferId}) {
  return (
    <ApplicantDetails
      applicantData={applicantData} 
      jobOfferId={jobOfferId}
    />
  )
}

export default ApplicantDetailsTemplate
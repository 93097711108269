import { axiosFn, handleError } from "..";
import { makeFilterString } from "../Helper/Helper";
import * as URL from "../URLs";

export async function getOperatorsList(filter_obj) {
  return await axiosFn("get", URL.Operators + makeFilterString(filter_obj))
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}
export async function getSalesmenList(filter_obj) {
  return await axiosFn("get", URL.Salesmen + makeFilterString(filter_obj))
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}

export async function getSalesmanReservations(filter_obj) {
  return await axiosFn(
    "get",
    URL.SalesmanReservations + makeFilterString(filter_obj)
  )
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}

export async function getOperatorDetails(operator_id) {
  return await axiosFn("get", `${URL.Operators}/${operator_id}`)
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}

export async function updateRejectionReason(operator_id, data) {
  return await axiosFn("put", `${URL.Operators}/${operator_id}`, data)
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}

export async function createOperator(data) {
  return await axiosFn("post", URL.Operators, data)
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}

export async function approveOperator(id, data) {
  return await axiosFn("put", `${URL.Operators}/${id}`, data)
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}

export async function updateOperator(id, data) {
  return await axiosFn("put", `${URL.Operators}/${id}`, data)
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}

export async function deleteOperator(id) {
  return await axiosFn("delete", `${URL.Operators}/${id}`)
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}

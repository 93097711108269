import React from "react";
import { getCustomerDetails } from "../../../Contexts/APIs/Customer/Customer";
import Loader from "../../../Components/Atoms/Loader/Loader";
import CustomerDetailsTemplate from "../../../Templates/Main/Customers/CustomerDetails.Template";
import { useState } from "react";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";

function CustomerDetailsPage({roles}) {
  const { customerId } = useParams();
  const [customerData, setCustomerData] = useState({});
  const [openLoader, setOpenLoader] = useState(false);

  const getCustomer = async (id) => {
    setOpenLoader(true);
    const { res, err } = await getCustomerDetails(id);
    if (res) {
      setCustomerData(res?.data?.user);
    }
    if (err) {
      toast.error(err);
    }
    setOpenLoader(false);
  };

  useEffect(() => {
    getCustomer(customerId);
  }, []);

  return (
    <>
      <Loader open={openLoader} />
      <CustomerDetailsTemplate customerData={customerData} roles={roles} />
    </>
  );
}

export default CustomerDetailsPage;

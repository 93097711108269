import { Box, IconButton, Typography } from "@mui/material";
import React, { useState } from "react";
import Select, { StylesConfig } from "react-select";
import ClearIcon from "@mui/icons-material/Clear";
import "./SearchableSelect.css";
import { useEffect } from "react";

const SearchableSelect = ({
  options,
  onChange,
  onClear,
  value,
  label,
  className,
  error,
  id,
  name,
  defaultValue,
  onMenuOpen,
  getList,
  count,
  moreFilterData,
  customStyles,
  disabled=false
}) => {
  const [useEffectTog, setUseEffectTog] = useState("default");
  const [keyword, setKeyword] = useState(null);

  const handelOptions = options?.map((val) => {
    return {
      label: val?.name,
      value: val?.id,
      color: val?.color,
    };
  });

  // colorStyles using to add custom color to each option in list
  // every option must has color property to custom it
  const colorStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.data.color,
      backgroundColor: state.isSelected ? '#f6b119' : 'inherit' ,
      '&:hover': { backgroundColor: state.isSelected ? '#f6b119' : '#fff2c7' },
    }),
  };
  
  const ClearIndicator = () => {
    return (
      <IconButton
        btnType="icon"
        onClick={() => {
          setUseEffectTog("clear");
          setKeyword(null);
          onClear();
        }}
        fullwidth="true"
        style={{
          position: "absolute",
          top: "calc(50% - 10px)",
          right: "35px",
          padding: "0",
          zIndex: "99",
          width: "20px",
        }}
      >
        <ClearIcon
          sx={{
            color: "hsl(0, 0%, 80%) !important",
            fontSize: "20px !important",
            "&:hover": { color: "hsl(0, 0%, 60%) !important" },
            "&:focus": { color: "hsl(0, 0%, 20%) !important" },
          }}
        />
      </IconButton>
    );
  };

  const handleMenuScrollToBottom = () => {
    if (options?.length < count) {
      getList({
        ...moreFilterData,
        page_number: Math?.floor(options?.length / 10) + 1,
      });
    }
  };

  useEffect(() => {
    const timeId = setTimeout(() => {
      if (getList && useEffectTog !== "default") {
        getList({ keyword: keyword, page_number: 1, ...moreFilterData });
        setUseEffectTog("default");
      }
    }, 700);
    return () => {
      clearTimeout(timeId);
    };
  }, [keyword, moreFilterData]);

  return (
    <>
      <Box
        sx={{
          p: 0,
          m: 0,
          boxShadow: "none",
          border: "none",
          position: "relative",
        }}
      >
        <Select
          id={id}
          onMenuOpen={() => onMenuOpen && onMenuOpen()}
          // onMenuOpen={onMenuOpen && onMenuOpen}
          onMenuScrollToBottom={handleMenuScrollToBottom}
          name={name}
          className={`${className} ${error && "select-error"}`}
          options={handelOptions}
          onChange={onChange}
          value={value?.label ? value : null}
          placeholder={label}
          defaultValue={defaultValue}
          loadingMessage={() => <p>loading...</p>}
          onKeyDown={(e) => e.stopPropagation()}
          disabled={disabled}
          styles={{
            ...customStyles,
            ...handelOptions[0]?.color === undefined && colorStyles,
          }}
            onInputChange={(e) => {
            if (e.length !== 0) {
              setUseEffectTog("search");
              setKeyword(e);
            }
          }}
        />
        {value?.label ? <ClearIndicator /> : null}
      </Box>
      {error && (
        <Typography
          component={"p"}
          className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium MuiFormHelperText-contained css-1sb723f-MuiFormHelperText-root"
        >
          {label} is Required
        </Typography>
      )}
    </>
  );
};
export default SearchableSelect;

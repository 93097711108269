import React, { useEffect, useState } from "react";
import Loader from "../../../../Components/Atoms/Loader/Loader";
import { useNavigate } from "react-router-dom";
import CurrencyTemplate from "../../../../Templates/Main/PredefinedLookups/Currency/Currency.template";
import {
  CurrencyAPI,
  getCurrencyDetails,
  updateCurrency,
} from "../../../../Contexts/APIs/Lookups/Currency";
import { Box, CircularProgress, Stack } from "@mui/material";
import SelectBox from "../../../../Components/Atoms/Select/Select";
import { getCountryList } from "../../../../Contexts/APIs/Country/Country";
import usePageFilter from "../../../../Components/Atoms/Hooks/usePageFilter";
import { handlePageChange } from "../../../../Contexts/APIs/Helper/Helper";


function CurrencyPage({roles}) {
  const navigate = useNavigate();
  // let [searchParams] = useSearchParams();
  const {
    page,
    filterObj,
    handlePageChange: onPageChange,
    setFilterObj,
    setPage,
    searchParams,
  } = usePageFilter();
  const id = searchParams.get("id");
  const index = searchParams.get("index");
  const type = searchParams.get("type");

  const [openLoader, setOpenLoader] = useState(false);
  const [currencyList, setCurrencyList] = useState([]);

  const [count, setCount] = useState(0);
  // const [page, setPage] = useState(1);

  // const [filterObj, setFilterObj] = useState(null);

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [currencyData, setCurrencyData] = useState({});
  const [countriesList, setCountriesList] = useState([]);
  const [isLoading, setLoading] = useState({
    index: null,
    status: false,
    type: "",
  });
  const [countriesCount, setCountriesCount] = useState(0);

  // const updatePagination = (page) => {
  //   setFilterObj({ ...filterObj, page_number: page });
  // };

  // const handlePageChange = (event, value) => {
  //   setPage(value);
  //   updatePagination(value);
  // };

  const getCurrencies = async () => {
    setOpenLoader(true);
    const defaultFilterObj = {
      page_size: 10,
      page_number: 1,
      keyword: "",
      sort_by: "id",
    };
    const { res, error } = await CurrencyAPI({
      ...defaultFilterObj,
      ...filterObj,
    });
    if (res) {
      setOpenLoader(false);
      setCurrencyList(res?.data?.currencies);
      setCount(res?.extra?.total_count);
    }
    if (error) {
      setOpenLoader(false);
    }
  };

  const getCurrencyData = async () => {
    setOpenLoader(true);
    const { res, error } = await getCurrencyDetails(id);
    if (res) {
      setCurrencyData(res.data.currency);
      setOpenLoader(false);
    }
    if (error) {
      setOpenLoader(false);
    }
  };

  useEffect(() => {
    if (filterObj) getCurrencies({});
  }, [filterObj]);

  useEffect(() => {
    id && getCurrencyData({});
  }, [id]);

  const rowData = (data) => {
    setSelectedRow(data?.rowData);
  };

  const mappedData = currencyList?.map((data, index) => {
    return {
      ...data,
      is_available_btn: (
        <Stack
          sx={{
            background: data?.is_available ? "#62c08b" : "#cc4d49",
            color: "white",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "10px",
            padding: "10px",
            height: "50px",
            width: "80px",
            cursor: roles?.can_read && roles?.can_update ? "pointer" : "default",
          }}
          onClick={() => {
            if(roles?.can_read && roles?.can_update){
              const action = (index) => {
                setLoading({ index: index, status: true, type: "isAvailable" });

                let newList = currencyList;
                newList[index].is_available = !data?.is_available;
                setCurrencyList(() => newList);
                setTimeout(() => {
                  setLoading({ index: null, status: false });
                }, 300);
              };
              updateCurrency({
                id: data?.id,
                data: {
                  currency: { ...data, is_available: !data?.is_available },
                },
                action: () => action(index),
              });
            }
          }}
        >
          {isLoading?.status &&
          isLoading?.index == index &&
          type == "isAvailable" ? (
            <CircularProgress />
          ) : data?.is_available ? (
            "Yes"
          ) : (
            "No"
          )}
        </Stack>
      ),
      is_payout_currency_btn: (
        <Stack
          sx={{
            background: data?.is_payout_currency ? "#62c08b" : "#cc4d49",
            color: "white",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "10px",
            padding: "10px",
            height: "50px",
            width: "80px",
            cursor: roles?.can_read && roles?.can_update ? "pointer" : "default",
          }}
          onClick={() => {
            if(roles?.can_read && roles?.can_update){
              const action = (index) => {
                setLoading({ index: index, status: true, type: "isPayout" });

                let newList = currencyList;
                newList[index].is_payout_currency = !data?.is_payout_currency;
                setCurrencyList(() => newList);
                setTimeout(() => {
                  setLoading({ index: null, status: false, type: "isPayout" });
                }, 300);
              };
              updateCurrency({
                id: data?.id,
                data: {
                  currency: {
                    ...data,
                    is_payout_currency: !data?.is_payout_currency,
                  },
                },
                action: () => action(index),
              });
            }
          }}
        >
          {isLoading?.status &&
          isLoading?.index == index &&
          type == "isPayout" ? (
            <CircularProgress />
          ) : data?.is_payout_currency ? (
            "Yes"
          ) : (
            "No"
          )}
        </Stack>
      ),
      action: roles?.can_read && roles?.can_update ? (
        <Box
          sx={{ cursor: "pointer" }}
          onClick={() => navigate(`?type=form&id=${data?.id}&index=${index}`)}
        >
          Edit
        </Box>
      ) : null,
    };
  });

  const getCountry = async(data) => {
    setOpenLoader(true);
    const prevCountries = countriesList;
    const DataKey = data?.keyword;
    if((data?.page_number === 1 || data?.page_number === undefined) && !DataKey && countriesList.length === 10){
      setCountriesList(prevCountries);      
    }else{
      const { res, error } = (countriesCount !== 10 || DataKey)? await getCountryList({ page_size: 10, ...data }) : {};
      if (res) {      
        (data?.page_number == 1 || data?.page_number == undefined)
        ? setCountriesList(res?.data?.countries)
        : setCountriesList((s) => [...s, ...res?.data?.countries]);
        setCountriesCount(res?.extra?.total_count);
      }
    }
    setOpenLoader(false);
  };

  const headerChildren = () => {
    return (
      <>
        <Box width="200px">
          <SelectBox
            items={[
              { id: 0, name: "All" },

              { id: true, name: "Yes" },
              { id: false, name: "No" },
            ]}
            onchange={(e) => {
              onPageChange && handlePageChange(1, onPageChange);
              setFilterObj({
                ...filterObj,
                is_payout_currency: e.target.value,
              });
            }}
            title="is payout currency"
            value={filterObj?.["is_payout_currency"]}
            label="Notification type"
          />
        </Box>
        <Box width="200px">
          <SelectBox
            items={[
              { id: 0, name: "All" },

              { id: true, name: "Yes" },
              { id: false, name: "No" },
            ]}
            title="is available"
            onchange={(e) => {
              onPageChange && handlePageChange(1, onPageChange);
              setFilterObj({ ...filterObj, is_available: e.target.value });
            }}
            value={filterObj?.["is_available"]}
            label="Notification type"
          />
        </Box>
      </>
    );
  };

  const breadcrumbs = [
    {
      active: true,
      path: "/predefined-lookups/currency",
      title: "Currency",
    },

    { active: false, title: id ? "Edit currency" : "Add new currency" },
  ];
  return (
    <>
      <Loader open={openLoader} />
      <CurrencyTemplate
        filterObj={filterObj}
        setFilterObj={setFilterObj}
        data={mappedData}
        count={count}
        paginationPage={page}
        setPaginationPage={setPage}
        handlePageChange={onPageChange}
        openDeleteModal={openDeleteModal}
        setOpenDeleteModal={setOpenDeleteModal}
        rowData={rowData}
        details={currencyData}
        headerChildren={headerChildren()}
        countriesList={countriesList}
        getCountry={getCountry}
        countriesCount={countriesCount}
        breadcrumbs={breadcrumbs}
        roles={roles}
      />
    </>
  );
}

export default CurrencyPage;

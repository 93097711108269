import React, { useEffect, useState } from "react";
import CCrud from "../../../../Components/Molecules/General/Crud/CCrud";
import "../../../../Components/Atoms/Shared/Styles/list-page.css";
import { Divider, Grid, Stack, Typography } from "@mui/material";
import "./style.css";
import CustomSeparator from "../../../../Components/Atoms/BreadCrumb";
import { useNavigate, useParams } from "react-router-dom";
import ReservationFilter from "../../../../Components/Molecules/Operators/Reservaions/Filter";
import moment from "moment";
const SalesmanReservationsTemplate = ({
  setFilterObj,
  data,
  count,
  paginationPage,
  setPaginationPage,
  handlePageChange,
  rowData,
  branchesList,
  salesmanList,
  getSalesmanList,
  getBranches,
}) => {
  const { operatorId, salesmanId } = useParams();
  const navigate = useNavigate();

  const filterMenus = [
    {
      head: "From",
      type: "date",
      handleFilter: (val) =>
        setFilterObj((s) => {
          console.log(val, 'date picker');
          console.log(s, 'date picker');
          return { ...s, from_date: val == 'Invalid Date' ? null : val };
        }),
    },
    {
      head: "To",
      type: "date",
      handleFilter: (val) =>
        setFilterObj((s) => {
          return { ...s, to_date: val == 'Invalid Date' ? null : val  };
        }),
    },

    {
      head: "All Branches",
      dataList: [{ id: 0, name: "All" }, ...branchesList],
      handelClick: () => getBranches({ dive_center_id: operatorId }),

      handleFilter: (val) =>
        setFilterObj((s) => {
          return { ...s, branch_id: val };
        }),
    },
    {
      head: "Salesman",
      dataList: [{ id: "All", name: "All" }, ...salesmanList],
      handelClick: () => { },
      defaultValue: salesmanId,
      handleFilter: (val) => {
        navigate(`/operators/salesman/${operatorId}/reservation/${val}`);
      },
    },
    {
      head: "Status",
      dataList: [
        {
          name: "pending",
          id: 0,
        },
        { name: "confirmed", id: 1 },

        { name: "cancelled", id: 2 },
      ],
      handleFilter: (val) =>
        setFilterObj((s) => {
          return { ...s, status: val };
        }),
    },
  ];

  const headerChildren = () => {
    return (
      <Grid container sx={12} gap={1} justifyContent="end">
        <Divider width="100%" sx={{ mb: 2 }} />
        {filterMenus &&
          filterMenus.map((filterMenu, index) => {
            return (
              <Grid item className="filter-menu-wrap" key={index}>
                <ReservationFilter
                  head={filterMenu.head}
                  dataList={filterMenu.dataList}
                  handleFilter={filterMenu?.handleFilter}
                  type={filterMenu?.type}
                  defaultValue={filterMenu?.defaultValue}
                  handleClickFun={
                    filterMenu?.handelClick &&
                    filterMenu.dataList?.length <= 1 &&
                    filterMenu?.handelClick
                  }
                />
              </Grid>
            );
          })}
      </Grid>
    );
  };
  const breadcrumbs = [
    { active: true, path: "/operators/main", title: "Operators" },
    {
      active: true,
      title: "Salesmen",
      path: `/operators/salesman/${operatorId}`,
    },
    { active: false, title: "Reservations" },
  ];
  return (
    <Stack>
      <CustomSeparator breadcrumbs={breadcrumbs} />
      <CCrud
        table={{
          list: data,
          openDeleteModal: false,
          setOpenDeleteModal: () => { },
          rowData: rowData,
          listTitle: <Typography width="400px">Reservations</Typography>,
          emptyListMsg: "There are no Reservations",
          childrenType: "custom",
          headerChildren: headerChildren(),
          columns: [
            { accessor: "id", Header: "No." },
            {
              accessor: "participants.name",
              Header: "Customer name",
            },
            {
              accessor: "inhouse_activity_group.inhouse_activity.name",
              Header: "Activity Name",
            },
            { accessor: "activityDate", Header: "Activity Date" },
            {
              accessor: "inhouse_activity_group.inhouse_activity.branch.name",
              Header: "Branch",
            },
            { accessor: "date", Header: "Reservation date &Time" },
            { accessor: "reservation_number", Header: "Reservation number" },
            { accessor: "reserved_number_of_spots", Header: "No of Seat" },
            { accessor: "discount_percentage", Header: "Discount" },
            { accessor: "salesman.name", Header: "Salesman name" },
            { accessor: "total_amount", Header: "Total price" },
            { accessor: "status", Header: "Status" },
          ],
          isFilter: false,
          isSearch: true,
          isChecked: false,
          isSort: false,
          total_count: count,
          setFilterObj,
          createBtnFun: () => { },
          className: "organization-table",
          paginationPage: paginationPage,
          setPaginationPage: setPaginationPage,
          handlePageChange,
          searchInputPlaceholder: "Search reservation no",
          searchAttribute: "reservation_number",
        }}
      />
    </Stack>
  );
};

export default SalesmanReservationsTemplate;

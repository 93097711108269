import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  editSystemConfig,
  getSystemConfigDetails,
  getSystemConfigsList,
} from "../../../../Contexts/APIs/SystemConfigurations/SystemConfigurations";
import SystemConfigurationsTemplate from "../../../../Templates/Main/SystemConfigurations/SystemConfiguration/SystemConfigurations.Template";
import { toast } from "react-toastify";
import Loader from "../../../../Components/Atoms/Loader/Loader";
import { Box } from "@mui/material";

const SystemConfigurationsPage = ({ roles }) => {
  const navigate = useNavigate();
  let searchParams = new URLSearchParams(window.location.search);
  const [pageNumber, setPageNumber] = useState(
    () => +searchParams.get("page_number") || 1
  );
  // let [searchParams] = useSearchParams();
  const index = searchParams.get("index");
  const systemConfigId = searchParams.get("id");

  const [openLoader, setOpenLoader] = useState(false);
  const [systemConfigsList, setSystemConfigsList] = useState([]);

  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);

  const [filterObj, setFilterObj] = useState(null);

  const [selectedRow, setSelectedRow] = useState({});
  const [systemConfig, setSystemConfig] = useState({});

  const mappedData = systemConfigsList?.map((data, index) => {
    console.log(data?.value?.length);
    return {
      ...data,
      system_id: <div style={{ width: "50px !important" }}>{data?.id}</div>,
      system_key: <div style={{ width: "280px" }}>{data?.key}</div>,
      system_value: (
        <div
          style={{
            width: data?.value?.length > 20 ? "max-content" : "120px",
            maxWidth: "380px",
          }}
        >
          {data?.value}
        </div>
      ),
      system_unit: <div style={{ width: "105px" }}>{data?.unit}</div>,
      system_describtion: (
        <div style={{ width: "400px" }}>{data?.description}</div>
      ),
      action:
        roles?.can_read && roles?.can_update ? (
          <Box
            sx={{ cursor: "pointer", textDecoration: "underline" }}
            onClick={() =>
              navigate(`?type=form&id=${data?.key}&index=${index}`)
            }
          >
            Edit
          </Box>
        ) : null,
    };
  });

  const handlePageChange = (newPage) => {
    setPageNumber(newPage); // Update the state immediately
  };

  useEffect(() => {
    if (!pageNumber) {
      setPage(1);
      setFilterObj({ ...filterObj, page_number: 1 });
    } else {
      setPage(+pageNumber);
      setFilterObj({ ...filterObj, page_number: pageNumber });
    }
  }, [pageNumber]);

  const rowData = (data) => {
    setSelectedRow(data?.rowData);
  };

  const getSystemConfig = async () => {
    const { res, err } = await getSystemConfigDetails(systemConfigId);
    if (res) {
      setSystemConfig(res?.data?.system_configuration);
    }
    if (err) {
      toast.error(err);
    }
  };

  const getSystemConfigs = async (filterObj) => {
    setOpenLoader(true);
    const defaultFilterObj = {
      page_size: 10,
      page_number: 1,
      keyword: "",
      sort_by: "id",
    };
    const { res, error } = await getSystemConfigsList({
      ...defaultFilterObj,
      ...filterObj,
    });
    if (res) {
      setOpenLoader(false);
      setSystemConfigsList(res?.data?.system_configurations);
      setCount(res?.extra?.total_count);
    }
    if (error) {
      setOpenLoader(false);
    }
  };

  const handleSubmit = (data, formik) => {
    const action = () => {
      searchParams.delete("id");
      searchParams.delete("type");
      searchParams.delete("index");
      formik?.resetForm();
      navigate(`/system-configurations/main`);
    };
    const { contract, ...formData } = data;
    if (index) {
      const { res, err } = editSystemConfig(
        systemConfigId,
        {
          system_configuration:
            data?.key === "dive_center_contract"
              ? { ...formData, value: data?.contract }
              : { ...data },
        },
        action
      );
    }
  };

  useEffect(() => {
    systemConfigId && getSystemConfig(systemConfigId);
  }, [systemConfigId]);

  useEffect(() => {
    if (filterObj) getSystemConfigs(filterObj);
  }, [filterObj]);
  return (
    <>
      <Loader open={openLoader} />
      <SystemConfigurationsTemplate
        setFilterObj={setFilterObj}
        data={mappedData}
        count={count}
        paginationPage={page}
        setPaginationPage={setPage}
        handlePageChange={handlePageChange}
        handleSubmit={handleSubmit}
        rowData={rowData}
        systemConfig={systemConfig}
        roles={roles}
      setPage={setPage}
      />
    </>
  );
};

export default SystemConfigurationsPage;

import { Stack } from "@mui/material";
import CTable from "../Table/CTable";
import { useState, useEffect } from "react";
import InfoModal from "../../../Molecules/General/Models/InfoModal";

function TableUse({
  isChecked = false,
  headerChildren,
  total_count,
  columnsData = [],
  list = [{ id: 0 }],
  // setSelectedRow,
  listTitle,
  emptyListMsg,
  searchAttribute,
  isSort,
  isSearch,
  isFilter,
  setFilterObj,
  tableData,
  createBtnTitle,
  createBtnFun,
  CreateBtnIcon,
  hideCreateBtn,
  createBtnClass,
  createBtnDisable,
  filterList,
  className,
  tableContainerOverflow,
  paginationPage,
  setPaginationPage,
  searchInputPlaceholder,
  handleApplyFilterMenu,
  handleResetFilterMenu,
  handlePageChange,
  childrenType,
  setPage
}) {
  const [checked, setChecked] = useState([false]);

  const viewData = (data) => {
    tableData((prevData) => {
      return {
        ...prevData,
        rowData: data,
        checkedData: checked,
      };
    });
  };

  useEffect(() => {
    tableData((prevData) => {
      return {
        ...prevData,
        checkedData: checked,
      };
    });
  }, [checked]);

  const columns = columnsData;

  return (
    <Stack>
      <CTable
        className={className}
        tableContainerOverflow={tableContainerOverflow}
        selectedData={viewData}
        // setSelectedRow={setSelectedRow}
        isFilter={isFilter}
        filterList={filterList}
        isSearch={isSearch}
        setFilterObj={setFilterObj}
        isSort={isSort}
        Data={list}
        Columns={columns}
        Title={listTitle}
        emptyListMsg={emptyListMsg}
        searchAttribute={searchAttribute}
        totalPage={Math.ceil(total_count / 10)}
        isChecked={isChecked}
        checked={checked}
        setChecked={setChecked}
        createBtnTitle={createBtnTitle}
        createBtnFun={createBtnFun}
        CreateBtnIcon={CreateBtnIcon}
        hideCreateBtn={hideCreateBtn}
        createBtnClass={createBtnClass}
        createBtnDisable={createBtnDisable}
        total_count={total_count}
        paginationPage={paginationPage}
        setPaginationPage={setPaginationPage}
        searchInputPlaceholder={searchInputPlaceholder}
        handleApplyFilterMenu={handleApplyFilterMenu}
        handleResetFilterMenu={handleResetFilterMenu}
        handlePageChange={handlePageChange}
        headerChildren={headerChildren}
        childrenType={childrenType}
        setPage={setPage}
      />
    </Stack>
  );
}
export default TableUse;

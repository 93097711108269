import React, { useState } from "react";
import SearchableSelect from "../../Components/Atoms/Select/SearchableSelect";
import { getBranchesList } from "../../Contexts/APIs/Branches/Branches";
import "./DiveCenterStatistics.css";

const BranchesFilter = ({ setFilterObj, filterObj }) => {
  const [branchesList, setBranchesList] = useState([]);
  const [branchesCount, setBranchesCount] = useState(10);
  const [branch, setBranch] = useState({});
  const userId = localStorage.getItem("userId");

  const getBranches = async (filter) => {
    const filterData = {
      page_size: "10",
      page_number: 1,
      dive_center_id: userId,
      ...filter,
    };
    const { res, err } = await getBranchesList({
      ...filterData,
    });
    if (res) {
      filterData?.page_number == 1
        ? setBranchesList(res?.data?.branches)
        : setBranchesList((s) => [...s, ...res?.data?.branches]);
      setBranchesCount(res?.extra?.total_count);
    }
  };
  const updateBranchesList = (name, value) => {
    filterObj[name] != value && setFilterObj({ ...filterObj, [name]: value });
  };

  const updateBranch = (branch_val) => {
    setBranch({ label: branch_val?.label, id: branch_val?.value });
    updateBranchesList("branch_id", branch_val?.value);
  };
  return (
    <SearchableSelect
      handelMenuOpen={branchesList?.length === 0 && getBranches}
      name="branches"
      getList={getBranches}
      count={branchesCount}
      options={[{ name: "All", id: 0 }, ...branchesList]}
      label={"joboffer.allBranches"}
      className="searchable-select branches-filter border-r-10px"
      onChange={(val) => updateBranch(val)}
      value={{ label: branch?.label, value: branch?.id }}
      onClear={() => {
        setBranch({});
        updateBranchesList("branch_id", null);
        getBranches({ branch_id: null });
      }}
    />
  );
};

export default BranchesFilter;

import React from "react";
import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Languages from "../../../../Components/Atoms/Shared/language/Language";
import CCrud from "../../../../Components/Molecules/General/Crud/CCrud";
import "../../../../Components/Atoms/Shared/Styles/list-page.css";
import { initialValues, structure, validationSchema } from "./structure";

import {
  createCustomerLevels,
  updateCustomerLevels,
} from "../../../../Contexts/APIs/Levels/Customer/customer";
import CustomerLevelsDetailsPage from "../../../../Pages/Main/Levels/Customer/CustomerDetails.page";
import "./CustomerLevels.css";
const CustomerLevelsTemplate = ({
  customersLevelsData,
  setFilterObj,
  data,
  openDeleteModal,
  setOpenDeleteModal,
  rowData,
  count,
  paginationPage,
  setPaginationPage,
  handlePageChange,
  breadcrumbs,
  roles
}) => {
  const searchInputPlaceholder = "Search Provinces name";
  let [searchParams] = useSearchParams();
  const index = searchParams.get("index");
  const id = searchParams.get("id");
  const navigate = useNavigate();
  const [supportedLang, setSupportedLang] = useState("en");

  const handelSubmit = (data, formik) => {
    const action = () => {
      searchParams.delete("id");
      searchParams.delete("type");
      searchParams.delete("index");
      navigate("/levels/customer");
      formik?.resetForm();
    };

    if (index) {
      updateCustomerLevels({ id, data: { level: data }, action });
    } else {
      createCustomerLevels({
        data: { level: data },
        action,
      });
    }
  };

  const actionsList = ({ id, index, details }) => {
    return [
      {
        condition: () => {
          if (roles?.can_read) {
            return true;
          } else {
            return false;
          }
        },
        click: () => {
          navigate(
            `/levels/customer/${id}?type=details&id=${id}&index=${index}`
          );
        },
        text: "View details",
      },
      {
        condition: () => {
          if (roles?.can_read && roles?.can_update) {
            return true;
          } else {
            return false;
          }
        },
        click: () => {
          navigate(`?type=form&id=${id}&index=${index}`);
        },
        text: "edit",
      },
    ];
  };

  return (
    <CCrud
      table={{
        list: data,
        openDeleteModal: openDeleteModal,
        setOpenDeleteModal: setOpenDeleteModal,
        rowData: rowData,
        listTitle: "Customer Levels",
        emptyListMsg: "There are no Customer Levels",
        details: {
          navigatePath: "/levels/countries",
          statusField: null,
          hideStatus: null,
        },
        actionsList: actionsList,
        columns: [
          { accessor: "id_data", Header: "Id" },
          { accessor: "level_data", Header: "Level" },
          {
            accessor: "reservations_required_data",
            Header: "Reservations required",
          },

          { accessor: "rank_data", Header: "Rank" },
          {
            accessor: "flat_points_earned_inc",
            Header: "Flat points earned Inc",
          },
          { accessor: "Actions", Header: "Actions" },
        ],
        isFilter: false,
        isSearch: false,
        isChecked: false,
        isSort: false,
        total_count: count,
        setFilterObj,

        className: "organization-table customer-levels-table",
        tableContainerOverflow: {overflow: 'auto', paddingBottom: "2.9rem"},
        paginationPage: paginationPage,
        setPaginationPage: setPaginationPage,
        searchInputPlaceholder: searchInputPlaceholder,
        handlePageChange,
      }}
      form={{
        structure: () =>
          structure(supportedLang, [{ id: "216", name: "test" }]),
        validationSchema: validationSchema,
        initialValues: !index
          ? {}
          : initialValues(customersLevelsData, supportedLang),
        onSubmit: handelSubmit,
        className: { form: "flow-form", actionButton: "flow-form-buttons" },
        handleLanguage: <Languages setLanguage={setSupportedLang} />,
        title: index ? "Edit customer level" : "Add customer level",
        breadcrumbs: breadcrumbs,
        setSupportedLang: setSupportedLang,
      }}
      DetailsComponent={<CustomerLevelsDetailsPage />}
    />
  );
};

export default CustomerLevelsTemplate;

import { axiosFn, handleError } from "../index"
import * as URL from "../URLs"

export async function getSupportedLanguages() {
    return await axiosFn("get", URL.lookups + URL.get_supported_languages)
        .then((res) => {
            return {
                res: res.data,
                err: null,
            }
        })
        .catch(handleError)
}
import React, { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import CCrud from "../../../Components/Molecules/General/Crud/CCrud";
import "../../../Components/Atoms/Shared/Styles/list-page.css";
import { initialValues, structure, validationSchema } from "./structure";
import OrganizationDetailsPage from "../../../Pages/Main/Organizations/OrganizationDetails.page";
import AddIcon from "@mui/icons-material/Add";
import LanguagesSelect from "../../../Components/Molecules/Forms/LanguagesSelect";
import Languages from "../../../Components/Atoms/Shared/language/Language";
import { Grid } from "@mui/material";
import { Stack } from "@mui/system";
import CustomSeparator from "../../../Components/Atoms/BreadCrumb";
import "../../../Components/Atoms/Shared/Styles/form-page.css";
import "./Organizations.css";
const OrganizationsTemplate = ({
  setFilterObj,
  data,
  openDeleteModal,
  setOpenDeleteModal,
  handleDeleteRequest,
  selectedRow,
  setSelectedRow,
  count,
  paginationPage,
  setPaginationPage,
  handlePageChange,
  supportedLanguages,
  updateSupportedLang,
  setSupportLang,
  supportLang,
  handleSubmit,
  rowData,
  organizationData,
  roles
  // breadcrumbs
}) => {
  const [supportedLang, setSupportedLang] = useState("en");
  const searchInputPlaceholder = "Search organization name";
  let [searchParams] = useSearchParams();
  const type = searchParams.get("type");
  const index = searchParams.get("index");
  const organizationName = searchParams.get("orgName");
  const navigate = useNavigate();

  const actionsList = ({ id, index }) => {
    return [
      {
        condition: () => {
          if (data[index].status !== "deleted" && roles?.can_read) {
            return true;
          } else {
            return false;
          }
        },
        click: () => {
          navigate(`/organizations/${id}?index=${index}`);
        },
        text: "View Details",
      },
      {
        condition: () => {
          if (roles?.can_read && roles?.can_update) {
            return true;
          } else {
            return false;
          }
        },
        click: () => {
          navigate(`?type=form&id=${id}&index=${index}`);
        },
        text: "Edit",
      },
      {
        condition: () => {
          if (roles?.can_read && roles?.can_delete) {
            return true;
          } else {
            return false;
          }
        },
        click: () => {
          setOpenDeleteModal(true);
        },
        text: "Delete",
      },
    ];
  };
  const breadcrumbs = [
    { path: "/organizations", active: true, title: "Organizations" },

    {
      active: false,
      title: index === null ? "Add organization" : "Edit organization",
    },
  ];
  return (
    <>
      {type === "form" ? (
        <Stack
          direction="row"
          alignSelf="start"
          justifyContent="start"
          gap={2}
          width="100%"
          mb={1}
        >
          <CustomSeparator breadcrumbs={breadcrumbs} />
        </Stack>
      ) : null}
      <CCrud
        table={{
          list: data,
          openDeleteModal: openDeleteModal,
          setOpenDeleteModal: setOpenDeleteModal,
          selectedRow: selectedRow,
          setSelectedRow: setSelectedRow,
          listTitle: "Organizations",
          emptyListMsg: "There are no organizations",
          listingPath: "/organizations",
          actionsList: actionsList,
          columns: [
            { accessor: "id", Header: "Id" },
            { accessor: "name", Header: "Organizations name" },
            { accessor: "website", Header: "Website" },
            { accessor: "view_certificates", Header: "" },
            { accessor: "Actions", Header: "Actions" },
          ],
          isFilter: false,
          isSearch: true,
          isChecked: false,
          isSort: false,
          total_count: count,
          setFilterObj,
          createBtnTitle: "Add Ogranization",
          createBtnFun: () => {
            setSupportLang("en");
            navigate(`?type=form`);            
          },
          CreateBtnIcon: AddIcon,
          hideCreateBtn: roles?.can_read && roles?.can_create ? null : true,
          createBtnClass: "add-organization-button",
          className: "organization-table organization-table-style",
          paginationPage: paginationPage,
          setPaginationPage: setPaginationPage,
          searchInputPlaceholder: searchInputPlaceholder,
          handlePageChange,
          openDeleteModal: openDeleteModal,
          setOpenDeleteModal: setOpenDeleteModal,
          handleDeleteRequest,
          rowData: rowData,
        }}
        form={{
          structure: () => structure(supportedLang),
          initialValues: !index ? {} : initialValues(organizationData,supportedLang),
          validationSchema: validationSchema(supportedLang),
          onSubmit: handleSubmit,
          setSupportedLang: setSupportedLang,
          submitTitle: index === null ? "Add" : "Save changes",
          title: index === null ? "Add organization" : "Edit organization",
          className: {
            form: "organization-form flow-form",
            actionButton: "organization-form-buttons flow-form-buttons",
          },
          handleLanguage: (<Languages setLanguage={setSupportedLang} />),
        }}
        DetailsComponent={<OrganizationDetailsPage />}
      />
    </>
  );
};

export default OrganizationsTemplate;

import { axiosFn, handleError } from "..";
import { makeFilterString } from "../Helper/Helper";
import * as URL from "../URLs";

export async function getDocumentsList(filter_obj) {
  return await axiosFn("get", URL.Document + makeFilterString(filter_obj))
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}

export async function createDocuments({ data, action }) {
  return await axiosFn("post", URL.Document_Operations, data)
    .then((res) => {
      action && action();
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}

export async function getDocumentsDetails(organization_id) {
  return await axiosFn("get", `${URL.Document_Operations}/${organization_id}`)
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}

export async function deleteDocuments(id) {
  return await axiosFn("delete", `${URL.Document_Operations}/${id}`)
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}

export async function updateDocuments({ id, data, action }) {
  return await axiosFn("put", `${URL.Document_Operations}/${id}`, data)
    .then((res) => {
      action && action();
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}

import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import CCrud from "../../../Components/Molecules/General/Crud/CCrud";
import "../../../Components/Atoms/Shared/Styles/list-page.css";
import { initialValues, structure, validationSchema } from "./structure";
import {
  createSponser,
  updateSponser,
} from "../../../Contexts/APIs/Sponsers/Sponsers";

const SponsersTemplate = ({
  filterObj,
  setFilterObj,
  getSponsers,
  sponserData,
  data,
  rowData,
  count,
  paginationPage,
  setPaginationPage,
  handlePageChange,
  breadcrumbs,
  openDeleteModal,
  setOpenDeleteModal,
  handleDeleteRequest,
}) => {
  const searchInputPlaceholder = "Search partner";
  let [searchParams] = useSearchParams();
  const index = searchParams.get("index");
  const id = searchParams.get("id");
  const navigate = useNavigate();

  const handelSubmit = (data, formik) => {
    const action = () => {
      searchParams.delete("id");
      searchParams.delete("type");
      searchParams.delete("index");
      getSponsers(filterObj);
      navigate("/sponsers");
      formik?.resetForm();
    };

    if (index) {
      updateSponser({ id, data: { sponsor: data }, action });
    } else {
      createSponser({
        data: { sponsor: data },
        action,
      });
    }
  };

  const actionsList = ({ id, index, details }) => {
    return [
      {
        condition: () => {
          return true;
        },
        click: () => {
          navigate(`?type=form&id=${id}&index=${index}`);
        },
        text: "edit",
      },
      {
        condition: () => {
          return true;
        },
        click: () => {
          setOpenDeleteModal(true);
        },
        text: "Delete",
      },
    ];
  };

  return (
    <CCrud
      table={{
        list: data,
        rowData: rowData,
        setFilterObj: setFilterObj,
        listTitle: "Partners",
        emptyListMsg: "There are no Partners",
        details: {
          statusField: null,
          hideStatus: null,
        },
        actionsList: actionsList,
        columns: [
          { accessor: "id", Header: "Id" },
          { accessor: "logo", Header: "Logo" },
          { accessor: "name", Header: "Name" },
          { accessor: "Actions", Header: "Actions" },
        ],
        isFilter: false,
        isSearch: true,
        isChecked: false,
        isSort: false,
        createBtnFun: () => navigate("?type=form"),
        CreateBtnIcon: AddIcon,
        createBtnTitle: "Add New Partner",
        createBtnClass: "add-organization-button",
        total_count: count,
        className: "organization-table",
        tableContainerOverflow: {overflow: 'auto', paddingBottom: "2.3rem"},
        paginationPage: paginationPage,
        setPaginationPage: setPaginationPage,
        searchInputPlaceholder: searchInputPlaceholder,
        handlePageChange,
        openDeleteModal,
        setOpenDeleteModal,
        handleDeleteRequest,
      }}
      form={{
        structure: () => structure(),
        validationSchema: validationSchema,
        initialValues: !index ? {} : initialValues(sponserData),
        onSubmit: handelSubmit,
        className: { form: "flow-form", actionButton: "flow-form-buttons" },
        handleLanguage: <></>,
        title: index ? "Edit partner" : "Add new partner",
        submitTitle: index ? 'Save changes' : 'Add',
        breadcrumbs: breadcrumbs,
        setSupportedLang: () => {},
      }}
    />
  );
};

export default SponsersTemplate;

import React, { useEffect, useState } from "react";
import Loader from "../../../../Components/Atoms/Loader/Loader";
import { getCountryList } from "../../../../Contexts/APIs/Country/Country";
import ProvinceTemplate from "../../../../Templates/Main/Geolocations/Province/Province.template";
import {
  deleteProvinces,
  getProvincesDetails,
  getProvincesList,
} from "../../../../Contexts/APIs/Provinces/Provinces";
import { getIsoCodesList } from "../../../../Contexts/APIs/IsoCodes/IsoCodes";
import usePageFilter from "../../../../Components/Atoms/Hooks/usePageFilter";

function ProvincePage({ roles }) {
  // let [searchParams, setSearchParams] = useSearchParams();
  const {
    page,
    filterObj,
    handlePageChange: onPageChange,
    setFilterObj,
    setPage,
    searchParams,
  } = usePageFilter();

  const id = searchParams.get("id");

  const [openLoader, setOpenLoader] = useState(false);
  const [provincesList, setProvincesList] = useState([]);
  const [provinceData, setProvinceData] = useState({});

  const [count, setCount] = useState(0);
  // const [page, setPage] = useState(1);
  const [countriesList, setCountriesList] = useState([]);
  const [isoCodesList, setIsoCodesList] = useState([]);

  // const [filterObj, setFilterObj] = useState(null);

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [countriesCount, setCountriesCount] = useState(0);
  const [isoCodesCount, setIsoCodesCount] = useState(0);

  // const updatePagination = (page) => {
  //   setFilterObj({ ...filterObj, page_number: page });
  // };

  // const handlePageChange = (event, value) => {
  //   setPage(value);
  //   updatePagination(value);
  // };

  const getProvinces = async (filterObj) => {
    setOpenLoader(true);
    const defaultFilterObj = {
      page_size: 10,
      page_number: 1,
      keyword: "",
      sort_by: "id",
    };
    const { res, error } = await getProvincesList({
      ...defaultFilterObj,
      ...filterObj,
    });
    if (res) {
      setOpenLoader(false);
      setProvincesList(res?.data?.cities);
      setCount(res?.extra?.total_count);
    }
    if (error) {
      setOpenLoader(false);
    }
  };
  const getProvincesData = async () => {
    setOpenLoader(true);
    const { res, error } = await getProvincesDetails(id);
    if (res) {
      setProvinceData(res.data.city);
      setOpenLoader(false);
    }
    if (error) {
      setOpenLoader(false);
    }
  };
  // mappedData use for create custom cell to concat multi values or navigate button
  const mappedData = provincesList?.map((data) => {
    return {
      ...data,
      id_data: <div style={{ width: "50px" }}>{data?.id}</div>,
      name_data: <div style={{ width: "200px" }}>{data?.name}</div>,
      lookup_data: (
        <div style={{ width: "200px" }}>{data?.lookup_key || "-"}</div>
      ),
    };
  });

  /*const handleDeleteRequest = async () => {
    const action = () => {
      const newList = provincesList;
      const filterdData = newList?.filter(
        (data) => data?.id != selectedRow?.id
      );
      setProvincesList(filterdData);
      setOpenDeleteModal(false);
      if (filterdData?.length == 0) {
        setFilterObj({ ...filterObj, page_number: 1 });
        setPage(1);
      }
    };
    updateProvinces({
      id: selectedRow?.id,
      data: { city: { is_deleted: true } },
      action,
    });
  };*/
  const handleDeleteRequest = async () => {
    const action = (data) => {
      setOpenDeleteModal(false);
      // setFilterObj({ ...filterObj, page_number: 1 });
      // setPage(1);
      console.log(data);
      setProvincesList((prevState) =>
      prevState?.filter((ele) => ele?.id !== data?.data?.city?.id))
    };
    deleteProvinces(selectedRow?.id, action);
  };

  useEffect(() => {
    if (filterObj) getProvinces(filterObj);
  }, [filterObj]);

  const getCountry = async(data) => {
    setOpenLoader(true);
    const prevCountries = countriesList;
    const DataKey = data?.keyword;
    if((data?.page_number === 1 || data?.page_number === undefined) && !DataKey && countriesList.length === 10){
      setCountriesList(prevCountries);      
    }else{
      const { res, error } = (countriesCount !== 10 || DataKey)? await getCountryList({ page_size: 10, ...data }) : {};
      if (res) {      
        (data?.page_number == 1 || data?.page_number == undefined)
        ? setCountriesList(res?.data?.countries)
        : setCountriesList((s) => [...s, ...res?.data?.countries]);
        setCountriesCount(res?.extra?.total_count);
      }
    }
    setOpenLoader(false);
  };

  const getIsoKeys = async(data) => {
    setOpenLoader(true);
    const prevIsoCodes = isoCodesList;
    const DataKey = data?.keyword;
    if((data?.page_number === 1 || data?.page_number === undefined) && !DataKey && isoCodesList.length === 10){
      setIsoCodesList(prevIsoCodes);      
    }else{
      const { res, error } = (isoCodesCount !== 10 || DataKey)? await getIsoCodesList({ page_size: 10, iso_code_type: "city", ...data }) : {};
      if (res) {      
        (data?.page_number == 1 || data?.page_number == undefined)
        ? setIsoCodesList(res?.data?.iso_codes)
        : setIsoCodesList((s) => [...s, ...res?.data?.iso_codes]);
        setIsoCodesCount(res?.extra?.total_count);
      }
    }
    setOpenLoader(false);
  };

  useEffect(() => {
    id && getProvincesData({});
  }, [id]);

  const rowData = (data) => {
    setSelectedRow(data?.rowData);
  };
  const breadcrumbs = [
    {
      active: true,
      path: "/geolocations/provinces",
      title: "Provinces",
    },
    {
      active: false,
      title: id ? "Edit province" : "Add new province",
    },
  ];
  return (
    <>
      <Loader open={openLoader} />
      <ProvinceTemplate
        filterObj={filterObj}
        setFilterObj={setFilterObj}
        data={mappedData}
        count={count}
        paginationPage={page}
        setPaginationPage={setPage}
        handlePageChange={onPageChange}
        openDeleteModal={openDeleteModal}
        setOpenDeleteModal={setOpenDeleteModal}
        handleDeleteRequest={handleDeleteRequest}
        countriesList={countriesList}
        isoCodesList={isoCodesList}
        rowData={rowData}
        provinceData={provinceData}
        breadcrumbs={breadcrumbs}
        getCountry={getCountry}
        getIsoKeys={getIsoKeys}
        roles={roles}
        countriesCount={countriesCount}
        isoCodesCount={isoCodesCount}
      />
    </>
  );
}

export default ProvincePage;

import React from "react";
import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Languages from "../../../../Components/Atoms/Shared/language/Language";
import CCrud from "../../../../Components/Molecules/General/Crud/CCrud";
import "../../../../Components/Atoms/Shared/Styles/list-page.css";
import "./Currency.css";
import { initialValues, structure, validationSchema } from "./structure";
import CustomerLevelsDetailsPage from "../../../../Pages/Main/Levels/Customer/CustomerDetails.page";
import {
  syncCurrency,
  updateCurrency,
} from "../../../../Contexts/APIs/Lookups/Currency";
import { userType } from "../../../../services/modules/utils/constant";

const CurrencyTemplate = ({
  details,
  setFilterObj,
  data,
  openDeleteModal,
  setOpenDeleteModal,
  rowData,
  count,
  paginationPage,
  setPaginationPage,
  handlePageChange,
  headerChildren,
  countriesList,
  getCountry,
  countriesCount,
  breadcrumbs,
  roles,
}) => {
  const searchInputPlaceholder = "Search Provinces name";
  let [searchParams] = useSearchParams();
  const index = searchParams.get("index");
  const id = searchParams.get("id");
  const navigate = useNavigate();
  const [supportedLang, setSupportedLang] = useState("en");
  const [createBtnDisable, setCreateBtnDisable] = useState(false);
  const [country, setCountry] = useState();

  const handelSubmit = (data, formik) => {
    const action = () => {
      searchParams.delete("id");
      searchParams.delete("type");
      searchParams.delete("index");
      navigate("/predefined-lookups/currency");
      formik?.resetForm();
    };

    if (index) {
      updateCurrency({ id, data: { currency: data }, action });
    }
  };

  const actionsList = ({ id, index, details }) => {
    return [
      {
        condition: () => {
          return true;
        },
        click: () => {
          navigate(
            `/predefined-lookups/currency/${id}?type=details&id=${id}&index=${index}`
          );
        },
        text: "View details",
      },
      {
        condition: () => {
          return true;
        },
        click: () => {
          navigate(`?type=form&id=${id}&index=${index}`);
        },
        text: "edit",
      },
    ];
  };

  return (
    <CCrud
      table={{
        list: data,
        openDeleteModal: openDeleteModal,
        setOpenDeleteModal: setOpenDeleteModal,
        rowData: rowData,
        listTitle: "Currency",
        emptyListMsg: "There are no currencies",
        details: {
          navigatePath: "/predefined-lookups/currency",
          statusField: null,
          hideStatus: null,
        },
        actionsList: actionsList,
        columns: [
          { accessor: "id", Header: "Id" },
          { accessor: "name", Header: "Name" },
          {
            accessor: "lookup_key",
            Header: "Country lookup key",
          },

          { accessor: "usd_value", Header: "USD value" },
          {
            accessor: "is_available_btn",
            Header: "Is available",
          },
          {
            accessor: "is_payout_currency_btn",
            Header: "Is payout currency",
          },
          { accessor: "action", Header: "Actions" },
        ],
        isFilter: false,
        isSearch: true,
        isChecked: false,
        isSort: false,
        total_count: count,
        setFilterObj,
        createBtnTitle:
          roles?.can_update &&
          userType !== "descendant_user" &&
          "Sync currencies",
        createBtnFun: async () => {
          // const action = () => window.location.reload();
          // syncCurrency({ action });
          setCreateBtnDisable(true);
          const { res, err } = await syncCurrency({});
          if (res || err) {
            setCreateBtnDisable(false);
          }
        },
        hideCreateBtn:
          roles?.can_update && userType !== "descendant_user" ? false : true,
        createBtnClass: "add-organization-button",
        createBtnDisable: createBtnDisable,
        className: "organization-table currency-table",
        paginationPage: paginationPage,
        setPaginationPage: setPaginationPage,
        searchInputPlaceholder: searchInputPlaceholder,
        handlePageChange,
        headerChildren,
      }}
      form={{
        structure: () =>
          structure(
            supportedLang,
            countriesList,
            getCountry,
            countriesCount,
            country,
            setCountry
          ),
        validationSchema: validationSchema,
        initialValues: !index ? {} : initialValues(details, supportedLang),
        onSubmit: handelSubmit,
        setSupportedLang: setSupportedLang,
        className: { form: "flow-form", actionButton: "flow-form-buttons" },
        handleLanguage: <Languages setLanguage={setSupportedLang} />,
        breadcrumbs: breadcrumbs,
        title: index ? "Edit currency" : "Add new currency",
      }}
      DetailsComponent={<CustomerLevelsDetailsPage />}
    />
  );
};

export default CurrencyTemplate;

import React from 'react'
import ActivityDetailsTemplate from '../../../Templates/Main/Activities/ActivityDetails.template'
import { getActivityDetails } from '../../../Contexts/APIs/Activities/Activities';
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { getSupportedLanguages } from '../../../Contexts/APIs/Lookups/SupportedLanguages';

function ActivityDetailsPage() {
    const { activityId } = useParams();
    const [activityData, setActivityData] = useState({});
    const [openLoader, setOpenLoader] = useState(false);

    const getActivity = async (id) => {
        setOpenLoader(true);
        const { res, err } = await getActivityDetails(id);
        if (res) { setActivityData(res?.data?.activity); console.log(res?.data?.activity); }
        if (err) toast.error(err)
        setOpenLoader(false);
    };

    const [supportedLanguages, setSupportedLanguages] = useState([]);
    const getLanguages = async () => {
      const { res, err } = await getSupportedLanguages();
      if (res) {
        setSupportedLanguages(res?.data?.supported_locales);
      }
    };
    const [supportLang, setSupportLang] = useState("en");
    const updateSupportedLang = (e, languages) => {
      setSupportLang(e.target.value);
      console.log(e.target.value);
    };
    
    useEffect(() => {
      getActivity(activityId)
      getLanguages();
    }, []);
  return (
    <ActivityDetailsTemplate 
      ActivityData={activityData}
      supportedLanguages={supportedLanguages}
      updateSupportedLang={updateSupportedLang}
      supportLang={supportLang}
    />
  )
}

export default ActivityDetailsPage
import { axiosFn, handleError } from "..";
import { makeFilterString } from "../Helper/Helper";
import * as URL from "../URLs";

export async function getOrganizationsList(filter_obj) {
    return await axiosFn("get", URL.organizations + makeFilterString(filter_obj))
      .then((res) => {        
        return {
          res: res.data,
          err: null,
        };
      })
      .catch(handleError);
}

export async function createOrganization(data) {
  return await axiosFn("post", URL.organizations, data)    
    .then((res) => {        
      return {
        res: res.data,
        err: null,
      };
    }) 
    .catch(handleError);
}

export async function editOrganization(id , data) {
  return await axiosFn("put", `${URL.organizations}/${id}`,data)
      .then((res) => {
          return {
              res: res.data,
              err: null,
          };
      })
      .catch(handleError);
}

export async function getOrganizationDetails(organization_id) {
  return await axiosFn("get", `${URL.organizations}/${organization_id}`)
      .then((res) => {
          return {
              res: res.data,
              err: null,
          };
      })
      .catch(handleError);
}

export async function deleteOrganization(id) {
  return await axiosFn("delete", `${URL.organizations}/${id}`)
      .then((res) => {
          return {
              res: res.data,
              err: null,
          };
      })
      .catch(handleError);
}
import { useFormik } from "formik";
import React from "react";
import { createOperator } from "../../../Contexts/APIs/Operators/Operators";
import * as yup from "yup";
import AddOperatorTemplate from "../../../Templates/Main/Operators/AddOperator.Template";
import { useNavigate } from "react-router-dom";

function AddOperatorPage() {
  const navigate = useNavigate();
  const validationSchemaObject = yup.object({
    user_email: yup
      .string()
      .trim()
      .required("Email required")
      .email("email must be valid"),
    user_name: yup
      .string()
      .trim()
      .min(3, "name must be at least 3 characters")
      .required("name required"),
  });

  const handleAddOperator = async (values) => {
    const { res, err } = await createOperator({
      user: {
        email: values.user_email,
        name: values.user_name,
      },
    });
    if (res) {
      navigate("/operators/main");
    }
  };

  const formik = useFormik({
    initialValues: {
      user_email: "",
      user_name: "",
    },
    validationSchema: validationSchemaObject,
    onSubmit: (values) => {
      handleAddOperator(values);
    },
  });

  return <AddOperatorTemplate formik={formik} />;
}

export default AddOperatorPage;

import { axiosFn, handleError } from "..";
import { makeFilterString } from "../Helper/Helper";
import * as URL from "../URLs";

export async function getLanguageFluencysList(filter_obj) {
  return await axiosFn(
    "get",
    URL.Language_Fluency + makeFilterString(filter_obj)
  )
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}

export async function createLanguageFluency({ data, action }) {
  return await axiosFn("post", URL.Language_Fluency, data)
    .then((res) => {
      action && action();
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}

export async function getLanguageFluencyDetails(organization_id) {
  return await axiosFn("get", `${URL.Language_Fluency}/${organization_id}`)
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
  }
  
  export async function deleteLanguageFluency(id, action) {
    return await axiosFn("delete", `${URL.Language_Fluency}/${id}`)
    .then((res) => {
      action && action();
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}

export async function updateLanguageFluency({ id, data, action }) {
  return await axiosFn("put", `${URL.Language_Fluency}/${id}`, data)
    .then((res) => {
      action && action();
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}

import React from 'react'
import JobOffersList from '../../Molecules/JobOffers/JobOffersList/JobOffersList';
import JobOffersHeader from '../../Molecules/JobOffers/JobOffersHeader/JobOffersHeader';
import SearchIcon from '@mui/icons-material/Search';

function JobOffers(props) {    
    const { filterObj, setFilterObj, jobOffersList, count, page, setPage, handlePageChange, roles, applicants_roles } = props;

    return (
      <>
          <JobOffersHeader
              title='Job offers'              
              searchInput={{
                  type: "search",
                  placeholder: "Search job offers",
                  StartAdornment: { SearchIcon },
                  className: "Search-job"
              }}
  
              filterObj={filterObj}
              setFilterObj={setFilterObj}
              setPage={setPage}
              handleChange={handlePageChange}
          />
          <JobOffersList
              jobOffersList={jobOffersList}
              count={count} page={page}
              handlePageChange={handlePageChange}
              filterObj={filterObj}
              roles={roles}
              applicants_roles={applicants_roles}
          />
      </>
    )
}

export default JobOffers
import React, { useEffect, useState } from 'react'
import { getApplicantDetails } from '../../../Contexts/APIs/JobOffers/JobOffers';
import Loader from '../../../Components/Atoms/Loader/Loader';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import ApplicantDetailsTemplate from '../../../Templates/Main/JobOffers/ApplicantDetails.template';

function ApplicantDetailsPage() {
    const { jobOfferId } = useParams();
    const { applicantId } = useParams();
    const [applicantData, setApplicantData] = useState({});
    const [openLoader, setOpenLoader] = useState(false);

    const getApplicant = async (id) => {
        setOpenLoader(true);
        const { res, err } = await getApplicantDetails(id);
        if (res) { setApplicantData(res?.data?.user); }
        if (err) toast.error(err)
        setOpenLoader(false);
    };

    useEffect(() => {
        getApplicant(applicantId);
    }, []);

  return (
    <>
        <Loader open={openLoader} />
        <ApplicantDetailsTemplate
            applicantData={applicantData}
            jobOfferId={jobOfferId}
        />
    </>
  )
}

export default ApplicantDetailsPage
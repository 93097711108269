import { useState, useEffect } from 'react';

function usePageFilter() {
  const searchParams = new URLSearchParams(window.location.search);
  const [pageNumber, setPageNumber] = useState(
    () => +searchParams.get("page_number") || 1
  );
  const [page, setPage] = useState(1);
  const [filterObj, setFilterObj] = useState(null);

  useEffect(() => {
    if (!pageNumber) {
      setPage(1);
      setFilterObj(prev => ({ ...prev, page_number: 1 }));
    } else {
      setPage(pageNumber);
      setFilterObj(prev => ({ ...prev, page_number: pageNumber }));
    }
  }, [pageNumber]);

  const handlePageChange = (newPage) => {
    setPageNumber(newPage);
  };

  return { page, filterObj, handlePageChange, setFilterObj, setPage, searchParams };
}

export default usePageFilter;

import { axiosFn, handleError } from "..";
import { makeFilterString } from "../Helper/Helper";
import * as URL from "../URLs";

// export async function getCertificatesList(organization_id, filter_obj) {
//     return await axiosFn("get", `${URL.organization_certificates}/${organization_id}` + makeFilterString(filter_obj))
export async function getCertificatesList(filter_obj) {
  return await axiosFn("get", URL.organization_certificates + makeFilterString(filter_obj))
      .then((res) => {        
        return {
          res: res.data,
          err: null,
        };
      })
      .catch(handleError);
  }
  
  
  export async function createCertificate(data) {
    return await axiosFn("post", URL.organization_certificates, data)    
      .then((res) => {        
        return {
          res: res.data,
          err: null,
        };
      }) 
      .catch(handleError);
  }
  
  export async function editCertificate(id , data) {
    return await axiosFn("put", `${URL.organization_certificates}/${id}`,data)
        .then((res) => {
            return {
                res: res.data,
                err: null,
            };
        })
        .catch(handleError);
  }
  
  export async function getCertificateDetails(certificate_id) {
    return await axiosFn("get", `${URL.organization_certificates}/${certificate_id}`)
        .then((res) => {
            return {
                res: res.data,
                err: null,
            };
        })
        .catch(handleError);
  }
  
  export async function deleteCertificate(id) {
    return await axiosFn("delete", `${URL.organization_certificates}/${id}`)
        .then((res) => {
            return {
                res: res.data,
                err: null,
            };
        })
        .catch(handleError);
  }
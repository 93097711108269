import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Loader from '../../../Components/Atoms/Loader/Loader';
import { getFreelancerDetails } from '../../../Contexts/APIs/Freelancers/Freelancers';
import FreelancerDetailsTemplate from '../../../Templates/Main/Freelancers/FreelancerDetails.Template'

const FreelancerDetailsPage = ({roles}) => {
    
    const { freelancerId } = useParams();    
    const [applicantData, setApplicantData] = useState({});
    const [openLoader, setOpenLoader] = useState(false);


    const getApplicantDetails = async () => {
        setOpenLoader(true);
        const { res, err } = await getFreelancerDetails(freelancerId);
        if (res) setApplicantData(res?.data?.user);
        if (err) toast.error(err)
        setOpenLoader(false);
    };

    useEffect(() => {
        getApplicantDetails()
    }, []);
    return (
        <>
            <Loader open={openLoader} />
            <FreelancerDetailsTemplate                
                applicantData={applicantData} 
                getFreelancer={getApplicantDetails}
                roles={roles}
            />
        </>
    )
}

export default FreelancerDetailsPage
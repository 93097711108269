import React from "react";
import Loader from "../../../../Components/Atoms/Loader/Loader";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import { getSupportedLanguages } from "../../../../Contexts/APIs/Lookups/SupportedLanguages";
import { toast } from "react-toastify";
import FeatureOptionsDetailsTemplate from "../../../../Templates/Main/PredefinedLookups/FeatureOptions/FeatureOptionsDetails.template";
import { deleteFeatureOption, getFeatureOptionDetails, updateFeatureOption } from "../../../../Contexts/APIs/FeatureOptions/FeatureOptions";

function FeatureOptionsDetailsPage({roles}) {
  let [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const navigate = useNavigate();
  const [featureOptionData, setFeatureOptionData] = useState({});
  const [openLoader, setOpenLoader] = useState(false);
  const [supportedLanguages, setSupportedLanguages] = useState([]);
  const [supportLang, setSupportLang] = useState("en");
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const getFeatureOption = async (featureOptionId) => {
    setOpenLoader(true);
    const { res, err } = await getFeatureOptionDetails(featureOptionId);
    if (res) {
      setFeatureOptionData(res?.data?.feature_option);
    }
    if (err) {
      toast.error(err);
    }
    setOpenLoader(false);
  };

  const getLanguages = async () => {
    const { res, err } = await getSupportedLanguages();
    if (res) {
      setSupportedLanguages(res?.data?.supported_locales);
    }
  };

  const handleDeleteRequest = async (featId) => {
    const { res, err } = await deleteFeatureOption(featId);
    if (res) {
      setOpenDeleteModal(false);
      navigate('/predefined-lookups/feature-options')
    }
  };

  useEffect(() => {
    id && getFeatureOption(id);
  }, [id]);
  useEffect(() => {
    getLanguages({});
  }, []);
  const updateSupportedLang = (e, languages) => {
    setSupportLang(e.target.value);
  };


  return (
    <>
      <Loader open={openLoader} />
      <FeatureOptionsDetailsTemplate
        customersLevelsData={featureOptionData}
        supportedLanguages={supportedLanguages}
        updateSupportedLang={updateSupportedLang}
        supportLang={supportLang}
        openDeleteModal={openDeleteModal}
        setOpenDeleteModal={setOpenDeleteModal}
        handleDeleteRequest={handleDeleteRequest}
        roles={roles}
      />
    </>
  );
}

export default FeatureOptionsDetailsPage;

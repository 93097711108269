import React, { useMemo } from "react";
import FilterListIcon from "@mui/icons-material/FilterList";
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  usePagination,
} from "react-table";
import Footer from "./Footer";
import Header from "./Header";
import {
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import styled from "@emotion/styled";
import CustomPagination from "../../Pagination/Pagination";

const CTable = ({
  Data,
  Columns,
  Title,
  emptyListMsg,
  searchAttribute,
  isChecked,
  isSort = false,
  isSearch,
  isFilter,
  setFilterObj,
  selectedData,
  // setSelectedRow,
  currentpage,
  totalPage,
  checked,
  setChecked,
  headerChildren,
  createBtnTitle,
  createBtnFun,
  CreateBtnIcon,
  hideCreateBtn,
  createBtnClass,
  createBtnDisable,
  filterList,
  className,
  tableContainerOverflow,
  total_count,
  paginationPage,
  setPaginationPage,
  searchInputPlaceholder,
  handlePageChange,
  customKeyword,
  childrenType,
  setPage,
}) => {
  // used tableContainerOverflowObj : to show actions menu full without scroll or hidden it
  const tableContainerOverflowObj = tableContainerOverflow
    ? tableContainerOverflow
    : { overflow: "visible" };
  const handleAllCheckboxChange = (event) => {
    let checkedData = [];
    for (let key = 0; key < data?.length; key++) {
      checkedData.push(event.target.checked);
    }
    setChecked((s) => checkedData);
  };

  const handleCheckboxChange = (event, index) => {
    setChecked((s) => {
      s[index] = event.target.checked;
      return s;
    });
  };

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(even)": {
      backgroundColor: "#2f80ed17",
      border: 0,
    },
    "td, th": {
      border: 0,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const data = useMemo(
    () =>
      Data &&
      Data?.map((value, i) => {
        return {
          ...value,
        };
      }),
    [Data]
  );

  const columns = useMemo(
    () =>
      Columns?.map((column) => {
        return {
          Header: column.Header,
          accessor: column.accessor,
        };
      }),
    [Columns]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    pageOptions,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      useControlledState: (state) => {
        return React.useMemo(
          () => ({
            ...state,
            pageIndex: currentpage,
          }),
          [state, currentpage]
        );
      },
      initialState: { pageIndex: currentpage },
      manualPagination: true,
      pageCount: totalPage,
    },

    useGlobalFilter,
    useSortBy,
    usePagination
  );
  return (
    <TableContainer
      component={Paper}
      className={className}
      sx={{
        background: "transparent",
        ...tableContainerOverflowObj,
        overflow: "auto !important",
      }}
    >
      <Header
        isSearch={isSearch}
        isFilter={isFilter}
        Title={Title}
        Data={Data}
        setFilterObj={setFilterObj}
        searchAttribute={searchAttribute}
        createBtnTitle={createBtnTitle}
        createBtnFun={createBtnFun}
        CreateBtnIcon={CreateBtnIcon}
        hideCreateBtn={hideCreateBtn}
        createBtnClass={createBtnClass}
        createBtnDisable={createBtnDisable}
        filterList={filterList}
        setPaginationPage={setPaginationPage}
        searchInputPlaceholder={searchInputPlaceholder}
        children={headerChildren}
        childrenType={childrenType}
        setPage={setPage}
        handleChange={handlePageChange}
      />

      <Table {...getTableProps()} bg="white" mb="6" size="small">
        <TableHead bg="#efeeee" color="#645f65">
          {headerGroups.map((headerGroup, index) => (
            <TableRow {...headerGroup.getHeaderGroupProps()} key={index}>
              {isChecked && (
                <TableCell>
                  <FormControlLabel
                    ml={4}
                    control={
                      <Checkbox
                        checked={!checked?.includes(false) ? true : false}
                        indeterminate={
                          checked?.includes(false) && checked?.includes(true)
                        }
                        onChange={(e) => handleAllCheckboxChange(e)}
                      />
                    }
                  />
                </TableCell>
              )}
              {headerGroup.headers.map((column, index) => (
                <TableCell
                  key={index}
                  bg="inherit"
                  {...column.getHeaderProps(
                    column.Header === "" || !isSort
                      ? ""
                      : column.getSortByToggleProps()
                  )}
                >
                  <Stack direction="row">
                    {Data?.length > 0 ? (
                      <>
                        <Typography fontWeight="500" fontSize="14px">
                          {column.render("Header")}
                        </Typography>
                        {column.Header === "" ? (
                          ""
                        ) : (
                          <span
                            style={{
                              paddingLeft: "10px",
                              margin: "-1px",
                            }}
                            display="inline-block"
                          >
                            {isSort &&
                              (column.isSorted ? (
                                column.isSortedDesc ? (
                                  <FilterListIcon
                                    aria-label="sorted descending"
                                    fontSize="20px"
                                  />
                                ) : (
                                  <FilterListIcon
                                    aria-label="sorted ascending"
                                    fontSize="20px"
                                  />
                                )
                              ) : (
                                <FilterListIcon fontSize="20px" />
                              ))}
                          </span>
                        )}
                      </>
                    ) : (
                      ""
                    )}
                  </Stack>
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        {Data.length > 0 ? (
          <TableBody {...getTableBodyProps()} color="black">
            {page.map((row, index) => {
              prepareRow(row);
              return (
                <StyledTableRow
                  key={index}
                  {...row.getRowProps()}
                  onClick={() => {
                    selectedData(row.original);
                    // setSelectedRow && setSelectedRow(row.original);
                  }}
                >
                  {isChecked && (
                    <TableCell
                      sx={{
                        color: "#6E7079",
                        fontweight: "400",
                        fontsize: "14px",
                      }}
                    >
                      <Checkbox
                        checked={checked[index]}
                        onChange={(e) => handleCheckboxChange(e, index)}
                        style={{
                          marginTop: "10px",
                          marginLeft: "-10px",
                        }}
                      />
                    </TableCell>
                  )}
                  {row.cells.map((cell, index) => {
                    return (
                      <TableCell
                        key={index}
                        sx={{
                          color: "#6E7079",
                          fontweight: "400",
                          fontsize: "14px",
                          position: "relative",
                        }}
                        {...cell.getCellProps()}
                      >
                        {cell.render("Cell")}
                      </TableCell>
                    );
                  })}
                </StyledTableRow>
              );
            })}
          </TableBody>
        ) : (
          <TableBody>
            <StyledTableRow>
              <TableCell
                sx={{
                  width: "100% !important",
                  borderBottom: "none !important",
                  padding: "0px !important",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "20px",
                    color: "#333132",
                    textAlign: "center",
                  }}
                >
                  {emptyListMsg}
                </Typography>
              </TableCell>
            </StyledTableRow>
          </TableBody>
        )}
      </Table>

      {total_count > 10 && (
        <Grid container item justifyContent={"center"} xs={12} mb={7.5} mt={5}>
          <CustomPagination
            count={Math.ceil(total_count / 10)}
            size="large"
            shape="rounded"
            page={paginationPage}
            handleChange={handlePageChange}
            className="pagination"
          />
        </Grid>
      )}
    </TableContainer>
  );
};

export default CTable;

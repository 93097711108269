import * as Yup from "yup";
import { icons } from "../../../../Assets/AssetHelper";
export const initialValues = (data) => ({
  ...data,
  ...data?.localized_data,
});
export const structure = (lang) => [
  {
    head: "Badge information",
    list: [
      {
        name: `${lang}.name`,
        kind: "input",
        type: "text",
        label: "Badge name",
        width: "50%",
        padding:'9.5px 14px'
      }
    ],
  },
  {
    head: "Badge icon",
    list: [
      {
        name: `image`,
        kind: "upload",
        uploadIcon:icons.addImage,
        type: "text",
        label: "Add badgeicon",
        width: "50%",
      },
    ],
  },
];

export const validationSchema = () =>
  Yup.object({
    en: Yup.object().shape({
      name: Yup.string().required("required"),
    })
  });

import {
  Button,
  CircularProgress,
  Grid,
  LinearProgress,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import {
  getPresignedLinkAPI,
  uploadFileAPI,
} from "../../../../../../services/modules/utils/Upload";
import { useState } from "react";
import { useEffect } from "react";
import { icons } from "../../../../../../Assets/AssetHelper";
import PrimaryButton from "../../../../Buttons/Primary/PrimaryButton";

function CUpload({ type, id, label, name, formik, changeAction, validation, displayEmptyState = false,uploadIcon,sx,sxDeleteIcon,sxDelete }) {
  const [videoFileName, setVideoFileName] = useState("");
  const [openLoader, setOpenLoader] = useState(false);
  const [error, setError] = useState(null);

  const uploadFile = async (event, type) => {
    const extension = event.target?.files[0]?.type.split("/")[1];
    if (validation && validation !== extension) {
      // Validation failed, do not proceed
      setError("File extension must be pdf");
      return;
    }
    setError(null);
    setOpenLoader(true);
    const res = await getPresignedLinkAPI([
      { extension: type === "image" ? "png" : extension },
    ]);
    const presignedUrl = res?.res?.data[0].presigned_url;
    const file_url = res?.res?.data[0].file_url;

    const file = event.target.files[0];
    const upload = await uploadFileAPI(presignedUrl, file);

    if (upload) {
      setVideoFileName(file_url);
      changeAction(file_url);
    }

    setOpenLoader(false);
  };

  const removeFile = () => {
    changeAction(null);
  };

  useEffect(() => {
    setVideoFileName(formik?.values[name]);
  }, [formik?.values[name]]);
  console.log(formik?.values[name], 'formik?.values[name]');
  // console.log(videoFileName, formik?.values[name]);
  console.log(error, "error");
  return (
<Grid container>
  <Grid item xs={4} lg={12} sx={{ direction: 'row', display: 'flex', gap: 2 }}>
    <label htmlFor={`upload-${id}`}>
      {!videoFileName && (
        <input
          id={`upload-${id}`}
          name={`upload-${id}`}
          type="file"
          style={{ display: "none" }}
          onChange={(e) => uploadFile(e, type)}
        />
      )}
      <Stack
        alignItems="center"
        justifyContent="center"
        gap={1}
        sx={{
          borderRadius: "10px",
          backgroundColor: "#f3f3f3",
          p: 2,
          mb: 3,
          width: '110px', 
          height: '110px', 
          position: 'relative', 
          ...sx,
        }}
      >
        {openLoader ? (
          <CircularProgress />
        ) : videoFileName ? (
          <Grid
            position="relative"
            sx={{
              width: '100%', 
              height: '100%', 
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <img
              src={validation === 'pdf' ? icons.PdfIcon : videoFileName}
              alt=""
              style={{
                width: '100%', 
                height: '100%', 
                objectFit:'contain'
              }}
            />
            <PrimaryButton
              btnType="icon"
              startIcon={<img src={icons.DeleteIcon} alt="delete" style={{...sxDeleteIcon}} />} // Scale delete icon
              style={{
                position: "absolute",
                bottom: '-20px',
                right: '-20px',
                ...sxDelete,
              }}
              click={() => removeFile()}
            />
          </Grid>
        ) : (
          <>
            <img
              src={uploadIcon}
              alt="Upload File"
              style={{ cursor: "pointer", width: '50px', height: '50px' }}
            />
            <Typography
              sx={{
                fontSize: "14px",
                color: "#f6b119",
                fontWeight: "500 !important",
                cursor: "pointer",
                width: "70px",
                textAlign: "center",
              }}
            >
              {label ? label : "Add image"}
            </Typography>
          </>
        )}
      </Stack>
      {error && (
        <Typography mt={"-20px"} color="red" fontSize="14px">
          {error}
        </Typography>
      )}
    </label>
    {displayEmptyState && (
      <Stack sx={{ mt: '10px', width: '100% !important', overflow: 'clip' }}>
        <Typography
          sx={{
            width: '100%',
            fontSize: '18px',
            color: '#333132',
            wordBreak: 'break-word',
          }}
        >
          {formik?.values[name] ? formik?.values[name] : 'No file chosen'}
        </Typography>
      </Stack>
    )}
  </Grid>
</Grid>
  );
}

export default CUpload;

import { axiosFn, handleError } from "../index";
import { makeFilterString } from "../Helper/Helper";
import * as URL from "../URLs";

export async function CurrencyAPI(filter) {
  return await axiosFn(
    "get",
    URL.lookups + URL.currencies + makeFilterString(filter)
  )
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}

export async function createCurrency({ data, action }) {
  return await axiosFn("post", URL.lookups + URL.currencies, data)
    .then((res) => {
      action && action();
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}

export async function getCurrencyDetails(organization_id) {
  return await axiosFn(
    "get",
    `${URL.lookups + URL.currencies}/${organization_id}`
  )
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}

export async function deleteCurrency(id) {
  return await axiosFn("delete", `${URL.lookups + URL.currencies}/${id}`)
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}

export async function updateCurrency({ id, data, action }) {
  return await axiosFn("put", `${URL.lookups + URL.currencies}/${id}`, data)
    .then((res) => {
      action && action();
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}

export async function syncCurrency({ action }) {
  return await axiosFn("post", `${URL.lookups + URL.currencies}/sync`)
    .then((res) => {
      action && action();
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}

import { IconButton, Menu, MenuItem } from "@mui/material";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { useState } from 'react'
import { useNavigate } from "react-router-dom";
import DeleteModal from "../General/Models/DeleteModal";

function ActionsMenu(props) {
    const {user, userPath, userPathLength, handleDelete, userType, usersPrivilages} = props;
    const navigate = useNavigate();
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => setAnchorEl(event.currentTarget);

    const handleClose = () => setAnchorEl(null);

    // hide in view details button in details page
    /*const hrf = window.location.href;
    const checkDetails = typeof parseInt(hrf.slice(hrf.indexOf(userPath)+(userPathLength+1), hrf.length)) === 'number' 
        && hrf.slice(hrf.indexOf(userPath)+(userPathLength+1), hrf.length).length != 0
        ? true : false;*/
    // hide in view details button in details page

    const handleDeleteRequest = () => {
        setOpenDeleteModal(false);
        handleClose();
    };

  return (
    <>
        <IconButton
            id={`demo-positioned-btn${user?.id}`}
            aria-controls={open ? `demo-positioned-menu${user?.id}` : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
        >
            <MoreHorizIcon style={{ color: "black" }} />
        </IconButton>
        <Menu
            id={`demo-positioned-menu${user?.id}`}
            aria-labelledby={`demo-positioned-btn${user?.id}`}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
        >
            {/* {!checkDetails && */}
            {
                (userType !== "descendant_user" || (userType === "descendant_user" && usersPrivilages?.can_read)) &&
                <MenuItem onClick={() => {                    
                    navigate(`/${userPath}/` + user?.id);
                    handleClose();
                }}>
                    View details
                </MenuItem>
            }
            {/* } */}

            { 
                (userType !== 'descendant_user' || (userType === 'descendant_user' && usersPrivilages?.can_read && usersPrivilages?.can_update)) &&
                <MenuItem onClick={() => {
                    navigate(`/${userPath}/edit/` + user?.id);
                    handleClose();
                }}>
                    Edit
                </MenuItem>
            }

            {
                (userType !== 'descendant_user' || (userType === 'descendant_user' && usersPrivilages?.can_read && usersPrivilages?.can_update)) &&
                <MenuItem onClick={() => {
                    navigate(`/${userPath}/edit-password/` + user?.id);                    
                    handleClose();
                }}>
                    Change pasword
                </MenuItem>
            }
        
            {
                (userType !== 'descendant_user' || (userType === 'descendant_user' && usersPrivilages?.can_read && usersPrivilages?.can_delete)) &&
                <MenuItem onClick={() => {
                    setOpenDeleteModal(true);
                }}>
                    Delete
                </MenuItem>            
            }
        </Menu>

        {
            openDeleteModal && <DeleteModal
                open={openDeleteModal} setOpen={setOpenDeleteModal}
                handelDeleteRequest={() => {
                    handleDelete();
                    handleDeleteRequest();
                }}
                handleCancelDelete={handleClose}
                text={[`Delete ${user?.name}`, `are you sure that you want to delete ${user?.name}`]}
            />
        }
    </>
  )
}

export default ActionsMenu
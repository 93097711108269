import React from "react";
import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Languages from "../../../../Components/Atoms/Shared/language/Language";
import CCrud from "../../../../Components/Molecules/General/Crud/CCrud";
import "../../../../Components/Atoms/Shared/Styles/list-page.css";
import { initialValues, structure, validationSchema } from "./structure";
import AddIcon from "@mui/icons-material/Add";

import { facility_type, is_hidden } from "./filter_data";
import FeatureOptionsDetailsPage from "../../../../Pages/Main/PredefinedLookups/FeatureOptions/FeatureOptionsDetails.page";
import {
  createFeatureOption,
  updateFeatureOption,
} from "../../../../Contexts/APIs/FeatureOptions/FeatureOptions";
import "./FeatureOptions.css";
import { toast } from "react-toastify";

const FeatureOptionsTemplate = ({
  setFilterObj,
  data,
  openDeleteModal,
  setOpenDeleteModal,
  rowData,
  count,
  paginationPage,
  setPaginationPage,
  handlePageChange,
  handleApplyFilterMenu,
  handleResetFilterMenu,
  details,
  handleDeleteRequest,
  breadcrumbs,
  roles,
}) => {
  const searchInputPlaceholder = "Search Provinces name";
  let [searchParams] = useSearchParams();
  const index = searchParams.get("index");
  const id = searchParams.get("id");
  const navigate = useNavigate();
  const [supportedLang, setSupportedLang] = useState("en");

  const handelSubmit = (data, formik) => {
    console.log(data, formik, "handelSubmit");

    const action = () => {
      searchParams.delete("id");
      searchParams.delete("type");
      searchParams.delete("index");
      navigate("/predefined-lookups/feature-options");
      formik?.resetForm();
    };

    if((formik.values.icon == undefined || formik.values.icon?.length == 0) && formik.values.facility_type?.length){
      toast.error("icon is required");
    }else{
      if (index) {
        updateFeatureOption({ id, data: { feature_option: data }, action });
      } else {
        createFeatureOption({
          data: { feature_option: data },
          action,
        });
      }
    }

  };

  const actionsList = ({ id, index, details }) => {
    return [
      {
        condition: () => {
          if (roles?.can_read) {
            return true;
          } else {
            return false;
          }
        },
        click: () => {
          navigate(
            `/predefined-lookups/feature-options/${id}?type=details&id=${id}&index=${index}`
          );
        },
        text: "View details",
      },
      {
        condition: () => {
          if (roles?.can_read && roles?.can_update) {
            return true;
          } else {
            return false;
          }
        },
        click: () => {
          navigate(`?type=form&id=${id}&index=${index}`);
        },
        text: "edit",
      },
      {
        condition: () => {
          if (roles?.can_read && roles?.can_delete) {
            return true;
          } else {
            return false;
          }
        },
        click: () => {
          setOpenDeleteModal(true);
        },
        text: "Delete",
      },
    ];
  };
  const filterList = [
    {
      key: "facility_type",
      title: "Facility type",
      list: facility_type,
    },
    {
      key: "is_hidden",
      title: "Is hidden",
      list: is_hidden,
    },
  ];
  return (
    <CCrud
      table={{
        list: data,
        filterList,
        openDeleteModal: openDeleteModal,
        setOpenDeleteModal: setOpenDeleteModal,
        handleApplyFilterMenu: handleApplyFilterMenu,
        handleResetFilterMenu: handleResetFilterMenu,
        rowData: rowData,
        listTitle: "Feature options",
        emptyListMsg: "There are no Feature options",
        details: {
          navigatePath: "/levels/countries",
          statusField: null,
          hideStatus: null,
        },
        actionsList: actionsList,
        columns: [
          { accessor: "id_data", Header: "Id" },
          { accessor: "image", Header: "Image" },
          {
            accessor: "name",
            Header: "Name",
          },

          { accessor: "facility_type", Header: "Facility type" },
          {
            accessor: "is_hidden_data",
            Header: "Is hidden",
          },
          { accessor: "Actions", Header: "Actions" },
        ],
        isFilter: true,
        isSearch: false,
        isChecked: false,
        isSort: false,
        total_count: count,
        setFilterObj,
        createBtnTitle: "Add new Feature options",
        createBtnFun:
          roles?.can_read && roles?.can_create
            ? () => navigate("?type=form")
            : null,
        CreateBtnIcon: AddIcon,
        createBtnClass: "add-organization-button",
        className: "organization-table feature-options-table",
        paginationPage: paginationPage,
        setPaginationPage: setPaginationPage,
        searchInputPlaceholder: searchInputPlaceholder,
        handlePageChange,
        handleDeleteRequest,
      }}
      form={{
        structure: () =>
          structure(supportedLang, [{ id: "216", name: "test" }]),
        validationSchema: validationSchema,
        initialValues: !index ? {} : initialValues(details, supportedLang),
        onSubmit: handelSubmit,
        setSupportedLang: setSupportedLang,
        className: { form: "flow-form", actionButton: "flow-form-buttons" },
        handleLanguage: <Languages setLanguage={setSupportedLang} />,
        title: index ? "Edit Feature options" : "Add new feature option",
        submitTitle: index === null ? "Add" : "Save changes",
        breadcrumbs: breadcrumbs,
      }}
      DetailsComponent={<FeatureOptionsDetailsPage />}
    />
  );
};

export default FeatureOptionsTemplate;

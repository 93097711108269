import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material'

function FormHead({text}) {
    const style = {        
        fontSize: "20px",
        fontWeight: "bold",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "1.5",
        letterSpacing: "normal",
        textAlign: "left",
        color: "#333132",
        borderBottom: "2px solid var(--primary-color)",
        width: "fit-content"
    };
  return (
    <Typography component="h1" className="mb-20px" style={style}>
        {text}
    </Typography>
  )
}

FormHead.propTypes = {
    text: PropTypes.string
}

export default FormHead
import { Button, Modal, Stack, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import './RejectionModal.css';
import FormHead from '../../../Atoms/FormHead/FormHead';
import TextFieldInput from '../../../Atoms/Input/TextField/TextFieldInput';

function RejectionModal({open, setOpen, text, rejectionReason, setRejectionReason, errorListener, buttons, handelRejectRequest, handelCancelReject, modalType, setErrorListener}) {
    /*const handleClose = () => { 
        setOpen(false); 
        // formik.handleReset();
    }*/

  return (
    <Modal
        open={open}
        // onClose={handleClose}
        onClose={handelCancelReject}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
    >
        <Stack alignItems='center' justifyContent='center' className='rejection-modal'>
            <FormHead text={ text[0] }></FormHead>
            {
                // modalType === 'text' &&
                // <Typography style={{ fontWeight: "bold", textAlign: 'left', width: '100%' }}>
                //     {text[1]}
                // </Typography>
                modalType === 'text' && <>{
                    text[1].indexOf("\n") !== -1 ? <>
                    {
                        text[1].split("\n").map( separatedText => {
                            return <Typography style={{ fontWeight: "bold", textAlign: 'left', width: '100%' }}>
                                {separatedText}
                            </Typography>
                        } )
                    }
                    </>
                    : <Typography style={{ fontWeight: "bold", textAlign: 'left', width: '100%' }}>
                        {text[1]}
                    </Typography>
    
                }</>
            }
            {
                modalType === 'form' &&
                <TextFieldInput
                    className='rejection-msg'
                    placeholder="Write the rejection reason"
                    type="text"
                    multiline={true}
                    rows={6}
                    onChange={(e) => {
                        setRejectionReason(e?.target?.value);
                        e?.target?.value.length === 0 ? setErrorListener(true) : setErrorListener(false);
                    }}
                    value={rejectionReason}
                />
            }
            {
                modalType === 'form' &&  errorListener === true && 
                <Typography textAlign='center' className='errorValid' style={{ fontWeight: "bold" }}>
                    Reason Required
                </Typography>                
            }
            <Stack flexDirection='row' alignItems='center' mt={4} className='buttons-wrap'>
                {/* <Button style={{ width: '150px', color: 'black' }} onClick={() => setOpen(false)}>{buttons[0]}</Button> */}
                <Button style={{ width: '150px', color: 'black' }} onClick={handelCancelReject}>{buttons[0]}</Button>

                <Button style={{ width: '150px' }} className='primary r-25px' onClick={handelRejectRequest}>{buttons[1]}</Button>
            </Stack>
        </Stack>

    </Modal>
  )
}

export default RejectionModal
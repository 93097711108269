import React, { useState, useEffect } from "react";
import ReservationsTemplate from "../../../Templates/Main/Reservations/Reservations.Template";
import { getReservationsList } from "../../../Contexts/APIs/Reservations/Reservations";
import Loader from "../../../Components/Atoms/Loader/Loader";
import { Grid, IconButton, Typography } from "@mui/material";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import InfoModal from "../../../Components/Molecules/General/Models/InfoModal";
import ParticipantsModal from "../../../Components/Molecules/Reservations/ParticipantsModal";
import moment from "moment";
import { getSystemConfigDetails } from "../../../Contexts/APIs/SystemConfigurations/SystemConfigurations";
import usePageFilter from "../../../Components/Atoms/Hooks/usePageFilter";
import { useNavigate } from "react-router-dom";

function ReservationsPage() {
  const [openLoader, setOpenLoader] = useState(false);
  const [reservationsList, setReservationsList] = useState([]);
  const [count, setCount] = useState(0);
  const [openInfoModal, setOpenInfoModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [openParticipantsModal, setOpenParticipantsModal] = useState(false);
  const [autoRejectedMsg, setAutoRejectedMsg] = useState("");
  const navigate = useNavigate();
  const { page, filterObj, handlePageChange, setFilterObj, setPage } =
    usePageFilter();

  const getReservations = async (filterObj) => {
    const defaultFilter = {
      page_size: 10,
      page_number: 1,
      keyword: "",
      sort_by: "id",
    };
    setOpenLoader(true);
    const { res, error } = await getReservationsList({
      ...defaultFilter,
      ...filterObj,
    });
    if (res) {
      setOpenLoader(false);
      setReservationsList(res?.data?.orders);
      setCount(res?.extra?.total_count);
    }
    if (error) {
      setOpenLoader(false);
    }
  };
  const autoRejectedDuration = async () => {
    const { res, err } = await getSystemConfigDetails(
      "auto_rejection_duration"
    );
    if (res) {
      setAutoRejectedMsg(res?.data?.system_configuration);
    }
  };
  const statusList = {
    pending: "#333132",
    reservation_confirmed: "#f6b119",
    checkin_pending: "#00b906",
    completed: "#00b906",
    checked_in: "#00b906",
    payment_pending: "#f6b119",
    cancelled: "#e10007",
    user_cancelled_resrvation: "#e10007",
    reservation_auto_rejected: "#e10007",
    payment_expired: "#e10007",
    rejected: "#e10007",
  };

  // mappedData use for create custom cell to concat multi values or navigate button
  console.log(reservationsList,'reservationsList');
  const mappedData = reservationsList?.map((data) => {
    const reservationDateTime = moment(data.order_item?.created_at)?.format(
      "MMM , DD,YYYY /hh a"
    );

    const activityDate = moment(data.order_item.purchasable.start_date)?.format(
      "MMM , DD,YYYY"
    );

    return {
      ...data,
      customer_name: (
        <Typography
          className="reservation-customer-name"
          onClick={() => setOpenParticipantsModal(true)}
        >
          {data.user_info.name}
        </Typography>
      ),
      activity_name: (
        <Typography
          className="reservation-customer-name"
          onClick={(_) =>
            navigate(
              `/operators/activities/${data?.order_item?.purchasable?.dive_activity_id}/activity-groups`
            )
          }
        >
          {data.order_item.purchasable.dive_activity.name}
        </Typography>
      ),
      branch_name: (
        <Typography>
          {data.order_item?.purchasable?.dive_activity?.branch?.name}
        </Typography>
      ),
      reservation_date_time: <Typography>{reservationDateTime}</Typography>,
      seats: <Typography>{data.order_item.quantity}</Typography>,
      activity_date: <Typography>{activityDate}</Typography>,
      total_price: (
        <Typography className="reservation-total-price">
          {data.payment_transaction.original_price.toFixed(2)}{" "}
          {data.payment_transaction.original_currency}
        </Typography>
      ),
      payment_paid: (
        <Typography
          className={`${
            data.is_paid ? "reservation-paid" : "reservation-unpaid"
          }`}
        >
          {data.is_paid ? "Paid" : "Unpaid"}
        </Typography>
      ),
      reservation_type:
        data?.order_item?.purchasable?.dive_activity?.owner_type ===
        "DiveExpert"
          ? "Private guide reservation"
          : "Normal reservation",
      payment_date_time: <Typography>{reservationDateTime}</Typography>,
      status: (
        <>
          {data.status === "pending" ? (
            <Grid>
              <Typography
                className="reservation-status"
                color={"#f6b119 !important"}
              >
                Wait for confirm reservation
                <IconButton
                  className="reservation-modal-button"
                  onClick={() => setOpenInfoModal(true)}
                >
                  <ErrorOutlineOutlinedIcon style={{ color: "#e10007" }} />
                </IconButton>
              </Typography>
            </Grid>
          ) : (
            <Typography
              className="reservation-status"
              color={statusList[data?.status] + " !important"}
            >
              {`${
                data.status === "payment_pending"
                  ? "Wait for payment confirmation"
                  : data.status_display.display_name
              }`}
            </Typography>
          )}
        </>
      ),
    };
  });

  const rowData = (data) => {
    setSelectedRow(data?.rowData);
  };

  const showInfoModal = () => {
    return (
      <>
        {" "}
        {openInfoModal && selectedRow?.status_display && (
          <InfoModal
            open={openInfoModal}
            setOpen={setOpenInfoModal}
            text={[
              "Attention",
              selectedRow?.status_display?.display_name?.toLowerCase() ===
              "waiting for confirm reservation"
                ? `Activity will be auto rejected if there is no confirmation Within ${autoRejectedMsg?.value} ${autoRejectedMsg?.unit}`
                : "Activity Cancelled because payment due date expired",
            ]}
          />
        )}{" "}
      </>
    );
  };

  const showParticipantsModal = () => {
    return (
      <>
        {openParticipantsModal && (
          <ParticipantsModal
            open={openParticipantsModal}
            setOpen={setOpenParticipantsModal}
            meetingPoint={selectedRow?.order_item?.pickup_location}
            resInfo={selectedRow?.user_info}
            participantsList={selectedRow?.order_item?.reservation_participants}
          />
        )}
      </>
    );
  };

  useEffect(() => {
    // filterObj && getReservations(filterObj);
    if (filterObj) {
      getReservations(filterObj);
    }
  }, [filterObj]);

  useEffect(() => {
    openInfoModal && autoRejectedDuration && autoRejectedDuration();
  }, [openInfoModal]);
  return (
    <>
      <Loader open={openLoader} />
      <ReservationsTemplate
        filterObj={filterObj}
        setFilterObj={setFilterObj}
        setPage={setPage}
        data={mappedData}
        selectedRow={selectedRow}
        setSelectedRow={setSelectedRow}
        count={count}
        paginationPage={page}
        setPaginationPage={setPage}
        handlePageChange={handlePageChange}
        modalComponents={[showInfoModal, showParticipantsModal]}
        rowData={rowData}
      />
    </>
  );
}

export default ReservationsPage;

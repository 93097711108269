import * as Yup from "yup";

export const initialValues = (data) => ({
  ...data,
  value: data?.value,
  description: data?.description,
});

export const structure = (key) => [
  {
    head: `${key} information`,
    list: [
      {
        name: `value`,
        kind: "input",
        type: "text",
        label: "Value",
        width: "100%",
      },
    ],
  },
];

export const validationSchema = () =>
  Yup.object({
    value: Yup.string().required("required"),
  });

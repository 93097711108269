import { axiosFn, handleError } from "../index"
import * as URL from "../URLs"

export async function notificationTypesList() {
    return await axiosFn("get", URL.NotificationTypes)
      .then((res) => {
        return {
          res: res.data,
          err: null,
        };
      })
      .catch(handleError);
  }
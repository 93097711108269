import { Grid } from '@mui/material';
import React from 'react'
import TextFieldInput from '../../Atoms/Input/TextField/TextFieldInput';
import { useEffect } from 'react';
import './OperatorForm.css';

function OperatorForm(props) {
    const {formik, handleFormSubmit} = props; 

    useEffect(() => {
        handleFormSubmit.current = () => { formik.handleSubmit() };
    }, [formik.values.user_name, formik.values.user_email]);

  return (
    <Grid item md={12} style={{ margin: "auto"}} justifyContent='start' className='form-element'>
        <TextFieldInput
            name="user_name"
            id="user_name"
            placeholder="Full name"
            type="text"
            onChange={formik.handleChange}
            value={formik.values.user_name}
            error={formik.touched.user_name && Boolean(formik.errors.user_name)}
            helperText={formik.touched.user_name && formik.errors.user_name} 
        />
        <TextFieldInput
            name="user_email"
            id="user_email"
            placeholder="Email address"
            type="email"
            onChange={formik.handleChange}
            value={formik.values.user_email}
            error={formik.touched.user_email && Boolean(formik.errors.user_email)}
            helperText={formik.touched.user_email && formik.errors.user_email}
        />
    </Grid>
  )
}

export default OperatorForm
import { Grid, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import React, { useEffect, useState } from "react";
import TextFieldInput from "../../../Atoms/Input/TextField/TextFieldInput";
import CustomSeparator from "../../../Atoms/BreadCrumb";
import SearchIcon from "@mui/icons-material/Search";
import { useParams } from "react-router-dom";
import "./ApplicantsHeader.css";
import { getJobOfferDetails } from "../../../../Contexts/APIs/JobOffers/JobOffers";

function ApplicantsHeader({
  data,
  searchInput,
  setPage,
  setApplicants,
  setCount,
  openLoader,
  setOpenLoader,
}) {
  const { jobOfferId } = useParams();
  const [searchKeywords, setSearchKeywords] = useState({});

  const handleFilterApplicant = async (id, keyword) => {
    setOpenLoader(true);
    const { res, err } = await getJobOfferDetails(id, {keyword});
    if (res) {
      setCount(
        res?.data?.job_offer?.job_requests?.filter((item) =>
          item.dive_expert?.name?.includes(keyword)
        ).length
      );
      setApplicants(
        res?.data?.job_offer?.job_requests?.filter((item) =>
          item.dive_expert?.name?.includes(keyword)
        )
      );
      setPage(1);
      setOpenLoader(false);
    }
  };

  const getAllApplicant = async (id, keyword) => {
    setOpenLoader(true);
    const { res, err } = await getJobOfferDetails(id , {keyword});
    if (res) {
      setCount(res?.data?.job_offer?.job_requests?.length);
      setApplicants(res?.data?.job_offer?.job_requests);
      setPage(1);
      setOpenLoader(false);
    }
  };

  const getKeywords = (keyword, type) => {
    setSearchKeywords({ keyword, type });
    // setSearchKeywords({ keyword: keyword, type: e?.type })
  };
  
  useEffect(() => {
    const timeId = setTimeout(() => {
      if (searchKeywords?.keyword && searchKeywords?.type) {
        if (searchKeywords?.keyword?.length != 0) {
          handleFilterApplicant(jobOfferId, searchKeywords?.keyword?.trim());
        } 
      }else if (searchKeywords?.type){
        getAllApplicant(jobOfferId);

      }
    }, 500);
    setPage(1);
    return () => clearTimeout(timeId);
  }, [searchKeywords]);

  const breadcrumbs = [
    { path: "/operators/job-offers", active: true, title: "Job offers" },
    {
      path: `/operators/job-offers/${data?.id}`,
      active: true,
      title: `${data?.title} details`,
    },
    { active: false, title: "applicants" },
  ];
  return (
    <Stack
      alignItems="center"
      bgcolor="white"
      boxShadow="0 2px 15px 0 rgba(0, 0, 0, 0.1)"
      borderRadius="10px"
      padding={2}
      flexDirection="column"
      justifyContent="space-between"
      mb={3}
    >
      <Stack width="100%" mb={3}>
        <CustomSeparator breadcrumbs={breadcrumbs} />
      </Stack>
      <Stack flexDirection="row" gap={2} width="100%">
        <Stack
          flexDirection="row"
          justifyContent="space-between"
          width="100%"
          className="details-header-content"
        >
          <Stack flexDirection="column" justifyContent="space-evenly">
            <Stack flexDirection="row" gap={2} alignItems="center">
              <Stack flexDirection="row" gap={"50px"} alignItems="center">
                <Typography
                  fontSize="30px"
                  fontWeight="bold"
                  color="#333132"
                  textTransform="capitalize"
                >
                  {data?.title} -{" "}
                  {data?.dive_expert_professional_level?.replaceAll("_", " ")}
                </Typography>
              </Stack>
            </Stack>

            <Stack flexDirection="row" alignItems="center" gap="5px">
              <Typography
                fontSize="20px"
                fontWeight="bold"
                color="#333132"
                textTransform="capitalize"
              >{`${
                data?.activity_type === "live_aboard"
                  ? "liveaboard"
                  : data?.activity_type?.replaceAll("_", " ")
              } , ${data?.job_type?.replaceAll(
                "_",
                " "
              )} , ${data?.location?.area?.name?.replaceAll(
                "_",
                " "
              )} ,`}</Typography>
              <Typography
                fontSize="20px"
                fontWeight="bold"
                color="#f6b119"
                textTransform="capitalize"
              >
                {" "}
                {`${data?.rate} ${data?.currency?.lookup_key}/day`}
              </Typography>
            </Stack>
          </Stack>

          <Grid item xs={6} lg={4}>
            {searchInput && (
              <TextFieldInput
                type={searchInput.type}
                onChange={(e) => getKeywords(e.target.value, e?.type)}
                placeholder={searchInput.placeholder}
                StartAdornment={<SearchIcon />}
                className={searchInput.className}
                value={searchKeywords?.keyword}
              />
            )}
          </Grid>
        </Stack>
      </Stack>
    </Stack>
  );
}

export default ApplicantsHeader;

import React from "react";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import { icons } from "../../../Assets/AssetHelper";

function CustomerLevalDetailsHeader({
  data,
  supportLang,
  handleEdit,
  handleDelete,
  isTitle = true,
  icon = true,
  roles
}) {
  const iconslist = {
    Bronze: icons.BronzeIcon,
    Silver: icons.SilverIcon,
    Platinum: icons.PlatinumIcon,
    Gold: icons.GoldIcon,
  };
  return (
    <Stack
      alignItems="center"
      bgcolor="white"
      borderRadius="10px 10px 0px 0 "
      padding={"10px 54px"}
      borderBottom="1px solid #d9d9d9"
      sx={{
        boxShadow: "0 2px 15px 0 rgba(0, 0, 0, 0.1)",
      }}
    >
      <Stack
        flexDirection="row"
        justifyContent="space-between"
        width="100%"
        className="details-header-content"
        alignItems="center"
      >
        <Stack flexDirection="row" alignItems="center" gap={1} my={2}>
          {icon && (
            <Box
              width="82px"
              height="82px"
              borderRadius="50%"
              p={1}
              style={{
                border: "1px solid rgba(0, 0, 0, 0.1)",
              }}
            >
              <img src={iconslist[data?.name]} width="100%" height="100%" />
            </Box>
          )}
          <Box>
            <Typography
              fontSize="30px"
              fontWeight="bold"
              color="#333132"
              textTransform="capitalize"
            >
              {data?.localized_data
                ? data?.localized_data[`${supportLang}`]["name"]
                : data?.name}{" "}
              {isTitle && "details"}
            </Typography>
          </Box>
        </Stack>
        <Stack
          flexDirection="row"
          gap={"20px"}
          justifyContent="right"
          alignItems="center"
          flexWrap="wrap"
        >
          {
            ((roles && roles?.can_read && roles?.can_update) || !roles) &&
            <IconButton onClick={handleEdit}>
              <img width="35" src={icons.EditIcon} alt="edit" />
            </IconButton>
          }
          {handleDelete && (
            <IconButton onClick={handleDelete}>
              <img width="35" src={icons.DeleteIcon} alt="delete" />
            </IconButton>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
}

export default CustomerLevalDetailsHeader;

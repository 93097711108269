import { axiosFn, handleError } from "..";
import { makeFilterString } from "../Helper/Helper";
import * as URL from "../URLs";

export async function getFeatureOptionsList(filter_obj) {
  return await axiosFn(
    "get",
    URL.Feature_Options + makeFilterString(filter_obj)
  )
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}

export async function createFeatureOption({ data, action }) {
  return await axiosFn("post", URL.Feature_Options, data)
    .then((res) => {
      action && action();
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}

export async function getFeatureOptionDetails(organization_id) {
  return await axiosFn("get", `${URL.Feature_Options}/${organization_id}`)
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}

export async function deleteFeatureOption(id, action) {
  return await axiosFn("delete", `${URL.Feature_Options}/${id}`)
    .then((res) => {
      action && action(res?.data);
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}

export async function updateFeatureOption({ id, data, action }) {
  return await axiosFn("put", `${URL.Feature_Options}/${id}`, data)
    .then((res) => {
      action && action(res);
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}

import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
// import UpdatePassword from '../../../Components/Organisms/DiveCenterAccount/Models/UpdatePassword';
import { confirmPassword } from '../../../Contexts/APIs/Auth/ConfirmPassword';
import AccountDataTemplate from '../../../Templates/Main/Account/AccountData.template'

const AccountDataPage = ({profileData}) => {
    const navigate = useNavigate()
    const dive_center_id = localStorage.getItem('userId')
    const [open, setOpen] = useState(false);

    

    const handelClick = (password) => {
        ConfirmPassword(dive_center_id, password)
    }

    const ConfirmPassword = async (dive_center_id, password) => {
        const { res, err } = await confirmPassword(dive_center_id, password);
        if (res) {
            navigate('/update-account')
        } else {

            toast.error(err)
        }
    };
    return (
        <>
            <AccountDataTemplate profileData={profileData} setOpen={setOpen} />
            {/* {open && <UpdatePassword open={open} setOpen={setOpen} handelClick={handelClick} />} */}
        </>

    )
}

export default AccountDataPage
import React, { useEffect, useState } from "react";
import { getBranchReviews } from "../../../Contexts/APIs/Branches/Branches";
import { useParams, useSearchParams } from "react-router-dom";
import FreelancerReviewsTemplate from "../../../Templates/Main/Freelancers/FreelancerReviewsList.Template";
import { getFreelancerDetails } from "../../../Contexts/APIs/Freelancers/Freelancers";

const FreelancerReviewsListPage = () => {
  const [list, setList] = useState([]);
  const [count, setCount] = useState(0);
  const { freelancerId } = useParams();
  const [freelancerData, setFreelancerData] = useState({});
  const [reviewsDetails, setReviewsDetails] = useState({});
  const [page, setPage] = useState(1);

  const handleChange = (key, value) => {
    setPage(value);
  };
  const getReviewsList = async () => {
    const { res, err } = await getBranchReviews(
      { page_number: page, page_size: 10 },
      {
        review: {
          reviewee_id: freelancerId,
          reviewee_type: "DiveExpert",
          reviewer_type: "DiveCenter",
        },
      }
    );

    if (res) {
      setList(res.data?.reviews);
      setReviewsDetails(res?.data?.reviews_details);
      setCount(res?.extra?.total_count);
    }
  };

  useEffect(() => {
    getReviewsList();
  }, [page]);

  const getFreelancer = async (id) => {
    const { res, err } = await getFreelancerDetails(id);
    if (res) {
      setFreelancerData(res?.data?.user);
    }
  };

  useEffect(() => {
    freelancerId && getFreelancer(freelancerId);
  }, [freelancerId]);
  return (
    <FreelancerReviewsTemplate
      count={count}
      reviewsList={list}
      reviewsDetails={reviewsDetails}
      data={freelancerData}
      handleChange={handleChange}
      page={page}
    />
  );
};

export default FreelancerReviewsListPage;

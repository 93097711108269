import React from "react";
import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Languages from "../../../../Components/Atoms/Shared/language/Language";
import CCrud from "../../../../Components/Molecules/General/Crud/CCrud";
import "../../../../Components/Atoms/Shared/Styles/list-page.css";
import { initialValues, structure, validationSchema } from "./structure";
import CustomerLevelsDetailsPage from "../../../../Pages/Main/Levels/Customer/CustomerDetails.page";
import { updateFreelancerLevels } from "../../../../Contexts/APIs/Levels/Freelancer/freelancer";
import "./FreelancerLevels.css";

const FreelancerLevelsTemplate = ({
  filterObj,
  getFreelancerLevels,
  freelancersLevelsData,
  data,
  rowData,
  count,
  paginationPage,
  setPaginationPage,
  handlePageChange,
  breadcrumbs,
  roles,
  setFilterObj
}) => {
  const searchInputPlaceholder = "Search Provinces name";
  let [searchParams] = useSearchParams();
  const index = searchParams.get("index");
  const id = searchParams.get("id");
  const navigate = useNavigate();
  const [supportedLang, setSupportedLang] = useState("en");

  const handelSubmit = (data, formik) => {
    const action = () => {
      searchParams.delete("id");
      searchParams.delete("type");
      searchParams.delete("index");
      getFreelancerLevels(filterObj);
      navigate("/levels/freelancer");
      formik?.resetForm();
    };

    updateFreelancerLevels({ id, data: { level: data }, action });
  };

  const actionsList = ({ id, index, details }) => {
    return [
      {
        condition: () => {
          if (roles?.can_read) {
            return true;
          } else {
            return false;
          }
        },
        click: () => {
          navigate(
            `/levels/freelancer/${id}?type=details&id=${id}&index=${index}`
          );
        },
        text: "View details",
      },
      {
        condition: () => {
          if (roles?.can_read && roles?.can_update) {
            return true;
          } else {
            return false;
          }
        },
        click: () => {
          navigate(`?type=form&id=${id}&index=${index}`);
        },
        text: "edit",
      },
    ];
  };

  return (
    <CCrud
      table={{
        list: data,
        rowData: rowData,
        listTitle: "Freelancer Levels",
        emptyListMsg: "There are no Freelancer Levels",
        details: {
          navigatePath: "/levels/freelancer",
          statusField: null,
          hideStatus: null,
        },
        actionsList: actionsList,
        columns: [
          { accessor: "id", Header: "Id" },
          { accessor: "name", Header: "Level name" },
          { accessor: "rank", Header: "Rank" },

          {
            accessor: "days_worked_required",
            Header: "Days worked",
          },

          {
            accessor: "cert_customer_invitations_required",
            Header: "Cert customer invitations",
          },
          {
            accessor: "min_rating_required",
            Header: "Min rating",
          },

          {
            accessor: "min_badges_required",
            Header: "Min badges",
          },
          {
            accessor: "can_create_team_btn",
            Header: "Can create team",
          },

          {
            accessor: "max_no_of_team_members",
            Header: "Max no of team",
          },
          {
            accessor: "flat_commission_reduction_amount",
            Header: "Flat commission reduction amount",
          },
          {
            accessor: `wallet_debt_limit`,
            Header: "Wallet debit limit amount",          
          },      
          {
            accessor: `is_wallet_debit_limit_enabled_btn`,
            Header: "Enable wallet debit limt"
          },
          { accessor: "Actions", Header: "Actions" },
        ],
        isFilter: false,
        isSearch: false,
        isChecked: false,
        isSort: false,
        total_count: count,
        className: "organization-table freelances-table",
        tableContainerOverflow: {overflow: 'auto', paddingBottom: "2.4rem"},
        paginationPage: paginationPage,
        setPaginationPage: setPaginationPage,
        searchInputPlaceholder: searchInputPlaceholder,
        handlePageChange,
        setFilterObj
      }}
      form={{
        structure: () =>
          structure(supportedLang, [{ id: "216", name: "test" }]),
        validationSchema: validationSchema,
        initialValues: !index
          ? {}
          : initialValues(freelancersLevelsData, supportedLang),
        onSubmit: handelSubmit,
        className: { form: "flow-form freelancer-levels-form", actionButton: "flow-form-buttons" },
        handleLanguage: <Languages setLanguage={setSupportedLang} />,
        title: index ? "Edit freelancer level" : "Add freelancer level",
        breadcrumbs: breadcrumbs,
        setSupportedLang: setSupportedLang,
      }}
      DetailsComponent={<CustomerLevelsDetailsPage />}
    />
  );
};

export default FreelancerLevelsTemplate;

import React from 'react'
import RoleFormWrap from '../../../../Components/Organisms/Users/Roles/RoleFormWrap'

function RoleFormTemplate(props) {      

    const {formik, roleDataRes, validationMethod, handlePermissionsChange,handleBatchPermissionsChange, openLoader, formType,rows,handleSelectedRowsChange,optionsStateObj} = props;
  return (
    <RoleFormWrap 
        formik={formik}
        rows={rows}
        optionsStateObj={optionsStateObj}
        handleSelectedRowsChange={handleSelectedRowsChange}
        roleDataRes={roleDataRes}
        validationMethod={validationMethod}
        handlePermissionsChange={handlePermissionsChange}
        handleBatchPermissionsChange={handleBatchPermissionsChange}
        openLoader={openLoader}
        formType={formType}
    />
  )
}

export default RoleFormTemplate
import { axiosFn } from "../..";
import handleErrors from "../../../../services/modules/utils/handel_error";
import { makeFilterString } from "../../Helper/Helper";
import * as URL from "../../URLs";

export async function getCustomerLevelsList(filter_obj) {
  return await axiosFn(
    "get",
    URL.Customer_Levels + makeFilterString(filter_obj)
  )
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleErrors);
}

export async function createCustomerLevels({ data, action }) {
  return await axiosFn("post", URL.Customer_Levels, data)
    .then((res) => {
      action && action();

      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleErrors);
}

export async function getCustomerLevelsDetails(id) {
  return await axiosFn("get", `${URL.Customer_Levels}/${id}`)
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleErrors);
}

export async function deleteCustomerLevels(id) {
  return await axiosFn("delete", `${URL.Customer_Levels}/${id}`)
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleErrors);
}

export async function updateCustomerLevels({ id, data, action }) {
  return await axiosFn("put", `${URL.Customer_Levels}/${id}`, data)
    .then((res) => {
      action && action();
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleErrors);
}

import { axiosFn, handleError } from "..";
import { makeFilterString } from "../Helper/Helper";
import * as URL from "../URLs";

export async function getPriceIncludeList(filter_obj) {
  return await axiosFn("get", URL.Price_Includes + makeFilterString(filter_obj))
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}

export async function createPriceInclude({ data, action }) {
  return await axiosFn("post", URL.Price_Includes, data)
    .then((res) => {
      action && action();
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}

export async function getPriceIncludeDetails(organization_id) {
  return await axiosFn("get", `${URL.Price_Includes}/${organization_id}`)
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}

export async function deletePriceInclude(id, action) {
  return await axiosFn("delete", `${URL.Price_Includes}/${id}`)
    .then((res) => {
      action && action(res?.data);
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}

export async function updatePriceInclude({ id, data, action }) {
  return await axiosFn("put", `${URL.Price_Includes}/${id}`, data)
    .then((res) => {
      action && action();
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}

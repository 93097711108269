import { Grid, Typography, Divider, Button, Menu } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

import SelectBox from "../../Atoms/Select/Select.jsx";
import TextFieldInput from "../../Atoms/Input/TextField/TextFieldInput.jsx";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { useEffect, useState } from "react";
import SearchableSelect from "../../Atoms/Select/SearchableSelect.jsx";
import { CountryAPI } from "../../../Contexts/APIs/Lookups/Country.js";
import { CityAPI } from "../../../Contexts/APIs/Lookups/City.js";
import { AreaAPI } from "../../../Contexts/APIs/Lookups/Area";
import { FreeLancerLevel } from "../../../Contexts/APIs/Lookups/FreelancerLevels.js";
import PrimaryButton from "../../Atoms/Buttons/Primary/PrimaryButton.jsx";
import { toast } from "react-toastify";
import { handlePageChange } from "../../../Contexts/APIs/Helper/Helper.js";

const FreelancersHeaders = ({
  handleChange,
  filterObj,
  setFilterObj,
  setPage,
  handlePaginationPageChange,
}) => {
  const [countries, setCounries] = useState([]);
  const [cities, setCities] = useState([]);
  const [areas, setAreas] = useState([]);
  const [levels, setLevels] = useState([]);

  const statuses = [
    { id: 1, name: "Active" },
    { id: 2, name: "Deactivated" },
    { id: 4, name: "Suspended" },
  ];
  const [country, setCountry] = useState();
  const [city, setCity] = useState();
  const [area, setArea] = useState();
  const [level, setLevel] = useState();
  const [status, setStatus] = useState();
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [countriesCount, setCounriesCount] = useState(0);
  const [citiesCount, setCitiesCount] = useState(0);
  const [areasCount, setAreasCount] = useState(0);
  const [levelsCount, setLevelsCount] = useState(0);
  const statusList = [
    {
      name: "Approve",
      id: 0,
      color: "#00b906",
    },
    {
      name: "Pending",
      id: 1,
      color: "#f6b119",
    },
    {
      name: "Incomplete",
      id: 2,
      color: "#f6b119",
    },
    // {
    //     name: 'Suspend',
    //     id: 3,
    //     color: '#f6b119'
    // },
    {
      name: "Reject",
      id: 4,
      color: "#e10007",
    },
  ];

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const getCountries = async (filter) => {
    const prevCountries = countries;
    const DataKey = filter?.keyword;
    if (
      (filter?.page_number === 1 || filter?.page_number === undefined) &&
      !DataKey &&
      countries.length === 10
    ) {
      setCounries(prevCountries);
    } else {
      const { res, error } =
        countriesCount !== 10 || DataKey
          ? await CountryAPI({ page_size: 10, ...filter })
          : {};
      if (res) {
        filter?.page_number === 1 || filter?.page_number === undefined
          ? setCounries(res?.data?.countries)
          : setCounries((prev) => [...prev, ...res?.data?.countries]);
        setCounriesCount(res?.extra?.total_count);
      }
    }
  };

  const getCities = async (filter) => {
    const prevCities = cities;
    const DataKey = filter?.keyword;
    if (
      (filter?.page_number === 1 || filter?.page_number === undefined) &&
      !DataKey &&
      !filter?.country_id
    ) {
      setCities(prevCities);
    } else {
      const { res, error } =
        citiesCount !== 10 || DataKey
          ? await CityAPI({
              page_size: 10,
              country_id: filter?.country_id,
              ...filter,
            })
          : {};
      if (res) {
        filter?.page_number === 1 || filter?.page_number === undefined
          ? setCities(res?.data?.cities)
          : setCities((prev) => [...prev, ...res?.data?.cities]);
        setCitiesCount(res?.extra?.total_count);
      }
    }
  };

  const getAreas = async (filter) => {
    const prevAreas = areas;
    const DataKey = filter?.keyword;
    if (
      (filter?.page_number === 1 || filter?.page_number === undefined) &&
      !DataKey &&
      !filter?.city_id
    ) {
      setAreas(prevAreas);
    } else {
      const { res, error } =
        areasCount !== 10 || DataKey
          ? await AreaAPI({
              page_size: 10,
              city_id: filter?.city_id,
              ...filter,
            })
          : {};
      if (res) {
        filter?.page_number === 1 || filter?.page_number === undefined
          ? setAreas(res?.data?.areas)
          : setAreas((prev) => [...prev, ...res?.data?.areas]);
        setAreasCount(res?.extra?.total_count);
      }
    }
  };

  const getLevels = async () => {
    const { res, err } = levels.length === 0 && (await FreeLancerLevel());
    if (res) {
      setLevels(res?.data?.levels);
    }
  };

  const handleApplyFilterMenu = () => {
    if (from < 0 || to < 0) {
      toast.error("Rate average can't be negative number");
    } else {
      handlePaginationPageChange && handlePageChange(1, handlePaginationPageChange);
      setFilterObj({
        ...filterObj,
        page_number: 1,
        country_id: country?.value,
        city_id: city?.value,
        area_id: area?.value,
        level_id: level?.value,
        status: status?.label?.toLowerCase(),
        job_rate_from: from,
        job_rate_to: to,
      });
      handleClose();
      setPage(1);
    }
  };
  
  const handleResetFilterMenu = () => {
    handlePaginationPageChange && handlePageChange(1, handlePaginationPageChange);
    setCountry({});
    setCity({});
    setArea({});
    setLevel({});
    setStatus({});
    setFrom(null);
    setTo(null);
    setFilterObj({ page_number: 1 });
    handleClose();
    setPage(1);
  };

  const handleChangeProfileStatus = (obj) => {
    handlePaginationPageChange && handlePageChange(1, handlePaginationPageChange);
    setFilterObj({
      ...filterObj,
      page_number: 1,
      profile_status:
        obj.name === "Approve"
          ? "approved"
          : obj.name === "Reject"
          ? "rejected"
          : obj.name === "Pending"
          ? "pending_approval"
          : // : obj.name === 'Suspend' ? 'suspended'
            obj.name.toLowerCase(),
    });
    setPage(1);
  };

  return (
    <Grid container>
      <Grid item xs={2}>
        <Typography
          component="h1"
          style={{ fontWeight: "bold", fontSize: "30px" }}
        >
          Freelancers
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <TextFieldInput
          type="search"
          placeholder="Search freelancers"
          StartAdornment={<SearchIcon />}
          className="reservation-search"
          onChange={(e) => handleChange("keyword", e.target.value)}
        />
      </Grid>
      <Grid item xs={3.5}></Grid>
      <Grid item xs={1.3}>
        <Button
          aria-label="filter"
          id="filter-button"
          aria-controls={Boolean(anchorEl) ? "filter-menu" : undefined}
          aria-expanded={Boolean(anchorEl) ? "true" : undefined}
          aria-haspopup="true"
          variant="outlined"
          startIcon={<FilterAltIcon sx={{ color: "#bebebe" }} />}
          sx={{
            width: "100%",
            height: "100%",
            color: "#333132",
            borderRadius: "10px",
            border: "solid 1px #ececec",
            fontSize: "16px",
            fontWeight: 500,
            textTransform: "capitalize",
            lineHeight: "unset",
          }}
          onClick={handleClick}
        >
          Filter
        </Button>
        <Menu
          id="filter-menu"
          MenuListProps={{
            "aria-labelledby": "filter-button",
          }}
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          PaperProps={{
            style: {
              width: "330px",
              paddingBottom: "10px",
              borderRadius: "6px",
              border: "solid 1px #bebebe",
              boxShadow: "none",
            },
          }}
        >
          <Typography
            sx={{
              paddingInlineStart: "16px",
              fontSize: "16px",
              fontWeight: 500,
            }}
          >
            Filter by
          </Typography>
          <Divider sx={{ width: "100%", margin: "12px 0" }} />
          <Grid container sx={{ padding: "0 16px" }}>
            <Grid item xs={12}>
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: 500,
                }}
              >
                Location
              </Typography>
            </Grid>
            <Grid item xs={12} mb={2}>
              <SearchableSelect
                name="country_id"
                options={countries}
                getList={getCountries}
                count={countriesCount}
                label="Country"
                className="searchable-select"
                onChange={(val) => {
                  setCountry(val);
                  setCity(null);
                  setArea(null);
                  setCities([]);
                  setAreas([]);
                }}
                value={country}
                onClear={() => {
                  setCountry(null);
                  setCity(null);
                  setArea(null);
                  setCities([]);
                  setAreas([]);
                }}
                onMenuOpen={() => countries.length === 0 && getCountries()}
              />
            </Grid>
            <Grid item xs={12} mb={2}>
              <SearchableSelect
                name="city_id"
                options={cities}
                getList={getCities}
                count={citiesCount}
                label="Province"
                className="searchable-select"
                onChange={(val) => {
                  setCity(val);
                  setArea(null);
                  setAreas([]);
                }}
                value={city}
                onClear={() => {
                  setCity(null);
                  setArea(null);
                  setAreas([]);
                }}
                moreFilterData={{ country_id: country?.value }}
                onMenuOpen={() =>
                  cities.length === 0 &&
                  country?.value &&
                  getCities({ page_number: 1, country_id: country.value })
                }
              />
            </Grid>
            <Grid item xs={12} mb={2}>
              <SearchableSelect
                name="area_id"
                options={areas}
                getList={getAreas}
                count={areasCount}
                label="City"
                className="searchable-select"
                onChange={(val) => setArea(val)}
                value={area}
                onClear={() => setArea(null)}
                moreFilterData={{ city_id: city?.value }}
                onMenuOpen={() =>
                  areas.length === 0 &&
                  city?.value &&
                  getAreas({ page_number: 1, city_id: city.value })
                }
              />
            </Grid>
            <Grid item xs={12}>
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: 500,
                }}
              >
                Level
              </Typography>
            </Grid>
            <Grid item xs={12} mb={2}>
              <SearchableSelect
                name="level_id"
                options={levels}
                onMenuOpen={getLevels}
                label="Select option"
                className="searchable-select"
                onChange={(val) => setLevel(val)}
                value={level}
                onClear={() => setLevel(null)}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: 500,
                }}
              >
                Status
              </Typography>
            </Grid>
            <Grid item xs={12} mb={2}>
              <SearchableSelect
                name="status_id"
                options={statuses}
                label="Select option"
                className="searchable-select"
                onChange={(val) => setStatus(val)}
                value={status}
                onClear={() => setStatus(null)}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: 500,
                }}
              >
                Rate average (in USD)
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <TextFieldInput
                placeholder="From"
                className="reservation-search"
                value={from ? from : ""}
                onChange={(e) => setFrom(e.target.value)}
                style={{ paddingInlineEnd: "4px" }}
                type="number"
              />
            </Grid>
            <Grid item xs={6}>
              <TextFieldInput
                placeholder="To"
                className="reservation-search"
                value={to ? to : ""}
                onChange={(e) => setTo(e.target.value)}
                style={{ paddingInlineStart: "4px" }}
                type="number"
              />
            </Grid>
            <Grid item xs={6} mt={2.5} sx={{ paddingInlineEnd: "10px" }}>
              <PrimaryButton
                text="Reset"
                classBtn="reset-btn"
                click={handleResetFilterMenu}
              />
            </Grid>
            <Grid item xs={6} mt={2.5} sx={{ paddingInlineStart: "10px" }}>
              <PrimaryButton
                text="Apply"
                classBtn="apply-btn"
                click={handleApplyFilterMenu}
              />
            </Grid>
          </Grid>
        </Menu>
      </Grid>
      <Grid item xs={0.2}></Grid>
      <Grid item xs={2}>
        <SelectBox
          items={statusList}
          title="Profile Status"
          className="select"
          defaultValue=""
          onchange={(e) => {
            const selectedObj = statusList.find(
              (item) => item?.id === e.target?.value
            );
            handleChangeProfileStatus(selectedObj);
          }}
        />
      </Grid>
      <Divider style={{ width: "100%", margin: "20px 0" }} />
    </Grid>
  );
};

export default FreelancersHeaders;

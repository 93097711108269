import { useEffect, useState } from "react";
import { Firebase } from "./Firebase/Firebase.js";
import RoutesPage from "./RoutesPage.js";
import "react-toastify/dist/ReactToastify.css";
import { CreateAuthToken } from "./Contexts/APIs/Auth/CreateAuthToken.js";
import Cookies from "js-cookie";
import "./App.css";

function App() {
  // const firebaseAuthToken = Cookies.get("firebase_auth_token");

  // const handleSignInFirebase = async () => {
  //   Firebase.getFCMToken();
  //   const reg = async () => {
  //     await Firebase.SignInFirebase(
  //       firebaseAuthToken || localStorage.getItem("firebase_auth_token")
  //     );
  //   };
  //   reg();
  // };

  // const createAuthToken = async () => {
  //   const date = new Date();
  //   date.setTime(date.getTime() + 1 * 60 * 60 * 1000); // Multiply by milliseconds in a day
  //   let expires = date;
  //   const { res, err } = await CreateAuthToken();
  //   if (res) {
  //     Cookies.set("firebase_auth_token", res?.data?.firebase_auth_token, {
  //       expires,
  //     });
  //     localStorage.setItem(
  //       "firebase_auth_token",
  //       res?.data?.firebase_auth_token
  //     );
  //     handleSignInFirebase();
  //   }
  // };

  // useEffect(() => {
  //   const checkCookie = () => {
  //     const token = Cookies.get("firebase_auth_token");
  //     const userToken = localStorage.getItem("Token");
  //     if (
  //       !token &&
  //       userToken
  //     ) {
  //       createAuthToken();
  //     } else {
  //       // console.log('Token exists:', token);
  //     }
  //   };

  //   checkCookie();
  //   const interval = setInterval(() => {
  //     checkCookie();
  //   }, 60000);
  //   return () => clearInterval(interval);
  // }, []);

  return (
    <div className="App">
      <RoutesPage />
    </div>
  );
}

export default App;

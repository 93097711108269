import React, { useEffect, useState } from "react";
import Loader from "../../../../Components/Atoms/Loader/Loader";
import {
  deleteFeatureOption,
  getFeatureOptionDetails,
  getFeatureOptionsList,
  updateFeatureOption,
} from "../../../../Contexts/APIs/FeatureOptions/FeatureOptions";
import FeatureOptionsTemplate from "../../../../Templates/Main/PredefinedLookups/FeatureOptions/FeatureOptions.template";
import { Box, Stack } from "@mui/material";
import usePageFilter from "../../../../Components/Atoms/Hooks/usePageFilter";

function FeatureOptionsPage({ roles }) {
  // let [searchParams] = useSearchParams();
  const {
    page,
    filterObj,
    handlePageChange: onPageChange,
    setFilterObj,
    setPage,
    searchParams,
  } = usePageFilter();
  const id = searchParams.get("id");

  const [openLoader, setOpenLoader] = useState(false);
  const [featureOptionList, setFeatureOptionList] = useState([]);

  const [count, setCount] = useState(0);
  // const [page, setPage] = useState(1);

  // const [filterObj, setFilterObj] = useState(null);

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [featureOptionData, setFeatureOptionData] = useState({});

  // const updatePagination = (page) => {
  //   setFilterObj({ ...filterObj, page_number: page });
  // };

  // const handlePageChange = (event, value) => {
  //   setPage(value);
  //   updatePagination(value);
  // };

  const getFeatureOptions = async (filterObj) => {
    setOpenLoader(true);
    const defaultFilter = {
      page_size: 10,
      page_number: 1,
      keyword: "",
      sort_by: "id",
    };
    const { res, error } = await getFeatureOptionsList({
      ...defaultFilter,
      ...filterObj,
    });
    if (res) {
      setOpenLoader(false);
      setFeatureOptionList(res?.data?.feature_options);
      setCount(res?.extra?.total_count);
    }
    if (error) {
      setOpenLoader(false);
    }
  };
  const getFeatureOptionsData = async () => {
    setOpenLoader(true);
    const { res, error } = await getFeatureOptionDetails(id);
    if (res) {
      setFeatureOptionData(res.data.feature_option);
      setOpenLoader(false);
    }
    if (error) {
      setOpenLoader(false);
    }
  };

  useEffect(() => {
    if (filterObj) getFeatureOptions(filterObj);
  }, [filterObj]);
  useEffect(() => {
    id && getFeatureOptionsData({});
  }, [id]);
  const rowData = (data) => {
    setSelectedRow(data?.rowData);
  };
  // mappedData use for create custom cell to concat multi values or navigate button
  const mappedData = featureOptionList?.map((data) => {
    return {
      ...data,
      id_data: <Stack>{data?.id}</Stack>,
      image: (
        <Box width="100px">
          <Box
            component="img"
            src={data?.icon}
            width="30px"
            height="30px"
            sx={{ ml: 2 }}
          />
        </Box>
      ),
      is_hidden_data: (
        <Stack
          sx={{
            background: data?.is_hidden ? "#62c08b" : "#cc4d49",
            color: "white",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "10px",
            padding: "10px",
            height: "50px",
            width: "80px",
            cursor:
              roles?.can_read && roles?.can_update ? "pointer" : "default",
          }}
          onClick={() => {
            if (roles?.can_read && roles?.can_update) {
              // const action = () => window.location.reload();
              const action = () => {
                setOpenDeleteModal(true);
                getFeatureOptions(filterObj);
                setFilterObj({ ...filterObj, page_number: 1 });
                setPage(1);
                setOpenDeleteModal(false);
              };
              updateFeatureOption({
                id: data?.id,
                data: {
                  feature_option: { ...data, is_hidden: !data?.is_hidden },
                },
                action,
              });
            }
          }}
        >
          {data?.is_hidden ? "Yes" : "No"}
        </Stack>
      ),
    };
  });
  const handleApplyFilterMenu = (obj) => {
    setFilterObj((prev) => {
      return { ...prev, ...obj, page_number: 1 };
    });
    setPage(1);
  };
  const handleResetFilterMenu = () => {
    setFilterObj({
      page_size: 10,
      page_number: 1,
      keyword: "",
      sort_by: "id",
    });
    setPage(1);
  };

  const handleDeleteRequest = async () => {
    const action = (data) => {
      setOpenDeleteModal(false);
      setFeatureOptionList((prevState) =>
      prevState?.filter((ele) => ele?.id !== data?.data?.feature_option?.id))
    };
    deleteFeatureOption(selectedRow?.id, action);
  };
  const breadcrumbs = [
    {
      active: true,
      path: "/predefined-lookups/feature-options",
      title: "Feature option",
    },
    {
      active: false,
      title: id ? "Edit  feature option" : "Add  new feature option",
    },
  ];
  return (
    <>
      <Loader open={openLoader} />
      <FeatureOptionsTemplate
        filterObj={filterObj}
        setFilterObj={setFilterObj}
        data={mappedData}
        count={count}
        paginationPage={page}
        setPaginationPage={setPage}
        handlePageChange={onPageChange}
        openDeleteModal={openDeleteModal}
        setOpenDeleteModal={setOpenDeleteModal}
        rowData={rowData}
        details={featureOptionData}
        handleApplyFilterMenu={handleApplyFilterMenu}
        handleResetFilterMenu={handleResetFilterMenu}
        handleDeleteRequest={handleDeleteRequest}
        breadcrumbs={breadcrumbs}
        roles={roles}
      />
    </>
  );
}

export default FeatureOptionsPage;

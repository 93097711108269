import { makeFilterString } from "../Helper/Helper.js";
import { axiosFn, handleError } from "../index.js";
import * as URL from "../URLs";

export async function getUsersList(filter_obj) {
    return await axiosFn("get", URL.Descendant_users + makeFilterString(filter_obj))
      .then((res) => {
        return {
          res: res.data,
          err: null,
        };
      })
      .catch(handleError);
}


export async function createDescendantUser(data) {
    return await axiosFn("post", URL.Descendant_users, data)    
      .then((res) => {        
        return {
          res: res.data,
          err: null,
        };
      }) 
      .catch(handleError);
}


export async function showDescendantUser(id) {
  return await axiosFn("get", `${URL.Descendant_users}/${id}`)    
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}


export async function editDescendantUser(id , data) {
  return await axiosFn("put", `${URL.Descendant_users}/${id}`,data)
      .then((res) => {
          return {
              res: res.data,
              err: null,
          };
      })
      .catch(handleError);
}


export async function deleteDescendantUser(id) {
    return await axiosFn("delete", `${URL.Descendant_users}/${id}`)
    .then((res) => {
            return {
                res: res.data,
                err: null,
            };
        })
        .catch(handleError);
}
import { Stack, Typography } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useEffect, useState } from "react";
import TextFieldInput from "../../Input/TextField/TextFieldInput";
import PrimaryButton from "../../Buttons/Primary/PrimaryButton";
import { useSearchParams } from "react-router-dom";
import { handlePageChange } from "../../../../Contexts/APIs/Helper/Helper";

const Header = ({
  Title,
  isFilter,
  isSearch,
  setFilterObj,
  filterList,
  createBtnTitle,
  createBtnFun,
  CreateBtnIcon,
  hideCreateBtn,
  createBtnClass,
  createBtnDisable,
  setPaginationPage,
  searchInputPlaceholder,
  children,
  searchAttribute = "keyword",
  childrenType = "normal",
  handleChange,
}) => {
  const [value, setValue] = useState(null);
  const [searchParams] = useSearchParams();
  const pageNumber = searchParams.get("page_number") || 1;

  useEffect(() => {
    if (!+searchParams.get("page_number")) {
      setPaginationPage(1);
      setFilterObj((filterObj) => {
        return { ...filterObj, page_number: 1 };
      });
    } else {
      setPaginationPage(+pageNumber);
      setFilterObj((filterObj) => {
        return { ...filterObj, page_number: pageNumber };
      });
    }
  }, [searchParams.get("page_number")]);

  useEffect(() => {
    const timeId = setTimeout(() => {
      setFilterObj &&
        value?.type &&
        setFilterObj((data) => {
          return {
            ...data,
            page_number: value?.keyword?.length == 0 ? pageNumber : 1,
            [searchAttribute]: value?.keyword?.trim(),
          };
        });
    }, 500);
    // setPaginationPage(1);
    return () => clearTimeout(timeId);
  }, [value]);

  return (
    <Stack direction="column" alignItems="center" width="100%">
      <Stack
        width="100%"
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        py={2}
      >
        <Stack width="auto" direction="row" alignItems="center">
          <Typography fontSize="18px" fontWeight="600" gutterBottom>
            {Title}
          </Typography>

          {isSearch && (
            <Stack>
              <TextFieldInput
                type={"search"}
                onChange={(e) => {
                  handleChange && handlePageChange(1, handleChange);
                  setValue({ keyword: e.target.value, type: e?.type });
                }}
                placeholder={searchInputPlaceholder}
                StartAdornment={<SearchIcon />}
                value={value?.keyword ? value?.keyword : ""}
                className="search-input"
              />
            </Stack>
          )}
        </Stack>

        <Stack
          direction="row"
          width="auto"
          gap={2}
          alignSelf="end"
          justifyContent="end"
        >
          <>{isFilter && <>{filterList}</>}</>
          {childrenType == "normal" && children}
          {createBtnFun && hideCreateBtn != true && (
            <PrimaryButton
              text={createBtnTitle}
              startIcon={CreateBtnIcon && <CreateBtnIcon />}
              classBtn={createBtnClass}
              click={() => createBtnFun()}
              disabled={createBtnDisable}
            />
          )}
        </Stack>
      </Stack>

      {childrenType != "normal" && children}
    </Stack>
  );
};

export default Header;

import * as Yup from "yup";
import { icons } from "../../../Assets/AssetHelper";
export const initialValues = (data) => {
  return {
    ...data,
  };
};
export const structure = () => [
  {
    head: "Partner information",
    list: [
      {
        name: `name`,
        kind: "input",
        type: "text",
        label: "Partner name",
        width: "100%",
      },
    ],
  },
  {
    head: "Logo image",
    list: [
      {
        name: `logo`,
        kind: "upload",
        uploadIcon:icons.addImage,
        label: "Add logo image",
        width: "45%",
      },
    ],
  },
];

export const validationSchema = () =>
  Yup.object({
    name: Yup.string().required("required"),
  });

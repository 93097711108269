import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import PagesHead from "../../Molecules/Users/PagesHead";
import SearchIcon from "@mui/icons-material/Search";
import UsersList from "../../Molecules/Users/UsersList/UsersList";
import { useNavigate } from "react-router-dom";
import Loader from "../../Atoms/Loader/Loader";

const Users = (props) => {
  const {
    filterObj,
    setFilterObj,
    usersList,
    setUsersList,
    openLoader,
    setOpenLoader,
    count,
    page,
    handlePageChange,
    usersPrivilages,
    rolesPrivilages,
    handleApplyFilterMenu,
    handleResetFilterMenu,
    filterList,
  } = props;
  const navigate = useNavigate();
  const userType = localStorage.getItem("userType");

  const primaryButtonsArray = [];

  if (userType !== "descendant_user") {
    primaryButtonsArray.push(
      {
        fontSize: "14px",
        text: "Log history",
        classBtn: "primary r-25px primary-outlined",
        style: {
          padding: "5px 20px",
          height: "48px",
          marginLeft: "1rem",
        },
        click: () => navigate("/users/log-history"),
      }
    );
  }

  if (
    userType !== "descendant_user" ||
    (userType === "descendant_user" &&
      usersPrivilages?.can_read &&
      rolesPrivilages?.can_read)
  ) {
    primaryButtonsArray.push(
      {
        fontSize: "14px",
        text: "Roles Details",
        classBtn: "primary r-25px primary-outlined",
        style: {
          padding: "5px 20px",
          height: "48px",
          marginRight: "1rem",
        },
        click: () => navigate("/users/roles"),
      }
    );
  }

  if (
    userType !== "descendant_user" ||
    (userType === "descendant_user" &&
      usersPrivilages?.can_read &&
      usersPrivilages?.can_create)
  ) {
    primaryButtonsArray.push({
      fontSize: "14px",
      startIcon: true,
      text: "Add new user",
      classBtn: "primary r-25px",
      style: { padding: "5px 20px", height: "48px" },
      click: () => navigate("/users/add"),
    });
  }

  return (
    <>
      <Loader open={openLoader} />
      <Grid container>
        <PagesHead
          title="Users"
          searchInput={{
            type: "search",
            placeholder: "Search-users",
            StartAdornment: { SearchIcon },
            className: "Search-users",
          }}
          /*primaryButtons={
            userType !== "descendant_user" || 
            (userType === "descendant_user" && usersPrivilages?.can_create)
              ? [
                  {
                    fontSize: "14px",
                    text: "Roles Details",
                    classBtn: "primary r-25px primary-outlined",
                    style: {
                      padding: "5px 20px",
                      height: "48px",
                      marginRight: "1rem",
                    },
                    click: () => navigate("/users/roles"),
                  },
                  {
                    fontSize: "14px",
                    startIcon: true,
                    text: "Add new user",
                    classBtn: "primary r-25px",
                    style: { padding: "5px 20px", height: "48px" },
                    click: () => navigate("/users/add"),
                  },
                ]
              : [
                  {
                    fontSize: "14px",
                    text: "Roles Details",
                    classBtn: "primary r-25px primary-outlined",
                    style: {
                      padding: "5px 20px",
                      height: "48px",
                      marginRight: "1rem",
                    },
                    click: () => navigate("/users/roles"),
                  },
                ]
          }*/
          primaryButtons={primaryButtonsArray}
          filterObj={filterObj}
          setFilterObj={setFilterObj}
          filter={{
            filterList: filterList,
            handleApplyFilterMenu: handleApplyFilterMenu,
            handleResetFilterMenu: handleResetFilterMenu,
          }}
          handleChange={handlePageChange}
        />
        <UsersList
          usersList={usersList}
          setUsersList={setUsersList}
          setOpenLoader={setOpenLoader}
          count={count}
          page={page}
          handlePageChange={handlePageChange}
          usersPrivilages={usersPrivilages}
        />
      </Grid>
    </>
  );
};

export default Users;

import * as Yup from "yup";
export const initialValues = (data) => ({
  ...data,
  en: {
    name: data?.name,
  },
});
export const structure = (lang) => [
  {
    head: "User information",
    list: [
      {
        name: `${lang}.name`,
        kind: "input",
        type: "text",
        label: "Level",
        width: "50%",
      },
      {
        name: `reservations_required`,
        kind: "input",
        type: "number",
        label: "Reservation required",
        width: "50%",
      },
      {
        name: `flat_points_earned_inc`,
        kind: "input",
        type: "number",
        label: "Flat point earned Inc",
        width: "50%",
      },
    ],
  },
];

export const validationSchema = () =>
  Yup.object({
    en: Yup.object().shape({
      name: Yup.string().required("required"),
    }),
    reservations_required: Yup.number().required("required"),
    flat_points_earned_inc: Yup.number().required("required"),
  });

import { Grid } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import CustomPagination from "../../../Atoms/Pagination/Pagination";
import "./JobOffersList.css";

function JobOffersList(props) {
  const {
    jobOffersList,
    count,
    page,
    handlePageChange,
    roles,
    applicants_roles,
  } = props;
  const navigate = useNavigate();
  return (
    <Grid container>
      <Grid container className="table-header job-offer-header mb-20px">
        <Grid item xs={1} md={0.75} lg={0.5} textAlign="center">
          ID
        </Grid>
        <Grid item xs={2} md={1} lg={0.8}>
          Job title
        </Grid>
        <Grid item xs={2} md={2} lg={1.1} pr={1.5}>
          Dive center name
        </Grid>
        <Grid item xs={2} md={2} lg={0.8} pr={1.5}>
          Branch name
        </Grid>
        <Grid item xs={2} md={1} lg={0.8}>
          Job type
        </Grid>
        <Grid item xs={2} md={1.5} lg={0.9}>
          Dive expert level
        </Grid>

        <Grid item xs={2} md={1.5} lg={0.9}>
          Activity type
        </Grid>
        <Grid item xs={2} md={1} lg={0.6}>
          rate
        </Grid>
        <Grid item xs={2} md={2} lg={1.1}>
          Early notification
        </Grid>
        <Grid item xs={2} md={2} lg={1.1}>
          High priority
        </Grid>

        <Grid item xs={2} md={1} lg={0.8}>
          Status
        </Grid>
        <Grid item xs={2} md={2} lg={1.3} pl={1.5}>
          Applicants / hired
        </Grid>
        {!roles?.can_read ? null : (
          <Grid item xs={2} md={1.5} lg={1.3}>
            Action
          </Grid>
        )}
      </Grid>

      {jobOffersList?.length === 0 && (
        <Grid container display="block" textAlign="center">
          There are no job offers
        </Grid>
      )}

      {jobOffersList?.length >= 1 && (
        <>
          {jobOffersList?.map((item, index) => {
            return (
              <Grid
                key={index}
                container
                item
                lg={12}
                className="job-offer-item mb-20px"
              >
                <Grid
                  item
                  xs={1}
                  md={0.75}
                  lg={0.5}
                  className="job-offer-item-no"
                  textAlign="center"
                >
                  {item?.id}
                </Grid>
                <Grid
                  item
                  xs={2}
                  md={1}
                  lg={0.8}
                  className="job-offer-item-name"
                >
                  {item?.title}
                </Grid>
                <Grid item xs={2} md={2} lg={1.1} pr={1.5}>
                  {item?.dive_center?.name}
                </Grid>
                <Grid item xs={2} md={2} lg={0.8} pr={1.5}>
                  {item?.branch?.name}
                </Grid>
                <Grid item xs={2} md={1} lg={0.8}>
                  {item?.job_type?.replaceAll("_", " ")}
                </Grid>
                <Grid item xs={2} md={1.5} lg={0.9}>
                  {item?.dive_expert_professional_level?.replaceAll("_", " ")}
                </Grid>
                <Grid item xs={2} md={1.5} lg={0.9}>
                  {item?.activity_type === "live_aboard"
                    ? "liveaboard"
                    : item?.activity_type?.replaceAll("_", " ")}
                </Grid>
                <Grid item xs={2} md={1} lg={0.6}>
                  {item?.rate}
                </Grid>
                <Grid item xs={2} md={2} lg={1.1}>
                  <div
                    className={`${
                      item?.reach_x_applicants === true ||
                      item?.reach_favorite_first === true
                        ? "job-offer-verified"
                        : "job-offer-not-verified"
                    }`}
                  >
                    {" "}
                    {item?.reach_x_applicants === true ||
                    item?.reach_favorite_first === true
                      ? "Yes"
                      : "No"}
                  </div>
                </Grid>
                <Grid item xs={2} md={2} lg={1.1}>
                  <div
                    className={`${
                      item?.is_high_priority === true
                        ? "job-offer-verified"
                        : "job-offer-not-verified"
                    }`}
                  >
                    {item?.is_high_priority === true ? "Yes" : "No"}
                  </div>
                </Grid>

                <Grid
                  item
                  xs={2}
                  md={1}
                  lg={0.8}
                  sx={{
                    color: `${
                      item?.status === "hired" ||
                      item?.status === "qr_scanned" ||
                      item?.status === "completed"
                        ? "#00b906"
                        : item?.status === "pending"
                        ? "#f6b119"
                        : item?.status === "deleted"
                        ? "#e10007"
                        : "#333132"
                    }`,
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  {item?.status === "qr_scanned" ? "Scanned QR" : item?.status}
                </Grid>

                {/* send accepted job request status, job offer title to ApplicantDetails component  */}
                <Grid
                  item
                  xs={2}
                  md={2}
                  lg={1.3}
                  className="applicant-wrap"
                  pl={1.5}
                >
                  {/* {item?.status === "hired" ||
                  item?.status === "qr_scanned" ||
                  item?.status === "completed" ? (
                    <> */}
                      <p
                        style={{
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          applicants_roles?.can_read
                            ? navigate(
                                "/operators/job-offers/" +
                                  item?.id +
                                  "/applicants"
                              )
                            : null
                        }
                      >
                        {item?.number_of_applicants} applicant
                      </p>
                      {
                        item?.accepted_job_request ? <span
                          style={{
                            textDecoration: "underline",
                            cursor: "pointer",
                          }}
                          // onClick={() =>
                          //   applicants_roles?.can_read
                          //     ? navigate(
                          //         "/operators/job-offers/" +
                          //           item?.id +
                          //           "/applicants/" +
                          //           item?.accepted_job_request?.dive_expert?.id,
                          //         {
                          //           state: {
                          //             status: item?.accepted_job_request?.status,
                          //             title: item?.title,
                          //           },
                          //         }
                          //       )
                          //     : null
                          // }
                        >{`${item?.accepted_job_request?.dive_expert?.firstname} ${item?.accepted_job_request?.dive_expert?.lastname} (hired)`}</span>
                        : null
                      }
                    {/* </>
                  ) : (
                    <p
                      style={{ textDecoration: "underline", cursor: "pointer" }}
                      onClick={() =>
                        applicants_roles?.can_read
                          ? navigate(
                              "/operators/job-offers/" +
                                item?.id +
                                "/applicants"
                            )
                          : null
                      }
                    >
                      {item?.number_of_applicants} applicant
                    </p>
                  )} */}
                </Grid>
                <Grid item xs={2} md={1.5} lg={1.3}>
                  <button
                    onClick={() =>
                      roles?.can_read
                        ? navigate("/operators/job-offers/" + item?.id)
                        : null
                    }
                  >
                    view details
                  </button>
                </Grid>
              </Grid>
            );
          })}
          {count > 10 && (
            <Grid
              container
              item
              justifyContent={"center"}
              xs={12}
              mb={7.5}
              mt={5}
            >
              <CustomPagination
                count={Math.ceil(count / 10)}
                size="large"
                shape="rounded"
                page={page}
                handleChange={handlePageChange}
                className="pagination"
              />
            </Grid>
          )}
        </>
      )}
    </Grid>
  );
}

export default JobOffersList;

import axios from "axios";
import { toast } from "react-toastify";
import { BASE_URL } from "./URLs";
import { LogoutAPI } from "./Auth/Logout";
import Cookies from "js-cookie";

export function axiosFn(
  method,
  url,
  recivedData,
  auth = true,
  contentType = "application/json",
  responseType = "json" // Default to JSON
) {
  let token =
    localStorage.getItem("Token") || localStorage.getItem("accessToken");

  let data = { ...recivedData };
  let verifyToken = localStorage.getItem("VERIFY");
  
  return axios({
    method,
    url: BASE_URL + url,
    data,
    headers: {
      Authorization: verifyToken ? "" : `Bearer ${token}`,
      "Content-Type": contentType,
      "Accept": responseType === "blob" ? "text/csv" : "application/json",
      "verification-token": verifyToken,
      "Accept-Language": localStorage.getItem("lang"),
      Timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
    responseType // Add responseType here
  });
}

const Logout = async () => {
  const { res, err } = await LogoutAPI();
  if (res) {
    localStorage.clear();
    Cookies.remove("firebase_auth_token");
    window.location.reload();
  }
  if (err) toast.error(err);
  localStorage.clear();
  window.location.reload();
};

export function handleError(err) {
  let errMsg;
  if (err?.response){
    if(err?.response?.data?.error === 'invalid_token' && err.response.status === 401){
      Logout();
    }else{
      toast.error(err?.response?.data?.message);
    }
  } 
  return {
    res: null,
    err: errMsg,
  };
}

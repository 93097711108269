import { Grid} from "@mui/material";
import { useNavigate } from "react-router-dom";
import CustomPagination from "../../../Atoms/Pagination/Pagination.js";
import moment from "moment";
import "./ListGroupsForActivities.css";

const ListGroupsForActivities = (props) => {
    const {  count, activityGroups, page, handlePageChange } = props;
    const Navigate = useNavigate();

    return (
        <Grid container>
            <Grid container className="table-header activity-groups-list-head mb-20px">
                <Grid md={1} textAlign="center">ID</Grid>
                <Grid md={3}>Activity date / Time</Grid>
                <Grid sm={2}>Booked/Capacity</Grid>
                {/* <Grid sm={2}>Reception </Grid> */}
                <Grid sm={2}>Status</Grid>
                {/* <Grid sm={2}>Action</Grid> */}
            </Grid>
            {activityGroups?.length === 0 && (
                <Grid container display="block" textAlign="center">
                    There are no records
                </Grid>
            )}
            {activityGroups?.map((activityItem, index) => (
                <Grid
                    key={index}
                    // onClick={() => Navigate('/reservations/activities/activity-groups/reservations-list/' + activityItem?.id)} 
                    container lg={12} className="activity-item mb-20px">
                    <Grid md={1} sx={{ fontSize: '20px', fontWeight: 'bold', color: '#cccccc', textAlign: 'center' }} >{activityItem?.id}</Grid>
                    {/* <Grid md={3} fontWeight="bold">{activityItem?.start_date} / {moment(activityItem?.start_time).format('h a')}</Grid> */}
                    <Grid md={3} fontWeight="bold">{moment(activityItem?.start_date).format('DD-MM-YYYY')} / {moment(activityItem?.start_time).format('h a')}</Grid>
                    <Grid sm={2}>{activityItem?.capacity - activityItem?.remaining_capacity} / {activityItem?.capacity}</Grid>
                    <Grid sm={2}>{activityItem?.status}</Grid>
                </Grid>
            ))}
            {count > 10 &&
                <Grid container justifyContent='center' mb={7.5} mt={5}>
                    <CustomPagination
                        count={Math.ceil(count / 10)}
                        size='large'
                        shape="rounded"
                        page={page}
                        handleChange={handlePageChange}
                        className='pagination'
                    />
                </Grid>
            }
        </Grid>
    )
}

export default ListGroupsForActivities
import React, { useEffect } from "react";
import BranchesList from "../../Molecules/Branches/BranchesList";
import { Grid } from "@mui/material";
import Loader from "../../Atoms/Loader/Loader";
import BranchHead from "../../Molecules/Branches/BranchHead/BranchHead";
import { useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate, useSearchParams } from "react-router-dom";

function Branches(props) {
  const {
    filterObj,
    setFilterObj,
    branchesList,
    openLoader,
    count,
    page,
    setPage,
    handlePageChange,
    getBranches,
    roles,
    setBranchesList,
  } = props;

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const branchType = searchParams.get("branch_type");

  useEffect(() => {
    setTimeout(() => {
      if (branchType === "") {
        setFilterObj({
          ...filterObj,
          page_number: filterObj?.page_number || 1,
          branch_type: "",
        });
        setBranchValue(0);
      }
      if (branchType === "safari_boat") {
        setFilterObj({
          ...filterObj,
          page_number: filterObj?.page_number || 1,
          branch_type: "safari_boat",
        });
        setBranchValue(1);
      }
      if (branchType === "office") {
        setFilterObj({
          ...filterObj,
          page_number: filterObj?.page_number || 1,
          branch_type: "office",
        });
        setBranchValue(2);
      }
    }, 500);
  }, [branchType]);

  const [BranchValue, setBranchValue] = useState(0);
  const BranchesTypes = [
    {
      id: 1,
      label: "all",
    },
    {
      id: 2,
      label: "safari Boat",
    },
    {
      id: 3,
      label: "dive Center",
    },
  ];

  const handleBranchValue = (event, newValue) => {
    setBranchValue(newValue);
    if (newValue === 0) navigate("?branch_type=all");
    if (newValue === 1) navigate("?branch_type=safari_boat");
    if (newValue === 2) navigate("?branch_type=office");
  };

  const getBranchType = (value) => {
    setPage(1);
  };

  return (
    <>
      <Loader open={openLoader} />
      <Grid container>
        <BranchHead
          title="Branches"
          searchInput={{
            type: "search",
            placeholder: "Search Branches",
            StartAdornment: { SearchIcon },
            className: "Search-branch",
          }}
          filterObj={filterObj}
          setFilterObj={setFilterObj}
          options={BranchesTypes}
          BranchValue={BranchValue}
          handleBranchValue={handleBranchValue}
          getBranchType={(value) => getBranchType(value)}
          getBranches={getBranches}
          setPage={setPage}
          handleChange={handlePageChange}
        />
        <BranchesList
          branchesList={branchesList}
          count={count}
          page={page}
          handlePageChange={handlePageChange}
          getBranches={getBranches}
          filterObj={filterObj}
          setFilterObj={setFilterObj}
          roles={roles}
          setBranchesList={setBranchesList}
        />
      </Grid>
    </>
  );
}

export default Branches;

import React from "react";
import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import CCrud from "../../../../Components/Molecules/General/Crud/CCrud";
import { initialValues, structure, validationSchema } from "./structure";
import AddIcon from "@mui/icons-material/Add";
import { updatePointConfig } from "../../../../Contexts/APIs/PointsConfig/PointsConfig";
import { action_type, per_type, point_type, user_type } from "./typesData";
import "../../../../Components/Atoms/Shared/Styles/list-page.css";
import "./PointsConfiguration.css";
const PointsConfigurationTemplate = ({
  setFilterObj,
  data,
  rowData,
  count,
  paginationPage,
  setPaginationPage,
  handlePageChange,
  pointConfigData,
  handleApplyFilterMenu,
  handleResetFilterMenu,
  breadcrumbs,
  roles,
}) => {
  let [searchParams, setSearchParams] = useSearchParams();
  const index = searchParams.get("index");

  const id = searchParams.get("id");
  const navigate = useNavigate();
  const [supportedLang, setSupportedLang] = useState("en");

  const actionColumn =
    !roles?.can_read && !roles?.can_update
      ? {
          accessor: ".",
          Header: " ",
        }
      : { accessor: "action", Header: "Actions" };

  const handelSubmit = (data, formik) => {
    const action = () => {
      searchParams.delete("id");
      searchParams.delete("type");
      searchParams.delete("index");
      formik?.resetForm();

      navigate("/system-configurations/points");
    };

    if (index) {
      updatePointConfig({ id, data: { point_configuration: data }, action });
    }
  };

  const filterList = [
    {
      key: "user_type",
      title: "User type",
      list: user_type,
    },
    {
      key: "point_type",
      title: "Point type",
      list: point_type,
    },
    {
      key: "action_type",
      title: "Action type",
      list: action_type,
    },
    {
      key: "per_type",
      title: "Per type",
      list: per_type,
    },
  ];

  return (
    <CCrud
      table={{
        list: data,
        filterList,
        listTitle: "Point configurations",
        emptyListMsg: "There are no Point configurations",
        details: {
          navigatePath: "/system-configurations/points",
          statusField: null,
          hideStatus: null,
        },
        handleApplyFilterMenu: handleApplyFilterMenu,
        handleResetFilterMenu: handleResetFilterMenu,
        columns: [
          { accessor: "id", Header: "Id" },
          { accessor: "user_type", Header: "User type" },
          { accessor: "point_type_data", Header: "Point type" },
          { accessor: "num_of_points", Header: "Num of points" },
          { accessor: "per_amount", Header: "Per amount" },
          { accessor: "per_type", Header: "Per type" },
          { accessor: "action_type", Header: "Action type" },
          actionColumn,
        ],
        isFilter: true,
        isSearch: false,
        isChecked: false,
        isSort: false,
        total_count: count,
        setFilterObj,
        rowData: rowData,
        CreateBtnIcon: AddIcon,
        createBtnClass: "add-organization-button",
        className: "organization-table points-config-table",
        paginationPage: paginationPage,
        setPaginationPage: setPaginationPage,
        handlePageChange,
        roles,
      }}
      form={{
        structure: () =>
          structure(
            supportedLang,
            user_type,
            point_type,
            action_type,
            per_type
          ),
        validationSchema: validationSchema,
        initialValues: !index
          ? {}
          : initialValues(pointConfigData, supportedLang),
        onSubmit: handelSubmit,
        className: { form: "flow-form", actionButton: "flow-form-buttons" },
        breadcrumbs: breadcrumbs,
        handleLanguage: <></>,
        title: "Edit Point configuration",
      }}
    />
  );
};

export default PointsConfigurationTemplate;

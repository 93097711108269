import React, { useEffect, useState } from "react";
import HomeTemplate from "../../Templates/Main/Home.template";
import { useSearchParams } from "react-router-dom";

const HomePage = ({ profileData }) => {
  const [filterObj, setFilterObj] = useState({
    interval_type: "month",
    keyword: "",
    page_number: 1,
  });

  const [searchParams, setSearchParams] = useSearchParams();
  const pageNumber = searchParams.get("page_number") || 1;

  const handleChange = (name, value) =>
    setFilterObj({
      ...filterObj,
      [name]: name == "keyword" ? value.trim() : value,
    });

  const [page, setPage] = useState(1);
  const handlePageChange = (event, value) => {
    setSearchParams({ page_number: value });
  };

  useEffect(() => {
    if (!+searchParams.get("page_number")) {
      setPage(1);
      handleChange("page_number", 1);
    } else {
      setPage(+pageNumber);
      handleChange("page_number", +pageNumber);
    }
  }, [searchParams.get("page_number")]);
  return (
    <HomeTemplate
      handleChange={handleChange}
      filterObj={filterObj}
      setFilterObj={setFilterObj}
      setPage={setPage}
      profileData={profileData}
    />
  );
};

export default HomePage;

import { axiosFn, handleError } from "..";
import { makeFilterString } from "../Helper/Helper";
import * as URL from "../URLs";

export async function getCustomersList(filter_obj) {
  return await axiosFn(
    "get",
    URL.users + "customer" + makeFilterString(filter_obj)
  )
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}

export async function getCustomerDetails(customer_id) {
  return await axiosFn("get", `profile/customer/${customer_id}/show`)
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}

export async function updateCustomer(data) {
  return await axiosFn("put", `/profile/update`, data)
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}

export async function deleteCustomer(id, action) {
  return await axiosFn("delete", URL.users + "customer/delete/" + id)
    .then((res) => {
      action && action();
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}

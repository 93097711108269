import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Grid, Typography } from "@mui/material";
import { deleteDescendantUser } from "../../../../Contexts/APIs/Users/UsersList";
import CustomPagination from "../../../Atoms/Pagination/Pagination";
import ActionsMenu from "../ActionsMenu";
import "./UsersList.css";
import { icons } from "../../../../Assets/AssetHelper";
import PhoneInput from "react-phone-input-2";

function UsersList({
  usersList,
  setUsersList,
  setOpenLoader,
  count,
  page,
  handlePageChange,
  usersPrivilages,
}) {
  const navigate = useNavigate();
  const userType = localStorage.getItem("userType");

  const [openDeleteModel, setOpenDeleteModel] = useState(false);
  const [selectedUser, setSelectedUser] = useState(false);

  const handelDeleteUser = async () => {
    const { res, err } = await deleteDescendantUser(selectedUser?.user?.id);
    setOpenLoader(true);
    if (res) {
      let newData = usersList?.filter(
        (val) => val?.id != selectedUser?.user?.id
      );
      setUsersList(newData);
      setOpenLoader(false);
    }
  };

  return (
    <Grid container>
      <Grid container className="table-header mb-20px">
        <Grid item md={0.5} textAlign="center">
          ID
        </Grid>
        <Grid item md={0.75}>
          Image
        </Grid>
        <Grid item sm={2}>
          Name
        </Grid>
        <Grid item sm={3}>
          Email address
        </Grid>
        <Grid item sm={2}>
          Mobile phone
        </Grid>
        <Grid item sm={2.75}>
          User role
        </Grid>
        {!usersPrivilages?.can_read &&
        !usersPrivilages?.can_update &&
        !usersPrivilages?.can_delete ? null : (
          <Grid item sm={1} sx={{ pl: 2 }}>
            Action
          </Grid>
        )}
      </Grid>

      {usersList?.length === 0 && (
        <Grid container display="block" textAlign="center">
          <img src={icons.EmptyUsers} alt="Users" />
          <Typography fontSize="30px" fontWeight="bold" color="#333132">
            No users added yet
          </Typography>
        </Grid>
      )}

      {usersList?.length >= 1 && (
        <>
          {usersList?.map((item, index) => {
            return (
              <Grid
                key={index}
                container
                item
                lg={12}
                className="user-item mb-20px"
                onClick={() => setSelectedUser({ user: item, index: index })}
              >
                <Grid item md={0.5} className="user-item-no" textAlign="center">
                  {item.id}
                </Grid>
                <Grid item md={0.75}>
                  <img src={item.avatar} alt="user" />
                </Grid>
                <Grid item sm={2} className="user-item-name">
                  {item.name}
                </Grid>
                <Grid item sm={3}>
                  {item.email}
                </Grid>
                <Grid item sm={2}>
                  <PhoneInput
                    inputStyle={{
                      border: "none",
                      width: "100%",
                      padding: "0px",
                      direction: "ltr",
                      fontSize: '18px',                      
                      color: '#333132',
                      backgroundColor: 'white',
                      wordBreak: 'break-word'
                    }}
                    defaultMask={parseInt(item.country_code) == 20 ? '... ... ....' 
                    : parseInt(item.country_code) == 93 ? '.. ... ....' 
                    : null}
                    value={`${item.country_code} ${item?.phone_number}`}
                    disabled={true}
                    disableDropdown={true}
                    specialLabel=""
                    placeholder=""
                  />
                </Grid>
                <Grid item sm={2.75}>
                  {item.role.name}
                </Grid>
                {!usersPrivilages?.can_read &&
                !usersPrivilages?.can_update &&
                !usersPrivilages?.can_delete ? null : (
                  <Grid item container sm={1} columnGap={1}>
                    <ActionsMenu
                      user={item}
                      userPath={"users"}
                      // userPathLength={6}
                      handleDelete={handelDeleteUser}
                      userType={userType}
                      usersPrivilages={usersPrivilages}
                    />
                  </Grid>
                )}
              </Grid>
            );
          })}
          {count > 10 && (
            <Grid
              container
              item
              justifyContent={"center"}
              xs={12}
              mb={7.5}
              mt={5}
            >
              <CustomPagination
                count={Math.ceil(count / 10)}
                size="large"
                shape="rounded"
                page={page}
                handleChange={handlePageChange}
                className="pagination"
              />
            </Grid>
          )}
        </>
      )}
    </Grid>
  );
}

export default UsersList;

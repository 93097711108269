import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import CCrud from "../../../../Components/Molecules/General/Crud/CCrud";
import "../../../../Components/Atoms/Shared/Styles/list-page.css";
import "../../../../Components/Atoms/Shared/Styles/form-page.css";
import "./PaymentMethods.css";
import { initialValues, structure, validationSchema } from "./structure";
import LanguagesSelect from "../../../../Components/Molecules/Forms/LanguagesSelect";
import { Grid } from "@mui/material";

const PaymentMethodsTemplate = ({
  setFilterObj,
  data,
  count,
  paginationPage,
  setPaginationPage,
  handlePageChange,
  supportedLanguages,
  updateSupportedLang,
  supportLang,
  handleSubmit,
  rowData,
  paymentMethodData,
  breadcrumbs,
}) => {
  let [searchParams] = useSearchParams();
  const index = searchParams.get("index");
  const navigate = useNavigate();

  return (
    <CCrud
      table={{
        list: data,
        listTitle: "Payment methods",
        emptyListMsg: "There are no payment methods",
        columns: [
          { accessor: "paymentMeth_id", Header: "Id" },
          { accessor: "paymentMeth_name", Header: "Name" },
          { accessor: "paymentMeth_lookup_key", Header: "Lookup key" },
          { accessor: "paymentMeth_source", Header: "Source" },
          {
            accessor: "paymentMeth_enable_dive_trip",
            Header: "Enable dive trip",
          },
          {
            accessor: "paymentMeth_enable_dive_course",
            Header: "Enable dive course",
          },
          {
            accessor: "paymentMeth_enable_live_aboard",
            Header: "Enable live aboard",
          },
          { accessor: "action", Header: "Actions" },
        ],
        isFilter: false,
        isSearch: false,
        isChecked: false,
        isSort: false,
        total_count: count,
        setFilterObj,
        className:
          "organization-table flow-table  payment-method-organization-table",
        tableContainerOverflow: {overflow: 'auto'},
        paginationPage: paginationPage,
        setPaginationPage: setPaginationPage,
        handlePageChange,
        rowData: rowData,
      }}
      form={{
        structure: () => structure(supportLang),
        initialValues: !index ? {} : initialValues(paymentMethodData),
        validationSchema: validationSchema(),
        onSubmit: handleSubmit,
        setSupportedLang: updateSupportedLang,
        submitTitle: index === null ? "Add" : "Save changes",
        title:
          index === null ? "Add new payment method" : "Edit payment method",
        className: {
          form: "flow-form payment-meth-form",
          actionButton: "flow-form-buttons",
        },
        handleLanguage: (
          <Grid container item className="select-lang-wrap" mb={2.5}>
            <LanguagesSelect
              value={supportLang}
              supportedLanguages={supportedLanguages}
              onChange={updateSupportedLang}
              LanguagesSelectHead={"Data Language"}
            />
          </Grid>
        ),
        breadcrumbs: breadcrumbs,
      }}
    />
  );
};

export default PaymentMethodsTemplate;

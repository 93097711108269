import { useState } from "react";
import { Grid, Typography, Divider, Stack } from "@mui/material";
import moment from "moment";
import SelectBox from "../../../Atoms/Select/Select.jsx";
import BasicDatePicker from "../../../Atoms/Input/DatePicker/DatePicker.js";
import { GroupsStatus } from "../../../../Contexts/APIs/Helper/Helper.js";
import './ActivityGroupHeader.css';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';

const ActivityGroupHeader = (props) => {
    const { updateStartDate, updateEndDate, updateGroupStatus } = props;
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [groupStatus, setGroupStatus] = useState("");

    const handleStartDate = (start_date) => {
        setStartDate(start_date);
        start_date 
        ? updateStartDate(moment(new Date(start_date), "YYYY/MM/DD").format("YYYY-MM-DD"))
        : updateStartDate(start_date);
    };
    const handleEndDate = (end_date) => {
        setEndDate(end_date);
        end_date 
        ? updateEndDate(moment(new Date(end_date), "YYYY/MM/DD").format("YYYY-MM-DD"))
        : updateEndDate(end_date);
    };
    const getGroupStatus = (e) => {
        setGroupStatus(e.target.value);
        e.target.value === "all" ? updateGroupStatus("") : updateGroupStatus(e.target.value);
    };

    return (
        <Grid container className="activity-group-header">
            <Grid container md={12}>
                <Grid container sm={6}>
                    <Grid>
                        <Typography component="h1" style={{ fontSize: "30px", fontWeight: "bold", color: "#333132" }}>
                            Activity groups
                        </Typography>
                    </Grid>
                </Grid>
                <Stack container width='50%' gap={2} flexDirection='row' flexWrap='wrap'>
                    <Grid xs={'auto'}>
                        <BasicDatePicker
                            value={startDate}
                            setValue={handleStartDate}
                            placeholder="From"
                            className="border-r-10px basic-date-picker"
                        />
                    </Grid>
                    <Grid xs={'auto'}>
                        <BasicDatePicker
                            value={endDate}
                            setValue={handleEndDate}
                            placeholder="To"
                            className="border-r-10px basic-date-picker"
                        />
                    </Grid>
                    <Grid xs={'auto'} className="activity-group-header-select">
                        <SelectBox
                            id="status"
                            name="status"
                            items={GroupsStatus}
                            title="Status"
                            className="select"
                            value={groupStatus}
                            style={{ width: "90%" }}
                            onchange={(e) => getGroupStatus(e)}
                            icon={ExpandMoreRoundedIcon}
                        />
                    </Grid>
                </Stack>
            </Grid>
            <Divider style={{ width: "100%", margin: "20px 0" }} />
        </Grid>
    )
}

export default ActivityGroupHeader
import { Box, Grid, Stack, Typography } from "@mui/material";
import { icons, images } from "../../../Assets/AssetHelper.js";
import PrimaryButton from "../../Atoms/Buttons/Primary/PrimaryButton.jsx";
import { NavLink } from "react-router-dom";
import "./Layout.css";
import AccordionTab from "./AccordionTab.jsx";
import { useState } from "react";

const SideBar = (props) => {
  const {
    roles,
    canReadActivities,
    canReadOrders,
    canReadJobOffers,
    canReadFreelancers,
    canReadOffers,
    canReadUsers,
  } = props;
  const ButtonsGroup = [
    {
      text: "Dashboard",
      icon: <img src={icons.HomeIcon} alt="Dashboard" />,
      action: "/",
      isAllowed: true,
    },
    {
      text: "Users",
      icon: <img src={icons.Users} alt="Users" />,
      action: "/users",
      isAllowed: roles?.["descendant_users"]?.can_read ?? true,
    },
    {
      text: "Operators",
      icon: <img src={icons.Operators} alt="Operators" />,
      action: "/operators/main",
      subLinks: [
        {
          link: "Operators",
          action: "/operators/main",
          isAllowed: roles?.["dive_centers"]?.can_read ?? true,
        },
        {
          link: "Branches",
          action: "/operators/branches",
          isAllowed: roles?.["branches"]?.can_read ?? true,
        },
        {
          link: "Job offers",
          action: "/operators/job-offers",
          isAllowed: roles?.["job_offers"]?.can_read ?? true,
        },
        {
          link: "Dive activities",
          action: "/operators/activities",
          isAllowed: roles?.["activities"]?.can_read ?? true,
        },
        {
          link: "Activites offers",
          action: "/operators/activity-offers",
          isAllowed: roles?.["activity_offers"]?.can_read ?? true,
        },
        {
          link: "Reservations",
          action: "/operators/reservations",
          isAllowed: roles?.["orders"]?.can_read ?? true,
        },
      ],
      isAllowed: (!roles?.["dive_centers"]?.can_read && !roles?.["branches"]?.can_read && !roles?.["job_offers"]?.can_read
        && !roles?.["activities"]?.can_read && !roles?.["activity_offers"]?.can_read && !roles?.["orders"]?.can_read
      ) ? false : true,
    },
    {
      text: "Dive clubs",
      icon: <img src={icons.DiveClubs} alt="Dive clubs" />,
      action: "/dive-clubs",
      isAllowed: roles?.["profile"]?.can_read ?? true,
    },
    {
      text: "Customers",
      icon: <img src={icons.Customers} alt="Customers" />,
      action: "/customers",
      isAllowed: roles?.["profile"]?.can_read ?? true,
    },
    {
      text: "Freelancers",
      icon: <img src={icons.Freelancers} alt="Freelancers" />,
      action: "/freelancers",
      isAllowed: roles?.["dive_experts"]?.can_read ?? true,
    },
    {
      text: "Partners",
      icon: <img src={icons.Sponsors} alt="Partners" />,
      action: "/sponsers",
      isAllowed: true,
    },
  ];

  const ButtonsGroupTwo = [
    {
      text: "Notifications",
      icon: <img src={icons.notification} alt="notification" />,
      action: "/notifications",
      isAllowed: true,
    },
    {
      text: "Predefined lookups",
      icon: <img src={icons.PredefinedLookups} alt="Predefined lookups" />,
      action: "/predefined-lookups/currency",
      subLinks: [
        {
          link: "Currency",
          action: "/predefined-lookups/currency",
          isAllowed: roles?.["currencies"]?.can_read ?? true,
        },
        {
          link: "Languages",
          action: "/predefined-lookups/languages",
          isAllowed: roles?.["languages"]?.can_read ?? true,
        },
        {
          link: "Language fluency levels",
          action: "/predefined-lookups/fluency-languages",
          isAllowed: roles?.["language_fluency_levels"]?.can_read ?? true,
        },
        {
          link: "Social media",
          action: "/predefined-lookups/social-media",
          isAllowed: roles?.["social_media"]?.can_read ?? true,
        },
        {
          link: "Badges",
          action: "/predefined-lookups/badges",
          isAllowed: roles?.["badges"]?.can_read ?? true,
        },
        {
          link: " Feature options",
          action: "/predefined-lookups/feature-options",
          isAllowed: roles?.["feature_options"]?.can_read ?? true,
        },
        {
          link: "Payment Methods",
          action: "/predefined-lookups/payment-methods",
          isAllowed: roles?.["payment_methods"]?.can_read ?? true,
        },
        {
          link: " Price Includes",
          action: "/predefined-lookups/price-includes",
          isAllowed: roles?.["price_includes"]?.can_read ?? true,
        },
      ],
      isAllowed: (!roles?.["currencies"]?.can_read && !roles?.["languages"]?.can_read 
        && !roles?.["language_fluency_levels"]?.can_read && !roles?.["social_media"]?.can_read 
        && !roles?.["badges"]?.can_read && !roles?.["feature_options"]?.can_read 
        && !roles?.["payment_methods"]?.can_read && !roles?.["price_includes"]?.can_read
      ) ? false : true,
    },
    // {
    //     text: "Certifications",
    //     icon: <img src={icons.Certifications} alt="Certifications" />,
    //     action: "/certifications",
    //     isAllowed: canReadUsers ?? true
    // },
    {
      text: "Organizations",
      icon: <img src={icons.Certifications} alt="Organizations" />,
      action: "/organizations",
      isAllowed: roles?.["organizations"]?.can_read ?? true,
    },
    {
      text: "Geolocations",
      icon: <img src={icons.Geolocations} alt="Geolocations" />,
      action: "/geolocations/cities",
      subLinks: [
        {
          link: "Countries",
          action: "/geolocations/countries",
          isAllowed: roles?.["countries"]?.can_read ?? true,
        },
        {
          link: "Provinces",
          action: "/geolocations/provinces",
          isAllowed: roles?.["cities"]?.can_read ?? true,
        },
        {
          link: "Cities",
          action: "/geolocations/cities",
          isAllowed: roles?.["areas"]?.can_read ?? true,
        },
      ],
      isAllowed: (!roles?.["countries"]?.can_read && !roles?.["cities"]?.can_read && !roles?.["areas"]?.can_read) ? false : true,
    },

    {
      text: "Levels",
      icon: <img src={icons.Levels} alt="Levels" />,
      action: "/levels/customer",
      subLinks: [
        {
          link: "Customer Levels",
          action: "/levels/customer",
          isAllowed: roles?.["customer_levels"]?.can_read ?? true,
        },
        {
          link: "Freelancer Levels",
          action: "/levels/freelancer",
          isAllowed: roles?.["dive_expert_levels"]?.can_read ?? true,
        },
      ],
      isAllowed: (!roles?.["customer_levels"]?.can_read && !roles?.["dive_expert_levels"]?.can_read) ? false : true,
    },
    {
      text: "System configurations",
      icon: (
        <img src={icons.SystemConfigurations} alt="System configurations" />
      ),
      action: "/system-configurations/main",
      subLinks: [
        {
          link: "System configurations",
          action: "/system-configurations/main",
          isAllowed: roles?.["system_configurations"]?.can_read ?? true,
        },
        {
          link: "Point configurations",
          action: "/system-configurations/points",
          isAllowed: roles?.["points"]?.can_read ?? true,
        },
      ],
      isAllowed: (!roles?.["system_configurations"]?.can_read && !roles?.["points"]?.can_read) ? false : true,
    },
    {
      text: "Documents",
      icon: <img src={icons.Documents} alt="Documents" />,
      action: "/documents",
      subLinks: [
        {
          link: "Documents",
          action: "/documents",
          isAllowed: roles?.["documents"]?.can_read ?? true,
        },
        {
          link: "FAQ's",
          action: "/faqs",
          isAllowed: roles?.["faqs"]?.can_read ?? true,
        },
      ],
      isAllowed: (!roles?.["documents"]?.can_read && !roles?.["faqs"]?.can_read) ? false : true,
    },
    // {
    //   text: "FAQ's",
    //   icon: <img src={icons.Faqs} alt="FAQ's" />,
    //   action: "/faqs",
    // },
    {
      text: "Social media links",
      icon: <img src={icons.AppVersion} alt="App version" />,
      action: "/contacts",
      isAllowed: true,
    },
  ];

  const [accordionToggle, setAccordionToggle] = useState(false);
  const [accordionListener, setAccordionListener] = useState(false);
  const [activeIndex, setActiveIndex] = useState(null);

  return props.open ? (
    <Grid
      container
      xs={12}
      item
      className="side-bar-container"
      sx={{
        width: { md: "18%", xs: "5%" },
        height: "100%",
        position: "fixed",
        top: "0px",
        overflow: "auto",
      }}
    >
      <Grid style={{ margin: " 0 auto", padding: "5px" }}>
        <Grid container className="mb-20px">
          <Grid item sm={2}>
            <PrimaryButton
              btnType="icon"
              startIcon={<img src={icons.SideIcon} alt="side menu" />}
              click={() => props.setOpen(false)}
            />
          </Grid>
          <Grid item sm={10} style={{ textAlign: "center" }}>
            <img src={images.HeaderLogo} alt="Logo" width="85%" />
          </Grid>
        </Grid>
        <Grid container className="mb-30px" p={1}>
          <Typography
            sx={{
              fontSize: "18px",
              fontWeight: "bold",
              color: "#aeaeae",
              marginBottom: "12px",
            }}
          >
            Actions & create
          </Typography>
          {ButtonsGroup?.map(
            (btn, index) =>
              btn.isAllowed &&
              (btn?.subLinks?.length > 0 ? (
                <Box
                  width={"100%"}
                  onClick={() => setActiveIndex(index)}
                  key={index}
                >
                  <AccordionTab
                    btn={btn}
                    index={index}
                    key={index}
                    accordionToggle={
                      activeIndex === index ? accordionToggle : false
                    }
                    setAccordionToggle={setAccordionToggle}
                    setAccordionListener={setAccordionListener}
                  />
                </Box>
              ) : (
                <NavLink
                  className={`side-bar-btn mb-10px ${
                    accordionListener ? "remove-active" : ""
                  }`}
                  to={btn.action}
                  key={index}
                  onClick={() => {
                    setAccordionToggle(false);
                    setAccordionListener(false);
                  }}
                >
                  <Stack
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      p: "13px 5px",
                      flexDirection: "row",
                      gap: 1.5,
                    }}
                  >
                    <Box
                      sx={{
                        width: "25px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {" "}
                      {btn?.icon}{" "}
                    </Box>
                    <Typography>{btn?.text}</Typography>
                  </Stack>
                </NavLink>
              ))
          )}
        </Grid>
        <Grid container p={1}>
          <Typography
            sx={{
              fontSize: "18px",
              fontWeight: "bold",
              color: "#aeaeae",
              marginBottom: "12px",
            }}
          >
            Data Entry
          </Typography>
          {ButtonsGroupTwo?.map(
            (btn, index) =>
              btn.isAllowed &&
              (btn?.subLinks?.length > 0 ? (
                <Box
                  width={"100%"}
                  onClick={() => setActiveIndex(index)}
                  key={index}
                >
                  <AccordionTab
                    btn={btn}
                    index={index}
                    key={index}
                    accordionToggle={
                      activeIndex === index ? accordionToggle : false
                    }
                    setAccordionToggle={setAccordionToggle}
                    setAccordionListener={setAccordionListener}
                  />
                </Box>
              ) : (
                <NavLink
                  className={`side-bar-btn mb-10px ${
                    accordionListener ? "remove-active" : ""
                  }`}
                  to={btn.action}
                  key={index}
                  onClick={() => {
                    setAccordionToggle(false);
                    setAccordionListener(false);
                  }}
                >
                  <Stack
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      p: "13px 5px",
                      flexDirection: "row",
                      gap: 2,
                    }}
                  >
                    <Box
                      sx={{
                        width: "25px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {" "}
                      {btn?.icon}{" "}
                    </Box>
                    <Typography>{btn?.text}</Typography>
                  </Stack>
                </NavLink>
              ))
          )}
        </Grid>
        <Typography
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "10px",
            width: "100%",
            mt: "auto",
            mb: "0px",
            fontSize: "12px",
            color: "#aeaeae",
          }}
        >
          Developed by
          <img src={images.InovaLogo} alt="side menu" />
        </Typography>
      </Grid>
    </Grid>
  ) : (
    <Grid container className="side-bar-container" style={{ height: "100%" }}>
      <Grid item xs={12}>
        <PrimaryButton
          btnType="icon"
          startIcon={<img src={icons.SideIcon} alt="side menu" />}
          click={() => props.setOpen(true)}
          style={{ position: "fixed" }}
        />
      </Grid>
    </Grid>
  );
};

export default SideBar;

import { axiosFn, handleError } from "..";
import { makeFilterString } from "../Helper/Helper";
import * as URL from "../URLs";

export async function getReservationsList(filter_obj) {
    return await axiosFn("get", URL.Reservations + makeFilterString(filter_obj))
      .then((res) => {
        console.log(res.data.orders);
        return {
          res: res.data,
          err: null,
        };
      })
      .catch(handleError);
}
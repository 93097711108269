import { Grid } from '@mui/material';
import React, { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Loader from '../../../Atoms/Loader/Loader';
import RolesForm from '../../../Molecules/Forms/RolesForm';
import PagesHead from '../../../Molecules/Users/PagesHead';

function RoleFormWrap(props) {
    const {formik, roleDataRes, validationMethod, handlePermissionsChange,handleBatchPermissionsChange, openLoader, formType,rows,handleSelectedRowsChange,optionsStateObj} = props;
    const navigate = useNavigate();
    const handleFormSubmit = useRef(null);

  return (
    <Grid container>
        <Loader open={openLoader} /> 
        <PagesHead 
            title={formType === 'Add' ?"Add New Role" :"Edit user role"}
            breadCrumb={{
                link: "Roles",
                path: '/users/roles',
                text: `${formType === 'Add' ?"Add New Role" :"Edit user role"}`
            }}
            primaryButtons={[
                {
                    fontSize:'14px',
                    text:"Cancel",
                    classBtn:"primary r-25px primary-text-outline",
                    style:{ padding: "5px 20px", height: "48px", marginRight: "1rem" },
                    click: () => navigate('/users/roles')
                },
                {
                    fontSize:'14px',                    
                    text:`${formType === 'Add' ?"Add" :"Save change"}`,
                    classBtn:"primary r-25px",
                    style:{ padding: "5px 20px", height: "48px" },
                    btnType: 'submit'
                }
            ]}
            handelSubmit={() => handleFormSubmit.current()}
        />
        {
            !openLoader && <RolesForm
                formik={formik}
                roleDataRes={roleDataRes}
                optionsStateObj={optionsStateObj}
                rows={rows}
                handleSelectedRowsChange={handleSelectedRowsChange}        
                handleFormSubmit={handleFormSubmit}
                validationMethod={validationMethod}
                handlePermissionsChange={handlePermissionsChange}
                handleBatchPermissionsChange={handleBatchPermissionsChange}
                formType={formType}
            />            
        }
    </Grid>
  )
}

export default RoleFormWrap
import React from 'react'
import DiveClubsList from '../../Molecules/DiveClubs/DiveClubsList/DiveClubsList'
import DiveClubsHeader from '../../Molecules/DiveClubs/DiveClubsHeader/DiveClubsHeader'
import { Grid } from '@mui/material'
import Loader from '../../Atoms/Loader/Loader.js'
import SearchIcon from '@mui/icons-material/Search'

function DiveClubs({filterObj, setFilterObj, diveClubsList, openLoader, count, page, setPage, handlePageChange, getDiveClubs,setDiveClubsList, roles}) {
  return (
    <>
    <Loader open={openLoader} />
    <Grid container>
        <DiveClubsHeader
            title='Dive clubs'
            searchInput={{
                type: "search",
                placeholder: "Search dive club",
                StartAdornment: { SearchIcon },
                className: "Search-dive-club"
            }}
            filterObj={filterObj}
            setFilterObj={setFilterObj}
            setPage={setPage}
            handleChange={handlePageChange}
        />
        <DiveClubsList
            diveClubsList={diveClubsList}
            count={count} page={page}
            handlePageChange={handlePageChange}
            getDiveClubs={getDiveClubs}
            filterObj={filterObj}
            roles={roles}
            setDiveClubsList={setDiveClubsList}
        />
    </Grid>
</>
  )
}

export default DiveClubs
import { axiosFn, handleError } from "..";
import { makeFilterString } from "../Helper/Helper";
import * as URL from "../URLs";

export async function getSocialMediaList(filter_obj) {
  return await axiosFn("get", URL.Social_Media + makeFilterString(filter_obj))
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}

export async function createSocialMedia({ data, action }) {
  return await axiosFn("post", URL.Social_Media, data)
    .then((res) => {
      action && action();
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}

export async function getSocialMediaDetails(organization_id) {
  return await axiosFn("get", `${URL.Social_Media}/${organization_id}`)
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}

export async function deleteSocialMedia(id, action) {
  return await axiosFn("delete", `${URL.Social_Media}/${id}`)
    .then((res) => {
      action && action(res?.data);
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}

export async function updateSocialMedia({ id, data, action }) {
  return await axiosFn("put", `${URL.Social_Media}/${id}`, data)
    .then((res) => {
      action && action();
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}

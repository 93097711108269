import { Button, Modal, Stack, Typography } from '@mui/material';
import React from 'react'
import './DeleteModal.css';

const DeleteModal = ({
  t,  open, setOpen, handelDeleteRequest, handleCancelDelete, customClass, text
}) => {

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        boxShadow: 24,
        borderRadius: '25px',
        p: 4,
    };


    // const handleClose = () => setOpen(false);
    const handleClose = () => {
        setOpen(false);
        handleCancelDelete();
    }

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Stack sx={style} alignItems='center' justifyContent='center' className={`delete-modal ${customClass}`}>
                <Typography component="h1" style={{ color: '#f6b119', fontWeight: "bold", fontSize: "30px" }} >
                   { text[0] }
                </Typography>
                <Typography textAlign='center' style={{ fontWeight: "bold" }}>
                    {text[1]}
                </Typography>
                {/* <Stack flexDirection='row' alignItems='center' mt={4}>
                    <Button style={{ width: '150px', color: 'black' }} onClick={() => setOpen(false)}>  {t('offer.cancel')}</Button>

                    <Button style={{ width: '150px' }} className='primary r-25px' onClick={handelDeleteRequest}>{t('offer.delete')}</Button>
                </Stack> */}
                <Stack flexDirection='row' alignItems='center' mt={4} className='buttons-wrap'>
                    <Button style={{ width: '150px', color: 'black' }} className='r-25px' onClick={handleClose}> Cancel </Button>

                    <Button style={{ width: '150px' }} className='primary r-25px' onClick={handelDeleteRequest}> Delete </Button>
                </Stack>
            </Stack>

        </Modal>
    )
}

export default DeleteModal
import React from "react";
import SearchIcon from "@mui/icons-material/Search";
import ActivityOffersList from "../../Molecules/ActivityOffers/ActivityOffersList";
import ActivityOffersHeader from "../../Molecules/ActivityOffers/ActivityOffersHeader/ActivityOffersHeader";

function ActivityOffers(props) {
  const {
    filterObj,
    setFilterObj,
    activityOffersList,
    count,
    page,
    setPage,
    handlePageChange,
  } = props;
  return (
    <>
      <ActivityOffersHeader
        breadCrumb={{
          path: "/operators/main",
          link: "Dive centers",
          text: "Activity offers",
        }}
        title="Activities offers"
        searchInput={{
          type: "search",
          placeholder: "Search activity  name",
          StartAdornment: { SearchIcon },
          className: "Search-activity",
        }}
        filterObj={filterObj}
        setFilterObj={setFilterObj}
        setPage={setPage}
        handleChange={handlePageChange}
      />
      <ActivityOffersList
        activityOffersList={activityOffersList}
        count={count}
        page={page}
        handlePageChange={handlePageChange}
      />
    </>
  );
}

export default ActivityOffers;

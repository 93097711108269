import * as Yup from "yup";
export const initialValues = (data, lang) => ({
  ...data,
  ...data?.localized_data,
  country_id: {
    value: data?.country?.id,
    label: data?.country?.name,
  },
  lookup_key: data?.lookup_key,
  iso_code: data?.iso_code,
});

export const structure = (lang, countriesList, countriesCount, getCountry, country, setCountry) => [
  {
    head: "Province information",
    list: [
      {
        name: `${lang}.name`,
        kind: "input",
        type: "text",
        label: "Area  name",
        width: "45%",
      },
      {
        name: `country_id`,
        kind: "select",
        type: "text",
        label: "Country",
        width: "45%",
        options: countriesList,
        count: countriesCount,
        getList: getCountry,
        value: country,
        onChange: (val) => {setCountry(val)},
        onClear: () => {setCountry(null)},
        onOpen: () => countriesList.length === 0 && getCountry()
      },
      {
        name: `lookup_key`,
        kind: "input",
        type: "text",
        label: "Lookup key",
        width: "45%",
      },
      {
        name: `iso_code`,
        kind: "input",
        type: "text",
        label: "ISO code",
        width: "45%",
      },
    ],
  },
];

export const validationSchema = () =>
  Yup.object({
    en: Yup.object().shape({
      name: Yup.string().required("required"),
    }),
    country_id:
      Yup.mixed()
      .required("required"),
    lookup_key: Yup.string().required("required"),
    iso_code: Yup.string().required("required"),
  });

import { Grid, Stack, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import ResetPasswordForm from "../../../Components/Organisms/Auth/ResetPassword/ResetPasswordForm";
import CustomSeparator from "../../../Components/Atoms/BreadCrumb";

const ResetPasswordTemplate = ({ ChangePassword }) => {
  const breadcrumbs = [
    { path: "/profile", active: true, title: "Profile" },

    {
      active: false,
      title: "Change password",
    },
  ];
  return (
    <Stack container gap={2}>
      <CustomSeparator breadcrumbs={breadcrumbs} />
      <Typography component="h1" fontWeight="bold" fontSize="30px">
        {"Change password"}
      </Typography>
      <Grid
        className="activity-form"
        container
        xl={7}
        lg={7}
        md={7}
        sm={11}
        xs={12}
      >
        <ResetPasswordForm ChangePassword={ChangePassword} />
      </Grid>
    </Stack>
  );
};

export default ResetPasswordTemplate;

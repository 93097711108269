import { axiosFn, handleError } from "..";
import { makeFilterString } from "../Helper/Helper";
import * as URL from "../URLs";

export async function getBadgesList(filter_obj) {
  return await axiosFn("get", URL.Badges + makeFilterString(filter_obj))
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}

export async function createBadge(data) {
  return await axiosFn("post", URL.Badges, data)
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}

export async function editBadge(id, data, action) {
  return await axiosFn("put", `${URL.Badges}/${id}`, data)
    .then((res) => {
      action && action();
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}

export async function getBadgeDetails(Badge_id) {
  return await axiosFn("get", `${URL.Badges}/${Badge_id}`)
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}

export async function deleteBadge(id, action) {
  return await axiosFn("delete", `${URL.Badges}/${id}`)
    .then((res) => {
      action && action(res?.data);
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}

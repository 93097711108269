import { useState, useEffect } from "react";
import { getOperatorsList } from "../../../Contexts/APIs/Operators/Operators";
import OperatorsTemplate from "../../../Templates/Main/Operators/Operators.Template";
import usePageFilter from "../../../Components/Atoms/Hooks/usePageFilter";

const OperatorsPage = ({ roles, salesman_roles }) => {
  const [openLoader, setOpenLoader] = useState(false);
  const [operatorsList, setOperatorsList] = useState([]);
  const [count, setCount] = useState(0);
  const { page, filterObj, handlePageChange, setFilterObj, setPage } = usePageFilter();

  const getOperators = async (filterObj) => {
    const defaultFilter = {
      page_size: 10,
      page_number: 1,
      keyword: "",
      sort_by: "id",
    };
    setOpenLoader(true);
    const { res, error } = await getOperatorsList({
      ...defaultFilter,
      ...filterObj,
    });
    if (res) {
      setOpenLoader(false);
      setOperatorsList(res?.data?.dive_centers);
      setCount(res?.extra?.total_count);
    }
    if (error) {
      setOpenLoader(false);
    }
  };

  useEffect(() => {
    filterObj && getOperators(filterObj);
  }, [filterObj]);
  return (
    <OperatorsTemplate
      filterObj={filterObj}
      setFilterObj={setFilterObj}
      operatorsList={operatorsList}
      openLoader={openLoader}
      count={count}
      page={page}
      setPage={setPage}
      handlePageChange={handlePageChange}
      getOperators={getOperators}
      roles={roles}
      salesman_roles={salesman_roles}
      setOperatorsList={setOperatorsList}
    />
  );
};

export default OperatorsPage;

import { Box, Button, Grid, Modal, Stack, Typography } from '@mui/material'
import React from 'react'
import moment from 'moment';
import FormHead from '../../Atoms/FormHead/FormHead';
import PhoneInput from "react-phone-input-2";
import './ParticipantsModal.css'
function ParticipantsModal({open, setOpen, meetingPoint, resInfo, participantsList}) {
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 1016,
        height: 750,
        maxWidth: '80vw',
        maxHeight: '70vh',
        bgcolor: 'background.paper',
        boxShadow: 24,
        borderRadius: '25px',
        p: 4,
        overflow: 'auto'
    };

  return (
    <Modal
    open={open}
    onClose={() => setOpen(false)}
    aria-labelledby="modal-modal-title"

    aria-describedby="modal-modal-description"
>
    <Stack sx={style} justifyContent='center' gap={2} >
        <Stack flexDirection='row' alignItems='center' gap={1}>
            <img src={resInfo?.image} style={{ width: '64px', height: '64px', maxWidth: '64px', maxHeight: '64px', borderRadius: '10px' }} />
            <Typography fontSize='24px' fontWeight='bold'>{resInfo?.name}</Typography>
        </Stack>
        <Stack className="participants-panel" gap={1} >
            <FormHead text={'Meeting point'} />
                <Typography sx={{ fontSize: '16px', fontWeight: 'normal' }}>{meetingPoint?.full_address}</Typography>
                <Typography sx={{ fontSize: '16px', fontWeight: 'normal' }}>{meetingPoint?.area?.name},{meetingPoint?.city?.name},{meetingPoint?.country?.name}</Typography>
        </Stack>
        <Box sx={{ height: '44vh', overflowY: 'scroll' }}>
            {participantsList?.length > 0 &&
                <Stack className="participants-panel" gap={1} >
                    <FormHead text={'Participants info'} />
                    <Stack gap={{xs:2, md:0}} justifyContent={'space-between'} flexDirection='row' flexWrap='wrap'>
                        {participantsList?.map((val, index) => {
                            return <Stack gap={2} width={{ xs:"100%", md:"48%" }}
                            key={index} >
                                <Typography color='#faa100' fontWeight='bold' fontSize='18px'>Participants {index + 1}</Typography>

                                <Grid container p={0} m={0}>
                                    <Grid item xs={12} md={6} p={0} pr={'4px'} m={0} display='flex' flexDirection='column' gap={1}>
                                        <Stack>
                                            <Typography color='#aaaaaa' fontSize='13px'>Name</Typography>
                                            <Typography fontWeight='bold' fontSize='18px'>{val?.name}</Typography>
                                        </Stack>
                                        <Stack>
                                            <Typography color='#aaaaaa' fontSize='13px'>Email</Typography>
                                            <Typography fontWeight='bold' fontSize='18px'>{val?.email}</Typography>
                                        </Stack>
                                        <Stack>
                                            <Typography color='#aaaaaa' fontSize='13px'>Phone number</Typography>
                                            <Typography fontWeight='bold' fontSize='18px'>
                                                <PhoneInput
                                                    inputStyle={{
                                                        border: "none",
                                                        width: "100%",
                                                        padding: "0px",
                                                        direction: "ltr",
                                                        fontSize: '18px',                      
                                                        color: '#3c3c3c',
                                                        backgroundColor: '#fbfbfb',
                                                        wordBreak: 'break-word',
                                                        fontWeight: 'bold'
                                                    }}
                                                    defaultMask={parseInt(val.country_code) == 20 ? '... ... ....' 
                                                    : parseInt(val.country_code) == 93 ? '.. ... ....' 
                                                    : ''}                
                                                    value={`${val.country_code} ${val?.phone_number}`}
                                                    disabled={true}
                                                    disableDropdown={true}
                                                    specialLabel=""
                                                    placeholder=""
                                                />    
                                            </Typography>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12} md={6} p={0} pl={'4px'} m={0} display='flex' flexDirection='column' gap={1}>                                    
                                        <Stack>
                                            <Typography color='#aaaaaa' fontSize='13px'>Number of Dives</Typography>
                                            <Typography fontWeight='bold' fontSize='18px'>{val?.no_of_dives ? val?.no_of_dives : 'N/A'}</Typography>                         
                                        </Stack>
                                        <Stack>
                                            <Typography color='#aaaaaa' fontSize='13px'>Last Dive Date</Typography>
                                            <Typography fontWeight='bold' fontSize='18px'>{val?.last_dive_date ? moment(val?.last_dive_date).format('MMM DD,YYYY') : 'N/A'}</Typography>                        
                                        </Stack>                                
                                        <Stack>
                                            <Typography color='#aaaaaa' fontSize='13px'>Certificates</Typography>
                                            <Typography fontWeight='bold' fontSize='18px'>{val?.certificates_list ? val?.certificates_list : 'N/A'}</Typography>
                                        </Stack>
                                    </Grid>    
                                </Grid>                                
                            </Stack>
                        })

                        }
                    </Stack>
                </Stack>
            }

        </Box>
        <Stack flexDirection='row' alignItems='center' mt={4} alignSelf='center'>
            <Button style={{ width: '150px', color: 'white', background: '#faa100', borderRadius: '18px' }} onClick={() => setOpen(false)}>Done</Button>
        </Stack>
    </Stack>

</Modal>
  )
}

export default ParticipantsModal
import React from "react";
import ActivityGroupsTemplate from "../../../Templates/Main/Activities/ActivityGroups.template";
import Loader from "../../../Components/Atoms/Loader/Loader";
import { getActivityGroups } from "../../../Contexts/APIs/Activities/Activities";
import { useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import usePageFilter from "../../../Components/Atoms/Hooks/usePageFilter";

function ActivityGroupsPage() {
  const { activityId } = useParams();
  const [count, setCount] = useState();
  const [openLoader, setOpenLoader] = useState(false);
  const [activityGroups, setActivityGroups] = useState([]);
  const [activityInfo, setActivityInfo] = useState({});
  const { page, filterObj, handlePageChange, setFilterObj, setPage } =
    usePageFilter();

  const getActivityGroupsList = async (filterObj) => {
    setOpenLoader(true);
    const { res, err } = await getActivityGroups(activityId, {...filterObj});
    if (res) {
      setCount(res?.extra?.total_count);
      setActivityGroups(res?.data?.activity_groups);
      setActivityInfo(res?.data?.activity);
    }
    if (err) {
    }
    setOpenLoader(false);
  };

  useEffect(() => {
    getActivityGroupsList(filterObj);
  }, [filterObj]);
  return (
    <>
      <Loader open={openLoader} />
      <ActivityGroupsTemplate
        count={count}
        page={page}
        setPage={setPage}
        activityGroups={activityGroups}
        activityInfo={activityInfo}
        filterObj={filterObj}
        setFilterObj={setFilterObj}
        handlePageChange={handlePageChange}
      />
    </>
  );
}

export default ActivityGroupsPage;

import { Grid } from '@mui/material';
import React, { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import UserForm from '../../Molecules/Forms/UserForm';
import PagesHead from '../../Molecules/Users/PagesHead';

function UserFormWrap(props) {
    const {formik, openLoader, rolesData, getRolesData, rolesCount, validationMethod, userImage, uploadUserImages, removeUserImage, formType} = props;
    const navigate = useNavigate();
    const navigateToUser = () => {        
        navigate('/users');
    }
    const handleFormSubmit = useRef(null);

    const href = window.location.href;
    const editPage = href.indexOf('/edit/') !== -1,
        editPasswordPage = href.indexOf('/edit-password/') !== -1;

  return (
    <Grid container className={ formType === 'Add' ?'add-user' :'edit-user' }>
        <PagesHead 
            // title={ formType === 'Add' ? "Add new user" : "Edit user" }
            title={ formType === 'Add' ? "Add new user" 
                : editPage ? "Edit user" 
                : editPasswordPage ? "Change password" 
                : null
            }
            breadCrumb={{
                link: "Users",
                path: '/users',
                // text: `${formType === 'Add' ? "Add new user" : "Edit user"}`
                text: `${ formType === 'Add' ? "Add new user" 
                    : editPage ? "Edit user" 
                    : editPasswordPage ? "Change password" 
                    : null
                }`
            }}
            primaryButtons={[
                {
                    fontSize:'14px',
                    text:"Cancel",
                    classBtn:"primary r-25px primary-text",
                    style:{ padding: "5px 20px", height: "48px", marginRight: "1rem" },
                    click: navigateToUser                    
                },
                {
                    fontSize:'14px',
                    // text:`${formType === 'Add' ? "Add user" : "Edit user"}`,
                    text:`${ formType === 'Add' ? "Add user" 
                        : editPage ? "Edit user" 
                        : editPasswordPage ? "Save password" 
                        : null
                    }`,
                    classBtn:"primary r-25px",
                    style:{ padding: "5px 20px", height: "48px" },
                    btnType: 'submit'
                }
            ]}
            handelSubmit={() => handleFormSubmit.current()}
        />
        <UserForm 
            handleFormSubmit={handleFormSubmit}             
            formik={formik}
            openLoader={openLoader} 
            rolesData={rolesData}            
            getRolesData={getRolesData}
            rolesCount={rolesCount}
            validationMethod={validationMethod}
            userImage={userImage}
            uploadUserImages={uploadUserImages}
            removeUserImage={removeUserImage}
            formType={formType}
        />
    </Grid>
  )
}


export default UserFormWrap
import React, { useEffect, useState } from "react";
import Loader from "../../../../Components/Atoms/Loader/Loader";
import { Stack, CircularProgress } from "@mui/material";
import {
  deleteFAQ,
  getFAQDetails,
  getFAQList,
  updateFAQ,
} from "../../../../Contexts/APIs/FAQ/FAQ";
import FAQTemplate from "../../../../Templates/Main/Documents/FAQ/FAQ.template";
import usePageFilter from "../../../../Components/Atoms/Hooks/usePageFilter";

function FAQPage({roles}) {
  // let [searchParams] = useSearchParams();
  const {
    page,
    filterObj,
    handlePageChange: onPageChange,
    setFilterObj,
    setPage,
    searchParams,
  } = usePageFilter();
  const id = searchParams.get("id");

  const [openLoader, setOpenLoader] = useState(false);
  const [faqList, setFaqList] = useState([]);

  const [count, setCount] = useState(0);
  // const [page, setPage] = useState(1);

  // const [filterObj, setFilterObj] = useState(null);

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [faqData, setFaqData] = useState({});
  const [isLoading, setLoading] = useState({ index: null, status: false });

  // const updatePagination = (page) => {
  //   setFilterObj({ ...filterObj, page_number: page });
  // };

  // const handlePageChange = (event, value) => {
  //   setPage(value);
  //   updatePagination(value);
  // };

  const getFAQ = async () => {
    const defaultFilter = {
      page_size: 10,
      page_number: 1,
      keyword: "",
      sort_by: "id",
    };
    setOpenLoader(true);
    const { res, error } = await getFAQList({ ...defaultFilter, ...filterObj });
    if (res) {
      setOpenLoader(false);
      setFaqList(res?.data?.faqs);
      setCount(res?.extra?.total_count);
    }
    if (error) {
      setOpenLoader(false);
    }
  };
  const getFAQData = async () => {
    setOpenLoader(true);
    const { res, error } = await getFAQDetails(id);
    if (res) {
      setFaqData(res.data.faq);
      setOpenLoader(false);
    }
    if (error) {
      setOpenLoader(false);
    }
  };
  useEffect(() => {
    if (filterObj) getFAQ();
  }, [filterObj]);

  useEffect(() => {
    id && getFAQData({});
  }, [id]);

  const rowData = (data) => {
    setSelectedRow(data?.rowData);
  };
  // mappedData use for create custom cell to concat multi values or navigate button
  const mappedData = faqList?.map((data, index) => {
    return {
      ...data,

      faq_id: <div style={{ width: "100px", padding: "10px" }}>{data?.id}</div>,
      question_data: <div style={{ width: "150px" }}>{data?.question}</div>,

      is_hidden_data: (
        <Stack
          sx={{
            background: data?.is_hidden ? "#62c08b" : "#cc4d49",
            color: "white",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "10px",
            padding: "10px",
            height: "50px",
            width: "80px",
            cursor: roles?.can_read && roles?.can_update ? "pointer" : "default",
          }}
          onClick={() => {
            if(roles?.can_read && roles?.can_update){
              const action = (index) => {
                setLoading({ index: index, status: true });
                let newList = faqList;
                newList[index].is_hidden = !data?.is_hidden;
                setFaqList(() => newList);
                setTimeout(() => {
                  setLoading({ index: null, status: false });
                }, 300);
              };
              updateFAQ({
                id: data?.id,
                data: {
                  faq: { ...data, is_hidden: !data?.is_hidden },
                },
                action: () => action(index),
              });
            }
          }}
        >
          {isLoading?.status && isLoading?.index == index ? (
            <CircularProgress />
          ) : data?.is_hidden ? (
            "Yes"
          ) : (
            "No"
          )}
        </Stack>
      ),
    };
  });

  const handleApplyFilterMenu = (obj) => {
    setFilterObj({
      ...filterObj,
      page_number: 1,
      ...obj,
    });
    setPage(1);
  };
  const handleResetFilterMenu = () => {
    setFilterObj({
      page_size: 10,
      page_number: 1,
      keyword: "",
      sort_by: "id",
    });
    setPage(1);
  };

  const handleDeleteRequest = async () => {
    const action = () => {
      const newList = faqList;
      const filterdData = newList?.filter(
        (data) => data?.id != selectedRow?.id
      );
      setFaqList(filterdData);
      setOpenDeleteModal(false);
      if (filterdData?.length == 0) {
        setFilterObj({ ...filterObj, page_number: 1 });
        setPage(1);
      }
    };
    const {res, err} = await deleteFAQ(selectedRow?.id);
    if(res){
      action();
    }
  };
  const breadcrumbs = [
    {
      active: true,
      path: "/faqs",
      title: "Frequently asked question",
    },
    {
      active: false,
      title: id
        ? "Edit  Frequently asked question"
        : "Add  new Frequently asked question",
    },
  ];
  return (
    <>
      <Loader open={openLoader} />
      <FAQTemplate
        setFilterObj={setFilterObj}
        data={mappedData}
        count={count}
        paginationPage={page}
        setPaginationPage={setPage}
        handlePageChange={onPageChange}
        openDeleteModal={openDeleteModal}
        setOpenDeleteModal={setOpenDeleteModal}
        rowData={rowData}
        details={faqData}
        handleApplyFilterMenu={handleApplyFilterMenu}
        handleResetFilterMenu={handleResetFilterMenu}
        handleDeleteRequest={handleDeleteRequest}
        breadcrumbs={breadcrumbs}
        roles={roles}
      />
    </>
  );
}

export default FAQPage;

import LoginLogo from "./Icons/login-logo.svg";
import HeaderLogo from "./Images/headerlogo.png";
import SideIcon from "./Icons/icon-menu.svg";
import HomeIcon from "./Icons/dashboard.svg";
import Users from "./Icons/users.svg";
import Operators from "./Icons/operators.svg";
import DiveClubs from "./Icons/dive-clubs.svg";
import Customers from "./Icons/customers.svg";
import Freelancers from "./Icons/freelancers.svg";
import Sponsors from "./Icons/sponsors.svg";
import DeleteIcon from "./Icons/delete-icon.svg";
import EditIcon from "./Icons/edit-icon.svg";
import Notifications from "./Icons/notifications.svg";
import LogoutIcon from "./Icons/logout.svg";
import PredefinedLookups from "./Icons/predefined-lookups.svg";
import Certifications from "./Icons/certifications.svg";
import Levels from "./Icons/levels.svg";
import Geolocations from "./Icons/geolocations.svg";
import SystemConfigurations from "./Icons/system-configurations.svg";
import Documents from "./Icons/documents.svg";
import Faqs from "./Icons/faqs.svg";
import AppVersion from "./Icons/app-version.svg";
import Location from "./Icons/location.svg";
import Download from "./Icons/download.svg";
import addImage from "./Icons/add-image.svg";
import Roles from "./Icons/roles.svg";
import BronzeIcon from "./Icons/bronze-icon.svg";
import GoldIcon from "./Icons/gold-icon.svg";
import PlatinumIcon from "./Icons/platinum-icon.svg";
import SilverIcon from "./Icons/silver-icon.svg";
import imagePlaceHolder from "./Icons/image_placeholder.svg";
import notification from "./Icons/notification.svg";
import EmptyUsers from "./Icons/empty-users.svg";
import PdfIcon from "./Icons/pdfIcon.png";

import notificationItem from "./Icons/notification-item.svg";
import InovaLogo from "./Images/inova.png";
import ComplainNotification from "./Icons/complaint-notification.svg";
import SystemNotificaion from "./Icons/System-notification.svg";
import BranchAccepted from "./Icons/branch-accepted.svg";
import PadLock from "./Icons/padlock.svg"
import Extract from "./Icons/extract.svg"
export const icons = {
  BranchAccepted,
  SystemNotificaion,
  ComplainNotification,
  PdfIcon,
  EmptyUsers,
  Roles,
  notification,
  notificationItem,
  LoginLogo,
  SideIcon,
  HomeIcon,
  Users,
  Operators,
  imagePlaceHolder,
  DiveClubs,
  Customers,
  Freelancers,
  Sponsors,
  DeleteIcon,
  EditIcon,
  Notifications,
  LogoutIcon,
  PredefinedLookups,
  Certifications,
  Levels,
  Geolocations,
  SystemConfigurations,
  Documents,
  Faqs,
  AppVersion,
  Location,
  Download,
  BronzeIcon,
  GoldIcon,
  PlatinumIcon,
  SilverIcon,
  addImage,
  PadLock,
  Extract
};

export const images = {
  HeaderLogo,
  InovaLogo,
};

import { Grid, Avatar, Menu, MenuItem, IconButton } from "@mui/material";
import { useState, useEffect } from "react";
import CustomPagination from "../../../Atoms/Pagination/Pagination";
import { icons } from "../../../../Assets/AssetHelper.js";
import PrimaryButton from "../../../Atoms/Buttons/Primary/PrimaryButton";
import moment from "moment";
import { addToFavoriate, approveFreelancer, deleteFavoriate, deleteFreelancer, updateFreelancer, updateRejectionReason } from "../../../../Contexts/APIs/Freelancers/Freelancers";
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';


import MoreMenu from "../Menu/MoreMenu";
import { toast } from "react-toastify";

import { useNavigate } from "react-router-dom";
import RejectionModal from "../../General/Models/RejectionModal";
const ListFreelancers = ({ count, freelancers, handleChange, freelancersPrivilages, filterObj, setFilterObj, page, setPage, handlePageChange, getFreelancers, roles,setList }) => {
    const navigate = useNavigate();
    // const [page, setPage] = useState(1);
    const [freelancerData, setData] = useState(freelancers)
    const [loading, setLoading] = useState(false)
    const userType = localStorage.getItem("userType")

    // const handlePageChange = (event, value) => {
    //     setPage(value);
    //     handleChange('page_number', value);
    // };

    /*const handleDeleteFreelancer = async (id) => {
        setLoading(true)
        // const data = {
        //     "black_list": {
        //         "black_listed_id": id,
        //         "black_listed_type": "DiveExpert" // DiveActivity, DiveCenter, DiveExpert
        //     }
        // }
        const { res, err } = await deleteFreelancer(id)
        if (res) setData(prevData => prevData.filter(data => data.id != id))
        if (err) toast.error(err)
        setLoading(false)
    }*/

    useEffect(() => {
        setData(freelancers)
    }, [freelancers])

    const [openRejectModel, setOpenRejectModel] = useState(false);
    const [modalType, setModalType] = useState('text');
    const [selectedOperator, setSelectedOperator] = useState(false);
    const [rejectionReason, setRejectionReason] = useState('');
    const [errorListener, setErrorListener] = useState(false);
  
      const handelRejectReason = async () => {
          if( rejectionReason.trim().length === 0 && modalType === 'form' ){
              setErrorListener(true);
          }else{
              if( modalType === 'form' ){
                  const { res, err } = await updateRejectionReason(selectedOperator?.user?.id,{
                      "user": {
                          "profile_status": "rejected",
                          "rejection_reason": `${rejectionReason}`
                      }
                  })
                  if (res) {
                      setOpenRejectModel(false);
                      getFreelancers(filterObj);
                      setModalType('text');                
                      setErrorListener(false);
                  }
              }else{
                  setModalType('form');
                  setErrorListener(false);
              }
          }
      } 
      const handelCancelReject = () => {
              setOpenRejectModel(false);
              setModalType('text');
      }
  
      const [rejectHeadName, setRejectHeadName] = useState()
      const handelOpenRejectReason = (value) => {
          setRejectHeadName(value);
          setOpenRejectModel(true);
      }

    return (
        <Grid container>
            <Grid container className="table-header mb-20px">
                <Grid item md={0.5} textAlign="center">ID</Grid>
                <Grid item md={1.8} >Name</Grid>
                <Grid item md={1.5}>Level</Grid>
                <Grid item sm={1.2}>Rate</Grid>
                <Grid item sm={3}>Location</Grid>
                <Grid item sm={1}>Status</Grid>
                <Grid item sm={2}>Profile Status</Grid>
                {
                    (!roles?.can_read && !roles?.can_update && !roles?.can_delete)
                    ? null
                    : <Grid item sm={1}>Action</Grid>
                }
            </Grid>
            {freelancers?.length === 0 && (
                <Grid container display="block" textAlign="center">
                    There are no records
                </Grid>
            )}
            {!loading && freelancerData?.map((freelancer, index) => (
                <Grid
                    key={freelancer?.id}
                    container
                    item
                    lg={12}
                    className="activity-item mb-20px"
                    onClick={ () => {
                        setSelectedOperator({ user: freelancer, index: index });
                        setRejectionReason(freelancer?.rejection_reason);
                    }}
                >
                    <Grid item md={0.5} textAlign="center" textTransform="capitalize" fontWeight="bold" color="#ccc">
                        {freelancer?.id}
                    </Grid>
                    <Grid item md={1.8} textTransform="capitalize" fontWeight="bold">
                        {`${freelancer?.firstname} ${freelancer?.lastname ? freelancer?.lastname : ''}`}
                    </Grid>
                    <Grid item md={1.5} textTransform="capitalize">
                        {freelancer?.level?.name}
                    </Grid>
                    <Grid item sm={1.2} textTransform="capitalize">
                        {`${freelancer?.rate ? freelancer?.rate : ''} ${freelancer?.currency?.lookup_key ? freelancer?.currency?.lookup_key : ''}`}
                    </Grid>
                    <Grid item sm={3} textTransform="capitalize">
                        {freelancer?.area?.name && `${freelancer?.area?.name}/${freelancer?.city?.name}/${freelancer?.country?.name}`}
                    </Grid>
                    <Grid item sm={1} textTransform="capitalize">
                        {freelancer?.account_status}
                    </Grid>
                    <Grid item sm={2} color={freelancer?.profile_status == 'approved' ? '#00b906' : freelancer?.profile_status == 'rejected' ? '#e10007' : '#f6b119'} textTransform="capitalize" display="flex" flexDirection="column">
                        { freelancer?.profile_status === 'pending_approval' ? 'pending approval'
                            : freelancer?.profile_status
                        }
                        {
                            freelancer?.profile_status === 'rejected' && <button
                            style={{ background: 'transparent', textDecoration: 'underLine', border: 'none',
                            fontSize: '16px', lineHeight: '1.88', textAlign: 'left', color: '#272726', cursor: 'pointer'
                            }}
                            onClick={() => handelOpenRejectReason(freelancer?.name)}> ( View reasons ) </button>
                        }
                    </Grid> 

                    {
                        (!roles?.can_read && !roles?.can_update && !roles?.can_delete)
                        ? null
                        : <Grid item sm={1} textTransform="capitalize">
                            <MoreMenu
                                freelancer={freelancer}
                                // deleteFreelancer={(id) => handleDeleteFreelancer(id)}
                                handleApprove={ approveFreelancer }
                                handleUpdate={ updateFreelancer }
                                handleDelete={ deleteFreelancer }
                                handleRejection={ updateRejectionReason }
                                getFreelancers={getFreelancers}
                                filterObj={filterObj}
                                setFilterObj={setFilterObj}
                                setErrorListener={setErrorListener}
                                errorListener={errorListener}
                                setPage={setPage}
                                roles={roles}
                                // handlePageChange={handlePageChange}
                                setList={setList}
                            />
                            {/* 
                            {userType === 'descendant_user' && freelancersPrivilages?.can_delete &&
                                <PrimaryButton
                                    btnType="icon"
                                    startIcon={<img width="35" src={icons.DeleteIcon} alt="delete" />}
                                    // click={async () => {
                                    //     const action = async () => {
                                    //         let newData = await data
                                    //         let filteredData = await newData?.filter((data, idx) => idx != index)
                                    //         setData(filteredData)
                                    //     }
                                    //     const data = {
                                    //         "black_list": {
                                    //             "black_listed_id": freelancer?.id,
                                    //             "black_listed_type": "DiveExpert" // DiveActivity, DiveCenter, DiveExpert
                                    //         }
                                    //     }
                                    //     const { res, err } = await deleteFreelancer(data, action, setLoading)
                                    // }}
                                    click={ () => handleDeleteFreelancer(freelancer, index) }
                                />
                            }
                            {userType !== 'descendant_user' &&
                                <PrimaryButton
                                    btnType="icon"
                                    startIcon={<img width="35" src={icons.DeleteIcon} alt="delete" />}
                                    // click={async () => {
                                    //     const action = async () => {
                                    //         let newData = await data
                                    //         let filteredData = await newData?.filter((data, idx) => idx != index)
                                    //         setData(filteredData)
                                    //     }
                                    //     const data = {
                                    //         "black_list": {
                                    //             "black_listed_id": freelancer?.id,
                                    //             "black_listed_type": "DiveExpert" // DiveActivity, DiveCenter, DiveExpert
                                    //         }
                                    //     }
                                    //     const { res, err } = await deleteFreelancer(data, action, setLoading)
                                    // }}
                                    click={ () => handleDeleteFreelancer(freelancer, index) }
                                />
                            } */}
                        </Grid>
                    }
                </Grid>
            ))}
            {count > 10 &&
                <Grid container justifyContent='center' mb={7.5} mt={5}>
                    <CustomPagination
                        count={Math.ceil(count / 10)}
                        size='large'
                        shape="rounded"
                        page={page}
                        handleChange={handlePageChange}
                        className='pagination'
                    />
                </Grid>
            }

        {
          openRejectModel === true && <RejectionModal
              open={openRejectModel}
              setOpen={setOpenRejectModel}
              text={[`Rejection reason  ( ${rejectHeadName} )`, rejectionReason]}
              rejectionReason={rejectionReason}   setRejectionReason={setRejectionReason}
              errorListener={errorListener}
              buttons={['Cancel', `${ modalType === 'form' ? 'ok' : 'Edit reason' }`
              ]}
              handelRejectRequest={() => {handelRejectReason();}}
              handelCancelReject={handelCancelReject}
              modalType={modalType}            
          />
        }
        </Grid>
    );
}

export default ListFreelancers;
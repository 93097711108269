import React from "react";
import { getBranchesList } from "../../../Contexts/APIs/Branches/Branches";
import BranchesListTemplate from "../../../Templates/Main/Branches/BranchesList.Template";
import { useState } from "react";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import usePageFilter from "../../../Components/Atoms/Hooks/usePageFilter";

function BranchesListPage({ roles }) {
  const [openLoader, setOpenLoader] = useState(false);
  const [branchesList, setBranchesList] = useState([]);

  const [count, setCount] = useState(0);
  const { page, filterObj, handlePageChange ,setFilterObj,setPage,searchParams} = usePageFilter();

  // const [page, setPage] = useState(1);

  // const [filterObj, setFilterObj] = useState(null);
  // let searchParams = new URLSearchParams(window.location.search);
  // const [pageNumber, setPageNumber] = useState(
  //   () => +searchParams.get("page_number") || 1
  // );
  // const handlePageChange = (newPage) => {
  //   setPageNumber(newPage); 
  // };

  // usePageFilter(pageNumber, page, setPage, filterObj, setFilterObj);

  const getBranches = async (filterObj) => {
    const defaultFilter = {
      page_size: 10,
      page_number: 1,
      keyword: "",
      sort_by: "id",
      dive_center_id: searchParams?.get("dive_center_id") || "",
    };
    setOpenLoader(true);
    const { res, error } = await getBranchesList({
      ...defaultFilter,
      ...filterObj,
    });
    if (res) {
      setOpenLoader(false);
      setBranchesList(res?.data?.branches);
      setCount(res?.extra?.total_count);
    }
    if (error) {
      setOpenLoader(false);
    }
  };

  useEffect(() => {
    if (filterObj && (filterObj.keyword !== "" || filterObj.keyword !== null))
      getBranches(filterObj);
  }, [filterObj]);

  return (
    <BranchesListTemplate
      filterObj={filterObj}
      setFilterObj={setFilterObj}
      branchesList={branchesList}
      openLoader={openLoader}
      count={count}
      page={page}
      setPage={setPage}
      handlePageChange={handlePageChange}
      getBranches={getBranches}
      roles={roles}
      setBranchesList={setBranchesList}
    />
  );
}

export default BranchesListPage;

import React, { useEffect, useState } from "react";
import Loader from "../../../../Components/Atoms/Loader/Loader";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getSupportedLanguages } from "../../../../Contexts/APIs/Lookups/SupportedLanguages";
import { toast } from "react-toastify";
import {
  createBadge,
  deleteBadge,
  editBadge,
  getBadgeDetails,
  getBadgesList,
} from "../../../../Contexts/APIs/Badges/Badges";
import BadgesTemplate from "../../../../Templates/Main/PredefinedLookups/Badges/Badges.template";
import usePageFilter from "../../../../Components/Atoms/Hooks/usePageFilter";

function BadgesPage({ roles }) {
  const navigate = useNavigate();
  // let [searchParams] = useSearchParams();
  const {
    page,
    filterObj,
    handlePageChange: onPageChange,
    setFilterObj,
    setPage,
    searchParams,
  } = usePageFilter();
  const id = searchParams.get("id");
  const index = searchParams.get("index");
  const badgeId = searchParams.get("id");
  const type = searchParams.get("type");

  const [openLoader, setOpenLoader] = useState(false);
  const [badgesList, setBadgesList] = useState([]);

  const [count, setCount] = useState(0);
  // const [page, setPage] = useState(1);

  // const [filterObj, setFilterObj] = useState(null);

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [supportedLanguages, setSupportedLanguages] = useState([]);
  const [supportLang, setSupportLang] = useState("en");
  const [badgeData, setBadgeData] = useState({});

  const mappedData = badgesList?.map((data) => {
    return {
      ...data,
      badge_id: <div style={{ width: "70px" }}>{data?.id}</div>,
      badge_img: (
        <div style={{ width: "50px" }}>
          <img
            src={data?.image}
            style={{
              borderRadius: "50%",
              width: "56px",
              height: "56px",
              display: "block",
            }}
          />
        </div>
      ),
      badge_name: <div style={{ width: "25vw" }}>{data?.name}</div>,
    };
  });

  const breadcrumbs = [
    {
      active: true,
      path: "/predefined-lookups/currency",
      title: "Predefined lookups",
    },
    {
      active: true,
      path: "/predefined-lookups/badges",
      title: "Badges",
    },
    {
      active: false,
      title: index === null ? "Add new badge" : "Edit badge",
    },
  ];

  // const updatePagination = (page) => {
  //   setFilterObj({ ...filterObj, page_number: page });
  // };
  // const handlePageChange = (event, value) => {
  //   setPage(value);
  //   updatePagination(value);
  // };

  const getLanguages = async () => {
    const { res, err } = await getSupportedLanguages();
    if (res) {
      setSupportedLanguages(res?.data?.supported_locales);
    }
  };
  const updateSupportedLang = (e, languages) => {
    setSupportLang(e.target.value);
  };

  /*const handleDeleteRequest = async () => {
    const action = () => {
      const newList = badgesList;
      const filterdData = newList?.filter(
        (data) => data?.id != selectedRow?.id
      );
      setBadgesList(filterdData);
      setOpenDeleteModal(false);
      if (filterdData?.length == 0) {
        setFilterObj({ ...filterObj, page_number: 1 });
        setPage(1);
      }
    };
    const { res, err } = await editBadge(
      selectedRow?.id,
      {
        badge: { is_deleted: true },
      },
      action
    );
  };*/

  const rowData = (data) => {
    setSelectedRow(data?.rowData);
  };

  const getBadge = async () => {
    const { res, err } = await getBadgeDetails(badgeId);
    if (res) {
      setBadgeData(res?.data?.badge);
    }
    if (err) {
      toast.error(err);
    }
  };

  const getBadges = async (filterObj) => {
    const defaultFilter = {
      page_size: 10,
      page_number: 1,
      keyword: "",
      sort_by: "id",
    };
    setOpenLoader(true);
    const { res, error } = await getBadgesList({
      ...defaultFilter,
      ...filterObj,
    });
    if (res) {
      setOpenLoader(false);
      setBadgesList(res?.data?.badges);
      setCount(res?.extra?.total_count);
    }
    if (error) {
      setOpenLoader(false);
    }
  };

  const handleCreateBadge = async (values) => {
    const { res, err } = await createBadge({
      badge: values,
    });
    if (res) {
      navigate("/predefined-lookups/badges");
    }
  };
  const handleEditBadge = async (values) => {
    const { res, err } = await editBadge(badgeId, {
      badge: values,
    });
    if (res) {
      navigate("/predefined-lookups/badges");
    }
  };
  const handleSubmit = (data, formik) => {
    const action = () => {
      searchParams.delete("id");
      searchParams.delete("type");
      searchParams.delete("index");
      formik.resetForm();
    };
    if (index) {
      handleEditBadge(data);
      action();
    } else {
      handleCreateBadge(data);
      action();
    }
  };

  const handleDeleteRequest = async () => {
    const action = (data) => {
      setOpenDeleteModal(false);
      setBadgesList((prevState) =>
        prevState?.filter((ele) => ele?.id !== data?.data?.badge?.id)
      )
    };
    deleteBadge(selectedRow?.id, action);
  };

  useEffect(() => {
    if (type == "form") getLanguages();
  }, [type]);

  useEffect(() => {
    badgeId && getBadge(badgeId);
  }, [badgeId]);

  useEffect(() => {
    if (filterObj) getBadges(filterObj);
  }, [filterObj]);

  return (
    <>
      <Loader open={openLoader} />
      <BadgesTemplate
        setFilterObj={setFilterObj}
        data={mappedData}
        count={count}
        paginationPage={page}
        setPaginationPage={setPage}
        handlePageChange={onPageChange}
        openDeleteModal={openDeleteModal}
        setOpenDeleteModal={setOpenDeleteModal}
        handleDeleteRequest={handleDeleteRequest}
        supportedLanguages={supportedLanguages}
        updateSupportedLang={updateSupportedLang}
        setSupportLang={setSupportLang}
        supportLang={supportLang}
        handleSubmit={handleSubmit}
        rowData={rowData}
        badgeData={badgeData}
        breadcrumbs={breadcrumbs}
        roles={roles}
      />
    </>
  );
}

export default BadgesPage;

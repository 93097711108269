import { Box, Divider, Stack, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import moment from "moment";
import { useEffect, useState } from "react";
import { icons } from "../../../Assets/AssetHelper";
import { getNotificationsList } from "../../../Contexts/APIs/Notifications/Notifications";

import { useNavigate } from "react-router-dom";
import { removeDuplicateObjects } from "../../../Contexts/APIs/Helper/Helper";
import { Firebase, auth } from "../../../Firebase/Firebase";
import InfiniteScroll from "react-infinite-scroll-component";
import CircleIcon from "@mui/icons-material/Circle";

const Notifications = ({ canReadOrders }) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [list, setList] = useState([]);
  const [notifCount, setNotifCount] = useState(0);
  const [unreadCount, setUnreadCount] = useState(0);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    (list?.length === 0 || unreadCount !== 0) && notificationsList(1);
    setAnchorEl(event.currentTarget);
  };

  const actions = () => {
    return {
      branch_awaiting_approval: "/operators/branches",
      dive_center_awaiting_approval: "/operators/main",
      complaints: null,
    };
  };

  const notificationIcon = (type) => {
    const notifications = {
      branch_awaiting_approval: icons.BranchAccepted,
      dive_center_awaiting_approval: null,
      complaints: icons.ComplainNotification,
    };
    return notifications[type];
  };

  const setIsSeenTrue = (id) => {
    const updatedNotifications = list.map((notification) => {
      if (notification?.id == id) {
        return { ...notification, is_seen: true };
      } else {
        return notification;
      }
    });
    setList(updatedNotifications);
  };
  const handleClose = (data) => {
    setIsSeenTrue(data?.id);
    setAnchorEl(null);
    if (data?.need_action) {
      navigate(actions()[data?.notification_type]);
    }
  };
  // enum notification_type: { system: 0, dive_team_request: 1, dive_team: 2, dive_expert: 3, job_offer: 4, job_request: 5}

  const notificationsList = async (page_number) => {
    page_number = page_number ?? Math?.ceil(list?.length / 10 + 1);
    const { res, err } = await getNotificationsList({ self: true, page_number });
    if (res) {
      const notifications = [...list, ...res?.data?.notifications];
      const uniqueNotifications = removeDuplicateObjects(notifications, "id");
      const sortedNotifications = uniqueNotifications.sort((a, b) => b?.id - a?.id);
      setList(sortedNotifications);
      setNotifCount(res?.extra?.total_count);
    }
  };

  useEffect(() => {
    // start listening on the node when sign in firebase
    const unsubscribe = auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        Firebase.listenOnNode(setUnreadCount);
      }
    });
    return () => unsubscribe();
  }, []);

  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <img src={icons.Notifications} alt="" />
        {unreadCount !== 0 && (
          <Stack
            sx={{
              position: "absolute",
              top: "20%",
              right: "20%",
              bgcolor: "red",
              borderRadius: "50%",
              height: "20px",
              width: "20px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography sx={{ color: "#fff", fontSize: "12px" }}>{unreadCount}</Typography>
          </Stack>
        )}
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: "485px",
          },
        }}
      >
        <Typography
          sx={{
            fontSize: "24px",
            fontWeight: "bold",
            textAlign: "left",
            color: "#333132",
            padding: "16px 20px 10px",
          }}
        >
          {" "}
          Notifications{" "}
        </Typography>
        <Divider style={{ width: "100%" }} />
        <InfiniteScroll
          dataLength={list?.length} //This is important field to render the next data
          next={notificationsList}
          hasMore={notifCount != list?.length}
          height={500}
        >
          {list.map((option, index) => (
            <MenuItem
              key={option}
              selected={option === "Pyxis"}
              onClick={() => handleClose(option)}
              sx={{ justifyContent: "space-between" }}
            >
              <Stack sx={{ width: "100%" }}>
                <Stack flexDirection="row" gap={2} alignItems="center" sx={{ width: "95%" }}>
                  <Stack
                    width="64px"
                    height="64px"
                    borderRadius="50%"
                    border="1px solid #faa100"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Box as="img" src={notificationIcon(option?.notification_type)} width="64px" height="64px" />
                  </Stack>

                  <Typography sx={{ textWrap: "wrap" }} fontSize="18px" fontWeight="bold">
                    {option?.message}
                  </Typography>
                </Stack>
                <Typography alignSelf="end" color="#bfbfbf" fontSize="14px">
                  {moment(option?.sent_at).format("ddd , HH:MM A")}
                </Typography>
              </Stack>
              {!option?.is_seen && <CircleIcon sx={{ fontSize: "small", color: "orange", position: "absolute", right: 3 }} />}
            </MenuItem>
          ))}
        </InfiniteScroll>
      </Menu>
    </div>
  );
};

export default Notifications;

import { Rating, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import CircleIcon from "@mui/icons-material/Circle";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import "./FreelancerDetailsHeader.css";
import {
  approveFreelancer,
  deleteFreelancer,
  updateFreelancer,
  updateRejectionReason,
} from "../../../Contexts/APIs/Freelancers/Freelancers";
import MoreMenu from "./Menu/MoreMenu";
import { useNavigate } from "react-router-dom";

const FreelancerDetailsHeader = ({ data, getFreelancer, link, roles }) => {
  const StyledRating = styled(Rating)({
    "& .MuiRating-iconFilled": {
      color: "#3ed181",
    },
    "& .MuiRating-iconHover": {
      color: "#3ed181",
    },
  });

  const navigate = useNavigate();
  const [errorListener, setErrorListener] = useState(false);

  return (
    <Stack
      alignItems="center"
      bgcolor="#333132"
      borderRadius="10px"
      padding={2}
      flexDirection="row"
      justifyContent="space-between"
    >
      <Stack flexDirection="row" gap={2} width="100%">
        <img
          src={data?.avatar}
          width="90px"
          height="90px"
          style={{ borderRadius: "10px" }}
        />
        <Stack
          flexDirection="row"
          justifyContent="space-between"
          width="100%"
          className="details-header-content"
        >
          <Stack flexDirection="column" justifyContent="space-evenly">
            <Stack flexDirection="row" gap={2} alignItems="center">
              <Typography
                fontSize="30px"
                fontWeight="bold"
                color="white"
                textTransform="capitalize"
              >
                {data?.name}
              </Typography>
              <Stack
                flexDirection="row"
                gap={0.5}
                alignItems="center"
                style={{ cursor: "pointer" }}
                onClick={() => link && navigate(link)}
              >
                <StyledRating
                  name="customized-color"
                  value={data?.dive_center_avg_rate}
                  readOnly={true}
                  icon={
                    <CircleIcon
                      fontSize="inherit"
                      style={{ fontSize: "12px" }}
                    />
                  }
                  emptyIcon={
                    <CircleOutlinedIcon
                      fontSize="inherit"
                      style={{ fontSize: "12px", color: "#3ed181" }}
                    />
                  }
                />
                <Typography fontSize="20px" fontWeight="600" color="#3ed181">
                  ({data?.dive_center_reviews_count} reviews)
                </Typography>
              </Stack>
            </Stack>
            <Stack flexDirection="row" gap={2} alignItems="center">
              <Typography fontSize="20px" fontWeight="bold" color="white">
                Freelancer
              </Typography>
              <Typography fontSize="20px" fontWeight="bold" color="#f6b119">
                {
                  data?.rate ? `${data?.rate} ${data?.currency?.lookup_key}/day` : "N/A /day"
                }                
              </Typography>
            </Stack>
          </Stack>
          <Stack flexDirection="row" gap={2}>
            <Stack
              flexDirection="column"
              py="4px"
              justifyContent="space-between"
            >
              <Typography
                fontSize="18px"
                color="white"
                display="block"
                textTransform="capitalize"
              >
                Status : {data?.account_status}
              </Typography>
              <Stack flexDirection="row" gap={"16px 5px"} alignItems="center">
                <Typography fontSize="18px" color="white">
                  Profile status :{" "}
                </Typography>
                <Typography
                  fontSize="18px"
                  textTransform="capitalize"
                  color={
                    data?.profile_status == "approved"
                      ? "#00b906"
                      : data?.profile_status == "rejected"
                      ? "#e10007"
                      : "#f6b119"
                  }
                >
                  {data?.profile_status === "pending_approval"
                    ? "pending"
                    : data?.profile_status}
                </Typography>
              </Stack>
            </Stack>
            {
              (!roles?.can_read && !roles?.can_update && !roles?.can_delete)
              ? null
              : <Stack
                flexDirection="row"
                justifyContent="center"
                alignItems="start"
              >
                <MoreMenu
                  freelancer={data}
                  handleApprove={approveFreelancer}
                  handleUpdate={updateFreelancer}
                  handleDelete={deleteFreelancer}
                  handleRejection={updateRejectionReason}
                  getFreelancer={getFreelancer}
                  freelancerCount={"single"}
                  setErrorListener={setErrorListener}
                  errorListener={errorListener}
                  roles={roles}
                />
              </Stack>
            }
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default FreelancerDetailsHeader;

import { axiosFn, handleError } from "..";
import { makeFilterString } from "../Helper/Helper";
import * as URL from "../URLs";

export async function getSystemConfigsList(filter_obj) {
  return await axiosFn(
    "get",
    URL.SystemConfigurations + makeFilterString(filter_obj)
  )
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}

export async function getSystemConfigDetails(systemConfig_id) {
  return await axiosFn("get", `${URL.SystemConfigurations}/${systemConfig_id}`)
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}

export async function editSystemConfig(id, data, action) {
  return await axiosFn("put", `${URL.SystemConfigurations}/${id}`, data)
    .then((res) => {
      action && action();
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}

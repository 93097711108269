import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  createRole,
  editRole,
  getPermissionList,
  showRole,
} from "../../../../Contexts/APIs/Users/Roles";
import RoleFormTemplate from "../../../../Templates/Main/Users/Roles/RoleForm.template";
import * as yup from "yup";
import { toast } from "react-toastify";

function RoleFormPage(props) {
  const { formType } = props;
  const navigate = useNavigate();

  const [roleDataRes, setRoleDataRes] = useState([]);
  const [roleData, setRoleData] = useState({});
  const [openLoader, setOpenLoader] = useState(false);


  const [selectedRows, setSelectedRows] = useState(new Set()); // use Set for faster add/remove operations
  const [rows, setRows] = useState([]); // holds the status of each row, selected or not selected 
  const [optionsStateObj,setOptionsStateObj]=useState({'select_all':false,'can_create':false,'can_read':false,
    'can_update':false,'can_delete':false,'disabled':true})


  const exceptions={'can_create':['Activity Offers','Customer Levels','Freelancer Levels','Documents','Salesman Feature','Salesman Reservations'],
    'can_read':[],
    'can_update':['Activity Offers','Salesman Feature','Salesman Reservations'],
    'can_delete':['Activity Offers','Customer Levels','Freelancer Levels','Documents','Salesman Feature','Salesman Reservations']}

  const updateStateObj = (selectedRows,flagObj) => {
    let newState = optionsStateObj

    if (selectedRows.size === 0) { //disable permissions if no row was selected //return to default
        newState={'select_all':false,'can_create':false,'can_read':false,
          'can_update':false,'can_delete':false,'disabled':true}
    } 
    else{
      newState={...newState,'can_create':flagObj.allCanCreate,'can_read':flagObj.allCanRead,'can_update':flagObj.allCanUpdate,'can_delete':flagObj.allCanDelete,'select_all':flagObj.allSelected,disabled:false}
    }
    return newState;
  };


  const roleDataResWithExceptionsRemoved = (updatedSelectedRows,exceptions) => {
    return Array.from(updatedSelectedRows).map(index => roleDataRes[index]).filter(obj => !exceptions.includes(obj.title));
  };


  const handleSelectedRowsChange = (checked, idx) => {
    let flagObj={'allCanCreate':false,'allCanRead':false,'allCanUpdate':false,'allCanDelete':false}

    if (idx === "all") { //if select all was pressed
      const updatedRows = new Array(rows.length).fill(checked); //create an array with the same length 
      const updatedSelectedRows = new Set(); //to update selected rows (everything or empty)

      if (checked) {
        rows.forEach((_, index) => updatedSelectedRows.add(index)); //there is no faster way in js
        //i want to check each button if all of them are already selected then it should be on when select all 
        flagObj = {
          allCanCreate: roleDataResWithExceptionsRemoved(updatedSelectedRows,exceptions.can_create).every(obj => obj.can_create === true),
          allCanRead: roleDataResWithExceptionsRemoved(updatedSelectedRows,exceptions.can_read).every(obj => obj.can_read === true),
          allCanUpdate: roleDataResWithExceptionsRemoved(updatedSelectedRows,exceptions.can_update).every(obj => obj.can_update === true),
          allCanDelete: roleDataResWithExceptionsRemoved(updatedSelectedRows,exceptions.can_delete).every(obj => obj.can_delete === true),
          allSelected:checked
        };
  
      }

      setSelectedRows(updatedSelectedRows);
      setOptionsStateObj(updateStateObj(updatedSelectedRows,flagObj));
      setRows(updatedRows);
    } else {
      const updatedSelectedRows = new Set(selectedRows);
      const updatedRows = [...rows];

      if (checked) { //if it's checked then add it to the list of selected rows
        updatedSelectedRows.add(idx);
        updatedRows[idx] = true;
      } else {
        updatedSelectedRows.delete(idx);
        updatedRows[idx] = false;
      }

      // if user selects all the options manually then select all button will be checked
      const allSelected = updatedSelectedRows.size === rows.length;
      flagObj = {
        allCanCreate: roleDataResWithExceptionsRemoved(updatedSelectedRows,exceptions.can_create).every(obj => obj.can_create === true),
        allCanRead: roleDataResWithExceptionsRemoved(updatedSelectedRows,exceptions.can_read).every(obj => obj.can_read === true),
        allCanUpdate: roleDataResWithExceptionsRemoved(updatedSelectedRows,exceptions.can_update).every(obj => obj.can_update === true),
        allCanDelete: roleDataResWithExceptionsRemoved(updatedSelectedRows,exceptions.can_delete).every(obj => obj.can_delete === true),
        allSelected:allSelected
      };

      setOptionsStateObj(updateStateObj(updatedSelectedRows,flagObj));
      setSelectedRows(updatedSelectedRows);
      setRows(updatedRows);
    }
  };



  const handleBatchPermissionsChange = (permission, checked) => {
    const updatedStateObj = {
      ...optionsStateObj,
      [permission]:  checked ,
    };
    setOptionsStateObj(updatedStateObj);

    const updatedRoleDataRes = [...roleDataRes];
    selectedRows.forEach((idx) => {
      if (!exceptions[permission].includes(updatedRoleDataRes[idx].title)) {
        updatedRoleDataRes[idx][permission] = checked;
      }
    });

    setRoleDataRes(updatedRoleDataRes);
  };


  const getPermissions = async () => {
    const { res, error } = await getPermissionList({
      // for: "admin_user_descendant", was removed in stagging
      for: "admin_user_descendant",
      page_size: 100,
    });
    if (res) {
      setRoleDataRes(
        res?.data?.resources.map((data, index) => {
          return {
            title: data.presentation,
            resource_id: data.id,
            can_create: false,
            can_read: false,
            can_update: false,
            can_delete: false,
          };
        })
      );
      setRows(new Array(res.data.resources.length).fill(false));//added for batch update
    }
  };

  useEffect(() => {
    if (formType === "Edit" && Object.keys(roleData).length !== 0) {
      setRoleDataRes(
        roleData.permissions.map((data, index) => {
          return {
            id: data.id,
            title: data.resource.presentation,
            resource_id: data.resource.id,
            can_create: data.can_create,
            can_read: data.can_read,
            can_update: data.can_update,
            can_delete: data.can_delete,
            selected:false,//added for batch update
          };
        })
      );
      setRows(new Array(roleData.permissions.length).fill(false));//added for batch update

    } else {
      getPermissions();
    }
  }, [roleData]);



  const handlePermissionsChange = (type, value, id) => {
    const obj = roleDataRes.find((item) => item.resource_id === id);
    obj[type] = value;

    setRoleDataRes((prev) => [
      ...prev.map((item) => {
        if (item.resource_id === id) {
          return obj;
        } else {
          return item;
        }
      }),
    ]);
    const flagObj = {
      allCanCreate: roleDataResWithExceptionsRemoved(selectedRows,exceptions.can_create).every(obj => obj.can_create === true),
      allCanRead:   roleDataResWithExceptionsRemoved(selectedRows,exceptions.can_read).every(obj => obj.can_read === true),
      allCanUpdate: roleDataResWithExceptionsRemoved(selectedRows,exceptions.can_update).every(obj => obj.can_update === true),
      allCanDelete: roleDataResWithExceptionsRemoved(selectedRows,exceptions.can_delete).every(obj => obj.can_delete === true),
      allSelected:  selectedRows.size === rows.length
    };
    setOptionsStateObj(updateStateObj(selectedRows,flagObj));

  };

  const handleCreateRole = async (values) => {
    const { res, err } = await createRole({
      role: {
        name: values.name,
        // owner_id: localStorage.getItem("userId"),
        // owner_type: "AdminUser",
        permissions_attributes: roleDataRes,
      },
    });
    if (res) {
      getPermissions();
      navigate("/users/roles");
    }
  };

  const handleEditRole = async (values) => {
    const href = window.location.href;
    const { res, err } = await editRole(
      `${href.slice(href.indexOf("edit/") + 5, href.length)}`,
      {
        role: {
          name: values.name,
          // owner_id: localStorage.getItem("userId"),
          // owner_type: "AdminUser",
          permissions_attributes: roleDataRes,
        },
      }
    );
    if (res) {
      navigate("/users/roles");
    }
  };

  const getRole = async (id) => {
    setOpenLoader(true);
    const { res, error } = await showRole(id);
    if (res) {
      setRoleData(res?.data?.role);
      formik.setValues({
        name: res?.data?.role?.name,
      });
    }
    setOpenLoader(false);
  };

  useEffect(() => {
    const href = window.location.href;
    href.indexOf("edit/") > 0 && getRole(`${href.slice(href.indexOf("edit/") + 5, href.length)}`);
  }, [window.location.href.indexOf("edit/")]);

  const formik = useFormik({
    initialValues: {
      name: roleData.name ? roleData.name : "",
    },
    validationSchema: yup.object({
      name: yup
        .string()
        .min(5, "role title must 5 chars at least")
        .required("role title required"),
    }),
    onSubmit: (values) => {
      formType === "Add" && handleCreateRole(values);
      formType === "Edit" && handleEditRole(values);
    },
  });

  const validationMethod = () => {
    if (formik.values.name.length === 0) {
      toast.error("please type role title");
    } else {
      formik.handleSubmit();
    }
  };

  return (
    <RoleFormTemplate
      formik={formik}
      rows={rows}
      optionsStateObj={optionsStateObj}
      handleSelectedRowsChange={handleSelectedRowsChange}
      roleDataRes={roleDataRes}
      validationMethod={validationMethod}
      handlePermissionsChange={handlePermissionsChange}
      handleBatchPermissionsChange={handleBatchPermissionsChange}
      openLoader={openLoader}
      formType={formType}
    />
  );
}

export default RoleFormPage;

import React, { useEffect } from "react";
import ActivitiesList from "../../Molecules/Activities/ActivitiesList/ActivitiesList";
import ActivitiesHeader from "../../Molecules/Activities/ActivitiesHeader/ActivitiesHeader";
import Loader from "../../Atoms/Loader/Loader";
import SearchIcon from "@mui/icons-material/Search";
import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

function Activities(props) {
  const {
    filterObj,
    setFilterObj,
    activitiesList,
    openLoader,
    count,
    page,
    setPage,
    handlePageChange,
    activitiesLength,
    getActivities,
    roles,
    setActivitiesList
  } = props;

  const [ActivityValue, setActivityValue] = useState(0);
  const navigate = useNavigate();
  const ActivitiesTypes = [
    {
      id: 1,
      label: "all",
    },
    {
      id: 2,
      label: "dive trip",
    },
    {
      id: 3,
      label: "courses",
    },
    {
      id: 4,
      label: "liveaboard",
    },
  ];

  const [searchParams] = useSearchParams();
  const activityType = searchParams.get('activity_type');
  useEffect(() => {
    setTimeout(() => {
        if (activityType === 'all') {
          setFilterObj({ ...filterObj, page_number: 1, activity_type: '' });
          setActivityValue(0);
        }
        if (activityType === 'dive_trip') {
          setFilterObj({ ...filterObj, page_number: 1, activity_type: 'dive_trip' });
          setActivityValue(1);
        }
        if (activityType === 'dive_course') {
          setFilterObj({ ...filterObj, page_number: 1, activity_type: 'dive_course' });
          setActivityValue(2);
        }
        if (activityType === 'live_aboard') {
          setFilterObj({ ...filterObj, page_number: 1, activity_type: 'live_aboard' });
          setActivityValue(3);
        }        
    }, 500);
  }, [activityType])

  const handleActivityValue = (event, newValue) => {
    setActivityValue(newValue);    
      if (newValue === 0){ navigate('?activity_type=all'); }
      if (newValue === 1){ navigate('?activity_type=dive_trip'); }
      if (newValue === 2){ navigate('?activity_type=dive_course'); }
      if (newValue === 3){ navigate('?activity_type=live_aboard'); }
  }

  const getActivityType = (value) => {
    setPage(1);
  };
  return (
    <>
      <Loader open={openLoader} />
      <ActivitiesHeader
        breadCrumb={{
          path: "/operators/main",
          link: "Dive centers",
          text: "Dive activites",
        }}
        title="Activities"
        subTitle={`${activitiesLength} ${
          activitiesLength === 1 ? "activity" : "activities"
        }`}
        searchInput={{
          type: "search",
          placeholder: "Search activities",
          StartAdornment: { SearchIcon },
          className: "Search-activity",
        }}
        filterObj={filterObj}
        setFilterObj={setFilterObj}
        options={ActivitiesTypes}
        ActivityValue={ActivityValue}
        handleActivityValue={handleActivityValue}
        getActivityType={(value) => {
          getActivityType(value);
        }}
        setPage={setPage}
        handleChange={handlePageChange}
        />
      <ActivitiesList
        activitiesList={activitiesList}
        count={count}
        page={page}
        handlePageChange={handlePageChange}
        getActivities={getActivities}
        filterObj={filterObj}
        roles={roles}
        setFilterObj={setFilterObj}
        setPage={setPage}
        setActivitiesList={setActivitiesList}
      />
    </>
  );
}

export default Activities;

import React from "react";
import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Languages from "../../../../Components/Atoms/Shared/language/Language";
import CCrud from "../../../../Components/Molecules/General/Crud/CCrud";
import "../../../../Components/Atoms/Shared/Styles/list-page.css";
import { initialValues, structure, validationSchema } from "./structure";
import AddIcon from "@mui/icons-material/Add";
import "./styles.css";

import {
  createDocuments,
  updateDocuments,
} from "../../../../Contexts/APIs/Documents/Documents";

const DocumentsTemplate = ({
  setFilterObj,
  data,
  openDeleteModal,
  setOpenDeleteModal,
  handleDeleteRequest,
  count,
  paginationPage,
  setPaginationPage,
  handlePageChange,
  rowData,
  details,
  breadcrumbs,
  roles
}) => {
  let [searchParams, setSearchParams] = useSearchParams();
  const index = searchParams.get("index");
  const id = searchParams.get("id");
  const navigate = useNavigate();
  const [supportedLang, setSupportedLang] = useState("en");

  const handelSubmit = (data, formik) => {
    const action = () => {
      searchParams.delete("id");
      searchParams.delete("type");
      searchParams.delete("index");
      formik?.resetForm();
      if (index) {
        navigate(`/documents/${id}?type=details&id=${id}&index=${index}`);
      }else{
        navigate("/documents");
      }
    };

    if (index) {
      updateDocuments({
        id,
        data: { document: data },
        action,
      });
    } else {
      createDocuments({
        data: { document: data },
        action,
      });
    }
  };

  const handleApplyFilterMenu = (obj) => {
    setFilterObj((prev) => {
      return { ...prev, ...obj, page_number: 1 };
    });
    setPaginationPage(1);
  };
  const handleResetFilterMenu = () => {
    setFilterObj({
      page_size: 10,
      page_number: 1,
      keyword: "",
      sort_by: "id",
    });
    setPaginationPage(1);
  };
  const actionsList = ({ id, index, details }) => {
    return [
      {
        condition: () => {
          if (roles?.can_read) {
            return true;
          } else {
            return false;
          }
        },
        click: () => {
          navigate(`/documents/${id}?type=details&id=${id}&index=${index}`);
        },
        text: "View details",
      },
      {
        condition: () => {
          if (roles?.can_read && roles?.can_update) {
            return true;
          } else {
            return false;
          }
        },
        click: () => {
          navigate(`?type=form&id=${id}&index=${index}`);
        },
        text: "Edit",
      },
    ];
  };
  return (
    <CCrud
      table={{
        list: data,
        openDeleteModal: openDeleteModal,
        setOpenDeleteModal: setOpenDeleteModal,
        rowData: rowData,
        actionsList,
        listTitle: "Documents",
        emptyListMsg: "There are no Documents",
        details: {
          navigatePath: "/documents",
          statusField: null,
          hideStatus: null,
        },
        columns: [
          { accessor: "id_data", Header: "Id" },
          { accessor: "name_data", Header: "Name" },

          { accessor: "Actions", Header: "Actions" },
        ],
        isFilter: false,
        isSearch: false,
        isChecked: false,
        isSort: false,
        total_count: count,
        setFilterObj,
        handleApplyFilterMenu: handleApplyFilterMenu,
        handleResetFilterMenu: handleResetFilterMenu,

        createBtnClass: "add-organization-button",
        className: "organization-table",
        paginationPage: paginationPage,
        setPaginationPage: setPaginationPage,
        handlePageChange,
        openDeleteModal: openDeleteModal,
        setOpenDeleteModal: setOpenDeleteModal,
        handleDeleteRequest,
      }}
      form={{
        structure: () => structure(supportedLang),
        validationSchema: () => validationSchema(supportedLang),
        initialValues: !index ? {} : initialValues(details, supportedLang),
        onSubmit: handelSubmit,
        className: {
          form: "flow-form document",
          actionButton: "flow-form-buttons",
        },
        handleLanguage: <Languages setLanguage={setSupportedLang} />,
        setSupportedLang: setSupportedLang,

        title: index ? `Edit "${details?.name}"` : "Add new Documents",
        breadcrumbs: breadcrumbs,
      }}
    />
  );
};

export default DocumentsTemplate;

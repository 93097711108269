import { Grid } from '@mui/material';
import React from 'react'
import { useState } from 'react';
import CustomPagination from '../../Atoms/Pagination/Pagination';
import { approveBranch, deleteBranch, updateBranch, updateRejectionReason } from '../../../Contexts/APIs/Branches/Branches';

import './BranchesList.css';
import RejectionModal from '../General/Models/RejectionModal';
import ActionsMenu from './ActionsMenu';

function BranchesList(props) {
    const { branchesList, count, page, handlePageChange, getBranches, filterObj, setFilterObj, roles ,setBranchesList} = props;    
    const userType = localStorage.getItem("userType");
  
    const [openRejectModel, setOpenRejectModel] = useState(false);
    const [modalType, setModalType] = useState('text');
    const [selectedOperator, setSelectedOperator] = useState(false);
    const [rejectionReason, setRejectionReason] = useState('');
    const [errorListener, setErrorListener] = useState(false);
  
      const handelRejectReason = async () => {
          if( (rejectionReason?.trim().length === 0 || rejectionReason?.length === 0) && modalType === 'form' ){                    
              setErrorListener(true);
              console.log('textarea empty');
          }else{
              if( modalType === 'form' ){
                  const { res, err } = await updateRejectionReason(selectedOperator?.user?.id,{
                      "branch": {
                          "name": selectedOperator?.user?.name,
                          "status": "rejected",
                          "rejection_reason": `${rejectionReason}`
                      }
                  })
                  if (res) {
                      setOpenRejectModel(false);
                      getBranches(filterObj);
                      setModalType('text');                
                      setErrorListener(false);
                  }
              }else{
                  setModalType('form');
                  setErrorListener(false);
              }
          }
      }
      const handelCancelReject = () => {
              setOpenRejectModel(false);
              setModalType('text');
      }
  
      const [rejectHeadName, setRejectHeadName] = useState()
      const handelOpenRejectReason = (value) => {
          setRejectHeadName(value);
          setOpenRejectModel(true);
      }

    return (
    <Grid container>
        <Grid container className="table-header mb-20px">
            <Grid item md={0.5} textAlign="center">ID</Grid>
            <Grid item sm={1.5}>Branch name</Grid>
            <Grid item sm={1.5} px={1.2}>Dive center name</Grid>
            <Grid item sm={1.5}>Manager name</Grid>
            <Grid item sm={2.5} px={1.2}>Location</Grid>
            <Grid item sm={1}>Type</Grid>
            {/* <Grid item sm={1.25}>Organization</Grid> */}
            <Grid item sm={1.25} px={1.2}>Operator status</Grid>
            <Grid item sm={1.25}>Approval Status</Grid>
            {
              !roles?.can_read && !roles?.can_update 
              ? null
              : <Grid item sm={1} sx={{ pl: 2 }}>Action</Grid>
            }        
        </Grid>
  
        {branchesList?.length === 0 && (
            <Grid container display="block" textAlign="center">
                There are no branches
            </Grid>
        )}
  
        {
          branchesList?.length >= 1 && <>
              {
                  branchesList?.map((item, index) => {
                      return (
                          <Grid
                              key={index}
                              container item
                              lg={12}
                              className="branch-item mb-20px"
                              onClick={() => {
                                  setSelectedOperator({ user: item, index: index });
                                  setRejectionReason(item.rejection_reason !== null ? item.rejection_reason : '');
                                  console.log(rejectionReason);
                              }}
                          >
                              <Grid item md={0.5} className="branch-item-no" textAlign="center">{item.id}</Grid>                            
                              <Grid item sm={1.5} className="branch-item-name">{item.name}</Grid>
                              <Grid item sm={1.5} px={1.2}>{item.dive_center_name}</Grid>
                              <Grid item sm={1.5}>{item.manager_name}</Grid>
                              <Grid item sm={2.5} px={1.2}>{item.area?.name}/{item.city?.name}/{item.country?.name}</Grid>
                              <Grid item sm={1}>{item.branch_type === 'safari_boat' ? 'safari boat' 
                                : item.branch_type === 'office' ? 'dive center'
                                : item.branch_type
                              }</Grid>
                              {/* <Grid item sm={1.25}>{item.organizations[0]?.name}</Grid> */}
                              <Grid item sm={1.25} px={1.2}>{item.dive_center_status}</Grid>
                              <Grid item sm={1.25} sx={{color: `${
                                  item.status === 'approved' ? '#00b906'
                                  : (item.status === 'pending' || item.status === 'incomplete' || item.status === 'suspended' || item.status === 'deactivated') ? '#f6b119'
                                  : item.status === 'rejected' ? '#e10007'
                                  : '#333132'
                                  }`, display: 'flex', flexDirection: 'column'}}
                              >
                              { item.status === 'pending_approval' ? 'pending approval'
                                 : item.status
                              }
                              {
                                  item.status === 'rejected' && <button
                                  style={{ background: 'transparent', textDecoration: 'underLine', border: 'none',
                                  fontSize: '16px', lineHeight: '1.88', textAlign: 'left', color: '#272726', cursor: 'pointer'
                                  }}
                                  onClick={() => handelOpenRejectReason(item.name)}> ( View reasons ) </button>
                              }
                              </Grid>
  
                            {
                                (!roles?.can_read && !roles?.can_update) 
                                ? null
                                : <Grid item sm={1} className='operator-menu-button-wrap'>
                                    <ActionsMenu
                                        user={item} 
                                        statusAttribute={'dive_center_status'} 
                                        profileStatusAttribute={'status'} 
                                        userPath={'operators/branches'}
                                        userPathLength={18}       
                                        handleApprove={ approveBranch }
                                        handleUpdate={ updateBranch }
                                        handleDelete={ deleteBranch }
                                        handleRejection={ updateRejectionReason }
                                        // getBranches={getBranches}
                                        filterObj={filterObj}
                                        setErrorListener={setErrorListener}
                                        errorListener={errorListener}
                                        roles={roles}
                                        getList={getBranches}                                        
                                        isActivitiesList={true}
                                        setList={setBranchesList}
                                    />
                                </Grid>
                            }
                          </Grid>
                      )
                  })
              }
              {count > 10 &&
                  <Grid container item justifyContent={'center'} xs={12} mb={7.5} mt={5}>
                      <CustomPagination
                          count={Math.ceil(count / 10)}
                          size='large'
                          shape="rounded"
                          page={page}
                          handleChange={handlePageChange}
                          className='pagination'
                      />
                  </Grid>
              }
          </>
        }
  
        {
          openRejectModel === true && <RejectionModal
              open={openRejectModel}
              setOpen={setOpenRejectModel}
              text={[`Rejection reason  ( ${rejectHeadName} )`, rejectionReason]}
              rejectionReason={rejectionReason}   setRejectionReason={setRejectionReason}
              errorListener={errorListener}
              buttons={['Cancel', `${ modalType === 'form' ? 'ok' : 'Edit reason' }`
              ]}
              handelRejectRequest={() => {handelRejectReason();}}
              handelCancelReject={handelCancelReject}
              modalType={modalType}
              setErrorListener={setErrorListener}
          />
        }
       
    </Grid>
    )
}

export default BranchesList
import {
  Route,
  BrowserRouter,
  Routes as Switch,
  Navigate,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
/* 
import CheckEmailPage from "./Pages/Auth/ForgotPassword/CheckEmail.page.jsx";
import ConfirmPasswordPage from "./Pages/Auth/ForgotPassword/ConfirmPassword.page.jsx";
import EmailSuccessPage from "./Pages/Auth/ForgotPassword/EmailSuccess.page.jsx";
import VerificationCodePage from "./Pages/Auth/ForgotPassword/VerificationCode.page.jsx";
 */
import LoginPage from "./Pages/Auth/Login.page.jsx";
import MainPage from "./Pages/Main/Main.Page.jsx";

function RoutesPage(props) {
  
  const isAuth = localStorage.getItem("Token");
  const accessToken = localStorage.getItem("accessToken");
  
  return (
    <BrowserRouter>
      <ToastContainer />
      <Switch>
        <Route
          path="*"
          element={isAuth ? <MainPage /> : <Navigate to="/login" />}
        />
        {!isAuth &&
          (accessToken ? (
            <>              
               {/* <Route path="/login" exact element={<LoginPage />} /> */}

            {/*<Route
                path="/change-password"
                exact
                element={<ChangePasswordPage t={t} />}
              />
              */}
            </>
          ) : (
            <>
              <Route path="/login" exact element={<LoginPage />} />
              {/* <Route exact path="/forget-password" element={<CheckEmailPage />} />
              <Route exact path="/email-success" element={<EmailSuccessPage />} />
              <Route exact path="/email-verify" element={<VerificationCodePage />} />
              <Route exact path="/confirm-password" element={<ConfirmPasswordPage />} /> */}              
            </>
          ))}
      </Switch>
    </BrowserRouter>
  );
}

export default RoutesPage;

import React, { useEffect, useState } from "react";
import Loader from "../../../Components/Atoms/Loader/Loader";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { getCountryList } from "../../../Contexts/APIs/Country/Country";
import {
  deleteProvinces,
  getProvincesList,
} from "../../../Contexts/APIs/Provinces/Provinces";
import NotificationsTemplate from "../../../Templates/Main/Notifications/Notifications.template";
import {
  getNotificationsDetails,
  getNotificationsList,
} from "../../../Contexts/APIs/Notifications/Notifications";
import { Box } from "@mui/material";
import SelectBox from "../../../Components/Atoms/Shared/Forms/main/components/CSelect";
import { getCitiessList } from "../../../Contexts/APIs/Cities/Cities";
import { getOrganizationsList } from "../../../Contexts/APIs/Organizations/Organizations";
import { getCustomerLevelsList } from "../../../Contexts/APIs/Levels/Customer/customer";
import { getCertificatesList } from "../../../Contexts/APIs/Organizations/OrganizationsCertificates";
import { getFreelancerLevelsList } from "../../../Contexts/APIs/Levels/Freelancer/freelancer";
import { notificationTypesList } from "../../../Contexts/APIs/Lookups/Notifications";
import { toast } from "react-toastify";
import usePageFilter from "../../../Components/Atoms/Hooks/usePageFilter";
import { handlePageChange } from "../../../Contexts/APIs/Helper/Helper";

function NotificationsPage() {
  const navigate = useNavigate();
  const {
    page,
    filterObj,
    handlePageChange: onPageChange, 
    setFilterObj, 
    setPage,
    searchParams,
  } = usePageFilter();
  
  const id = searchParams.get("id");
  const type = searchParams.get("type");
  
  const [openLoader, setOpenLoader] = useState(false);
  const [filterValue, setFilterValue] = useState(null);
  
  const [notificationsList, setNotificationsList] = useState([]);
  const [notificationData, setNotificationsData] = useState({});
  const [count, setCount] = useState(0);
  // let [searchParams, setSearchParams] = useSearchParams();
  // const [page, setPage] = useState(1);
  // const [filterObj, setFilterObj] = useState(null);
 // const updatePagination = (page) => {
  //   setFilterObj({ ...filterObj, page_number: page });
  // };

  // const handlePageChange = (event, value) => {
  //   setPage(value);
  //   updatePagination(value);
  // };
  // useEffect(() => {
  //   if (!pageNumber) {
  //     setPage(1);
  //     setFilterObj(prev => ({ ...prev, page_number: 1 }));
  //   } else {
  //     setPage(pageNumber);
  //     setFilterObj(prev => ({ ...prev, page_number: pageNumber }));
  //   }
  // }, [pageNumber]);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [countriesList, setCountriesList] = useState([]);
  const [citiesList, setCitiesList] = useState([]);
  const [provinicesList, setProvinicesList] = useState([]);
  const [organizationsList, setOrganizationsList] = useState([]);
  const [customerLevels, setCustomerLevels] = useState([]);
  const [freelancerLevels, setFreelancerLevels] = useState([]);

  const [bookingCountriesList, setBookingCountriesList] = useState([]);
  const [bookingCitiesList, setBookingCitiesList] = useState([]);
  const [bookingProvinicesList, setBookingProvinicesList] = useState([]);
  const [organizationsCertificateList, setOrganizationsCertificatesList] =
    useState([]);
  const [notificationTypesListing, setNotificationTypesListing] = useState([]);

  const [notificationsUsersTypes, setNotificationsUsersTypes] = useState([
    { name: "All", id: "" },
  ]);

  const [countriesCount, setCountriesCount] = useState(0);
  const [provinicesCount, setProvinicesCount] = useState(0);
  const [bookingCountriesCount, setBookingCountriesCount] = useState(0);
  const [bookingCitiesCount, setBookingCitiesCount] = useState(0);
  const [bookingProvinicesCount, setBookingProvinicesCount] = useState(0);
  const [citiesCount, setCitiesCount] = useState(0);
  const [organizationsCount, setOrganizationsCount] = useState(0);
  const [customerLevelsCount, setCustomerLevelsCount] = useState(0);
  const [freelancerLevelsCount, setFreelancerLevelsCount] = useState(0);
  const [organizationsCertificateCount, setOrganizationsCertificatesCount] =
    useState(0);

  useEffect(() => {
    notificationTypesListing.forEach((item) => {
      setNotificationsUsersTypes((prevState) => [
        ...prevState,
        {
          name: item.name.replaceAll("_", " "),
          id: item.name,
        },
      ]);
    });
  }, [notificationTypesListing]);

 

  const getNotifications = async (filterObj) => {
    setOpenLoader(true);
    const defaultFilterObj = {
      page_size: 10,
      page_number: 1,
      keyword: "",
      sort_by: "id",
    };
    const { res, error } = await getNotificationsList({
      ...defaultFilterObj,
      ...filterObj,
    });
    if (res) {
      setOpenLoader(false);
      setNotificationsList(res?.data?.notifications);
      setCount(res?.extra?.total_count);
    }
    if (error) {
      setOpenLoader(false);
    }
  };
  const getNotificationData = async () => {
    setOpenLoader(true);
    const { res, error } = await getNotificationsDetails(id);
    if (res) {
      setNotificationsData(res.data.notification);
      setOpenLoader(false);
    }
    if (error) {
      setOpenLoader(false);
    }
  };

  const handleDeleteRequest = async () => {
    const { res, err } = await deleteProvinces(selectedRow?.rowData?.id);
    if (res) {
      setOpenDeleteModal(false);
      getNotifications(filterObj);
      setFilterObj({ ...filterObj, page_number: 1 });
      setPage(1);
    }
  };

  const handleResetNotificationFormDdls = () => {
    setCountriesList([]);
    setProvinicesList([]);
    setCitiesList([]);
    setOrganizationsList([]);
    setCustomerLevels([]);
    setFreelancerLevels([]);
    setBookingCountriesList([]);
    setBookingCitiesList([]);
    setBookingProvinicesList([]);
    setOrganizationsCertificatesList([]);
  };

  useEffect(() => {
    if (filterObj) {
      getNotifications(filterObj);
      notificationTypesListing.length == 0 && getNotificationTypesList();
    }
  }, [filterObj]);
  useEffect(() => {
    id && getNotificationData({});
  }, [id]);

  const getFreelancerLevels = async (filter) => {
    // condition (freelancerLevelsCount > 10 || freelancerLevelsCount === 0) to prevent call request where total_count <= 10
    const { res, error } =
      freelancerLevelsCount > 10 || freelancerLevelsCount === 0
        ? await getFreelancerLevelsList({
            page_size: 10,
            page_number: 1,
            ...filter,
          })
        : {};
    if (res) {
      filter.page_number === 1 || filter.page_number === undefined
        ? setFreelancerLevels(res?.data?.levels)
        : setFreelancerLevels((prev) => [...prev, ...res?.data?.levels]);
      setFreelancerLevelsCount(res?.extra?.total_count);
    }
  };

  const getCountry = async (filter) => {
    setOpenLoader(true);
    const prevCountries = countriesList;
    const DataKey = filter?.keyword;
    if (
      (filter?.page_number === 1 || filter?.page_number === undefined) &&
      !DataKey &&
      countriesList.length === 10
    ) {
      setCountriesList(prevCountries);
    } else {
      const { res, error } =
        countriesCount !== 10 || DataKey
          ? await getCountryList({ page_size: 10, ...filter })
          : {};
      if (res) {
        filter?.page_number === 1 || filter?.page_number === undefined
          ? setCountriesList(res?.data?.countries)
          : setCountriesList((prev) => [...prev, ...res?.data?.countries]);
        setCountriesCount(res?.extra?.total_count);
      }
    }
    setOpenLoader(false);
  };

  const getBookingCountry = async (filter) => {
    setOpenLoader(true);
    const prevCountries = bookingCountriesList;
    const DataKey = filter?.keyword;
    if (
      (filter?.page_number === 1 || filter?.page_number === undefined) &&
      !DataKey &&
      bookingCountriesList.length === 10
    ) {
      setBookingCountriesList(prevCountries);
    } else {
      const { res, error } =
        bookingCountriesCount !== 10 || DataKey
          ? await getCountryList({ page_size: 10, ...filter })
          : {};
      if (res) {
        filter?.page_number === 1 || filter?.page_number === undefined
          ? setBookingCountriesList(res?.data?.countries)
          : setBookingCountriesList((prev) => [
              ...prev,
              ...res?.data?.countries,
            ]);
        setBookingCountriesCount(res?.extra?.total_count);
      }
    }
    setOpenLoader(false);
  };

  const getCity = async (obj) => {
    setOpenLoader(true);
    const prevCities = citiesList;
    if (
      (obj?.page_number === 1 || obj?.page_number === undefined) &&
      !obj?.keyword &&
      !obj?.city_id
    ) {
      setCitiesList(prevCities);
    } else {
      const { res, error } =
        citiesCount !== 10 || obj?.keyword
          ? await getCitiessList({ page_size: 10, ...obj })
          : {};
      if (res) {
        obj.page_number === 1 || obj.page_number === undefined
          ? setCitiesList(res?.data?.areas)
          : setCitiesList((prev) => [...prev, ...res?.data?.areas]);
        setCitiesCount(res?.extra?.total_count);
      }
    }
    setOpenLoader(false);
  };

  const getBookingCity = async (obj) => {
    setOpenLoader(true);
    const prevCities = citiesList;
    if (
      (obj?.page_number === 1 || obj?.page_number === undefined) &&
      !obj?.keyword &&
      !obj?.city_id
    ) {
      setBookingCitiesList(prevCities);
    } else {
      const { res, error } =
        citiesCount !== 10 || obj?.keyword
          ? await getCitiessList({ page_size: 10, ...obj })
          : {};
      if (res) {
        obj.page_number === 1 || obj.page_number === undefined
          ? setBookingCitiesList(res?.data?.areas)
          : setBookingCitiesList((prev) => [...prev, ...res?.data?.areas]);
        setBookingCitiesCount(res?.extra?.total_count);
      }
    }
    setOpenLoader(false);
  };

  const getProvinice = async (obj) => {
    setOpenLoader(true);
    const prevProvinces = provinicesList;
    if (
      (obj?.page_number === 1 || obj?.page_number === undefined) &&
      !obj.keyword &&
      !obj.country_id
    ) {
      setProvinicesList(prevProvinces);
    } else {
      const { res, error } =
        provinicesCount !== 10 || obj.keyword
          ? await getProvincesList({ page_size: 10, ...obj })
          : {};
      if (res) {
        obj?.page_number === 1 || obj?.page_number === undefined
          ? setProvinicesList(res?.data?.cities)
          : setProvinicesList((prev) => [...prev, ...res?.data?.cities]);
        setProvinicesCount(res?.extra?.total_count);
      }
    }
    setOpenLoader(false);
  };

  const getBookingProvinice = async (obj) => {
    setOpenLoader(true);
    const prevProvinces = bookingProvinicesList;
    if (
      (obj?.page_number === 1 || obj?.page_number === undefined) &&
      !obj.keyword &&
      !obj.country_id
    ) {
      setBookingProvinicesList(prevProvinces);
    } else {
      const { res, error } =
        provinicesCount !== 10 || obj.keyword
          ? await getProvincesList({ page_size: 10, ...obj })
          : {};
      if (res) {
        obj?.page_number === 1 || obj?.page_number === undefined
          ? setBookingProvinicesList(res?.data?.cities)
          : setBookingProvinicesList((prev) => [...prev, ...res?.data?.cities]);
        setBookingProvinicesCount(res?.extra?.total_count);
      }
    }
    setOpenLoader(false);
  };

  const getOrganizations = async (obj) => {
    setOpenLoader(true);
    const prevOrganizations = organizationsList;
    if (
      (obj?.page_number === 1 || obj?.page_number === undefined) &&
      !obj?.keyword &&
      organizationsList.length === 10
    ) {
      setOrganizationsList(prevOrganizations);
    } else {
      const { res, error } =
        organizationsCount !== 10 || obj?.keyword
          ? await getOrganizationsList({ page_size: 10, ...obj })
          : {};
      if (res) {
        obj?.page_number === 1 || obj?.page_number === undefined
          ? setOrganizationsList(res?.data?.organizations)
          : setOrganizationsList((prev) => [
              ...prev,
              ...res?.data?.organizations,
            ]);
        setOrganizationsCount(res?.extra?.total_count);
      }
    }
    setOpenLoader(false);
  };

  const getCustomerLevels = async (filter) => {
    const { res, error } =
      customerLevelsCount > 10 || customerLevelsCount === 0
        ? await getCustomerLevelsList({
            page_size: 10,
            page_number: 1,
            ...filter,
          })
        : {};
    if (res) {
      filter.page_number === 1 || filter.page_number === undefined
        ? setCustomerLevels(res?.data?.levels)
        : setCustomerLevels((prev) => [...prev, ...res?.data?.levels]);
      setCustomerLevelsCount(res?.extra?.total_count);
    }
  };

  const getCertificates = async (obj) => {
    setOpenLoader(true);
    const prevOrgsCertificates = organizationsCertificateList;
    if (
      (obj?.page_number === 1 || obj?.page_number === undefined) &&
      !obj?.keyword &&
      !obj?.organization_id
    ) {
      setOrganizationsCertificatesList(prevOrgsCertificates);
    } else {
      const { res, error } =
        organizationsCertificateCount !== 10 || obj?.keyword
          ? await getCertificatesList({ page_size: 10, ...obj })
          : {};
      if (res) {
        obj?.page_number === 1 || obj?.page_number === undefined
          ? setOrganizationsCertificatesList(
              res?.data?.organization_certificates
            )
          : setOrganizationsCertificatesList((prev) => [
              ...prev,
              ...res?.data?.organization_certificates,
            ]);
        setOrganizationsCertificatesCount(res?.extra?.total_count);
      }
    }
    setOpenLoader(false);
  };

  const getNotificationTypesList = async () => {
    const { res, error } = await notificationTypesList();
    res && setNotificationTypesListing(res.data.notification_types);
    error && toast.error(error);
  };

  const rowData = (data) => {
    setSelectedRow(data);
  };

  const headerChildren = () => {
    return (
      <Box
        width="200px"
        style={{
          position: "relative",
          zIndex: "101",
          textTransform: "capitalize",
        }}
      >
        <SelectBox
          options={notificationsUsersTypes}
          defaultValue={notificationsUsersTypes[0]}
          changeAction={(value) => {
            onPageChange && handlePageChange(1, onPageChange); // NOTE
            setFilterValue(value);
            setFilterObj({ ...filterObj, notification_type: value?.value });
          }}
          value={filterValue}
          label="Notification type"
          onClear={() => {
            onPageChange && handlePageChange(1, onPageChange);
            setFilterValue(null);
            setFilterObj({ ...filterObj, notification_type: null });
          }}
        />
      </Box>
    );
  };

  const breadcrumbs = [
    { active: true, path: "/notifications", title: "Notifications" },
    { active: false, title: "Add new notification" },
  ];

  const mappedData = notificationsList?.map((data) => {
    return {
      ...data,
      id_data: <div>{data?.id}</div>,
    };
  });
  return (
    <>
      <Loader open={openLoader} />
      <NotificationsTemplate
        selectedRow={selectedRow}
        filterObj={filterObj}
        setFilterObj={setFilterObj}
        data={mappedData}
        count={count}
        paginationPage={page}
        setPaginationPage={setPage}
        handleChange={onPageChange}
        openDeleteModal={openDeleteModal}
        setOpenDeleteModal={setOpenDeleteModal}
        handleDeleteRequest={handleDeleteRequest}
        getCountry={getCountry}
        countriesList={countriesList}
        countriesCount={countriesCount}
        notificationsUsersTypes={notificationsUsersTypes}
        getCity={getCity}
        citiesList={citiesList}
        setCitiesList={setCitiesList}
        citiesCount={citiesCount}
        getProvinice={getProvinice}
        provinicesList={provinicesList}
        setProvinicesList={setProvinicesList}
        provinicesCount={provinicesCount}
        getOrganizations={getOrganizations}
        organizationsList={organizationsList}
        organizationsCount={organizationsCount}
        getCustomerLevels={getCustomerLevels}
        customerLevels={customerLevels}
        customerLevelsCount={customerLevelsCount}
        rowData={rowData}
        notificationData={notificationData}
        getCertificates={getCertificates}
        organizationsCertificateList={organizationsCertificateList}
        setOrganizationsCertificatesList={setOrganizationsCertificatesList}
        organizationsCertificateCount={organizationsCertificateCount}
        headerChildren={headerChildren()}
        breadcrumbs={breadcrumbs}
        getFreelancerLevels={getFreelancerLevels}
        freelancerLevels={freelancerLevels}
        freelancerLevelsCount={freelancerLevelsCount}
        getBookingCountry={getBookingCountry}
        bookingCountriesList={bookingCountriesList}
        bookingCountriesCount={bookingCountriesCount}
        getBookingProvinice={getBookingProvinice}
        bookingProvinicesList={bookingProvinicesList}
        setBookingProvinicesList={setBookingProvinicesList}
        bookingProvinicesCount={bookingProvinicesCount}
        getBookingCity={getBookingCity}
        bookingCitiesList={bookingCitiesList}
        setBookingCitiesList={setBookingCitiesList}
        bookingCitiesCount={bookingCitiesCount}
        handleResetNotificationFormDdls={handleResetNotificationFormDdls}
      />
    </>
  );
}

export default NotificationsPage;

import axios from "axios";
import { axiosFn } from "./handel_api";
import handleError from "../utils/handel_error";
import { BaseURL, PresignedURL } from "../utils/constant";

export async function getPresignedLinkAPI(files) {
  return await axios
    .post(`${BaseURL}${PresignedURL}`, { files: files })
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}

export async function uploadFileAPI(presigned_url, file) {
  const config = {
    header: {
      "Content-Type": file.type,
    },
  };
  return await axios
    .put(presigned_url, file, config)
    .then((res) => {
      if (res) {
        return res;
      }
    })
    .catch(handleError);
}

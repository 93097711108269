import React from 'react'
import OrganizationDetailsTemplate from '../../../Templates/Main/Organizations/OrganizationDetails.template';
import Loader from '../../../Components/Atoms/Loader/Loader';
import { deleteOrganization, getOrganizationDetails } from '../../../Contexts/APIs/Organizations/Organizations';
import { useNavigate, useParams } from 'react-router-dom';
import { useState } from 'react';
import { useEffect } from 'react';
import { getSupportedLanguages } from '../../../Contexts/APIs/Lookups/SupportedLanguages';
import { toast } from 'react-toastify';

function OrganizationDetailsPage({roles}) {
    const { organizationId } = useParams();    
    const navigate = useNavigate();
    const [organizationData, setOrganizationData] = useState({});
    const [openLoader, setOpenLoader] = useState(false);
    const [supportedLanguages, setSupportedLanguages] = useState([]);
    const [supportLang, setSupportLang] = useState("en");
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
            
    const getOrganization = async (id) => {
        setOpenLoader(true);
        const { res, err } = await getOrganizationDetails(id);
        if (res) { 
            setOrganizationData(res?.data?.organization); 
        }
        if (err) {
            toast.error(err)
        }
        setOpenLoader(false);        
    };

    const getLanguages = async () => {
        const { res, err } = await getSupportedLanguages();
        if (res) {
        setSupportedLanguages(res?.data?.supported_locales);
        }
    };
    const updateSupportedLang = (e, languages) => {
        setSupportLang(e.target.value);
    };

    const handleDeleteRequest = async () => {
        const {res, err} = await deleteOrganization(organizationId);
        if(res){
            setOpenDeleteModal(false);
            navigate('/organizations');
        }
    };

    useEffect(() => {
        getOrganization(organizationId);
        getLanguages();
    }, []);

  return (
    <>
        <Loader open={openLoader} />
        <OrganizationDetailsTemplate
            organizationId={organizationId}
            organizationData={organizationData}
            supportedLanguages={supportedLanguages}
            updateSupportedLang={updateSupportedLang}
            supportLang={supportLang}
            openDeleteModal={openDeleteModal} setOpenDeleteModal={setOpenDeleteModal} 
            handleDeleteRequest={handleDeleteRequest}
            handleCancelDelete={() => setOpenDeleteModal(false)}
            roles={roles}
        />
    </>
  )
}

export default OrganizationDetailsPage
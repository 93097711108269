import React, { useEffect, useState } from "react";
import Loader from "../../../../Components/Atoms/Loader/Loader";
import FluencyLanguageTemplate from "../../../../Templates/Main/PredefinedLookups/FluencyLanguage/FluencyLanguage.template";
import {
  deleteLanguageFluency,
  getLanguageFluencyDetails,
  getLanguageFluencysList,
} from "../../../../Contexts/APIs/Fluency Level/FluencyLevel";
import moment from "moment";
import usePageFilter from "../../../../Components/Atoms/Hooks/usePageFilter";

function FluencyLanguagePage({roles}) {
  // let [searchParams, setSearchParams] = useSearchParams();
  const {
    page,
    filterObj,
    handlePageChange: onPageChange,
    setFilterObj,
    setPage,
    searchParams,
  } = usePageFilter();

  const id = searchParams.get("id");

  const [openLoader, setOpenLoader] = useState(false);
  const [languagesList, setLanguagesList] = useState([]);
  const [languageFluencyData, setLanguageFluencyData] = useState({});

  const [count, setCount] = useState(0);
  // const [page, setPage] = useState(1);

  // const [filterObj, setFilterObj] = useState(null);

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});

  // const updatePagination = (page) => {
  //   setFilterObj({ ...filterObj, page_number: page });
  // };

  // const handlePageChange = (event, value) => {
  //   setPage(value);
  //   updatePagination(value);
  // };

  const getLanguages = async (filterObj) => {
    setOpenLoader(true);
    const defaultFilterObj = {
      page_size: 10,
      page_number: 1,
      keyword: "",
      sort_by: "id",
    };
    const { res, error } = await getLanguageFluencysList({
      ...defaultFilterObj,
      ...filterObj,
    });
    if (res) {
      setOpenLoader(false);
      setLanguagesList(res?.data?.language_fluency_levels);
      setCount(res?.extra?.total_count);
    }
    if (error) {
      setOpenLoader(false);
    }
  };
  const getLanguageFluencyData = async () => {
    setOpenLoader(true);
    const { res, error } = await getLanguageFluencyDetails(id);
    if (res) {
      setLanguageFluencyData(res.data.language_fluency_level);
      setOpenLoader(false);
    }
    if (error) {
      setOpenLoader(false);
    }
  };
  // mappedData use for create custom cell to concat multi values or navigate button
  const mappedData = languagesList?.map((data) => {
    return {
      ...data,
      created_at_data: moment(data?.created_at).format("DD-MM-YYYY  HH:mm"),
      updated_at_data: moment(data?.updated_at).format("DD-MM-YYYY  HH:mm"),
    };
  });

  /*const handleDeleteRequest = async () => {
    const action = () => {
      const newList = languagesList;
      const filterdData = newList?.filter(
        (data) => data?.id != selectedRow?.id
      );
      setLanguagesList(filterdData);
      setOpenDeleteModal(false);
      if (filterdData?.length == 0) {
        setFilterObj({ ...filterObj, page_number: 1 });
        setPage(1);
      }
    };
    const { res, err } = await updateLanguageFluency({
      id: selectedRow?.id,
      data: { language_fluency_level: { is_deleted: true } },
      action,
    });
  };*/
  const handleDeleteRequest = async () => {
    const action = () => {
      setOpenDeleteModal(false);  
      setFilterObj({ ...filterObj, page_number: 1 });
      setPage(1);      
    };
    deleteLanguageFluency(selectedRow?.id, action);
  };

  useEffect(() => {
    if (filterObj) getLanguages(filterObj);
  }, [filterObj]);

  useEffect(() => {
    id && getLanguageFluencyData({});
  }, [id]);

  const rowData = (data) => {
    setSelectedRow(data?.rowData);
  };

  const breadcrumbs = [
    {
      active: true,
      path: "/predefined-lookups/fluency-languages",
      title: "Languages",
    },
    {
      active: false,
      title: id
        ? "Edit language fluency level"
        : "Add new language fluency level",
    },
  ];

  return (
    <>
      <Loader open={openLoader} />
      <FluencyLanguageTemplate
        filterObj={filterObj}
        setFilterObj={setFilterObj}
        data={mappedData}
        count={count}
        paginationPage={page}
        setPaginationPage={setPage}
        handlePageChange={onPageChange}
        openDeleteModal={openDeleteModal}
        setOpenDeleteModal={setOpenDeleteModal}
        handleDeleteRequest={handleDeleteRequest}
        rowData={rowData}
        details={languageFluencyData}
        breadcrumbs={breadcrumbs}
        roles={roles}
      />
    </>
  );
}

export default FluencyLanguagePage;

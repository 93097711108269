import { Box, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomSeparator from "../../Atoms/BreadCrumb";
import { useLocation } from "react-router-dom";
import FormHead from "../../Atoms/FormHead/FormHead";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import CheckIcon from "@mui/icons-material/Check";
import Carousel from "../../Atoms/Carousel/Carousel";
import ApplicantDetailsHeader from "../../Molecules/JobOffers/ApplicantDetailsHeader/ApplicantDetailsHeader";
import "./ApplicantDetails.css";
import FormHeadIcon from "../../Atoms/FormHead/FormHeadIcon";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import moment from "moment";
import PhoneInput from "react-phone-input-2";

function ApplicantDetails(props) {
  const { applicantData, jobOfferId } = props;
  const [countryCode, setCountryCode] = useState(null);

  const created = applicantData?.created_at ? moment(applicantData?.created_at).format("DD-MM-YYYY HH:mm") : 'N/A',
    updated = applicantData?.updated_at ? moment(applicantData?.updated_at).format("DD-MM-YYYY HH:mm") : 'N/A';  

  const info = [
    {
      title: "Diving insurance no",
      val: applicantData?.diving_insurance_number,
    },
    {
      title: "Local government license no",
      val: applicantData?.local_gov_license_number,
    }
  ];
  const infoBoolean = [
    {
      title: "Liveaboard work experience",
      status: applicantData?.live_aboard_work_experience ? true : false,
    },
    {
      title: "Volunteer work",
      status: applicantData?.volunteer_work_participant,
    },
    { title: "is verified", status: applicantData?.is_verified ? true : false }
  ];

  const certificates = applicantData?.certificates;

  const locationsResponsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const certificatesResponsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const location = useLocation();

  const handleDigits = (number) => {
    return number.toString().length === 1 ? "0" + number : number;
  };
  const breadcrumbs = [
    { path: "/operators/job-offers", active: true, title: "Job offers" },
    {
      // path: `/freelancers/job-offers/${jobOfferId}`,
      path: `/operators/job-offers/${jobOfferId}`,
      active: true,
      title: location?.state?.title + "details",
    },
    {
      active: false,
      title: applicantData?.name + "details",
    },
  ];
  
  useEffect(()=>{
    setTimeout(() => {
      setCountryCode(applicantData.country_code);      
    }, 1000);
  },[applicantData])

  return (
    <Stack gap={3}>
      <Grid container item sm={12}>
        <CustomSeparator breadcrumbs={breadcrumbs} />
      </Grid>

      <ApplicantDetailsHeader
        data={applicantData}
        JobOffersStatus={location?.state?.status}
        link={`/freelancers/${applicantData?.id}/reviews`}

      />

      <Stack
        mt="-25px"
        bgcoloe="white"
        padding="53.8px"
        className="details-panel"
        flexDirection="row"
        justifyContent="space-between"
        gap="1rem"
        boxShadow="0 2px 15px 0 rgba(0, 0, 0, 0.1)"
        overflow="auto"
      >
        <Stack gap={2} width="70%">
          <Stack bgcolor="rgba(0, 0, 0, 0.01)" borderRadius="10px" p={2}>
            <FormHead text={`${applicantData?.name} info`} />
            <Box position="relative">
              <Grid container gap={1}>
                <Grid item md={5}>
                  <Stack flexDirection="column" gap={1}>
                    <Typography fontSize="13px" color="#aaa">
                      Id number
                    </Typography>
                    <Typography className="details-text-bold">
                      {applicantData?.id}
                    </Typography>
                  </Stack>
                  <Stack flexDirection="column" gap={1}>
                    <Typography fontSize="13px" color="#aaa">
                      Freelancer Mobile
                    </Typography>
                    <Typography className="details-text-bold">
                      {applicantData?.phone_number ? <PhoneInput
                        inputStyle={{
                            border: "none",
                            width: "100%",
                            padding: "0px",
                            direction: "ltr",
                            fontSize: '18px',                      
                            color: '#282828',
                            backgroundColor: '#f9f9f9',
                            wordBreak: 'break-word',
                            fontWeight: 'bold'
                      }}
                        defaultMask={`${countryCode === "" ? '...........' : '... ... ....'}`}
                        value={`${countryCode === "" ? '  ' : applicantData.country_code}${applicantData.phone_number}`}
                        disabled={true}
                        disableDropdown={true}
                        specialLabel=""
                        placeholder=""
                        disableCountryCode={countryCode === "" ? true : false}
                      /> : "N/A"}
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item md={6}>
                  <Stack flexDirection="column" gap={1}>
                    <Typography fontSize="13px" color="#aaa">
                      Freelancer Email
                    </Typography>
                    <Typography className="details-text-bold">
                      {applicantData?.email}
                    </Typography>
                  </Stack>
                </Grid>
              </Grid>
            </Box>
          </Stack>
          <Stack bgcolor="rgba(0, 0, 0, 0.01)" borderRadius="10px" p={2} className="applicant-carousel">
            <FormHead text={"Work locations"} />
            {applicantData?.work_locations?.length > 1 ? (
              <Carousel
                swipeable={true}
                draggable={true}
                showDots={false}
                responsive={locationsResponsive}
                autoPlaySpeed={1000}
                containerClass={"carousel-location"}
                itemClass={"carousel-location-item"}
                partialVisible={true}
                arrows={false}
              >
                {applicantData?.work_locations?.map((data, index) => {
                  return (
                    <Stack
                      key={index}
                      flexDirection="row"
                      alignItems="center"
                      gap={2}
                      width="208px"
                    >
                      <Box position="relative" width="100%" height="100%">
                        <img
                          src={data?.area?.logo}
                          width="100%"
                          height="100%"
                          style={{ borderRadius: "15px" }}
                        />
                        <Stack
                          alignItems="center"
                          justifyContent="center"
                          width="100%"
                          position="absolute"
                          bottom="20px"
                        >
                          <Typography
                            fontSize="20px"
                            letterSpacing="2px"
                            color="white"
                            fontWeight="bold"
                            sx={{ textShadow: "0 2px 5px #00000033" }}
                          >
                            {data?.area?.city?.name}
                          </Typography>
                        </Stack>
                      </Box>
                    </Stack>
                  );
                })}
              </Carousel>
            ) : applicantData?.work_locations?.length == 1 ? (
              <Stack flexDirection="row" alignItems="center" gap={2}>
                <Box position="relative">
                  <img
                    src={applicantData?.work_locations[0].area?.logo}
                    width="208px"
                    height="149px"
                    style={{ borderRadius: "15px" }}
                  />
                  <Stack
                    alignItems="center"
                    justifyContent="center"
                    width="208px"
                    position="absolute"
                    bottom="20px"
                  >
                    <Typography
                      fontSize="20px"
                      letterSpacing="2px"
                      color="white"
                      fontWeight="bold"
                      sx={{ textShadow: "0 2px 5px #00000033" }}
                    >
                      {applicantData?.work_locations[0].area?.city?.name}
                    </Typography>
                  </Stack>
                </Box>
                <Typography
                  fontSize="18px"
                  letterSpacing="2px"
                  color="#333132"
                  fontWeight="bold"
                >
                  {applicantData?.work_locations[0].area?.name} / {applicantData?.work_locations[0].area?.city?.name} /{" "}
                  {applicantData?.work_locations[0].area?.city?.country?.name}
                </Typography>
              </Stack>
            ) : null}
          </Stack>

          <Stack bgcolor="rgba(0, 0, 0, 0.01)" borderRadius="10px" p={2} className="applicant-carousel">
            <FormHead text={"Work certificates"} />
            {certificates?.length > 1 ? (
              <Carousel
                swipeable={true}
                draggable={true}
                showDots={false}
                responsive={certificatesResponsive}
                autoPlaySpeed={1000}
                containerClass={"carousel-certificate"}
                itemClass={"carousel-certificate-item"}
                partialVisible={true}
                arrows={false}
              >
                {certificates.map((data, index) => {
                  return (
                    <Stack
                      key={index}
                      alignItems="center"
                      justifyContent="center"
                      borderRadius="10px"
                      textAlign="center"
                    >
                      <img
                        src={data?.image}
                        width="100%"
                        height="124px"
                        style={{ borderRadius: "15px" }}
                      />
                      <Typography
                        color="#333132"
                        fontSize="14px"
                        fontWeight="bold"
                        px={2}
                        mt="5px"
                      >
                        {data?.organization_certificate?.name}
                      </Typography>
                      <Typography color="#bebebe" fontSize="14px" px={2}>
                        3rd level diver
                      </Typography>
                    </Stack>
                  );
                })}
              </Carousel>
            ) : certificates?.length === 1 ? (
              <Stack flexDirection="row" gap={1}>
                <Stack
                  alignItems="center"
                  justifyContent="center"
                  borderRadius="10px"
                >
                  <img
                    src={certificates[0]?.image}
                    width="178px"
                    height="124px"
                    style={{ borderRadius: "15px" }}
                  />
                  <Typography
                    color="#333132"
                    fontSize="14px"
                    fontWeight="bold"
                    mt="5px"
                  >
                    {certificates[0]?.organization_certificate?.name}
                  </Typography>
                  <Typography color="#bebebe" fontSize="14px">
                    3rd level diver
                  </Typography>
                </Stack>
              </Stack>
            ) : null}
          </Stack>

          <Stack bgcolor="rgba(0, 0, 0, 0.01)" borderRadius="10px" p={2}>
            {applicantData?.volunteer_work_participant ? (
              <Stack>
                <FormHeadIcon text={"Volunteer Work"}>
                  <CheckOutlinedIcon
                    fontSize="1.1rem"
                    style={{ color: "#f6b119" }}
                  />
                </FormHeadIcon>
                <Typography className="details-text-bold">
                  have volunteer work experience
                </Typography>
              </Stack>
            ) : (
              <Stack>
                <FormHeadIcon text={"Volunteer Work"}>
                  <CloseOutlinedIcon
                    fontSize="1.1rem"
                    style={{ color: "#e10007" }}
                  />
                </FormHeadIcon>
                <Typography className="details-text-bold" color="#e10007">
                  No volunteer work experience
                </Typography>
              </Stack>
            )}
          </Stack>

          <Stack
            flexDirection="row"
            gap="1rem 3rem"
            padding="1rem 0rem 2rem"
            className="details-time details-time-lg"
          >
            <Stack flexDirection='row' gap={1}>
                <Typography color='#333132' className='details-text-bold'>{`Created at : ${created}`}</Typography>                    
            </Stack>
            <Stack flexDirection='row' gap={1}>
                <Typography color='#333132' className='details-text-bold'>{`Updated at : ${updated}`}</Typography>                    
            </Stack>

          </Stack>
        </Stack>

        <Stack gap={2} width="30%">
          <Stack bgcolor="rgba(0, 0, 0, 0.01)" borderRadius="10px" p={2}>
            <FormHead text={"Languages"} />
            <Grid container gap={1} flexWrap="wrap">
              <Grid item xs={12} lg={5.5}>
                {applicantData?.languages?.length > 4 ? (
                  <>
                    {applicantData?.languages?.map((lang, index) => {
                      return (
                        index <= 3 && (
                          <Typography
                            textTransform="capitalize"
                            display="flex"
                            alignItems="center"
                            flexWrap="wrap"
                            mb={1}
                            className="details-text-bold"
                            key={index}
                          >
                            {lang?.language?.name}
                            <Typography
                              color="#bebebe"
                              width="fit-content"
                              maxWidth="50%"
                              ml="5px"
                              className="details-text-bold"
                            >{`: ${lang?.language_fluency_level?.name}`}</Typography>
                          </Typography>
                        )
                      );
                    })}
                  </>
                ) : (
                  <>
                    {applicantData?.languages?.map((lang, index) => {
                      return (
                        <Typography
                          textTransform="capitalize"
                          display="flex"
                          alignItems="center"
                          flexWrap="wrap"
                          mb={1}
                          className="details-text-bold"
                          key={index}
                        >
                          {lang?.language?.name}
                          <Typography
                            color="#bebebe"
                            width="fit-content"
                            maxWidth="50%"
                            ml="5px"
                            className="details-text-bold"
                          >{`: ${lang?.language_fluency_level?.name}`}</Typography>
                        </Typography>
                      );
                    })}
                  </>
                )}
              </Grid>
              {applicantData?.languages?.length > 4 && (
                <Grid item xs={12} lg={5.5}>
                  {applicantData?.languages?.map((lang, index) => {
                    return (
                      index > 3 && (
                        <Typography
                          textTransform="capitalize"
                          display="flex"
                          alignItems="center"
                          flexWrap="wrap"
                          mb={1}
                          className="details-text-bold"
                          key={index}
                        >
                          {lang?.language?.name}
                          <Typography
                            color="#bebebe"
                            width="fit-content"
                            maxWidth="50%"
                            ml="5px"
                            className="details-text-bold"
                          >{`: ${lang?.language_fluency_level?.name}`}</Typography>
                        </Typography>
                      )
                    );
                  })}
                </Grid>
              )}
            </Grid>
          </Stack>

          <Stack bgcolor="rgba(0, 0, 0, 0.01)" borderRadius="10px" p={2}>
            <FormHead text={"Information"} />
            {info.map((inf, index) => {
              return (
                <Stack flexDirection="row" gap={1} mb={2}>
                  <Typography color="#333132" className="details-text">
                    {inf.title}
                  </Typography>
                  <Typography
                    color={
                      inf.title === "Technical certificate"
                        ? "#bebebe"
                        : "#333132"
                    }
                    className="details-text"
                  >
                    {inf.val}
                  </Typography>
                </Stack>
              );
            })}
            {infoBoolean?.map((data, index) => {
              return (
                <Stack
                  flexDirection="row"
                  gap={1}
                  my={1}
                  key={index}
                  className="details-info"
                >
                  {data?.status ? (
                    <CheckIcon fontSize="1.1rem" style={{ color: "#f6b119" }} />
                  ) : (
                    <CloseOutlinedIcon
                      fontSize="1.1rem"
                      style={{ color: "#e10007" }}
                    />
                  )}
                  <Typography className="details-text">
                    {data?.title}
                  </Typography>
                </Stack>
              );
            })}
          </Stack>

          <Stack
            flexDirection="row"
            gap="1rem 3rem"
            padding="1rem 0rem 2rem"
            className="details-time details-time-sm"
          >
            <Stack flexDirection='row' gap={1}>
                <Typography color='#333132' className='details-text-bold'>{`Created at : ${created}`}</Typography>                    
            </Stack>
            <Stack flexDirection='row' gap={1}>
                <Typography color='#333132' className='details-text-bold'>{`Updated at : ${updated}`}</Typography>                    
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}

export default ApplicantDetails;

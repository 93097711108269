import React, { useEffect, useState } from "react";
import Loader from "../../../../Components/Atoms/Loader/Loader";
import SocialMediaTemplate from "../../../../Templates/Main/PredefinedLookups/SocialMedia/SocialMedia.template";
import {
  deleteSocialMedia,
  getSocialMediaDetails,
  getSocialMediaList,
} from "../../../../Contexts/APIs/SocialMedia/SocialMedia";
import { Box } from "@mui/material";
import usePageFilter from "../../../../Components/Atoms/Hooks/usePageFilter";

function SocialMediaPage({ roles }) {
  // let [searchParams, setSearchParams] = useSearchParams();
  const {
    page,
    filterObj,
    handlePageChange: onPageChange,
    setFilterObj,
    setPage,
    searchParams,
  } = usePageFilter();

  const id = searchParams.get("id");

  const [openLoader, setOpenLoader] = useState(false);
  const [socialMediaList, setSocialMediaList] = useState([]);
  const [socialMediaDataData, setSocialMediaData] = useState({});

  const [count, setCount] = useState(0);
  // const [page, setPage] = useState(1);

  // const [filterObj, setFilterObj] = useState(null);

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});

  // const updatePagination = (page) => {
  //   setFilterObj({ ...filterObj, page_number: page });
  // };

  // const handlePageChange = (event, value) => {
  //   setPage(value);
  //   updatePagination(value);
  // };

  const getSocialMedia = async (filterObj) => {
    setOpenLoader(true);
    const defaultFilterObj = {
      page_size: 10,
      page_number: 1,
      keyword: "",
      sort_by: "id",
    };
    const { res, error } = await getSocialMediaList({
      ...defaultFilterObj,
      ...filterObj,
    });
    if (res) {
      setOpenLoader(false);
      setSocialMediaList(res?.data?.social_media);
      setCount(res?.extra?.total_count);
    }
    if (error) {
      setOpenLoader(false);
    }
  };
  const getSocialMediaData = async () => {
    setOpenLoader(true);
    const { res, error } = await getSocialMediaDetails(id);
    if (res) {
      setSocialMediaData(res.data.social_medium);
      setOpenLoader(false);
    }
    if (error) {
      setOpenLoader(false);
    }
  };
  // mappedData use for create custom cell to concat multi values or navigate button
  const mappedData = socialMediaList?.map((data) => {
    return {
      ...data,
      name_data: <Box width="500px">{data?.name}</Box>,
    };
  });

  const handleDeleteRequest = async () => {
    const action = (data) => {
      setOpenDeleteModal(false);
      setSocialMediaList((prevState) =>
        prevState?.filter((ele) => ele?.id !== data?.data?.social_medium?.id)
      );
    };
    deleteSocialMedia(selectedRow?.id, action);
  };

  useEffect(() => {
    if (filterObj) getSocialMedia(filterObj);
  }, [filterObj]);

  useEffect(() => {
    id && getSocialMediaData({});
  }, [id]);

  const rowData = (data) => {
    setSelectedRow(data?.rowData);
  };
  const breadcrumbs = [
    {
      active: true,
      path: "/predefined-lookups/social-media",
      title: "Socail media",
    },
    { active: false, title: "Add new social media" },
  ];
  return (
    <>
      <Loader open={openLoader} />
      <SocialMediaTemplate
        filterObj={filterObj}
        setFilterObj={setFilterObj}
        data={mappedData}
        count={count}
        paginationPage={page}
        setPaginationPage={setPage}
        handlePageChange={onPageChange}
        openDeleteModal={openDeleteModal}
        setOpenDeleteModal={setOpenDeleteModal}
        handleDeleteRequest={handleDeleteRequest}
        rowData={rowData}
        details={socialMediaDataData}
        breadcrumbs={breadcrumbs}
        roles={roles}
      />
    </>
  );
}

export default SocialMediaPage;

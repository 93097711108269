import { Avatar, Grid, Stack, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import "./Layout.css";
import Notifications from "./Notifications.jsx";

const Navbar = (props) => {
  const { profileData } = props;

  return (
    <Grid
      container item
      className="navbar-container"
      xs={12}
      sx={{ position: 'sticky', top: '0px', zIndex: '999' }}
    >
      <Stack style={{ padding: "0px 20px" }} width='100%' flexDirection='row' alignItems='center' justifyContent='flex-end' gap={1} >
        <Grid container gap={1} style={{ padding: "10px 0", alignItems: 'center' }} width='fit-content'>
          <Link to='/profile'>
            <Avatar src={profileData?.logo || profileData?.avatar} width='50px' height='50px' alt="avatar" />
          </Link>
          <Grid >
            <Link style={{ textDecoration: 'none' }} to='/profile'>
              <Typography component="h1" style={{ fontSize: "18px", fontWeight: 600, color: "#333132" }}>
                {/* {profileData?.owner_name || profileData?.name} */}
              </Typography>
              <Typography component="p" style={{ fontSize: "14px", color: "#aaaaaa" }}>
                {profileData?.name ? profileData?.name : 'Super admin'}
              </Typography>
            </Link>
          </Grid>
        </Grid>
        <Grid >
          <Notifications />
        </Grid>
      </Stack>
    </Grid>
  )
}

export default Navbar
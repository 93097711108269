import { Box, Grid, IconButton, Menu, MenuItem } from "@mui/material";
import { useState } from "react";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import "./FilterMenu.css";
import { handlePageChange } from "../../../../../Contexts/APIs/Helper/Helper";

const FilterMenu = ({ head, dataList, handleFilter, handleChange }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedData, setSelectedData] = useState(null);

  const open = Boolean(anchorEl);

  const handleClick = (event) => setAnchorEl(event.currentTarget);

  const handleClose = () => setAnchorEl(null);

  const statusColors = {
    approved: "#00b906",
    pending: "#f6b119",
    incompleted: "#f6b119",
    rejected: "#e10007",
  };
  return (
    <>
      <IconButton
        id={`demo-positioned-btn${head}`}
        aria-controls={open ? `demo-positioned-menu${head}` : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        className="filter-menu-button"
      >
        {selectedData ? selectedData : head}
        <KeyboardArrowDownRoundedIcon style={{ color: "black" }} />
      </IconButton>
      <Menu
        id={`demo-positioned-menu${head}`}
        aria-labelledby={`demo-positioned-btn${head}`}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        className="filter-menu-list"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        {dataList.map((item, index) => {
          return (
            <MenuItem
              key={index}
              sx={{
                color: statusColors[item] || "#818181",
              }}
              onClick={() => {
                handleFilter(item);
                handleClose();
                setSelectedData(item);
                handleChange && handlePageChange(1, handleChange);
              }}
            >
              {item}
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};

export default FilterMenu;

import React from "react";
import OperatorDetails from "../../../Components/Organisms/Operators/OperatorDetails";

function OperatorDetailsTemplate({ operatorData, getOperator, roles }) {
  return (
    <OperatorDetails operatorData={operatorData} getOperator={getOperator} roles={roles} />
  );
}

export default OperatorDetailsTemplate;

import React, { useState } from 'react'
import { Rating, Stack, Typography } from '@mui/material'
import { styled } from '@mui/material/styles';
import ActionsMenu from '../ActionsMenu';
import { approveBranch, deleteBranch, updateBranch, updateRejectionReason } from '../../../../Contexts/APIs/Branches/Branches';
import MailOutlineOutlinedIcon from '@mui/icons-material/MailOutlineOutlined';

function BranchDetailsHeader({data, getBranch, branchType, roles}) {
    const StyledRating = styled(Rating)({
        '& .MuiRating-iconFilled': {
            color: '#3ed181',
        },
        '& .MuiRating-iconHover': {
            color: '#3ed181',
        },
    });
    // const handleDeleteFreelancer = async (id) => {
    //     // setLoading(true)
    //     const { res, err } = await deleteFreelancer(id)
    //     // if (res) setData(prevData => prevData.filter(data => data.id != id))
    //     if (err) toast.error(err)
    //     // setLoading(false)
    // }
    const [errorListener, setErrorListener] = useState(false);
  return (
    <Stack alignItems='center' bgcolor='white' boxShadow='0 2px 15px 0 rgba(0, 0, 0, 0.1)' 
        borderRadius='10px' padding={2} flexDirection='row' justifyContent='space-between'>
        <Stack flexDirection='row' gap={2} width='100%'>
            <Stack flexDirection='row' justifyContent='space-between' width='100%' className='details-header-content'>
                <Stack flexDirection='column' justifyContent='space-evenly'>
                    <Stack flexDirection='row' gap={2} alignItems='center'>
                        <Stack flexDirection='row' gap={'60px'} alignItems='center'>
                            <Typography fontSize='30px' fontWeight='bold' color='#333132' textTransform='capitalize'>{data?.name}</Typography>
                            <Typography fontSize='18px' color='#bebebe' textTransform='capitalize'>{data?.dive_center_name}</Typography>
                        </Stack>
                    </Stack>
                    <Stack flexDirection='row' gap={'100px'} alignItems='center'>
                        <Typography fontSize='20px' fontWeight='500' color='#333132'>Branch ID : {data?.id}</Typography>
                        <Stack flexDirection='row' gap={1} my={1} alignItems={'center'}  p={'2px 10px'} borderRadius={'10px'} border='solid 1px #d9d9d9'>
                            <MailOutlineOutlinedIcon fontSize='18px' style={{ color: '#333132' }} />
                            <Typography fontSize='16px' fontWeight='500' color='#333132'>{data?.email}</Typography>                            
                        </Stack>
                    </Stack>
                </Stack>
                <Stack flexDirection='row' gap={2}>
                    <Stack flexDirection='column' py='4px' justifyContent='space-between'>
                        <Typography fontSize='16px' fontWeight='bold' color='#333132' display='block' textTransform='capitalize'>Account Status : {data?.dive_center_status}</Typography>
                        <Stack flexDirection='row' gap={'4px'} alignItems='center'>
                            <Typography fontSize='16px' fontWeight='bold' color='#333132'>Approval Status :</Typography>                            
                            <Typography sx={{textTransform: 'capitalize', color: `${
                                data.status === 'approved' ? '#00b906'
                                : (data.status === 'pending_approval' || data.status === 'incomplete' || data.status === 'pending' || data.status === 'suspended') ? '#f6b119'
                                : data.status === 'rejected' ? '#e10007'
                                : '#333132'
                                }`, fontSize:'16px', fontWeight:'bold'}}
                            >{ data.status === 'pending_approval' ? 'pending approval'
                                : data.status
                            }</Typography>
                        </Stack>
                    </Stack>
                    {
                        (!roles?.can_read && !roles?.can_update) 
                        ? null
                        : <Stack flexDirection='row' justifyContent='center' alignItems='start'>
                            <ActionsMenu
                                user={data} 
                                statusAttribute={'status'} 
                                profileStatusAttribute={'status'} 
                                userPath={'operators/branches'}
                                userPathLength={19} 
                                handleApprove={ approveBranch }
                                handleUpdate={ updateBranch }
                                handleDelete={ deleteBranch }
                                handleRejection={ updateRejectionReason }
                                getBranch={getBranch}
                                branchCount={'single'}
                                // filterObj={filterObj}
                                setErrorListener={setErrorListener}
                                errorListener={errorListener}
                                roles={roles}
                            />
                        </Stack>
                    }
                </Stack>
            </Stack>
        </Stack>
    </Stack>
  )
}

export default BranchDetailsHeader
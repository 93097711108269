import React, { useEffect, useState } from "react";
import Loader from "../../../Components/Atoms/Loader/Loader";
import { useSearchParams } from "react-router-dom";
import SponsersTemplate from "../../../Templates/Main/Sponsers/Sponsers.Template";
import {
  deleteSponser,
  getSponserDetails,
  getSponsersList,
} from "../../../Contexts/APIs/Sponsers/Sponsers";
import { Box } from "@mui/material";
import usePageFilter from "../../../Components/Atoms/Hooks/usePageFilter";

function SponsersPage() {
  const { page, filterObj, handlePageChange ,setFilterObj,setPage,searchParams} = usePageFilter();
  const id = searchParams.get("id");
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openLoader, setOpenLoader] = useState(false);
  const [sponsersList, setSponsersList] = useState([]);
  const [selectedRow, setSelectedRow] = useState({});
  const [sponserData, setSponserData] = useState({});
  const [count, setCount] = useState(0);


  const handleDeleteRequest = async () => {
    setOpenLoader(true);
    const action = () => {
      setOpenDeleteModal(false);
      setFilterObj({ ...filterObj, page_number: 1 });
      setPage(1);
    };
    deleteSponser(selectedRow?.id, action);
    setOpenLoader(false);
  };

  // const handlePageChange = (event, value) => {
  //   setPage(value);
  // };

  const getSponsers = async (filterObj) => {
    setOpenLoader(true);
    const defaultFilter = {
      page_size: 10,
      page_number: 1,
      keyword: ""
    };
    const { res, error } = await getSponsersList({
      ...defaultFilter,
      ...filterObj,
    });
    if (res) {
      setOpenLoader(false);
      setSponsersList(res?.data?.sponsors);
      setCount(res?.extra?.total_count);
    }
    if (error) {
      setOpenLoader(false);
    }
  };
  const getSponsersData = async () => {
    setOpenLoader(true);
    const { res, error } = await getSponserDetails(id);
    if (res) {
      setSponserData(res.data.sponsor);
      setOpenLoader(false);
    }
    if (error) {
      setOpenLoader(false);
    }
  };

  useEffect(() => {
    filterObj && getSponsers(filterObj);
  }, [filterObj]);
  
  useEffect(() => {
    id && getSponsersData({});
  }, [id]);

  const rowData = (data) => {
    setSelectedRow(data?.rowData);
  };
  const breadcrumbs = [
    {
      active: true,
      path: "/sponsers",
      title: "Partners",
    },
    {
      active: false,
      title: id ? "Edit partner" : "Add new partner",
    },
  ];

  const mappedData = sponsersList?.map((data, index) => {
    return {
      ...data,
      logo: (
        <Box width="100px" display={"flex"} alignItems={"center"}>
          <Box
            component="img"
            src={data?.logo}
            width="53px"
            height="53px"
            sx={{ borderRadius: "10px" }}
          />
        </Box>
      ),
    };
  });
  return (
    <>
      <Loader open={openLoader} />
      <SponsersTemplate
        filterObj={filterObj}
        setFilterObj={setFilterObj}
        getSponsers={getSponsers}
        data={mappedData}
        count={count}
        paginationPage={page}
        setPaginationPage={setPage}
        handlePageChange={handlePageChange}
        rowData={rowData}
        sponserData={sponserData}
        breadcrumbs={breadcrumbs}
        openDeleteModal={openDeleteModal}
        setOpenDeleteModal={setOpenDeleteModal}
        handleDeleteRequest={handleDeleteRequest}
      />
    </>
  );
}

export default SponsersPage;

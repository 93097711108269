import { axiosFn } from "../..";
import handleErrors from "../../../../services/modules/utils/handel_error";
import { makeFilterString } from "../../Helper/Helper";
import * as URL from "../../URLs";

export async function getFreelancerLevelsList(filter_obj) {
  return await axiosFn(
    "get",
    URL.Freelancer_Levels + makeFilterString(filter_obj)
  )
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleErrors);
}

export async function createFreelancerLevels({ data, action }) {
  return await axiosFn("post", URL.Freelancer_Levels, data)
    .then((res) => {
      action && action();

      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleErrors);
}

export async function getFreelancerLevelsDetails(id) {
  return await axiosFn("get", `${URL.Freelancer_Levels}/${id}`)
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleErrors);
}

export async function deleteFreelancerLevels(id) {
  return await axiosFn("delete", `${URL.Freelancer_Levels}/${id}`)
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleErrors);
}

export async function updateFreelancerLevels({ id, data, action }) {
  return await axiosFn("put", `${URL.Freelancer_Levels}/${id}`, data)
    .then((res) => {
      action && action();
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleErrors);
}

import React from 'react'
import JobOffers from '../../../Components/Organisms/JobOffers/JobOffers';

function JobOffersTemplate(props) {
    const { filterObj, setFilterObj, jobOffersList, count, page, setPage, handlePageChange, roles, applicants_roles } = props;
    return ( 
        <JobOffers
            filterObj={filterObj} setFilterObj={setFilterObj}
            jobOffersList={jobOffersList}
            count={count} page={page}
            setPage={setPage}
            handlePageChange={handlePageChange}
            roles={roles}
            applicants_roles={applicants_roles}
        />
  )
}

export default JobOffersTemplate
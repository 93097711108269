import { Grid, Typography } from "@mui/material";
import "./Form.css";
import SelectBox from "../../Atoms/Select/Select.jsx";

const LanguagesSelect = (props) => {
  const {
    supportedLanguages,
    value,
    onChange,
    LanguagesSelectHead,
    LanguagesSelectTip,
  } = props;
  return (
    <Grid container md={12} item>
      <Grid container md={12} item flexDirection="row">
        {/* <Grid container item flexDirection='row' width={'350px'}> */}
        <Grid item md={3}>
          <Typography lineHeight="3.5" fontWeight="bold" component="p" mr={2}>
            {/* Data's Language */}
            {LanguagesSelectHead}
          </Typography>
        </Grid>
        <Grid container item md={4}>
          <Grid item md={10} margin="auto">
            {/* <Grid sx={{ width:'200px !important', maxWidth:'200px !important' }}> */}
            <SelectBox
              id="language"
              name="language"
              items={supportedLanguages}
              title="Select Language"
              className="select"
              value={value}
              onchange={onChange}
              selectBoxStyle={{ p: "9.5px 12px !important", mt: "4px" }}
            />
          </Grid>
        </Grid>
      </Grid>

      {LanguagesSelectTip === true ? (
        <Grid item md={8}>
          {/* ? <Grid width={'370px'}> */}
          <Typography lineHeight="3.5" fontWeight="normal" component="p">
            <Typography style={{ color: "red" }} component="span">
              Tip :{" "}
            </Typography>
            you can enter data with more than more language
          </Typography>
        </Grid>
      ) : null}
    </Grid>
  );
};

export default LanguagesSelect;

import React, { useState } from "react";
import NormalTabs from "../../Components/Atoms/Tabs/NormalTabs";

const DiveCenterStatisticsFilters = ({
  options,
  getType,
  handleChange,
  optionValue,
}) => {
  const [ActivityValue, setActivityValue] = useState(0);

  const handleActivityValue = (event, newValue) => {
    setActivityValue(newValue);
  };
  return (
    <>
      <NormalTabs
        TabsOptions={options}
        value={ActivityValue}
        change={handleActivityValue}
        ContainerStyle="switch-container"
        TabStyle="switch-btn filter-tabs-style mb-20px"
        onclick={(value) => {
          getType && getType(value);
        }}
      />
    </>
  );
};

export default DiveCenterStatisticsFilters;

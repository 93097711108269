import React from 'react'
import UserFormWrap from '../../../Components/Organisms/Users/UserFormWrap'

function UserFormTemplate(props) {
    const {formik, openLoader, rolesData, getRolesData, rolesCount, validationMethod, userImage, uploadUserImages, removeUserImage, formType} = props;
  return (
    <UserFormWrap  
        formik={formik}
        openLoader={openLoader} 
        rolesData={rolesData}
        getRolesData={getRolesData}
        rolesCount={rolesCount}
        validationMethod={validationMethod}
        userImage={userImage}
        uploadUserImages={uploadUserImages}
        removeUserImage={removeUserImage}
        formType={formType}
    />
  )
}

export default UserFormTemplate
import { Box, InputLabel, Stack, TextField } from "@mui/material";
import React from "react";
import CFormErrors from "./CFormErrors";

const CInput = ({
  label,
  placeholder,
  type = "text",
  name,
  formik,
  ...rest
}) => {
  const multinames = name?.split(".")?.map((data) => data);
  const value =
    multinames?.length == 2
      ? formik?.values?.[multinames[0]]?.[multinames[1]]
        ? formik?.values?.[multinames[0]]?.[multinames[1]]
        : ""
      : formik?.values[name];
  const errorsMsg =
    multinames?.length == 2
      ? formik?.errors?.[multinames[0]]?.[multinames[1]]
        ? formik?.errors?.[multinames[0]]?.[multinames[1]]
        : ""
      : formik.errors[name];

  const textfield = {
    position: "absolute",
    top: "-12px",
    fontSize: "13px",
    margin: "0 20px",
    background: "#fff",
    zIndex: "99",
    padding: "0 10px",
    color: "black",
  };
  console.log(rest, "selectBoxStyle");
  return (
    <Stack mb="5" position="relative">
      {value ? label && <span style={textfield}>{label}</span> : null}
      <TextField
        sx={{
          width: "100%",
          background: "white",
          objectFit: "contain",
          borderRadius: "10px",
          "& input": {
            padding: rest?.padding && rest?.padding,
          },
        }}
        id={name}
        variant="outlined"
        label={value ? "" : label}
        type={type}
        onChange={formik?.handleChange}
        value={value}
        onBlur={formik.handleBlur}
        name={name}
        {...rest}
      />
      <CFormErrors errorsMsg={errorsMsg} />
    </Stack>
  );
};

export default CInput;

export const BASE_URL = process.env.REACT_APP_API_URL;
export const auth = "auth/";
export const create_auth_token = "tokens/";
export const login = "/login";
export const scanQr = "scan_qr";
export const ADMINUSERS = "admin_users";
export const activities = "activities";
export const generate_presigned_url = "/generate_presigned_url";
export const activity_groups = "activity_groups/";
export const reservations = "/reservations";
export const group_reservations = "/group_reservations";
export const update_payment = "/update_payment";
export const orders = "orders/";
export const users = "users/";
export const create_order = "/create_order/";
export const FREELANCERS = "dive_experts";
// export const FREELANCERS = "dive_experts/mutuals";
export const JOB_OFFERS = "job_offers";
export const OFFERS = "activity_offers";
export const POINTS = "/point_actions";
export const SalesmanReservations = "salesman_reservation";
export const Salesmen = "/salesmen";
export const PointsConfig = "/point_configs";
export const Contacts = "/lookups/contacts";

export const branches = "branches";
export const organizations = "/organizations";
export const lookups = "lookups";
export const countries = "/countries";
export const cities = "/cities";
export const Sponsor = "/lookups/sponsors";
export const areas = "/areas";
export const feature_options = "/feature_options";
export const get_supported_languages = "/get_supported_languages";
export const currencies = "/currencies";
export const organization_certificates = "/organization_certificates";
export const languages = "/languages";
export const price_includes = "/price_includes";
export const payment_methods = "lookups/payment_methods/";
export const reviews = "reviews";
export const getGroupReservations =
  "activities/dashboard/search_dive_center_reservation";
export const notifications = "/notifications";
export const FCM = "/auth/set_device_fcm";
export const Decendent = "descendant_users/";

export const Roles = "lookups/roles";
export const Descendant_users = "/descendant_users";
export const Log_History = "/user_log_histories";
export const Log_History_toCSV = "/user_log_histories/to_csv";

export const SelectUserSuper = "lookups/users";
export const FREELANCERLEVELS = "lookups/dive_expert_levels";
export const Operators = "/dive_centers";
export const JOB_REQUESTS = "job_requests";
export const Reservations = "/orders";
export const Cities = "lookups/areas";
export const Country = "lookups/countries";
export const Provinces = "lookups/cities";
export const Customer_Levels = "/lookups/customer_levels";
export const Freelancer_Levels = "/lookups/dive_expert_levels";
export const Point_Configs = "/lookups/point_configs";
export const SystemConfigurations = "lookups/system_configurations";
export const Language_Fluency = "/lookups/language_fluency_levels";
export const Social_Media = "/lookups/social_media";
export const Badges = "/lookups/badges";
export const Feature_Options = "/lookups/feature_options";
export const Document = "/lookups/get_documents";
export const Document_Operations = "/lookups/documents";

export const Price_Includes = "/lookups/price_includes";
export const FAQ = "/lookups/faqs";
export const IsoCodes = "/lookups/iso_codes";
export const NotificationTypes = "/lookups/notification_types";

export const SalesmanStatistics = "salesman_statistics";
export const OrderStatistics = "order_statistics";
export const EarningsStatistics= "earning_statistics";
export const ActiveUserStatistics = "active_user_statistics";

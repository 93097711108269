import React from 'react'
import Activities from '../../../Components/Organisms/Activities/Activities';

function ActivitiesListTemplate(props) {
    const { filterObj, setFilterObj, activitiesList, openLoader, count, page, setPage, handlePageChange, activitiesLength, getActivities, roles ,setActivitiesList} = props;
    return ( 
        <Activities
            filterObj={filterObj} setFilterObj={setFilterObj}
            activitiesList={activitiesList}
            openLoader={openLoader}
            count={count} page={page}
            setPage={setPage}
            handlePageChange={handlePageChange}
            activitiesLength={activitiesLength}
            getActivities={getActivities}
            roles={roles}
            setActivitiesList={setActivitiesList}
        />
  )
}

export default ActivitiesListTemplate
import {IconButton, Menu, MenuItem } from "@mui/material";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import React, { useState } from 'react'
import { useNavigate } from "react-router-dom";
import RejectionModal from "../General/Models/RejectionModal";
import DeleteModal from "../General/Models/DeleteModal";


function ActionsMenu(props) {
    const {user, statusAttribute, profileStatusAttribute, userPath, userPathLength, handleApprove, handleUpdate, handleDelete, handleRejection, getBranches, filterObj, getBranch, getList, branchCount, setErrorListener, errorListener, isActivitiesList, roles,setList} = props;
    const navigate = useNavigate();
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => setAnchorEl(event.currentTarget);

    const handleClose = () => setAnchorEl(null);

    // hide in view details button in details page
    const hrf = window.location.href;
    const checkDetails = typeof parseInt(hrf.slice(hrf.indexOf(userPath)+(userPathLength+1), hrf.length)) === 'number' 
        && hrf.slice(hrf.indexOf(userPath)+(userPathLength+1), hrf.length).length != 0
        ? true : false;
    // hide in view details button in details page

    const handleDeleteRequest = async () => {
        const {res, err} = await handleDelete(user?.id);
        if(res){
            setOpenDeleteModal(false);
            handleClose();
            isActivitiesList == true 
                ? setList((prevState) =>
                prevState?.filter((ele) => ele?.id !== user?.id)
              )
                // : navigate("/operators/branches", {replace: true});
                : navigate(`/${userPath}`, {replace: true});
        }
    };

    const [openRejectModel, setOpenRejectModel] = useState(false);
    const [rejectionReason, setRejectionReason] = useState('');
    const handleRejectRequest = async () => {        
        if( rejectionReason.trim().length === 0 ){
            setErrorListener(true);
        }else{
            const { res, err } = await handleRejection(user?.id,{
                // "branch": {
                //     'name': user?.name,
                //     'status': "rejected",
                //     "rejection_reason": `${rejectionReason}`
                // }
                // "branch": {
                //     ...user,                                    
                //     'en': { 'name': user?.name },
                //     'status': "rejected",
                //     "rejection_reason": `${rejectionReason}`
                // }
                "branch": {
                    ...removeProperties(user, 'boat'),                                    
                    'en': { 'name': user?.name },
                    'status': "rejected",
                    "rejection_reason": `${rejectionReason}`
                },
                "boat": {
                    ...user?.boat
                }
            })
            if (res) {
                setOpenRejectModel(false);
                handleClose();
                branchCount === 'single'
                ? getBranch(user?.id)
                : getBranches && getBranches(filterObj);    
                setErrorListener(false);
            }
        }        
    }

    const removeProperties = (obj, ...properties) => {
        const result = { ...obj };
        properties.forEach(function(property) {
          delete result[property];
        });
        return result;
    }

  return (
    <>
        <IconButton
            id={`demo-positioned-btn${user?.id}`}
            aria-controls={open ? `demo-positioned-menu${user?.id}` : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
        >
            <MoreHorizIcon style={{ color: "black" }} />
        </IconButton>
        {
            ( !(user[profileStatusAttribute] === 'rejected' && checkDetails) 
              || isActivitiesList == true) &&
            <Menu
                id={`demo-positioned-menu${user?.id}`}
                aria-labelledby={`demo-positioned-btn${user?.id}`}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                {(!checkDetails && roles?.can_read) &&
                    <MenuItem onClick={() => {
                        console.log(`/${userPath}/` + user?.id);
                        navigate(`/${userPath}/` + user?.id);
                        handleClose();
                    }}>
                        View details
                    </MenuItem>
                }

            {/* { user[profileStatusAttribute] !== 'rejected' && <> */}

                {(user[profileStatusAttribute] !== 'rejected' && user !== null && user[profileStatusAttribute] !== null && ( 
                    user[profileStatusAttribute] === 'deactivated' || user[profileStatusAttribute] === 'suspended' 
                    || user[profileStatusAttribute] === 'pending' 
                ) && roles?.can_read && roles?.can_update && !isActivitiesList) &&
                        <MenuItem onClick={() => {
                            (async () => {
                                const {res, err} = await handleApprove(user?.id, {
                                    // "branch": {'name': user?.name, 'status': "approved", ...user}
                                    // "branch": {
                                    //     ...user,                                    
                                    //     'en': { 'name': user?.name },
                                    //     'status': "approved"
                                    // }
                                    "branch": {
                                        ...removeProperties(user, 'boat'),                                    
                                        'en': { 'name': user?.name },
                                        'status': "approved"
                                    },
                                    "boat": {
                                        ...user?.boat
                                    }
                                });
                                
                                if(res){
                                    handleClose();
                                    branchCount === 'single'
                                    ? getBranch(user?.id)
                                    : getBranches && getBranches(filterObj);                    
                                }
                            })();
                        }}>
                            Approve
                        </MenuItem>
                }

                {(user[profileStatusAttribute] !== 'rejected' && user !== null && user[profileStatusAttribute] !== null && ( 
                    user[profileStatusAttribute] === 'deactivated' || user[profileStatusAttribute] === 'pending' 
                ) && roles?.can_read && roles?.can_update && !isActivitiesList) &&
                        <MenuItem onClick={() => {
                            setOpenRejectModel(true);
                        }}>
                            Reject
                        </MenuItem>
                }


                {(user[profileStatusAttribute] !== 'rejected' && user !== null && user[profileStatusAttribute] !== null && ( 
                    user[profileStatusAttribute] === 'deactivated' || user[profileStatusAttribute] === 'approved' 
                    || user[profileStatusAttribute] === 'pending' 
                ) && roles?.can_read && roles?.can_update && !isActivitiesList) &&
                    <MenuItem onClick={() => {
                        (async () => {
                            const {res, err} = await handleUpdate(user?.id, {
                                // "branch": {
                                //     ...user, 
                                //     // ...removeProperties(user, 'name'),
                                //     'en': { 'name': user?.name },
                                //     'status': "suspended"
                                // }
                                "branch": {
                                    ...removeProperties(user, 'boat'),                                    
                                    'en': { 'name': user?.name },
                                    'status': "suspended"
                                },
                                "boat": {
                                    ...user?.boat
                                }
                            });
                            if(res){
                                handleClose();
                                branchCount === 'single'
                                ? getBranch(user?.id)
                                : getBranches && getBranches(filterObj);
                            }
                        })();
                    }}>
                        Suspend
                    </MenuItem> 
                    // : (( user[statusAttribute] === 'suspended' || user[statusAttribute] === 'deleted' ) && user[statusAttribute] !== null && user !== null)?
                    // <MenuItem onClick={() => {
                    //     (async () => {
                    //         const {res, err} = await handleUpdate(user?.id, {
                    //             "branch": {'status': "active"}
                    //         });
                    //         if(res){
                    //             handleClose();
                    //             branchCount === 'single'
                    //             ? getBranch(user?.id)
                    //             : getBranches && getBranches(filterObj);
                    //         }
                    //     })();
                    // }}>
                    //     Reactivate
                    // </MenuItem> : null
                }
                
                {( (user !== null && user[profileStatusAttribute] !== null && user[profileStatusAttribute] !== 'deleted' && roles?.can_delete && !isActivitiesList)
                 || (isActivitiesList && roles?.can_delete) )&&
                    <MenuItem onClick={() => {
                        setOpenDeleteModal(true);
                    }}>
                        Delete
                    </MenuItem>
                }
            {/* </>} */}
            </Menu>
        }
        {
            openRejectModel === true && <RejectionModal
                open={openRejectModel}
                setOpen={setOpenRejectModel}
                text={[`Rejection reason  ( ${user?.name} )`, '']}
                rejectionReason={rejectionReason}   setRejectionReason={setRejectionReason}
                errorListener={errorListener}
                buttons={['Cancel', 'ok']}
                handelRejectRequest={handleRejectRequest}
                handelCancelReject={()=>{
                    setOpenRejectModel(false);
                    handleClose();
                }}
                modalType={'form'}
                setErrorListener={setErrorListener}
            />
        }

        {
            openDeleteModal && <DeleteModal
                open={openDeleteModal} setOpen={setOpenDeleteModal} 
                handelDeleteRequest={handleDeleteRequest}
                handleCancelDelete={handleClose}
                text={[`Delete ${user?.name}`, `are you sure that you want to delete ${user?.name}`]}
            />
        }
    </>

  )
}

export default ActionsMenu
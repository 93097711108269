import { Box, IconButton, Menu, MenuItem } from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DeleteModal from "../../Models/DeleteModal";
import RejectionModal from "../../Models/RejectionModal";

function PrivilagesMenu(props) {
  const {
    user,
    statusAttribute,
    profileStatusAttribute,
    userPath,
    userPathLength,
    handleApprove,
    handleUpdate,
    handleDelete,
    handleRejection,
    getUsers,
    filterObj,
    getUser,
    userCount,
    setErrorListener,
    errorListener,
    roles,
    salesman_roles,
    setList,
    list,
  } = props;
  const navigate = useNavigate();
  const { operatorId } = useParams();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => setAnchorEl(event.currentTarget);

  const handleClose = () => setAnchorEl(null);
  // console.log(list);
  // hide in view details button in details page
  /*const hrf = window.location.href;
    const checkDetails = typeof parseInt(hrf.slice(hrf.indexOf(userPath)+(userPathLength+1), hrf.length)) === 'number' 
        && hrf.slice(hrf.indexOf(userPath)+(userPathLength+1), hrf.length).length != 0
        ? true : false;*/
  const checkDetails = operatorId ? true : false;
  // hide in view details button in details page

  const handleDeleteRequest = async () => {
    const { res, err } = await handleDelete(user?.id);
    if (res) {
      setOpenDeleteModal(false);
      handleClose();
      setList((prevState) => prevState?.filter((ele) => ele?.id !== user?.id));
      // userCount === "single"
      //   ? getUser(user?.id)
      //   : getUsers && getUsers(filterObj);
    }
  };

  const [openRejectModel, setOpenRejectModel] = useState(false);
  const [rejectionReason, setRejectionReason] = useState("");
  const handleRejectRequest = async () => {
    if (rejectionReason.trim().length === 0) {
      setErrorListener(true);
    } else {
      const { res, err } = await handleRejection(user?.id, {
        user: {
          profile_status: "rejected",
          rejection_reason: `${rejectionReason}`,
        },
      });
      if (res) {
        setOpenRejectModel(false);
        handleClose();
        userCount === "single"
          ? getUser(user?.id)
          : getUsers && getUsers(filterObj);
        setErrorListener(false);
      }
    }
  };

  return (
    <>
      <IconButton
        id={`demo-positioned-btn${user?.id}`}
        aria-controls={open ? `demo-positioned-men${user?.id}` : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <MoreHorizIcon style={{ color: "black" }} />
      </IconButton>
      <Menu
        id={`demo-positioned-men${user?.id}`}
        aria-labelledby={`demo-positioned-btn${user?.id}`}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        {!checkDetails && roles?.can_read && (
          <>
            <MenuItem
              onClick={() => {
                navigate(`/${userPath}/` + user?.id);
                handleClose();
              }}
            >
              View details
            </MenuItem>
            {user?.salesman_feature && salesman_roles?.can_read && (
              <MenuItem
                onClick={() => {
                  navigate(`/operators/salesman/` + user?.id);
                  handleClose();
                }}
              >
                View salesman
              </MenuItem>
            )}
          </>
        )}

        {user !== null &&
          user[statusAttribute] !== null &&
          user[statusAttribute] !== "suspended" &&
          user[statusAttribute] !== "deleted" &&
          user[profileStatusAttribute] === "pending_approval" &&
          roles?.can_read &&
          roles?.can_update && (
            <Box>
              <MenuItem
                onClick={() => {
                  (async () => {
                    const { res, err } = await handleApprove(user?.id, {
                      user: { profile_status: "approved" },
                    });
                    if (res) {
                      handleClose();
                      userCount === "single"
                        ? getUser(user?.id)
                        : getUsers && getUsers(filterObj);
                    }
                  })();
                }}
              >
                Approve
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setOpenRejectModel(true);
                }}
              >
                Reject
              </MenuItem>
            </Box>
          )}

        {!(
          user[statusAttribute] === "suspended" ||
          user[statusAttribute] === "deleted"
        ) &&
        user[statusAttribute] !== "deleted" &&
        user[statusAttribute] !== null &&
        user !== null &&
        roles?.can_read &&
        roles?.can_update ? (
          <MenuItem
            onClick={() => {
              (async () => {
                const { res, err } = await handleUpdate(user?.id, {
                  user: { status: "suspended" },
                });
                if (res) {
                  handleClose();
                  userCount === "single"
                    ? getUser(user?.id)
                    : getUsers && getUsers(filterObj);
                }
              })();
            }}
          >
            Suspend
          </MenuItem>
        ) : (user[statusAttribute] === "suspended" ||
            user[statusAttribute] === "deleted") &&
          user[statusAttribute] !== null &&
          user !== null ? (
          <MenuItem
            onClick={() => {
              (async () => {
                const { res, err } = await handleUpdate(user?.id, {
                  user: { status: "active" },
                });
                if (res) {
                  handleClose();
                  userCount === "single"
                    ? getUser(user?.id)
                    : getUsers && getUsers(filterObj);
                }
              })();
            }}
          >
            Reactivate
          </MenuItem>
        ) : null}

        {user !== null &&
          user[statusAttribute] !== null &&
          user[statusAttribute] !== "deleted" &&
          roles?.can_read &&
          roles?.can_delete && (
            <MenuItem
              onClick={() => {
                setOpenDeleteModal(true);
              }}
            >
              Delete
            </MenuItem>
          )}
      </Menu>
      {openRejectModel === true && (
        <RejectionModal
          open={openRejectModel}
          setOpen={setOpenRejectModel}
          text={[`Rejection reason  ( ${user?.name} )`, ""]}
          rejectionReason={rejectionReason}
          setRejectionReason={setRejectionReason}
          errorListener={errorListener}
          buttons={["Cancel", "ok"]}
          handelRejectRequest={handleRejectRequest}
          handelCancelReject={() => {
            setOpenRejectModel(false);
            handleClose();
          }}
          modalType={"form"}
        />
      )}

      {openDeleteModal && (
        <DeleteModal
          open={openDeleteModal}
          setOpen={setOpenDeleteModal}
          handelDeleteRequest={handleDeleteRequest}
          handleCancelDelete={handleClose}
          text={[
            `Delete ${user?.name}`,
            `are you sure that you want to delete ${user?.name}`,
          ]}
        />
      )}
    </>
  );
}

export default PrivilagesMenu;

import React, { useEffect, useState } from "react";
import Loader from "../../../../Components/Atoms/Loader/Loader";
import { useNavigate, useSearchParams } from "react-router-dom";
import PointsConfigurationTemplate from "../../../../Templates/Main/SystemConfigurations/PointsConfiguration/PointsConfiguration.template";
import {
  getPointConfigDetails,
  getPointsConfigList,
} from "../../../../Contexts/APIs/PointsConfig/PointsConfig";
import { Box, Button } from "@mui/material";

function PointsConfigurationPage({ roles }) {
  let [searchParams] = useSearchParams();
  const id = searchParams.get("id");

  const navigate = useNavigate();
  const [openLoader, setOpenLoader] = useState(false);
  const [pointsConfigList, setPointsConfigList] = useState([]);

  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);

  const [filterObj, setFilterObj] = useState(null);

  const [selectedRow, setSelectedRow] = useState({});
  const [pointConfigData, setPointConfigData] = useState({});

  const handlePageChange = (event, value) => {
    // setPage(value);
    // updatePagination(value);
  };

  const getPointsConfig = async (filterObj) => {
    setOpenLoader(true);
    const defaultFilterObj = {
      page_size: 10,
      page_number: 1,
      keyword: "",
      sort_by: "id",
    };
    const { res, error } = await getPointsConfigList({
      ...defaultFilterObj,
      ...filterObj,
    });
    if (res) {
      setOpenLoader(false);
      setPointsConfigList(res?.data);
      setCount(0);
    }
    if (error) {
      setOpenLoader(false);
    }
  };

  const getPointsConfigData = async () => {
    setOpenLoader(true);
    const { res, error } = await getPointConfigDetails(id);
    if (res) {
      setPointConfigData(res.data.point_configuration);
      setOpenLoader(false);
    }
    if (error) {
      setOpenLoader(false);
    }
  };
  // mappedData use for create custom cell to concat multi values or navigate button
  const mappedData = pointsConfigList?.map((data, index) => {
    return {
      ...data,
      lookup_key: data?.lookup_key ?? "-",
      action:
        roles?.can_read && roles?.can_update ? (
          <Box
            sx={{ cursor: "pointer", textDecoration: "underline" }}
            onClick={() => navigate(`?type=form&id=${data?.id}&index=${index}`)}
          >
            Edit
          </Box>
        ) : null,
      point_type_data: data?.point_type?.replaceAll("_", " "),
    };
  });

  useEffect(() => {
    if (filterObj) getPointsConfig(filterObj);
  }, [filterObj]);

  const rowData = (data) => {
    setSelectedRow(data);
  };
  const handleApplyFilterMenu = (obj) => {
    setFilterObj((prev) => {
      return { ...prev, ...obj, page_number: 1 };
    });
    setPage(1);
  };
  const handleResetFilterMenu = () => {
    setFilterObj({
      page_size: 10,
      page_number: 1,
      keyword: "",
      sort_by: "id",
    });
    setPage(1);
  };
  useEffect(() => {
    if (id) getPointsConfigData();
  }, [id]);
  const breadcrumbs = [
    {
      active: true,
      path: "/system-configurations/points",
      title: "point configurations",
    },
    {
      active: false,
      title: id ? "Edit point configuration" : "Add new point configuration",
    },
  ];

  return (
    <>
      <Loader open={openLoader} />
      <PointsConfigurationTemplate
        filterObj={filterObj}
        setFilterObj={setFilterObj}
        data={mappedData}
        count={count}
        paginationPage={page}
        setPaginationPage={setPage}
        handlePageChange={handlePageChange}
        rowData={rowData}
        pointConfigData={pointConfigData}
        handleApplyFilterMenu={handleApplyFilterMenu}
        handleResetFilterMenu={handleResetFilterMenu}
        breadcrumbs={breadcrumbs}
        roles={roles}
      />
    </>
  );
}

export default PointsConfigurationPage;

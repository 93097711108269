import {
    Button,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
  } from "@mui/material";
  import React from "react";
  import { useNavigate } from "react-router-dom";
  
  const DetailsUse = ({ data }) => {
    const navigate = useNavigate();
    console.log(data);
  
    const handelRowData = (key) => {
      console.log(typeof data[key]);
      if (typeof data[key] == "object") return data[key]?.name;
      else return data[key] ? data[key] : "-";
    };
    return (
      <Stack gap={2}>
        <Stack>
          {" "}
          <Button
            variant="contained"
            onClick={() => navigate("")}
            sx={{ width: "fit-content" }}
          >
            back
          </Button>
        </Stack>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
            <TableBody>
              {Object?.keys(data).map((row) => {
                return (
                  <TableRow key={row.name}>
                    <TableCell component="th" scope="row">
                      {row}
                    </TableCell>
                    <TableCell>{handelRowData(row)}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
    );
  };
  
  export default DetailsUse;
  
import React from 'react'
import { deleteDescendantUser, showDescendantUser } from '../../../Contexts/APIs/Users/UsersList';
import { useNavigate, useParams } from 'react-router-dom';
import { useState } from 'react';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import Loader from '../../../Components/Atoms/Loader/Loader';
import UserDetailsTemplate from '../../../Templates/Main/Users/UserDetails.template';

const UserDetailsPage = ({usersPrivilages}) => {
    const userType = localStorage.getItem("userType");
    const { userId } = useParams();
    const [userData, setUserData] = useState({});
    const [openLoader, setOpenLoader] = useState(false);
    const navigate = useNavigate();

    const getUser = async (id) => {
        setOpenLoader(true);
        const { res, err } = await showDescendantUser(id);
        if (res) { setUserData(res?.data?.descendant_user); }
        if (err) toast.error(err)
        setOpenLoader(false);
    };

    const handleEditUser = () => {
        if( userType !== 'descendant_user' || (userType === 'descendant_user' && usersPrivilages?.can_read && usersPrivilages?.can_update) ){
            navigate(`/users/edit/` + userId);
        }
    }

    const handelDeleteUser = async () => {
        if( userType !== 'descendant_user' || (userType === 'descendant_user' && usersPrivilages?.can_read && usersPrivilages?.can_delete) ){
            const { res, err } = await deleteDescendantUser(userId)
            setOpenLoader(true);
            if (res) {
                navigate(`/users`);
                setOpenLoader(false);
            }
        }
    }

    useEffect(() => {
        getUser(userId)
    }, []);

  return (
    <>
        <Loader open={openLoader} />
        <UserDetailsTemplate
          userData={userData}
          getUser={getUser}
          handleEditUser={handleEditUser}
          handelDeleteUser={handelDeleteUser}
          usersPrivilages={usersPrivilages}
        />
    </>
  )
}

export default UserDetailsPage
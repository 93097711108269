import { Grid, Typography, Avatar } from "@mui/material";
import { Link } from "react-router-dom";
import CustomSeparator from "../../../Atoms/BreadCrumb";
import "./ActivityGroupInfo.css";

const ActivityGroupInfo = (props) => {
  const { activityInfo } = props;
  const breadcrumbs = [
    { path: "/operators/activities", active: true, title: "Activities" },
    { active: false, title: activityInfo?.name },
  ];
  return (
    <Grid xs={12} gap={2}>
      <CustomSeparator breadcrumbs={breadcrumbs} />

      <Grid container className="activity-info-panel mb-30px" mt={2}>
        <Grid container lg={6} gap={2}>
          <Grid md={2}>
            {activityInfo?.logo === "url" ||
            activityInfo?.logo === "" ||
            activityInfo?.logo === null ? (
              <Avatar
                src=""
                height={"80px"}
                width={"80px"}
                sx={{
                  width: "80px",
                  height: "80px",
                  bgcolor: "grey",
                  borderRadius: "10px",
                }}
              />
            ) : (
              <img
                src={activityInfo?.logo}
                style={{
                  width: "80px",
                  height: "80px",
                  borderRadius: "10px",
                }}
              />
            )}
          </Grid>
          <Grid md={9} gap={1}>
            <Typography
              className="mb-10px"
              component="h1"
              style={{ fontSize: "30px", fontWeight: "bold", color: "#333132" }}
            >
              {activityInfo?.name}
            </Typography>
            <Typography
              component="p"
              style={{ fontSize: "20px", fontWeight: "500", color: "#f6b119" }}
            >
              {activityInfo?.price} {activityInfo?.currency?.lookup_key}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ActivityGroupInfo;

import React, { useEffect } from "react";
import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Languages from "../../../../Components/Atoms/Shared/language/Language";
import CCrud from "../../../../Components/Molecules/General/Crud/CCrud";
import "../../../../Components/Atoms/Shared/Styles/list-page.css";
import { initialValues, structure, validationSchema } from "./structure";
import AddIcon from "@mui/icons-material/Add";
import {
  createProvinces,
  updateProvinces,
} from "../../../../Contexts/APIs/Provinces/Provinces";
import './Organizations.css'
const ProvinceTemplate = ({
  setFilterObj,
  countriesList,
  isoCodesList,
  data,
  openDeleteModal,
  setOpenDeleteModal,
  handleDeleteRequest,
  count,
  paginationPage,
  setPaginationPage,
  handlePageChange,
  rowData,
  provinceData,
  breadcrumbs,
  getCountry,
  getIsoKeys,
  roles,
  countriesCount,
  isoCodesCount
}) => {
  const searchInputPlaceholder = "Search Provinces";
  let [searchParams, setSearchParams] = useSearchParams();
  const index = searchParams.get("index");
  const type = searchParams.get("type");

  const id = searchParams.get("id");
  const navigate = useNavigate();
  const [supportedLang, setSupportedLang] = useState("en");

  const [country, setCountry] = useState(null);

  const handelSubmit = (data) => {
    const action = () => {
      searchParams.delete("id");
      searchParams.delete("type");
      searchParams.delete("index");
      navigate("/geolocations/provinces");
    };

    if (index) {
      updateProvinces({ id, data: { city: data }, action });
    } else {
      createProvinces({
        data: { city: data },
        action,
      });
    }
  };
  const actionsList = ({ id, index, details }) => {
    return [
      {
        condition: () => {
          if (roles?.can_read && roles?.can_update) {
            return true;
          } else {
            return false;
          }
        },
        click: () => {
          navigate(`?type=form&id=${id}&index=${index}`);
        },
        text: "Edit",
      },
      {
        condition: () => {
          if (roles?.can_read && roles?.can_delete) {
            return true;
          } else {
            return false;
          }
        },
        click: () => {
          setOpenDeleteModal(true);
        },
        text: "Delete",
      },
    ];
  };

  const filalIsoCodesList = isoCodesList.map((isoCode) => {
    return { id: isoCode, name: isoCode };
  });

  const filterList = [
    {
      key: "country_id",
      title: "Contry",
      list: countriesList,
      getList: getCountry,
      count: countriesCount,
      onMenuOpen: () => countriesList.length === 0 && getCountry()
    },
    {
      key: "iso_code",
      title: "ISO key",
      list: filalIsoCodesList,
      getList: getIsoKeys,
      count: isoCodesCount,
      onMenuOpen: () => isoCodesList.length === 0 && getIsoKeys()
    },
  ];

  const handleApplyFilterMenu = (obj) => {
    setFilterObj((prev) => {
      return { ...prev, ...obj, page_number: 1 };
    });
    setPaginationPage(1);
  };
  const handleResetFilterMenu = (obj) => {
    setFilterObj({
      page_size: 10,
      page_number: 1,
      keyword: "",
      sort_by: "id",
    });
    setPaginationPage(1);
  };
  return (
    <CCrud
      table={{
        list: data,
        filterList,
        openDeleteModal: openDeleteModal,
        setOpenDeleteModal: setOpenDeleteModal,
        rowData: rowData,
        listTitle: "Provinces",
        emptyListMsg: "There are no Provinces",
        details: {
          navigatePath: "/geolocations/provinces",
          statusField: null,
          hideStatus: null,
        },
        actionsList: actionsList,
        columns: [
          { accessor: "id_data", Header: "Id" },
          { accessor: "name_data", Header: "Province name" },
          { accessor: "country.name", Header: "Country" },
          { accessor: "lookup_data", Header: "Lookup key" },
          { accessor: "iso_code", Header: "Iso code" },
          { accessor: "Actions", Header: "Actions" },
        ],
        isFilter: true,
        isSearch: true,
        isChecked: false,
        isSort: false,
        total_count: count,
        setFilterObj,

        handleApplyFilterMenu: handleApplyFilterMenu,
        handleResetFilterMenu: handleResetFilterMenu,
        createBtnTitle: "Add new provinces",
        createBtnFun:
          roles?.can_read && roles?.can_create
            ? () => navigate("?type=form")
            : null,
        CreateBtnIcon: AddIcon,
        hideCreateBtn: roles?.can_read && roles?.can_create ? null : true,
        createBtnClass: "add-organization-button",

        className: "organization-table provinces-table",
        tableContainerOverflow: {overflow: 'auto'},
        paginationPage: paginationPage,
        setPaginationPage: setPaginationPage,
        handlePageChange,

        searchInputPlaceholder: searchInputPlaceholder,

        openDeleteModal: openDeleteModal,
        setOpenDeleteModal: setOpenDeleteModal,
        handleDeleteRequest,
      }}
      form={{
        structure: () => structure(supportedLang, countriesList, countriesCount, getCountry, country, setCountry),
        validationSchema: () => validationSchema(supportedLang),
        initialValues: !index ? {} : initialValues(provinceData, supportedLang),
        onSubmit: handelSubmit,
        className: { form: "flow-form", actionButton: "flow-form-buttons" },
        submitTitle: index === null ? "Add" : "Save changes",
        setSupportedLang: setSupportedLang,
        handleLanguage: <Languages setLanguage={setSupportedLang} />,
        formHead: "Provinces  information",
        title: index ? `Edit "${provinceData?.name}"` : "Add new Provinces",
        breadcrumbs: breadcrumbs,
      }}
    />
  );
};

export default ProvinceTemplate;

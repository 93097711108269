import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import CCrud from "../../../Components/Molecules/General/Crud/CCrud";
import { initialValues, structure, validationSchema } from "./structure";
import { Stack } from "@mui/material";
import CustomSeparator from "../../../Components/Atoms/BreadCrumb";
import "../../../Components/Atoms/Shared/Styles/list-page.css";

const ContactsTemplate = ({
  setFilterObj,
  data,
  count,
  paginationPage,
  setPaginationPage,
  handlePageChange,
  handleSubmit,
  rowData,
  systemConfig,
}) => {
  let [searchParams] = useSearchParams();
  const type = searchParams.get("type");
  const id = searchParams.get("id");

  const name = searchParams.get("name");
  const navigate = useNavigate();

  const breadcrumbs = [
    {
      path: "/contacts",
      active: true,
      title: "Social media links",
    },

    {
      active: false,
      title: `Edit ${name}`,
    },
  ];
  return (
    <>
      {type === "form" ? (
        <Stack
          direction="row"
          alignSelf="start"
          justifyContent="start"
          gap={2}
          width="100%"
          mb={1}
        >
          <CustomSeparator breadcrumbs={breadcrumbs} />
        </Stack>
      ) : null}
      <CCrud
        table={{
          list: data,
          listTitle: "Social media links",
          emptyListMsg: "There are no Social media links",

          columns: [
            { accessor: "name", Header: "Contact" },
            { accessor: "url", Header: "Url" },

            { accessor: "action", Header: "Actions" },
          ],
          isFilter: false,
          isSearch: false,
          isChecked: false,
          isSort: false,
          total_count: count,
          setFilterObj,
          className: "organization-table system-configurations-table",
          tableContainerOverflow: {overflow: 'auto'},
          paginationPage: paginationPage,
          setPaginationPage: setPaginationPage,
          handlePageChange,
          rowData: rowData,
        }}
        form={{
          structure: () => structure(name),
          initialValues: !id ? {} : initialValues(systemConfig),
          validationSchema: validationSchema(),
          onSubmit: handleSubmit,
          title: `Edit ${name}`,
          setSupportedLang: () => {},
          className: { form: "flow-form", actionButton: "flow-form-buttons" },
        }}
      />
    </>
  );
};

export default ContactsTemplate;

import React, { useEffect, useState } from "react";
import GoogleMapReact from 'google-map-react';
import RoomIcon from '@mui/icons-material/Room';
import Autocomplete from "react-google-autocomplete";
import PrimaryButton from "../../Atoms/Buttons/Primary/PrimaryButton.jsx";
import { Alert, Button, Collapse, Stack, Typography } from "@mui/material";
import { toast } from "react-toastify";

const Marker = ({ icon }) => <div>{icon}</div>;

export default function Map(props) {
  const { type, getCurrentLocation, lat, lng } = props;
  const mapKey = process.env.REACT_APP_GOOGLE_API_MAP_KEY;
  const [open, setOpen] = React.useState(true);

  const [userLat, setUserLat] = useState(48.89880531796407);
  const [userLng, setUserLng] = useState(2.3507744060959697);
  const [center, setCenter] = useState({
    lat: "",
    lng: ""
  });

  const getLocation = () => {

    // if (navigator.geolocation) {
    //   navigator.geolocation.getCurrentPosition(showPosition);
    // }

  };

  const showPosition = (position) => {
    setUserLat(position.coords.latitude);
    setUserLng(position.coords.longitude);
  };
  const defaultProps = {

    center: {
      lat: 2.3507744060959697,
      lng: 2.3507744060959697
    },
    greatPlaceCoords: { lat: 59.724465, lng: 30.080121 },
    zoom: 10
  };

  useEffect(() => {
    if (!lat && !lng) { getCurrentLocation(48.856614, 2.3522219); }
  }, [lat]);
  const changLocation = (map) => {
    setOpen(true)
    if (type === 'add') {
      setUserLat(map.lat);
      setUserLng(map.lng);
    }
  };

  const getSearchLocation = (place) => {
    setUserLat(place.geometry.location.lat());
    setUserLng(place.geometry.location.lng());
  };

  useEffect(() => {
    setCenter({
      lat: userLat,
      lng: userLng
    });
    if (type === 'add') getCurrentLocation(userLat, userLng);
  }, [userLat, userLng]);

  useEffect(() => {
    if (lat !== null) {
      setUserLat(lat);
      setUserLng(lng);
      getCurrentLocation(lat, lng)
    }
  }, [lat, lng]);


  return (
    <div
      style={{
        height: '300px',
        width: '100%',
        borderRadius: '10px',
        boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
        overflow: 'hidden',
        position: 'relative',
        marginBottom: '20px'
      }}
    >
      {type === 'add' &&
        <Autocomplete
          className="places-search"
          apiKey={mapKey}
          onPlaceSelected={(place) => {
            getSearchLocation(place);
          }}
          placeholder="Search Location"
        />
      }
      <GoogleMapReact
        bootstrapURLKeys={{ key: mapKey, libraries: ['places'] }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
        center={center}
        onClick={(map) => changLocation(map)}
      >
        <Marker
          lat={userLat}
          lng={userLng}
          icon={
            <RoomIcon
              style={{
                color: "var(--primary-color)",
                fontSize: "40px"
              }}
            />
          }
        />
      </GoogleMapReact>

      {type == 'add' &&

        <>
          <Button
            onClick={() => {
              if (type == 'add') {
                setOpen(false)
                toast.info('Location saved successfully', {
                  position: "bottom-center",
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "dark",
                });
              }
            }}
            sx={{ "&:hover": { background: 'var(--primary-color)' }, visibility: open ? 'visible' : 'hidden', background: "var(--primary-color)", color: 'white', position: 'absolute', bottom: '20px', right: '20px', borderRadius: '24px' }} >
            Add location
          </Button>

        </>

      }


    </div >
  );
}
import React, { useEffect, useState } from "react";
import Loader from "../../../../Components/Atoms/Loader/Loader";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import SalesmanTemplate from "../../../../Templates/Main/Operators/Salesman/Salesman.template";
import { Box, Typography } from "@mui/material";
import { getSalesmenList } from "../../../../Contexts/APIs/Operators/Operators";
import PhoneInput from 'react-phone-input-2'

function SalesmanPage({salesman_reservations_roles}) {
  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();
  const { operatorId } = useParams();
  const id = searchParams.get("id");
  const type = searchParams.get("type");

  const [openLoader, setOpenLoader] = useState(false);
  const [salesmanList, setSalesmanList] = useState([]);
  const [notificationData, setNotificationsData] = useState({});
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [filterObj, setFilterObj] = useState(null);
  const [selectedRow, setSelectedRow] = useState({});

  const updatePagination = (page) => {
    setFilterObj({ ...filterObj, page_number: page });
  };

  const handlePageChange = (event, value) => {
    setPage(value);
    updatePagination(value);
  };

  const getSalesmanList = async (filterObj) => {
    const defaultFilter = {
      page_size: 10,
      page_number: 1,
      keyword: "",
      sort_by: "id",
    };
    setOpenLoader(true);
    const { res, error } = await getSalesmenList({
      ...defaultFilter,
      ...filterObj,
      dive_center_id: operatorId,
    });
    if (res) {
      setOpenLoader(false);
      setSalesmanList(res?.data?.salesmen);
      setCount(res?.extra?.total_count);
    }
    if (error) {
      setOpenLoader(false);
    }
  };

  useEffect(() => {
    if (filterObj) getSalesmanList(filterObj);
  }, [filterObj]);

  const rowData = (data) => {
    setSelectedRow(data);
  };

  const mappedData = salesmanList?.map((val) => {
    return {
      ...val,
      name: val?.name,
      email: <Typography sx={{ minWidth: "250px" }}>{val?.email}</Typography>,
      phone:  val?.phone_number && <PhoneInput
        inputStyle={{
          border: "none",
          width: "100%",
          padding: "0px",
          direction: "ltr",
          fontSize: '18px',                      
          color: '#210000',
          backgroundColor: 'white',
          wordBreak: 'break-word'
        }}
        defaultMask={ parseInt(val.country_code) == 20 ? '... ... ....' : '.. ... ... ....' }  
        value={`${val.country_code} ${val?.phone_number}`}
        disabled={true}
        disableDropdown={true}
        specialLabel=""
        placeholder=""
      />,
      image: <Box as="img" src={val?.avatar} width="50px" height="50px" />,
      status: val?.status ? (
        <Typography sx={{ color: "#00B906 !important" }}>Active</Typography>
      ) : (
        <Typography sx={{ color: "#F6B119 !important" }}>
          Deactivated
        </Typography>
      ),
      action: salesman_reservations_roles?.can_read ? (
        <Link
          to={`/operators/salesman/${operatorId}/reservation/${val?.id}`}
          style={{ color: "#333132", fontSize: "16px" }}
        >
          View reservations
        </Link>
      ):null,
    };
  });
  return (
    <>
      <Loader open={openLoader} />
      <SalesmanTemplate
        setFilterObj={setFilterObj}
        data={mappedData}
        count={count}
        paginationPage={page}
        setPaginationPage={setPage}
        handlePageChange={handlePageChange}
        rowData={rowData}
      />
    </>
  );
}

export default SalesmanPage;

import React from "react";
import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Languages from "../../../../Components/Atoms/Shared/language/Language";
import CCrud from "../../../../Components/Molecules/General/Crud/CCrud";
import "../../../../Components/Atoms/Shared/Styles/list-page.css";
import { initialValues, structure, validationSchema } from "./structure";
import AddIcon from "@mui/icons-material/Add";
import {
  createLanguageFluency,
  updateLanguageFluency,
} from "../../../../Contexts/APIs/Fluency Level/FluencyLevel";
import {
  createPriceInclude,
  updatePriceInclude,
} from "../../../../Contexts/APIs/PriceInclude/PriceInclude";
import "./PriceInclude.css";
import LanguagesSelect from "../../../../Components/Molecules/Forms/LanguagesSelect";
import { Grid } from "@mui/material";

const PriceIncludeTemplate = ({
  setFilterObj,
  data,
  openDeleteModal,
  setOpenDeleteModal,
  handleDeleteRequest,
  count,
  paginationPage,
  setPaginationPage,
  handlePageChange,
  supportedLanguages,
  rowData,
  details,
  breadcrumbs,
  roles,
}) => {
  let [searchParams, setSearchParams] = useSearchParams();
  const index = searchParams.get("index");
  const id = searchParams.get("id");
  const navigate = useNavigate();
  const [supportedLang, setSupportedLang] = useState("en");
  const updateSupportedLang = (e, languages) => {
    setSupportedLang(e.target.value);
  };

  const handelSubmit = async (data, formik) => {
    const action = () => {
      searchParams.delete("id");
      searchParams.delete("type");
      searchParams.delete("index");
      navigate("/predefined-lookups/price-includes");
      formik?.resetForm();
    };

    if (index) {
      updatePriceInclude({
        id,
        data: { price_include: data },
        action,
      });
    } else {
      createPriceInclude({
        data: { price_include: data },
        action,
      });
    }
  };

  const handleApplyFilterMenu = (obj) => {
    setFilterObj((prev) => {
      return { ...prev, ...obj, page_number: 1 };
    });
    setPaginationPage(1);
  };
  const handleResetFilterMenu = () => {
    setFilterObj({
      page_size: 10,
      page_number: 1,
      keyword: "",
      sort_by: "id",
    });
    setPaginationPage(1);
  };
  const actionsList = ({ id, index, details }) => {
    return [
      {
        condition: () => {
          if (roles?.can_read && roles?.can_update) {
            return true;
          } else {
            return false;
          }
        },
        click: () => {
          navigate(`?type=form&id=${id}&index=${index}`);
        },
        text: "Edit",
      },
      {
        condition: () => {
          if (roles?.can_read && roles?.can_delete) {
            return true;
          } else {
            return false;
          }
        },
        click: () => {
          setOpenDeleteModal(true);
        },
        text: "Delete",
      },
    ];
  };
  return (
    <CCrud
      table={{
        list: data,
        openDeleteModal: openDeleteModal,
        setOpenDeleteModal: setOpenDeleteModal,
        rowData: rowData,
        actionsList,
        listTitle: "Price includes",
        emptyListMsg: "There are no fluency level",
        details: {
          navigatePath: "/predefined-lookups/price-includes",
          statusField: null,
          hideStatus: null,
        },
        columns: [
          { accessor: "id", Header: "Id" },
          { accessor: "name", Header: "Name" },
          { accessor: "created_at_data", Header: "Created at" },
          { accessor: "updated_at_data", Header: "Updated at" },
          { accessor: "Actions", Header: "Actions" },
        ],
        isFilter: false,
        isSearch: false,
        isChecked: false,
        isSort: false,
        total_count: count,
        setFilterObj,
        handleApplyFilterMenu: handleApplyFilterMenu,
        handleResetFilterMenu: handleResetFilterMenu,
        createBtnTitle: "Add new price include",
        createBtnFun:
          roles?.can_read && roles?.can_create
            ? () => navigate("?type=form")
            : null,
        CreateBtnIcon: AddIcon,
        createBtnClass: "add-organization-button",
        className: "organization-table price-include-table",
        paginationPage: paginationPage,
        setPaginationPage: setPaginationPage,
        handlePageChange,
        openDeleteModal: openDeleteModal,
        setOpenDeleteModal: setOpenDeleteModal,
        handleDeleteRequest,
      }}
      form={{
        structure: () => structure(supportedLang),
        validationSchema: () => validationSchema(supportedLang),
        initialValues: !index ? {} : initialValues(details, supportedLang),
        onSubmit: handelSubmit,
        setSupportedLang: setSupportedLang,
        className: {
          form: "flow-form price-include-form",
          actionButton: "flow-form-buttons",
        },
        handleLanguage: (
          <Grid container item className="select-lang-wrap" mb="18.5px">
            <LanguagesSelect
              value={supportedLang}
              supportedLanguages={supportedLanguages}
              onChange={(e) => updateSupportedLang(e, supportedLanguages)}
              LanguagesSelectHead={"Data Language"}
            />
          </Grid>
        ),
        title: index ? "Edit price include" : "Add new price include",
        submitTitle: index === null ? "Add" : "Save changes",
        breadcrumbs: breadcrumbs,
      }}
    />
  );
};

export default PriceIncludeTemplate;

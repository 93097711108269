import FreelancersList from "../../../Components/Organisms/Freelancers/FreelancersList";

const FreelancersListTemplate = ({ count, freelancers, getFreelancers, freelancersPrivilages, roles,setList }) => {
    return (
        <FreelancersList
            count={count}
            freelancers={freelancers}
            getFreelancers={getFreelancers}
            freelancersPrivilages={freelancersPrivilages}
            roles={roles}
            setList={setList}
        />
    );
}

export default FreelancersListTemplate;
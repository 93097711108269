import React, { useEffect, useState } from "react";
import Loader from "../../../../Components/Atoms/Loader/Loader";
import { useSearchParams } from "react-router-dom";
import LanguageTemplate from "../../../../Templates/Main/PredefinedLookups/Language/Language.template";
import { LanguageAPI } from "../../../../Contexts/APIs/Lookups/Language";
import usePageFilter from "../../../../Components/Atoms/Hooks/usePageFilter";

function LanguagePage() {
  // let [searchParams] = useSearchParams();
  const {
    page,
    filterObj,
    handlePageChange: onPageChange,
    setFilterObj,
    setPage,
    searchParams,
  } = usePageFilter();
  const id = searchParams.get("id");

  const [openLoader, setOpenLoader] = useState(false);
  const [languageList, setLanguageList] = useState([]);

  const [count, setCount] = useState(0);
  // const [page, setPage] = useState(1);

  // const [filterObj, setFilterObj] = useState(null);

  const [selectedRow, setSelectedRow] = useState({});

  // const updatePagination = (page) => {
  //   setFilterObj({ ...filterObj, page_number: page });
  // };

  // const handlePageChange = (event, value) => {
  //   setPage(value);
  //   updatePagination(value);
  // };

  const getLanguage = async () => {
    setOpenLoader(true);
    const defaultFilterObj = {
      page_size: 10,
      page_number: 1,
      keyword: "",
      sort_by: "id",
    };
    const { res, error } = await LanguageAPI({
      ...defaultFilterObj,
      ...filterObj,
    });
    if (res) {
      setOpenLoader(false);
      setLanguageList(res?.data?.languages);
      setCount(res?.extra?.total_count);
    }
    if (error) {
      setOpenLoader(false);
    }
  };

  useEffect(() => {
    if (filterObj) getLanguage();
  }, [filterObj]);

  const rowData = (data) => {
    setSelectedRow(data?.rowData);
  };
  console.log(filterObj);
  return (
    <>
      <Loader open={openLoader} />
      <LanguageTemplate
        filterObj={filterObj}
        setFilterObj={setFilterObj}
        data={languageList}
        count={count}
        paginationPage={page}
        setPaginationPage={setPage}
        handlePageChange={onPageChange}
        rowData={rowData}
      />
    </>
  );
}

export default LanguagePage;

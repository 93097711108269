import React from "react";
import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Languages from "../../../../Components/Atoms/Shared/language/Language";
import CCrud from "../../../../Components/Molecules/General/Crud/CCrud";
import "../../../../Components/Atoms/Shared/Styles/list-page.css";
import { initialValues, structure, validationSchema } from "./structure";
import OrganizationDetailsPage from "../../../../Pages/Main/Organizations/OrganizationDetails.page";
import AddIcon from "@mui/icons-material/Add";
import {
  createCountry,
  updateCountry,
} from "../../../../Contexts/APIs/Country/Country";
import "./Country.css";

const CountryTemplate = ({
  countryData,
  setFilterObj,
  data,
  openDeleteModal,
  setOpenDeleteModal,
  handleDeleteRequest,
  rowData,
  count,
  paginationPage,
  setPaginationPage,
  handlePageChange,
  breadcrumbs,
  roles
}) => {
  let [searchParams] = useSearchParams();
  const index = searchParams.get("index");
  const id = searchParams.get("id");
  const navigate = useNavigate();
  const [supportedLang, setSupportedLang] = useState("en");

  const handelSubmit = (data, formik) => {
    const action = () => {
      searchParams.delete("id");
      searchParams.delete("type");
      searchParams.delete("index");
      navigate("/geolocations/countries");
      formik?.resetForm();
    };
    if (index) {
      updateCountry({ id, data: { country: data }, action });
    } else {
      createCountry({
        data: { country: data },
        action,
      });
    }
  };

  const actionsList = ({ id, index, details }) => {
    return [
      {
        condition: () => {
          if (roles?.can_read && roles?.can_update) {
            return true;
          } else {
            return false;
          }
        },
        click: () => {
          navigate(`?type=form&id=${id}&index=${index}`);
        },
        text: "Edit",
      },
      {
        condition: () => {
          if (roles?.can_read && roles?.can_delete) {
            return true;
          } else {
            return false;
          }
        },
        click: () => {
          setOpenDeleteModal(true);
        },
        text: "Delete",
      },
    ];
  };

  return (
    <CCrud
      table={{
        list: data,
        openDeleteModal: openDeleteModal,
        setOpenDeleteModal: setOpenDeleteModal,
        rowData: rowData,
        listTitle: "Countries",
        emptyListMsg: "There are no country",
        details: {
          navigatePath: "/geolocations/countries",
          statusField: null,
          hideStatus: null,
        },
        actionsList: actionsList,
        columns: [
          { accessor: "id", Header: "Id" },
          { accessor: "name", Header: "Country name" },
          { accessor: "lookup_data", Header: "Lookup key" },
          { accessor: "iso_code", Header: "ISO code" },
          { accessor: "Actions", Header: "Actions" },
        ],
        isFilter: false,
        isSearch: true,
        isChecked: false,
        isSort: false,
        total_count: count,
        setFilterObj,
        createBtnTitle: "Add new country",
        createBtnFun: () => navigate("?type=form"),
        CreateBtnIcon: AddIcon,
        hideCreateBtn: roles?.can_read && roles?.can_create ? null : true,
        createBtnClass: "add-organization-button",
        className: "organization-table county-table",
        paginationPage: paginationPage,
        setPaginationPage: setPaginationPage,
        searchInputPlaceholder: "Search Country",
        handlePageChange,
        openDeleteModal: openDeleteModal,
        setOpenDeleteModal: setOpenDeleteModal,
        handleDeleteRequest,
      }}
      form={{
        structure: () =>
          structure(supportedLang, [{ id: "216", name: "test" }]),
        validationSchema: validationSchema,
        initialValues: !index ? {} : initialValues(countryData, supportedLang),
        onSubmit: handelSubmit,
        className: { form: "flow-form", actionButton: "flow-form-buttons" },
        handleLanguage: <Languages setLanguage={setSupportedLang} />,
        submitTitle: index === null ? "Add" : "Save changes",
        setSupportedLang: setSupportedLang,
        formHead: "Country  information",
        title: index ? `Edit "${countryData?.name}"` : "Add new country",
        breadcrumbs: breadcrumbs,
      }}
      DetailsComponent={<OrganizationDetailsPage />}
    />
  );
};

export default CountryTemplate;

import { Grid } from "@mui/material";
import AuthLayout from "../../Components/Organisms/Auth/Layout";
import LoginForm from "../../Components/Organisms/Auth/Login/LoginForm";

const LoginTemplate = (props) => {
  const { Login } = props;

  return (
    <AuthLayout title=" Welcome to our diving world">
      <Grid container>
        <Grid
          container
          item
          xl={4}
          lg={5}
          md={5}
          sm={11}
          xs={12}
          borderRadius="10px"
          boxShadow="0 2px 15px 0 rgba(0, 0, 0, 0.1)"
          backgroundColor="#fff"
          style={{ margin: "auto" }}
        >
          <LoginForm Login={Login} />
        </Grid>
      </Grid>
    </AuthLayout>
  );
};

export default LoginTemplate;

import LoginTemplate from "../../Templates/Auth/Login.Template.jsx";
import { login } from "../../Contexts/APIs/Auth/Login.js";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
// import { useEffect } from "react";
// import { Firebase } from "../../Contexts/Firebase/Firebase.js";

const LoginPage = (props) => {
  const Login = async (data) => {
    const { res, err } = await login("admin_user", data);
    if (res) {
      // localStorage.setItem("accessToken", res?.data?.extra?.access_token);
      localStorage.setItem("Token", res?.data?.extra?.access_token);
      localStorage.setItem("userId", res?.data?.user?.id);
      // localStorage.setItem("contract", res.data?.user?.dive_center_contract);
      localStorage.setItem("email", res.data?.user?.email);
      localStorage.setItem("firebase_auth_token", res?.data?.user?.firebase_auth_token);
      localStorage.setItem("firebase_node_path", res?.data?.user?.firebase_node_path);

      localStorage.setItem("roles", JSON?.stringify(res?.data?.user?.permissions));

      localStorage.setItem("userType", res.data?.user?.user_type);
      window.location.href = "/profile";
      if (!localStorage.getItem("fcm_token")) localStorage.setItem("fcm_token", window.navigator.userAgent);
    }
    if (err) {
      toast.error(err);
    }
  };

  return <LoginTemplate Login={Login} />;
};

export default LoginPage;

import { axiosFn, handleError } from "..";
import { makeFilterString } from "../Helper/Helper";
import * as URL from "../URLs";

export async function getFAQList(filter_obj) {
  return await axiosFn("get", URL.FAQ + makeFilterString(filter_obj))
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}

export async function createFAQ({ data, action }) {
  return await axiosFn("post", URL.FAQ, data)
    .then((res) => {
      action && action();
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}

export async function getFAQDetails(organization_id) {
  return await axiosFn("get", `${URL.FAQ}/${organization_id}`)
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}

export async function deleteFAQ(id) {
  return await axiosFn("delete", `${URL.FAQ}/${id}`)
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}

export async function updateFAQ({ id, data, action }) {
  return await axiosFn("put", `${URL.FAQ}/${id}`, data)
    .then((res) => {
      action && action(res);
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}

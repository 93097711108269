import React from 'react'
import BranchDetails from '../../../Components/Organisms/Branches/BranchDetails'

function BranchDetailsTemplate({branchData, getBranch, roles}) {
  return (
    <BranchDetails 
        branchData={branchData}
        getBranch={getBranch}
        roles={roles}
    />
  )
}

export default BranchDetailsTemplate
import { axiosFn, handleError } from "..";
import { makeFilterString } from "../Helper/Helper";
import * as URL from "../URLs";

export async function getPointsConfigList(filter_obj) {
  return await axiosFn("get", URL.Point_Configs + makeFilterString(filter_obj))
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}

export async function createPointConfig({ data, action }) {
  return await axiosFn("post", URL.Point_Configs, data)
    .then((res) => {
      action && action();
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}

export async function getPointConfigDetails(organization_id) {
  return await axiosFn("get", `${URL.Point_Configs}/${organization_id}`)
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}

export async function deletePointConfig(id) {
  return await axiosFn("delete", `${URL.Point_Configs}/${id}`)
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}

export async function updatePointConfig({ id, data, action }) {
  return await axiosFn("put", `${URL.Point_Configs}/${id}`, data)
    .then((res) => {
      action && action();
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}

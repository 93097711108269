import React, { useEffect, useState } from "react";
import Loader from "../../../../Components/Atoms/Loader/Loader";
import { useNavigate } from "react-router-dom";
import { getSupportedLanguages } from "../../../../Contexts/APIs/Lookups/SupportedLanguages";
import { toast } from "react-toastify";
import {
  editPaymentMethod,
  getPaymentMethodDetails,
  getPaymentMethodsList,
} from "../../../../Contexts/APIs/PaymentMethods/PaymentMethods";
import PaymentMethodsTemplate from "../../../../Templates/Main/PredefinedLookups/PaymentMethods/PaymentMethods.template";
import { Box } from "@mui/material";
import usePageFilter from "../../../../Components/Atoms/Hooks/usePageFilter";

function PaymentMethodsPage({roles}) {
  const navigate = useNavigate();
  // let [searchParams] = useSearchParams();
  const {
    page,
    filterObj,
    handlePageChange: onPageChange,
    setFilterObj,
    setPage,
    searchParams,
  } = usePageFilter();
  const index = searchParams.get("index");
  const type = searchParams.get("type");

  const paymentMethodId = searchParams.get("id");
  const [openLoader, setOpenLoader] = useState(false);
  const [paymentMethodsList, setPaymentMethodsList] = useState([]);

  const [count, setCount] = useState(0);
  // const [page, setPage] = useState(1);

  // const [filterObj, setFilterObj] = useState(null);

  const [selectedRow, setSelectedRow] = useState({});
  const [supportedLanguages, setSupportedLanguages] = useState([]);
  const [supportLang, setSupportLang] = useState("en");
  const [paymentMethodData, setPaymentMethodData] = useState({});
  const userType = localStorage.getItem("userType");

  const mappedData = paymentMethodsList?.map((data) => {
    return {
      ...data,
      paymentMeth_id: <div style={{ width: "60px" }}>{data?.id}</div>,
      paymentMeth_name: <div style={{ width: "120px" }}>{data?.name}</div>,
      paymentMeth_lookup_key: (
        <div style={{ width: "120px" }}>{data?.lookup_key}</div>
      ),
      paymentMeth_source: <div style={{ width: "100px" }}>{data?.source}</div>,
      paymentMeth_enable_dive_trip: (
        <div style={{ width: "100px" }}>
          {" "}
          <div
            className={`${
              data.enable_dive_trip ? "attribute-found" : "attribute-not-found"
            }`}
          >
            {data.enable_dive_trip ? "Yes" : "No"}
          </div>{" "}
        </div>
      ),
      paymentMeth_enable_dive_course: (
        <div style={{ width: "100px" }}>
          {" "}
          <div
            className={`${
              data.enable_dive_course
                ? "attribute-found"
                : "attribute-not-found"
            }`}
          >
            {data.enable_dive_course ? "Yes" : "No"}
          </div>{" "}
        </div>
      ),
      paymentMeth_enable_live_aboard: (
        <div style={{ width: "100px" }}>
          {" "}
          <div
            className={`${
              data.enable_live_aboard
                ? "attribute-found"
                : "attribute-not-found"
            }`}
          >
            {data.enable_live_aboard ? "Yes" : "No"}
          </div>{" "}
        </div>
      ),
      action: userType !== "descendant_user" || (userType === "descendant_user" && roles?.can_read && roles?.can_update)
      ?(
        <Box
          sx={{
            cursor: "pointer",
            fontSize: "16px",
            fontWeight: "500",
            color: "#333132",
            textAlign: "left",
            padding: "0px",
          }}
          onClick={() => {
            setSupportLang("en");
            navigate(`?type=form&id=${data?.id}&index=${index}`);
          }}
        >
          Edit
        </Box>
      ):null,
    };
  });

  const breadcrumbs = [
    {
      active: true,
      path: "/predefined-lookups/currency",
      title: "Predefined lookups",
    },
    {
      active: true,
      path: "/predefined-lookups/payment-methods",
      title: "Payment method",
    },
    {
      active: false,
      title: index === null ? "Add new payment method" : "Edit payment method",
    },
  ];

  // const updatePagination = (page) => {
  //   setFilterObj({ ...filterObj, page_number: page });
  // };
  // const handlePageChange = (event, value) => {
  //   setPage(value);
  //   updatePagination(value);
  // };

  const getLanguages = async () => {
    const { res, err } = await getSupportedLanguages();
    if (res) {
      setSupportedLanguages(res?.data?.supported_locales);
    }
  };
  const updateSupportedLang = (e, languages) => {
    setSupportLang(e.target.value);
  };

  const rowData = (data) => {
    setSelectedRow(data?.rowData);
  };

  const getPaymentMethod = async () => {
    const { res, err } = await getPaymentMethodDetails(paymentMethodId);
    if (res) {
      setPaymentMethodData(res?.data?.payment_method);
    }
    if (err) {
      toast.error(err);
    }
  };

  const getPaymentMethods = async (filterObj) => {
    setOpenLoader(true);
    const defaultFilter = {
      page_size: 10,
      page_number: 1,
      keyword: "",
      sort_by: "id",
    };
    const { res, error } = await getPaymentMethodsList({
      ...defaultFilter,
      ...filterObj,
    });
    if (res) {
      setOpenLoader(false);
      setPaymentMethodsList(res?.data?.payment_methods);
      setCount(res?.extra?.total_count);
    }
    if (error) {
      setOpenLoader(false);
    }
  };

  // removeProperties : to removed unused properties sent in request payload
  const removeProperties = (obj, ...properties) => {
    const result = { ...obj };
    properties.forEach(function (property) {
      delete result[property];
    });
    return result;
  };
  const handleEditPaymentMethod = async (values) => {
    const { res, err } = await editPaymentMethod(paymentMethodId, {
      payment_method: removeProperties(values, "lookup_key", "source"),
    });
    if (res) {
      navigate("/predefined-lookups/payment-methods");
    }
  };
  const handleSubmit = (data, formik) => {
    const action = () => {
      searchParams.delete("id");
      searchParams.delete("type");
      searchParams.delete("index");
      formik.resetForm();
    };
    if (index) {
      handleEditPaymentMethod(data);
      action();
    }
  };

  useEffect(() => {
    if (type == "form") getLanguages();
  }, [type]);

  useEffect(() => {
    paymentMethodId && getPaymentMethod(paymentMethodId);
  }, [paymentMethodId]);

  useEffect(() => {
    if (filterObj) getPaymentMethods(filterObj);
  }, [filterObj]);

  return (
    <>
      <Loader open={openLoader} />
      <PaymentMethodsTemplate
        setFilterObj={setFilterObj}
        data={mappedData}
        count={count}
        paginationPage={page}
        setPaginationPage={setPage}
        handlePageChange={onPageChange}
        supportedLanguages={supportedLanguages}
        updateSupportedLang={updateSupportedLang}
        supportLang={supportLang}
        handleSubmit={handleSubmit}
        rowData={rowData}
        paymentMethodData={paymentMethodData}
        breadcrumbs={breadcrumbs}
      />
    </>
  );
}

export default PaymentMethodsPage;

import { Stack } from "@mui/material";
import {
  FormControl,
  IconButton,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";

const Footer = ({
  pageIndex,
  pageOptions,
  totalPage,
  setPageNumber,
  setPerPage,
  perPage,
}) => {
  return (
    <Stack
      direction={{ xs: "column", md: "row" }}
      bgcolor="transparent"
      alignItems="center"
      justifyContent="space-between"
      p={2}
    >
      <Stack
        direction="row"
        alignItems="center"
        className="page-items-select"
        width={{ xs: "100%", sm: "100%", md: "35%", lg: "32%", xl: "27%" }}
      >
        <FormControl
          className="page-items-select"
          sx={{ display: "flex", flexDirection: "row" }}
        >
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={perPage}
            onChange={(e) => setPerPage(e.target.value)}
            sx={{
              background: "white",
              padding: "0",
              height: "23px",
              fontSize: "14px",
              display: "flex",
            }}
          >
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={20}>20</MenuItem>
            <MenuItem value={30}>30</MenuItem>
          </Select>
          <Typography color="#A6A8B1" mx="5px">
            Items per page
          </Typography>
        </FormControl>

        <Typography mx={2}>
          {pageIndex} of {pageOptions.length} items
        </Typography>
      </Stack>

      <Stack
        direction="row"
        alignItems="center"
        width={{ xs: "100%", sm: "100%", md: "28%", lg: "24%", xl: "20%" }}
        justifyContent="space-between"
      >
        <FormControl className="page-items-select">
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={pageIndex}
            sx={{
              background: "white",
              padding: "0",
              height: "23px",
              fontSize: "14px",
            }}
          >
            {[...Array(pageOptions?.length)].map((data, index) => {
              return (
                <MenuItem
                  value={index + 1}
                  key={index}
                  onClick={() => setPageNumber(index + 1)}
                >
                  {index + 1}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <Typography color="#A6A8B1">of {pageOptions.length} pages</Typography>
        <IconButton
          aria-label="previousPage"
          onClick={() => {
            setPageNumber((s) => (s === 1 ? 1 : s - 1));
          }}
          disabled={pageIndex === 1}
        >
          <KeyboardArrowLeftIcon />
        </IconButton>
        <span>{pageIndex}</span>
        <IconButton
          aria-label="nextPage"
          onClick={() => {
            setPageNumber((s) => s + 1);
          }}
          disabled={pageIndex === totalPage}
        >
          <KeyboardArrowRightIcon />
        </IconButton>
      </Stack>
    </Stack>
  );
};

export default Footer;

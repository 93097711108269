import React, { useEffect, useState } from "react";
import Loader from "../../../../Components/Atoms/Loader/Loader";
import { useSearchParams } from "react-router-dom";

import CustomerLevelsTemplate from "../../../../Templates/Main/Levels/Customer/Customer.template";
import {
  getCustomerLevelsDetails,
  getCustomerLevelsList,
} from "../../../../Contexts/APIs/Levels/Customer/customer";

function CustomerLevelsPage({roles}) {
  let [searchParams] = useSearchParams();
  const id = searchParams.get("id");

  const [openLoader, setOpenLoader] = useState(false);
  const [customersLevelsList, setCustomersLevelsList] = useState([]);

  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);

  const [filterObj, setFilterObj] = useState(null);

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [customersLevelsData, setCustomersLevelsData] = useState({});

  const updatePagination = (page) => {
    setFilterObj({ ...filterObj, page_number: page });
  };

  const handlePageChange = (event, value) => {
    setPage(value);
    updatePagination(value);
  };

  const getCustomerLevels = async (filterObj) => {
    setOpenLoader(true);
    const defaultFilterObj = {
      page_size: 10,
      page_number: 1,
      keyword: "",
      sort_by: "id",
    };
    const { res, error } = await getCustomerLevelsList({
      ...defaultFilterObj,
      ...filterObj,
    });
    if (res) {
      setOpenLoader(false);
      setCustomersLevelsList(res?.data?.levels);
      setCount(res?.extra?.total_count);
    }
    if (error) {
      setOpenLoader(false);
    }
  };
  const getgetCustomerLevelsData = async () => {
    setOpenLoader(true);
    const { res, error } = await getCustomerLevelsDetails(id);
    if (res) {
      setCustomersLevelsData(res.data.level);
      setOpenLoader(false);
    }
    if (error) {
      setOpenLoader(false);
    }
  };

  useEffect(() => {
    if (filterObj) getCustomerLevels(filterObj);
  }, [filterObj]);

  useEffect(() => {
    id && getgetCustomerLevelsData({});
  }, [id]);

  const rowData = (data) => {
    setSelectedRow(data?.rowData);
  };

  const breadcrumbs = [
    {
      active: true,
      path: "/levels/customer",
      title: "Customer levels",
    },
    {
      active: false,
      title: id ? "Edit customer level" : "Add new customer level",
    },
  ];
  const mappedData = customersLevelsList?.map((data, index) => {
    return {
      ...data,
      id_data: <div style={{ width: "80px" }}>{data?.id}</div>,
      level_data: <div style={{ width: "200px" }}>{data?.name}</div>,
      rank_data: <div style={{ width: "200px" }}>{data?.rank}</div>,

      reservations_required_data: (
        <div style={{ width: "200px" }}>{data?.reservations_required}</div>
      ),
    };
  });
  return (
    <>
      <Loader open={openLoader} />
      <CustomerLevelsTemplate
        filterObj={filterObj}
        setFilterObj={setFilterObj}
        data={mappedData}
        count={count}
        paginationPage={page}
        setPaginationPage={setPage}
        handlePageChange={handlePageChange}
        openDeleteModal={openDeleteModal}
        setOpenDeleteModal={setOpenDeleteModal}
        rowData={rowData}
        customersLevelsData={customersLevelsData}
        breadcrumbs={breadcrumbs}
        roles={roles}
      />
    </>
  );
}

export default CustomerLevelsPage;

import * as Yup from "yup";
import { for_data, is_hidden } from "./filter_data";
export const initialValues = (data) => ({
  ...data,
  ...data?.localized_data,
});
export const structure = (lang) => [
  {
    head: "Frequently asked question information",
    list: [
      {
        name: `for`,
        kind: "select",
        type: "number",
        label: "For",
        width: "100%",
        options: for_data,
      },
      {
        name: `${lang}.question`,
        kind: "input",
        type: "text",
        label: "Write question",
        width: "100%",
      },
      {
        name: `${lang}.answer`,
        kind: "textArea",
        type: "text",
        placeholder: "Write Answer",
        width: "100%",
        rows: 4,
      },
      {
        name: `is_hidden`,
        kind: "checkbox",
        type: "number",
        label: "Is Hidden",
        width: "100%",
      },
    ],
  },
];

export const validationSchema = () =>
  Yup.object({
    en: Yup.object().shape({
      question: Yup.string().required("required"),
      answer: Yup.string().required("required"),
    }),
    for: Yup.string().required("required"),
    is_hidden: Yup.bool().required().default(false),
  });

import { Avatar, Box, Button, Divider, Grid, Stack, Typography } from "@mui/material";
import PrimaryButton from "../../../Components/Atoms/Buttons/Primary/PrimaryButton";
import { icons } from "../../../Assets/AssetHelper";
import { NavLink, useNavigate } from "react-router-dom";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import ClearIcon from "@mui/icons-material/Clear";
import { LogoutAPI } from "../../../Contexts/APIs/Auth/Logout";
import { toast } from "react-toastify";
import PhoneInput from "react-phone-input-2";
import Cookies from "js-cookie";
import { Firebase } from "../../../Firebase/Firebase";

const AccountDataTemplate = ({ t, profileData, setOpen }) => {
  const lang = localStorage.getItem("lang");
  const userType = localStorage.getItem("userType");
  const navigate = useNavigate();
  const Logout = async () => {
    const { res, err } = await LogoutAPI();
    localStorage.clear();
    Cookies.remove("firebase_auth_token");
    localStorage.removeItem("firebase_auth_token");
    Firebase.SignoutFirebase();
    window.location.reload();
  };
  return (
    <Grid>
      <Typography fontSize="18px" color="#bebebe">
        Profile
      </Typography>
      <Grid container className="activity-form" flexDirection="column" gap={2}>
        {/* main data */}
        <Stack width="100%" flexDirection="row" alignItems="center" justifyContent="space-between">

          <Stack flexDirection="row" gap={2}>
            <Avatar
              src={profileData?.logo || profileData?.avatar}
              style={{ width: "82px", height: "82px", borderRadius: "10px" }}
            />
          </Stack>

          <Stack flexDirection="row" gap={2}>

          <Stack flexDirection={"row"} alignItems={'center'} gap={2}>
            <Button 
              style={{
                backgroundColor: "#F9F9F9",
                borderRadius: '8px',
                border: "1px solid #ECECEC",
                height: "41px",
                maxWidth: "22px",
                minWidth: '41px'
              }}
              onClick={() => {
                navigate("/profile/change-password")
              }}
            >
              <img src={icons.PadLock} />
            </Button>

            <Typography
              fontSize="18px"
              color="#333132"
              fontWeight={700}
              onClick={() => {
                navigate("/profile/change-password")
              }}
              sx={{
                cursor: 'pointer',
                textDecoration: 'none',
                '&:hover': {
                  textDecoration: 'underline'
                }
              }}
            >
              Change password
            </Typography>
          </Stack>

            <Button
              onClick={Logout}
              style={{
                textTransform: "capitalize",
                height: "52px",
                fontSize: "14px",
                width: "123px",
                color: "#e10007",
                border: "1px solid #e10007",
                borderRadius: "26px",
              }}
            >
              <img src={icons.LogoutIcon} /> <span style={{ marginInline: "3px" }}> Logout</span>
            </Button>
          </Stack>

        </Stack>
        <Divider style={{ marginBlock: "20px", width: "100%", background: "#d9d9d9" }} />
        <Stack justifyContent="space-between" flexDirection="row">
          <Box bgcolor="#fbfbfb" borderRadius="20px" p={2} width="55%" height="100%">
            <Typography
              width="fit-content"
              className="mb-20px"
              component="h1"
              sx={{
                fontWeight: "bolder",
                borderBottom: "2px solid var(--primary-color)",
              }}
            >
              User information
            </Typography>
            <Grid container>
              <Grid item xs={4}>
                <Typography sx={{ fontSize: "13px", color: "#aaa" }}>Full name</Typography>
                <Typography
                  sx={{
                    fontSize: "18px",
                    color: "#333132",
                    fontWeight: "bold",
                  }}
                >
                  {profileData?.name}
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography sx={{ fontSize: "13px", color: "#aaa" }}>Email address</Typography>
                <Typography
                  sx={{
                    fontSize: "18px",
                    color: "#333132",
                    fontWeight: "bold",
                  }}
                >
                  {profileData?.email}
                </Typography>
              </Grid>
              <Grid item xs={12} mt={2.5}>
                <Typography sx={{ fontSize: "13px", color: "#aaa" }}>Mobile</Typography>
                <Typography
                  sx={{
                    fontSize: "18px",
                    color: "#333132",
                    fontWeight: "bold",
                  }}
                >
                  {/* {profileData?.phone} */}
                  {profileData?.phone_number ? (
                    <PhoneInput
                      inputStyle={{
                        border: "none",
                        width: "100%",
                        padding: "0px",
                        direction: "ltr",
                        fontSize: "18px",
                        color: "#333132",
                        backgroundColor: "#fbfbfb",
                        wordBreak: "break-word",
                        fontWeight: "700",
                      }}
                      defaultMask={
                        parseInt(profileData.country_code) == 20
                          ? "... ... ...."
                          : parseInt(profileData.country_code) == 93
                          ? ".. ... ...."
                          : null
                      }
                      value={`${profileData.country_code} ${profileData?.phone_number}`}
                      disabled={true}
                      disableDropdown={true}
                      specialLabel=""
                      placeholder=""
                    />
                  ) : null}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default AccountDataTemplate;

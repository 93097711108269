import { axiosFn, handleError } from "..";
import { makeFilterString } from "../Helper/Helper";
import * as URL from "../URLs";

export async function getPaymentMethodsList(filter_obj) {
    return await axiosFn("get", URL.payment_methods + makeFilterString(filter_obj))
      .then((res) => {        
        return {
          res: res.data,
          err: null,
        };
      })
      .catch(handleError);
}

export async function editPaymentMethod(id , data) {
  return await axiosFn("put", `${URL.payment_methods}/${id}`,data)
      .then((res) => {
          return {
              res: res.data,
              err: null,
          };
      })
      .catch(handleError);
}

export async function getPaymentMethodDetails(PaymentMethod_id) {
  return await axiosFn("get", `${URL.payment_methods}/${PaymentMethod_id}`)
      .then((res) => {
          return {
              res: res.data,
              err: null,
          };
      })
      .catch(handleError);
}


import { Grid, IconButton, Stack, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import ClearIcon from "@mui/icons-material/Clear";

import "./DatePicker.css";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

export default function BasicDatePicker({
  inputFormat = "DD/MM/YYYY",
  value,
  setValue,
  handleChange,
  handleClear,
  placeholder,
  dateMsgSelect,
  className,
  name,
  id,
  redBorder,
  setFilterObj,
  createLis,
}) {
  const handleDateChange = (newDate) => {
    if (setValue) {
      setValue(newDate);
    }
    if (handleChange) {
      handleChange(newDate);
    }
  };
  const handleClearDate = () => {
    if (handleClear) handleClear()
    if (setValue) setValue(null)
  }
  return (
    // <Grid className={className}>
    <Grid
      className={className}
      style={{
        position: "relative",
        display: "inline-block",
        height: "100%",
      }}
    >
      <DatePicker
        name={name}
        id={id}
        value={value}
        // onChange={handleChange}
        onAccept={handleDateChange}
        // renderInput={(params) => <TextField {...params} />}
        label={
          typeof value === "undefined" && createLis === true
            ? dateMsgSelect
            : placeholder
        }
        format={inputFormat}
        // className={`${typeof value === "undefined" && createLis === true && redBorder
        //   }`}
        openTo={"year"}
        views={["year", "month", "day"]}
      />
      {value && (
        <IconButton
          style={{
            position: "absolute",
            margin: "auto 5px",
            right: "2rem",
            top: "calc(50% - 11px)",  // Adjusted this line
            padding: "0px"
          }}
          onClick={handleClearDate}
        >
          <ClearIcon sx={{ color: "rgba(0, 0, 0, 0.4)" }} />
        </IconButton>
      )}
    </Grid>
  );
}

import Pagination from '@mui/material/Pagination'

import './Pagination.css'
import { useSearchParams } from 'react-router-dom';
import { useState } from 'react';
import { handlePageChange } from '../../../Contexts/APIs/Helper/Helper';

export default function CustomPagination({
  count,
  size,
  shape,
  page,
  handleChange,
  className
}) {
  // const [searchParams, setSearchParams] = useSearchParams();
  // const pageNumber = searchParams.get("page_number") || 1;

  // let searchParams = new URLSearchParams(window.location.search);

  // const handlePageChange = (e, newPage) => {
  //   handleChange(newPage); // Update the state immediately
  //   // Update the search parameters without reloading the page
  //   const newSearchParams = new URLSearchParams(searchParams);
  //   newSearchParams.set("page_number", newPage);
  //   // Update the URL using history.pushState
  //   const newUrl = `${window.location.pathname}?${newSearchParams.toString()}`;
  //   if (window.history.pushState) {
  //     window.history.pushState({ path: newUrl }, "", newUrl);
  //   }
  // };

  return (
    <Pagination
      count={count}
      size={size}
      className={className}
      sx={{
        ".MuiPaginationItem-previousNext": {
          color: '#f6b119'
        },
        ".MuiPaginationItem-page": {
          bgcolor: '#f2f5f7'
        },
        ".Mui-selected": {
          color: 'white !important',
          bgcolor: '#f6b119 !important'
        }
      }}
      shape={shape}
      page={page}
      onChange={(e, page) => {
        handlePageChange(page, handleChange)
      }}
    />
  );
}
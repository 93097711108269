import { Box, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import CustomSeparator from "../../Atoms/BreadCrumb";
import FreelancerDetailsHeader from "../../Molecules/Freelancers/FreelancerDetailsHeader";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import CheckIcon from "@mui/icons-material/Check";
import FormHead from "../../Atoms/FormHead/FormHead";

import Carousel from "../../Atoms/Carousel/Carousel";
import "./FreelancerDetails.css";
import FormHeadIcon from "../../Atoms/FormHead/FormHeadIcon";
import moment from "moment";
import PhoneInput from "react-phone-input-2";

const FreelancerDetails = ({ applicantData, getFreelancer, roles }) => {
  const [countryCode, setCountryCode] = useState(null);
  const info = [
    // {
    //   title: "Safari experience",
    //   status: applicantData?.safari_experience ? true : false,
    // },
    {
      title: "Liveaboard work experience",
      status: applicantData?.live_aboard_work_experience ? true : false,
    },
    {
      title: "Volunteer work",
      status: applicantData?.volunteer_work_participant,
    },
    // { title: "is verified", status: applicantData?.is_verified ? true : false },
  ];
  const certificatesResponsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
      partialVisibilityGutter: 10,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      partialVisibilityGutter: 10,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      partialVisibilityGutter: 10,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      partialVisibilityGutter: 20,
    },
  };
  const certificates = applicantData?.certificates;
  const localGovLicense = applicantData?.local_gov_license_media;
  const divingInsuranceMedia = applicantData?.diving_insurance_media;
  const { freelancerId } = useParams();

  const locationsResponsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 4,
      partialVisibilityGutter: 10,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      partialVisibilityGutter: 60,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      partialVisibilityGutter: 10,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const created = applicantData?.created_at
      ? moment(applicantData?.created_at).format("DD-MM-YYYY HH:mm")
      : "N/A",
    updated = applicantData?.updated_at
      ? moment(applicantData?.updated_at).format("DD-MM-YYYY HH:mm")
      : "N/A";

  const breadcrumbs = [
    { path: "/freelancers", active: true, title: "Freelancers" },

    {
      active: false,
      title: applicantData?.name,
    },
  ];

  useEffect(() => {
    setTimeout(() => {
      setCountryCode(applicantData.country_code);
    }, 1000);
  }, [applicantData]);

  return (
    <Stack gap={3}>
      <Grid container item sm={12}>
        <CustomSeparator breadcrumbs={breadcrumbs} />
      </Grid>

      <FreelancerDetailsHeader
        data={applicantData}
        getFreelancer={getFreelancer}
        link={`/freelancers/${freelancerId}/reviews`}
        roles={roles}
      />

      <Stack
        mt="-25px"
        bgcoloe="white"
        padding="53.8px"
        className="details-panel"
        flexDirection="row"
        justifyContent="space-between"
        gap="1rem"
        boxShadow="0 2px 15px 0 rgba(0, 0, 0, 0.1)"
        overflow="auto"
      >
        <Stack gap={2} width="70%">
          <Stack bgcolor="rgba(0, 0, 0, 0.01)" borderRadius="10px" p={2}>
            <FormHead text={`${applicantData?.name} info`} />
            <Box position="relative">
              <Grid container gap={1}>
                <Grid item md={5}>
                  <Stack flexDirection="column" gap={1}>
                    <Typography fontSize="13px" color="#aaa">
                      Id number
                    </Typography>
                    <Typography className="details-text-bold">
                      {applicantData?.id}
                    </Typography>
                  </Stack>
                  <Stack flexDirection="column" gap={1}>
                    <Typography fontSize="13px" color="#aaa">
                      Freelancer Mobile
                    </Typography>
                    <Typography className="details-text-bold details-phone">
                      {applicantData?.phone_number ? (
                        <PhoneInput
                          inputStyle={{
                            border: "none",
                            width: "100%",
                            padding: "0px",
                            direction: "ltr",
                            fontSize: "18px",
                            color: "#282828",
                            backgroundColor: "#f9f9f9",
                            wordBreak: "break-word",
                            fontWeight: "bold",
                          }}
                          defaultMask={`${
                            countryCode === "" ? "..........." : "... ... ...."
                          }`}
                          value={`${
                            countryCode === ""
                              ? "  "
                              : applicantData.country_code
                          }${applicantData.phone_number}`}
                          disabled={true}
                          disableDropdown={true}
                          specialLabel=""
                          placeholder=""
                          disableCountryCode={countryCode === "" ? true : false}
                        />
                      ) : (
                        "N/A"
                      )}
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item md={6}>
                  <Stack flexDirection="column" gap={1}>
                    <Typography fontSize="13px" color="#aaa">
                      Freelancer Email
                    </Typography>
                    <Typography className="details-text-bold">
                      {applicantData?.email}
                    </Typography>
                  </Stack>
                </Grid>
              </Grid>
            </Box>
          </Stack>

          {applicantData?.work_locations?.length >= 1 ? (
            <Stack
              bgcolor="rgba(0, 0, 0, 0.01)"
              borderRadius="10px"
              p={2}
              className="freelancer-carousel"
            >
              <FormHead text={"Work locations"} />

              {/* <Stack flexDirection='row' alignItems='center' gap={2} className='details-location'>
                              <Box position='relative'>
                                  <img src={applicantData?.area?.logo} width='208px' height='149px' style={{ borderRadius: '15px' }} />
                                  <Stack alignItems='center' justifyContent='center' position='absolute' top='20px' left='54px' bgcolor='#faa100' borderRadius='15px' color='white' fontSize='12px' p='5px 10px'>
                                      Current location
                                  </Stack>
                                  <Stack alignItems='center' justifyContent='center' width='208px' position='absolute' bottom='20px'>
                                      <Typography fontSize='20px' letterSpacing='2px' color='white' fontWeight='bold' sx={{ textShadow: '0 2px 5px #00000033' }}>{applicantData?.city?.name}</Typography>
                                  </Stack>
                              </Box>
                              <Typography fontSize='18px' letterSpacing='2px' color='#333132' fontWeight='bold'>{applicantData?.area?.name} / {applicantData?.city?.name} / {applicantData?.country?.name}</Typography>
                          </Stack>

                          <Carousel
                              swipeable={true}
                              draggable={true}
                              showDots={false}
                              responsive={locationsResponsive}
                              autoPlaySpeed={1000}
                              containerClass={'carousel-location'}
                              itemClass={'carousel-location-item'}
                              partialVisible={true}
                              arrows={false}
                          >
                              {
                                  [applicantData, applicantData, applicantData, applicantData, applicantData].map((data ,index) => {
                                      return <Stack key={index} flexDirection='row' alignItems='center' gap={2} width='208px'>
                                          <Box position='relative' width='100%' height='100%'>
                                              <img src={data?.area?.logo} width='100%' height='100%' style={{ borderRadius: '15px' }} />
                                              <Stack alignItems='center' justifyContent='center' position='absolute' top='20px' left='54px' bgcolor='#faa100' borderRadius='15px' color='white' fontSize='12px' p='5px 10px'>
                                                  Current location
                                              </Stack>
                                              <Stack alignItems='center' justifyContent='center' width='100%' position='absolute' bottom='20px'>
                                                  <Typography fontSize='20px' letterSpacing='2px' color='white' fontWeight='bold' sx={{ textShadow: '0 2px 5px #00000033' }}>{data?.city?.name}</Typography>
                                              </Stack>
                                          </Box>
                                      </Stack>
                                  })
                              }
                          </Carousel> */}

              {applicantData?.work_locations?.length > 1 ? (
                <Carousel
                  swipeable={true}
                  draggable={true}
                  showDots={false}
                  responsive={locationsResponsive}
                  autoPlaySpeed={1000}
                  containerClass={"carousel-location"}
                  itemClass={"carousel-location-item"}
                  partialVisible={true}
                  arrows={false}
                >
                  {applicantData?.work_locations?.map((data, index) => {
                    return (
                      <Stack
                        key={index}
                        flexDirection="row"
                        alignItems="center"
                        gap={2}
                        width="208px"
                      >
                        <Box position="relative" width="100%" height="100%">
                          <img
                            src={data?.area?.logo}
                            width="100%"
                            height="100%"
                            style={{ borderRadius: "15px" }}
                          />
                          <Stack
                            alignItems="center"
                            justifyContent="center"
                            width="100%"
                            position="absolute"
                            bottom="20px"
                          >
                            <Typography
                              fontSize="20px"
                              letterSpacing="2px"
                              color="white"
                              fontWeight="bold"
                              sx={{ textShadow: "0 2px 5px #00000033" }}
                            >
                              {data?.area?.city?.name}
                            </Typography>
                          </Stack>
                        </Box>
                      </Stack>
                    );
                  })}
                </Carousel>
              ) : applicantData?.work_locations?.length === 1 ? (
                <Stack flexDirection="row" alignItems="center" gap={2}>
                  <Box position="relative">
                    <img
                      src={applicantData?.work_locations[0].area?.logo}
                      width="208px"
                      height="149px"
                      style={{ borderRadius: "15px" }}
                    />
                    <Stack
                      alignItems="center"
                      justifyContent="center"
                      width="208px"
                      position="absolute"
                      bottom="20px"
                    >
                      <Typography
                        fontSize="20px"
                        letterSpacing="2px"
                        color="white"
                        fontWeight="bold"
                        sx={{ textShadow: "0 2px 5px #00000033" }}
                      >
                        {applicantData?.work_locations[0].area?.city?.name}
                      </Typography>
                    </Stack>
                  </Box>
                  <Typography
                    fontSize="18px"
                    letterSpacing="2px"
                    color="#333132"
                    fontWeight="bold"
                  >
                    {applicantData?.work_locations[0].area?.name} /{" "}
                    {applicantData?.work_locations[0].area?.city?.name} /{" "}
                    {applicantData?.work_locations[0].area?.city?.country?.name}
                  </Typography>
                </Stack>
              ) : null}
            </Stack>
          ) : null}

          <Stack
            bgcolor="rgba(0, 0, 0, 0.01)"
            borderRadius="10px"
            p={2}
            className="freelancer-carousel"
          >
            <FormHead text={"Work certificates"} />
            {certificates?.length > 1 ? (
              <Carousel
                swipeable={true}
                draggable={true}
                showDots={false}
                responsive={certificatesResponsive}
                autoPlaySpeed={1000}
                containerClass={"carousel-certificate"}
                itemClass={"carousel-certificate-item"}
                partialVisible={true}
                arrows={false}
              >
                {certificates.map((data, index) => {
                  return (
                    <Stack
                      key={index}
                      alignItems="center"
                      justifyContent="center"
                      borderRadius="10px"
                    >
                      <img
                        src={data?.image}
                        width="100%"
                        height="124px"
                        style={{ borderRadius: "15px" }}
                      />
                      <Typography
                        color="#333132"
                        fontSize="14px"
                        fontWeight="bold"
                        mt="5px"
                      >
                        {data?.organization_certificate?.name}
                      </Typography>
                    </Stack>
                  );
                })}
              </Carousel>
            ) : certificates?.length === 1 ? (
              <Stack flexDirection="row" gap={1}>
                <Stack
                  alignItems="center"
                  justifyContent="center"
                  borderRadius="10px"
                >
                  <img
                    src={certificates[0]?.image}
                    width="178px"
                    height="124px"
                    style={{ borderRadius: "15px" }}
                    alt="certificates"
                  />
                  <Typography
                    color="#333132"
                    fontSize="14px"
                    fontWeight="bold"
                    mt="5px"
                  >
                    {certificates[0]?.organization_certificate?.name}
                  </Typography>
                </Stack>
              </Stack>
            ) : (
              <Typography className="details-text-bold"> N/A </Typography>
            )}
          </Stack>
          <Stack
            bgcolor="rgba(0, 0, 0, 0.01)"
            borderRadius="10px"
            p={2}
            className="freelancer-carousel"
          >
            <FormHead text={"local government"} />
            {localGovLicense?.map((license) => (
              <Stack flexDirection="row" gap={1}>
                <Stack
                  alignItems="center"
                  justifyContent="center"
                  borderRadius="10px"
                >
                  <img
                    src={license?.url}
                    width="178px"
                    height="124px"
                    style={{ borderRadius: "15px" }}
                    alt="local gov license"
                  />
                </Stack>
              </Stack>
            ))}
          </Stack>
          <Stack
            bgcolor="rgba(0, 0, 0, 0.01)"
            borderRadius="10px"
            p={2}
            className="freelancer-carousel"
          >
            <FormHead text={"Diving insurance"} />
            {divingInsuranceMedia?.map((license) => (
              <Stack flexDirection="row" gap={1}>
                <Stack
                  alignItems="center"
                  justifyContent="center"
                  borderRadius="10px"
                >
                  <img
                    src={license?.url}
                    width="178px"
                    height="124px"
                    style={{ borderRadius: "15px" }}
                    alt="local gov license"
                  />
                </Stack>
              </Stack>
            ))}
          </Stack>

          <Stack bgcolor="rgba(0, 0, 0, 0.01)" borderRadius="10px" p={2}>
            {applicantData?.about ? (
              <Stack>
                <FormHeadIcon text={"About"}>
                  <CheckOutlinedIcon
                    fontSize="1.1rem"
                    style={{ color: "#f6b119" }}
                  />
                </FormHeadIcon>
                <Typography className="details-text-bold">
                  {applicantData?.about}
                </Typography>
              </Stack>
            ) : (
              <Stack>
                <FormHeadIcon text={"About"}>
                  <CloseOutlinedIcon
                    fontSize="1.1rem"
                    style={{ color: "#e10007" }}
                  />
                </FormHeadIcon>
                <Typography className="details-text-bold" color="#e10007">
                  No data available
                </Typography>
              </Stack>
            )}
          </Stack>

          <Stack
            flexDirection="row"
            gap="1rem 3rem"
            padding="1rem 0rem 2rem"
            className="details-time details-time-lg"
          >
            <Stack flexDirection="row" gap={1}>
              <Typography
                color="#333132"
                className="details-text-bold"
              >{`Created at : ${created}`}</Typography>
            </Stack>
            <Stack flexDirection="row" gap={1}>
              <Typography
                color="#333132"
                className="details-text-bold"
              >{`Updated at : ${updated}`}</Typography>
            </Stack>
          </Stack>
        </Stack>

        <Stack gap={2} width="30%">
          <Stack bgcolor="rgba(0, 0, 0, 0.01)" borderRadius="10px" p={2}>
            <FormHead text={"Language"} />
            <Grid container gap={1}>
              {applicantData?.languages?.length >= 1 ? (
                applicantData?.languages?.map((data, index) => {
                  return (
                    <Grid item md={5.5} key={index}>
                      <Stack flexDirection="row" gap={1}>
                        <Typography
                          color="#333132"
                          className="details-text-bold"
                        >
                          {data?.language?.name}
                        </Typography>
                        <Typography color="#bebebe" className="details-text">
                          {data?.language_fluency_level?.name}
                        </Typography>
                      </Stack>
                    </Grid>
                  );
                })
              ) : (
                <Typography className="details-text-bold"> N/A </Typography>
              )}
            </Grid>
          </Stack>
          <Stack bgcolor="rgba(0, 0, 0, 0.01)" borderRadius="10px" p={2}>
            <FormHead text={"Information"} />

            <Stack flexDirection="row" gap={1} mb={2}>
              <Typography color="#333132" className="details-text">
                Diving insurance no
              </Typography>
              <Typography color="#333132" className="details-text">
                {applicantData?.diving_insurance_number}
              </Typography>
            </Stack>
            <Stack flexDirection="row" gap={1} mb={2}>
              <Typography color="#333132" className="details-text">
                Local government license no
              </Typography>
              <Typography color="#333132" className="details-text">
                {applicantData?.local_gov_license_number}
              </Typography>
            </Stack>
            <Stack flexDirection="row" gap={1} mb={2}>
              <Typography color="#333132" className="details-text">
                Technical certificate
              </Typography>
              {/* <Typography color='#bebebe'>{applicantData?.}</Typography> */}
              <Typography color="#bebebe" className="details-text">
                Certificate name
              </Typography>
            </Stack>
            <Stack flexDirection="row" gap={1} mb={2}>
              <Typography color="#333132" className="details-text">
                Invitation code :{" "}
              </Typography>
              <Typography
                color="#333132"
                className="details-text"
                style={{ overflowWrap: "anywhere" }}
              >
                {applicantData?.invitation_code}
              </Typography>
            </Stack>
            <Stack flexDirection="row" gap={1} mb={2}>
              <Typography color="#333132" className="details-text">
                Invitation link :{" "}
              </Typography>
              <Typography color="#333132" className="details-text">
                {applicantData?.invitation_link
                  ? applicantData?.invitation_link
                  : "Empty"}
              </Typography>
            </Stack>
            <Stack flexDirection="row" gap={1} mb={2}>
              <Typography color="#333132" className="details-text">
                Currency
              </Typography>
              <Typography color="#bebebe" className="details-text">
                {applicantData?.currency?.name ? (
                  applicantData?.currency?.name
                ) : (
                  <Typography className="details-text-bold"> N/A </Typography>
                )}
              </Typography>
            </Stack>

            {info?.map((data, index) => {
              return (
                <Stack
                  flexDirection="row"
                  gap={1}
                  my={1}
                  key={index}
                  className="details-info"
                >
                  {data?.status ? (
                    <CheckIcon fontSize="1.1rem" style={{ color: "#f6b119" }} />
                  ) : (
                    <CloseOutlinedIcon
                      fontSize="1.1rem"
                      style={{ color: "#e10007" }}
                    />
                  )}
                  <Typography className="details-text">
                    {data?.title}
                  </Typography>
                </Stack>
              );
            })}
          </Stack>

          <Stack
            flexDirection="row"
            gap="1rem 3rem"
            padding="1rem 0rem 2rem"
            className="details-time details-time-sm"
          >
            <Stack flexDirection="row" gap={1}>
              <Typography
                color="#333132"
                className="details-text-bold"
              >{`Created at : ${created}`}</Typography>
            </Stack>
            <Stack flexDirection="row" gap={1}>
              <Typography
                color="#333132"
                className="details-text-bold"
              >{`Updated at : ${updated}`}</Typography>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default FreelancerDetails;

import { Box, Grid, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import CustomSeparator from "../../../Atoms/BreadCrumb";
import FormHead from "../../../Atoms/FormHead/FormHead";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import CheckIcon from "@mui/icons-material/Check";
import Carousel from "../../../Atoms/Carousel/Carousel";
import Map from "../../../Molecules/Maps/GoogleMap";
import "./ActivityDetails.css";
import { icons } from "../../../../Assets/AssetHelper";
import ActivityDetailsHeader from "../../../Molecules/Activities/ActivityDetailsHeader/ActivityDetailsHeader";
import LanguagesSelect from "../../../Molecules/Forms/LanguagesSelect";
import moment from "moment";

function ActivityDetails(props) {
  const { ActivityData, supportedLanguages, updateSupportedLang, supportLang } =
    props;

  const created = ActivityData?.created_at
      ? moment(ActivityData?.created_at).format("DD-MM-YYYY HH:MM")
      : "N/A",
    updated = ActivityData?.updated_at
      ? moment(ActivityData?.updated_at).format("DD-MM-YYYY HH:MM")
      : "N/A";

  const ImagesResponsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 7,
      partialVisibilityGutter: 50,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
      partialVisibilityGutter: 20,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
      partialVisibilityGutter: 20,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      partialVisibilityGutter: 20,
    },
  };

  const [location, setLocation] = useState({
    lat: "",
    lng: "",
  });
  const getCurrentLocation = (lat, lng) => {
    setLocation({
      lat: lat,
      lng: lng,
    });
  };

  const info = [
    {
      title: "Instant confirmation",
      status:
        ActivityData?.confirmation_option === "instant_confirmation"
          ? true
          : false,
    },
    {
      title: "Free cancelation",
      status:
        ActivityData?.cancellation_option === "free_cancellation"
          ? true
          : false,
    },
    {
      title: "Hotel pickup",
      status: ActivityData?.pickup_option === "hotel_pickup" ? true : false,
    },
    {
      title: `Max. ${
        ActivityData?.max_no_of_students !== 0
          ? ActivityData?.max_no_of_students
          : null
      } students/instructors`,
      status: ActivityData?.max_no_of_students !== 0 ? true : false,
    },
    { title: `${ActivityData?.languages}`, status: true },
  ];
  const breadcrumbs = [
    { path: "/operators/main", active: true, title: "Operators" },
    {
      path: `/operators/activities`,
      active: true,
      title: `Dive activites`,
    },
    {
      active: false,
      title: ActivityData?.localized_data
        ? ActivityData?.localized_data[`${supportLang}`]["name"]
        : ActivityData?.name,
    },
  ];
  return (
    <Stack gap={3}>
      <Grid container item sm={12}>
        <CustomSeparator breadcrumbs={breadcrumbs} />
      </Grid>

      <Grid container item sm={12} className="select-lang-wrap">
        <LanguagesSelect
          value={supportLang}
          supportedLanguages={supportedLanguages}
          onChange={(e) => updateSupportedLang(e, supportedLanguages)}
          LanguagesSelectHead={"Data Language"}
          LanguagesSelectTip={false}
        />
      </Grid>

      <ActivityDetailsHeader data={ActivityData} supportLang={supportLang} />

      <Stack
        mt="-25px"
        bgcoloe="white"
        padding="53.8px"
        className="details-panel"
        flexDirection="row"
        justifyContent="space-between"
        gap="1rem"
        boxShadow="0 2px 15px 0 rgba(0, 0, 0, 0.1)"
        overflow="auto"
      >
        <Stack gap={2} width={{ xs: "100%", md: "65%" }}>
          <Stack bgcolor="rgba(0, 0, 0, 0.01)" borderRadius="10px" p={2}>
            <FormHead text={"Activity images"} />

            {ActivityData?.media?.length >= 1 ? (
              <Carousel
                swipeable={true}
                draggable={true}
                showDots={false}
                responsive={ImagesResponsive}
                autoPlaySpeed={1000}
                containerClass={"carousel-activity"}
                itemClass={"carousel-activity-item"}
                partialVisible={true}
                arrows={false}
              >
                {ActivityData?.media?.map((data, index) => {
                  return (
                    <Box
                      position="relative"
                      width="100%"
                      height="100%"
                      key={index}
                    >
                      <img
                        src={data?.url}
                        width="100%"
                        height="100%"
                        style={{ borderRadius: "15px" }}
                        draggable={false}
                      />
                    </Box>
                  );
                })}
              </Carousel>
            ) : null}
          </Stack>

          <Stack bgcolor="rgba(0, 0, 0, 0.01)" borderRadius="10px" p={2}>
            <FormHead text={"Package description"} />

            <Typography className="details-text-bold">
              {ActivityData?.localized_data
                ? ActivityData?.localized_data[`${supportLang}`]["description"]
                : ActivityData?.description}
            </Typography>
          </Stack>

          <Stack bgcolor="rgba(0, 0, 0, 0.01)" borderRadius="10px" p={2}>
            <FormHead text={"What to expect"} />

            <Typography className="details-text-bold">
              {ActivityData?.localized_data
                ? ActivityData?.localized_data[`${supportLang}`][
                    "client_expectation"
                  ]
                : ActivityData?.client_expectation}
            </Typography>
          </Stack>

          <Stack bgcolor="rgba(0, 0, 0, 0.01)" borderRadius="10px" p={2}>
            <FormHead text={"Activity info"} />

            <Typography className="details-text-bold">
              {ActivityData?.localized_data
                ? ActivityData?.localized_data[`${supportLang}`][
                    "other_information"
                  ]
                : ActivityData?.other_information}
            </Typography>
          </Stack>

          <Stack bgcolor="rgba(0, 0, 0, 0.01)" borderRadius="10px" p={2}>
            <FormHead text={"Cancelation policy"} />

            <Typography className="details-text-bold">
              {ActivityData?.localized_data
                ? ActivityData?.localized_data[`${supportLang}`][
                    "cancellation_policy"
                  ]
                : ActivityData?.cancellation_policy}
            </Typography>
          </Stack>

          <Stack
            flexDirection="row"
            gap="1rem 3rem"
            padding="1rem 0rem 0rem"
            m="auto 0rem 0rem"
            className="details-time details-time-lg"
          >
            <Stack flexDirection="row" gap={1}>
              <Typography color="#333132" className="details-text-bold">
                Created at :{" "}
              </Typography>
              <Typography color="#333132" className="details-text-bold">
                {created}
              </Typography>
            </Stack>
            <Stack flexDirection="row" gap={1}>
              <Typography color="#333132" className="details-text-bold">
                Updated at :{" "}
              </Typography>
              <Typography color="#333132" className="details-text-bold">
                {updated}
              </Typography>
            </Stack>
          </Stack>
        </Stack>

        <Stack gap={2} width={{ xs: "100%", md: "35%" }}>
          <Stack bgcolor="rgba(0, 0, 0, 0.01)" borderRadius="10px" p={2}>
            <FormHead text={"Activity info"} />

            <Stack flexDirection="row" gap={1} mb={2}>
              <Typography color="#333132" className="details-text">
                Dive cener name :{" "}
              </Typography>
              <Typography color="#333132" className="details-text">
                {ActivityData?.dive_center?.name}
              </Typography>
            </Stack>
            <Stack flexDirection="row" gap={1} mb={2}>
              <Typography color="#333132" className="details-text">
                Branch name :{" "}
              </Typography>
              <Typography color="#333132" className="details-text">
                {ActivityData?.branch?.name}
              </Typography>
            </Stack>
            <Stack flexDirection="row" gap={1} mb={2}>
              <Typography color="#333132" className="details-text">
                Owner name :{" "}
              </Typography>
              <Typography color="#333132" className="details-text">
                {ActivityData?.owner?.name}
              </Typography>
            </Stack>
          </Stack>

          <Stack p={2}>
            <FormHead text={"Information"} />
            {info?.map((data, index) => {
              return (
                <Stack
                  flexDirection="row"
                  gap={1}
                  my={1}
                  key={index}
                  className="details-info"
                >
                  {data?.status ? (
                    <CheckIcon fontSize="1.1rem" style={{ color: "#f6b119" }} />
                  ) : (
                    <CloseOutlinedIcon
                      fontSize="1.1rem"
                      style={{ color: "#e10007" }}
                    />
                  )}
                  <Typography className="details-text">
                    {data?.title}
                  </Typography>
                </Stack>
              );
            })}
            <Stack flexDirection="row" gap={1} mb={2}>
              <Typography color="#333132" className="details-text">
                Invitation link :{" "}
              </Typography>
              <Typography color="#333132" className="details-text">
                {ActivityData?.invitation_link?.length !== 0
                  ? ActivityData?.invitation_link
                  : "Empty"}
              </Typography>
            </Stack>
            <Stack flexDirection="row" gap={1} mb={2}>
              <Typography color="#333132" className="details-text">
                Min age :{" "}
              </Typography>
              <Typography color="#333132" className="details-text">
                {ActivityData?.min_age ? ActivityData?.min_age : "N/A"}
              </Typography>
            </Stack>
            <Stack flexDirection="row" gap={1} mb={2}>
              <Typography color="#333132" className="details-text">
                Min dives :{" "}
              </Typography>
              <Typography color="#333132" className="details-text">
                {ActivityData?.min_dives ? ActivityData?.min_dives : "N/A"}
              </Typography>
            </Stack>
          </Stack>

          <Stack p={2}>
            <FormHead text={"Date and time"} />
            <Stack
              flexDirection="row"
              gap={1}
              my={1}
              className="details-info"
              alignItems="flex-start"
            >
              <CheckIcon
                fontSize="1.1rem"
                style={{
                  color: "#f6b119",
                  marginTop: "4px",
                  fontSize: "15px",
                }}
              />
              <Grid>
                <Typography className="details-text">
                  {ActivityData.scheduling_option === "everyday"
                    ? "Everyday"
                    : ActivityData.scheduling_option === "some_weekdays"
                    ? "Repeat weekly"
                    : ActivityData.scheduling_option === "some_dates"
                    ? "Some dates"
                    : null}
                </Typography>
              </Grid>
            </Stack>
            {ActivityData.scheduling_option === "everyday" ? (
              <>
                <Stack flexDirection="row" gap={1} mb={2}>
                  <Typography color="#333132" className="details-text">
                    Start date :{" "}
                  </Typography>
                  <Typography color="#333132" className="details-text">
                    {moment(new Date(ActivityData?.start_date)).format(
                      "DD/MM/YYYY"
                    )}
                  </Typography>
                </Stack>
                <Stack flexDirection="row" gap={1} mb={2}>
                  <Typography color="#333132" className="details-text">
                    End date :{" "}
                  </Typography>
                  <Typography color="#333132" className="details-text">
                    {moment(new Date(ActivityData?.end_date)).format(
                      "DD/MM/YYYY"
                    )}
                  </Typography>
                </Stack>
                <Stack flexDirection="row" gap={1} mb={2}>
                  <Typography color="#333132" className="details-text">
                    Start time :{" "}
                  </Typography>
                  <Typography color="#333132" className="details-text">
                    {ActivityData?.scheduled_days?.[0]?.start_time
                      ? ActivityData?.scheduled_days?.[0]?.start_time
                      : "N/A"}
                  </Typography>
                </Stack>
              </>
            ) : ActivityData.scheduling_option === "some_weekdays" ? (
              <>
                {ActivityData.scheduled_days.map((day, index) => {
                  return (
                    <Grid container key={index} mb={1}>
                      <Grid item xs={12} md={6}>
                        <Typography
                          color="#333132"
                          className="details-text"
                          sx={{ textTransform: "capitalize" }}
                        >
                          {day.weekday}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Typography color="#333132" className="details-text">
                          {`Start time : ${day?.start_time}`}
                        </Typography>
                      </Grid>
                    </Grid>
                  );
                })}
                <Grid container mb={1}>
                  <Grid item xs={12} md={6}>
                    <Typography color="#333132" className="details-text">
                      {`Start date : ${moment(
                        new Date(ActivityData?.start_date)
                      ).format("DD/MM/YYYY")}`}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography color="#333132" className="details-text">
                      {`End date : ${moment(
                        new Date(ActivityData?.end_date)
                      ).format("DD/MM/YYYY")}`}
                    </Typography>
                  </Grid>
                </Grid>
              </>
            ) : ActivityData.scheduling_option === "some_dates" ? (
              ActivityData.activity_groups.map((day, index) => {
                return (
                  <Grid container key={index} mb={0.5}>
                    <Grid item xs={6}>
                      <Typography color="#333132" className="details-text">
                        Date : {moment(day.start_date).format("DD-MM-YYYY")}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography color="#333132" className="details-text">
                        {day?.start_time}
                      </Typography>
                    </Grid>
                  </Grid>
                );
              })
            ) : null}

            <Stack flexDirection="row" gap={1} mb={2}>
              <Typography color="#333132" className="details-text">
                Reserve before :{" "}
              </Typography>
              <Typography color="#333132" className="details-text">
                {ActivityData?.reserve_before}{" "}
                {ActivityData?.reserve_before === 1 ? "day" : "days"}
              </Typography>
            </Stack>
          </Stack>

          <Stack
            bgcolor="rgba(0, 0, 0, 0.01)"
            borderRadius="10px"
            p={0}
            className="map-wrap"
          >
            <Typography className="details-text">Meeting Point</Typography>
            <Map
              getCurrentLocation={getCurrentLocation}
              lat={ActivityData?.pickup_location?.lat}
              lng={ActivityData?.pickup_location?.long}
              type="view"
            />
            {ActivityData?.pickup_location?.full_address?.length !== 0 &&
              typeof ActivityData?.pickup_location?.full_address ===
                "string" && (
                <Typography className="map-wrap-address">
                  <img src={icons.Location} alt="location" />
                  {ActivityData?.pickup_location?.full_address}
                </Typography>
              )}
          </Stack>

          <Stack p={2}>
            <FormHead text={"Price includes"} />
            {ActivityData?.activity_price_includes?.map((data, index) => {
              return (
                <Stack
                  flexDirection="row"
                  gap={1}
                  my={1}
                  key={index}
                  className="details-info"
                >
                  <CheckIcon fontSize="1.1rem" style={{ color: "#f6b119" }} />
                  <Typography className="details-text">{data?.name}</Typography>
                </Stack>
              );
            })}
          </Stack>

          <Stack p={2}>
            <FormHead text={"Price not includes"} />
            {ActivityData?.activity_price_excludes?.map((data, index) => {
              return (
                <Stack
                  flexDirection="row"
                  gap={1}
                  my={1}
                  key={index}
                  className="details-info"
                >
                  <CloseOutlinedIcon
                    fontSize="1.1rem"
                    style={{ color: "#e10007" }}
                  />
                  <Typography className="details-text">{data?.name}</Typography>
                </Stack>
              );
            })}
          </Stack>

          <Stack
            bgcolor="rgba(0, 0, 0, 0.01)"
            borderRadius="10px"
            p={2}
            className="organizations-wrap"
          >
            <FormHead text={"Certifications"} />
            <Typography color="#333132" className="details-text" mb={2.5}>
              {`Organization : ${
                ActivityData?.minimum_certificate?.organization?.name
                  ? ActivityData?.minimum_certificate?.organization?.name
                  : "Any"
              }`}
            </Typography>
            <Typography color="#333132" className="details-text" mb={2.5}>
              {`Certificate : ${
                ActivityData?.minimum_certificate?.name
                  ? ActivityData?.minimum_certificate?.name
                  : "Any"
              }`}
            </Typography>
          </Stack>

          <Stack
            flexDirection="row"
            gap="1rem 3rem"
            padding="1rem 0rem 2rem"
            className="details-time details-time-sm"
          >
            <Stack flexDirection="row" gap={1}>
              <Typography color="#333132" className="details-text-bold">
                Created at :{" "}
              </Typography>
              <Typography color="#333132" className="details-text-bold">
                {created}
              </Typography>
            </Stack>
            <Stack flexDirection="row" gap={1}>
              <Typography color="#333132" className="details-text-bold">
                Updated at :{" "}
              </Typography>
              <Typography color="#333132" className="details-text-bold">
                {updated}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}

export default ActivityDetails;

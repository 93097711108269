import { axiosFn, handleError } from "../index";
import * as URL from "../URLs";
import { makeFilterString } from "../Helper/Helper.js";

export async function getActivitiesList(filter_obj) {
    return await axiosFn("get", URL.activities + makeFilterString(filter_obj))
        .then((res) => {
            return {
                res: res.data,
                err: null,
            };
        })
        .catch(handleError);
}


export async function getActivityDetails(activity_id) {
    return await axiosFn("get", `${URL.activities}/${activity_id}`)
        .then((res) => {
            return {
                res: res.data,
                err: null,
            };
        })
        .catch(handleError);
}

export async function getActivityGroups(activity_id, filter_obj) {
    return await axiosFn("get", URL.activities + '/' + activity_id + URL.group_reservations + makeFilterString(filter_obj))
      .then((res) => {
        return {
          res: res.data,
          err: null,
        };
      })
      .catch(handleError);
  }

export async function getActivityReviews(filter_obj, data) {
    console.log(data);
    return await axiosFn("post", URL.reviews + makeFilterString(filter_obj), data)
      .then((res) => {
        return {
          res: res.data,
          err: null,
        };
      })
      .catch(handleError);
}

export async function deleteActivity(activityId) {
  return await axiosFn("delete", `${URL.activities}/${activityId}`)
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}
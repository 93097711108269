import { Box, IconButton, Menu, MenuItem } from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import RejectionModal from "../../General/Models/RejectionModal";
import DeleteModal from "../../General/Models/DeleteModal";
import { upgradeFreelancerLevel } from "../../../../Contexts/APIs/Freelancers/Freelancers";
import { toast } from "react-toastify";
// import RejectionModal from "../Models/RejectionModal";
// import DeleteModal from "../Models/DeleteModal";

// const MoreMenu = ({ freelancer, deleteFreelancer }) => {
const MoreMenu = ({
  freelancer,
  handleApprove,
  handleUpdate,
  handleDelete,
  handleRejection,
  getFreelancers,
  filterObj,
  setFilterObj,
  getFreelancer,
  freelancerCount,
  setErrorListener,
  errorListener,
  setPage,
  roles,
  setList,
}) => {
  const navigate = useNavigate();
  // const [openRejection, setOpenRejection] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const isSuperAdmin = localStorage.getItem("userType") === "super_admin";

  const handleClick = (event) => setAnchorEl(event.currentTarget);

  const handleClose = () => setAnchorEl(null);

  // const handleCloseRejection = () => setOpenRejection(false)

  // hide in view details button in details page
  const hrf = window.location.href;
  const indexOfDividerSymbol = hrf.length - hrf.lastIndexOf("/");
  const checkDetails =
    indexOfDividerSymbol <= 4 && indexOfDividerSymbol > 1 ? true : false;
  // hide in view details button in details page
  const handleDeleteRequest = async () => {
    const { res, err } = await handleDelete(freelancer?.id);
    if (res) {
      setOpenDeleteModal(false);
      handleClose();

      setList((prevState) =>
        prevState?.filter((ele) => ele?.id !== freelancer?.id)
      );
      setPage(1);
    }
  };

  const [openRejectModel, setOpenRejectModel] = useState(false);
  const [rejectionReason, setRejectionReason] = useState("");

  const handleRejectRequest = async () => {
    if (rejectionReason.trim().length === 0) {
      setErrorListener(true);
    } else {
      const { res, err } = await handleRejection(freelancer?.id, {
        user: {
          profile_status: "rejected",
          rejection_reason: `${rejectionReason}`,
        },
      });
      if (res) {
        setOpenRejectModel(false);
        handleClose();
        freelancerCount === "single"
          ? getFreelancer(freelancer?.id)
          : getFreelancers && getFreelancers(filterObj);
        setErrorListener(false);
        setFilterObj({ ...filterObj, page_number: 1 });
        setPage(1);
      }
    }
  };
  return (
    <>
      <IconButton
        id={`demo-positioned-btn${freelancer?.id}`}
        aria-controls={
          open ? `demo-positioned-men${freelancer?.id}` : undefined
        }
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <MoreHorizIcon style={{ color: "black" }} />
      </IconButton>
      {(freelancer?.account_status !== "deleted" ||
        (freelancer?.account_status === "deleted" &&
          freelancer?.profile_status === "pending_approval")) && (
        <Menu
          id={`demo-positioned-men${freelancer?.id}`}
          aria-labelledby={`demo-positioned-btn${freelancer?.id}`}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          {!checkDetails && roles?.can_read && (
            <MenuItem
              onClick={() => {
                navigate("/freelancers/" + freelancer?.id);
                handleClose();
              }}
            >
              View details
            </MenuItem>
          )}
          {/* {(freelancer?.account_status === 'active' && freelancer?.profile_status === 'pending_approval') && */}
          {freelancer?.profile_status === "pending_approval" &&
            roles?.can_read &&
            roles?.can_update && (
              <Box>
                <MenuItem
                  onClick={() => {
                    // alert('Approve')
                    // handleClose()
                    (async () => {
                      const { res, err } = await handleApprove(freelancer?.id, {
                        user: { profile_status: "approved" },
                      });
                      if (res) {
                        handleClose();
                        freelancerCount === "single"
                          ? getFreelancer(freelancer?.id)
                          : getFreelancers && getFreelancers(filterObj);
                        setFilterObj({ ...filterObj, page_number: 1 });
                        setPage(1);
                      }
                    })();
                  }}
                >
                  Approve
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setOpenRejectModel(true);
                    // handleClose()
                  }}
                >
                  Reject
                </MenuItem>
              </Box>
            )}
          {/* {freelancer?.account_status !== 'active' && */}
          {(freelancer?.account_status === "deactivated" ||
            freelancer?.account_status === "suspended") &&
            roles?.can_read &&
            roles?.can_update && (
              <MenuItem
                onClick={() => {
                  // alert('Reactivate')
                  // handleClose()
                  (async () => {
                    const { res, err } = await handleUpdate(freelancer?.id, {
                      user: { status: "active" },
                    });
                    if (res) {
                      console.log(res);
                      handleClose();
                      freelancerCount === "single"
                        ? setList(res?.data?.user)
                        : setList((prevState) =>
                            prevState?.map((user) =>
                              user?.id == res?.data?.user?.id
                                ? res?.data?.user
                                : user
                            )
                          );
                      // setFilterObj({...filterObj, 'page_number': 1})
                      setPage(1);
                    }
                  })();
                }}
              >
                Reactivate
              </MenuItem>
            )}
          {(freelancer?.account_status === "active" ||
            freelancer?.account_status === "deactivated") &&
            roles?.can_read &&
            roles?.can_update && (
              <MenuItem
                onClick={() => {
                  (async () => {
                    const { res, err } = await handleUpdate(freelancer?.id, {
                      user: { status: "suspended" },
                    });
                    if (res) {
                      handleClose();
                      freelancerCount === "single"
                        ? setList(res?.data?.user)
                        : setList((prevState) =>
                            prevState?.map((user) =>
                              user?.id == res?.data?.user?.id
                                ? res?.data?.user
                                : user
                            )
                          );
                      // setFilterObj({...filterObj, 'page_number': 1})
                      setPage(1);
                    }
                  })();
                }}
              >
                Suspend
              </MenuItem>
            )}
          {/* {freelancer?.account_status !== 'deleted' && */}
          {(freelancer?.account_status === "active" ||
            freelancer?.account_status === "deactivated") &&
            roles?.can_read &&
            roles?.can_delete && (
              <MenuItem
                onClick={() => {
                  // deleteFreelancer(freelancer?.id)
                  // handleClose()
                  setOpenDeleteModal(true);
                }}
              >
                Delete
              </MenuItem>
            )}
          {freelancer?.level?.rank !== 3 && isSuperAdmin && (
            <MenuItem
              onClick={() => {
                const action = (data) => {
                  toast.success(
                    `Freelancer level upgraded successfully to level ${data?.data?.user?.level?.rank}`
                  );
                  setList((prevState) =>
                    prevState?.map((ele) =>
                      ele.id === data?.data?.user?.id
                        ? { ...ele, ...data?.data?.user }
                        : ele
                    )
                  );
                };
                upgradeFreelancerLevel(freelancer?.id, action);
                handleClose();
              }}
            >
              Upgrade Level
            </MenuItem>
          )}
        </Menu>
      )}
      {/* <RejectionModal
                open={openRejection}
                handleClose={handleCloseRejection}
            /> */}
      {openRejectModel === true && (
        <RejectionModal
          open={openRejectModel}
          setOpen={setOpenRejectModel}
          text={[`Rejection reason  ( ${freelancer?.name} )`, ""]}
          rejectionReason={rejectionReason}
          setRejectionReason={setRejectionReason}
          errorListener={errorListener}
          buttons={["Cancel", "ok"]}
          handelRejectRequest={handleRejectRequest}
          handelCancelReject={() => {
            setOpenRejectModel(false);
            handleClose();
          }}
          modalType={"form"}
        />
      )}

      {openDeleteModal && (
        <DeleteModal
          open={openDeleteModal}
          setOpen={setOpenDeleteModal}
          handelDeleteRequest={handleDeleteRequest}
          handleCancelDelete={handleClose}
          text={[
            `Delete ${freelancer?.name}`,
            `are you sure that you want to delete ${freelancer?.name}`,
          ]}
        />
      )}
    </>
  );
};

export default MoreMenu;

import {
  Box,
  Breadcrumbs,
  ButtonGroup,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import PrimaryButton from "../../Atoms/Buttons/Primary/PrimaryButton";
import TextFieldInput from "../../Atoms/Input/TextField/TextFieldInput";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import dayjs from "dayjs";
import "./PagesHead.css";
import Filter from "../General/Crud/filter";
import { useSearchParams } from "react-router-dom";
import SearchableSelect from "../../Atoms/Select/SearchableSelect.jsx";
import BasicDatePicker from "../../Atoms/Input/DatePicker/DatePicker.js";
const LogHistoryPageHead = ({
  title,
  searchInput,
  primaryButtons,
  breadCrumb,
  handelSubmit,
  filterObj,
  setFilterObj,
  filterList,
  handleApplyFilterMenu,
}) => {
  const [searchKeywords, setSearchKeywords] = useState("");
  const [searchParams] = useSearchParams();
  const pageNumber = searchParams.get("page_number") || 1;
  const customStyles = {
    control: (provided) => ({
      ...provided,
      width: 'inherit !important',
      maxWidth: 'inherit !important',
    }),
  };
  
  const getKeywords = (keyword, type) => {
    setSearchKeywords({ keyword, type });
    setFilterObj({
      ...filterObj,
      page_number: keyword?.length == 0 ? pageNumber : 1,
      keyword: keyword.trim(),
    });
  };
  return (
    <Grid
      container
      style={{ borderBottom: "1px solid #d9d9d9" }}
      sx={{ pb: 1, mb: 2 ,justifyContent: 'flex-start',gap:3}}
      className="user-pages-head"
    >
      {breadCrumb && (
        <Grid item sm={12}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link color="inherit" href={breadCrumb.path}>
              {breadCrumb.link}
            </Link>
            <Typography color="text.primary">{breadCrumb.text}</Typography>
          </Breadcrumbs>
        </Grid>
      )}
      <Grid container item lg={4} justifyContent={'space-between'}>
        <Grid item lg={4}>
          <Typography
            component="h1"
            style={{
              fontSize: "30px",
              fontWeight: "bold",
              textAlign: "left",
              color: "#333132",
            }}
          >
            {title}
          </Typography>
        </Grid>
        <Grid item lg={7.5} >
          {searchInput && (
            <TextFieldInput
              type={searchInput.type}
              onChange={(e) => getKeywords(e.target.value, e?.type)}
              placeholder={searchInput.placeholder}
              StartAdornment={<SearchIcon />}
              className={searchInput.className}
              value={searchKeywords?.keyword}
              style={{height: "100%",width:"100%"}}
            />
          )}
        </Grid>
      </Grid>
      {filterList &&
          filterList?.map((filterMenu, index) => {
            return (
              <Grid item lg={1.5} className="filter-menu-wrap" key={index} >
{filterMenu.type==='search'?<SearchableSelect
                  customStyles={customStyles}
                  options={filterMenu?.list}
                  label={filterMenu?.title}
                  className="searchable-select filter-menu-button"
                  onChange={(val) => {
                    filterMenu.handleFilter({role_id:val.value});
                    filterMenu.handleChange(val);
                  }}
                  value={filterMenu.value}
                  getList={filterMenu.getList}
                  count={filterMenu.count}
                  onClear={() => {
                    filterMenu.handleFilter({role_id:null});
                    filterMenu.handleChange(null);
                  }}
                  onMenuOpen={filterMenu?.onMenuOpen}
                  moreFilterData={filterMenu?.moreFilterData}
                />:
                <BasicDatePicker
                value={filterMenu.value}
                handleChange={(value) => {
                  filterMenu.handleFilter({
                    [filterMenu.key]: dayjs(value, "YYYY-MM-DD").format("YYYY-MM-DD")
                  });
                  filterMenu.handleChange(value)
                }}
                handleClear={(value) => {
                  filterMenu.handleFilter({
                    [filterMenu.key]: null
                  });
                  filterMenu.handleChange(null)
                }}
                setValue={filterMenu.handleChange}
                placeholder={filterMenu.title}
                className={filterMenu.className}
            />

}                
              </Grid>
            );
          })}
      <Grid
        item
        lg={2.5}
      >
        {primaryButtons.map((btn, index) => {
          return (
            <PrimaryButton
              key={index}
              fontSize={btn.fontSize}
              startIcon={<Box component={'img'} src={btn.startIcon} sx={{width:'18px',height:'18px'}}/>}
              text={btn.text}
              classBtn={btn.classBtn}
              style={btn.style}
              click={() => {
                btn.click && btn.click();
                btn.btnType === "submit" && handelSubmit();
              }}
            />
          );
        })}
      </Grid>
    </Grid>
  );
};

export default LogHistoryPageHead;

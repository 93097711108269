import "../../../../Components/Atoms/Shared/Styles/form-page.css";
import React from "react";
import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Languages from "../../../../Components/Atoms/Shared/language/Language";
import CCrud from "../../../../Components/Molecules/General/Crud/CCrud";
import "../../../../Components/Atoms/Shared/Styles/list-page.css";
import { initialValues, structure, validationSchema } from "./structure";
import AddIcon from "@mui/icons-material/Add";

import {
  createSocialMedia,
  updateSocialMedia,
} from "../../../../Contexts/APIs/SocialMedia/SocialMedia";
import "./SocialMedia.css";
const SocialMediaTemplate = ({
  setFilterObj,
  data,
  openDeleteModal,
  setOpenDeleteModal,
  handleDeleteRequest,
  count,
  paginationPage,
  setPaginationPage,
  handlePageChange,
  rowData,
  details,
  breadcrumbs,
  roles,
}) => {
  let [searchParams, setSearchParams] = useSearchParams();
  const index = searchParams.get("index");

  const id = searchParams.get("id");
  const navigate = useNavigate();
  const [supportedLang, setSupportedLang] = useState("en");

  const handelSubmit = async (data, formik) => {
    console.log(formik);
    const action = () => {
      searchParams.delete("id");
      searchParams.delete("type");
      searchParams.delete("index");
      formik?.resetForm();
      navigate("/predefined-lookups/social-media");
    };

    if (index) {
      updateSocialMedia({
        id,
        data: { social_medium: data },
        action,
      });
    } else {
      createSocialMedia({
        data: { social_medium: data },
        action,
      });
    }
  };

  const handleApplyFilterMenu = (obj) => {
    setFilterObj((prev) => {
      return { ...prev, ...obj, page_number: 1 };
    });
    setPaginationPage(1);
  };
  const handleResetFilterMenu = () => {
    setFilterObj({
      page_size: 10,
      page_number: 1,
      keyword: "",
      sort_by: "id",
    });
    setPaginationPage(1);
  };
  const actionsList = ({ id, index, details }) => {
    return [
      {
        condition: () => {
          if (roles?.can_read && roles?.can_update) {
            return true;
          } else {
            return false;
          }
        },
        click: () => {
          navigate(`?type=form&id=${id}&index=${index}`);
        },
        text: "Edit",
      },
      {
        condition: () => {
          if (roles?.can_read && roles?.can_delete) {
            return true;
          } else {
            return false;
          }
        },
        click: () => {
          setOpenDeleteModal(true);
        },
        text: "Delete",
      },
    ];
  };
  return (
    <CCrud
      table={{
        list: data,
        openDeleteModal: openDeleteModal,
        setOpenDeleteModal: setOpenDeleteModal,
        rowData: rowData,
        actionsList,
        listTitle: "Social media",
        emptyListMsg: "There are no Social media",
        details: {
          navigatePath: "/predefined-lookups/social-media",
          statusField: null,
          hideStatus: null,
        },
        columns: [
          { accessor: "id", Header: "Id" },
          { accessor: "name_data", Header: "Name" },
          { accessor: "Actions", Header: "Actions" },
        ],
        isFilter: false,
        isSearch: false,
        isChecked: false,
        isSort: false,
        total_count: count,
        setFilterObj,
        handleApplyFilterMenu: handleApplyFilterMenu,
        handleResetFilterMenu: handleResetFilterMenu,
        createBtnTitle: "Add new Social media",
        createBtnFun:
          roles?.can_read && roles?.can_create
            ? () => navigate("?type=form")
            : null,
        CreateBtnIcon: AddIcon,
        createBtnClass: "add-organization-button",
        className: "organization-table social-media-table",
        paginationPage: paginationPage,
        setPaginationPage: setPaginationPage,
        handlePageChange,
        openDeleteModal: openDeleteModal,
        setOpenDeleteModal: setOpenDeleteModal,
        handleDeleteRequest,
      }}
      form={{
        structure: () => structure(supportedLang),
        validationSchema: () => validationSchema(supportedLang),
        initialValues: !index ? {} : initialValues(details, supportedLang),
        onSubmit: handelSubmit,
        submitTitle: index === null ? "Add" : "Save changes",
        className: { form: "flow-form", actionButton: "flow-form-buttons" },
        handleLanguage: <></>,
        breadcrumbs: breadcrumbs,
        title: index ? "Edit Social media" : "Add new Social media",
      }}
    />
  );
};

export default SocialMediaTemplate;

import React from "react";
import Loader from "../../../Components/Atoms/Loader/Loader";
import { useEffect } from "react";
import { getActivityOffersList } from "../../../Contexts/APIs/ActivityOffers/ActivityOffers";
import { useState } from "react";
import { toast } from "react-toastify";
import ActivityOffersTemplate from "../../../Templates/Main/ActivityOffers/ActivityOffers.template";
import usePageFilter from "../../../Components/Atoms/Hooks/usePageFilter";

function ActivityOffersPage() {
  const [count, setCount] = useState();
  const [openLoader, setOpenLoader] = useState(false);
  const [activityOffers, setActivityOffers] = useState([]);
  const { page, filterObj, handlePageChange ,setFilterObj,setPage} = usePageFilter();

  const getActivityOffers = async (filter) => {
    setOpenLoader(true);
    const defaultFilterObj = {
      page_size: 10,
      page_number: 1,
      keyword: "",
      sort_by: "id",
    };
    const { res, err } = await getActivityOffersList({
      ...defaultFilterObj,
      ...filter,
      keyword: filter?.keyword?.trim()
    });
    if (res) {
      setCount(res?.extra?.total_count);
      setActivityOffers(res?.data?.activity_offers);
    }
    if (err) toast.error(err);
    setOpenLoader(false);
  };

  useEffect(() => {
    if (filterObj) getActivityOffers(filterObj);
  }, [filterObj]);

  return (
    <>
      <Loader open={openLoader} />
      <ActivityOffersTemplate
        filterObj={filterObj}
        setFilterObj={setFilterObj}
        activityOffersList={activityOffers}
        count={count}
        page={page}
        setPage={setPage}
        handlePageChange={handlePageChange}
      />
    </>
  );
}

export default ActivityOffersPage;

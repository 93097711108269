import { Box, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomSeparator from "../../Atoms/BreadCrumb";
import FormHead from "../../Atoms/FormHead/FormHead";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import "./DiveClubDetails.css";
import FormHeadIcon from "../../Atoms/FormHead/FormHeadIcon";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import DiveClubDetailsHeader from "../../Molecules/DiveClubs/DiveClubDetailsHeader/DiveClubDetailsHeader";
import moment from "moment";
import PhoneInput from "react-phone-input-2";

function DiveClubDetails(props) {
  const { diveClubData, roles } = props;

  const created = moment(diveClubData?.created_at).format("DD-MM-YYYY HH:mm"),
    updated = moment(diveClubData?.updated_at).format("DD-MM-YYYY HH:mm"),
    foundationDate = diveClubData?.birthdate
      ? moment(diveClubData?.birthdate).format("DD MMMM YYYY")
      : "-";

  const breadcrumbs = [
    { path: "/dive-clubs", active: true, title: "Dive clubs" },

    {
      active: false,
      title: diveClubData?.name + "details",
    },
  ];
  const [countryCode, setCountryCode] = useState(null);
  useEffect(()=>{
    setTimeout(() => {
      setCountryCode(diveClubData.country_code);      
    }, 1000);
  },[diveClubData])
  return (
    <Stack gap={3}>
      <Grid container item sm={12}>
        <CustomSeparator breadcrumbs={breadcrumbs} />
      </Grid>

      <DiveClubDetailsHeader data={diveClubData} roles={roles} />

      <Stack
        mt="-25px"
        bgcolor="white"
        padding="53.8px"
        className="details-panel"
        flexDirection="row"
        justifyContent="space-between"
        gap="1rem"
        overflow="auto"
      >
        <Stack gap={2} width="calc(55% - 1.5rem)">
          <Stack bgcolor="rgba(0, 0, 0, 0.01)" borderRadius="10px" p={2}>
            <FormHead text={"Dive clud info"} />
            <Box position="relative">
              <Grid container gap={1}>
                <Grid item md={5}>
                  <Stack flexDirection="column" gap={1} mb="21px">
                    <Typography fontSize="13px" color="#aaa">
                      Dive club ID
                    </Typography>
                    <Typography className="details-text-bold">
                      {diveClubData?.id}
                    </Typography>
                  </Stack>
                  <Stack flexDirection="column" gap={1} mb="21px">
                    <Typography fontSize="13px" color="#aaa">
                      Dive club mobile
                    </Typography>
                    <Typography className="details-text-bold">
                      {diveClubData?.phone_number ? <PhoneInput
                          inputStyle={{
                              border: "none",
                              width: "100%",
                              padding: "0px",
                              fontSize: '18px',                      
                              color: '#282828',
                              backgroundColor: '#f9f9f9',
                              wordBreak: 'break-word',
                              fontWeight: 'bold'
                          }}
                          defaultMask={`${countryCode === "" ? '...........' : '... ... ....'}`}
                          value={`${countryCode === "" ? '  ' : diveClubData.country_code}${diveClubData.phone_number}`}
                          disabled={true}
                          disableDropdown={true}
                          specialLabel=""
                          placeholder=""
                          disableCountryCode={countryCode === "" ? true : false}
                        /> : "-"}
                    </Typography>
                  </Stack>
                  <Stack flexDirection="column" gap={1} mb="21px">
                    <Typography fontSize="13px" color="#aaa">
                      Contact person name
                    </Typography>
                    <Typography className="details-text-bold">
                      {diveClubData?.contact_person_name || "-"}
                    </Typography>
                  </Stack>
                  <Stack flexDirection="column" gap={1} mb="21px">
                    <Typography fontSize="13px" color="#aaa">
                      Primary language
                    </Typography>
                    <Typography className="details-text-bold">
                      {diveClubData?.primary_language?.name || "-"}
                    </Typography>
                  </Stack>
                  <Stack flexDirection="column" gap={1}>
                    {diveClubData?.is_verified ? (
                      <Stack className="about-dive-club-wrap">
                        <FormHeadIcon text={"Profile is verified"}>
                          <CheckOutlinedIcon
                            fontSize="1.1rem"
                            style={{ color: "#f6b119" }}
                          />
                        </FormHeadIcon>
                      </Stack>
                    ) : (
                      <Stack className="about-dive-club-wrap">
                        <FormHeadIcon text={"Profile is not verified"}>
                          <CloseOutlinedIcon
                            fontSize="1.1rem"
                            style={{ color: "#e10007" }}
                          />
                        </FormHeadIcon>
                      </Stack>
                    )}
                  </Stack>
                </Grid>
                <Grid item md={6}>
                  <Stack flexDirection="column" gap={1} mb="21px">
                    <Typography fontSize="13px" color="#aaa">
                      Dive club email
                    </Typography>
                    <Typography
                      className="details-text-bold"
                      style={{ overflowWrap: "anywhere" }}
                    >
                      {diveClubData?.email || "-"}
                    </Typography>
                  </Stack>
                  <Stack flexDirection="column" gap={1} mb="21px">
                    <Typography fontSize="13px" color="#aaa">
                      Website
                    </Typography>
                    <Typography className="details-text-bold">
                      {diveClubData?.website || "-"}
                    </Typography>
                  </Stack>
                  <Stack flexDirection="column" gap={1}>
                    <Typography fontSize="13px" color="#aaa">
                      Foundation date
                    </Typography>
                    <Typography className="details-text-bold">
                      {foundationDate}
                    </Typography>
                  </Stack>
                </Grid>
              </Grid>
            </Box>
          </Stack>

          {/* <Stack bgcolor="rgba(0, 0, 0, 0.01)" borderRadius="10px" p={2}>
            <FormHead text={`About ${diveClubData?.name}`} />
            <Typography className="details-text" color="#333132">
              {diveClubData?.about_us}
            </Typography>
          </Stack> */}

          <Stack
            flexDirection="row"
            gap="1rem 3rem"
            padding="1rem 0rem 2rem"
            className="details-time details-time-lg"
          >
            <Stack flexDirection="row" gap={1}>
              <Typography color="#333132" className="details-text-bold">
                Created at :{" "}
              </Typography>

              <Typography color="#333132" className="details-text-bold">
                {created}
              </Typography>
            </Stack>
            <Stack flexDirection="row" gap={1}>
              <Typography color="#333132" className="details-text-bold">
                Updated at :{" "}
              </Typography>
              <Typography color="#333132" className="details-text-bold">
                {updated}
              </Typography>
            </Stack>
          </Stack>
        </Stack>

        <Stack
          width="calc(45% - 1.5rem)"
          bgcolor="rgba(0, 0, 0, 0.01)"
          borderRadius="10px"
          p={2}
          height="100%"
        >
          <FormHead text={"Location info"} />
          <Box position="relative">
            <Stack flexDirection="column" gap={1} mb="21px">
              <Typography fontSize="13px" color="#aaa">
                Location
              </Typography>
              <Typography className="details-text-bold">
                {!diveClubData?.area
                  ? "-"
                  : diveClubData?.area?.name +
                    "," +
                    diveClubData?.city?.name +
                    "," +
                    diveClubData?.country?.name}
              </Typography>
            </Stack>
          </Box>

          <Stack
            flexDirection="row"
            gap="1rem 3rem"
            padding="1rem 0rem 2rem"
            className="details-time details-time-sm"
          >
            <Stack flexDirection="row" gap={1}>
              <Typography color="#333132" className="details-text-bold">
                Created at :{" "}
              </Typography>
              <Typography color="#333132" className="details-text-bold">
                {created}
              </Typography>
            </Stack>
            <Stack flexDirection="row" gap={1}>
              <Typography color="#333132" className="details-text-bold">
                Updated at :{" "}
              </Typography>
              <Typography color="#333132" className="details-text-bold">
                {updated}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}

export default DiveClubDetails;

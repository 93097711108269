import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { getUsersList } from "../../../Contexts/APIs/Users/UsersList";
import UsersTemplate from "../../../Templates/Main/Users/Users.template";
import { getRoleList } from "../../../Contexts/APIs/Users/Roles";
import { useSearchParams } from "react-router-dom";
import usePageFilter from "../../../Components/Atoms/Hooks/usePageFilter";

const UsersPage = ({ usersPrivilages, rolesPrivilages }) => {
  const [openLoader, setOpenLoader] = useState(false);
  const [usersList, setUsersList] = useState([]);
  const [rolesData, setRolesData] = useState([]);
  // const [searchParams, setSearchParams] = useSearchParams();
  const [count, setCount] = useState(0);
  const [rolesCount, setRolesCount] = useState(0);
  const { page, filterObj, handlePageChange ,setFilterObj,setPage} = usePageFilter();

  const getUsers = async (filterObj) => {
    setOpenLoader(true);
    const defaultFilterObj = {
      page_size: 10,
      page_number: 1,
      keyword: "",
      sort_by: "id",
    };
    const { res, error } = await getUsersList({
      ...defaultFilterObj,
      ...filterObj,
    });
    if (res) {
      setOpenLoader(false);
      setUsersList(res?.data?.descendant_users);
      setCount(res?.extra?.total_count);
    }
    if (error) {
      setOpenLoader(false);
    }
  };

  useEffect(() => {
    const timeId = setTimeout(() => {
      getUsers(filterObj);
    }, 500);
    return () => clearTimeout(timeId);
  }, [filterObj]);
  // const getRolesData = async () => {
  //   const { res, err } = await getRoleList({ page_size: 100, page_number: 1 });
  //   if (res) {
  //     setRolesData(res?.data?.roles);
  //   }
  // };
  const getRolesData = async(data) => {
    setOpenLoader(true);
    const prevRoles = rolesData;
    const DataKey = data?.keyword;
    if((data?.page_number === 1 || data?.page_number === undefined) && !DataKey && rolesData.length === 10){
      setRolesData(prevRoles);      
    }else{
      const { res, error } = (rolesCount !== 10 || DataKey)? await getRoleList({ page_size: 10, ...data }) : {};
      if (res) {      
        (data?.page_number == 1 || data?.page_number == undefined)
        ? setRolesData(res?.data?.roles)
        : setRolesData((s) => [...s, ...res?.data?.roles]);
        setRolesCount(res?.extra?.total_count);
      }
    }
    setOpenLoader(false);
  };
  // useEffect(() => {
  //   getRolesData();
  // }, []);
  return (
    <UsersTemplate
      filterObj={filterObj}
      setFilterObj={setFilterObj}
      usersList={usersList}
      setUsersList={setUsersList}
      openLoader={openLoader}
      setOpenLoader={setOpenLoader}
      count={count}
      page={page}
      setPage={setPage}
      handlePageChange={handlePageChange}
      usersPrivilages={usersPrivilages}
      rolesPrivilages={rolesPrivilages}
      rolesData={rolesData}
      getRolesData={getRolesData}
      rolesCount={rolesCount}
    />
  );
};

export default UsersPage;

import { Grid } from "@mui/material";
import React from "react";
import CustomPagination from "../../../Atoms/Pagination/Pagination";
import "./ActivitiesList.css";
import { useNavigate } from "react-router-dom";
import ActionsMenu from "../../Branches/ActionsMenu";
import { deleteActivity } from "../../../../Contexts/APIs/Activities/Activities";

function ActivitiesList(props) {
  const { activitiesList, count, page, handlePageChange, getActivities, filterObj, roles, setActivitiesList} = props;
  const navigate = useNavigate();
  return (
    <Grid container>
      <Grid container className="table-header activity-header mb-20px">
        <Grid item xs={4} md={2} lg={0.75} textAlign="center">
          ID
        </Grid>
        <Grid item xs={4} md={2} lg={1.15}>
          Name
        </Grid>
        <Grid item xs={4} md={2} lg={1.15}>
          Type
        </Grid>
        <Grid item xs={4} md={2} lg={1.3}>
          Dive center
        </Grid>
        <Grid item xs={4} md={2} lg={1.3}>
          Branch
        </Grid>
        <Grid item xs={4} md={2} lg={1.5}>
          Need confirmation
        </Grid>
        <Grid item xs={4} md={2} lg={1.25}>
          Price
        </Grid>
        <Grid item xs={4} md={2} lg={1.15}>
          Activity groups
        </Grid>
        <Grid item xs={4} md={2} lg={1.2}>
          Status
        </Grid>
        {
          !roles?.can_read ? null : <Grid item xs={4} md={2} lg={1.25} sx={{ pl: 2 }}> Action </Grid>
        }
      </Grid>

      {activitiesList?.length === 0 && (
        <Grid container display="block" textAlign="center">
          There are no activities
        </Grid>
      )}

      {activitiesList?.length >= 1 && (
        <>
          {activitiesList?.map((item, index) => {
            return (
              <Grid
                key={index}
                container
                item
                lg={12}
                className="activity-item mb-20px"
                style={{ cursor: "pointer" }}
                onClick={() =>
                  roles.can_read ? navigate(
                    "/operators/activities/" + item?.id + "/activity-groups"
                  ) : null
                }
              >
                <Grid
                  item
                  xs={4}
                  md={2}
                  lg={0.75}
                  className="activity-item-no"
                  textAlign="center"
                >
                  {item.id}
                </Grid>
                <Grid
                  item
                  xs={4}
                  md={2}
                  lg={1.15}
                  className="activity-item-name"
                >
                  {item.name}
                </Grid>
                <Grid item xs={4} md={2} lg={1.15}>
                  {item.activity_type === "live_aboard"
                    ? "liveaboard"
                    : item.activity_type.replace("_", " ")}
                </Grid>
                <Grid item xs={4} md={2} lg={1.3}>
                  {item.dive_center?.name}
                </Grid>
                <Grid item xs={4} md={2} lg={1.3}>
                  {item.branch.name}
                </Grid>
                <Grid item xs={4} md={2} lg={1.5}>
                  <div
                    className={`${
                      item.need_confirmation
                        ? "activity-verified"
                        : "activity-not-verified"
                    }`}
                  >
                    {item.need_confirmation ? "Yes" : "No"}
                  </div>
                </Grid>
                <Grid
                  item
                  xs={4}
                  md={2}
                  lg={1.25}
                >{`${item.price} ${item.currency.lookup_key}`}</Grid>
                <Grid item xs={4} md={2} lg={1.15}>
                  {item.number_of_groups}
                </Grid>
                <Grid item xs={4} md={2} lg={1.2}>
                  {item.is_available ? "active" : "deactive"}
                </Grid>
                {/* <Grid item xs={4} md={2} lg={1.25}>
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      if(roles.can_read){
                        navigate("/operators/activities/" + item?.id);
                      }
                    }}
                  >
                    view details
                  </button>
                </Grid> */}
                {
                  !roles?.can_read ? null
                  : <Grid item xs={4} md={2} lg={1.25} className='operator-menu-button-wrap' onClick={(e) => e.stopPropagation()}>
                      <ActionsMenu
                        user={item} 
                        userPath={'operators/activities'}
                        userPathLength={20}
                        handleDelete={deleteActivity}
                        getList={getActivities}
                        filterObj={filterObj}
                        isActivitiesList={true}
                        roles={roles}
                        setList={setActivitiesList}
                      />
                    </Grid>
                }
              </Grid>
            );
          })}
          {count > 10 && (
            <Grid
              container
              item
              justifyContent={"center"}
              xs={12}
              mb={7.5}
              mt={5}
            >
              <CustomPagination
                count={Math.ceil(count / 10)}
                size="large"
                shape="rounded"
                page={page}
                handleChange={handlePageChange}
                className="pagination"
              />
            </Grid>
          )}
        </>
      )}
    </Grid>
  );
}

export default ActivitiesList;

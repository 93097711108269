import { axiosFn, handleError } from "../index";

export async function changePassword(user_type, data) {
    return await axiosFn("put", `auth/passwords/${user_type}/change_password`, data)
        .then((res) => {
            return {
                res: res.data,
                err: null,
            };
        })
        .catch(handleError);
}
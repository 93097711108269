import * as Yup from "yup";
export const initialValues = (data, lang) => ({});

export const structure = (
  lang,
  index,
  getCountry,
  countriesList, countriesCount,
  country, setCountry,
  notificationsUsersTypes,
  
  getCity, citiesCount,
  citiesList,setCitiesList,
  city, setCity,
  
  getProvinice, provinicesCount,
  provinicesList, setProvinicesList,
  provinice, setProvinice,
  
  getOrganizations,
  organizationsList, organizationsCount,
  organization, setOrganization,
  
  getCustomerLevels,
  customerLevels, customerLevelsCount,
  customerLevel, setCustomerLevel,
  
  selectedRow,
  getCertificates, organizationsCertificateCount,
  organizationsCertificateList, setOrganizationsCertificatesList,
  organizationCertificate, setOrganizationCertificate,

  getFreelancerLevels,
  freelancerLevels, freelancerLevelsCount,
  freelancerLevel, setFreelancerLevel,

  getBookingCountry,
  bookingCountriesList, bookingCountriesCount,
  bookingCountry, setBookingCountry,
  
  getBookingProvinice, bookingProvinicesCount,
  bookingProvinicesList, setBookingProvinicesList,
  bookingProvinice, setBookingProvinice,
  
  getBookingCity, bookingCitiesCount,
  bookingCitiesList, setBookingCitiesList,
  bookingCity, setBookingCity,
) => {
  const parent = "notification_user";
  return [
    {
      head: "Notification  information",
      list: [
        {
          name: `user_type`,
          kind: "select",
          type: "text",
          label: "User type",
          width: "45%",
          options: notificationsUsersTypes,
        },
        {
          name: `country_id`,
          kind: "select",
          type: "text",
          label: "Country",
          width: "45%",
          options: countriesList,
          count: countriesCount,
          getList: getCountry,
          value: country,
          onChange: (val) => {
            setCountry(val);
            setProvinice(null);
            setCity(null);
            setProvinicesList([]);
            setCitiesList([]);
          },
          onClear: () => {
            setCountry(null);
            setProvinice(null);
            setCity(null);
            setProvinicesList([]);
            setCitiesList([]);
          },
          onOpen: () => countriesList.length === 0 && getCountry()
        },
        {
          name: `city_id`,
          kind: "select",
          type: "text",
          label: "Province",
          width: "45%",
          options: provinicesList,
          count: provinicesCount,
          getList: getProvinice,
          value: provinice,
          moreFilterData: {country_id: country?.value},
          onChange: (val) => {
            setProvinice(val);
            setCity(null);
            setCitiesList([]);
          },
          onClear: () => {
            setProvinice(null)
            setCity(null);
            setCitiesList([]);
          },
          onOpen: () => {(provinicesList.length === 0 && country?.value) && getProvinice({page_number: 1, country_id: country.value})}
        },
        {
          name: `area_id`,
          kind: "select",
          type: "text",
          label: "City",
          width: "45%",
          options: citiesList,
          count: citiesCount,
          getList: getCity,
          value: city,
          moreFilterData: {city_id: provinice?.value},
          onChange: (val) => {setCity(val)},
          onClear: () => {setCity(null)},
          onOpen: () => {(citiesList.length === 0 && provinice?.value) && getCity({page_number: 1, city_id: provinice.value})}
        },
        (selectedRow?.formikData?.values?.user_type == "DiveClub" ||
          selectedRow?.formikData?.values?.user_type == "Customer") && {
          name: `booking_country_id`,
          kind: "select",
          type: "text",
          label: "Bookings' country",
          width: "45%",
          options: bookingCountriesList,
          count: bookingCountriesCount,
          getList: getBookingCountry,
          value: bookingCountry,
          onChange: (val) => {
            setBookingCountry(val);
            setBookingProvinice(null);
            setBookingCity(null);    
            setBookingProvinicesList([]);
            setBookingCitiesList([]);        
          },
          onClear: () => {
            setBookingCountry(null);
            setBookingProvinice(null);
            setBookingCity(null);
            setBookingProvinicesList([]);
            setBookingCitiesList([]);
          },
          onOpen: () => bookingCountriesList.length === 0 && getBookingCountry()
        },
        (selectedRow?.formikData?.values?.user_type == "Customer" ||
          selectedRow?.formikData?.values?.user_type == "Freelancer") && {
          name: `organization_id`,
          kind: "select",
          type: "text",
          label: "Organization",
          width: "45%",
          options: organizationsList,
          count: organizationsCount,
          getList: getOrganizations,
          value: organization,
          onChange: (val) => {
            setOrganization(val);
            setOrganizationCertificate(null);
            setOrganizationsCertificatesList([]);
          },
          onClear: () => {
            setOrganization(null);
            setOrganizationCertificate(null);
            setOrganizationsCertificatesList([]);
          },
          onOpen: () => organizationsList.length === 0 && getOrganizations()
        },
        (selectedRow?.formikData?.values?.user_type == "DiveClub" ||
          selectedRow?.formikData?.values?.user_type == "Customer") && {
          name: `booking_city_id`,
          kind: "select",
          type: "text",
          label: "Bookings' province",
          width: "45%",
          options: bookingProvinicesList,
          count: bookingProvinicesCount,
          getList: getBookingProvinice,
          value: bookingProvinice,
          onChange: (val) => {           
            setBookingProvinice(val);
            setBookingCity(null);
            setBookingCitiesList([]);
          },
          onClear: () => {            
            setBookingProvinice(null);
            setBookingCity(null);
            setBookingCitiesList([]);
          },
          moreFilterData: {country_id: bookingCountry?.value},
          onOpen: () => {(bookingProvinicesList.length === 0 && bookingCountry?.value) && getBookingProvinice({page_number: 1, country_id: bookingCountry.value})}
        },
        (selectedRow?.formikData?.values?.user_type == "Customer" ||
          selectedRow?.formikData?.values?.user_type == "Freelancer") && {
          name: `organization_certificate_id`,
          kind: "select",
          type: "text",
          label: "Organization certificate",
          width: "45%",
          options: organizationsCertificateList,
          count: organizationsCertificateCount,
          getList: getCertificates,
          value: organizationCertificate,
          onChange: (val) => {
            setOrganizationCertificate(val);            
          },
          onClear: () => {
            setOrganizationCertificate(null);            
          },
          moreFilterData: {organization_id: organization?.value},
          onOpen: () => {(organizationsCertificateList.length === 0 && organization?.value) && getCertificates({page_number: 1, organization_id: organization.value})}
        },
        (selectedRow?.formikData?.values?.user_type == "DiveClub" ||
          selectedRow?.formikData?.values?.user_type == "Customer") && {
          name: `booking_area_id`,
          kind: "select",
          type: "text",
          label: "Bookings' city",
          width: "45%",
          options: bookingCitiesList,
          count: bookingCitiesCount,
          getList: getBookingCity,
          value: bookingCity,
          onChange: (val) => {
            setBookingCity(val);          
          },
          onClear: () => {            
            setBookingCity(null);            
          },
          moreFilterData: {city_id: bookingProvinice?.value},
          onOpen: () => {(bookingCitiesList.length === 0 && bookingProvinice?.value) && getBookingCity({page_number: 1, city_id: bookingProvinice.value})}
        },
        (selectedRow?.formikData?.values?.user_type == "DiveClub" ||
          selectedRow?.formikData?.values?.user_type == "Customer") && {
          name: `customer_level_id`,
          kind: "select",
          type: "text",
          label: "Customer level",
          width: "45%",
          options: customerLevels,
          count: customerLevelsCount,
          getList: getCustomerLevels,
          value: customerLevel,
          onChange: (val) => {setCustomerLevel(val)},
          onClear: () => {setCustomerLevel(null)},
          onOpen: () => customerLevels.length === 0 && getCustomerLevels({})
        },
        selectedRow?.formikData?.values?.user_type == "Freelancer" && {
          name: `dive_expert_level_id`,
          kind: "select",
          type: "text",
          label: "Freelancer level",
          width: "45%",
          options: freelancerLevels,
          count: freelancerLevelsCount,
          getList: getFreelancerLevels,
          value: freelancerLevel,
          onChange: (val) => {setFreelancerLevel(val)},
          onClear: () => {setFreelancerLevel(null)},
          onOpen: () => freelancerLevels.length === 0 && getFreelancerLevels({})
        },
      ],
    },

    {
      head: "Notification  message",
      list: [
        {
          name: `notification.${lang}.title`,
          name: !index ? `${lang}.title` : `notification.${lang}.title`,
          kind: "input",
          type: "text",
          label: "Notification title",
          width: "100%",
        },
        {
          name: `notification.${lang}.message`,
          name: !index ? `${lang}.message` : `notification.${lang}.message`,
          kind: "input",
          type: "text",
          label: "Notification Message",
          width: "100%",
          rows: 4,
          multiline: true,
        },
      ],
    },
  ];
};

export const validationSchema = () =>
  Yup.object({
    // en: Yup.object().shape({
    //   name: Yup.string().required("required"),
    // }),
    // country_id: Yup.string().required("required"),
    // lookup_key: Yup.string().required("required"),
    // iso_code: Yup.string().required("required"),
  });

import React, { useState } from "react";
import Users from "../../../Components/Organisms/Users/Users.jsx";
import LogHistory from "../../../Components/Organisms/Users/LogHistory.jsx";

function LogHistoryTemplate(props) {
  const {
    filterObj,
    setFilterObj,
    logHistoryList,
    setLogHistoryList,
    openLoader,
    setOpenLoader,
    count,
    page,
    setPage,
    handlePageChange,
    usersPrivilages,
    rolesPrivilages,
    rolesData,
    getRolesData,
    rolesCount,
    getCSV
  } = props;

  const handleApplyFilterMenu = (obj) => {
    setFilterObj((prev) => {
      return { ...prev, ...obj, page_number: 1 };
    });
    setPage(1);
  };

  const handleResetFilterMenu = () => {
    setFilterObj({
      page_size: 10,
      page_number: 1,
      keyword: "",
      sort_by: "id",
    });
    setPage(1);
  };

  const [role,setRole]=useState(null)
  const [endDate,setEndDate]=useState(null)
  const [startDate,setStartDate]=useState(null)

  const filterList = [
    {
      title: "User role",
      type:'search',
      list: [{ id: "", name: "All" }, ...rolesData],
      getList: getRolesData,
      count: rolesCount,
      value:role,
      onMenuOpen: () => rolesData.length === 0 && getRolesData(),
      handleFilter:handleApplyFilterMenu,
      handleChange:setRole
    },
    {
      key:"from_datetime",
      title: "From",
      type:'date',
      value:startDate,
      handleFilter:handleApplyFilterMenu,
      handleChange:setStartDate,
      className:"border-r-10px basic-date-picker"
    },
    {
      key:"to_datetime",
      title: "To",
      type:'date',
      value:endDate,
      handleFilter:handleApplyFilterMenu,
      handleChange:setEndDate,
      className:"border-r-10px basic-date-picker"


    },

  ];

  return (
    <>
      <LogHistory
        filterObj={filterObj}
        setFilterObj={setFilterObj}
        logHistoryList={logHistoryList}
        setLogHistoryList={setLogHistoryList}
        openLoader={openLoader}
        setOpenLoader={setOpenLoader}
        count={count}
        page={page}
        handlePageChange={handlePageChange}
        usersPrivilages={usersPrivilages}
        rolesPrivilages={rolesPrivilages}
        filterList={filterList}
        handleApplyFilterMenu={handleApplyFilterMenu}
        handleResetFilterMenu={handleResetFilterMenu}
        getCSV={getCSV}
      />
    </>
  );
}

export default LogHistoryTemplate;

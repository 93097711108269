import { axiosFn, handleError } from "..";
import { makeFilterString } from "../Helper/Helper";
import * as URL from "../URLs";

export async function getSponsersList(filter_obj) {
  return await axiosFn("get", URL.Sponsor + makeFilterString(filter_obj))
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}

export async function createSponser({ data, action }) {
  return await axiosFn("post", URL.Sponsor, data)
    .then((res) => {
      action && action();
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}

export async function getSponserDetails(organization_id) {
  return await axiosFn("get", `${URL.Sponsor}/${organization_id}`)
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}

export async function deleteSponser(id, action) {
  return await axiosFn("delete", `${URL.Sponsor}/${id}`)
    .then((res) => {
      action && action();
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}

export async function updateSponser({ id, data, action }) {
  return await axiosFn("put", `${URL.Sponsor}/${id}`, data)
    .then((res) => {
      action && action();
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}

import { FormControlLabel, Grid, Switch } from '@mui/material'
import React from 'react'
import { styled } from '@mui/material/styles';

const RoleSwitch = ({crudIndex,  val , change, disabled,label,sx}) => {
  
  const CustomSwitch = styled((props) => (
    <Switch 
        focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} 
        name={val?.name}   checked={val?.val}  onChange={ change }    sx={{...sx}}
    />))(({ theme }) => ({
        width: 42,
        height: 26,
        padding: 0,
        '& .MuiSwitch-switchBase': {
            padding: 0,
            margin: 2,
            transitionDuration: '300ms',
            '&.Mui-checked': {
                transform: 'translateX(16px)',
                color: '#fff',
                '& + .MuiSwitch-track': {
                    backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#faa100',
                    opacity: 1,
                    border: 0,
                },
                '&.Mui-disabled + .MuiSwitch-track': {
                    opacity: 0.5,
                },
            },
            '&.Mui-focusVisible .MuiSwitch-thumb': {
                color: '#33cf4d',
                border: '6px solid #fff',
            },
            '&.Mui-disabled .MuiSwitch-thumb': {
                color:
                    theme.palette.mode === 'light'
                        ? theme.palette.grey[100]
                        : theme.palette.grey[600],
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
            },
        },
        '& .MuiSwitch-thumb': {
            boxSizing: 'border-box',
            width: 22,
            height: 22,
        },
        '& .MuiSwitch-track': {
            borderRadius: 26 / 2,
            backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
            opacity: 1,
            transition: theme.transitions.create(['background-color'], {
                duration: 500,
            }),
        },
    }));
  return (

<FormControlLabel
  disabled={disabled}
  control={<CustomSwitch />}
  label={label}
  componentsProps={{ typography: { marginLeft: 1 } }} // Adjust the spacing as needed
/>
    )
}

export default RoleSwitch
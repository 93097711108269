import { axiosFn, handleError } from "..";
import { makeFilterString } from "../Helper/Helper";
import * as URL from "../URLs";

export async function getProvincesList(filter_obj) {
  return await axiosFn("get", URL.Provinces + makeFilterString(filter_obj))
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}

export async function createProvinces({ data, action }) {
  return await axiosFn("post", URL.Provinces, data)
    .then((res) => {
      action && action();

      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}

export async function getProvincesDetails(organization_id) {
  return await axiosFn("get", `${URL.Provinces}/${organization_id}`)
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}

export async function deleteProvinces(id, action) {
  return await axiosFn("delete", `${URL.Provinces}/${id}`)
  .then((res) => {
      action && action(res?.data);
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}

export async function updateProvinces({ id, data, action }) {
  return await axiosFn("put", `${URL.Provinces}/${id}`, data)
    .then((res) => {
      action && action();
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}

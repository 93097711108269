import * as Yup from "yup";
import { icons } from "../../../../Assets/AssetHelper";

export const initialValues = (data) => ({
  ...data,
  value: data?.value,
  description: data?.description,
});

export const structure = (key) => [
  {
    head: `${key} information`,
    list: [
      {
        name: `value`,
        kind: "input",
        type: "text",
        label: "Value",
        width: "100%",
        disabled: key == 'dive_center_contract' && true,
      },
      key == 'dive_center_contract' && {
        name: `contract`,
        kind: "upload",
        type: "file",
        label: "choose file",
        width: "100%",
        validation: 'pdf',
        displayEmptyState: true,
        id:0,//this was needed in the component already as a key
        //we need to add for upload component
        //1.icon before choosing a file (file icon) , 2.icon on select or option to show file itself for ex an img should be shown..
        uploadIcon:icons.addImage,
        sx:{width:'70px',height:'70px'},//style the container itself
        sxDeleteIcon:{width:'28px',height:'28px'},//to resize delete icons
        sxDelete:{bottom: '-20px',right: '-20px'}//to position the icon as wanted
      },
      {
        name: `unit`,
        kind: "input",
        type: "text",
        label: "Unit",
        width: "100%",
        disabled: true,
      },
      {
        name: `description`,
        kind: "textArea",
        type: "text",
        label: "Description",
        width: "100%",
        rows: 4,
      },
    ],
  },
];

export const validationSchema = (key) =>
  Yup.object({
    value: Yup.string().required("required"),
    description: Yup.string().required("required"),
    contract: key == 'dive_center_contract' && Yup.string().required("required"),
  });

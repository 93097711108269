import { Button, Modal, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import React from 'react'
import './InfoModal.css';
const InfoModal = ({open, setOpen, text}) => {
    
    const handleClose = () => {
        setOpen(false);     
    }
  return (
    <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
    >
        <Stack alignItems='center' justifyContent='center' className='info-modal'>
            <Typography component="h1" style={{ color: '#f6b119', fontWeight: "bold", fontSize: "30px" }} >
            { text[0] }
            </Typography>
            <Typography textAlign='center' style={{ fontWeight: "bold" }}>
                {text[1]}
            </Typography>
            
            <Stack flexDirection='row' alignItems='center' mt={4} className='buttons-wrap'>
                <Button style={{ width: '150px' }} className='primary r-25px' onClick={handleClose}> Done </Button>
            </Stack>
        </Stack>

    </Modal>
  )
}

export default InfoModal
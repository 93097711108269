import { Chip, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { icons } from "../../../Assets/AssetHelper";
import PrimaryButton from "../../Atoms/Buttons/Primary/PrimaryButton";
import { updateCustomer } from "../../../Contexts/APIs/Customer/Customer";
import { useNavigate, useParams } from "react-router-dom";
import DeleteModal from "../General/Models/DeleteModal";

function CustomerDetailsHeader({ data, roles }) {
  const iconslist = {
    Bronze: icons.BronzeIcon,
    Silver: icons.SilverIcon,
    Platinum: icons.PlatinumIcon,
    Gold: icons.GoldIcon,
  };
  const { customerId } = useParams();
  const navigate = useNavigate();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const handleDeleteRequest = async () => {
    const {res, err} = await updateCustomer({
      "customer_id": customerId,
      "user": {
          'status': "deleted"
      },
    });
    
    if(res){
      setOpenDeleteModal(false);
      navigate("/customers");
    }
  };
  return (
    <>
    <Stack
      alignItems="start"
      flexDirection="row"
      justifyContent="space-between"
      gap={1}
    >
      <Stack
        boxShadow="0 2px 15px 0 rgba(0, 0, 0, 0.1)"
        borderRadius="10px"
        py="1rem"
        pr="4rem"
        pl="2.5rem"
        flexDirection="row"
        gap={3}
        width="100%"
        sx={{
          backgroundImage:
            data?.level?.name === "Gold"
              ? "linear-gradient(to left, #f3d163 0%, #dfa440)"
              : data?.level?.name === "Platinum"
              ? "linear-gradient(to left, #c2e0f0 0%, #9ac5db)"
              : data?.level?.name === "Silver"
              ? "linear-gradient(to left, #c8c8c8 0%, #8a8a8a)"
              : "linear-gradient(to left, #dbb06e 0%, #804a00)",
        }}
      >
        {data?.avatar && (
          <img
            src={data?.avatar}
            width="90px"
            height="90px"
            style={{ borderRadius: "50%" }}
          />
        )}
        <Stack
          flexDirection="row"
          justifyContent="space-between"
          width="100%"
          className="details-header-content"
        >
          <Stack flexDirection="column" justifyContent="space-evenly">
            <Stack flexDirection="row" gap={2} alignItems="center">
              <Typography
                fontSize="29px"
                fontWeight="bold"
                color="white"
                textTransform="capitalize"
              >{`${data.firstname} ${data.lastname}`}</Typography>
            </Stack>
            <Stack flexDirection="row" gap={2} alignItems="center">
              <Chip
                icon={<img src={iconslist[data?.level?.name]} alt="points" />}
                label={`${data?.level?.name} member`}
                className={`details-header-content-chip ${
                  data?.level?.name === "Bronze"
                    ? "details-header-content-chip-bronze"
                    : data?.level?.name === "Gold"
                    ? "details-header-content-chip-gold"
                    : data?.level?.name === "Platinum"
                    ? "details-header-content-chip-platinum"
                    : data?.level?.name === "Silver"
                    ? "details-header-content-chip-silver"
                    : null
                }`}
              />
            </Stack>
          </Stack>
          <Stack
            flexDirection="row"
            gap={2}
            alignItems="center"
            justifyContent="center"
          >
            <Typography
              fontSize="20px"
              fontWeight="bold"
              color="white"
              display="block"
              textTransform="capitalize"
            >
              Status : {data?.status}
            </Typography>
            {
              (roles?.can_delete && roles?.can_read) ? <PrimaryButton
                btnType="icon"
                startIcon={<img src={icons.DeleteIcon} alt="delete" />}
                click={() =>  setOpenDeleteModal(true)}
              />: null
            }
          </Stack>
        </Stack>
      </Stack>
      <Stack
        boxShadow="0 2px 15px 0 rgba(0, 0, 0, 0.1)"
        flexDirection="column"
        gap={1}
        width="100%"
        borderRadius="10px"
        py="11px"
      >
        <Typography color="#aaa" fontSize="20px" fontWeight="600" pl={3}>
          Available points
        </Typography>
        <Typography color="#f6b119" fontSize="32px" fontWeight="bold" pl={3}>
          {data?.available_points}
        </Typography>
      </Stack>
    </Stack>
    {
      openDeleteModal && <DeleteModal
          open={openDeleteModal} setOpen={setOpenDeleteModal} 
          handelDeleteRequest={handleDeleteRequest}
          handleCancelDelete={()=>setOpenDeleteModal(false)}
          text={[`Delete ${`${data.firstname} ${data.lastname}`}`, `are you sure that you want to delete ${`${data.firstname} ${data.lastname}`}`]}
      />
    }
    </>
  );
}

export default CustomerDetailsHeader;

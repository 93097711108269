import React, { useEffect } from "react";
import { useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import Languages from "../../../Components/Atoms/Shared/language/Language";
import CCrud from "../../../Components/Molecules/General/Crud/CCrud";
import "../../../Components/Atoms/Shared/Styles/list-page.css";
import { initialValues, structure, validationSchema } from "./structure";
import AddIcon from "@mui/icons-material/Add";
import { createNotifications } from "../../../Contexts/APIs/Notifications/Notifications";
import { Box } from "@mui/material";
import "./Notifications.css";
import { handlePageChange } from "../../../Contexts/APIs/Helper/Helper";

const NotificationsTemplate = ({
  setFilterObj,
  getCountry,
  countriesList,
  countriesCount,
  getCity,
  citiesList,
  setCitiesList,
  citiesCount,
  getProvinice,
  provinicesList,
  setProvinicesList,
  provinicesCount,
  getOrganizations,
  organizationsList,
  organizationsCount,
  getCustomerLevels,
  customerLevels,
  customerLevelsCount,
  data,
  openDeleteModal,
  setOpenDeleteModal,
  handleDeleteRequest,
  count,
  paginationPage,
  setPaginationPage,
  handleChange,
  rowData,
  notificationData,
  headerChildren,
  selectedRow,
  getCertificates,
  organizationsCertificateCount,
  organizationsCertificateList,
  setOrganizationsCertificatesList,
  breadcrumbs,
  getFreelancerLevels,
  freelancerLevels,
  freelancerLevelsCount,

  getBookingCountry,
  bookingCountriesList,
  bookingCountriesCount,
  getBookingProvinice,
  bookingProvinicesList,
  setBookingProvinicesList,
  bookingProvinicesCount,
  getBookingCity,
  bookingCitiesList,
  setBookingCitiesList,
  bookingCitiesCount,
  handleResetNotificationFormDdls
}) => {
  let [searchParams, setSearchParams] = useSearchParams();
  const index = searchParams.get("index");
  const navigate = useNavigate();
  const [supportedLang, setSupportedLang] = useState("en");

  const [country, setCountry] = useState(null);
  const [provinice, setProvinice] = useState(null);
  const [bookingProvinice, setBookingProvinice] = useState(null);
  const [bookingCountry, setBookingCountry] = useState(null);
  const [bookingCity, setBookingCity] = useState(null);
  const [city, setCity] = useState(null);
  const [organization, setOrganization] = useState(null);
  const [organizationCertificate, setOrganizationCertificate] = useState(null);
  const [customerLevel, setCustomerLevel] = useState(null);
  const [freelancerLevel, setFreelancerLevel] = useState(null);

  const notificationsUsersTypes = [
    { name: "All", id: "All" },
    { name: "Freelancer", id: "Freelancer" },
    { name: "Customer", id: "Customer" },
    { name: "Dive center", id: "DiveCenter" },
    { name: "Dive club", id: "DiveClub" },
  ];
  const handelSubmit = (data, formik) => {
    const action = () => {
      searchParams.delete("id");
      searchParams.delete("type");
      searchParams.delete("index");
      navigate("/notifications");
      formik?.resetForm();
      setCountry(null);
      setProvinice(null);
      setBookingProvinice(null);
      setBookingCountry(null);
      setBookingCity(null);
      setCity(null);
      setOrganization(null);
      setOrganizationCertificate(null);
      setCustomerLevel(null);
      setFreelancerLevel(null);
      handleResetNotificationFormDdls();
    };

    const { notification: _, ...clone } = data;

    createNotifications({
      data: {
        notification_user: clone,
        notification: !index ? {
          de: data?.de,
          en: data?.en,
          fr: data?.fr,
          it: data?.it,
          nl: data?.nl,
          ru: data?.ru,
        } : data?.notification,
      },
      action,
    });
  };

  const handleApplyFilterMenu = (obj) => {
    handleChange && handlePageChange(1, handleChange);
    setFilterObj((prev) => {
      return { ...prev, ...obj, page_number: 1 };
    });
    setPaginationPage(1);
  };
  const handleResetFilterMenu = (obj) => {
    handleChange && handlePageChange(1, handleChange);
    setFilterObj({
      page_size: 10,
      page_number: 1,
      keyword: "",
      sort_by: "id",
    });
    setPaginationPage(1);
  };
  const mappedData = data?.map((val) => {
    return {
      ...val,
      notification_type: (
        <Box sx={{ width: "150px" }}>{val?.notification_type}</Box>
      ),
      title_data: <Box sx={{ width: "500px" }}>{val?.title}</Box>,
      action: (
        <Box sx={{ width: "100px" }}>
          <Link
            to={`/notifications/${val?.id}`}
            style={{ color: "black", textDecoration: "none", fontSize: "16px" }}
          >
            view details
          </Link>
        </Box>
      ),
    };
  });

  return (
    <CCrud
      table={{
        list: mappedData,
        openDeleteModal: openDeleteModal,
        setOpenDeleteModal: setOpenDeleteModal,
        rowData: rowData,
        listTitle: "Notifications",
        emptyListMsg: "There are no Notifications",

        columns: [
          { accessor: "id_data", Header: "Id" },
          { accessor: "notification_type", Header: "Notification type" },
          { accessor: "title_data", Header: "Title" },
          { accessor: "action", Header: "Actions" },
        ],
        isFilter: false,
        isSearch: true,
        isChecked: false,
        isSort: false,
        total_count: count,
        setFilterObj,
        handleApplyFilterMenu: handleApplyFilterMenu,
        handleResetFilterMenu: handleResetFilterMenu,
        createBtnTitle: "Add new notification",
        createBtnFun: () => navigate("?type=form"),
        CreateBtnIcon: AddIcon,
        createBtnClass: "add-organization-button",
        className: "organization-table  notification-organization-table",
        paginationPage: paginationPage,
        setPaginationPage: setPaginationPage,
        handlePageChange:handleChange,
        searchInputPlaceholder: "Search notification",
        openDeleteModal: openDeleteModal,
        setOpenDeleteModal: setOpenDeleteModal,
        handleDeleteRequest,
        headerChildren: headerChildren,
      }}
      form={{
        structure: () => structure(
          supportedLang,
          index,
          getCountry,
          countriesList,
          countriesCount,
          country, setCountry,
          notificationsUsersTypes,
          getCity, citiesCount,
          citiesList, setCitiesList,
          city, setCity,

          getProvinice, provinicesCount,
          provinicesList, setProvinicesList,
          provinice, setProvinice,

          getOrganizations,
          organizationsList, organizationsCount,
          organization, setOrganization,

          getCustomerLevels,
          customerLevels, customerLevelsCount,
          customerLevel, setCustomerLevel,

          selectedRow,
          getCertificates, organizationsCertificateCount,
          organizationsCertificateList, setOrganizationsCertificatesList,
          organizationCertificate, setOrganizationCertificate,
          
          getFreelancerLevels,
          freelancerLevels, freelancerLevelsCount,
          freelancerLevel, setFreelancerLevel,

          getBookingCountry,
          bookingCountriesList, bookingCountriesCount,
          bookingCountry, setBookingCountry,
          
          getBookingProvinice, bookingProvinicesCount,
          bookingProvinicesList, setBookingProvinicesList,
          bookingProvinice, setBookingProvinice,
          
          getBookingCity, bookingCitiesCount,
          bookingCitiesList, setBookingCitiesList,
          bookingCity, setBookingCity
        ),
        validationSchema: () => validationSchema(supportedLang),
        initialValues: !index ? {} : initialValues(notificationData, supportedLang),
        onSubmit: handelSubmit,
        setSupportedLang: setSupportedLang,
        className: { form: "flow-form", actionButton: "flow-form-buttons" },
        breadcrumbs: breadcrumbs,
        handleLanguage: <Languages setLanguage={setSupportedLang} />,
        title: index ? "Edit Notification" : "Add new Notification",
        submitTitle: index === null ? "Add" : "Save changes",
      }}
    />
  );
};

export default NotificationsTemplate;

import React, { useState, useEffect } from "react";
import { getRoleList } from "../../../../Contexts/APIs/Users/Roles";
import RolesTemplate from "../../../../Templates/Main/Users/Roles/Roles.template";
import { useSearchParams } from "react-router-dom";
import usePageFilter from "../../../../Components/Atoms/Hooks/usePageFilter";

function RolesPage({ usersPrivilages }) {
  const [rolesData, setRolesData] = useState([]);
  const [openLoader, setOpenLoader] = useState(false);
  const [count, setCount] = useState(0);
  const { page, filterObj, handlePageChange ,setFilterObj} = usePageFilter();

  const getRolesData = async (filterObj) => {
    setOpenLoader(true);
    const defaultFilter = {
      page_size: 10,
      page_number: 1,
      keyword: "",
      sort_by: "id",
    };
    const { res, err } = await getRoleList({ ...defaultFilter, ...filterObj });
    if (res) {
      setOpenLoader(false);
      setRolesData(res?.data?.roles);
      setCount(res?.extra?.total_count);
    }
    if (err) {
      setOpenLoader(false);
    }
  };

  useEffect(() => {
    const timeId = setTimeout(() => {
      getRolesData(filterObj);
    }, 500);
    return () => clearTimeout(timeId);
  }, [filterObj]);
  return (
    <RolesTemplate
      filterObj={filterObj}
      setFilterObj={setFilterObj}
      rolesData={rolesData}
      setRolesData={setRolesData}
      openLoader={openLoader}
      setOpenLoader={setOpenLoader}
      count={count}
      page={page}
      handlePageChange={handlePageChange}
      usersPrivilages={usersPrivilages}
    />
  );
}

export default RolesPage;

import * as Yup from "yup";
export const initialValues = (data, lang) => ({
  ...data,
  ...data?.localized_data,
  country_id: {
    value: data?.city?.country?.id,
    label: data?.city?.country?.name,
  },
  lookup_key: data?.lookup_key,
});
export const structure = (
  lang,
  user_type,
  point_type,
  action_type,
  per_type
) => [
  {
    head: "Point configuration  information",
    list: [
      {
        name: `user_type`,
        kind: "select",
        type: "text",
        label: "User type",
        width: "47%",
        isDisabled: true,
        options: user_type,
      },
      {
        name: `num_of_points`,
        kind: "input",
        type: "number",
        label: "Num of points",
        width: "47%",
      },
      {
        name: `point_type`,
        kind: "select",
        type: "text",
        label: "Point type",
        width: "47%",
        isDisabled: true,
        options: point_type,
      },
      {
        name: `per_amount`,
        kind: "input",
        type: "number",
        label: "Per amount",
        width: "47%",
      },
      {
        name: `per_type`,
        kind: "select",
        type: "text",
        label: "Per type",
        width: "47%",
        isDisabled: true,
        options: per_type,
      },
      {
        name: `action_type`,
        kind: "select",
        type: "text",
        label: "Action type",
        width: "47%",
        options: action_type,
      },
    ],
  },
];

export const validationSchema = () => Yup.object({
  per_amount:Yup.number().min(0, 'Min value 0.'),
  num_of_points:Yup.number().min(0, 'Min value 0.')
});

import { Button, Stack, Typography } from "@mui/material";
import React from "react";

const FormHeader = ({ handleModelClose, form, formikData, refsubmit }) => {
  return (
    <Stack
      flexDirection="row"
      flexWrap="wrap"
      alignItems="center"
      justifyContent="space-between"
      borderBottom="1px solid rgb(217, 217, 217) "
    >
      <Typography fontSize="30px" fontWeight="600">
        {form?.title}
      </Typography>
      <Stack
        my={2}
        flexDirection="row"
        gap={2}
        className={form?.className?.actionButton}
      >
        {/* add custom header, add cancel, save to it */}
        <Button
          onClick={(e) => {
            handleModelClose();
            form?.setSupportedLang && form?.setSupportedLang("en");
          }}
          variant="outlined"
          sx={{
            borderRadius: "26px",
            color: "black",
            borderColor: " #ececec",
            p: "10px 20px 5px",
            border: "1px solid #ececec",
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={() => refsubmit?.current?.click()}
          variant="contained"
          sx={{
            borderRadius: "26px",
            color: "white",
          }}
        >
          {form?.submitTitle ? form?.submitTitle : "Save Changes"}
        </Button>
      </Stack>
    </Stack>
  );
};

export default FormHeader;

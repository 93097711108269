import React, { useState, useEffect } from 'react';
import Loader from '../../../../Components/Atoms/Loader/Loader';
import { useNavigate, useParams } from 'react-router-dom';
import { getSupportedLanguages } from '../../../../Contexts/APIs/Lookups/SupportedLanguages';
import { toast } from 'react-toastify';
import { deleteBadge, editBadge, getBadgeDetails } from '../../../../Contexts/APIs/Badges/Badges';
import BadgeDetailsTemplate from '../../../../Templates/Main/PredefinedLookups/Badges/BadgeDetails.template';

function BadgeDetailsPage({roles}) {
    const { badgeId } = useParams();
    const navigate = useNavigate();
    const [badgeData, setBadgeData] = useState({});
    const [openLoader, setOpenLoader] = useState(false);
    const [supportedLanguages, setSupportedLanguages] = useState([]);
    const [supportLang, setSupportLang] = useState("en");
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
            
    const getBadge = async (id) => {
        setOpenLoader(true);
        const { res, err } = await getBadgeDetails(id);
        if (res) { 
            setBadgeData(res?.data?.badge); 
        }
        if (err) {
            toast.error(err)
        }
        setOpenLoader(false);        
    };

    const getLanguages = async () => {
        const { res, err } = await getSupportedLanguages();
        if (res) {
        setSupportedLanguages(res?.data?.supported_locales);
        }
    };
    const updateSupportedLang = (e, languages) => {
        setSupportLang(e.target.value);
    };

    const handleDeleteRequest = async () => {
        const {res, err} = await deleteBadge(badgeId);
        if(res){
            setOpenDeleteModal(false);
            navigate('/predefined-lookups/badges');
        }
    };

    const handleEditBadge = async(values) => {        
        const {res, err} = await editBadge(
            badgeId, {
            "badge": values
            });
        if(res){ getBadge(badgeId); }
    }

    useEffect(() => {
        getBadge(badgeId);
        getLanguages();
    }, []);

  return (
    <>
        <Loader open={openLoader} />
        <BadgeDetailsTemplate
            badgeId={badgeId}
            badgeData={badgeData}
            supportedLanguages={supportedLanguages}
            updateSupportedLang={updateSupportedLang}
            supportLang={supportLang}
            openDeleteModal={openDeleteModal} setOpenDeleteModal={setOpenDeleteModal} 
            handleDeleteRequest={handleDeleteRequest}
            handleCancelDelete={() => setOpenDeleteModal(false)}
            handleEditBadge={handleEditBadge}
            roles={roles}
        />
    </>
  )
}

export default BadgeDetailsPage
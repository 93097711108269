import React, { useEffect, useState } from "react";
import OrganizationsTemplate from "../../../Templates/Main/Organizations/Organizations.template";
import {
  createOrganization,
  deleteOrganization,
  editOrganization,
  getOrganizationDetails,
  getOrganizationsList,
} from "../../../Contexts/APIs/Organizations/Organizations";
import Loader from "../../../Components/Atoms/Loader/Loader";
import { useNavigate } from "react-router-dom";
import { getSupportedLanguages } from "../../../Contexts/APIs/Lookups/SupportedLanguages";
import { toast } from "react-toastify";
import usePageFilter from "../../../Components/Atoms/Hooks/usePageFilter";

function OrganizationsPage({roles}) {
  const navigate = useNavigate();
  // let [searchParams] = useSearchParams();
  const {
    page,
    filterObj,
    handlePageChange: onPageChange,
    setFilterObj,
    setPage,
    searchParams,
  } = usePageFilter();
  const index = searchParams.get("index");
  const type = searchParams.get("type");

  const organizationId = searchParams.get("id");
  const [openLoader, setOpenLoader] = useState(false);
  const [organizationsList, setOrganizationsList] = useState([]);

  const [count, setCount] = useState(0);
  // const [page, setPage] = useState(1);

  // const [filterObj, setFilterObj] = useState(null);

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [supportedLanguages, setSupportedLanguages] = useState([]);
  const [supportLang, setSupportLang] = useState("en");
  const [organizationData, setOrganizationData] = useState({});

  const mappedData = organizationsList?.map((data) => {
    return {
      ...data,
      view_certificates: (
        <button
          onClick={() => {
            navigate(
              `/organizations/${data.id}/certificates?orgName=${data.name}`
            );
          }}
        >
          View certificates
        </button>
      ),
    };
  });

  const breadcrumbs = [
    {
      active: true,
      path: "/organizations",
      title: "Organizations",
    },
    {
      active: false,
      title: index === null ? "Add organization" : "Edit organization",
    },
  ];

  // const updatePagination = (page) => {
  //   setFilterObj({ ...filterObj, page_number: page });
  // };
  // const handlePageChange = (event, value) => {
  //   setPage(value);
  //   updatePagination(value);
  // };

  const getLanguages = async () => {
    const { res, err } = await getSupportedLanguages();
    if (res) {
      setSupportedLanguages(res?.data?.supported_locales);
    }
  };
  const updateSupportedLang = (e) => {
    setSupportLang(e?.target?.value);
  };

  const handleDeleteRequest = async () => {
    const { res, err } = await deleteOrganization(selectedRow?.id);
    if (res) {
      setOpenDeleteModal(false);
      getOrganizations(filterObj);
      setFilterObj({ ...filterObj, page_number: 1 });
      setPage(1);
    }
  };

  const rowData = (data) => {
    setSelectedRow(data?.rowData);
  };

  const getOrganization = async () => {
    const { res, err } = await getOrganizationDetails(organizationId);
    if (res) {
      setOrganizationData(res?.data?.organization);
    }
    if (err) {
      toast.error(err);
    }
  };

  const getOrganizations = async (filterObj) => {
    setOpenLoader(true);
    const defaultFilterObj = {
      page_size: 10,
      page_number: 1,
      keyword: "",
      sort_by: "id",
    };
    const { res, error } = await getOrganizationsList({
      ...defaultFilterObj,
      ...filterObj,
    });
    if (res) {
      setOpenLoader(false);
      setOrganizationsList(res?.data?.organizations);
      setCount(res?.extra?.total_count);
    }
    if (error) {
      setOpenLoader(false);
    }
  };

  const handleCreateOrganization = async (values) => {
    const { res, err } = await createOrganization({
      organization: values,
    });
    if (res) {
      navigate("/organizations");
    }
  };
  const handleEditOrganization = async (values) => {
    const { res, err } = await editOrganization(organizationId, {
      organization: values,
    });
    if (res) {
      navigate("/organizations");
    }
  };
  const handleSubmit = (data, formik) => {
    const action = () => {
      searchParams.delete("id");
      searchParams.delete("type");
      searchParams.delete("index");
      formik.resetForm();
    };
    if (index) {
      handleEditOrganization(data);
      action();
    } else {
      handleCreateOrganization(data);
      action();
    }
  };

  useEffect(() => {
    if (type == "form") getLanguages();
  }, [type]);

  useEffect(() => {
    organizationId && getOrganization(organizationId);
  }, [organizationId]);

  useEffect(() => {
    if (filterObj) getOrganizations(filterObj);
  }, [filterObj]);

  return (
    <>
      <Loader open={openLoader} />
      <OrganizationsTemplate
        filterObj={filterObj}
        setFilterObj={setFilterObj}
        data={mappedData}
        count={count}
        paginationPage={page}
        setPaginationPage={setPage}
        handlePageChange={onPageChange}
        openDeleteModal={openDeleteModal}
        setOpenDeleteModal={setOpenDeleteModal}
        handleDeleteRequest={handleDeleteRequest}
        selectedRow={selectedRow}
        setSelectedRow={setSelectedRow}
        supportedLanguages={supportedLanguages}
        updateSupportedLang={updateSupportedLang}
        setSupportLang={setSupportLang}
        supportLang={supportLang}
        handleSubmit={handleSubmit}
        rowData={rowData}
        organizationData={organizationData}
        breadcrumbs={breadcrumbs}
        roles={roles}
      />
    </>
  );
}

export default OrganizationsPage;

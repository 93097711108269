import React, { useEffect, useState } from "react";
import Loader from "../../../../Components/Atoms/Loader/Loader";
import { useSearchParams } from "react-router-dom";

import FreelancerLevelsTemplate from "../../../../Templates/Main/Levels/Freelancer/Freelancer.template";
import {
  getFreelancerLevelsDetails,
  getFreelancerLevelsList,
  updateFreelancerLevels,
} from "../../../../Contexts/APIs/Levels/Freelancer/freelancer";
import { CircularProgress, Stack } from "@mui/material";

function FreelancerLevelsPage({ roles }) {
  let [searchParams] = useSearchParams();
  const id = searchParams.get("id");

  const [openLoader, setOpenLoader] = useState(false);
  const [freelancersLevelsList, setFreelancersLevelsList] = useState([]);

  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);

  const [filterObj, setFilterObj] = useState(null);
  const [isLoading, setLoading] = useState({
    index: null,
    status: false,
  });
  const [selectedRow, setSelectedRow] = useState({});
  const [freelancersLevelsData, setFreelancersLevelsData] = useState({});

  const updatePagination = (page) => {
    setFilterObj({ ...filterObj, page_number: page });
  };

  const handlePageChange = (event, value) => {
    setPage(value);
    updatePagination(value);
  };

  const getFreelancerLevels = async (filterObj) => {
    setOpenLoader(true);
    const defaultFilterObj = {
      page_size: 10,
      page_number: 1,
      keyword: "",
      sort_by: "id",
    };
    const { res, error } = await getFreelancerLevelsList({
      ...defaultFilterObj,
      ...filterObj,
    });
    if (res) {
      setOpenLoader(false);
      setFreelancersLevelsList(res?.data?.levels);
      setCount(res?.extra?.total_count);
    }
    if (error) {
      setOpenLoader(false);
    }
  };
  const getgetFreelancerLevelsData = async () => {
    setOpenLoader(true);
    const { res, error } = await getFreelancerLevelsDetails(id);
    if (res) {
      setFreelancersLevelsData(res.data.level);
      setOpenLoader(false);
    }
    if (error) {
      setOpenLoader(false);
    }
  };

  useEffect(() => {
    getFreelancerLevels(filterObj);
  }, [filterObj]);

  useEffect(() => {
    id && getgetFreelancerLevelsData({});
  }, [id]);

  const rowData = (data) => {
    setSelectedRow(data?.rowData);
  };
  const breadcrumbs = [
    {
      active: true,
      path: "/levels/freelancer",
      title: "Freelancer levels",
    },
    {
      active: false,
      title: id ? "Edit freelancer level" : "Add new freelancer level",
    },
  ];

  const mappedData = freelancersLevelsList?.map((data, index) => {
    return {
      ...data,
      can_create_team_btn: (
        <Stack
          sx={{
            background: data?.can_create_team ? "#62c08b" : "#cc4d49",
            color: "white",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "10px",
            padding: "10px",
            height: "50px",
            width: "80px",
            cursor: "pointer",
          }}
          onClick={() => {
            const action = (index) => {
              setLoading({ index: index, status: true });

              let newList = freelancersLevelsList;
              newList[index].can_create_team = !data?.can_create_team;
              setFreelancersLevelsList(() => newList);
              setTimeout(() => {
                setLoading({ index: null, status: false });
              }, 300);
            };
            updateFreelancerLevels({
              id: data?.id,
              data: {
                level: {
                  ...data,
                  can_create_team: !data?.can_create_team,
                },
              },
              action: () => action(index),
            });
          }}
        >
          {isLoading?.status && isLoading?.index == index ? (
            <CircularProgress />
          ) : data?.can_create_team ? (
            "Yes"
          ) : (
            "No"
          )}
        </Stack>
      ),
      is_wallet_debit_limit_enabled_btn: (
        <Stack
          sx={{
            background: data?.is_wallet_debit_limit_enabled
              ? "#62c08b"
              : "#cc4d49",
            color: "white",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "10px",
            padding: "10px",
            height: "50px",
            width: "80px",
            cursor: "pointer",
          }}
          onClick={() => {
            const action = (index) => {
              setLoading({ index: index, status: true });

              let newList = freelancersLevelsList;
              newList[index].is_wallet_debit_limit_enabled =
                !data?.is_wallet_debit_limit_enabled;
              setFreelancersLevelsList(() => newList);
              setTimeout(() => {
                setLoading({ index: null, status: false });
              }, 300);
            };
            updateFreelancerLevels({
              id: data?.id,
              data: {
                level: {
                  ...data,
                  is_wallet_debit_limit_enabled:
                    !data?.is_wallet_debit_limit_enabled,
                },
              },
              action: () => action(index),
            });
          }}
        >
          {isLoading?.status && isLoading?.index == index ? (
            <CircularProgress />
          ) : data?.is_wallet_debit_limit_enabled ? (
            "Yes"
          ) : (
            "No"
          )}
        </Stack>
      ),
    };
  });
  return (
    <>
      <Loader open={openLoader} />
      <FreelancerLevelsTemplate
        filterObj={filterObj}
        setFilterObj={setFilterObj}
        getFreelancerLevels={getFreelancerLevels}
        data={mappedData}
        count={count}
        paginationPage={page}
        setPaginationPage={setPage}
        handlePageChange={handlePageChange}
        rowData={rowData}
        freelancersLevelsData={freelancersLevelsData}
        breadcrumbs={breadcrumbs}
        roles={roles}
      />
    </>
  );
}

export default FreelancerLevelsPage;

import React, { useEffect } from "react";
import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Languages from "../../../../Components/Atoms/Shared/language/Language";
import CCrud from "../../../../Components/Molecules/General/Crud/CCrud";
import "../../../../Components/Atoms/Shared/Styles/list-page.css";
import { initialValues, structure, validationSchema } from "./structure";
import AddIcon from "@mui/icons-material/Add";
import {
  createCity,
  updateCities,
} from "../../../../Contexts/APIs/Cities/Cities";
import "./Cities.css";
const CitiesTemplate = ({
  setFilterObj,
  countriesList,
  provincesList,
  setProvincesList,
  data,
  openDeleteModal,
  setOpenDeleteModal,
  handleDeleteRequest,
  rowData,
  count,
  paginationPage,
  setPaginationPage,
  handlePageChange,
  cityData,
  handleApplyFilterMenu,
  handleResetFilterMenu,
  breadcrumbs,
  setFilterData,
  getCountry,
  getProvinces,
  roles,
  selectedRow,
  provincesCount,
  countriesCount
}) => {
  const searchInputPlaceholder = "Search City name";
  let [searchParams, setSearchParams] = useSearchParams();
  const index = searchParams.get("index");
  const type = searchParams.get("type");

  const id = searchParams.get("id");
  const navigate = useNavigate();
  const [supportedLang, setSupportedLang] = useState("en");

  const [country, setCountry] = useState(null);
  const [provinice, setProvinice] = useState(null);

  const handelSubmit = (data, formik) => {
    const action = () => {
      searchParams.delete("id");
      searchParams.delete("type");
      searchParams.delete("index");
      navigate("/geolocations/cities");
      formik?.resetForm();
    };

    if (index) {
      updateCities({ id, data: { area: data }, action });
    } else {
      createCity({
        data: { area: data },
        action,
      });
    }
  };

  const actionsList = ({ id, index, details }) => {
    return [
      {
        condition: () => {
          if (roles?.can_read && roles?.can_update) {
            return true;
          } else {
            return false;
          }
        },
        click: () => {
          navigate(
            `?type=form&id=${id}&index=${index}&country_id=${selectedRow?.rowData?.city?.country?.id}`
          );
        },
        text: "Edit",
      },
      {
        condition: () => {
          if (roles?.can_read && roles?.can_delete) {
            return true;
          } else {
            return false;
          }
        },
        click: () => {
          setOpenDeleteModal(true);
        },
        text: "Delete",
      },
    ];
  };

  const [filterCountry, setFilterCountry] = useState(null);
  const filterList = [
    {
      key: "country_id",
      title: "Country",
      list: countriesList,
      count: countriesCount,
      getList: getCountry,
      relatedResetFilterData: { city_id: null },
      onChange: (countryId) => {
        setFilterCountry(countryId);
        setProvincesList([]);
      },
      onClear: () => {
        setFilterCountry(null);
        setProvincesList([]);
      },
      onMenuOpen: () => countriesList.length === 0 && getCountry()
    },
    {
      key: "city_id",
      title: "Province",
      list: provincesList,
      count: provincesCount,
      getList: getProvinces,
      moreFilterData: {country_id: filterCountry},
      onMenuOpen: () => {(provincesList.length === 0 && filterCountry) && getProvinces({page_number: 1, country_id: filterCountry})}

    },
  ];

  return (
    <CCrud
      table={{
        list: data,
        filterList,
        setFilterData,
        listTitle: "Cities",
        emptyListMsg: "There are no City",
        details: {
          navigatePath: "/geolocations/cities",
          statusField: null,
          hideStatus: null,
        },
        actionsList: actionsList,
        handleApplyFilterMenu: handleApplyFilterMenu,
        handleResetFilterMenu: handleResetFilterMenu,
        columns: [
          { accessor: "id", Header: "Id" },
          { accessor: "image", Header: "Image" },
          { accessor: "name", Header: "City name" },
          { accessor: "geoNames", Header: "Province/Country" },
          { accessor: "lookup_key", Header: "Lookup key" },
          { accessor: "min_rate", Header: "Freelancer Min Rate" },
          { accessor: "Actions", Header: "Actions" },
        ],
        isFilter: true,
        isSearch: true,
        isChecked: false,
        isSort: false,
        total_count: count,
        setFilterObj,
        createBtnTitle: "Add new city",
        rowData: rowData,
        createBtnFun:
          roles?.can_read && roles?.can_create
            ? () => navigate("?type=form")
            : null,
        CreateBtnIcon: AddIcon,
        createBtnClass: "add-organization-button",
        className: "organization-table cities-table",
        tableContainerOverflow: {overflow: 'auto'},
        paginationPage: paginationPage,
        setPaginationPage: setPaginationPage,
        searchInputPlaceholder: searchInputPlaceholder,
        handlePageChange,
        openDeleteModal: openDeleteModal,
        setOpenDeleteModal: setOpenDeleteModal,
        handleDeleteRequest,
      }}
      form={{
        structure: () => structure(supportedLang, countriesList, countriesCount, getCountry, country, setCountry,
          provincesList, setProvincesList, provincesCount, getProvinces, provinice, setProvinice,
        ),
        validationSchema: validationSchema,
        initialValues: !index ? {} : initialValues(cityData, supportedLang),
        onSubmit: handelSubmit,
        className: { form: "flow-form", actionButton: "flow-form-buttons" },
        submitTitle: index === null ? "Add" : "Save changes",
        setSupportedLang: setSupportedLang,
        handleLanguage: <Languages setLanguage={setSupportedLang} />,
        formHead: "City  information",
        title: index ? `Edit "${cityData?.name}"` : "Add new city",
        breadcrumbs: breadcrumbs,
      }}
    />
  );
};

export default CitiesTemplate;

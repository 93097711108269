import React from 'react'
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';

function FormHeadIcon({text, children}) {
    const style = {        
        fontSize: "20px",
        fontWeight: "bold",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "1.5",
        letterSpacing: "normal",
        textAlign: "left",
        color: "#333132",
        borderBottom: "2px solid var(--primary-color)",
        width: "fit-content",
        display: "flex", 
        alignItems: "center",
        gap: "0.75rem"
    };
  return (
    <Typography component="h1" className="mb-20px" style={style}>
        {children}{text}
    </Typography>
  )
}

FormHeadIcon.propTypes = {
    text: PropTypes.string
}

export default FormHeadIcon
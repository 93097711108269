import { Grid } from '@mui/material'
import React from 'react'
import ActivityGroupInfo from '../../Molecules/Activities/ActivityGroup/ActivityGroupInfo';
import ActivityGroupHeader from '../../Molecules/Activities/ActivityGroup/ActivityGroupHeader';
import ListGroupsForActivities from '../../Molecules/Activities/ActivityGroup/ListGroupsForActivities';

function ActivityGroups(props) {
  const { count, activityGroups, activityInfo, filterObj, setFilterObj, page, setPage, handlePageChange } = props;
  const updateStartDate = ( date ) => {
    setFilterObj({ ...filterObj, 'page_number': 1, 'date[from]': date });
    setPage(1);
  };
  const updateEndDate = ( date ) => {
    setFilterObj({ ...filterObj, 'page_number': 1, 'date[to]': date });
    setPage(1);
  };
  const updateGroupStatus = (value) => {
    setFilterObj({ ...filterObj, 'page_number': 1, 'status': value });
    setPage(1);
  };
  return (
    <Grid container className="reservations-container">
        <ActivityGroupInfo
            activityInfo={activityInfo}
        />
        <ActivityGroupHeader
            updateStartDate={updateStartDate}
            updateEndDate={updateEndDate}
            updateGroupStatus={updateGroupStatus}
        />
        <ListGroupsForActivities
            count={count}
            activityGroups={activityGroups}
            page={page}
            setPage={setPage}
            handlePageChange={handlePageChange}
        />
    </Grid>
  )
}

export default ActivityGroups
import * as Yup from "yup";
import { facility_type } from "./filter_data";
import { icons } from "../../../../Assets/AssetHelper";
export const initialValues = (data) => ({
  ...data,
  ...data?.localized_data,
  en: {
    name: data?.name,
  },
  reservations_required: data?.reservations_required,
  inc_points_earned_percent: data?.flat_points_earned_inc,
});
export const structure = (lang) => [
  {
    head: "Feature option information",
    list: [
      {
        name: `${lang}.name`,
        kind: "input",
        type: "text",
        label: "Feature option name",
        width: "100%",
      },
      {
        name: `facility_type`,
        kind: "select",
        type: "number",
        label: "Facility type",
        width: "100%",
        options: facility_type,
      },
      {
        name: `is_hidden`,
        kind: "checkbox",
        type: "number",
        label: "Is Hidden",
        width: "100%",
      },
    ],
  },
  {
    head: "Feature option icon",
    list: [
      {
        name: `icon`,
        kind: "upload",
        uploadIcon:icons.addImage,
        type: "text",
        label: "Add feature option icon",
        width: "45%",
      },
    ],
  },
];

export const validationSchema = () =>
  Yup.object({
    en: Yup.object().shape({
      name: Yup.string().required("required"),
    }),
    facility_type: Yup.string().required("required"),
    // icon: Yup.string().required("required"),
  });

import { Box, Grid, IconButton, Menu, MenuItem, MenuList } from "@mui/material";
import { useEffect, useState } from "react";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import "./style.css";
import { DatePicker, StaticDatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import BasicDatePicker from "../../../Atoms/Input/DatePicker/DatePicker";

const ReservationFilter = ({
  head,
  dataList,
  handleFilter,
  type,
  handleClickFun,
  defaultValue,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedData, setSelectedData] = useState(null);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    handleClickFun && handleClickFun();
  };

  useEffect(() => {
    if (defaultValue) {
      const filerData = dataList?.find((val) => val?.id == defaultValue);
      setSelectedData(filerData?.name);
    }
  }, [defaultValue, dataList]);
  const handleClose = () => setAnchorEl(null);

  return type == "date" ? (
    <Box width="150px">
      <BasicDatePicker
        placeholder={head}
        setValue={(val) => handleFilter(dayjs(val).format("YYYY-MM-DD"))}
      />
    </Box>
  ) : (
    <>
      <IconButton
        id={`demo-positioned-btn${head}`}
        aria-controls={open ? `demo-positioned-menu${head}` : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        className="filter-menu-button"
        style={{ height: "57px" }}
      >
        {selectedData ? selectedData : head}
        <KeyboardArrowDownRoundedIcon style={{ color: "black" }} />
      </IconButton>
      <Menu
        sx={{
          "& .MuiPaper-root": {
            width: type == "date" ? "300px" : "auto",
          },
        }}
        id={`demo-positioned-menu${head}`}
        aria-labelledby={`demo-positioned-btn${head}`}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        className="filter-menu-list"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <MenuList>
          {dataList.map((item, index) => {
            return (
              <MenuItem
                key={index}
                onClick={() => {
                  handleFilter(item?.id);
                  handleClose();
                  setSelectedData(item?.name);
                }}
              >
                {item?.name}
              </MenuItem>
            );
          })}
        </MenuList>
      </Menu>
    </>
  );
};

export default ReservationFilter;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Route,
  Routes as Switch,
  Navigate,
  useNavigate,
} from "react-router-dom";
import { Grid } from "@mui/material";
import FreelancersListPage from "../../Pages/Main/Freelancers/FreelancersList.Pages.jsx";
import MainLayout from "../../Components/Organisms/Layout/index.jsx";
import AccountDataPage from "../../Pages/Main/Account/AccountData.page.jsx";
import HomePage from "../../Pages/Main/Home.Page.jsx";
import UsersPage from "../../Pages/Main/Users/Users.page.jsx";
import RolesPage from "../../Pages/Main/Users/Roles/Roles.page.jsx";
import UserFormPage from "../../Pages/Main/Users/UserForm.page.jsx";
import RoleFormPage from "../../Pages/Main/Users/Roles/RoleForm.page.jsx";
import OperatorsPage from "../../Pages/Main/Operators/Operators.Page.jsx";
import DiveClubsPage from "../../Pages/Main/DiveClubs/DiveClubs.Page.jsx";
import CustomersPage from "../../Pages/Main/Customers/Customers.Page.jsx";
import SponsersPage from "../../Pages/Main/Sponsers/Sponsers.Page.jsx";
import CertificationsPage from "../../Pages/Main/Certifications/Certifications.Page.jsx";
// import GeolocationsPage from "../../Pages/Main/Geolocations/Geolocations.Page.jsx";
import SystemConfigurationsPage from "../../Pages/Main/SystemConfigurations/SystemConfiguration/SystemConfigurations.Page.jsx";
import FreelancerDetailsPage from "../../Pages/Main/Freelancers/FreelancerDetails.Page.jsx";
import AddOperatorPage from "../../Pages/Main/Operators/AddOperator.Page.jsx";
import OperatorDetailsPage from "../../Pages/Main/Operators/OperatorDetails.Page.jsx";
import BranchesListPage from "../../Pages/Main/Branches/BranchesList.Page.jsx";
import ActivitiesListPage from "../../Pages/Main/Activities/ActivitiesList.page.jsx";
import ActivityDetailsPage from "../../Pages/Main/Activities/ActivityDetails.page.jsx";
import BranchDetailsPage from "../../Pages/Main/Branches/BranchDetails.Page.jsx";
import ActivityGroupsPage from "../../Pages/Main/Activities/ActivityGroups.page.jsx";
import JobOffersPage from "../../Pages/Main/JobOffers/JobOffers.page.jsx";
import JobOfferDetailsPage from "../../Pages/Main/JobOffers/JobOfferDetails.page.jsx";
import ApplicantsPage from "../../Pages/Main/JobOffers/Applicants.page.jsx";
import ApplicantDetailsPage from "../../Pages/Main/JobOffers/ApplicantDetails.page.jsx";
import ActivityOffersPage from "../../Pages/Main/ActivityOffers/ActivityOffers.page.jsx";
import DiveClubDetailsPage from "../../Pages/Main/DiveClubs/DiveClubDetails.Page.jsx";
import CustomerDetailsPage from "../../Pages/Main/Customers/CustomerDetails.Page.jsx";
import ReservationsPage from "../../Pages/Main/Reservations/Reservations.Page.jsx";
import OrganizationsPage from "../../Pages/Main/Organizations/Organizations.page.jsx";
import OrganizationDetailsPage from "../../Pages/Main/Organizations/OrganizationDetails.page.jsx";
import CitiesPage from "../../Pages/Main/Geolocations/Cities/Cities.page.jsx";
import CountryPage from "../../Pages/Main/Geolocations/Country/Country.page.jsx";
import ProvincePage from "../../Pages/Main/Geolocations/Province/Province.page.jsx";
import NotificationsPage from "../../Pages/Main/Notifications/Notifications.page.jsx";
import NotificationsDetailsPage from "../../Pages/Main/Notifications/NotificationsDetails.page.jsx";
import CustomerLevelsPage from "../../Pages/Main/Levels/Customer/Customer.page.jsx";
import CustomerLevelsDetailsPage from "../../Pages/Main/Levels/Customer/CustomerDetails.page.jsx";
import FreelancerLevelsPage from "../../Pages/Main/Levels/Freelancer/Freelancer.page.jsx";
import FreelancerLevelsDetailsPage from "../../Pages/Main/Levels/Freelancer/FreelancerDetails.page.jsx";
import OrganizationsCertificatesPage from "../../Pages/Main/Organizations/OrganizationsCertificates.page.jsx";
import PointsConfigurationPage from "../../Pages/Main/SystemConfigurations/PointsConfiguration/PointsConfiguration.page.jsx";
import CurrencyPage from "../../Pages/Main/PredefinedLookups/Currency/Currency.page.jsx";
import LanguagePage from "../../Pages/Main/PredefinedLookups/Language/Language.page.jsx";
import FluencyLanguagePage from "../../Pages/Main/PredefinedLookups/fluencyLanguage/FluencyLanguage.page.jsx";
import SocialMediaPage from "../../Pages/Main/PredefinedLookups/SocialMedia/SocialMedia.page.jsx";
import BadgesPage from "../../Pages/Main/PredefinedLookups/Badges/Badges.page.jsx";
import BadgeDetailsPage from "../../Pages/Main/PredefinedLookups/Badges/BadgeDetails.page.jsx";
import FeatureOptionsPage from "../../Pages/Main/PredefinedLookups/FeatureOptions/FeatureOptions.page.jsx";
import FeatureOptionsDetailsPage from "../../Pages/Main/PredefinedLookups/FeatureOptions/FeatureOptionsDetails.page.jsx";
import PriceIncludePage from "../../Pages/Main/PredefinedLookups/PriceInclude/PriceInclude.page.jsx";
import DocumentsPage from "../../Pages/Main/Documents/Documents/Documents.page.jsx";
import FAQPage from "../../Pages/Main/Documents/FAQ/FAQ.page.jsx";
import FAQDetailsPage from "../../Pages/Main/Documents/FAQ/FAQDetails.page.jsx";
import PaymentMethodsPage from "../../Pages/Main/PredefinedLookups/PaymentMethods/PaymentMethods.Page.jsx";
import BranchesReviewsListPage from "../../Pages/Main/Branches/BranchesReviewsList.Page.jsx";
import DocumentDetailsPage from "../../Pages/Main/Documents/Documents/DocumentDetails.page.jsx";
import UserDetailsPage from "../../Pages/Main/Users/UserDetails.page.jsx";
import FreelancerReviewsListPage from "../../Pages/Main/Freelancers/FreelancerReviewsList.Page.jsx";
import ActivitiesReviewsListPage from "../../Pages/Main/Activities/ActivitiesReviewsList.Page.jsx";
import SalesmanPage from "../../Pages/Main/Operators/Salesman/Salesman.page.jsx";
import SalesmanReservationsPage from "../../Pages/Main/Operators/SalesmanReservation/SalesmanReservation.page.jsx";
import ContactsPage from "../../Pages/Main/Contacts/Contacts.Page.jsx";
import ResetPasswordPage from "../../Pages/Main/Account/ResetPassword.page.jsx";
import LogHistoryPage from "../../Pages/Main/Users/LogHistory.page.jsx";

window.dispatchEvent(new Event("storage"));
const MainTemplate = (props) => {
  const { t, profileData } = props;
  const userType = localStorage.getItem("userType");
  const [open, setOpen] = useState(true);
  const navigate = useNavigate();

  const descentantUserRoles =
    typeof localStorage.getItem("descendant_user_roles") == "string" &&
    localStorage.getItem("descendant_user_roles") != "undefined"
      ? JSON?.parse(localStorage.getItem("descendant_user_roles"))
      : {};

  const role =
    typeof localStorage.getItem("roles") == "string" &&
    localStorage.getItem("roles") != "undefined"
      ? JSON?.parse(localStorage.getItem("roles"))
      : {};

  let roles = userType == "descendant_user" ? descentantUserRoles : role;

  useEffect(() => {
    roles = userType == "descendant_user" ? descentantUserRoles : role;
    // when the storage changes
    const handleStorageListener = () => {
      roles = userType == "descendant_user" ? descentantUserRoles : role;
    };
    window.addEventListener("storage", handleStorageListener);
    //clean up
    return () => {
      window.removeEventListener("storage", handleStorageListener);
    };
  }, [window.localStorage]);

  function ProtectedRoute({ children, isAllowed }) {
    if (!localStorage.getItem("Token")) {
      navigate("/login");
      return null;
    } else {
      if (isAllowed) {
        return children;
      } else if (!isAllowed) {
        navigate("/");
      }
    }
  }

  // This function clears the browser cache on reload to ensure the sidebar is updated if there are any changes to the roles.
  const clearCacheData = () => {
    caches?.keys()?.then((names) => {
      names?.forEach((name) => {
        caches?.delete(name);
      });
    });
  };

  useEffect(() => {
    clearCacheData();
  }, []);
  return (
    <Grid container>
      <Switch>
        <Route
          path="/*"
          element={
            <MainLayout
              profileData={profileData}
              roles={roles}
              open={open}
              setOpen={setOpen}
            >
              <Grid
                sx={{
                  width: !open ? "98%" : "90%",
                  mx: !open ? "1%" : "3%",
                  p: "1%",
                }}
              >
                <Switch>
                  <Route
                    path="/"
                    element={<HomePage profileData={profileData} />}
                  />
                  <Route
                    path="/profile"
                    element={<AccountDataPage profileData={profileData} />}
                  />

                  <Route
                    path="/profile/change-password"
                    element={<ResetPasswordPage profileData={profileData} />}
                  />

                  {/* Operators
                  <Route
                    exact
                    path={"/operators/add"}
                    element={
                      roles?.["dive_centers"]?.can_read &&
                      roles?.["dive_centers"]?.can_create ? (
                        <AddOperatorPage />
                      ) : (
                        <Navigate to={"/"} />
                      )
                    }
                  /> */}
                  <Route
                    path={"/operators/add"}
                    element={
                      <ProtectedRoute
                        isAllowed={
                          roles?.["dive_centers"]?.can_read &&
                          roles?.["dive_centers"]?.can_create
                        }
                      >
                        <AddOperatorPage />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    exact
                    path={"/operators/:operatorId"}
                    element={
                      <ProtectedRoute
                        isAllowed={roles?.["dive_centers"]?.can_read}
                      >
                        <OperatorDetailsPage roles={roles?.["dive_centers"]} />
                      </ProtectedRoute>
                    }
                  />
                  {/* <Route
                    exact
                    path={"/operators/branches"}
                    element={
                      roles?.["branches"]?.can_read === false ? (
                        <Navigate to={"/"} />
                      ) : (
                        <BranchesListPage roles={roles?.["branches"]} />
                      )
                    }
                  /> */}
                  <Route
                    exact
                    path={"/operators/branches"}
                    element={
                      <ProtectedRoute isAllowed={roles?.["branches"]?.can_read}>
                        <BranchesListPage roles={roles?.["branches"]} />
                      </ProtectedRoute>
                    }
                  />
                  {/* <Route
                    exact
                    path={"/operators/branches/:branchId/reviews"}
                    element={
                      roles?.["branches"]?.can_read ? (
                        <BranchesReviewsListPage />
                      ) : (
                        <Navigate to={"/"} />
                      )
                    }
                  /> */}
                  <Route
                    exact
                    path={"/operators/branches/:branchId/reviews"}
                    element={
                      <ProtectedRoute isAllowed={roles?.["branches"]?.can_read}>
                        <BranchesReviewsListPage />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    exact
                    path={"/operators/job-offers"}
                    element={
                      <ProtectedRoute
                        isAllowed={roles?.["job_offers"]?.can_read}
                      >
                        <JobOffersPage
                          roles={roles?.["job_offers"]}
                          applicants_roles={roles?.["job_requests"]}
                        />
                      </ProtectedRoute>
                    }
                  />
                  {/* <Route
                    exact
                    path={"/operators/job-offers/:jobOfferId"}
                    element={
                      roles?.["job_offers"]?.can_read ? (
                        <JobOfferDetailsPage roles={roles?.["job_requests"]} />
                      ) : (
                        <Navigate to={"/"} />
                      )
                    }
                  /> */}
                  <Route
                    exact
                    path={"/operators/job-offers/:jobOfferId"}
                    element={
                      <ProtectedRoute
                        isAllowed={roles?.["job_offers"]?.can_read}
                      >
                        <JobOfferDetailsPage roles={roles?.["job_requests"]} />
                      </ProtectedRoute>
                    }
                  />
                  {/* <Route
                    exact
                    path={"/operators/job-offers/:jobOfferId/applicants"}
                    element={
                      roles?.["job_offers"]?.can_read &&
                      roles?.["job_requests"]?.can_read ? (
                        <ApplicantsPage roles={roles?.["job_requests"]} />
                      ) : (
                        <Navigate to={"/"} />
                      )
                    }
                  /> */}
                  <Route
                    exact
                    path={"/operators/job-offers/:jobOfferId/applicants"}
                    element={
                      <ProtectedRoute
                        isAllowed={
                          roles?.["job_offers"]?.can_read &&
                          roles?.["job_requests"]?.can_read
                        }
                      >
                        <ApplicantsPage roles={roles?.["job_requests"]} />
                      </ProtectedRoute>
                    }
                  />
                  {/* <Route
                    exact
                    path={
                      "/operators/job-offers/:jobOfferId/applicants/:applicantId"
                    }
                    element={
                      roles?.["job_offers"]?.can_read &&
                      roles?.["job_requests"]?.can_read ? (
                        <ApplicantDetailsPage roles={roles?.["job_requests"]} />
                      ) : (
                        <Navigate to={"/"} />
                      )
                    }
                  /> */}
                  <Route
                    exact
                    path={
                      "/operators/job-offers/:jobOfferId/applicants/:applicantId"
                    }
                    element={
                      <ProtectedRoute
                        isAllowed={
                          roles?.["job_offers"]?.can_read &&
                          roles?.["job_requests"]?.can_read
                        }
                      >
                        <ApplicantDetailsPage roles={roles?.["job_requests"]} />
                      </ProtectedRoute>
                    }
                  />
                  {/* <Route
                    exact
                    path={"/operators/activity-offers"}
                    element={
                      roles?.["activity_offers"]?.can_read ? (
                        <ActivityOffersPage
                          roles={roles?.["activity_offers"]}
                        />
                      ) : (
                        <Navigate to={"/"} />
                      )
                    }
                  /> */}
                  <Route
                    exact
                    path={"/operators/activity-offers"}
                    element={
                      <ProtectedRoute
                        isAllowed={roles?.["activity_offers"]?.can_read}
                      >
                        <ActivityOffersPage
                          roles={roles?.["activity_offers"]}
                        />
                      </ProtectedRoute>
                    }
                  />
                  {/* <Route
                    exact
                    path={"/operators/activities"}
                    element={
                      roles?.["activities"]?.can_read ? (
                        <ActivitiesListPage roles={roles?.["activities"]} />
                      ) : (
                        <Navigate to={"/"} />
                      )
                    }
                  /> */}
                  <Route
                    exact
                    path={"/operators/activities"}
                    element={
                      <ProtectedRoute
                        isAllowed={roles?.["activities"]?.can_read}
                      >
                        <ActivitiesListPage roles={roles?.["activities"]} />
                      </ProtectedRoute>
                    }
                  />
                  {/* <Route
                    exact
                    path={"/operators/activities/:activityId"}
                    element={
                      roles?.["activities"]?.can_read ? (
                        <ActivityDetailsPage />
                      ) : (
                        <Navigate to={"/"} />
                      )
                    }
                  /> */}
                  <Route
                    exact
                    path={"/operators/activities/:activityId"}
                    element={
                      <ProtectedRoute
                        isAllowed={roles?.["activities"]?.can_read}
                      >
                        <ActivityDetailsPage />
                      </ProtectedRoute>
                    }
                  />
                  {/* <Route
                    exact
                    path={"/operators/activities/:activityId/activity-groups"}
                    element={
                      roles?.["activities"]?.can_read ? (
                        <ActivityGroupsPage />
                      ) : (
                        <Navigate to={"/"} />
                      )
                    }
                  /> */}
                  <Route
                    exact
                    path={"/operators/activities/:activityId/activity-groups"}
                    element={
                      <ProtectedRoute
                        isAllowed={roles?.["activities"]?.can_read}
                      >
                        <ActivityGroupsPage />
                      </ProtectedRoute>
                    }
                  />
                  {/* <Route
                    exact
                    path={"/operators/activities/:activityId/reviews"}
                    element={
                      roles?.["activities"]?.can_read ? (
                        <ActivitiesReviewsListPage />
                      ) : (
                        <Navigate to={"/"} />
                      )
                    }
                  /> */}
                  <Route
                    exact
                    path={"/operators/activities/:activityId/reviews"}
                    element={
                      <ProtectedRoute
                        isAllowed={roles?.["activities"]?.can_read}
                      >
                        <ActivitiesReviewsListPage />
                      </ProtectedRoute>
                    }
                  />
                  {/* <Route
                    exact
                    path={"/operators/branches/:branchId"}
                    element={
                      roles?.["branches"]?.can_read ? (
                        <BranchDetailsPage roles={roles?.["branches"]} />
                      ) : (
                        <Navigate to={"/"} />
                      )
                    }
                  /> */}
                  <Route
                    exact
                    path={"/operators/branches/:branchId"}
                    element={
                      <ProtectedRoute isAllowed={roles?.["branches"]?.can_read}>
                        <BranchDetailsPage roles={roles?.["branches"]} />
                      </ProtectedRoute>
                    }
                  />
                  {/* <Route
                    exact
                    path={"/operators/main"}
                    element={
                      roles?.["dive_centers"]?.can_read ? (
                        <OperatorsPage
                          roles={roles?.["dive_centers"]}
                          salesman_roles={roles?.["salesmen"]}
                        />
                      ) : (
                        <Navigate to={"/"} />
                      )
                    }
                  /> */}
                  <Route
                    exact
                    path={"/operators/main"}
                    element={
                      <ProtectedRoute
                        isAllowed={roles?.["dive_centers"]?.can_read}
                      >
                        <OperatorsPage
                          roles={roles?.["dive_centers"]}
                          salesman_roles={roles?.["salesmen"]}
                        />
                      </ProtectedRoute>
                    }
                  />
                  {/* <Route
                    exact
                    path={"/operators/salesman/:operatorId"}
                    element={
                      roles?.["dive_centers"]?.can_read &&
                      roles?.["salesmen"]?.can_read ? (
                        <SalesmanPage
                          salesman_reservations_roles={
                            roles?.["salesman_reservations"]
                          }
                        />
                      ) : (
                        <Navigate to={"/"} />
                      )
                    }
                  /> */}
                  <Route
                    exact
                    path={"/operators/salesman/:operatorId"}
                    element={
                      <ProtectedRoute
                        isAllowed={
                          roles?.["dive_centers"]?.can_read &&
                          roles?.["salesmen"]?.can_read
                        }
                      >
                        <SalesmanPage
                          salesman_reservations_roles={
                            roles?.["salesman_reservations"]
                          }
                        />
                      </ProtectedRoute>
                    }
                  />
                  {/* <Route
                    exact
                    path={
                      "/operators/salesman/:operatorId/reservation/:salesmanId"
                    }
                    element={
                      roles?.["dive_centers"]?.can_read &&
                      roles?.["salesmen"]?.can_read &&
                      roles?.["salesman_reservations"]?.can_read ? (
                        <SalesmanReservationsPage />
                      ) : (
                        <Navigate to={"/"} />
                      )
                    }
                  /> */}
                  <Route
                    exact
                    path={
                      "/operators/salesman/:operatorId/reservation/:salesmanId"
                    }
                    element={
                      <ProtectedRoute
                        isAllowed={
                          roles?.["dive_centers"]?.can_read &&
                          roles?.["salesmen"]?.can_read &&
                          roles?.["salesman_reservations"]?.can_read
                        }
                      >
                        <SalesmanReservationsPage />
                      </ProtectedRoute>
                    }
                  />
                  {/* <Route
                    exact
                    path={"/operators/reservations"}
                    element={
                      roles?.["orders"]?.can_read ? (
                        <ReservationsPage roles={roles?.["orders"]} />
                      ) : (
                        <Navigate to={"/"} />
                      )
                    }
                  /> */}
                  <Route
                    exact
                    path={"/operators/reservations"}
                    element={
                      <ProtectedRoute isAllowed={roles?.["orders"]?.can_read}>
                        <ReservationsPage roles={roles?.["orders"]} />
                      </ProtectedRoute>
                    }
                  />
                  {/* Dive clubs */}
                  {/* <Route
                    path={"/dive-clubs"}
                    element={
                      roles?.["profile"]?.can_read ===false ? (
                        
                          <Navigate to={"/"} />
                      ) : (
                      <DiveClubsPage roles={roles?.["profile"]} />
                      )
                    }
                  /> */}
                  <Route
                    exact
                    path={"/dive-clubs"}
                    element={
                      <ProtectedRoute isAllowed={roles?.["profile"]?.can_read}>
                        <DiveClubsPage roles={roles?.["profile"]} />
                      </ProtectedRoute>
                    }
                  />
                  {/* <Route
                    exact
                    path={"/dive-clubs/:diveClubId"}
                    element={
                      roles?.["profile"]?.can_read ? (
                        <DiveClubDetailsPage roles={roles?.["profile"]} />
                      ) : (
                        <Navigate to={"/"} />
                      )
                    }
                  /> */}
                  <Route
                    exact
                    path={"/dive-clubs/:diveClubId"}
                    element={
                      <ProtectedRoute isAllowed={roles?.["profile"]?.can_read}>
                        <DiveClubDetailsPage roles={roles?.["profile"]} />
                      </ProtectedRoute>
                    }
                  />
                  {/* Customers */}
                  {/* <Route
                    path={"/customers"}
                    element={
                      roles?.["profile"]?.can_read ? (
                        <CustomersPage roles={roles?.["profile"]} />
                      ) : (
                        <Navigate to={"/"} />
                      )
                    }
                  /> */}
                  <Route
                    exact
                    path={"/customers"}
                    element={
                      <ProtectedRoute isAllowed={roles?.["profile"]?.can_read}>
                        <CustomersPage roles={roles?.["profile"]} />
                      </ProtectedRoute>
                    }
                  />
                  {/* <Route
                    path={"/customers/:customerId"}
                    element={
                      roles?.["profile"]?.can_read ? (
                        <CustomerDetailsPage roles={roles?.["profile"]} />
                      ) : (
                        <Navigate to={"/"} />
                      )
                    }
                  /> */}
                  <Route
                    exact
                    path={"/customers/:customerId"}
                    element={
                      <ProtectedRoute isAllowed={roles?.["profile"]?.can_read}>
                        <CustomerDetailsPage roles={roles?.["profile"]} />
                      </ProtectedRoute>
                    }
                  />
                  {/* Sponsers */}
                  <Route path="/sponsers" element={<SponsersPage />} />
                  {/* Freelancers Routes */}
                  {/* <Route
                    exact
                    path={"/freelancers"}
                    element={
                      roles?.["dive_experts"]?.can_read ? (
                        <FreelancersListPage roles={roles?.["dive_experts"]} />
                      ) : (
                        <Navigate to={"/"} />
                      )
                    }
                  /> */}
                  <Route
                    exact
                    path={"/freelancers"}
                    element={
                      <ProtectedRoute
                        isAllowed={roles?.["dive_experts"]?.can_read}
                      >
                        <FreelancersListPage roles={roles?.["dive_experts"]} />
                      </ProtectedRoute>
                    }
                  />
                  {/* <Route
                    exact
                    path={"/freelancers/:freelancerId"}
                    element={
                      roles?.["dive_experts"]?.can_read ? (
                        <FreelancerDetailsPage
                          roles={roles?.["dive_experts"]}
                        />
                      ) : (
                        <Navigate to={"/"} />
                      )
                    }
                  /> */}
                  <Route
                    exact
                    path={"/freelancers/:freelancerId"}
                    element={
                      <ProtectedRoute
                        isAllowed={roles?.["dive_experts"]?.can_read}
                      >
                        <FreelancerDetailsPage
                          roles={roles?.["dive_experts"]}
                        />
                      </ProtectedRoute>
                    }
                  />
                  {/* <Route
                    exact
                    path={"/freelancers/:freelancerId/reviews"}
                    element={
                      roles?.["dive_experts"]?.can_read ? (
                        <FreelancerReviewsListPage />
                      ) : (
                        <Navigate to={"/"} />
                      )
                    }
                  /> */}
                  <Route
                    exact
                    path={"/freelancers/:freelancerId/reviews"}
                    element={
                      <ProtectedRoute
                        isAllowed={roles?.["dive_experts"]?.can_read}
                      >
                        <FreelancerReviewsListPage />
                      </ProtectedRoute>
                    }
                  />
                  {/* Predefined lookups */}
                  {/* <Route
                    exact
                    path="/predefined-lookups/currency"
                    element={
                      roles?.["currencies"]?.can_read ? (
                        <CurrencyPage roles={roles?.["currencies"]} />
                      ) : (
                        <Navigate to={"/"} />
                      )
                    }
                  /> */}
                  <Route
                    exact
                    path="/predefined-lookups/currency"
                    element={
                      <ProtectedRoute
                        isAllowed={roles?.["currencies"]?.can_read}
                      >
                        <CurrencyPage roles={roles?.["currencies"]} />
                      </ProtectedRoute>
                    }
                  />
                  {/* <Route
                    exact
                    path="/predefined-lookups/languages"
                    element={
                      roles?.["languages"]?.can_read ? (
                        <LanguagePage />
                      ) : (
                        <Navigate to={"/"} />
                      )
                    }
                  /> */}
                  <Route
                    exact
                    path="/predefined-lookups/languages"
                    element={
                      <ProtectedRoute
                        isAllowed={roles?.["languages"]?.can_read}
                      >
                        <LanguagePage />
                      </ProtectedRoute>
                    }
                  />
                  {/* <Route
                    exact
                    path="/predefined-lookups/fluency-languages"
                    element={
                      roles?.["language_fluency_levels"]?.can_read ? (
                        <FluencyLanguagePage
                          roles={roles?.["language_fluency_levels"]}
                        />
                      ) : (
                        <Navigate to={"/"} />
                      )
                    }
                  /> */}
                  <Route
                    exact
                    path="/predefined-lookups/fluency-languages"
                    element={
                      <ProtectedRoute
                        isAllowed={roles?.["language_fluency_levels"]?.can_read}
                      >
                        <FluencyLanguagePage
                          roles={roles?.["language_fluency_levels"]}
                        />
                      </ProtectedRoute>
                    }
                  />
                  {/* <Route
                    exact
                    path="/predefined-lookups/social-media"
                    element={
                      roles?.["social_media"]?.can_read ? (
                        <SocialMediaPage roles={roles?.["social_media"]} />
                      ) : (
                        <Navigate to={"/"} />
                      )
                    }
                  /> */}
                  <Route
                    exact
                    path="/predefined-lookups/social-media"
                    element={
                      <ProtectedRoute
                        isAllowed={roles?.["social_media"]?.can_read}
                      >
                        <SocialMediaPage roles={roles?.["social_media"]} />
                      </ProtectedRoute>
                    }
                  />
                  {/* <Route
                    exact
                    path="/predefined-lookups/badges"
                    element={
                      roles?.["badges"]?.can_read ? (
                        <BadgesPage roles={roles?.["badges"]} />
                      ) : (
                        <Navigate to={"/"} />
                      )
                    }
                  /> */}
                  <Route
                    exact
                    path="/predefined-lookups/badges"
                    element={
                      <ProtectedRoute isAllowed={roles?.["badges"]?.can_read}>
                        <BadgesPage roles={roles?.["badges"]} />
                      </ProtectedRoute>
                    }
                  />
                  {/* <Route
                    exact
                    path="/predefined-lookups/badges/:badgeId"
                    element={
                      roles?.["badges"]?.can_read ? (
                        <BadgeDetailsPage roles={roles?.["badges"]} />
                      ) : (
                        <Navigate to={"/"} />
                      )
                    }
                  /> */}
                  <Route
                    exact
                    path="/predefined-lookups/badges/:badgeId"
                    element={
                      <ProtectedRoute isAllowed={roles?.["badges"]?.can_read}>
                        <BadgeDetailsPage roles={roles?.["badges"]} />
                      </ProtectedRoute>
                    }
                  />
                  {/* <Route
                    exact
                    path="/predefined-lookups/feature-options"
                    element={
                      roles?.["feature_options"]?.can_read ? (
                        <FeatureOptionsPage
                          roles={roles?.["feature_options"]}
                        />
                      ) : (
                        <Navigate to={"/"} />
                      )
                    }
                  /> */}
                  <Route
                    exact
                    path="/predefined-lookups/feature-options"
                    element={
                      <ProtectedRoute
                        isAllowed={roles?.["feature_options"]?.can_read}
                      >
                        <FeatureOptionsPage
                          roles={roles?.["feature_options"]}
                        />
                      </ProtectedRoute>
                    }
                  />
                  {/* <Route
                    exact
                    path="/predefined-lookups/feature-options/:featureOptionId"
                    element={
                      roles?.["feature_options"]?.can_read ? (
                        <FeatureOptionsDetailsPage
                          roles={roles?.["feature_options"]}
                        />
                      ) : (
                        <Navigate to={"/"} />
                      )
                    }
                  /> */}
                  <Route
                    exact
                    path="/predefined-lookups/feature-options/:featureOptionId"
                    element={
                      <ProtectedRoute
                        isAllowed={roles?.["feature_options"]?.can_read}
                      >
                        <FeatureOptionsDetailsPage
                          roles={roles?.["feature_options"]}
                        />
                      </ProtectedRoute>
                    }
                  />
                  {/* <Route
                    exact
                    path="/predefined-lookups/payment-methods"
                    element={
                      roles?.["payment_methods"]?.can_read ? (
                        <PaymentMethodsPage
                          roles={roles?.["payment_methods"]}
                        />
                      ) : (
                        <Navigate to={"/"} />
                      )
                    }
                  /> */}
                  <Route
                    exact
                    path="/predefined-lookups/payment-methods"
                    element={
                      <ProtectedRoute
                        isAllowed={roles?.["payment_methods"]?.can_read}
                      >
                        <PaymentMethodsPage
                          roles={roles?.["payment_methods"]}
                        />
                      </ProtectedRoute>
                    }
                  />
                  {/* <Route
                    exact
                    path="/predefined-lookups/price-includes"
                    element={
                      roles?.["price_includes"]?.can_read ? (
                        <PriceIncludePage roles={roles?.["price_includes"]} />
                      ) : (
                        <Navigate to={"/"} />
                      )
                    }
                  /> */}
                  <Route
                    exact
                    path="/predefined-lookups/price-includes"
                    element={
                      <ProtectedRoute
                        isAllowed={roles?.["price_includes"]?.can_read}
                      >
                        <PriceIncludePage roles={roles?.["price_includes"]} />
                      </ProtectedRoute>
                    }
                  />
                  {/* Certifications */}
                  <Route
                    exact
                    path="/certifications"
                    element={<CertificationsPage />}
                  />
                  {/* Organizations */}
                  {/* <Route
                    exact
                    path="/organizations"
                    element={
                      roles?.["organizations"]?.can_read ? (
                        <OrganizationsPage roles={roles?.["organizations"]} />
                      ) : (
                        <Navigate to={"/"} />
                      )
                    }
                  /> */}
                  <Route
                    exact
                    path="/organizations"
                    element={
                      <ProtectedRoute
                        isAllowed={roles?.["organizations"]?.can_read}
                      >
                        <OrganizationsPage roles={roles?.["organizations"]} />
                      </ProtectedRoute>
                    }
                  />
                  {/* <Route
                    exact
                    path="/organizations/:organizationId"
                    element={
                      roles?.["organizations"]?.can_read ? (
                        <OrganizationDetailsPage
                          roles={roles?.["organizations"]}
                        />
                      ) : (
                        <Navigate to={"/"} />
                      )
                    }
                  /> */}
                  <Route
                    exact
                    path="/organizations/:organizationId"
                    element={
                      <ProtectedRoute
                        isAllowed={roles?.["organizations"]?.can_read}
                      >
                        <OrganizationDetailsPage
                          roles={roles?.["organizations"]}
                        />
                      </ProtectedRoute>
                    }
                  />
                  {/* <Route
                    exact
                    path="/organizations/:organizationId/certificates"
                    element={
                      roles?.["organizations"]?.can_read ? (
                        <OrganizationsCertificatesPage
                          roles={roles?.["organizations"]}
                        />
                      ) : (
                        <Navigate to={"/"} />
                      )
                    }
                  /> */}
                  <Route
                    exact
                    path="/organizations/:organizationId/certificates"
                    element={
                      <ProtectedRoute
                        isAllowed={roles?.["organizations"]?.can_read}
                      >
                        <OrganizationsCertificatesPage
                          roles={roles?.["organizations"]}
                        />
                      </ProtectedRoute>
                    }
                  />
                  {/* Geolocations */}
                  {/* <Route
                    exact
                    path="/geolocations"
                    element={<GeolocationsPage />}
                  /> */}
                  {/* <Route
                    exact
                    path="/geolocations/cities"
                    element={
                      roles?.["areas"]?.can_read ? (
                        <CitiesPage roles={roles?.["areas"]} />
                      ) : (
                        <Navigate to={"/"} />
                      )
                    }
                  /> */}
                  <Route
                    exact
                    path="/geolocations/cities"
                    element={
                      <ProtectedRoute isAllowed={roles?.["areas"]?.can_read}>
                        <CitiesPage roles={roles?.["areas"]} />
                      </ProtectedRoute>
                    }
                  />
                  {/* <Route
                    exact
                    path="/geolocations/countries"
                    element={
                      roles?.["countries"]?.can_read ? (
                        <CountryPage roles={roles?.["countries"]} />
                      ) : (
                        <Navigate to={"/"} />
                      )
                    }
                  /> */}
                  <Route
                    exact
                    path="/geolocations/countries"
                    element={
                      <ProtectedRoute
                        isAllowed={roles?.["countries"]?.can_read}
                      >
                        <CountryPage roles={roles?.["countries"]} />
                      </ProtectedRoute>
                    }
                  />
                  {/* <Route
                    exact
                    path="/geolocations/provinces"
                    element={
                      roles?.["cities"]?.can_read ? (
                        <ProvincePage roles={roles?.["cities"]} />
                      ) : (
                        <Navigate to={"/"} />
                      )
                    }
                  /> */}
                  <Route
                    exact
                    path="/geolocations/provinces"
                    element={
                      <ProtectedRoute isAllowed={roles?.["cities"]?.can_read}>
                        <ProvincePage roles={roles?.["cities"]} />
                      </ProtectedRoute>
                    }
                  />
                  {/* notifications */}
                  <Route
                    exact
                    path="/notifications"
                    element={<NotificationsPage />}
                  />
                  <Route
                    exact
                    path="/notifications/:notification_id"
                    element={<NotificationsDetailsPage />}
                  />
                  {/* Levels */}
                  {/* <Route
                    exact
                    path="/levels/customer"
                    element={<CustomerLevelsPage />}
                  />
                  <Route
                    exact
                    path="/levels/customer/:Level_id"
                    element={<CustomerLevelsDetailsPage />}
                  />
                  <Route
                    exact
                    path="/levels/freelancer"
                    element={<FreelancerLevelsPage />}
                  />
                  <Route
                    exact
                    path="/levels/freelancer/:Level_id"
                    element={<FreelancerLevelsDetailsPage />}
                  /> */}
                  {/* <Route
                    exact
                    path="/levels/customer"
                    element={
                      roles?.["customer_levels"]?.can_read ? (
                        <CustomerLevelsPage
                          roles={roles?.["customer_levels"]}
                        />
                      ) : (
                        <Navigate to={"/"} />
                      )
                    }
                  /> */}
                  <Route
                    exact
                    path="/levels/customer"
                    element={
                      <ProtectedRoute
                        isAllowed={roles?.["customer_levels"]?.can_read}
                      >
                        <CustomerLevelsPage
                          roles={roles?.["customer_levels"]}
                        />
                      </ProtectedRoute>
                    }
                  />
                  {/* <Route
                    exact
                    path="/levels/customer/:Level_id"
                    element={
                      roles?.["customer_levels"]?.can_read ? (
                        <CustomerLevelsDetailsPage
                          roles={roles?.["customer_levels"]}
                        />
                      ) : (
                        <Navigate to={"/"} />
                      )
                    }
                  /> */}
                  <Route
                    exact
                    path="/levels/customer/:Level_id"
                    element={
                      <ProtectedRoute
                        isAllowed={roles?.["customer_levels"]?.can_read}
                      >
                        <CustomerLevelsDetailsPage
                          roles={roles?.["customer_levels"]}
                        />
                      </ProtectedRoute>
                    }
                  />
                  {/* <Route
                    exact
                    path="/levels/freelancer"
                    element={
                      roles?.["dive_expert_levels"]?.can_read ? (
                        <FreelancerLevelsPage
                          roles={roles?.["dive_expert_levels"]}
                        />
                      ) : (
                        <Navigate to={"/"} />
                      )
                    }
                  /> */}
                  <Route
                    exact
                    path="/levels/freelancer"
                    element={
                      <ProtectedRoute
                        isAllowed={roles?.["dive_expert_levels"]?.can_read}
                      >
                        <FreelancerLevelsPage
                          roles={roles?.["dive_expert_levels"]}
                        />
                      </ProtectedRoute>
                    }
                  />
                  {/* <Route
                    exact
                    path="/levels/freelancer/:Level_id"
                    element={
                      roles?.["dive_expert_levels"]?.can_read ? (
                        <FreelancerLevelsDetailsPage
                          roles={roles?.["dive_expert_levels"]}
                        />
                      ) : (
                        <Navigate to={"/"} />
                      )
                    }
                  /> */}
                  <Route
                    exact
                    path="/levels/freelancer/:Level_id"
                    element={
                      <ProtectedRoute
                        isAllowed={roles?.["dive_expert_levels"]?.can_read}
                      >
                        <FreelancerLevelsDetailsPage
                          roles={roles?.["dive_expert_levels"]}
                        />
                      </ProtectedRoute>
                    }
                  />
                  {/* System configurations */}
                  {/* <Route
                    exact
                    path="/system-configurations/main"
                    element={
                      roles?.["system_configurations"]?.can_read ? (
                        <SystemConfigurationsPage
                          roles={roles?.["system_configurations"]}
                        />
                      ) : (
                        <Navigate to={"/"} />
                      )
                    }
                  /> */}
                  {/* <Route
                    exact
                    path="/system-configurations/main"
                    element={
                      <ProtectedRoute
                        isAllowed={roles?.["system_configurations"]?.can_read}
                      >
                        <SystemConfigurationsPage
                          roles={roles?.["system_configurations"]}
                        />
                      </ProtectedRoute>
                    }
                  /> */}
                  <Route
                    exact
                    path="/system-configurations/main"
                    element={
                      <ProtectedRoute
                        isAllowed={roles?.["system_configurations"]?.can_read}
                      >
                        <SystemConfigurationsPage
                          roles={roles?.["system_configurations"]}
                        />
                      </ProtectedRoute>
                    }
                  />
                  {/* <Route
                    exact
                    path={"/system-configurations/points"}
                    element={
                      roles?.["points"]?.can_read ? (
                        <PointsConfigurationPage roles={roles?.["points"]} />
                      ) : (
                        <Navigate to={"/"} />
                      )
                    }
                  /> */}
                  <Route
                    exact
                    path={"/system-configurations/points"}
                    element={
                      <ProtectedRoute isAllowed={roles?.["points"]?.can_read}>
                        <PointsConfigurationPage roles={roles?.["points"]} />
                      </ProtectedRoute>
                    }
                  />
                  {/* Documents */}
                  {/* <Route
                    exact
                    path={"/documents"}
                    element={
                      roles?.["documents"]?.can_read ? (
                        <DocumentsPage roles={roles?.["documents"]} />
                      ) : (
                        <Navigate to={"/"} />
                      )
                    }
                  /> */}
                  <Route
                    exact
                    path={"/documents"}
                    element={
                      <ProtectedRoute
                        isAllowed={roles?.["documents"]?.can_read}
                      >
                        <DocumentsPage roles={roles?.["documents"]} />
                      </ProtectedRoute>
                    }
                  />
                  {/* <Route
                    exact
                    path={"/documents/:document_id"}
                    element={
                      roles?.["documents"]?.can_read ? (
                        <DocumentDetailsPage roles={roles?.["documents"]} />
                      ) : (
                        <Navigate to={"/"} />
                      )
                    }
                  /> */}
                  <Route
                    exact
                    path={"/documents/:document_id"}
                    element={
                      <ProtectedRoute
                        isAllowed={roles?.["documents"]?.can_read}
                      >
                        <DocumentDetailsPage roles={roles?.["documents"]} />
                      </ProtectedRoute>
                    }
                  />
                  {/* FAQ's */}
                  {/* <Route
                    exact
                    path="/faqs"
                    element={
                      roles?.["faqs"]?.can_read ? (
                        <FAQPage roles={roles?.["faqs"]} />
                      ) : (
                        <Navigate to={"/"} />
                      )
                    }
                  /> */}
                  <Route
                    exact
                    path="/faqs"
                    element={
                      <ProtectedRoute isAllowed={roles?.["faqs"]?.can_read}>
                        <FAQPage roles={roles?.["faqs"]} />
                      </ProtectedRoute>
                    }
                  />
                  {/* <Route
                    exact
                    path="/faqs/:faqs_id"
                    element={
                      roles?.["faqs"]?.can_read ? (
                        <FAQDetailsPage roles={roles?.["faqs"]} />
                      ) : (
                        <Navigate to={"/"} />
                      )
                    }
                  /> */}
                  <Route
                    exact
                    path="/faqs/:faqs_id"
                    element={
                      <ProtectedRoute isAllowed={roles?.["faqs"]?.can_read}>
                        <FAQDetailsPage roles={roles?.["faqs"]} />
                      </ProtectedRoute>
                    }
                  />
                  {/* App version */}
                  <Route exact path="/contacts" element={<ContactsPage />} />
                  {/* Users Rotes */}
                  {/* <Route
                    exact
                    path={"/users/add"}
                    element={
                      roles?.["descendant_users"]?.can_read &&
                      roles?.["descendant_users"]?.can_create ? (
                        <UserFormPage formType="Add" />
                      ) : (
                        <Navigate to={"/"} />
                      )
                    }
                  /> */}
                  <Route
                    exact
                    path={"/users/add"}
                    element={
                      <ProtectedRoute
                        isAllowed={
                          roles?.["descendant_users"]?.can_read &&
                          roles?.["descendant_users"]?.can_create
                        }
                      >
                        <UserFormPage formType="Add" />
                      </ProtectedRoute>
                    }
                  />
                  {/* <Route
                    exact
                    path={"/users/roles/add"}
                    element={
                      roles?.["descendant_users"]?.can_read &&
                      roles?.["roles"]?.can_read &&
                      roles?.["roles"]?.can_create ? (
                        <RoleFormPage formType="Add" />
                      ) : (
                        <Navigate to={"/"} />
                      )
                    }
                  /> */}
                  <Route
                    exact
                    path={"/users/roles/add"}
                    element={
                      <ProtectedRoute
                        isAllowed={
                          roles?.["descendant_users"]?.can_read &&
                          roles?.["roles"]?.can_read &&
                          roles?.["roles"]?.can_create
                        }
                      >
                        <RoleFormPage formType="Add" />
                      </ProtectedRoute>
                    }
                  />
                  {/* <Route
                    exact
                    path={"/users/edit/:user_id"}
                    element={
                      roles?.["descendant_users"]?.can_read &&
                      roles?.["descendant_users"]?.can_update ? (
                        <UserFormPage formType="Edit" />
                      ) : (
                        <Navigate to={"/"} />
                      )
                    }
                  /> */}
                  <Route
                    exact
                    path={"/users/edit/:user_id"}
                    element={
                      <ProtectedRoute
                        isAllowed={
                          roles?.["descendant_users"]?.can_read &&
                          roles?.["descendant_users"]?.can_update
                        }
                      >
                        <UserFormPage formType="Edit" />
                      </ProtectedRoute>
                    }
                  />
                  {/* <Route
                    exact
                    path={"/users/edit-password/:user_id"}
                    element={
                      roles?.["descendant_users"]?.can_read &&
                      roles?.["descendant_users"]?.can_update ? (
                        <UserFormPage formType="Edit" />
                      ) : (
                        <Navigate to={"/"} />
                      )
                    }
                  /> */}
                  <Route
                    exact
                    path={"/users/edit-password/:user_id"}
                    element={
                      <ProtectedRoute
                        isAllowed={
                          roles?.["descendant_users"]?.can_read &&
                          roles?.["descendant_users"]?.can_update
                        }
                      >
                        <UserFormPage formType="Edit" />{" "}
                      </ProtectedRoute>
                    }
                  />
                  {/* <Route
                    exact
                    path={"/users/roles/edit/:role_id"}
                    element={
                      roles?.["descendant_users"]?.can_read &&
                      roles?.["roles"]?.can_read &&
                      roles?.["roles"]?.can_update ? (
                        <RoleFormPage formType="Edit" />
                      ) : (
                        <Navigate to={"/"} />
                      )
                    }
                  /> */}
                  <Route
                    exact
                    path={"/users/roles/edit/:role_id"}
                    element={
                      <ProtectedRoute
                        isAllowed={
                          roles?.["descendant_users"]?.can_read &&
                          roles?.["roles"]?.can_read &&
                          roles?.["roles"]?.can_update
                        }
                      >
                        <RoleFormPage formType="Edit" />
                      </ProtectedRoute>
                    }
                  />
                  {/* <Route
                    exact
                    path={"/users"}
                    element={
                      roles?.["descendant_users"]?.can_read ? (
                        <UsersPage
                          // roles={roles?.["descendant_users"]}
                          usersPrivilages={roles?.["descendant_users"]}
                          rolesPrivilages={roles?.["roles"]}
                        />
                      ) : (
                        <Navigate to={"/"} />
                      )
                    }
                  /> */}
                  <Route
                    exact
                    path={"/users"}
                    element={
                      <ProtectedRoute
                        isAllowed={roles?.["descendant_users"]?.can_read}
                      >
                        <UsersPage
                          // roles={roles?.["descendant_users"]}
                          usersPrivilages={roles?.["descendant_users"]}
                          rolesPrivilages={roles?.["roles"]}
                        />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    exact
                    path={"/users/log-history"}
                    element={
                      <ProtectedRoute
                        isAllowed={roles?.["descendant_users"]?.can_read}
                      >
                        <LogHistoryPage
                          // roles={roles?.["descendant_users"]}
                          usersPrivilages={roles?.["descendant_users"]}
                          rolesPrivilages={roles?.["roles"]}
                        />
                      </ProtectedRoute>
                    }
                  />
                  {/* <Route
                    exact
                    path={"/users/:userId"}
                    element={
                      roles?.["descendant_users"]?.can_read ? (
                        <UserDetailsPage
                          // roles={roles?.["descendant_users"]}
                          usersPrivilages={roles?.["descendant_users"]}
                        />
                      ) : (
                        <Navigate to={"/"} />
                      )
                    }
                  /> */}

                  <Route
                    exact
                    path={"/users/:userId"}
                    element={
                      <ProtectedRoute
                        isAllowed={roles?.["descendant_users"]?.can_read}
                      >
                        <UserDetailsPage
                          // roles={roles?.["descendant_users"]}
                          usersPrivilages={roles?.["descendant_users"]}
                        />
                      </ProtectedRoute>
                    }
                  />
                  {/* <Route
                    exact
                    path={"/users/roles"}
                    element={
                      roles?.["descendant_users"]?.can_read &&
                      roles?.["roles"]?.can_read ? (
                        <RolesPage
                          // roles={roles?.["roles"]}
                          usersPrivilages={roles?.["roles"]}
                        />
                      ) : (
                        <Navigate to={"/"} />
                      )
                    }
                  /> */}

                  <Route
                    exact
                    path={"/users/roles"}
                    element={
                      <ProtectedRoute
                        isAllowed={
                          roles?.["descendant_users"]?.can_read &&
                          roles?.["roles"]?.can_read
                        }
                      >
                        <RolesPage
                          // roles={roles?.["roles"]}
                          usersPrivilages={roles?.["roles"]}
                        />
                      </ProtectedRoute>
                    }
                  />
                  {/* Help Routes */}
                  {/* <Route exact path="/help/faq" element={<FaqPage />} />                                 */}
                  <Route path="*" element={<Navigate to="/" />} />
                </Switch>
              </Grid>
            </MainLayout>
          }
        />
      </Switch>
    </Grid>
  );
};
export default MainTemplate;

export default function handleErrors(error) {
  let errorData = error?.response?.data;

  if (errorData) {
    if (Array.isArray(errorData?.errors)) {
      errorData?.errors?.map((data) => {
        console.log(data?.message);
        throw data?.message;
      });
    } else {
      console.log(errorData?.message);
      throw errorData?.message;
    }
  } else {
    console.log(error?.message);
    throw error?.message;
  }
}

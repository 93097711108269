import React from "react";
import {
  Backdrop,
  Button,
  ClickAwayListener,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from "@mui/material";

import MoreVertIcon from "@mui/icons-material/MoreHorizRounded";
import { useState } from "react";
const CActions = ({
  id,
  index,
  data,
  handleClick,
  handleClose,
  anchorEl,
  anchorRef,
  open,
  actionsList,
}) => {
  return (
    <div style={{ position: "relative" }}>
      <Button
        id="demo-positioned-button"
        aria-controls={open ? "demo-positioned-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={(e) => handleClick(e, index, data)}
        style={{ margin: "auto" }}
      >
        <MoreVertIcon className="moreMenu" style={{ color: "#333132" }} />
      </Button>
      {/* { console.log(anchorRef.current, anchorEl) } */}
      <Popper
        open={anchorEl === index ? true : false}
        anchorEl={anchorRef.current}
        ref={anchorRef}
        role={undefined}
        transition
        disablePortal
        sx={{
          minWidth: "100px",
          "&": {
            position: "absolute !important",
            zIndex: "10",
            top: "25px !important",
            left: "-70px !important",
          },
        }}
      >
        <Backdrop
          sx={{
            color: "#fff",
            backgroundColor: "transparent",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={anchorEl == index ? true : false}
          onClick={handleClose}
        ></Backdrop>
        <Paper
          sx={{
            position: "relative",
            zIndex: (theme) => theme.zIndex.drawer + 2,
          }}
        >
          <ClickAwayListener onClickAway={handleClose}>
            <MenuList
              autoFocusItem={open}
              id="composition-menu"
              aria-labelledby="composition-button"
            >
              {actionsList({ id, index, data})?.map((action, ndx) => {
                return ( 
                  action.condition(index) === true && (
                    <MenuItem
                      onClick={() => {
                        action.click();
                        handleClose();
                      }}
                      key={ndx}
                    >
                      {action.text}
                    </MenuItem>
                  )
                );
              })}
            </MenuList>
          </ClickAwayListener>
        </Paper>
      </Popper>
    </div>
  );
};

export default CActions;

import { axiosFn, handleError } from "..";
import { makeFilterString } from "../Helper/Helper";
import * as URL from "../URLs";

export async function getBranchesList(filter_obj) {
  return await axiosFn("get", URL.branches + makeFilterString(filter_obj))
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}

export async function getBranchDetails(branch_id) {
  return await axiosFn("get", `${URL.branches}/${branch_id}`)
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}

export async function getOrganizationsList(filter_obj) {
  return await axiosFn("get", "/organizations" + makeFilterString(filter_obj))
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}

export async function updateRejectionReason(branch_id, data) {
  return await axiosFn("put", `${URL.branches}/${branch_id}`, data)
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}

export async function approveBranch(id, data) {
  return await axiosFn("put", `${URL.branches}/${id}`, data)
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}

export async function updateBranch(id, data) {
  return await axiosFn("put", `${URL.branches}/${id}`, data)
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}

export async function deleteBranch(id) {
  return await axiosFn("delete", `${URL.branches}/${id}`)
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}

export async function getBranchReviews(filter_obj, data) {
  console.log(data);
  return await axiosFn("post", URL.reviews + makeFilterString(filter_obj), data)
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}

import { useNavigate, useSearchParams } from "react-router-dom";
import CCrud from "../../../Components/Molecules/General/Crud/CCrud";
import Languages from "../../../Components/Atoms/Shared/language/Language";
import { useState } from "react";
import "./Customers.css";
import CustomerDetailsPage from "../../../Pages/Main/Customers/CustomerDetails.Page";
import { updateCustomer } from "../../../Contexts/APIs/Customer/Customer";

const CustomersTemplate = ({
  filterObj,
  setFilterObj,
  data,
  count,
  paginationPage,
  setPaginationPage,
  handlePageChange,
  getCustomers,
  rowData,
  roles,
  openDeleteModal,
  setOpenDeleteModal,
  handelDeleteRequest,
  setCustomersList,
}) => {
  let [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const searchInputPlaceholder = "Search customer";
  const type = searchParams.get("type");
  const [searchEmail, setSearchEmail] = useState("");

  const filterList = [
    {
      key: "status",
      title: "Status",
      list: [
        { id: "active", name: "active" },
        { id: "suspended", name: "suspended" },
      ],
    },
    {
      key: "is_verified",
      title: "Is verified",
      list: [
        { id: true, name: "yes" },
        { id: false, name: "no" },
      ],
    },
  ];

  const filterSearch = [
    {
      key: "email",
      title: "Email",
      setSearchState: setSearchEmail,
      searchState: searchEmail,
      placeholder: "Type email",
      customClass: "search-email",
    },
  ];

  const actionsList = ({ id, index }) => {
    return [
      {
        condition: () => {
          if (data[index].status !== "deleted" && roles?.can_read) {
            return true;
          } else {
            return false;
          }
        },
        click: () => {
          navigate(`/customers/${id}?index=${index}`);
        },
        text: "View Details",
      },
      {
        condition: () => {
          if (
            (data[index].status === "active" ||
              data[index].status === "deactivated") &&
            roles?.can_update &&
            roles?.can_read
          ) {
            return true;
          } else {
            return false;
          }
        },
        click: () => {
          (async () => {
            const { res, err } = await updateCustomer({
              customer_id: id,
              user: {
                status: "suspended",
              },
            });

            if (res) {
              setCustomersList((prevState) =>
                prevState?.map((user) =>
                  user?.id == res?.data?.user?.id ? res?.data?.user : user
                )
              );
            }
          })();
        },
        text: "Suspend",
      },
      {
        condition: () => {
          if (
            data[index].status === "suspended" &&
            roles?.can_update &&
            roles?.can_read
          ) {
            return true;
          } else {
            return false;
          }
        },
        click: () => {
          (async () => {
            const { res, err } = await updateCustomer({
              customer_id: id,
              user: {
                status: "active",
              },
            });

            if (res) {
              setCustomersList((prevState) =>
                prevState?.map((user) =>
                  user?.id == res?.data?.user?.id ? res?.data?.user : user
                )
              );
            }
          })();
        },
        text: "Active",
      },
      {
        condition: () => {
          if (
            (data[index].status === "active" ||
              data[index].status === "deactivated") &&
            roles?.can_delete &&
            roles?.can_read
          ) {
            return true;
          } else {
            return false;
          }
        },
        click: () => {
          setOpenDeleteModal(true);
        },
        text: "Delete",
      },
    ];
  };

  const [supportedLang, setSupportedLang] = useState("en");

  const actionColumn =
    !roles?.can_read && !roles?.can_update
      ? {
          accessor: ".",
          Header: " ",
        }
      : { accessor: "Actions", Header: "Actions" };

  const handleApplyFilterMenu = (obj) => {
    setFilterObj((prev) => {
      return { ...prev, ...obj, page_number: 1 };
    });
    setPaginationPage(1);
  };

  const handleResetFilterMenu = () => {
    setSearchEmail("");
    setFilterObj({
      ...filterObj,
      page_number: 1,
      is_verified: null,
      status: null,
      email: null,
    });
    setPaginationPage(1);
  };

  return (
    <>
      {type == "form" && <Languages setLanguage={setSupportedLang} />}
      <CCrud
        table={{
          list: data,
          listTitle: "Customers",
          emptyListMsg: "There are no customers",
          actionsList: actionsList,
          columns: [
            { accessor: "id", Header: "Id" },
            { accessor: "name", Header: "Name" },
            { accessor: "email", Header: "Email" },
            { accessor: "phone_number", Header: "Phone number" },
            { accessor: "status", Header: "Status" },
            { accessor: "is_verified", Header: "Is verified" },
            actionColumn,
          ],
          isFilter: true,
          isSearch: true,
          isChecked: false,
          isSort: false,
          total_count: count,
          filterList,
          filterSearch,
          setFilterObj,
          className: "customer-table",
          paginationPage: paginationPage,
          setPaginationPage: setPaginationPage,
          searchInputPlaceholder: searchInputPlaceholder,
          handleApplyFilterMenu,
          handleResetFilterMenu,
          handlePageChange: handlePageChange,
          openDeleteModal: openDeleteModal,
          setOpenDeleteModal: setOpenDeleteModal,
          handleDeleteRequest: handelDeleteRequest,
          rowData: rowData,
        }}
        DetailsComponent={<CustomerDetailsPage roles={roles} />}
      />
    </>
  );
};

export default CustomersTemplate;

import { axiosFn, handleError } from "../index";
import * as URL from "../URLs";
import { makeFilterString } from "../Helper/Helper.js";

export async function getFreelancersList(filter_obj) {
    return await axiosFn("get", URL.FREELANCERS + makeFilterString(filter_obj))
        .then((res) => {
            return {
                res: res.data,
                err: null,
            };
        })
        .catch(handleError);
}

export async function getFreelancerDetails(dive_expert_id) {
    return await axiosFn("get", `dive_experts/${dive_expert_id}`)
        .then((res) => {
            return {
                res: res.data,
                err: null,
            };
        })
        .catch(handleError);
}
export async function updateRejectionReason(branch_id, data) {
    return await axiosFn("put", `dive_experts/${branch_id}`, data)
        .then((res) => {
            return {
                res: res.data,
                err: null,
            };
        })
        .catch(handleError);
}


export async function deleteFreelancer(id) {
    return await axiosFn("delete", `/dive_experts/${id}`)
        .then((res) => {
            return {
                res: res.data,
                err: null,
            };
        })
        .catch(handleError);
}

export async function approveFreelancer(id, data) {
    return await axiosFn("patch", `/dive_experts/${id}`, data)
        .then((res) => {
            return {
                res: res.data,
                err: null,
            };
        })
        .catch(handleError);
}

export async function updateFreelancer(id, data) {
    return await axiosFn("put", `/dive_experts/${id}`, data)
        .then((res) => {
            return {
                res: res.data,
                err: null,
            };
        })
        .catch(handleError);
}


export async function addToFavoriate(data, action, setLoading) {
    setLoading(true)

    return await axiosFn("post", `/favourites`, data)
        .then((res) => {
            action && action()
            setLoading(false)
            return {
                res: res.data,
                err: null,
            };
        })
        .catch(handleError);
}

export async function deleteFavoriate(data, action, setLoading) {
    setLoading(true)

    return await axiosFn("delete", `/favourites`, data)
        .then((res) => {
            action && action()
            setLoading(false)
            return {
                res: res.data,
                err: null,
            };
        })
        .catch(handleError);
}

export async function upgradeFreelancerLevel(id, action, setLoading) {
    setLoading && setLoading(true)

    return await axiosFn("put", `/dive_experts/${id}/upgrade`)
        .then((res) => {
            action && action(res?.data)
            setLoading && setLoading(false)
            return {
                res: res.data,
                err: null,
            };
        })
        .catch(handleError);
}
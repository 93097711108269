import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "../../../Components/Atoms/Loader/Loader";
import { Box, Typography } from "@mui/material";
import ContactsTemplate from "../../../Templates/Main/Contacts/Contacts.Template";
import {
  editContact,
  getContactDetails,
  getContactsList,
} from "../../../Contexts/APIs/Contacts/Contacts";

const ContactsPage = ({ roles }) => {
  const navigate = useNavigate();
  let [searchParams] = useSearchParams();
  const index = searchParams.get("index");
  const id = searchParams.get("id");

  const [openLoader, setOpenLoader] = useState(false);
  const [systemConfigsList, setSystemConfigsList] = useState([]);

  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);

  const [filterObj, setFilterObj] = useState(null);

  const [selectedRow, setSelectedRow] = useState({});
  const [systemConfig, setSystemConfig] = useState({});

  const mappedData = systemConfigsList?.map((data, index) => {
    return {
      ...data,
      url: <Typography width="400px">{data?.value}</Typography>,
      action: (
        <Box
          sx={{ cursor: "pointer", textDecoration: "underline" }}
          onClick={() =>
            navigate(`?type=form&id=${data?.id}&name=${data?.name}`)
          }
        >
          Edit
        </Box>
      ),
    };
  });

  const updatePagination = (page) => {
    setFilterObj({ ...filterObj, page_number: page });
  };
  const handlePageChange = (event, value) => {
    setPage(value);
    updatePagination(value);
  };

  const rowData = (data) => {
    setSelectedRow(data?.rowData);
  };

  const getDetails = async (id) => {
    const { res, err } = await getContactDetails(id);
    if (res) {
      setSystemConfig(res?.data?.contact);
    }
    if (err) {
      toast.error(err);
    }
  };

  const getList = async (filterObj) => {
    setOpenLoader(true);
    const defaultFilterObj = {
      page_size: 10,
      page_number: 1,
      keyword: "",
      sort_by: "id",
    };
    const { res, error } = await getContactsList({
      ...defaultFilterObj,
      ...filterObj,
    });
    if (res) {
      setOpenLoader(false);
      setSystemConfigsList(res?.data?.contacts);
      setCount(res?.extra?.total_count);
    }
    if (error) {
      setOpenLoader(false);
    }
  };

  const handleSubmit = (data, formik) => {
    const action = () => {
      searchParams.delete("id");
      searchParams.delete("type");
      searchParams.delete("index");
      formik?.resetForm();
      navigate(`/contacts`);
    };
    if (id) {
      const { res, err } = editContact(
        id,
        {
          contact: data,
        },
        action
      );
    }
  };

  useEffect(() => {
    id && getDetails(id);
  }, [id]);

  useEffect(() => {
    if (filterObj) getList(filterObj);
  }, [filterObj]);
  return (
    <>
      <Loader open={openLoader} />
      <ContactsTemplate
        setFilterObj={setFilterObj}
        data={mappedData}
        count={count}
        paginationPage={page}
        setPaginationPage={setPage}
        handlePageChange={handlePageChange}
        handleSubmit={handleSubmit}
        rowData={rowData}
        systemConfig={systemConfig}
        roles={roles}
      />
    </>
  );
};

export default ContactsPage;

import { Box, Button, Modal, Stack, Typography } from "@mui/material";
import React from "react";
import { icons } from "../../../../Assets/AssetHelper";
import CloseIcon from "@mui/icons-material/Close";
const ImagesPreviewModel = ({ open, setOpen, data }) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "25px",
  };

  const handleClose = () => setOpen({ status: false, data: null });

  return (
    <Modal
      open={open?.status}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Stack sx={style} gap={2}>
        <Stack
          px={4}
          pt={2}
          alignItems="center"
          justifyContent="space-between"
          flexDirection="row"
        >
          <Typography fontSize="24px" fontWeight="bold">
            {open?.data?.name}
          </Typography>
          <Box onClick={handleClose} sx={{ cursor: "pointer" }}>
            <CloseIcon sx={{ width: "35px" }} />
          </Box>
        </Stack>

        <img src={open.data?.logo} width="100%" height="100%" />
      </Stack>
    </Modal>
  );
};

export default ImagesPreviewModel;

import * as Yup from "yup";

export const initialValues = (data) => ({
  ...data.localized_data,
  is_professional: data?.is_professional ? data?.is_professional : false,
  is_hidden: data?.is_hidden ? data?.is_hidden : false
});

export const structure = (lang, organizationName) => [
  {
  head: "Certificate  information",
  list: [
    {
      kind: "input",
      type: "text",      
      width: "100%",
      disabled: 'true', 
      defaultValue: organizationName,
      className: 'organizationName'
    },
    {
      name: `${lang}.name`,
      kind: "input",
      type: "text",
      label: "Certificate name",
      width: "100%",
    },
    {
      name: `is_professional`,
      kind: "checkbox",      
      label: "Is professional",
      width: "35%",
    },
    {
      name: `is_hidden`,
      kind: "checkbox",      
      label: "Is hidden",
      width: "60%",
    },
  ]
  }
]

export const validationSchema = () =>
  Yup.object({
    en: Yup.object().shape({
      name: Yup.string().required("required")
    })
});

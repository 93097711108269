import { Button, Divider, Grid, Menu, Stack, Typography } from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import {
  getActiveUserStatistics,
  getEarningsStatistics,
  getOrderStatistics,
} from "../../Contexts/APIs/Statistics/statistics";
import Charts from "../../Components/Atoms/SharedComponents/Charts";
import NormalTabs from "../../Components/Atoms/Tabs/NormalTabs";
import BranchesFilter from "./BranchesFilter";
import "./DiveCenterStatistics.css";
import DiveCenterStatisticsFilters from "./DiveCenterStatisticsFilters";
import SearchableSelect from "../../Components/Atoms/Select/SearchableSelect";
import PrimaryButton from "../../Components/Atoms/Buttons/Primary/PrimaryButton";
import { AreaAPI } from "../../Contexts/APIs/Lookups/Area";
import { CityAPI } from "../../Contexts/APIs/Lookups/City";
import { CountryAPI } from "../../Contexts/APIs/Lookups/Country";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { getOperatorsList } from "../../Contexts/APIs/Operators/Operators";

const HomeTemplate = ({ setPage, setFilterObj, filterObj, handleChange }) => {
  // const [page, setPage] = useState(1);

  const [allData, setAllData] = useState(null);
  const [typeStatus, setTypesStatus] = useState();
  const [diveCenters, setDiveCenters] = useState([]);
  const [diveCenter, setDiveCenter] = useState({});
  const [data, setData] = useState([]);
  const [commisionData, setCommisionData] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [countries, setCounries] = useState([]);
  const [areasCount, setAreasCount] = useState(0);
  const [areas, setAreas] = useState([]);
  const [diveCentersCount, setDiveCentersCount] = useState(0);

  const [country, setCountry] = useState();
  const [city, setCity] = useState();
  const [cities, setCities] = useState([]);
  const [countriesCount, setCounriesCount] = useState(0);
  const [citiesCount, setCitiesCount] = useState(0);
  const [ticks, setTicks] = useState([]);
  const [area, setArea] = useState();
  const [commissionTicks, setCommissionTicks] = useState([]);
  const [selectedTabId, setSelectedTabId] = useState(0);
  // const [filterObj, setFilterObj] = useState({
  //   interval_type: "month",
  //   keyword: "",
  //   page_number: 1,
  // });
  const [totals, setTotals] = useState({
    totalEarnings: 0,
    totalReservations: 0,
    totalCommission: 0,
  });
  const getYAixData = (value) => {
    let yAxisKey = "";
    let commisionYAxis = "";
    let commisionGraphPointKey = "";
    let graphPointKey = "";
    switch (value) {
      case 0:
        yAxisKey = "reservations_y_axis";
        graphPointKey = "reservations_count";
        break;
      case 1:
        yAxisKey = "earnings_y_axis";
        commisionYAxis = "commission_y_axis";
        commisionGraphPointKey = "commission_amount";
        graphPointKey = "earnings_amount";
        break;
      case 2:
        yAxisKey = "y_axis";
        // graphPointKey = "graph_points";
        break;
      default:
        yAxisKey = "reservations_y_axis";
        graphPointKey = "reservations_count";
    }
    return { yAxisKey, commisionYAxis, commisionGraphPointKey, graphPointKey };
  };
  // get statistics data
  const getStatistics = async (filterObj, selectedTabId) => {
    switch (selectedTabId) {
      case 0: // Else case logic
        const { res: orderStatistics } = await getOrderStatistics({ ...filterObj });
        if (orderStatistics) {
          setAllData(orderStatistics.data.order_statistics);
          setTotals({
            totalEarnings: orderStatistics.data.order_statistics.total_earnings_amount,
            totalReservations: orderStatistics.data.order_statistics.total_reservations,
            totalCommission: orderStatistics.data.order_statistics.total_commission_amount,
          });
        }
        break;
        
      case 1:
        //earnings
        const { res: earningsStatistics } = await getEarningsStatistics({ earning_scope:"all",...filterObj  });//only this api need to have all , not by default
        if (earningsStatistics) {
          setAllData(earningsStatistics.data.earning_statistics);
          setTotals({
            totalEarnings: earningsStatistics.data.earning_statistics.total_earnings_amount,
            totalReservations: earningsStatistics.data.earning_statistics.total_reservations,
            totalCommission: earningsStatistics.data.earning_statistics.total_commission_amount,
          });
        }

        break;
  
      case 2:
        const { res: activeUser } = await getActiveUserStatistics({
          ...filterObj,
          activity_owner_type: "",
        });
        if (activeUser) {
          setAllData(activeUser.data.active_users_statistics);
          setTotals({
            totalUsers: activeUser.data.active_users_statistics.total_users_count,
          });
        }
        break;
        default:{
          break;
        }
    }
  };
    // change the data when tab change
  const updateDataForSelectedTab = (selectedTabId) => {
    if (!allData) return;
    const { yAxisKey, commisionYAxis, graphPointKey, commisionGraphPointKey } =
      getYAixData(selectedTabId);

    const x_axis = allData.x_axis;
    const y_axis = allData[yAxisKey];
    const commision_y_axis = allData[commisionYAxis];
    const graph_points = allData.graph_points;
    const formattedData = x_axis?.map((date, index) => ({
      name: date,
      graphPoints:
        selectedTabId === 2
          ? graph_points?.[date]
          : graph_points?.[date]?.[graphPointKey] || 0, 
      y_axis: y_axis?.[index] || 0,
    }));
    const formattedCommissionData = x_axis?.map((date, index) => ({
      name: date,
      graphPoints:
        selectedTabId === 2 
          ? graph_points?.[date]
          : graph_points?.[date]?.[commisionGraphPointKey] || 0,
      y_axis: commision_y_axis?.[index] || 0,
    }));
    setData(formattedData);
    setCommisionData(formattedCommissionData);
    setTicks(y_axis || []);
    setCommissionTicks(commision_y_axis || []);
  };
  useEffect(() => {
    getStatistics(filterObj, selectedTabId);

  }, [filterObj, selectedTabId]);

  useEffect(() => {
    updateDataForSelectedTab(selectedTabId);
  }, [allData, selectedTabId]);

  const options = [
    {
      id: 0,
      label: "Bookings",
    },
    {
      id: 1,
      label: "Earnings",
    },
    {
      id: 2,
      label: "Active users",
    },
  ];
  const handleTabChange = async (event, newValue) => {
    setSelectedTabId(newValue);
    // if (newValue === 2) {
    //   const { res: activeUser } = await getActiveUserStatistics({
    //     ...filterObj,
    //     activity_owner_type: "",
    //   });
    //   if (activeUser) {
    //     setAllData(activeUser.data.active_users_statistics);
    //     setTotals({
    //       totalUsers: activeUser.data.active_users_statistics.total_users_count,
    //     });
    //   }
    // } else {
    //   const { res } = await getOrderStatistics({ ...filterObj });
    //   if (res) {
    //     setAllData(res.data.order_statistics);
    //     setTotals({
    //       totalEarnings: res.data.order_statistics.total_earnings_amount,
    //       totalReservations: res.data.order_statistics.total_reservations,
    //     });
    //   }
    // }
  };
  const getSelectedTabLabel = () => {
    const selectedTab = options.find((option) => option.id === selectedTabId);
    return selectedTab ? selectedTab.label : "";
  };
  const getTotalForSelectedTab = () => {
    switch (selectedTabId) {
      case 0:
        return `${totals?.totalReservations?totals?.totalReservations:"0"} ${"bookings"}`;
      case 1:
        return {
          commission: totals.totalCommission,
          earning: totals.totalEarnings,
        };
      case 2:
        return `${totals?.totalUsers?totals?.totalUsers:"0"} ${"users"}`;
      default:
        return "";
    }
  };
  const getTitleForSelectedTab = () => {
    switch (selectedTabId) {
      case 0:
        return `Total no. of bookings`;
      case 1:
        return `Total earnings `;
      case 2:
        return "Active users";
      default:
        return "";
    }
  };

  const activityTypeOptions = [
    {
      id: 0,
      label: "All",
    },
    {
      id: 1,
      label: "Courses",
    },
    {
      id: 2,
      label: "Dive trip",
    },
    {
      id: 3,
      label: "Liveaboard",
    },
  ];
  const usersTypeOptions = [
    {
      id: 0,
      label: "All",
    },
    {
      id: 1,
      label: "Freelancers",
    },
    {
      id: 2,
      label: "Customers",
    },
    {
      id: 3,
      label: "Diveclubs",
    },
  ];

  const EarningsTypeOptions = [
    {
      id: 0,
      label: "All",
    },
    {
      id: 1,
      label: "Activity",
    },
    {
      id: 2,
      label: "Private guide",
    },
    {
      id: 3,
      label: "Freelancer requests",
    },
  ];

  const getActivityType = (value) => { //switch case ?
    if (value === 0) {
      setFilterObj({ ...filterObj, activity_type: "", page_number: "" });
    }

    if (value === 1) {
      setFilterObj({
        ...filterObj,
        activity_type: "dive_course",
        page_number: "",
      });
    }
    if (value === 2) {
      setFilterObj({
        ...filterObj,
        activity_type: "dive_trip",
        page_number: "",
      });
    }
    if (value === 3) {
      setFilterObj({
        ...filterObj,
        activity_type: "live_aboard",
        page_number: "",
      });
    }
  };
  const getUserType = (value) => {
    if (value === 0) {
      setFilterObj({ ...filterObj, user_type: "", page_number: "" });
    }

    if (value === 1) {
      setFilterObj({
        ...filterObj,
        user_type: "DiveExpert",
        page_number: "",
      });
    }
    if (value === 2) {
      setFilterObj({
        ...filterObj,
        user_type: "Customer",
        page_number: "",
      });
    }
    if (value === 3) {
      setFilterObj({
        ...filterObj,
        user_type: "DiveClub",
        page_number: "",
      });
    }
  };

  const getEarningsType = (value) => {
    switch (value) {
      case 0:
        setFilterObj({ ...filterObj, earning_scope: "all", page_number: "" });
        break;
      case 1:
        setFilterObj({
          ...filterObj,
          earning_scope: "dive_activities",
          page_number: "",
        });
        break;
      case 2:
        setFilterObj({
          ...filterObj,
          earning_scope: "private_guides",
          page_number: "",
        });
        break;
      case 3:
        setFilterObj({
          ...filterObj,
          earning_scope: "job_offers",
          page_number: "",
        });
        break;
      default:
        break;
    }
  };

    const intervalTypeOptions = [
    {
      id: 0,
      label: "Monthly",
    },
    {
      id: 1,
      label: "Daily",
    },
    {
      id: 2,
      label: "Yearly",
    },
  ];
  const getIntervalType = (value) => {
    if (value === 0) {
      setFilterObj({
        ...filterObj,
        interval_type: "month",
        page_number: "",
      });
    }
    if (value === 1) {
      setFilterObj({
        ...filterObj,
        interval_type: "day",
        page_number: "",
      });
    }
    if (value === 2) {
      setFilterObj({
        ...filterObj,
        interval_type: "year",
        page_number: "",
      });
    }
  };
  const activityOwnerOptions = [
    {
      id: 1,
      label: "All",
    },
    {
      id: 2,
      label: "Activity",
    },
    {
      id: 3,
      label: "Private guide",
    },
  ];
  const getActivityOwnerType = (value) => {
    if (value === 0) {
      setFilterObj({ ...filterObj, activity_owner_type: "", page_number: "" });
    }
    if (value === 1) {
      setFilterObj({
        ...filterObj,
        activity_owner_type: "DiveCenter",
        page_number: "",
      });
    }
    if (value === 2) {
      setFilterObj({
        ...filterObj,
        activity_owner_type: "DiveExpert",
        page_number: "",
      });
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getCountries = async (filter) => {
    const prevCountries = countries;
    const DataKey = filter?.keyword;
    if (
      (filter?.page_number === 1 || filter?.page_number === undefined) &&
      !DataKey &&
      countries.length === 10
    ) {
      setCounries(prevCountries);
    } else {
      const { res, error } =
        countriesCount !== 10 || DataKey
          ? await CountryAPI({ page_size: 10, ...filter })
          : {};
      if (res) {
        filter?.page_number === 1 || filter?.page_number === undefined
          ? setCounries(res?.data?.countries)
          : setCounries((prev) => [...prev, ...res?.data?.countries]);
        setCounriesCount(res?.extra?.total_count);
      }
    }
  };

  const getCities = async (filter) => {
    const prevCities = cities;
    const DataKey = filter?.keyword;
    if (
      (filter?.page_number === 1 || filter?.page_number === undefined) &&
      !DataKey &&
      !filter?.country_id
    ) {
      setCities(prevCities);
    } else {
      const { res, error } =
        citiesCount !== 10 || DataKey
          ? await CityAPI({
              page_size: 10,
              country_id: filter?.country_id,
              ...filter,
            })
          : {};
      if (res) {
        filter?.page_number === 1 || filter?.page_number === undefined
          ? setCities(res?.data?.cities)
          : setCities((prev) => [...prev, ...res?.data?.cities]);
        setCitiesCount(res?.extra?.total_count);
      }
    }
  };
  const getAreas = async (filter) => {
    const prevAreas = areas;
    const DataKey = filter?.keyword;
    if (
      (filter?.page_number === 1 || filter?.page_number === undefined) &&
      !DataKey &&
      !filter?.city_id
    ) {
      setAreas(prevAreas);
    } else {
      const { res, error } =
        areasCount !== 10 || DataKey
          ? await AreaAPI({
              page_size: 10,
              city_id: filter?.city_id,
              ...filter,
            })
          : {};
      if (res) {
        filter?.page_number === 1 || filter?.page_number === undefined
          ? setAreas(res?.data?.areas)
          : setAreas((prev) => [...prev, ...res?.data?.areas]);
        setAreasCount(res?.extra?.total_count);
      }
    }
  };
  const handleApplyFilterMenu = () => {
    setFilterObj({
      ...filterObj,
      page_number: 1,
      country_id: country?.value,
      city_id: city?.value,
      area_id: area?.value,
      dive_center_id: diveCenter?.value,
      ordered_by: typeStatus?.value,
    });
    handleClose();
    setPage(1);
  };
  const handleResetFilterMenu = () => {
    setCountry({});
    setCity({});
    setArea({});
    // setLevel({});
    // setStatus({});
    // setFrom(null);
    // setTo(null);
    setFilterObj({ page_number: 1, interval_type: "month" });
    handleClose();
    setPage(1);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const typesList = [
    { id: "", name: "All" },
    { id: "Customer", name: "Customer" },
    { id: "DiveClub", name: "Dive club" },
  ];

  // selectedTabId === 0
  //   ? [
  //       { id: 1, name: "All", value: "" },
  //       { id: 2, name: "Customer", values: "Customer" },
  //       { id: 3, name: "Dive club", value: "DiveClub" },
  //     ]
  //   : [
  //       { id: 1, name: "All" },
  //       { id: 2, name: "Freelancer requests" },
  //       { id: 3, name: "Customer" },
  //       { id: 4, name: "Dive club" },
  //     ];

  const getDiveCenters = async (filter) => {
    const prevDiveCenters = diveCenters;
    const DataKey = filter?.keyword;
    if (
      (filter?.page_number === 1 || filter?.page_number === undefined) &&
      !DataKey &&
      diveCenters.length === 10
    ) {
      setDiveCenters(prevDiveCenters);
    } else {
      const { res, error } =
        diveCentersCount !== 10 || DataKey
          ? await getOperatorsList({ page_size: 10, ...filter })
          : {};
      if (res) {
        filter?.page_number === 1 || filter?.page_number === undefined
          ? setDiveCenters(res?.data?.dive_centers)
          : setDiveCenters((prev) => [...prev, ...res?.data?.dive_centers]);
        setDiveCentersCount(res?.extra?.total_count);
      }
    }
  };
  return (
    <Stack>
      <Typography
        component="h1"
        style={{ fontWeight: "bold", fontSize: "30px", marginBottom: "10px" }}
      >
        Statistics
      </Typography>
      {/* Bookings, Earnings, Active users Tabs */}
      <Stack sx={{ mb: "20px" }}>
        <NormalTabs
          TabsOptions={options}
          value={selectedTabId}
          change={handleTabChange}
          ContainerStyle="dive-center-tabs-container"
          TabStyle="dive-center-tabs"
          onclick={(_) => console.log()}
        />
      </Stack>
      <Grid container justifyContent={"space-between"}>
        {/* Earning Tab */}
        {getSelectedTabLabel() == "Earnings" && (
          <Grid sx={{ mb: "20px" }} className="border-r-10px">
            <DiveCenterStatisticsFilters
              getType={getEarningsType}
              options={EarningsTypeOptions}
            />
          </Grid>
        )}


        {/* Activity Owner Tabs */}
        {getSelectedTabLabel() == "Bookings" && (
          <Grid sx={{ mb: "20px" }} className="border-r-10px">
            <DiveCenterStatisticsFilters
              getType={getActivityOwnerType}
              options={activityOwnerOptions}
            />
          </Grid>
        )}
        {/* Activity Type Tabs */}
        {getSelectedTabLabel() !== "Active users" && (
          <Grid sx={{ mb: "20px" }} className="border-r-10px">
            <DiveCenterStatisticsFilters
              getType={getActivityType}
              options={activityTypeOptions}
            />
          </Grid>
        )}
        {getSelectedTabLabel() == "Active users" && (
          <Grid sx={{ mb: "20px" }} className="border-r-10px">
            <DiveCenterStatisticsFilters
              getType={getUserType}
              options={usersTypeOptions}
            />
          </Grid>
        )}
        <Stack sx={{ flexDirection: "row", gap: "20px" }}>
          {/* filter */}
          <Button
            aria-label="filter"
            id="filter-button"
            aria-controls={Boolean(anchorEl) ? "filter-menu" : undefined}
            aria-expanded={Boolean(anchorEl) ? "true" : undefined}
            aria-haspopup="true"
            variant="outlined"
            startIcon={<FilterAltIcon sx={{ color: "#bebebe" }} />}
            sx={{
              width: "100%",
              height: "min-content",
              color: "#333132",
              borderRadius: "10px",
              border: "solid 1px #ececec",
              fontSize: "16px",
              fontWeight: 500,
              textTransform: "capitalize",
              lineHeight: "unset",
              paddingY: "10px",
            }}
            onClick={handleClick}
          >
            Filter
          </Button>
          <Menu
            id="filter-menu"
            MenuListProps={{
              "aria-labelledby": "filter-button",
            }}
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            PaperProps={{
              style: {
                width: "330px",
                paddingBottom: "10px",
                borderRadius: "6px",
                border: "solid 1px #bebebe",
                boxShadow: "none",
              },
            }}
          >
            <Typography
              sx={{
                paddingInlineStart: "16px",
                fontSize: "16px",
                fontWeight: 500,
              }}
            >
              Filter by
            </Typography>
            <Divider sx={{ width: "100%", margin: "12px 0" }} />
            <Grid container sx={{ padding: "0 16px" }}>
              <Grid item xs={12}>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: 500,
                  }}
                >
                  Location
                </Typography>
              </Grid>
              <Grid item xs={12} mb={2}>
                <SearchableSelect
                  name="country_id"
                  options={countries}
                  getList={getCountries}
                  count={countriesCount}
                  label="Country"
                  className="searchable-select"
                  onChange={(val) => {
                    setCountry(val);
                    setCity(null);
                    setArea(null);
                    setCities([]);
                    setAreas([]);
                  }}
                  value={country}
                  onClear={() => {
                    setCountry(null);
                    setCity(null);
                    setArea(null);
                    setCities([]);
                    setAreas([]);
                  }}
                  onMenuOpen={() => countries.length === 0 && getCountries()}
                />
              </Grid>
              <Grid item xs={12} mb={2}>
                <SearchableSelect
                  name="city_id"
                  options={cities}
                  getList={getCities}
                  count={citiesCount}
                  label="Province"
                  className="searchable-select"
                  onChange={(val) => {
                    setCity(val);
                    setArea(null);
                    setAreas([]);
                  }}
                  value={city}
                  onClear={() => {
                    setCity(null);
                    setArea(null);
                    setAreas([]);
                  }}
                  moreFilterData={{ country_id: country?.value }}
                  onMenuOpen={() =>
                    cities.length === 0 &&
                    country?.value &&
                    getCities({ page_number: 1, country_id: country.value })
                  }
                />
              </Grid>
              <Grid item xs={12} mb={2}>
                <SearchableSelect
                  name="area_id"
                  options={areas}
                  getList={getAreas}
                  count={areasCount}
                  label="City"
                  className="searchable-select"
                  onChange={(val) => setArea(val)}
                  value={area}
                  onClear={() => setArea(null)}
                  moreFilterData={{ city_id: city?.value }}
                  onMenuOpen={() =>
                    areas.length === 0 &&
                    city?.value &&
                    getAreas({ page_number: 1, city_id: city.value })
                  }
                />
              </Grid>

              {getSelectedTabLabel() !== "Active users" && (
                  <Grid item xs={12}>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: 500,
                    }}
                  >
                    Type
                  </Typography>
                </Grid>
                )} 


              {getSelectedTabLabel() !== "Active users" && (
                <Grid item xs={12} mb={2}>
                  <SearchableSelect
                    name="ordered_by"
                    options={typesList}
                    label="Select option"
                    className="searchable-select"
                    onChange={(val) => setTypesStatus(val)}
                    value={typeStatus}
                    onClear={() => setTypesStatus(null)}
                  />
              </Grid>
              )} 



              {getSelectedTabLabel() !== "Active users" && (
                <Grid item xs={12}>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: 500,
                    }}
                  >
                    Dive center:
                  </Typography>
              </Grid>
            )} 
            {getSelectedTabLabel() !== "Active users" && (
              <Grid item xs={12} mb={2}>
              <SearchableSelect
                name="dive_center_id"
                options={diveCenters}
                getList={getDiveCenters}
                count={diveCentersCount}
                label="Select option"
                className="searchable-select"
                onChange={(val) => setDiveCenter(val)}
                value={diveCenter}
                onClear={() => setDiveCenter(null)}
                onMenuOpen={() =>
                  diveCenters.length === 0 && getDiveCenters()
                }
              />
            </Grid>
          )} 



              <Grid item xs={6} mt={2.5} sx={{ paddingInlineEnd: "10px" }}>
                <PrimaryButton
                  text="Reset"
                  classBtn="reset-btn"
                  click={handleResetFilterMenu}
                />
              </Grid>
              <Grid item xs={6} mt={2.5} sx={{ paddingInlineStart: "10px" }}>
                <PrimaryButton
                  text="Apply"
                  classBtn="apply-btn"
                  click={handleApplyFilterMenu}
                />
              </Grid>
            </Grid>
          </Menu>
          {/* Interval Tabs */}
          <Grid sx={{ mb: "20px" }} className="border-r-10px">
            <DiveCenterStatisticsFilters
              getType={getIntervalType}
              options={intervalTypeOptions}
            />
          </Grid>
        </Stack>
      </Grid>
      {/* Charts */}
      {/* <Charts
        data={data}
        ticks={ticks}
        total={getTotalForSelectedTab()}
        title={getTitleForSelectedTab()}
      /> */}
      {selectedTabId === 1 ? (
        <Stack
          width={"100%"}
          justifyContent={"space-between"}
          direction={"row"}
          alignItems={"center"}
        >
          <Stack width="49%">
            <Charts
              data={data}
              ticks={ticks}
              total={getTotalForSelectedTab().earning}
              totalTitle={`Total`} //added this to ensure that we already know from the start if this chart should have a total or not , this fix the messy render
              title={"Money"}
              height={400}
            />
          </Stack>
          <Stack width="49%">
            <Charts
              data={commisionData}
              ticks={commissionTicks}
              total={getTotalForSelectedTab().commission}
              totalTitle={`Total`} //added this to ensure that we already know from the start if this chart should have a total or not , this fix the messy render
              title={getTitleForSelectedTab()}
              height={400}
            />
          </Stack>

        </Stack>
      ) : (
        <Charts
          data={data}
          ticks={ticks}
          total={getTotalForSelectedTab()}
          totalTitle={`Total`} //added this to ensure that we already know from the start if this chart should have a total or not , this fix the messy render
          title={getTitleForSelectedTab()}
        />
      )}
    </Stack>
  );
};

export default HomeTemplate;

import React from 'react'
import Loader from '../../../Components/Atoms/Loader/Loader';
import BranchDetailsTemplate from '../../../Templates/Main/Branches/BranchDetails.Template';
import { getBranchDetails } from '../../../Contexts/APIs/Branches/Branches';
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { useEffect } from 'react';

function BranchDetailsPage({roles}) {
    const { branchId } = useParams();
    const [branchData, setBranchData] = useState({});
    const [openLoader, setOpenLoader] = useState(false);

    const getBranch = async (id) => {
        setOpenLoader(true);
        const { res, err } = await getBranchDetails(id);
        if (res) { setBranchData(res?.data?.branch); console.log(res?.data?.branch); }
        if (err) toast.error(err)
        setOpenLoader(false);
    };

    useEffect(() => {
        getBranch(branchId)
    }, []);
  return (
    <>
        <Loader open={openLoader} />
        <BranchDetailsTemplate 
            branchData={branchData}
            getBranch={getBranch}
            roles={roles}
        />
    </>
  )
}

export default BranchDetailsPage
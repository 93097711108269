import { axiosFn, handleError } from "../index";
import * as URL from "../URLs";

export async function LogoutAPI() {
  return await axiosFn("post", URL.auth + "logout")
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}

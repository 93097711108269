import { CircularProgress, Grid } from "@mui/material";
import React, { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomPagination from "../../../Atoms/Pagination/Pagination";
import "./OperatorsList.css";
import RejectionModal from "../../General/Models/RejectionModal";
import {
  approveOperator,
  deleteOperator,
  updateOperator,
  updateRejectionReason,
} from "../../../../Contexts/APIs/Operators/Operators";
import PrivilagesMenu from "../../General/PagesHead/Menu/PrivilagesMenu";
import SwitcheLabel from "../../../Atoms/Buttons/Switch/SwitchButton";
import Loader from "../../../Atoms/Loader/Loader";
import PhoneInput from 'react-phone-input-2'

function OperatorsList(props) {
  const {
    operatorsList,
    count,
    page,
    handlePageChange,
    getOperators,
    filterObj,
    roles,
    salesman_roles,
    setOperatorsList,
  } = props;
  const navigate = useNavigate();
  const userType = localStorage.getItem("userType");

  const [openRejectModel, setOpenRejectModel] = useState(false);
  const [modalType, setModalType] = useState("text");
  const [selectedOperator, setSelectedOperator] = useState(false);
  const [rejectionReason, setRejectionReason] = useState("");
  const [errorListener, setErrorListener] = useState(false);
  const [rejectHeadName, setRejectHeadName] = useState();
  const [isLoading, setLoading] = useState(false);

  const [salesManActive, setSalesManActive] = useState([
    {
      active: false,
      index: null,
    },
  ]);
  const handelRejectReason = async () => {
    if (rejectionReason.trim().length === 0 && modalType === "form") {
      setErrorListener(true);
    } else {
      if (modalType === "form") {
        const { res, err } = await updateRejectionReason(
          selectedOperator?.user?.id,
          {
            user: {
              profile_status: "rejected",
              rejection_reason: `${rejectionReason}`,
            },
          }
        );
        if (res) {
          setOpenRejectModel(false);
          getOperators(filterObj);
          setModalType("text");
          setErrorListener(false);
        }
      } else {
        setModalType("form");
        setErrorListener(false);
      }
    }
  };
  const handelCancelReject = () => {
    setOpenRejectModel(false);
    setModalType("text");
  };

  const handelOpenRejectReason = (value) => {
    setRejectHeadName(value);
    setOpenRejectModel(true);
  };
  const handelSalesManActive = async (val, index, id) => {
    let mappedData = salesManActive;
    let newOperators = operatorsList;
    mappedData?.map(() => {
      mappedData[index] = { active: val, index: index };
      newOperators[index] = { ...newOperators[index], salesman_feature: val };
    });
    const { res, err } = await updateOperator(id, {
      user: { salesman_feature: val },
    });
    if (res) {
      setSalesManActive(mappedData);
      setOperatorsList(newOperators);
    }
  };
  useEffect(() => {
    setLoading(true);
    let mappedData = salesManActive;
    operatorsList?.map((data, index) => {
      mappedData[index] = { active: data?.salesman_feature, index: index };
    });
    setSalesManActive(mappedData);

    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, [operatorsList]);
  return (
    <Grid container>
      <Grid container className="table-header mb-20px">
        <Grid item md={0.5} textAlign="center">
          ID
        </Grid>
        <Grid item sm={2}>
          Name
        </Grid>
        <Grid item sm={2}>
          Email
        </Grid>
        <Grid item sm={1.5}>
          Phone number
        </Grid>
        <Grid item sm={1}>
          Is verified
        </Grid>
        <Grid item sm={1.5}>
          Account status
        </Grid>
        <Grid item sm={1.7}>
          Approval Status
        </Grid>
        <Grid item sm={1}>
          Sales men
        </Grid>

        {!roles?.can_read && !roles?.can_update && !roles?.can_delete ? null : (
          <Grid item sm={0.5} sx={{ pl: 2 }}>
            Action
          </Grid>
        )}
      </Grid>

      {operatorsList?.length === 0 && (
        <Grid container display="block" textAlign="center">
          There are no operators
        </Grid>
      )}

      {operatorsList?.length >= 1 && (
        <>
          {operatorsList?.map((item, index) => {
            return (
              <Grid
                key={index}
                container
                item
                lg={12}
                className="user-item mb-20px"
                onClick={() => {
                  setSelectedOperator({ user: item, index: index });
                  setRejectionReason(item.rejection_reason);
                }}
              >
                <Grid item md={0.5} className="user-item-no" textAlign="center">
                  {item.id}
                </Grid>
                <Grid item sm={2} className="user-item-name">
                  {item.name}
                </Grid>
                <Grid item sm={2}>
                  {item.email}
                </Grid>
                <Grid item sm={1.5}>
                  {
                    item?.phone_number && <PhoneInput
                      inputStyle={{
                        border: "none",
                        width: "100%",
                        padding: "0px",
                        direction: "ltr",
                        fontSize: '18px',                      
                        color: '#210000',
                        backgroundColor: 'white',
                        wordBreak: 'break-word'
                      }}
                      defaultMask={ parseInt(item.country_code) == 20 ? '... ... ....' : '.. ... ... ....' }  
                      value={`${item.country_code} ${item?.phone_number}`}
                      disabled={true}
                      disableDropdown={true}
                      specialLabel=""
                      placeholder=""
                    />
                  }
                </Grid>
                <Grid item sm={1}>
                  <div
                    className={`${
                      item.is_verified
                        ? "operator-verified"
                        : "operator-not-verified"
                    }`}
                  >
                    {item.is_verified ? "Yes" : "No"}
                  </div>
                </Grid>
                <Grid item sm={1.5} sx={{ textTransform: "capitalize" }}>
                  {item.status}
                </Grid>
                <Grid
                  item
                  sm={1.7}
                  sx={{
                    textTransform: "capitalize",
                    color: `${
                      item.profile_status === "approved"
                        ? "#00b906"
                        : item.profile_status === "pending_approval" ||
                          item.profile_status === "incomplete"
                        ? "#f6b119"
                        : item.profile_status === "rejected"
                        ? "#e10007"
                        : "#333132"
                    }`,
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  {item.profile_status === "pending_approval"
                    ? "pending approval"
                    : item.profile_status}
                  {item.profile_status === "rejected" && (
                    <button
                      style={{
                        background: "transparent",
                        textDecoration: "underLine",
                        border: "none",
                        fontSize: "16px",
                        lineHeight: "1.88",
                        textAlign: "left",
                        color: "#272726",
                        cursor: "pointer",
                      }}
                      onClick={() => handelOpenRejectReason(item.name)}
                    >
                      {" "}
                      ( View reasons ){" "}
                    </button>
                  )}
                </Grid>
                {isLoading ? (
                  <CircularProgress />
                ) : (
                  <Grid item sm={1}>
                    <SwitcheLabel
                      checked={
                        salesManActive[index]?.index == index &&
                        salesManActive[index]?.active
                      }
                      onChange={(val) =>
                        handelSalesManActive(
                          val.target.checked,
                          index,
                          item?.id
                        )
                      }
                    />
                  </Grid>
                )}
                {!roles?.can_read &&
                !roles?.can_update &&
                !roles?.can_delete ? null : (
                  <Grid item sm={0.5} className="operator-menu-button-wrap">
                    <PrivilagesMenu
                      user={item}
                      statusAttribute={"status"}
                      profileStatusAttribute={"profile_status"}
                      userPath={"operators"}
                      userPathLength={9}
                      handleApprove={approveOperator}
                      handleUpdate={updateOperator}
                      handleDelete={deleteOperator}
                      handleRejection={updateRejectionReason}
                      getUsers={getOperators}
                      filterObj={filterObj}
                      setErrorListener={setErrorListener}
                      errorListener={errorListener}
                      roles={roles}
                      salesman_roles={salesman_roles}
                      list={operatorsList}
                      setList={setOperatorsList}
                    />
                  </Grid>
                )}
              </Grid>
            );
          })}
          {count > 10 && (
            <Grid
              container
              item
              justifyContent={"center"}
              xs={12}
              mb={7.5}
              mt={5}
            >
              <CustomPagination
                count={Math.ceil(count / 10)}
                size="large"
                shape="rounded"
                page={page}
                handleChange={handlePageChange}
                className="pagination"
              />
            </Grid>
          )}
        </>
      )}

      {openRejectModel === true && (
        <RejectionModal
          open={openRejectModel}
          setOpen={setOpenRejectModel}
          text={[`Rejection reason  ( ${rejectHeadName} )`, rejectionReason]}
          rejectionReason={rejectionReason}
          setRejectionReason={setRejectionReason}
          errorListener={errorListener}
          buttons={["Cancel", `${modalType === "form" ? "ok" : "Edit reason"}`]}
          handelRejectRequest={() => {
            handelRejectReason();
          }}
          handelCancelReject={handelCancelReject}
          modalType={modalType}
          setErrorListener={setErrorListener}
        />
      )}
    </Grid>
  );
}

export default OperatorsList;

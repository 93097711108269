import * as Yup from "yup";

export const initialValues = (data) => ({  
  ...data.localized_data,
  website: data?.website,
  description: data?.description,
});

export const structure = (lang) => [
  {
    head: "Organization  information",
    list: [
      {
        name: `${lang}.name`,
        kind: "input",
        type: "text",
        label: "Organization name",
        width: "100%",
      },
      {
        name: `website`,
        kind: "input",
        type: "text",
        label: "Website",
        width: "100%",
      },
      {
        name: `description`,
        kind: "textArea",
        type: "text",
        placeholder: "Add organization description",
        label: "Description",
        width: "100%",
        rows: 4,
      },
    ],
  },
];

export const validationSchema = () => 
  Yup.object({ 
    en: Yup.object().shape({ 
      name: Yup.string().required("required").min(5, 'name must be at least 5 characters'), 
    }), 
    website: Yup.string().matches(
      /(^((https?|ftp):\/\/www\.)|^((https?|ftp):\/\/)|^(www\.))([-a-zA-Z0-9@:%._\+~#=.]{2,256}\.)\b([-a-zA-Z0-9@:%_\+.~#()?&//=]*)/g,
      'not valid url'
    ).required('Please enter website'),
    description: Yup.string().required("required").min(5),
  });

import { Grid, Typography } from '@mui/material';
import React from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import CustomPagination from '../../../Atoms/Pagination/Pagination';
import './ApplicantsList.css'

function ApplicantsList(props) {
    const { applicantsList, count, page, handlePageChange, jobOfferTitle, roles } = props;
    const navigate = useNavigate();

    const handleDigits = (number) => {
        return (number).toString().length === 1 ? '0'+number : number
    }
    const { jobOfferId } = useParams();

  return (
    <Grid container>
        <Grid container className="table-header applicant-header mb-20px">
            <Grid item xs={1} md={0.75} lg={0.75} textAlign="center">ID</Grid>
            <Grid item xs={2} md={2} lg={2}>Freelancer name</Grid>
            <Grid item xs={2} md={2} lg={1.75}>Apply in</Grid>
            <Grid item xs={2} md={2} lg={1.75}>Freelancer state</Grid>
            <Grid item xs={2} md={2} lg={1.5}>Fare</Grid>
            <Grid item xs={2} md={2} lg={1.5}>Status</Grid>
            {
                !roles?.can_read ? null : <Grid item xs={2} md={2} lg={2}>Action</Grid>
            }            
        </Grid>

        {applicantsList?.length === 0 && (
            <Grid container display="block" textAlign="center">
                There are no applicants
            </Grid>
        )}
  
        {
          applicantsList?.length >= 1 && <>
              {
                applicantsList?.map((item, index) => {
                    const applied = new Date( Date.parse(item?.applied_at) );
                    return (
                        <Grid
                            key={index}
                            container item
                            lg={12}
                            className="applicant-item mb-20px"
                        >                           
                            <Grid item xs={1} md={0.75} lg={0.75} className="applicant-item-no" textAlign="center">{item?.id}</Grid>
                            <Grid container item xs={2} md={2} lg={2} gap={1} alignItems="center">
                                <img src={item?.dive_expert?.avatar} alt='user' />
                                <Typography className="applicant-item-name">
                                    {item?.dive_expert?.name}
                                </Typography>
                            </Grid>
                            <Grid item xs={2} md={2} lg={1.75}>
                            {applied != 'Invalid Date' ? <Typography color='#333132' className='applicant-text'>{`${applied?.toLocaleString('default', { month: 'short' })} ${handleDigits(applied?.getDate()+1)}, ${applied?.getFullYear()}`}</Typography> : null}
                            </Grid>
                            <Grid item xs={2} md={2} lg={1.75} className='applicant-text' sx={{color: `${
                                item?.dive_expert_status === 'Currently Working' ? '#00b906'                                  
                                : item?.dive_expert_status === 'X Freelancer' ? '#e10007'
                                : '#333132'
                                }`}}>{item?.dive_expert_status}</Grid>
                            <Grid item xs={2} md={2} lg={1.5} className='applicant-text'>{`${item?.dive_expert?.rate} ${item?.dive_expert?.currency?.lookup_key}`}</Grid>
                                                                                                                
                            <Grid item xs={2} md={2} lg={1.5} sx={{color: `${
                                (item?.status === 'hired' || item?.status === 'qr_scanned' || item?.status === 'completed' || item?.status === 'accepted') ? '#00b906'
                                : item?.status === 'pending' ? '#f6b119'
                                : item?.status === 'deleted' || item?.status === 'canceled' ? '#e10007'
                                : '#333132'
                                }`, display: 'flex', flexDirection: 'column'}}
                                className='applicant-text'
                            >
                            { item?.status === 'qr_scanned' ? 'Scanned QR' : item?.status }
                            </Grid>

                            {/* send job request status, job offer title to ApplicantDetails component  */}
                            <Grid item xs={2} md={2} lg={2}>
                                <button onClick={() => roles?.can_read ? navigate('/operators/job-offers/' + jobOfferId + '/applicants/'+ item?.dive_expert?.id,{
                                    state:{status: item?.status, title: jobOfferTitle}
                                }) : null}>view details</button>
                            </Grid>
                        </Grid>
                    )
                })
              }
              {count > 10 &&
                  <Grid container item justifyContent={'center'} xs={12} mb={7.5} mt={5}>
                      <CustomPagination
                          count={Math.ceil(count / 10)}
                          size='large'
                          shape="rounded"
                          page={page}
                          handleChange={handlePageChange}
                          className='pagination'
                      />
                  </Grid>
              }
          </>
        }       
    </Grid>
  )
}

export default ApplicantsList
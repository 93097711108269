import React from 'react'
import ActivityDetails from '../../../Components/Organisms/Activities/ActivityDetails/ActivityDetails'

function ActivityDetailsTemplate({ActivityData, supportedLanguages, updateSupportedLang, supportLang}) {
  return (
    <ActivityDetails 
        ActivityData={ActivityData}
        supportedLanguages={supportedLanguages}
        updateSupportedLang={updateSupportedLang}
        supportLang={supportLang}  
    />
  )
}

export default ActivityDetailsTemplate
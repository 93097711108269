import React from 'react'
import DiveClubs from '../../../Components/Organisms/DiveClubs/DiveClubs';

function DiveClubsTemplate({filterObj, setFilterObj, diveClubsList, openLoader, count, page, setPage, handlePageChange, getDiveClubs,setDiveClubsList, roles}) {
    return ( 
        <DiveClubs
            filterObj={filterObj} setFilterObj={setFilterObj}
            diveClubsList={diveClubsList}
            openLoader={openLoader}
            count={count} page={page}
            setPage={setPage}
            handlePageChange={handlePageChange}
            getDiveClubs={getDiveClubs}
            roles={roles}
            setDiveClubsList={setDiveClubsList}
        />
     );
} 

 
export default DiveClubsTemplate;

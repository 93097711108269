import React from 'react'
import { getDiveClubDetails } from '../../../Contexts/APIs/DiveClubs/DiveClubs';
import { useEffect } from 'react';
import { useState } from 'react';
import { toast } from 'react-toastify';
import Loader from '../../../Components/Atoms/Loader/Loader';
import { useParams } from 'react-router-dom';
import DiveClubDetailsTemplate from '../../../Templates/Main/DiveClubs/DiveClubDetails.Template';

function DiveClubDetailsPage({roles}) {
    const { diveClubId } = useParams();
    const [diveClubData, setDiveClubData] = useState({});
    const [openLoader, setOpenLoader] = useState(false);

    const getDiveClub = async (id) => {
        setOpenLoader(true);
        const { res, err } = await getDiveClubDetails(id);
        if (res) { 
            setDiveClubData(res?.data?.user); 
        }
        if (err) {
            toast.error(err)
            setDiveClubData({
                "user_type": "dive_club",
                "id": 433,
                "firstname": "Divewise Malta",
                "lastname": null,
                "avatar": null,
                "email": "info@divewise.com.mt",
                "available_points": 0.0,
                "status": "active",
                "gender": "unspecified",
                "country_code": null,
                "phone_number": null,
                "reset_password_token": null,
                "verification_code": "604726",
                "provider_account_id": null,
                "provider": "email",
                "source": "app",
                "enable_push": true,
                "invitation_code": "KGPulUyUnHm6zDCVKSGS",
                "invitation_link": null,
                "sign_in_count": 1,
                "current_sign_in_at": "2023-03-20T09:52:50.605+02:00",
                "current_sign_in_ip": "77.71.196.232",
                "last_sign_in_at": "2023-03-20T09:52:50.605+02:00",
                "last_sign_in_ip": "77.71.196.232",
                "is_verified": false,
                "created_at": "2023-03-20T09:52:50.707+02:00",
                "updated_at": "2023-03-20T09:54:13.219+02:00",
                "country_id": null,
                "city_id": null,
                "lat": null,
                "long": null,
                "birthdate": null,
                "country": null,
                "currency": {
                    "id": 2,
                    "name": "Dollar",
                    "lookup_key": "USD",
                    "usd_value": 1.0,
                    "country": null
                },
                "city": null,
                "area": null,
                "certificates": [],
                "languages": [],
                "level": {
                    "id": 1,
                    "name": "Bronze",
                    "icon": "",
                    "rank": 1,
                    "flat_points_earned_inc": 0.0,
                    "reservations_required": 0
                },
                "level_advancement_tracker": {
                    "id": 335,
                    "no_of_reservations": 0,
                    "reservations_left_till_next_level": 2,
                    "progress_till_next_level": {
                        "percentage": 0.0,
                        "ratio": 0.0
                    },
                    "no_of_tasks_achieved": 0,
                    "total_no_of_tasks": 1,
                    "next_level": {
                        "id": 2,
                        "name": "Silver",
                        "icon": "",
                        "rank": 2,
                        "flat_points_earned_inc": 5.0,
                        "reservations_required": 2
                    }
                },
                "name": "Divewise Malta",
                "website": null,
                "contact_person_name": null,
                "about_us": null,
                "primary_language": null,
                "social_media": []
            });
        }
        setOpenLoader(false);
    };

    useEffect(() => {
        getDiveClub(diveClubId);        
    }, []);

  return (
    <>
        <Loader open={openLoader} />
        <DiveClubDetailsTemplate
            diveClubData={diveClubData}
            roles={roles}
        />
    </>
  )
}

export default DiveClubDetailsPage
import React from "react";
import CCrud from "../../../../Components/Molecules/General/Crud/CCrud";
import "../../../../Components/Atoms/Shared/Styles/list-page.css";
import "./Language.css";
import { structure, validationSchema } from "./structure";

const LanguageTemplate = ({
  data,
  rowData,
  count,
  paginationPage,
  setPaginationPage,
  handlePageChange,
  filterObj,
  setFilterObj,
}) => {
  const searchInputPlaceholder = "Search Language";

  return (
    <CCrud
      table={{
        list: data,
        rowData: rowData,
        filterObj: filterObj,
        setFilterObj: setFilterObj,
        listTitle: "Languages",
        emptyListMsg: "There are no Languages",
        details: {
          navigatePath: "/predefined-lookups/languages",
          statusField: null,
          hideStatus: null,
        },
        columns: [
          { accessor: "id", Header: "Id" },
          { accessor: "name", Header: "Name" },
        ],
        isFilter: false,
        isSearch: true,
        isChecked: false,
        isSort: false,
        total_count: count,
        className: "organization-table language-table",
        paginationPage: paginationPage,
        setPaginationPage: setPaginationPage,
        searchInputPlaceholder: searchInputPlaceholder,
        handlePageChange,
      }}
      form={{
        structure: () => structure(),
        validationSchema: validationSchema,
        initialValues: {},
        onSubmit: () => {},
        className: { form: "flow-form", actionButton: "flow-form-buttons" },
        handleLanguage: <></>,
      }}
      DetailsComponent={<></>}
    />
  );
};

export default LanguageTemplate;

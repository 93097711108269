import { axiosFn, handleError } from "..";
import { makeFilterString } from "../Helper/Helper";
import * as URL from "../URLs";

export async function getDiveClubsList(filter_obj) {
  return await axiosFn(
    "get",
    URL.users + "dive_club" + makeFilterString(filter_obj)
  )
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}

export async function getDiveClubDetails(dive_club_id) {
  return await axiosFn("get", `profile/dive_club/${dive_club_id}/show`)
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}

export async function updateDiveClub(data) {
  return await axiosFn("put", `/profile/update`, data)
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}

export async function deleteDiveClub(id) {
  return await axiosFn("delete", `/users/dive_club/delete/${id}`)
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}

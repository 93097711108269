export const facility_type = [
  { id: "any", name: "Any" },
  { id: "boat", name: "Boat" },
  { id: "office", name: "Office" },
];

export const is_hidden = [
  { id: 0, name: "All" },

  { id: true, name: "Yes" },
  { id: false, name: "No" },
];

import React from "react";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import { icons } from "../../../Assets/AssetHelper";

function OrganizationDetailsHeader({
  data,
  supportLang,
  handleEdit,
  handleDelete,
  isTitle = true,
  roles
}) {
  return (
    <Stack
      alignItems="center"
      bgcolor="white"
      borderRadius="10px 10px 0px 0 "
      padding={"47px 54px"}
      borderBottom="1px solid #d9d9d9"
    >
      <Stack
        flexDirection="row"
        justifyContent="space-between"
        width="100%"
        className="details-header-content"
        alignItems="center"
      >
        <Stack flexDirection="row" alignItems="center" gap={1}>
          {data?.icon && (
            <img
              src={data?.icon}
              width="82px"
              height="82px"
              style={{
                borderRadius: "50%",
                boxShadow: "0 2px 15px 0 rgba(0, 0, 0, 0.1)",
              }}
            />
          )}
          <Box>
            <Typography
              fontSize="30px"
              fontWeight="bold"
              color="#333132"
              textTransform="capitalize"
            >
              {data?.localized_data
                ? data?.localized_data[`${supportLang}`]["name"]
                : data?.name}{" "}
              {isTitle && "details"}
            </Typography>
            <Stack gap={4} flexDirection="row">
              <Typography fontSize="20px" fontWeight="bold">
                ID number {data?.id}
              </Typography>
              <Typography
                fontSize="20px"
                fontWeight="bold"
                textTransform="capitalize"
              >
                For : {data?.for}
              </Typography>
            </Stack>
          </Box>
        </Stack>
          <Stack
            flexDirection="row"
            gap={"20px"}
            justifyContent="right"
            alignItems="center"
            flexWrap="wrap"
          >
          {
            ((roles && roles?.can_read && roles?.can_update) || !roles) &&
            <IconButton onClick={handleEdit}>
              <img width="35" src={icons.EditIcon} alt="edit" />
            </IconButton>
          }          
          { 
            ((roles && roles?.can_read && roles?.can_delete && handleDelete) || !roles && handleDelete) &&
            <IconButton onClick={handleDelete}>
              <img width="35" src={icons.DeleteIcon} alt="delete" />
            </IconButton>
          }
          </Stack>          
      </Stack>
    </Stack>
  );
}

export default OrganizationDetailsHeader;

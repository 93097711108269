import { axiosFn, handleError } from "..";
import { makeFilterString } from "../Helper/Helper";
import * as URL from "../URLs";

export async function getCitiessList(filter_obj) {
  return await axiosFn("get", URL.Cities + makeFilterString(filter_obj))
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}

export async function createCity({ data, action }) {
  return await axiosFn("post", URL.Cities, data)
    .then((res) => {
      action && action();
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}

export async function getCitiesDetails(organization_id) {
  return await axiosFn("get", `${URL.Cities}/${organization_id}`)
    .then((res) => {
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}

export async function deleteCities(id, action) {
  return await axiosFn("delete", `${URL.Cities}/${id}`)
    .then((res) => {
      action && action(res?.data);
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}

export async function updateCities({ id, data, action }) {
  return await axiosFn("put", `${URL.Cities}/${id}`, data)
    .then((res) => {
      action && action();
      return {
        res: res.data,
        err: null,
      };
    })
    .catch(handleError);
}

import * as Yup from "yup";
export const initialValues = (data) => {
  console.log(data, "initialValues");
  return {
    ...data,
    ...data?.localized_data,
  };
};
export const structure = (lang) => [
  {
    head: "User information",
    list: [
      {
        name: `${lang}.name`,
        kind: "input",
        type: "text",
        label: "Level name",
        width: "50%",
      },
      {
        name: `can_create_team`,
        kind: "checkbox",
        type: "text",
        label: "Can create team",
        width: "40%",
      },
      {
        name: `rank`,
        kind: "input",
        type: "number",
        label: "Rank",
        width: "45%",
      },
      {
        name: `min_badges_required`,
        kind: "input",
        type: "number",
        label: "Min badges",
        width: "45%",
      },

      {
        name: `days_worked_required`,
        kind: "input",
        type: "number",
        label: "Days worked",
        width: "45%",
      },
      {
        name: `max_no_of_team_members`,
        kind: "input",
        type: "number",
        label: "max number of team",
        width: "45%",
      },
      {
        name: `cert_customer_invitations_required`,
        kind: "input",
        type: "number",
        label: "Cert customer inviation",
        width: "45%",
      },

      {
        name: `flat_commission_reduction_amount`,
        kind: "input",
        type: "number",
        label: "Flat Commission reduction amount",
        width: "45%",
      },
      {
        name: `min_rating_required`,
        kind: "input",
        type: "number",
        label: "Min rating",
        width: "45%",
      },
    ],
  },
];

export const validationSchema = () =>
  Yup.object({
    en: Yup.object().shape({
      name: Yup.string().required("required"),
    }),
    days_worked_required: Yup.number().required("required"),
    min_rating_required: Yup.number().required("required"),
    min_badges_required: Yup.number().required("required"),
    can_create_team: false,
    max_no_of_team_members: Yup.number().required("required"),
    flat_commission_reduction_amount: Yup.number().required("required"),
  });

import Operators from "../../../Components/Organisms/Operators/Operators";

const OperatorsTemplate = (props) => {
  const {
    setOperatorsList,
    filterObj,
    setFilterObj,
    operatorsList,
    openLoader,
    count,
    page,
    setPage,
    handlePageChange,
    getOperators,
    roles,
    salesman_roles
  } = props;
  return (
    <Operators
      filterObj={filterObj}
      setFilterObj={setFilterObj}
      operatorsList={operatorsList}
      openLoader={openLoader}
      count={count}
      page={page}
      setPage={setPage}
      handleChange={handlePageChange}
      getOperators={getOperators}
      roles={roles}
      salesman_roles={salesman_roles}
      setOperatorsList={setOperatorsList}
    />
  );
};

export default OperatorsTemplate;

import React, { useState, useEffect } from "react";
import { Button, Divider, Grid, Menu, Typography } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import PrimaryButton from "../../../Atoms/Buttons/Primary/PrimaryButton";
import TextFieldInput from "../../../Atoms/Input/TextField/TextFieldInput";
import SearchableSelect from "../../../Atoms/Select/SearchableSelect.jsx";
import FilterMenu from "../../General/FilterMenu/FilterMenu";
import { getOperatorsList } from "../../../../Contexts/APIs/Operators/Operators";
import { getBranchesList } from "../../../../Contexts/APIs/Branches/Branches";
import "./JobOffersHeader.css";
import { toast } from "react-toastify";
import { useSearchParams } from "react-router-dom";
import { handlePageChange } from "../../../../Contexts/APIs/Helper/Helper.js";

function JobOffersHeader({
  title,
  searchInput,
  filterObj,
  setFilterObj,
  setPage,
  handleChange,
}) {
  const activityTypes = [
    { id: 1, name: "dive trip" },
    { id: 2, name: "dive course" },
    { id: 3, name: "liveaboard" },
  ];

  const jobTypes = [
    { id: 1, name: "full day" },
    { id: 2, name: "half day" },
  ];

  const diveExpertLevels = [
    { id: 1, name: "dive guide" },
    { id: 2, name: "instructor" },
    { id: 3, name: "speciality instructor" },
  ];

  const accountStatusList = [
    { id: 1, name: "pending" },
    { id: 2, name: "hired" },
    { id: 3, name: "Scanned QR" },
    { id: 4, name: "completed" },
    { id: 5, name: "canceled" },
    { id: 6, name: "deleted" },
  ];

  const [searchKeywords, setSearchKeywords] = useState("");
  const [diveCenters, setDiveCenters] = useState([]);
  const [branches, setBranches] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const [accountStatus, setAccountStatus] = useState({});

  const [activityType, setActivityType] = useState({});
  const [jobType, setJobType] = useState({});
  const [diveExpertLevel, setDiveExpertLevel] = useState({});
  const [searchParams] = useSearchParams();
  const pageNumber = searchParams.get("page_number") || 1;
  const [diveCenter, setDiveCenter] = useState({});
  const [diveCentersCount, setDiveCentersCount] = useState(0);
  const [branch, setBranch] = useState({});
  const [branchesCount, setBranchesCount] = useState(0);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    const timeId = setTimeout(() => {
      setFilterObj &&
        searchKeywords?.type &&
        setFilterObj((data) => {
          return {
            ...data,
            page_number: searchKeywords?.keyword?.length == 0 ? pageNumber : 1,
            keyword: searchKeywords?.keyword,
          };
        });
    }, 500);
    setPage(1);
    return () => clearTimeout(timeId);
  }, [searchKeywords]);

  const getDiveCenters = async (filter) => {
    const prevDiveCenters = diveCenters;
    // condition to setDiveCenters with same prev value because here not call listing api (getOperatorsList)
    if (
      (filter?.page_number === 1 || filter?.page_number === undefined) &&
      !filter?.keyword &&
      diveCenters.length === 10
    ) {
      setDiveCenters(prevDiveCenters);
    } else {
      const { res, err } =
        diveCentersCount !== 10 || filter?.keyword
          ? await getOperatorsList({ page_size: 10, page_number: 1, ...filter })
          : {};
      if (res) {
        filter?.page_number === 1 || filter?.page_number === undefined
          ? setDiveCenters(res?.data?.dive_centers)
          : setDiveCenters((prev) => [...prev, ...res?.data?.dive_centers]);
        setDiveCentersCount(res?.extra?.total_count);
      }
    }
  };

  const getBranches = async (filter) => {
    const prevBranches = branches;
    if (
      (filter?.page_number === 1 || filter?.page_number === undefined) &&
      !filter?.keyword &&
      !filter?.dive_center_id
    ) {
      setBranches(prevBranches);
    } else {
      const { res, err } =
        branchesCount !== 10 || filter?.keyword
          ? await getBranchesList({
              page_size: 10,
              page_number: 1,
              dive_center_id: filterObj?.dive_center_id,
              ...filter,
            })
          : {};
      if (res) {
        filter?.page_number === 1 || filter?.page_number === undefined
          ? setBranches(res?.data?.branches)
          : setBranches((prev) => [...prev, ...res?.data?.branches]);
        setBranchesCount(res?.extra?.total_count);
      }
    }
  };

  const handleDiveCenterFilter = (value) => {
    setFilterObj({
      ...filterObj,
      page_number: 1,
      dive_center_id: value,
      branch_id: null,
    });
    setPage(1);
  };
  const handleBranchFilter = (value) => {
    setFilterObj({ ...filterObj, page_number: 1, branch_id: value });
    setPage(1);
  };
  const filterMenus = [
    {
      head: "All dive centers",
      dataList: [{ id: "", name: "All" }, ...diveCenters],
      value: diveCenter,
      getList: getDiveCenters,
      count: diveCentersCount,
      handleFilter: handleDiveCenterFilter,
      handleClick: () => diveCenters.length === 0 && getDiveCenters(),
      handleChange: (val) => {
        setDiveCenter(val);
        setBranch(null);
        setBranches([]);
      },
      handleClear: () => {
        setDiveCenter(null);
        setBranch(null);
        setBranches([]);
      },
    },
    {
      head: "All branches",
      dataList: [{ id: "", name: "All" }, ...branches],
      value: branch,
      getList: getBranches,
      count: branchesCount,
      handleFilter: handleBranchFilter,
      handleClick: () => {
        branches.length === 0 &&
          diveCenter?.value &&
          getBranches({ page_number: 1, dive_center_id: diveCenter.value });
      },
      handleChange: (val) => {
        setBranch(val);
      },
      handleClear: () => {
        setBranch(null);
      },
      moreFilterData: { dive_center_id: diveCenter?.value },
    },
  ];

  const handleApplyFilterMenu = () => {
    if (from < 0 || to < 0) {
      toast.error("Rate average can't be negative number");
    } else {
      handleChange && handlePageChange(1, handleChange);
      setFilterObj({
        ...filterObj,
        page_number: 1,
        activity_type: activityType?.label?.toLowerCase(),
        job_type: jobType?.label?.toLowerCase(),
        dive_expert_professional_level: diveExpertLevel?.label?.toLowerCase(),
        status: accountStatus?.label?.toLowerCase(),
        "price[from]": from,
        "price[to]": to,
      });
      handleClose();
      setPage(1);
    }
  };

  const handleResetFilterMenu = () => {
    handleChange && handlePageChange(1, handleChange);
    setAccountStatus({});
    setJobType({});
    setDiveExpertLevel({});
    setActivityType({});
    setFrom("");
    setTo("");
    setFilterObj({ page_number: 1 });
    handleClose();
    setPage(1);
  };

  // useEffect(() => {
  //   if (filterObj?.dive_center_id) {
  //     getBranches();
  //   } else {
  //     setBranches([]);
  //   }
  // }, [filterObj?.dive_center_id]);

  return (
    <Grid
      container
      item
      lg={12}
      style={{ borderBottom: "1px solid #d9d9d9" }}
      sx={{ pb: 1, mb: 2 }}
      gap={"16px 0px"}
      justifyContent={"space-between"}
      className="job-offer-head"
    >
      <Grid
        container
        item
        xs={12}
        sm={"auto"}
        lg={"auto"}
        flexDirection="row"
        justifyContent="start"
        gap={"1rem 2rem"}
        marginRight={1}
        alignItems="center"
      >
        <Grid item lg={"auto"}>
          <Typography
            component="h1"
            style={{
              fontSize: "30px",
              fontWeight: "bold",
              textAlign: "left",
              color: "#333132",
            }}
          >
            {title}
          </Typography>
        </Grid>

        <Grid item lg={4}>
          {searchInput && (
            <TextFieldInput
              type={searchInput.type}
              onChange={(e) => {
                handleChange && handlePageChange(1, handleChange);
                setSearchKeywords({ keyword: e.target.value, type: e?.type });
              }}
              placeholder={searchInput.placeholder}
              StartAdornment={<SearchIcon />}
              className={searchInput.className}
              value={searchKeywords?.keyword}
            />
          )}
        </Grid>
        {filterMenus &&
          filterMenus.map((filterMenu, index) => {
            return (
              <Grid item lg={"auto"} className="filter-menu-wrap" key={index}>
                <SearchableSelect
                  options={filterMenu.dataList}
                  label={filterMenu.head}
                  className="searchable-select filter-menu-button"
                  onChange={(val) => {
                    handleChange && handlePageChange(1, handleChange);
                    filterMenu.handleFilter(val.value);
                    filterMenu.handleChange(val);
                  }}
                  value={filterMenu.value}
                  getList={filterMenu.getList}
                  count={filterMenu.count}
                  onClear={() => {
                    filterMenu.handleFilter(null);
                    filterMenu.handleClear();
                  }}
                  onMenuOpen={
                    filterMenu?.handleClick && filterMenu?.handleClick
                  }
                  moreFilterData={filterMenu?.moreFilterData}
                />
              </Grid>
            );
          })}
      </Grid>

      <Grid
        container
        item
        xs={12}
        sm={"auto"}
        lg={"auto"}
        alignItems={"center"}
      >
        <Grid item lg={"auto"} display={"flex"}>
          <Button
            aria-label="filter"
            id="filter-button"
            aria-controls={Boolean(anchorEl) ? "filter-menu" : undefined}
            aria-expanded={Boolean(anchorEl) ? "true" : undefined}
            aria-haspopup="true"
            variant="outlined"
            startIcon={<FilterAltIcon sx={{ color: "#bebebe" }} />}
            sx={{
              width: "100%",
              height: "auto",
              color: "#333132",
              borderRadius: "10px",
              border: "solid 1px #ececec",
              fontSize: "16px",
              fontWeight: 500,
              textTransform: "capitalize",
              lineHeight: "unset",
              margin: "auto",
              padding: "7px 15px 8px",
            }}
            onClick={handleClick}
          >
            Filter
          </Button>
          <Menu
            id="filter-menu"
            MenuListProps={{
              "aria-labelledby": "filter-button",
            }}
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            PaperProps={{
              style: {
                width: "330px",
              },
            }}
          >
            <Typography
              sx={{
                paddingInlineStart: "16px",
                fontSize: "16px",
                fontWeight: 500,
                mt: 1,
              }}
            >
              Filter by
            </Typography>
            <Divider sx={{ width: "100%", margin: "12px 0" }} />
            <Grid container sx={{ padding: "0 16px" }}>
              <Grid item xs={12}>
                <Typography sx={{ fontSize: "16px", fontWeight: 500 }}>
                  Activity type
                </Typography>
              </Grid>
              <Grid item xs={12} mb={2}>
                <SearchableSelect
                  name="activity_type_id"
                  options={activityTypes}
                  label="Select option"
                  className="searchable-select"
                  onChange={(val) =>
                    setActivityType({
                      ...val,
                      label:
                        val.label === "liveaboard"
                          ? "live_aboard"
                          : val.label?.replaceAll(" ", "_"),
                    })
                  }
                  value={
                    activityType
                      ? {
                          ...activityType,
                          label:
                            activityType.label === "live_aboard"
                              ? "liveaboard"
                              : activityType.label?.replaceAll("_", " "),
                        }
                      : null
                  }
                  onClear={() => setActivityType(null)}
                />
              </Grid>

              <Grid item xs={12}>
                <Typography sx={{ fontSize: "16px", fontWeight: 500 }}>
                  Job type
                </Typography>
              </Grid>
              <Grid item xs={12} mb={2}>
                <SearchableSelect
                  name="job_type_id"
                  options={jobTypes}
                  label="Select option"
                  className="searchable-select"
                  onChange={(val) =>
                    setJobType({
                      ...val,
                      label: val.label?.replaceAll(" ", "_"),
                    })
                  }
                  value={
                    jobType
                      ? {
                          ...jobType,
                          label: jobType.label?.replaceAll("_", " "),
                        }
                      : null
                  }
                  onClear={() => setJobType(null)}
                />
              </Grid>

              <Grid item xs={12}>
                <Typography sx={{ fontSize: "16px", fontWeight: 500 }}>
                  Dive expert level
                </Typography>
              </Grid>
              <Grid item xs={12} mb={2}>
                <SearchableSelect
                  name="dive_expert_level_id"
                  options={diveExpertLevels}
                  label="Select option"
                  className="searchable-select"
                  onChange={(val) =>
                    setDiveExpertLevel({
                      ...val,
                      label: val.label?.replaceAll(" ", "_"),
                    })
                  }
                  value={
                    diveExpertLevel
                      ? {
                          ...diveExpertLevel,
                          label: diveExpertLevel.label?.replaceAll("_", " "),
                        }
                      : null
                  }
                  onClear={() => setDiveExpertLevel(null)}
                />
              </Grid>

              <Grid item xs={12}>
                <Typography sx={{ fontSize: "16px", fontWeight: 500 }}>
                  Status
                </Typography>
              </Grid>
              <Grid item xs={12} mb={2} className="status-select-wrap">
                <SearchableSelect
                  name="status_id"
                  options={accountStatusList}
                  label="Select option"
                  className="searchable-select"
                  onChange={(val) =>
                    setAccountStatus({
                      ...val,
                      label:
                        val.label === "Scanned QR"
                          ? "qr_scanned"
                          : val.label?.replaceAll(" ", "_"),
                    })
                  }
                  value={
                    accountStatus
                      ? {
                          ...accountStatus,
                          label: accountStatus.label?.replaceAll("_", " "),
                        }
                      : null
                  }
                  onClear={() => setAccountStatus(null)}
                />
              </Grid>

              <Grid item xs={12}>
                <Typography sx={{ fontSize: "16px", fontWeight: 500 }}>
                  Rate average (in USD)
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <TextFieldInput
                  placeholder="From"
                  className="reservation-search"
                  onChange={(e) => setFrom(e.target.value)}
                  style={{ paddingInlineEnd: "4px" }}
                  value={from}
                  type="number"
                />
              </Grid>
              <Grid item xs={6}>
                <TextFieldInput
                  placeholder="To"
                  className="reservation-search"
                  onChange={(e) => setTo(e.target.value)}
                  style={{ paddingInlineStart: "4px" }}
                  value={to}
                  type="number"
                />
              </Grid>

              <Grid
                item
                xs={6}
                mt={2}
                mb={2.25}
                sx={{ paddingInlineEnd: "10px" }}
              >
                <PrimaryButton
                  text="Reset"
                  classBtn="reset-btn"
                  click={handleResetFilterMenu}
                />
              </Grid>
              <Grid
                item
                xs={6}
                mt={2}
                mb={2.25}
                sx={{ paddingInlineStart: "10px" }}
              >
                <PrimaryButton
                  text="Apply"
                  classBtn="apply-btn"
                  click={handleApplyFilterMenu}
                />
              </Grid>
            </Grid>
          </Menu>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default JobOffersHeader;

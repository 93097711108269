import { FormControl, Stack, Typography } from "@mui/material";
import React from "react";
import SearchableSelect from "../../../Atoms/Select/SearchableSelect";

const Filterlist = ({
  data,
  setFiltersSelectedOptions,
  filtersSelectedOptions,
  setFilterData,
  onMenuOpen,
  ...rest
}) => {
  const find = filtersSelectedOptions
    ? data?.list?.find((val) => val?.id == filtersSelectedOptions[data?.key])
    : {};

  return (
    <Stack px={2} py={1} gap={1} key={data?.key}>
      <Typography className="filter-menu-item-head">{data?.title}</Typography>
      <FormControl fullWidth>
        <SearchableSelect
          name={data?.key}
          options={data?.list}
          getList={data?.getList && data?.getList}
          count={data?.count}
          label="Select option"
          className="searchable-select"
          value={
            filtersSelectedOptions ? { label: find?.name, value: find?.id } : null
          }
          onMenuOpen={() => onMenuOpen && onMenuOpen()}
          onChange={(val) => {
            setFiltersSelectedOptions((prev) => {
              return { ...prev, [data?.key]: val?.value };
            });
            setFilterData && setFilterData({
              ...filtersSelectedOptions,
              [data?.key]: val?.value,
            });          
            data?.onChange && data?.onChange(val?.value);
          }}
          onClear={() => {
            setFiltersSelectedOptions((prev) => {return { ...prev, [data?.key]: null, ...data.relatedResetFilterData}});
            setFilterData && setFilterData({...filtersSelectedOptions, [data?.key]: null,  ...data.relatedResetFilterData});
            data?.onClear && data?.onClear();
          }}
          moreFilterData={data?.moreFilterData}
          {...rest}
        />
      </FormControl>
    </Stack>
  );
};

export default Filterlist;

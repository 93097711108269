import React, { useEffect, useState } from "react";
import CustomersTemplate from "../../../Templates/Main/Customers/Customers.Template";
import {
  getCustomersList,
  updateCustomer,
} from "../../../Contexts/APIs/Customer/Customer";
import Loader from "../../../Components/Atoms/Loader/Loader";
import PhoneInput from "react-phone-input-2";
import usePageFilter from "../../../Components/Atoms/Hooks/usePageFilter";

const CustomersPage = ({ roles }) => {
  const [openLoader, setOpenLoader] = useState(false);
  const [customersList, setCustomersList] = useState([]);
  const [selectedRow, setSelectedRow] = useState({});
  const [count, setCount] = useState(0);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const { page, filterObj, handlePageChange ,setFilterObj,setPage} = usePageFilter();

  const getCustomers = async (filterObj) => {
    const defaultFilter = {
      page_size: 10,
      page_number: 1,
      keyword: "",
      sort_by: "id",
    };
    setOpenLoader(true);
    const { res, error } = await getCustomersList({
      ...defaultFilter,
      ...filterObj,
    });
    if (res) {
      setOpenLoader(false);
      setCustomersList(res?.data?.users);
      setCount(res?.extra?.total_count);
    }
    if (error) {
      setOpenLoader(false);
    }
  };

  const handelDeleteRequest = async (id) => {
    const { res, err } = await updateCustomer({
      customer_id: id || selectedRow?.id,
      user: {
        status: "deleted",
      },
    });
    if (res) {
      setOpenDeleteModal(false);
      setCustomersList((prevState) =>
        prevState?.filter((ele) => ele?.id !== res?.data?.user?.id)
      );
    }
  };

  const rowData = (data) => {
    setSelectedRow(data?.rowData);
  };

  useEffect(() => {
    if (filterObj) getCustomers(filterObj);
  }, [filterObj]);

  // mappedData use for create custom cell to concat multi values or navigate button
  const mappedData = customersList?.map((data) => {
    let countryCode = data.country_code;
    return {
      ...data,
      name: `${data.firstname} ${data.lastname}`,
      phone_number: data?.phone_number && (
        <PhoneInput
          inputStyle={{
            border: "none",
            width: "100%",
            padding: "0px",
            direction: "ltr",
            fontSize: "18px",
            color: "#333132",
            backgroundColor: "white",
            wordBreak: "break-word",
          }}
          defaultMask={`${countryCode === "" ? "..........." : "... ... ...."}`}
          value={`${countryCode === "" ? "  " : data.country_code}${
            data.phone_number
          }`}
          disabled={true}
          disableDropdown={true}
          specialLabel=""
          placeholder=""
          disableCountryCode={countryCode === "" ? true : false}
        />
      ),
      is_verified: (
        <div
          className={`${
            data.is_verified ? "customer-verified" : "customer-not-verified"
          }`}
        >
          {data.is_verified ? "Yes" : "No"}
        </div>
      ),
    };
  });
  return (
    <>
      <Loader open={openLoader} />
      <CustomersTemplate
        filterObj={filterObj}
        setFilterObj={setFilterObj}
        data={mappedData}
        count={count}
        paginationPage={page}
        setPaginationPage={setPage}
        handlePageChange={handlePageChange}
        getCustomers={getCustomers}
        rowData={rowData}
        roles={roles}
        openDeleteModal={openDeleteModal}
        setOpenDeleteModal={setOpenDeleteModal}
        handelDeleteRequest={handelDeleteRequest}
        setCustomersList={setCustomersList}
      />
    </>
  );
};

export default CustomersPage;

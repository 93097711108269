import React, { useEffect, useRef } from "react";
import FormIndex from "../Forms/main";
import { Box, Button, Grid, Typography, Breadcrumbs } from "@mui/material";
import FormHeader from "../Forms/main/formHeader";
import { Link } from "react-router-dom";
import CustomSeparator from "../../BreadCrumb";
const FormUse = ({
  formStructure = () => {},
  handleLanguage,
  className,
  formik,
  handleModelClose,
  form,
  breadcrumbs,
}) => {
  const refsubmit = useRef();
  return (
    <Box>
      {breadcrumbs && <CustomSeparator breadcrumbs={breadcrumbs} />}
      <FormHeader
        formikData={formik}
        form={form}
        handleModelClose={handleModelClose}
        refsubmit={refsubmit}
      />
      {handleLanguage}
      <FormIndex
        formStructures={formStructure()}
        formik={formik}
        className={className}
        refsubmit={refsubmit}
      >
        <Button
          // sx={{ visibility: "none" }}
          ref={refsubmit}
          type="submit"
        ></Button>
      </FormIndex>
    </Box>
  );
};

export default FormUse;

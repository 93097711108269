import {
  Breadcrumbs,
  Button,
  Divider,
  Grid,
  Link,
  Menu,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import TextFieldInput from "../../../Atoms/Input/TextField/TextFieldInput";
import SearchIcon from "@mui/icons-material/Search";
import NormalTabs from "../../../Atoms/Tabs/NormalTabs";
import "./BranchHead.css";
import { CountryAPI } from "../../../../Contexts/APIs/Lookups/Country";
import { CityAPI } from "../../../../Contexts/APIs/Lookups/City";
import { AreaAPI } from "../../../../Contexts/APIs/Lookups/Area";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import SearchableSelect from "../../../Atoms/Select/SearchableSelect.jsx";
import PrimaryButton from "../../../Atoms/Buttons/Primary/PrimaryButton";
import { getOrganizationsList } from "../../../../Contexts/APIs/Branches/Branches";
import { getOperatorsList } from "../../../../Contexts/APIs/Operators/Operators";
import { useSearchParams } from "react-router-dom";
import { handlePageChange } from "../../../../Contexts/APIs/Helper/Helper.js";

const BranchHead = ({
  title,
  searchInput,
  filterObj,
  setFilterObj,
  options,
  BranchValue,
  handleBranchValue,
  getBranchType,
  getBranches,
  setPage,
  handleChange,
}) => {
  const [searchKeywords, setSearchKeywords] = useState("");
  const [diveCenters, setDiveCenters] = useState([]);
  const [diveCenter, setDiveCenter] = useState({});
  const [countries, setCounries] = useState([]);
  const [country, setCountry] = useState();
  const [cities, setCities] = useState([]);
  const [city, setCity] = useState();
  const [areas, setAreas] = useState([]);
  const [area, setArea] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [organizations, setOrganizations] = useState([]);
  const [organization, setOrganization] = useState({});
  const [accountStatus, setAccountStatus] = useState();
  const [approvalStatus, setApprovalStatus] = useState();
  const [searchParams] = useSearchParams();
  const pageNumber = searchParams.get("page_number") || 1;

  const [diveCentersCount, setDiveCentersCount] = useState(0);
  const [countriesCount, setCounriesCount] = useState(0);
  const [citiesCount, setCitiesCount] = useState(0);
  const [areasCount, setAreasCount] = useState(0);
  const [organizationsCount, setOrganizationsCount] = useState(0);

  const accountStatusList = [
    { id: 1, name: "Active" },
    { id: 2, name: "Suspended" },
    { id: 3, name: "Deactivated" },
  ];
  const approvalStatusList = [
    { id: 1, name: "Approved", color: "#00b906" },
    { id: 2, name: "Pending", color: "#f6b119" },
    { id: 3, name: "Suspended", color: "#f6b119" },
    { id: 4, name: "Deactivated", color: "#f6b119" },
    { id: 5, name: "Rejected", color: "#e10007" },
  ];

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getDiveCenters = async (filter) => {
    const prevDiveCenters = diveCenters;
    const DataKey = filter?.keyword;
    if (
      (filter?.page_number === 1 || filter?.page_number === undefined) &&
      !DataKey &&
      diveCenters.length === 10
    ) {
      setDiveCenters(prevDiveCenters);
    } else {
      const { res, error } =
        diveCentersCount !== 10 || DataKey
          ? await getOperatorsList({ page_size: 10, ...filter })
          : {};
      if (res) {
        filter?.page_number === 1 || filter?.page_number === undefined
          ? setDiveCenters(res?.data?.dive_centers)
          : setDiveCenters((prev) => [...prev, ...res?.data?.dive_centers]);
        setDiveCentersCount(res?.extra?.total_count);
      }
    }
  };

  const getCountries = async (filter) => {
    const prevCountries = countries;
    const DataKey = filter?.keyword;
    if (
      (filter?.page_number === 1 || filter?.page_number === undefined) &&
      !DataKey &&
      countries.length === 10
    ) {
      setCounries(prevCountries);
    } else {
      const { res, error } =
        countriesCount !== 10 || DataKey
          ? await CountryAPI({ page_size: 10, ...filter })
          : {};
      if (res) {
        filter?.page_number === 1 || filter?.page_number === undefined
          ? setCounries(res?.data?.countries)
          : setCounries((prev) => [...prev, ...res?.data?.countries]);
        setCounriesCount(res?.extra?.total_count);
      }
    }
  };

  const getCities = async (filter) => {
    const prevCities = cities;
    const DataKey = filter?.keyword;
    if (
      (filter?.page_number === 1 || filter?.page_number === undefined) &&
      !DataKey &&
      !filter?.country_id
    ) {
      setCities(prevCities);
    } else {
      const { res, error } =
        citiesCount !== 10 || DataKey
          ? await CityAPI({
              page_size: 10,
              country_id: filter?.country_id,
              ...filter,
            })
          : {};
      if (res) {
        filter?.page_number === 1 || filter?.page_number === undefined
          ? setCities(res?.data?.cities)
          : setCities((prev) => [...prev, ...res?.data?.cities]);
        setCitiesCount(res?.extra?.total_count);
      }
    }
  };

  const getAreas = async (filter) => {
    const prevAreas = areas;
    const DataKey = filter?.keyword;
    if (
      (filter?.page_number === 1 || filter?.page_number === undefined) &&
      !DataKey &&
      !filter?.city_id
    ) {
      setAreas(prevAreas);
    } else {
      const { res, error } =
        areasCount !== 10 || DataKey
          ? await AreaAPI({
              page_size: 10,
              city_id: filter?.city_id,
              ...filter,
            })
          : {};
      if (res) {
        filter?.page_number === 1 || filter?.page_number === undefined
          ? setAreas(res?.data?.areas)
          : setAreas((prev) => [...prev, ...res?.data?.areas]);
        setAreasCount(res?.extra?.total_count);
      }
    }
  };

  const getOrganizations = async (filter) => {
    const prevCountries = countries;
    const DataKey = filter?.keyword;
    if (
      (filter?.page_number === 1 || filter?.page_number === undefined) &&
      !DataKey &&
      organizations.length === 10
    ) {
      setOrganizations(prevCountries);
    } else {
      const { res, error } =
        organizationsCount !== 10 || DataKey
          ? await getOrganizationsList({ page_size: 10, ...filter })
          : {};
      if (res) {
        filter?.page_number === 1 || filter?.page_number === undefined
          ? setOrganizations(res?.data?.organizations)
          : setOrganizations((prev) => [...prev, ...res?.data?.organizations]);
        setOrganizationsCount(res?.extra?.total_count);
      }
    }
  };

  const getKeywords = (keyword, type) => {
    setSearchKeywords({ keyword, type });
  };
  useEffect(() => {
    const timeId = setTimeout(() => {
      setFilterObj &&
        searchKeywords?.type &&
        setFilterObj((data) => {
          return {
            ...data,
            page_number: searchKeywords?.keyword?.length == 0 ? pageNumber : 1,
            keyword: searchKeywords?.keyword?.trim(),
          };
        });
    }, 500);
    setPage(1);
    return () => clearTimeout(timeId);
  }, [searchKeywords]);
  const handleApplyFilterMenu = () => {
    handleChange && handlePageChange(1, handleChange);
    setFilterObj({
      ...filterObj,
      page_number: 1,
      dive_center_id: diveCenter?.value,
      country_id: country?.value,
      city_id: city?.value,
      area_id: area?.value,
      organization_id: organization?.value,
      dive_center_status: accountStatus?.label?.toLowerCase(),
      status: approvalStatus?.label?.toLowerCase(),
    });
    setPage(1);
    handleClose();
  };

  const handleResetFilterMenu = () => {
    handleChange && handlePageChange(1, handleChange);
    setCountry({});
    setCity({});
    setArea({});
    setApprovalStatus({});
    setAccountStatus({});
    setDiveCenter({});
    setOrganization({});
    setFilterObj({ page_number: 1, dive_center_status: "", status: "" });
    setPage(1);
    handleClose();
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <Grid
      container
      item
      lg={12}
      style={{ borderBottom: "1px solid #d9d9d9" }}
      sx={{ pb: 1, mb: 2 }}
      className="branch-head"
    >
      <Grid
        container
        item
        lg={10}
        flexDirection="row"
        justifyContent="start"
        gap={"1rem 2rem"}
        alignItems="center"
      >
        <Grid item lg={"auto"}>
          <Typography
            component="h1"
            style={{
              fontSize: "30px",
              fontWeight: "bold",
              textAlign: "left",
              color: "#333132",
            }}
          >
            {title}
          </Typography>
        </Grid>

        <Grid item lg={"auto"}>
          <NormalTabs
            TabsOptions={options}
            value={BranchValue}
            change={handleBranchValue}
            ContainerStyle="switch-container"
            TabStyle="switch-btn mb-20px"
            onclick={(value) => getBranchType(value)}
          />
        </Grid>

        <Grid item lg={4}>
          {searchInput && (
            <TextFieldInput
              type={searchInput.type}
              onChange={(e) => {
                handleChange && handlePageChange(1, handleChange);
                getKeywords(e.target.value, e?.type);
              }}
              value={searchKeywords?.keyword}
              placeholder={searchInput.placeholder}
              StartAdornment={<SearchIcon />}
              className={searchInput.className}
            />
          )}
        </Grid>
      </Grid>

      <Grid item xs={1} lg={1} height={"auto"} m={"auto 0px auto auto"}>
        <Button
          aria-label="filter"
          id="filter-button"
          aria-controls={Boolean(anchorEl) ? "filter-menu" : undefined}
          aria-expanded={Boolean(anchorEl) ? "true" : undefined}
          aria-haspopup="true"
          variant="outlined"
          startIcon={<FilterAltIcon sx={{ color: "#bebebe" }} />}
          sx={{
            width: "100%",
            height: "auto",
            color: "#333132",
            borderRadius: "10px",
            border: "solid 1px #ececec",
            fontSize: "16px",
            fontWeight: 500,
            textTransform: "capitalize",
            lineHeight: "unset",
          }}
          onClick={handleClick}
        >
          Filter
        </Button>
        <Menu
          id="filter-menu"
          MenuListProps={{
            "aria-labelledby": "filter-button",
          }}
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          PaperProps={{
            style: {
              width: "330px",
            },
          }}
        >
          <Typography
            sx={{
              paddingInlineStart: "16px",
              fontSize: "16px",
              fontWeight: 500,
              mt: 1,
            }}
          >
            Filter by
          </Typography>
          <Divider sx={{ width: "100%", margin: "12px 0" }} />
          <Grid container sx={{ padding: "0 16px" }}>
            <Grid item xs={12}>
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: 500,
                }}
              >
                Dive center:
              </Typography>
            </Grid>
            <Grid item xs={12} mb={2}>
              <SearchableSelect
                name="dive_center_id"
                options={diveCenters}
                getList={getDiveCenters}
                count={diveCentersCount}
                label="Select option"
                className="searchable-select"
                onChange={(val) => setDiveCenter(val)}
                value={diveCenter}
                onClear={() => setDiveCenter(null)}
                onMenuOpen={() => diveCenters.length === 0 && getDiveCenters()}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: 500,
                }}
              >
                Location
              </Typography>
            </Grid>
            <Grid item xs={12} mb={2}>
              <SearchableSelect
                name="country_id"
                options={countries}
                getList={getCountries}
                count={countriesCount}
                label="Country"
                className="searchable-select"
                onChange={(val) => {
                  setCountry(val);
                  setCity(null);
                  setArea(null);
                  setCities([]);
                  setAreas([]);
                }}
                value={country}
                onClear={() => {
                  setCountry(null);
                  setCity(null);
                  setArea(null);
                  setCities([]);
                  setAreas([]);
                }}
                onMenuOpen={() => countries.length === 0 && getCountries()}
              />
            </Grid>
            <Grid item xs={12} mb={2}>
              <SearchableSelect
                name="city_id"
                options={cities}
                getList={getCities}
                count={citiesCount}
                label="City"
                className="searchable-select"
                onChange={(val) => {
                  setCity(val);
                  setArea(null);
                  setAreas([]);
                }}
                value={city}
                onClear={() => {
                  setCity(null);
                  setArea(null);
                  setAreas([]);
                }}
                moreFilterData={{ country_id: country?.value }}
                onMenuOpen={() =>
                  cities.length === 0 &&
                  country?.value &&
                  getCities({ page_number: 1, country_id: country.value })
                }
              />
            </Grid>
            <Grid item xs={12} mb={2}>
              <SearchableSelect
                name="area_id"
                options={areas}
                getList={getAreas}
                count={areasCount}
                label="Area"
                className="searchable-select"
                onChange={(val) => setArea(val)}
                value={area}
                onClear={() => setArea(null)}
                moreFilterData={{ city_id: city?.value }}
                onMenuOpen={() =>
                  areas.length === 0 &&
                  city?.value &&
                  getAreas({ page_number: 1, city_id: city.value })
                }
              />
            </Grid>

            <Grid item xs={12}>
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: 500,
                }}
              >
                Organization:
              </Typography>
            </Grid>
            <Grid item xs={12} mb={2}>
              <SearchableSelect
                name="organization_id"
                options={organizations}
                getList={getOrganizations}
                count={organizationsCount}
                label="Select option"
                className="searchable-select"
                onChange={(val) => setOrganization(val)}
                value={organization}
                onClear={() => setOrganization(null)}
                onMenuOpen={() =>
                  organizations.length === 0 && getOrganizations()
                }
              />
            </Grid>

            <Grid item xs={12}>
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: 500,
                }}
              >
                Operator status:
              </Typography>
            </Grid>
            <Grid item xs={12} mb={2}>
              <SearchableSelect
                name="account_status_id"
                options={accountStatusList}
                label="Select option"
                className="searchable-select"
                onChange={(val) => setAccountStatus(val)}
                value={accountStatus}
                onClear={() => setAccountStatus(null)}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: 500,
                }}
              >
                Approval status:
              </Typography>
            </Grid>
            <Grid item xs={12} mb={2}>
              <SearchableSelect
                name="approval_status_id"
                options={approvalStatusList}
                label="Select option"
                className="searchable-select"
                onChange={(val) => setApprovalStatus(val)}
                value={approvalStatus}
                onClear={() => setApprovalStatus(null)}
              />
            </Grid>

            <Grid
              item
              xs={6}
              mt={2}
              mb={2.25}
              sx={{ paddingInlineEnd: "10px" }}
            >
              <PrimaryButton
                text="Reset"
                classBtn="reset-btn"
                click={handleResetFilterMenu}
              />
            </Grid>
            <Grid
              item
              xs={6}
              mt={2}
              mb={2.25}
              sx={{ paddingInlineStart: "10px" }}
            >
              <PrimaryButton
                text="Apply"
                classBtn="apply-btn"
                click={handleApplyFilterMenu}
              />
            </Grid>
          </Grid>
        </Menu>
      </Grid>
    </Grid>
  );
};

export default BranchHead;

import React, { useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import CActions from "./CActions";
import Filter from "./filter.jsx";
import DetailsUse from "../../../Atoms/Shared/CRUD/DetailsUse";
import TableUse from "../../../Atoms/Shared/CRUD/TableUse";
import FormUse from "../../../Atoms/Shared/CRUD/FormUse";
import DeleteModal from "../Models/DeleteModal";
import { useEffect } from "react";
import { useFormik } from "formik";
import { toast } from "react-toastify";

const CCrud = ({ table, form, DetailsComponent, modalComponents }) => {
  const {
    type,
    list,
    emptyListMsg,
    searchAttribute,
    details,
    actionsList,
    columns,
    isChecked,
    isSearch,
    isFilter,
    className,
    tableContainerOverflow,
    createBtnTitle,
    createBtnFun,
    CreateBtnIcon,
    hideCreateBtn,
    createBtnClass,
    createBtnDisable,
    listTitle,
    setFilterObj,
    total_count,
    filterList,
    filterSearch,
    filterExtra,
    isSort,
    paginationPage,
    setPaginationPage,
    searchInputPlaceholder,
    handleApplyFilterMenu,
    handleResetFilterMenu,
    handlePageChange,
    rowData,
    openDeleteModal,
    setOpenDeleteModal,
    handleDeleteRequest,
    headerChildren,
    setFilterData,
    roles,
    childrenType,
    setPage
  } = table;
  const formik = useFormik({
    initialValues: form?.initialValues,
    validationSchema: form?.validationSchema,
    onSubmit: async (values) => {
      form?.onSubmit(values, formik);
      form?.setSupportedLang("en");
    },
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
  });
  useEffect(() => {
    if (Object.keys(formik?.errors).length !== 0 && formik?.isSubmitting) {
      const msg = Object.keys(formik?.errors).map(
        (err) =>
          `${
            err == "en" ? "English name" : err.split("_").join(" ")
          }  is required`
      );
      toast.error(msg?.join(", "));
    }
  }, [formik?.errors, formik?.isSubmitting]);
  const navigate = useNavigate();
  let [searchParams] = useSearchParams();
  const display = searchParams.get("type");
  const index = searchParams.get("index");
  const id = searchParams.get("id");
  const userType = localStorage.getItem("userType");
  const [tableData, setTableData] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const anchorRef = useRef(null);
  const open = Boolean(anchorEl);
  const handleClick = (event, index, data) => {
    anchorEl == index ? setAnchorEl(null) : setAnchorEl(index);
  };
  // const is_allow = id ? roles?.can_update : roles?.can_create;
  const is_allow =
    id && roles ? roles?.can_update : !id && roles ? roles?.can_create : true;

  const handleClose = () => {
    setAnchorEl(null);
  };
  const Actions = (id, elmIndex, data) => {
    return (
      <CActions
        id={id}
        index={elmIndex}
        data={data}
        form={form}
        handleClick={handleClick}
        handleClose={handleClose}
        anchorRef={anchorRef}
        open={open}
        anchorEl={anchorEl}
        actionsList={actionsList}
        type={type}
      />
    );
  };
  const mappedData = list?.map((data, index) => {
    return { ...data, Actions: Actions(data?.id, index, data) };
  });
  const mainFilterList = (
    <Filter
      filterList={filterList}
      setFilterData={setFilterData}
      filterSearch={filterSearch}
      filterExtra={filterExtra}
      handleApplyFilterMenu={handleApplyFilterMenu}
      handleResetFilterMenu={handleResetFilterMenu}
      setFilterObj={setFilterObj}
      handleChange={handlePageChange}
    />
  );

  useEffect(() => {
    rowData({ ...tableData, formikData: formik });
  }, [tableData, formik?.values, formik?.errors]);

  const handleModelClose = () => {
    formik.resetForm();
    navigate(window.location.pathname);
  };
  
  return (
    <>
      {!display && (
        <TableUse
          className={className}
          tableContainerOverflow={tableContainerOverflow}
          // setSelectedRow={setSelectedRow}
          childrenType={childrenType}
          headerChildren={headerChildren}
          list={mappedData}
          emptyListMsg={emptyListMsg}
          searchAttribute={searchAttribute}
          columnsData={columns}
          isChecked={isChecked}
          tableData={setTableData}
          listTitle={listTitle}
          isSort={isSort}
          isSearch={isSearch}
          isFilter={isFilter}
          setFilterObj={setFilterObj}
          filterList={mainFilterList}
          createBtnTitle={createBtnTitle}
          createBtnFun={createBtnFun}
          CreateBtnIcon={CreateBtnIcon}
          hideCreateBtn={hideCreateBtn}
          createBtnClass={createBtnClass}
          createBtnDisable={createBtnDisable}
          total_count={total_count}
          paginationPage={paginationPage}
          setPaginationPage={setPaginationPage}
          searchInputPlaceholder={searchInputPlaceholder}
          handlePageChange={handlePageChange}
          setPage={setPage}
        />
      )}

      {display == "form" && is_allow && (
        <FormUse
          handleLanguage={form?.handleLanguage}
          formStructure={form?.structure}
          createBtnFun={() => {
            formik?.resetForm();
          }}
          className={form?.className?.form}
          formHead={form?.formHead}
          formik={formik}
          form={form}
          handleModelClose={handleModelClose}
          breadcrumbs={form?.breadcrumbs}
        ></FormUse>
      )}

      {display == "details" &&
        (DetailsComponent ? (
          DetailsComponent
        ) : (
          <DetailsUse data={list[index]} />
        ))}
  {console.log(index,'index')}
      {openDeleteModal && (
        <DeleteModal
          open={openDeleteModal}
          setOpen={setOpenDeleteModal}
          handelDeleteRequest={() => {
            handleDeleteRequest(id ? id : list[index]?.id);
          }}
          handleCancelDelete={() => setOpenDeleteModal(false)}
          customClass="organizations-delete-modal"
          text={[
            `Delete ${
              tableData?.rowData?.question || tableData?.rowData?.name
            }`,
            `Are you sure that you want to delete ${
              tableData?.rowData?.question || tableData?.rowData?.name
            } ?`,
          ]}
        />
      )}

      {modalComponents &&
        modalComponents?.map((modal, index) => {
          return <React.Fragment key={index}>{modal()}</React.Fragment>;
        })}
    </>
  );
};
export default CCrud;

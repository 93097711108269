import { Chip, Stack, Typography } from "@mui/material";
import React,{useState} from "react";
import "./DiveClubDetailsHeader.css";
import { icons } from "../../../../Assets/AssetHelper";
import PrimaryButton from "../../../Atoms/Buttons/Primary/PrimaryButton";
import { useNavigate } from "react-router-dom";
import DeleteModal from "../../General/Models/DeleteModal";
import { updateDiveClub } from "../../../../Contexts/APIs/DiveClubs/DiveClubs";

function DiveClubDetailsHeader({ data, roles }) {
  const navigate = useNavigate();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const handleDeleteRequest = () => {
    (async () => {
        const {res, err} = await updateDiveClub({
            "customer_id": data?.id,
            "user": {
                'status': "deleted"
            },
        });
        
        if(res){
          setOpenDeleteModal(false);
          navigate(`/dive-clubs`);
        }
    })();
  }
  return (
    <>
    <Stack
      alignItems="start"
      flexDirection="row"
      justifyContent="space-between"
      gap={1}
      py={1}
      boxShadow="0 2px 15px 0 rgba(0, 0, 0, 0.1)"
      bgcolor="white"
      height="100%"
    >
      <Stack
        borderRadius="10px"
        py="1rem"
        pr="4rem"
        pl="2.5rem"
        flexDirection="row"
        gap={3}
        width="100%"
        sx={{
          backgroundImage:
            data?.level?.name === "Gold"
              ? "linear-gradient(to left, #f3d163 123%, #dfa440)"
              : data?.level?.name === "Platinum"
              ? "linear-gradient(to left, #c2e0f0 123%, #9ac5db)"
              : data?.level?.name === "Silver"
              ? "linear-gradient(to left, #c8c8c8 121%, #8a8a8a)"
              : "linear-gradient(to left, #dbb06e 0%, #804a00)",
        }}
      >
        {data?.avatar && (
          <img
            src={data?.avatar}
            width="90px"
            height="90px"
            style={{ borderRadius: "50%" }}
          />
        )}
        <Stack
          flexDirection="row"
          justifyContent="space-between"
          width="100%"
          className="details-header-content"
        >
          <Stack flexDirection="column" justifyContent="space-evenly">
            <Stack flexDirection="row" gap={2} alignItems="center">
              <Typography
                fontSize="29px"
                fontWeight="bold"
                color="white"
                textTransform="capitalize"
              >
                {data?.name}
              </Typography>
            </Stack>
            <Stack flexDirection="row" gap={2} alignItems="center">
              <Chip
                icon={
                  <img
                    src={
                      data?.level?.name === "Bronze"
                        ? icons.BronzeIcon
                        : data?.level?.name === "Gold"
                        ? icons.GoldIcon
                        : data?.level?.name === "Platinum"
                        ? icons.PlatinumIcon
                        : data?.level?.name === "Silver"
                        ? icons.SilverIcon
                        : icons.BronzeIcon
                    }
                    alt="points"
                  />
                }
                label={data?.level?.name}
                className={`details-header-content-chip ${
                  data?.level?.name === "Bronze"
                    ? "details-header-content-chip-bronze"
                    : data?.level?.name === "Gold"
                    ? "details-header-content-chip-gold"
                    : data?.level?.name === "Platinum"
                    ? "details-header-content-chip-platinum"
                    : data?.level?.name === "Silver"
                    ? "details-header-content-chip-silver"
                    : null
                }`}
              />
            </Stack>
          </Stack>
          <Stack flexDirection="row" gap={2} alignItems="center">
            <Typography
              fontSize="20px"
              fontWeight="bold"
              color="white"
              display="block"
              textTransform="capitalize"
            >
              Status : {data?.status}
            </Typography>
            {
              roles?.can_delete ? <PrimaryButton
                btnType="icon"
                startIcon={<img src={icons.DeleteIcon} alt="delete" />}
                click={() => setOpenDeleteModal(true)}
              /> : null
            }
          </Stack>
        </Stack>
      </Stack>
      <Stack
        flexDirection="column"
        gap={2}
        width="100%"
        py="12px"
        boxShadow="0 2px 15px 0 rgba(0, 0, 0, 0.1)"
        borderRadius="10px"
      >
        <Typography color="#aaa" fontSize="20px" fontWeight="600" pl={3}>
          Available points
        </Typography>
        <Typography color="#f6b119" fontSize="32px" fontWeight="bold" pl={3}>
          {data?.available_points}
        </Typography>
      </Stack>
    </Stack>
    {
      openDeleteModal && <DeleteModal
          open={openDeleteModal} setOpen={setOpenDeleteModal} 
          handelDeleteRequest={handleDeleteRequest}
          handleCancelDelete={()=>setOpenDeleteModal(false)}
          text={[`Delete ${data?.name}`, `are you sure that you want to delete ${data?.name}`]}
      />
    }
    </>
  );
}

export default DiveClubDetailsHeader;

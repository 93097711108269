import * as Yup from "yup";
export const initialValues = (data) => ({
  ...data,
  ...data?.localized_data,
  country_id: {
    value: data?.country?.id,
    label: data?.country?.name,
  },
});
export const structure = (lang, countriesList, getCountry, countriesCount, country, setCountry) => [
  {
    head: "Currency information",
    list: [
      {
        name: `country_id`,
        kind: "select",
        type: "text",
        label: "Country",
        width: "100%",
        options: countriesList,
        count: countriesCount,
        getList: getCountry,
        value: country,
        onChange: (val) => {setCountry(val)},
        onClear: () => {setCountry(null)},
        onOpen: () => countriesList.length === 0 && getCountry()
      },
      {
        name: `${lang}.name`,
        kind: "input",
        type: "text",
        label: "Area  name",
        width: "100%",
        disabled: true,
      },
      {
        name: `lookup_key`,
        kind: "input",
        type: "text",
        label: "Lookup key",
        width: "100%",
        disabled: true,
      },
      {
        name: `usd_value`,
        kind: "input",
        type: "text",
        label: "Usd value",
        width: "100%",
        disabled: true,
      },

      {
        name: `is_available`,
        kind: "checkbox",
        type: "number",
        label: "is available",
        width: "100%",
      },
      {
        name: `is_payout_currency`,
        kind: "checkbox",
        type: "number",
        label: "is payout currency",
        width: "100%",
      },
    ],
  },
];

export const validationSchema = () => Yup.object({});

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Grid, Typography,Box } from "@mui/material";
import { deleteDescendantUser } from "../../../../Contexts/APIs/Users/UsersList";
import CustomPagination from "../../../Atoms/Pagination/Pagination";
import ActionsMenu from "../ActionsMenu";
import "./UsersList.css";
import { icons } from "../../../../Assets/AssetHelper";
import PhoneInput from "react-phone-input-2";
import dayjs from 'dayjs';

function LogHistoryList({
  logHistoryList,
  setLogHistoryList,
  setOpenLoader,
  count,
  page,
  handlePageChange,
  usersPrivilages,
}) {
  const navigate = useNavigate();
  const userType = localStorage.getItem("userType");
  const formatDate = (dateString) => {
    return dayjs(dateString).format('MMM D, YYYY / h A');
  };
  
  const [openDeleteModel, setOpenDeleteModel] = useState(false);
  const [selectedUser, setSelectedUser] = useState(false);

  const handelDeleteUser = async () => {
    const { res, err } = await deleteDescendantUser(selectedUser?.user?.id);
    setOpenLoader(true);
    if (res) {
      let newData = logHistoryList?.filter(
        (val) => val?.id != selectedUser?.user?.id
      );
      setLogHistoryList(newData);
      setOpenLoader(false);
    }
  };

  return (
    <Grid container>
      <Grid container className="table-header mb-20px">
        <Grid item md={0.5} textAlign="center">
          ID
        </Grid>
        <Grid item md={1.75}>
          User name
        </Grid>
        <Grid item sm={1.75}>
          User role
        </Grid>
        <Grid item sm={1.75}>
          Action type
        </Grid>
        <Grid item sm={1.75}>
          Action date / Time{" "}
        </Grid>
        <Grid item sm={4.5}>
          Description
        </Grid>
      </Grid>

      {logHistoryList?.length === 0 && (
        <Grid container display="block" textAlign="center">
          <img src={icons.EmptyUsers} alt="Users" />
          <Typography fontSize="30px" fontWeight="bold" color="#333132">
            No users added yet
          </Typography>
        </Grid>
      )}

      {logHistoryList?.length >= 1 && (
        <>
          {logHistoryList?.map((item, index) => {
            return (
              <Grid
                key={index}
                container
                item
                lg={12}
                className="user-item mb-20px"
                onClick={() => setSelectedUser({ user: item, index: index })}
              >
                <Grid item md={0.5} className="user-item-no" textAlign="center">
                  {item.id}
                </Grid>
                <Grid 
                  item 
                  sm={1.75} 
                  className="user-item-name"
                >
                  <Box 
                    sx={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      maxWidth: '70%' 
                    }}
                  >
                    {item?.user?.name}
                  </Box>
                </Grid>

                <Grid 
                  item 
                  sm={1.75} 
                  className="user-item-name"
                >
                  <Box 
                    sx={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      maxWidth: '70%' 
                    }}
                  >
                    {item?.user_role?.name}
                  </Box>
                </Grid>

                <Grid item sm={1.75}>
                  {item?.action_type}
                </Grid>
                <Grid 
                item 
                sm={1.75}
              >
                  {formatDate(item?.action_datetime)}
              </Grid>
                <Grid item sm={4.5}>

                  <Box 
                  sx={{
                    // overflow: 'hidden',
                    // textOverflow: 'ellipsis',
                    // whiteSpace: 'nowrap',
                    // maxWidth: '70%' // adjust the maxWidth as per your requirement
                  }}
                >
                  {item?.description}
                  </Box>

                </Grid>
              </Grid>
            );
          })}
          {count > 10 && (
            <Grid
              container
              item
              justifyContent={"center"}
              xs={12}
              mb={7.5}
              mt={5}
            >
              <CustomPagination
                count={Math.ceil(count / 10)}
                size="large"
                shape="rounded"
                page={page}
                handleChange={handlePageChange}
                className="pagination"
              />
            </Grid>
          )}
        </>
      )}
    </Grid>
  );
}

export default LogHistoryList;

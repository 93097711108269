import { Box, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import CustomSeparator from "../../Atoms/BreadCrumb";
import { Link } from "react-router-dom";
import FormHead from "../../Atoms/FormHead/FormHead";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import CheckIcon from "@mui/icons-material/Check";
import OperatorDetailsHeader from "../../Molecules/Operators/OperatorDetailsHeader/OperatorDetailsHeader";
import "./OperatorDetails.css";
import moment from "moment";
import PhoneInput from 'react-phone-input-2'

function OperatorDetails({ operatorData, getOperator, roles }) {
  const created = operatorData?.created_at ? moment(operatorData?.created_at).format("DD-MM-YYYY HH:mm") : 'N/A',
    updated = operatorData?.updated_at ? moment(operatorData?.updated_at).format("DD-MM-YYYY HH:mm") : 'N/A',
    approved = operatorData?.approved_at ? moment(operatorData?.approved_at).format("DD-MM-YYYY HH:mm") : 'N/A';

  const breadcrumbs = [
    { path: "/operators/main", active: true, title: "Operators" },

    {
      active: false,
      title: operatorData?.name + " details",
    },
  ];
  const ownerData = [
    { name: "Owner name", value: operatorData?.owner_name },
    { name: "Owner email address", value: operatorData?.owner_email },
    { name: "Owner Mobile", value: operatorData?.owner_phone_number ? `+${operatorData?.owner_country_code}${operatorData?.owner_phone_number}` : ' N/A ' }
  ];

  const contactData = [
    { name: "Contact person name", value: operatorData?.contact_person_name },
    {
      name: "Contact person email address",
      value: operatorData?.contact_person_email,
    },
    {
      name: "Contact person Mobile",
      value: operatorData?.contact_person_phone_number ? `+${operatorData?.contact_person_country_code}${operatorData?.contact_person_phone_number}` : ' N/A ',
    },
  ];

  const operationData = [
    {
      name: "Dive operation name",
      value: operatorData?.name,
    },
    {
      name: "Operator email address",
      value: operatorData?.email,
    },
    {
      name: "Operator Mobile",
      value: operatorData?.phone_number ? `+${operatorData?.country_code}${operatorData?.phone_number}` : ' N/A ',
    },
    {
      name: "Website link",
      value: operatorData?.website,
    },
    {
      name: "Social media link",
      value: operatorData?.facebook,
    },
    {
      name: " main office address",
      value: operatorData?.main_office_address,
    },
  ];

  return (
    <Stack gap={3}>
      <Grid container item sm={12}>
        <CustomSeparator breadcrumbs={breadcrumbs} />
      </Grid>

      <OperatorDetailsHeader data={operatorData} getOperator={getOperator} roles={roles} />

      <Stack
        mt="-25px"
        bgcoloe="white"
        padding="53.8px"
        className="details-panel"
        flexDirection="row"
        justifyContent="space-between"
        gap="1rem"
        boxShadow="0 2px 15px 0 rgba(0, 0, 0, 0.1)"
        overflow="auto"
      >
        <Stack gap={2} width="80%">
          <Stack bgcolor="rgba(0, 0, 0, 0.01)" borderRadius="10px" p={2}>
            <Typography fontSize="20px" fontWeight="800" color="#333132" mb={1}>
              About {operatorData?.name}
            </Typography>
            <Typography fontSize="18px" fontWeight="500" lineHeight="1.67">
              {operatorData?.about_us}
            </Typography>
          </Stack>

          <Stack bgcolor="rgba(0, 0, 0, 0.01)" borderRadius="10px" p={2}>
            <Box position="relative">
              <Grid container gap={3}>
                <Grid item md={5.5} gap={3}>
                  <Box mb={7} sx={{backgroundColor: '#fbfbfb'}}>
                    <FormHead text={"Owner info"} />
                    <Stack gap={2}>
                      {ownerData?.map((data, index) => {
                        return (
                          <Stack flexDirection="column" key={index}>
                            <Typography fontSize="13px" color="#aaa">
                              {data?.name}
                            </Typography>
                            <Typography className="details-text-bold">
                              {
                                (data?.name == 'Owner Mobile' && operatorData?.owner_phone_number)
                                ? <PhoneInput
                                  inputStyle={{
                                    border: "none",
                                    width: "100%",
                                    padding: "0px",
                                    direction: "ltr",
                                    fontSize: '18px',                      
                                    color: '#3c3c3c',
                                    backgroundColor: '#fbfbfb',
                                    wordBreak: 'break-word',
                                    fontWeight: 'bold'
                                  }}                                  
                                  defaultMask={ parseInt(operatorData?.owner_country_code) == 20 ? '... ... ....' : '.. ... ... ....' }  
                                  value={`${operatorData?.owner_country_code} ${operatorData?.owner_phone_number}`}
                                  disabled={true}
                                  disableDropdown={true}
                                  specialLabel=""
                                  placeholder=""
                                />: data?.name != 'Owner Mobile' 
                                ?<>{data?.value}</> :null
                              }
                            </Typography>
                          </Stack>
                        );
                      })}
                    </Stack>
                  </Box>

                  <Box sx={{backgroundColor: '#fbfbfb'}}>
                    <FormHead text={"Contact person info"} />
                    <Stack gap={2}>
                      {contactData?.map((data, index) => {
                        return (
                          <Stack flexDirection="column" key={index}>
                            <Typography fontSize="13px" color="#aaa">
                              {data?.name}
                            </Typography>
                            <Typography className="details-text-bold">
                            {
                              (data?.name == 'Contact person Mobile' && operatorData?.contact_person_phone_number)
                              ? <PhoneInput
                                inputStyle={{
                                  border: "none",
                                  width: "100%",
                                  padding: "0px",
                                  direction: "ltr",
                                  fontSize: '18px',                      
                                  color: '#3c3c3c',
                                  backgroundColor: '#fbfbfb',
                                  wordBreak: 'break-word',
                                  fontWeight: 'bold'
                              }}
                                defaultMask={ parseInt(operatorData?.owner_country_code) == 20 ? '... ... ....' : '.. ... ... ....' }
                                value={`${operatorData?.contact_person_country_code} ${operatorData?.contact_person_phone_number}`}
                                disabled={true}
                                disableDropdown={true}
                                specialLabel=""
                                placeholder=""
                              />: data?.name != 'Contact person Mobile' 
                              ?<>{data?.value}</> :null
                            }
                            </Typography>
                          </Stack>
                        );
                      })}
                    </Stack>
                  </Box>
                </Grid>
                <Grid item md={5.5} sx={{backgroundColor: '#fbfbfb'}}>
                  <FormHead text={"Dive Operation info"} />
                  <Stack gap={2}>
                    {operationData?.map((data, index) => {
                      return (
                        <Stack flexDirection="column" key={index}>
                          <Typography fontSize="13px" color="#aaa">
                            {data?.name}
                          </Typography>
                          <Typography className="details-text-bold">                                                      
                            {
                              (data?.name == 'Operator Mobile' && operatorData?.phone_number)
                              ? <PhoneInput
                                inputStyle={{
                                  border: "none",
                                  width: "100%",
                                  padding: "0px",
                                  direction: "ltr",
                                  fontSize: '18px',                      
                                  color: '#3c3c3c',
                                  backgroundColor: '#fbfbfb',
                                  wordBreak: 'break-word',
                                  fontWeight: 'bold'
                              }}
                                defaultMask={ parseInt(operatorData?.owner_country_code) == 20 ? '... ... ....' : '.. ... ... ....' }
                                value={`${operatorData?.country_code} ${operatorData?.phone_number}`}
                                disabled={true}
                                disableDropdown={true}
                                specialLabel=""
                                placeholder=""
                              />: data?.name != 'Operator Mobile' 
                              ?<>{data?.value}</> :null
                            }
                          </Typography>
                        </Stack>
                      );
                    })}
                  </Stack>
                </Grid>
              </Grid>
            </Box>
          </Stack>

          <Stack bgcolor="rgba(0, 0, 0, 0.01)" borderRadius="10px" p={2} mt={5}>
            <Box position="relative">
              <FormHead text={"Bank account info"} />
              <Grid container gap={"10px 0px"}>
                <Grid item xs={12} sm={6} md={4} lg={3} flexDirection="column">
                  <Typography className="details-text-light">
                    Bank name
                  </Typography>
                  <Typography className="details-text-bold">
                    {operatorData?.bank_account?.bank_name}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                  flexDirection="column"
                  gap={1}
                >
                  <Typography className="details-text-light">
                    Payout currency
                  </Typography>
                  <Typography className="details-text-bold"> 
                    {operatorData?.bank_account?.currency?.lookup_key}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                  flexDirection="column"
                  gap={1}
                >
                  <Typography className="details-text-light">
                    Payout country
                  </Typography>
                  <Typography className="details-text-bold">
                    {operatorData?.bank_account?.country?.lookup_key}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                  flexDirection="column"
                  gap={1}
                >
                  <Typography className="details-text-light">
                    Pay out city
                  </Typography>
                  <Typography className="details-text-bold">
                    {operatorData?.bank_account?.city?.lookup_key}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                  flexDirection="column"
                  gap={1}
                >
                  <Typography className="details-text-light">
                    Bank address
                  </Typography>
                  <Typography className="details-text-bold">
                    {operatorData?.bank_account?.bank_address}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                  flexDirection="column"
                  gap={1}
                >
                  <Typography className="details-text-light">
                    Swift code
                  </Typography>
                  <Typography className="details-text-bold">
                    {operatorData?.bank_account?.swift_code}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                  flexDirection="column"
                  gap={1}
                >
                  <Typography className="details-text-light">IBAN</Typography>
                  <Typography className="details-text-bold">
                    {operatorData?.bank_account?.iban}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                  flexDirection="column"
                  gap={1}
                >
                  <Typography className="details-text-light">
                    Beneficiary account number
                  </Typography>
                  <Typography className="details-text-bold">
                    {operatorData?.bank_account?.beneficiary_account_number}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                  flexDirection="column"
                  gap={1}
                >
                  <Typography className="details-text-light">
                    Beneficiary Name
                  </Typography>
                  <Typography className="details-text-bold">
                    {operatorData?.bank_account?.beneficiary_name}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                  flexDirection="column"
                  gap={1}
                >
                  <Typography className="details-text-light">
                    Beneficiary Address
                  </Typography>
                  <Typography className="details-text-bold">
                    {operatorData?.bank_account?.beneficiary_address}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Stack>

          <Stack
            flexDirection="row"
            gap="1rem 3rem"
            padding="1rem 0rem 2rem"
            className="details-time details-time-lg"
            flexWrap="wrap"
            width="100%"
          >
            <Stack flexDirection="row" gap={1}>
              <Typography color="#333132" className="details-text-bold">
                Created at :{" "}
              </Typography>
              <Typography color="#333132" className="details-text-bold">
                {created}
              </Typography>
            </Stack>
            <Stack flexDirection="row" gap={1}>
              <Typography color="#333132" className="details-text-bold">
                Updated at :{" "}
              </Typography>
              <Typography color="#333132" className="details-text-bold">
                {updated}
              </Typography>
            </Stack>
            <Stack flexDirection="row" gap={1}>
              <Typography color="#333132" className="details-text-bold">
                approved at :{" "}
              </Typography>
              <Typography color="#333132" className="details-text-bold">
                {approved}
              </Typography>
            </Stack>
          </Stack>
        </Stack>

        <Stack gap={2} width="20%">
          <Stack bgcolor="rgba(0, 0, 0, 0.01)" borderRadius="10px" p={2}>
            <FormHead text={"Freelance policy"} />
            <Stack flexDirection="row" gap={1} my={1} className="details-info">
              {operatorData?.allow_outsider_freelancers ? (
                <CheckIcon fontSize="1.1rem" style={{ color: "#f6b119" }} />
              ) : (
                <CloseOutlinedIcon
                  fontSize="1.1rem"
                  style={{ color: "#e10007" }}
                />
              )}
              <Typography className="details-text-bold">
                Operators accepts freelancer from outside
              </Typography>
            </Stack>
          </Stack>

          <Stack
            flexDirection="row"
            gap="1rem 3rem"
            padding="1rem 0rem 2rem"
            className="details-time details-time-sm"
          >
            <Stack flexDirection="row" gap={1}>
              <Typography color="#333132" className="details-text-bold">
                Created at :{" "}
              </Typography>
              <Typography color="#333132" className="details-text-bold">
                {created}
              </Typography>
            </Stack>
            <Stack flexDirection="row" gap={1}>
              <Typography color="#333132" className="details-text-bold">
                Updated at :{" "}
              </Typography>
              <Typography color="#333132" className="details-text-bold">
                {updated}
              </Typography>
            </Stack>
            <Stack flexDirection="row" gap={1}>
              <Typography color="#333132" className="details-text-bold">
                approved at :{" "}
              </Typography>
              <Typography color="#333132" className="details-text-bold">
                {approved}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}

export default OperatorDetails;

import { Rating, Stack, Typography } from "@mui/material";
import React from "react";
import { styled } from "@mui/material/styles";
import CircleIcon from "@mui/icons-material/Circle";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import "./ApplicantDetailsHeader.css";
import { useNavigate } from "react-router-dom";

const ApplicantDetailsHeader = ({ data, JobOffersStatus, link }) => {
  const navigate = useNavigate();
  const StyledRating = styled(Rating)({
    "& .MuiRating-iconFilled": {
      color: "#3ed181",
    },
    "& .MuiRating-iconHover": {
      color: "#3ed181",
    },
  });

  return (
    <Stack
      alignItems="center"
      bgcolor="#333132"
      borderRadius="10px"
      py="1rem"
      pr="9rem"
      pl="4rem"
      flexDirection="row"
      justifyContent="space-between"
    >
      <Stack flexDirection="row" gap={2} width="100%">
        <img
          src={data?.avatar}
          width="90px"
          height="90px"
          style={{ borderRadius: "10px" }}
        />
        <Stack
          flexDirection="row"
          justifyContent="space-between"
          width="100%"
          className="details-header-content"
        >
          <Stack flexDirection="column" justifyContent="space-evenly">
            <Stack flexDirection="row" gap={2} alignItems="center">
              <Typography
                fontSize="30px"
                fontWeight="bold"
                color="white"
                textTransform="capitalize"
              >
                {data?.name}
              </Typography>
              <Stack
                flexDirection="row"
                gap={0.5}
                alignItems="center"
                style={{ cursor: "pointer" }}
                onClick={() => navigate(link)}
              >
                <StyledRating
                  name="customized-color"
                  value={data?.avg_rate}
                  readOnly={true}
                  icon={
                    <CircleIcon
                      fontSize="inherit"
                      style={{ fontSize: "12px" }}
                    />
                  }
                  emptyIcon={
                    <CircleOutlinedIcon
                      fontSize="inherit"
                      style={{ fontSize: "12px", color: "#3ed181" }}
                    />
                  }
                />
                <Typography fontSize="20px" fontWeight="600" color="#3ed181">
                  ({data?.dive_center_reviews_count} reviews)
                </Typography>
              </Stack>
            </Stack>
            <Stack flexDirection="row" gap={2} alignItems="center">
              <Typography fontSize="20px" fontWeight="bold" color="white">
                Applicant
              </Typography>
              <Typography fontSize="20px" fontWeight="bold" color="#f6b119">
                {data?.rate} {data?.currency?.lookup_key}/hr
              </Typography>
            </Stack>
          </Stack>
          <Stack flexDirection="row" gap={2}>
            <Stack
              flexDirection="column"
              py="4px"
              justifyContent="space-between"
            >
              <Typography
                fontSize="18px"
                color="white"
                display="block"
                textTransform="capitalize"
              >
                Status : {data?.account_status}
              </Typography>
              <Stack flexDirection="row" gap={"16px 5px"} alignItems="center">
                <Typography fontSize="18px" color="white">
                  job status :{" "}
                </Typography>
                <Typography
                  fontSize="18px"
                  textTransform="capitalize"
                  color={`${
                    JobOffersStatus === "completed" ||
                    JobOffersStatus === "accepted"
                      ? "#00b906"
                      : JobOffersStatus === "pending"
                      ? "#f6b119"
                      : JobOffersStatus === "deleted" ||
                        JobOffersStatus === "canceled" ||
                        JobOffersStatus === "conflicted"
                      ? "#e10007"
                      : "#333132"
                  }`}
                >
                  {JobOffersStatus === "accepted" ? "Hired" : JobOffersStatus}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default ApplicantDetailsHeader;

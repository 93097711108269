import React, { useEffect } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { getLanguagesRequest } from "../../../../services/modules/languages";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { getSupportedLanguages } from "../../../../Contexts/APIs/Lookups/SupportedLanguages";
import { useState } from "react";
import './Language.css';

const Languages = ({ setLanguage }) => {
  const [languages, setLanguages] = useState([]);
  const getLanguages = async () => {
    const { res, err } = await getSupportedLanguages();
    if (res) {
      setLanguages(res?.data?.supported_locales);
    }
  };

  const handleChange = (event) => {
    setLanguage(event.target.value);
  };

  useEffect(() => {
    getLanguages();
  }, []);

  return (
    <Stack flexDirection="row" gap={1} alignItems="center" className="select-lang-wrap">
      <Typography fontSize="16px" fontWeight="bold">
        Data language
      </Typography>
      <FormControl sx={{ width: "20%", my: 2 }}>
        <InputLabel id="demo-simple-select-label">Languages</InputLabel>
        <Select
          defaultValue={"en"}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          label="Age"
          onChange={handleChange}
        >
          {languages?.map((data, indx) => {
            return (
              <MenuItem key={indx} value={data?.id}>
                {data.name}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      <Typography>
        <span style={{ color: "#e10007" }}>Tip</span> : you can enter data with
        more than one language
      </Typography>
    </Stack>
  );
};

export default Languages;

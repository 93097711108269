import React from 'react'
import FreelancerDetails from '../../../Components/Organisms/Freelancers/FreelancerDetails'

const FreelancerDetailsTemplate = (
    { applicantData, getFreelancer, roles }
) => {
    return (
        <FreelancerDetails  applicantData={applicantData} getFreelancer={getFreelancer} roles={roles} />
    )
}

export default FreelancerDetailsTemplate
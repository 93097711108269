import React, { useEffect, useState } from "react";
import JobOffersTemplate from "../../../Templates/Main/JobOffers/JobOffers.template";
import Loader from "../../../Components/Atoms/Loader/Loader";
import {
  getJobOffersList,
} from "../../../Contexts/APIs/JobOffers/JobOffers";
import { toast } from "react-toastify";
import usePageFilter from "../../../Components/Atoms/Hooks/usePageFilter";

function JobOffersPage({ roles, applicants_roles }) {
  const [count, setCount] = useState();
  const [openLoader, setOpenLoader] = useState(false);
  const [jobOffers, setJobOffers] = useState([]);
  const { page, filterObj, handlePageChange, setFilterObj, setPage } = usePageFilter();

  const getJobOffers = async (filter) => {
    setOpenLoader(true);
    const defaultFilterObj = {
      page_size: 10,
      page_number: 1,
      keyword: "",
      sort_by: "id",
    };
    const { res, err } = await getJobOffersList({
      ...defaultFilterObj,
      ...filter,
      keyword: filter?.keyword?.trim(),
    });
    if (res) {
      setCount(res?.extra?.total_count);
      setJobOffers(res?.data?.job_offers);
    }
    if (err) toast.error(err);
    setOpenLoader(false);
  };

  // const getApplicants = async (filter) => {
  //     setOpenLoader(true)
  //     const {res,err} = await getApplicantsList(filter)
  //     if (res) {
  //         setCount(res?.extra?.total_count);
  //         console.log(res?.data?.job_requests);
  //     }
  //     if (err) toast.error(err)
  //     setOpenLoader(false)
  // }

  useEffect(() => {
    if (filterObj) getJobOffers(filterObj);
  }, [filterObj]);
  return (
    <>
      <Loader open={openLoader} />
      <JobOffersTemplate
        filterObj={filterObj}
        setFilterObj={setFilterObj}
        jobOffersList={jobOffers}
        count={count}
        page={page}
        setPage={setPage}
        handlePageChange={handlePageChange}
        roles={roles}
        applicants_roles={applicants_roles}
      />
    </>
  );
}

export default JobOffersPage;

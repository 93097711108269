export const for_data = [
  { id: "all", name: "All" },

  { id: "dive_center", name: "Dive center" },
  { id: "freelancer", name: "Freelancer" },
  { id: "customer", name: "Customer" },
];

export const is_hidden = [
  { id: null, name: "All" },
  { id: true, name: "Yes" },
  { id: false, name: "No" },
];

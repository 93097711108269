import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import CCrud from "../../../../Components/Molecules/General/Crud/CCrud";
import "../../../../Components/Atoms/Shared/Styles/list-page.css";
import "../../../../Components/Atoms/Shared/Styles/form-page.css";
import { initialValues, structure, validationSchema } from "./structure";
import AddIcon from "@mui/icons-material/Add";
import LanguagesSelect from "../../../../Components/Molecules/Forms/LanguagesSelect";
import { Grid } from "@mui/material";
import "./Badges.css";
import BadgeDetailsPage from "../../../../Pages/Main/PredefinedLookups/Badges/BadgeDetails.page";

const BadgesTemplate = ({
  setFilterObj,
  data,
  openDeleteModal,
  setOpenDeleteModal,
  handleDeleteRequest,
  count,
  paginationPage,
  setPaginationPage,
  handlePageChange,
  supportedLanguages,
  updateSupportedLang,
  setSupportLang,
  supportLang,
  handleSubmit,
  rowData,
  badgeData,
  breadcrumbs,
  roles
}) => {
  let [searchParams] = useSearchParams();
  const index = searchParams.get("index");
  const navigate = useNavigate();

  const actionsList = ({ id, index }) => {
    return [
      {
        condition: () => {
          if (data[index].status !== "deleted" && roles?.can_read) {
            return true;
          } else {
            return false;
          }
        },
        click: () => {
          navigate(`/predefined-lookups/badges/${id}?index=${index}`);
        },
        text: "View Details",
      },
      {
        condition: () => {
          if (roles?.can_read && roles?.can_update) {
            return true;
          } else {
            return false;
          }
        },
        click: () => {
          navigate(`?type=form&id=${id}&index=${index}`);
        },
        text: "Edit",
      },
      {
        condition: () => {
          if (roles?.can_read && roles?.can_delete) {
            return true;
          } else {
            return false;
          }
        },
        click: () => {
          setOpenDeleteModal(true);
        },
        text: "Delete",
      },
    ];
  };

  return (
    <CCrud
      table={{
        list: data,
        listTitle: "Badges",
        emptyListMsg: "There are no badges",
        actionsList: actionsList,
        columns: [
          { accessor: "badge_id", Header: "Id" },
          { accessor: "badge_img", Header: "Image" },
          { accessor: "badge_name", Header: "Name" },
          { accessor: "Actions", Header: "Actions" },
        ],
        isFilter: false,
        isSearch: false,
        isChecked: false,
        isSort: false,
        total_count: count,
        setFilterObj,
        createBtnTitle: "Add new badge",
        createBtnFun: () => {
          setSupportLang("en");
          navigate(`?type=form`);
        },
        CreateBtnIcon: AddIcon,
        createBtnClass: "add-organization-button",
        hideCreateBtn: roles?.can_read && roles?.can_create ? null : true,
        className: "organization-table badges-table",
        tableContainerOverflow: {overflow: 'auto'},
        paginationPage: paginationPage,
        setPaginationPage: setPaginationPage,
        handlePageChange,
        openDeleteModal: openDeleteModal,
        setOpenDeleteModal: setOpenDeleteModal,
        handleDeleteRequest,
        rowData: rowData,
      }}
      form={{
        structure: () => structure(supportLang),
        initialValues: !index ? {} : initialValues(badgeData),
        validationSchema: validationSchema(),
        onSubmit: handleSubmit,
        setSupportedLang: updateSupportedLang,
        submitTitle: index === null ? "Add" : "Save changes",
        title: index === null ? "Add new badge" : "Edit badge",
        className: { form: "flow-form badges-form", actionButton: "flow-form-buttons" },
        handleLanguage: (
          <Grid container mb='10px' item className="select-lang-wrap">
            <LanguagesSelect
              value={supportLang}
              supportedLanguages={supportedLanguages}
              onChange={updateSupportedLang}
              LanguagesSelectHead={"Data Language"}
            />
          </Grid>
        ),
        breadcrumbs: breadcrumbs,
      }}
      DetailsComponent={<BadgeDetailsPage />}
    />
  );
};

export default BadgesTemplate;

import React from "react";
import Loader from "../../../../Components/Atoms/Loader/Loader";
import { useParams, useSearchParams } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import { getSupportedLanguages } from "../../../../Contexts/APIs/Lookups/SupportedLanguages";
import { toast } from "react-toastify";
import CustomerLevelsDetailsTemplate from "../../../../Templates/Main/Levels/Customer/CustomerDetails.template";
import { getCustomerLevelsDetails } from "../../../../Contexts/APIs/Levels/Customer/customer";

function CustomerLevelsDetailsPage({roles}) {
  let [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const [customersLevelsData, setCustomersLevelsData] = useState({});
  const [openLoader, setOpenLoader] = useState(false);
  const [supportedLanguages, setSupportedLanguages] = useState([]);
  const [supportLang, setSupportLang] = useState("en");

  const getCustomerLevels = async (notification_id) => {
    setOpenLoader(true);
    const { res, err } = await getCustomerLevelsDetails(notification_id);
    if (res) {
      setCustomersLevelsData(res?.data?.level);
    }
    if (err) {
      toast.error(err);
    }
    setOpenLoader(false);
  };

  const getLanguages = async () => {
    const { res, err } = await getSupportedLanguages();
    if (res) {
      setSupportedLanguages(res?.data?.supported_locales);
    }
  };

  useEffect(() => {
    id && getCustomerLevels(id);
  }, [id]);
  useEffect(() => {
    getLanguages({});
  }, []);
  const updateSupportedLang = (e, languages) => {
    setSupportLang(e.target.value);
  };
  return (
    <>
      <Loader open={openLoader} />
      <CustomerLevelsDetailsTemplate
        customersLevelsData={customersLevelsData}
        supportedLanguages={supportedLanguages}
        updateSupportedLang={updateSupportedLang}
        supportLang={supportLang}
        roles={roles}
      />
    </>
  );
}

export default CustomerLevelsDetailsPage;

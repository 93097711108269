import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import ActivitiesListTemplate from "../../../Templates/Main/Activities/ActivitiesList.template";
import { getActivitiesList } from "../../../Contexts/APIs/Activities/Activities";
import usePageFilter from "../../../Components/Atoms/Hooks/usePageFilter";

function ActivitiesListPage({ roles }) {
  const [openLoader, setOpenLoader] = useState(false);
  const [activitiesList, setActivitiesList] = useState([]);
  const [activitiesLength, setActivitiesLength] = useState(0);
  const [count, setCount] = useState(0);
  const { page, filterObj, handlePageChange ,setFilterObj,setPage} = usePageFilter();

  const getActivities = async (filterObj) => {
    setOpenLoader(true);
    const defaultFilterObj = {
      page_size: 10,
      page_number: 1,
      keyword: "",
      sort_by: "id",
    };
    const { res, error } = await getActivitiesList({
      ...defaultFilterObj,
      ...filterObj,
    });
    if (res) {
      setOpenLoader(false);
      setActivitiesLength(res?.extra?.total_count);
      setActivitiesList(res?.data?.activities);
      setCount(res?.extra?.total_count);
    }
    if (error) {
      setOpenLoader(false);
    }
  };

  useEffect(() => {
    if (filterObj && (filterObj.keyword !== "" || filterObj.keyword !== null))
      getActivities(filterObj);
  }, [filterObj]);

  return (
    <ActivitiesListTemplate
      filterObj={filterObj}
      setFilterObj={setFilterObj}
      activitiesList={activitiesList}
      openLoader={openLoader}
      count={count}
      page={page}
      setPage={setPage}
      handlePageChange={handlePageChange}
      getActivities={getActivities}
      activitiesLength={activitiesLength}
      roles={roles}
      setActivitiesList={setActivitiesList}
    />
  );
}

export default ActivitiesListPage;

import React from "react";
import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Languages from "../../../../Components/Atoms/Shared/language/Language";
import CCrud from "../../../../Components/Molecules/General/Crud/CCrud";
import "../../../../Components/Atoms/Shared/Styles/list-page.css";
import { initialValues, structure, validationSchema } from "./structure";
import AddIcon from "@mui/icons-material/Add";
import { for_data, is_hidden } from "./filter_data";
import FeatureOptionsDetailsPage from "../../../../Pages/Main/PredefinedLookups/FeatureOptions/FeatureOptionsDetails.page";
import { createFAQ, updateFAQ } from "../../../../Contexts/APIs/FAQ/FAQ";

const FAQTemplate = ({
  setFilterObj,
  data,
  openDeleteModal,
  setOpenDeleteModal,
  rowData,
  count,
  paginationPage,
  setPaginationPage,
  handlePageChange,
  handleApplyFilterMenu,
  handleResetFilterMenu,
  details,
  handleDeleteRequest,
  breadcrumbs,
  roles
}) => {
  const searchInputPlaceholder = "Search Provinces name";
  let [searchParams] = useSearchParams();
  const index = searchParams.get("index");
  const id = searchParams.get("id");
  const navigate = useNavigate();
  const [supportedLang, setSupportedLang] = useState("en");

  const handelSubmit = (data, formik) => {
    const action = () => {
      searchParams.delete("id");
      searchParams.delete("type");
      searchParams.delete("index");
      navigate("/faqs");
      formik?.resetForm();
    };

    if (index) {
      updateFAQ({ id, data: { faq: data }, action });
    } else {
      createFAQ({
        data: { faq: { is_hidden: false, ...data } },
        action,
      });
    }
  };

  const actionsList = ({ id, index, details }) => {
    return [
      {
        condition: () => {
          if (roles?.can_read) {
            return true;
          } else {
            return false;
          }
        },
        click: () => {
          navigate(`/faqs/${id}?type=details&id=${id}&index=${index}`);
        },
        text: "View details",
      },
      {
        condition: () => {
          if (roles?.can_read && roles?.can_update) {
            return true;
          } else {
            return false;
          }
        },
        click: () => {
          navigate(`?type=form&id=${id}&index=${index}`);
        },
        text: "edit",
      },
      {
        condition: () => {
          if (roles?.can_read && roles?.can_delete) {
            return true;
          } else {
            return false;
          }
        },
        click: () => {
          setOpenDeleteModal(true);
        },
        text: "Delete",
      },
    ];
  };
  const filterList = [
    {
      key: "for",
      title: "For type",
      list: for_data,
    },
    {
      key: "is_hidden",
      title: "Is hidden",
      list: is_hidden,
    },
  ];
  return (
    <CCrud
      table={{
        list: data,
        filterList,
        openDeleteModal: openDeleteModal,
        setOpenDeleteModal: setOpenDeleteModal,
        handleApplyFilterMenu: handleApplyFilterMenu,
        handleResetFilterMenu: handleResetFilterMenu,
        rowData: rowData,
        listTitle: "Frequently asked questions",
        emptyListMsg: "There are no Frequently asked questions",
        details: {
          navigatePath: "/levels/countries",
          statusField: null,
          hideStatus: null,
        },
        actionsList: actionsList,
        columns: [
          { accessor: "faq_id", Header: "Id" },
          {
            accessor: "question_data",
            Header: "Question",
          },

          { accessor: "for", Header: "For" },
          {
            accessor: "is_hidden_data",
            Header: "Is hidden",
          },
          { accessor: "Actions", Header: "Actions" },
        ],
        isFilter: true,
        isSearch: false,
        isChecked: false,
        isSort: false,
        total_count: count,
        setFilterObj,
        createBtnTitle: "Add new Frequently asked questions",
        createBtnFun: () => navigate("?type=form"),
        CreateBtnIcon: AddIcon,
        hideCreateBtn: roles?.can_read && roles?.can_create ? null : true,
        createBtnClass: "add-organization-button",
        className: "organization-table",
        tableContainerOverflow: {overflow: 'auto'},
        paginationPage: paginationPage,
        setPaginationPage: setPaginationPage,
        searchInputPlaceholder: searchInputPlaceholder,
        handlePageChange,
        handleDeleteRequest
      }}
      form={{
        structure: () =>
          structure(supportedLang, [{ id: "216", name: "test" }]),
        validationSchema: validationSchema,
        initialValues: !index ? {} : initialValues(details, supportedLang),
        submitTitle: index === null ? "Add" : "Save changes",
        onSubmit: handelSubmit,
        className: { form: "flow-form", actionButton: "flow-form-buttons" },
        handleLanguage: <Languages setLanguage={setSupportedLang} />,
        title: index
          ? "Edit Frequently asked questions"
          : "Add new Frequently asked question",
        breadcrumbs: breadcrumbs,
        setSupportedLang: setSupportedLang,
      }}
      DetailsComponent={<FeatureOptionsDetailsPage />}
    />
  );
};

export default FAQTemplate;

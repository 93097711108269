import { Box, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import "../../../../Components/Atoms/Shared/Styles/details-page.css";
import CustomSeparator from "../../../../Components/Atoms/BreadCrumb";
import FormHead from "../../../../Components/Atoms/FormHead/FormHead";
import LanguagesSelect from "../../../../Components/Molecules/Forms/LanguagesSelect";
import OrganizationDetailsHeader from "../../../../Components/Molecules/Organizations/OrganizationDetailsHeader";
import { deleteFeatureOption } from "../../../../Contexts/APIs/FeatureOptions/FeatureOptions";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";
import DeleteModal from "../../../../Components/Molecules/General/Models/DeleteModal";
function FeatureOptionsDetailsTemplate({
  customersLevelsData,
  supportLang,
  supportedLanguages,
  updateSupportedLang,
  openDeleteModal,
  setOpenDeleteModal,
  handleDeleteRequest,
  roles
}) {
  const navigate = useNavigate();
  let [searchParams] = useSearchParams();

  const id = searchParams.get("id");

  const data = [
    {
      label: "ID number",
      value: customersLevelsData?.id,
    },
    {
      label: "Feature option name",
      value:
        customersLevelsData?.localized_data &&
        Object.keys(customersLevelsData?.localized_data).includes(
          supportLang
        ) &&
        customersLevelsData?.localized_data[supportLang]?.name,
    },
    {
      label: "Facility type",
      value: customersLevelsData?.facility_type,
    },
  ];
  const breadcrumbs = [
    {
      path: "/predefined-lookups/feature-options",
      active: true,
      title: " Feature option",
    },

    {
      active: false,
      title: " Feature option details",
    },
  ];
  return (
    <Stack spacing={3}>
      <Grid container item sm={12}>
        <CustomSeparator breadcrumbs={breadcrumbs} />
      </Grid>

      <Stack spacing={2}>
        <OrganizationDetailsHeader
          data={{ name: "Feature option details" }}
          supportLang={supportLang}
          isTitle={false}
          handleEdit={() =>
            navigate(
              `/predefined-lookups/feature-options?type=form&id=${id}&index=1`
            )
          }
          handleDelete={() => setOpenDeleteModal(id)}
          roles={roles}
        />
        <Grid container item sm={12} className="select-lang-wrap">
          <LanguagesSelect
            value={supportLang}
            supportedLanguages={supportedLanguages}
            onChange={(e) => updateSupportedLang(e, supportedLanguages)}
            LanguagesSelectHead={"Data Language"}
            LanguagesSelectTip={false}
          />
        </Grid>
        <Stack
          mt="-25px"
          bgcoloe="white"
          padding="53.8px"
          className="details-panel"
          flexDirection="row"
          justifyContent="space-between"
          gap="1rem"
          boxShadow="0 2px 15px 0 rgba(0, 0, 0, 0.1)"
          overflow="auto"
          position="relative"
        >
          <Stack gap={2} width="70%">
            <Stack bgcolor="rgba(0, 0, 0, 0.01)" borderRadius="10px" p={2}>
              <FormHead text={"Feature option details"} />
              <Box position="relative">
                <Grid container gap={1}>
                  <Grid item xs={3}>
                    <Box component="img" src={customersLevelsData?.icon} sx={{ width: '100%', maxWidth: '100%' }} />
                  </Grid>
                  <Grid item xs={8}>
                    {data?.map((val, index) => {
                      return (
                        <Grid container gap={"8px 0px"} mb="21px">
                          <Grid item xs={12} md={4} key={index}>
                            <Typography
                              fontSize="18px"
                              fontWeight="500"
                              color="#333132"
                            >
                              {val?.label}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} md={8}>
                            <Typography className="details-text-bold">
                              {val?.value}
                            </Typography>
                          </Grid>
                        </Grid>
                      );
                    })}
                    <Grid container gap={"8px 0px"} mb="21px">
                      <Stack flexDirection="row" gap={1}>
                        <Typography
                          fontSize="18px"
                          fontWeight="500"
                          color="#333132"
                        >
                          {customersLevelsData?.is_hidden ? (
                            <CheckIcon
                              fontSize="1.1rem"
                              style={{ color: "#f6b119" }}
                            />
                          ) : (
                            <CloseIcon
                              fontSize="1.1rem"
                              style={{ color: "#e10007" }}
                            />
                          )}
                        </Typography>
                        <Typography className="details-text-bold">
                          Is Hidden
                        </Typography>
                      </Stack>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Stack>

            <Stack
              flexDirection="row"
              gap="1rem 3rem"
              padding="1rem 0rem 2rem"
              className="details-time details-time-lg"
            >
              <Stack flexDirection="row" gap={1}>
                <Typography color="#333132" className="details-text-bold">
                  Created at :
                  {moment(customersLevelsData?.created_at).format(
                    "DD-MM-YYYY  HH:mm"
                  )}
                </Typography>
              </Stack>
              <Stack flexDirection="row" gap={1}>
                <Typography color="#333132" className="details-text-bold">
                  Updated at :
                  {moment(customersLevelsData?.updated_at).format(
                    "DD-MM-YYYY  HH:mm"
                  )}
                </Typography>
              </Stack>
            </Stack>
          </Stack>

          <Stack gap={2} width="30%" position="absolute" bottom="0">
            <Stack
              flexDirection="row"
              gap="1rem 3rem"
              padding="1rem 0rem 2rem"
              className="details-time details-time-sm"
            >
              <Stack flexDirection="row" gap={1}>
                <Typography color="#333132" className="details-text-bold">
                  Created at :{" "}
                </Typography>
              </Stack>
              <Stack flexDirection="row" gap={1}>
                <Typography color="#333132" className="details-text-bold">
                  Updated at :{" "}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Stack>

      <DeleteModal
        open={openDeleteModal}
        setOpen={setOpenDeleteModal}
        handelDeleteRequest={() => {
          // deleteFeatureOption(id);
          handleDeleteRequest(id);
        }}
        handleCancelDelete={() => setOpenDeleteModal(false)}
        customClass="organizations-delete-modal"
        text={[
          `Delete ${customersLevelsData?.name}`,
          `Are you sure that you want to delete ${customersLevelsData?.name} ?`,
        ]}
      />
    </Stack>
  );
}

export default FeatureOptionsDetailsTemplate;

import { Box, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import "../../../../Components/Atoms/Shared/Styles/details-page.css";
import CustomSeparator from "../../../../Components/Atoms/BreadCrumb";
import FormHead from "../../../../Components/Atoms/FormHead/FormHead";
import LanguagesSelect from "../../../../Components/Molecules/Forms/LanguagesSelect";
import moment from "moment";
import CustomerLevalDetailsHeader from "../../../../Components/Molecules/Levels/detailsHeader";
function CustomerLevelsDetailsTemplate({
  customersLevelsData,
  supportLang,
  supportedLanguages,
  updateSupportedLang,
  roles
}) {
  const navigate = useNavigate();
  let [searchParams] = useSearchParams();

  const id = searchParams.get("id");

  const breadcrumbs = [
    { path: "/levels/customer", active: true, title: "Customer levels" },

    {
      active: false,
      title: "customer level details",
    },
  ];
  return (
    <Stack spacing={3}>
      <Grid container item sm={12}>
        <CustomSeparator breadcrumbs={breadcrumbs} />
      </Grid>
      <Grid container item sm={12} className="select-lang-wrap">
        <LanguagesSelect
          value={supportLang}
          supportedLanguages={supportedLanguages}
          onChange={(e) => updateSupportedLang(e, supportedLanguages)}
          LanguagesSelectHead={"Data Language"}
          LanguagesSelectTip={false}
        />
      </Grid>
      <Stack spacing={0.3}>
        <CustomerLevalDetailsHeader
          data={customersLevelsData}
          supportLang={supportLang}
          isTitle={false}
          handleEdit={() =>
            navigate(`/levels/customer?type=form&id=${id}&index=1`)
          }
          roles={roles}
        />
        <Stack
          mt="-25px"
          bgcoloe="white"
          padding="53.8px"
          className="details-panel"
          flexDirection="row"
          justifyContent="space-between"
          gap="1rem"
          boxShadow="0 2px 15px 0 rgba(0, 0, 0, 0.1)"
          overflow="auto"
          position="relative"
        >
          <Stack gap={2} width="100%">
            <Stack
              bgcolor="rgba(0, 0, 0, 0.01)"
              borderRadius="10px"
              p={2}
              width={{ xs: "100%", md: "50%" }}
            >
              <FormHead text={"Level  information"} />
              <Box position="relative">
                <Grid container gap={1}>
                  <Grid item xs={12}>
                    <Grid container gap={"8px 0px"} mb="21px">
                      <Grid item xs={12} md={5}>
                        <Typography
                          fontSize="18px"
                          fontWeight="500"
                          color="#333132"
                        >
                          ID number
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={5}>
                        <Typography className="details-text-bold">
                          {customersLevelsData?.id}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container gap={"8px 0px"} mb="21px">
                      <Grid item xs={12} md={5}>
                        <Typography
                          fontSize="18px"
                          fontWeight="500"
                          color="#333132"
                        >
                          Rank
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={5}>
                        <Typography className="details-text-bold">
                          {customersLevelsData?.rank}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container gap={"8px 0px"} mb="21px">
                      <Grid item xs={12} md={5}>
                        <Typography
                          fontSize="18px"
                          fontWeight="500"
                          color="#333132"
                        >
                          Reservations required
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={5}>
                        <Typography className="details-text-bold">
                          {customersLevelsData?.reservations_required}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container gap={"8px 0px"} mb="21px">
                      <Grid item xs={12} md={5}>
                        <Typography
                          fontSize="18px"
                          fontWeight="500"
                          color="#333132"
                        >
                          Flat points earned Inc
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={5}>
                        <Typography className="details-text-bold">
                          {customersLevelsData?.flat_points_earned_inc}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Stack>

            <Stack flexDirection="row" gap="1rem 3rem" padding="1rem 0rem 2rem">
              <Stack flexDirection="row" gap={1}>
                <Typography color="#333132" className="details-text-bold">
                  Created at :
                  {moment(customersLevelsData?.created_at).format(
                    "DD-MM-YYYY  HH:mm"
                  )}
                </Typography>
              </Stack>
              <Stack flexDirection="row" gap={1}>
                <Typography color="#333132" className="details-text-bold">
                  Updated at :
                  {moment(customersLevelsData?.updated_at).format(
                    "DD-MM-YYYY  HH:mm"
                  )}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}

export default CustomerLevelsDetailsTemplate;

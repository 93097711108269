import React, { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import ActivitiesReviewsListTemplate from "../../../Templates/Main/Activities/ActivitiesReviewsList.template";
import { getActivityDetails, getActivityReviews } from "../../../Contexts/APIs/Activities/Activities";

const ActivitiesReviewsListPage = () => {
  const [list, setList] = useState([]);
  const [count, setCount] = useState(0);
  const { activityId } = useParams();
  const [activityData, setActivityData] = useState({});
  const [reviewsDetails, setReviewsDetails] = useState({});
  const [page, setPage] = useState(1);
  const [searchParams] = useSearchParams();

  const handleChange = (key, value) => {
    setPage(value);
  };
  const getReviewsList = async () => {
    const { res, err } = await getActivityReviews(
      { page_number: page, page_size: 10, activity_id: activityId },
      {
        review: {
          reviewee_id: (searchParams.get("type") == null || searchParams.get("type") == 1) 
            ? activityData?.branch_id
            : searchParams.get("type") == 2 ? activityId
            : null,
          reviewee_type: (searchParams.get("type") == null || searchParams.get("type") == 1) 
            ? "Branch" 
            : searchParams.get("type") == 2 ? "DiveActivity"
            : searchParams.get("type") == 3 ? "DiveExpert" : null,
          reviewer_type: "Customer"
        },
      }
    );
    if (res) {
      setList(res.data?.reviews);
      setReviewsDetails(res?.data?.reviews_details);
      setCount(res?.extra?.total_count);
    }
  };

  const getActivity = async (id) => {
    const { res, err } = await getActivityDetails(id);
    if (res) {
      setActivityData(res?.data?.activity);
    }
  };
  

  useEffect(() => {
    getReviewsList();
  }, [page, searchParams.get("type")]);

  useEffect(() => {
    activityId && getActivity(activityId);
  }, [activityId]);
  return (
    <ActivitiesReviewsListTemplate
      count={count}
      reviewsList={list}
      reviewsDetails={reviewsDetails}
      data={activityData}
      handleChange={handleChange}
      page={page}
    />
  );
};

export default ActivitiesReviewsListPage;

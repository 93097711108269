import { Box, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import CustomSeparator from "../../Atoms/BreadCrumb";
import { Link } from "react-router-dom";
import FormHead from "../../Atoms/FormHead/FormHead";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import CheckIcon from "@mui/icons-material/Check";
import "./JobOfferDetails.css";
import JobOfferDetailsHeader from "../../Molecules/JobOffers/JobOfferDetailsHeader/JobOfferDetailsHeader";
import LanguagesSelect from "../../Molecules/Forms/LanguagesSelect";
import moment from "moment";

function JobOfferDetails({
  jobOfferData,
  supportedLanguages,
  updateSupportedLang,
  supportLang,
  roles
}) {
  const created = jobOfferData?.created_at ? moment(jobOfferData?.created_at).format("YYYY-MM-DD hh:mm a") : 'N/A',
    updated = jobOfferData?.updated_at ? moment(jobOfferData?.updated_at).format("YYYY-MM-DD hh:mm a") : 'N/A',
    startDate = jobOfferData?.start_date ? moment(jobOfferData?.start_date).format("DD-MM-YYYY") : 'N/A',
    endDate = jobOfferData?.end_date ? moment(jobOfferData?.end_date).format("DD-MM-YYYY") : 'N/A',
    startTime = jobOfferData?.start_time ? moment(jobOfferData?.start_time).format("hh:mm a") : 'N/A',
    info = [
      {
        title: "is public",
        status: jobOfferData?.is_public === true ? true : false,
      },
      {
        title: "is high priority",
        status: jobOfferData?.is_high_priority === true ? true : false,
      },
      {
        title: "transportation included",
        status: jobOfferData?.transportation_included === true ? true : false,
      },
      {
        title: "meals included",
        status: jobOfferData?.meals_included === true ? true : false,
      },
      {
        title: "reach x-applicants",
        status: jobOfferData?.reach_x_applicants === true ? true : false,
      },
      {
        title: "reach favorite first",
        status: jobOfferData?.reach_favorite_first === true ? true : false,
      },
    ];

  const handleDigits = (number) => {
    return number.toString().length === 1 ? "0" + number : number;
  };
  const breadcrumbs = [
    { path: "/operators/job-offers", active: true, title: "Job offers" },

    {
      active: false,
      title: jobOfferData?.localized_data
        ? jobOfferData?.localized_data[`${supportLang}`]["title"]
        : jobOfferData?.title + "details",
    },
  ];
  return (
    <Stack gap={3}>
      <Grid container item sm={12}>
        <CustomSeparator breadcrumbs={breadcrumbs} />
      </Grid>

      <Grid container item sm={12} className="select-lang-wrap">
        <LanguagesSelect
          value={supportLang}
          supportedLanguages={supportedLanguages}
          onChange={(e) => updateSupportedLang(e, supportedLanguages)}
          LanguagesSelectHead={"Data Language"}
          LanguagesSelectTip={false}
        />
      </Grid>

      <JobOfferDetailsHeader data={jobOfferData} supportLang={supportLang} roles={roles} />

      <Stack
        mt="-25px"
        bgcoloe="white"
        padding="53.8px"
        className="details-panel"
        flexDirection="row"
        justifyContent="space-between"
        gap="1rem"
        boxShadow="0 2px 15px 0 rgba(0, 0, 0, 0.1)"
        overflow="auto"
      >
        <Stack gap={2} width="70%">
          <Stack bgcolor="rgba(0, 0, 0, 0.01)" borderRadius="10px" p={2}>
            <Box position="relative">
              <Grid container gap={1}>
                <Grid item md={5.5}>
                  <FormHead text={"Job offer ID"} />
                  <Stack flexDirection="row" gap={1} mb={1}>
                    <Grid item md={5.5}>
                      {" "}
                      <Typography className="details-text">
                        Job offer ID
                      </Typography>{" "}
                    </Grid>
                    <Grid item md={5.5}>
                      {" "}
                      <Typography className="details-text-bold">
                        {jobOfferData?.id}
                      </Typography>{" "}
                    </Grid>
                  </Stack>
                  <Stack flexDirection="row" gap={1} mb={1}>
                    <Grid item md={5.5}>
                      {" "}
                      <Typography className="details-text">
                        Dive center name
                      </Typography>{" "}
                    </Grid>
                    <Grid item md={5.5}>
                      {" "}
                      <Typography className="details-text-bold">
                        {jobOfferData?.dive_center?.name}
                      </Typography>{" "}
                    </Grid>
                  </Stack>
                  <Stack flexDirection="row" gap={1} mb={1}>
                    <Grid item md={5.5}>
                      {" "}
                      <Typography className="details-text">
                        Branch name
                      </Typography>{" "}
                    </Grid>
                    <Grid item md={5.5}>
                      {" "}
                      <Typography className="details-text-bold">
                        {jobOfferData?.branch?.name}
                      </Typography>{" "}
                    </Grid>
                  </Stack>
                </Grid>
                <Grid item md={5.5}>
                  <FormHead text={"Languages"} />
                  <Grid container gap={1}>
                    <Grid item md={5.5}>
                      {jobOfferData?.languages?.length > 4 ? (
                        <Grid item md={5.5}>
                          {jobOfferData?.languages?.map((lang, index) => {
                            return (
                              index <= 3 && (
                                <Typography
                                  textTransform="capitalize"
                                  mb={1}
                                  className="details-text-bold"
                                  key={index}
                                >
                                  {lang?.name}
                                </Typography>
                              )
                            );
                          })}
                        </Grid>
                      ) : (
                        <Grid item md={5.5}>
                          {jobOfferData?.languages?.map((lang, index) => {
                            return (
                              <Typography
                                textTransform="capitalize"
                                mb={1}
                                className="details-text-bold"
                                key={index}
                              >
                                {lang?.name}
                              </Typography>
                            );
                          })}
                        </Grid>
                      )}
                    </Grid>
                    {jobOfferData?.languages?.length > 4 && (
                      <Grid item md={5.5}>
                        {jobOfferData?.languages?.map((lang, index) => {
                          return (
                            index > 3 && (
                              <Typography
                                textTransform="capitalize"
                                mb={1}
                                className="details-text-bold"
                                key={index}
                              >
                                {lang?.name}
                              </Typography>
                            )
                          );
                        })}
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Stack>

          <Stack bgcolor="rgba(0, 0, 0, 0.01)" borderRadius="10px" p={2}>
            <FormHead text={"Job description"} />
            <Typography className="details-text">
              {jobOfferData?.localized_data
                ? jobOfferData?.localized_data[`${supportLang}`]["description"]
                : jobOfferData?.description}
            </Typography>
          </Stack>

          <Stack
            flexDirection="row"
            gap="1rem 3rem"
            className="details-time details-time-lg"
            m="auto 0rem 0rem"
          >
            <Stack flexDirection="row" gap={1}>
              <Typography color="#333132" className="details-text-bold">
                Created at :{" "}
              </Typography>
              {created != "Invalid Date" ? (
                <Typography color="#333132" className="details-text-bold">
                  {created}
                </Typography>
              ) : null}
            </Stack>
            <Stack flexDirection="row" gap={1}>
              <Typography color="#333132" className="details-text-bold">
                Updated at :{" "}
              </Typography>
              {updated != "Invalid Date" ? (
                <Typography color="#333132" className="details-text-bold">
                  {updated}
                </Typography>
              ) : null}
            </Stack>
          </Stack>
        </Stack>

        <Stack gap={2} width="30%">
          <Stack
            bgcolor="rgba(0, 0, 0, 0.01)"
            borderRadius="10px"
            mb={2.5}
            p={"20px 32px"}
            boxShadow="0 2px 15px 0 rgba(0, 0, 0, 0.1)"
          >
            <Stack
              flexDirection="row"
              gap={1}
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography fontSize="20px" fontWeight="600" color="#aaa">
                Total pay
              </Typography>
              <Typography fontSize="32px" fontWeight="bold" color="#f6b119">
                {jobOfferData?.total_pay} {jobOfferData?.currency?.lookup_key}
              </Typography>
            </Stack>
          </Stack>

          <Stack p={2}>
            <FormHead text={"Information"} />
            {info?.map((data, index) => {
              return (
                <Stack
                  flexDirection="row"
                  gap={1}
                  mb={1}
                  key={index}
                  className="details-info"
                >
                  {data?.status ? (
                    <CheckIcon fontSize="1.1rem" style={{ color: "#f6b119" }} />
                  ) : (
                    <CloseOutlinedIcon
                      fontSize="1.1rem"
                      style={{ color: "#e10007" }}
                    />
                  )}
                  <Typography className="details-text">
                    {data?.title}
                  </Typography>
                </Stack>
              );
            })}
          </Stack>

          <Stack p={2}>
            <FormHead text={"Date and time"} />
            <Stack flexDirection="row" gap={1} mb={1}>
              <Typography className="details-text" mr={1}>
                Start date
              </Typography>
              <Typography className="details-text">{startDate}</Typography>
            </Stack>
            <Stack flexDirection="row" gap={1} mb={1}>
              <Typography className="details-text" mr={1}>
                End date
              </Typography>
              <Typography className="details-text">{endDate}</Typography>
            </Stack>
            <Stack flexDirection="row" gap={1} mb={1}>
              <Typography className="details-text" mr={1}>
                Start time
              </Typography>
              <Typography className="details-text">{startTime}</Typography>
            </Stack>
            <Stack flexDirection="row" gap={1} mb={1}>
              <Typography className="details-text" mr={1}>
                Duration days
              </Typography>
              <Typography className="details-text">
                {jobOfferData?.duration_days}
              </Typography>
            </Stack>
          </Stack>

          <Stack
            bgcolor="rgba(0, 0, 0, 0.01)"
            borderRadius="10px"
            p={2}
            className="organizations-wrap"
          >
            <FormHead text={"Certifications"} />
            <Typography color="#333132" className="details-text">
              {jobOfferData?.certificate?.name ? jobOfferData?.certificate?.name : "Any"}
            </Typography>
          </Stack>

          <Stack
            bgcolor="rgba(0, 0, 0, 0.01)"
            borderRadius="10px"
            p={2}
            className="organizations-wrap"
            mb={2.5}
          >
            <FormHead text={"Organizations"} />
            <Typography color="#333132" className="details-text">
              {jobOfferData?.organization?.name ? jobOfferData?.organization?.name : "Any"}
            </Typography>
          </Stack>

          <Stack
            flexDirection="row"
            gap="1rem 3rem"
            padding="1rem 0rem 2rem"
            className="details-time details-time-sm"
          >
            <Stack flexDirection="row" gap={1}>
              <Typography color="#333132" className="details-text-bold">
                Created at :{" "}
              </Typography>
              {created != "Invalid Date" ? (
                <Typography color="#333132" className="details-text-bold">
                  {created}
                </Typography>
              ) : null}
            </Stack>
            <Stack flexDirection="row" gap={1}>
              <Typography color="#333132" className="details-text-bold">
                Updated at :{" "}
              </Typography>
              {updated != "Invalid Date" ? (
                <Typography color="#333132" className="details-text-bold">
                  {updated}
                </Typography>
              ) : null}
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}

export default JobOfferDetails;

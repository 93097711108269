import React from 'react'
import UserDetails from '../../../Components/Organisms/Users/UserDetails'

const UserDetailsTemplate = ({ userData, getUser, handleEditUser, handelDeleteUser, usersPrivilages }) => {
  return (
    <UserDetails
        userData={userData} getUser={getUser} 
        handleEditUser={handleEditUser}
        handelDeleteUser={handelDeleteUser}
        usersPrivilages={usersPrivilages}
    />
  )
}

export default UserDetailsTemplate

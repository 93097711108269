import React from "react";
import Loader from "../../../Components/Atoms/Loader/Loader";
import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import { getSupportedLanguages } from "../../../Contexts/APIs/Lookups/SupportedLanguages";
import { toast } from "react-toastify";
import NotificationDetailsTemplate from "../../../Templates/Main/Notifications/NotificationDetails.template";
import { getNotificationsDetails } from "../../../Contexts/APIs/Notifications/Notifications";

function NotificationsDetailsPage() {
  const { notification_id } = useParams();
  const [notificationData, setNotificationData] = useState({});
  const [openLoader, setOpenLoader] = useState(false);
  const [supportedLanguages, setSupportedLanguages] = useState([]);
  const [supportLang, setSupportLang] = useState("en");

  const getNotification = async (notification_id) => {
    setOpenLoader(true);
    const { res, err } = await getNotificationsDetails(notification_id);
    if (res) {
      setNotificationData(res?.data?.notification);
    }
    if (err) {
      toast.error(err);
    }
    setOpenLoader(false);
  };

  const getLanguages = async () => {
    const { res, err } = await getSupportedLanguages();
    if (res) {
      setSupportedLanguages(res?.data?.supported_locales);
    }
  };

  useEffect(() => {
    notification_id && getNotification(notification_id);
    getLanguages({});
  }, [notification_id]);
  useEffect(() => {
    getLanguages({});
  }, []);
  const updateSupportedLang = (e, languages) => {
    setSupportLang(e.target.value);
  };
  return (
    <>
      <Loader open={openLoader} />
      <NotificationDetailsTemplate
        notificationData={notificationData}
        supportedLanguages={supportedLanguages}
        updateSupportedLang={updateSupportedLang}
        supportLang={supportLang}
      />
    </>
  );
}

export default NotificationsDetailsPage;

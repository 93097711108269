import * as Yup from "yup";
export const initialValues = (data) => ({
  ...data,
  ...data?.localized_data,

  lookup_key: data?.lookup_key,
});
export const structure = (lang) => [
  {
    head: "Country information",
    list: [
      {
        name: `${lang}.name`,
        kind: "input",
        type: "text",
        label: "Area  name",
        width: "50%",
      },
      {
        name: `lookup_key`,
        kind: "input",
        type: "text",
        label: "Lookup key",
        width: "50%",
      },
      {
        name: `iso_code`,
        kind: "input",
        type: "text",
        label: "Iso code",
        width: "50%",
      },
    ],
  },
];

export const validationSchema = () =>
  Yup.object({
    iso_code: Yup.string().required("required"),
    lookup_key: Yup.string().required("required"),
  });

import React from "react";
import Loader from "../../../../Components/Atoms/Loader/Loader";
import { useSearchParams } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import { getSupportedLanguages } from "../../../../Contexts/APIs/Lookups/SupportedLanguages";
import { toast } from "react-toastify";
import FAQDetailsTemplate from "../../../../Templates/Main/Documents/FAQ/FAQDetails.template";
import { getFAQDetails } from "../../../../Contexts/APIs/FAQ/FAQ";
import { getDocumentsDetails } from "../../../../Contexts/APIs/Documents/Documents";
import DocumentDetailsTemplate from "../../../../Templates/Main/Documents/Documents/DocumentDetails.template";

function DocumentDetailsPage({roles}) {
  let [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const [data, setData] = useState({});
  const [openLoader, setOpenLoader] = useState(false);
  const [supportedLanguages, setSupportedLanguages] = useState([]);
  const [supportLang, setSupportLang] = useState("en");

  const getDocument = async () => {
    setOpenLoader(true);
    const { res, err } = await getDocumentsDetails(id);
    if (res) {
      setData(res?.data?.document);
    }
    if (err) {
      toast.error(err);
    }
    setOpenLoader(false);
  };

  const getLanguages = async () => {
    const { res, err } = await getSupportedLanguages();
    if (res) {
      setSupportedLanguages(res?.data?.supported_locales);
    }
  };

  useEffect(() => {
    console.log(id);
    id && getDocument(id);
  }, [id]);
  useEffect(() => {
    getLanguages({});
  }, []);
  const updateSupportedLang = (e, languages) => {
    setSupportLang(e.target.value);
  };
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  return (
    <>
      <Loader open={openLoader} />
      <DocumentDetailsTemplate
        data={data}
        supportedLanguages={supportedLanguages}
        updateSupportedLang={updateSupportedLang}
        supportLang={supportLang}
        openDeleteModal={openDeleteModal}
        setOpenDeleteModal={setOpenDeleteModal}
        roles={roles}
      />
    </>
  );
}

export default DocumentDetailsPage;

import React from "react";
import { useState } from "react";
import {
  Breadcrumbs,
  Button,
  Divider,
  Grid,
  IconButton,
  Link,
  Menu,
  Typography,
} from "@mui/material";
import TextFieldInput from "../../../Atoms/Input/TextField/TextFieldInput";
import SearchIcon from "@mui/icons-material/Search";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import ClearIcon from "@mui/icons-material/Clear";
import SearchableSelect from "../../../Atoms/Select/SearchableSelect.jsx";
import PrimaryButton from "../../../Atoms/Buttons/Primary/PrimaryButton";
import "./ActivityOffersHeader.css";
import { getOperatorsList } from "../../../../Contexts/APIs/Operators/Operators";
import { getBranchesList } from "../../../../Contexts/APIs/Branches/Branches";
import BasicDatePicker from "../../../Atoms/Input/DatePicker/DatePicker.js";
import { useEffect } from "react";
import moment from "moment";
import { toast } from "react-toastify";
import { useSearchParams } from "react-router-dom";
import { handlePageChange } from "../../../../Contexts/APIs/Helper/Helper.js";

function ActivityOffersHeader({
  breadCrumb,
  title,
  searchInput,
  filterObj,
  setFilterObj,
  setPage,
  handleChange,
}) {
  const [searchKeywords, setSearchKeywords] = useState("");
  const [diveCenters, setDiveCenters] = useState([]);
  const [diveCenter, setDiveCenter] = useState({});
  const [branches, setBranches] = useState([]);
  const [branch, setBranch] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const [offerType, setOfferType] = useState({});
  const [accountStatus, setAccountStatus] = useState();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [searchParams] = useSearchParams();
  const pageNumber = searchParams.get("page_number") || 1;
  const [diveCentersCount, setDiveCentersCount] = useState(0);
  const [branchesCount, setBranchesCount] = useState(0);

  const accountStatusList = [
    { id: 1, name: "Active" },
    { id: 2, name: "Expired" },
  ];

  const offerTypes = [
    { id: 1, name: "General Offer" },
    { id: 2, name: "Mobile Offer" },
  ];

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    // getDiveCenters();
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const getKeywords = (keyword, type) => {
    setSearchKeywords(keyword, type);
    setFilterObj({
      ...filterObj,
      page_number: keyword?.length == 0 ? pageNumber : 1,
      keyword,
    });
    setPage(keyword?.length == 0 ? +pageNumber : 1);
  };

  const getDiveCenters = async (filter) => {
    const prevDiveCenters = diveCenters;
    const DataKey = filter?.keyword;
    if (
      (filter?.page_number === 1 || filter?.page_number === undefined) &&
      !DataKey &&
      diveCenters.length === 10
    ) {
      setDiveCenters(prevDiveCenters);
    } else {
      const { res, error } =
        diveCentersCount !== 10 || DataKey
          ? await getOperatorsList({ page_size: 10, ...filter })
          : {};
      if (res) {
        filter?.page_number === 1 || filter?.page_number === undefined
          ? setDiveCenters(res?.data?.dive_centers)
          : setDiveCenters((prev) => [...prev, ...res?.data?.dive_centers]);
        setDiveCentersCount(res?.extra?.total_count);
      }
    }
  };

  const getBranches = async (filter) => {
    const prevBranches = branches;
    const DataKey = filter?.keyword;
    if (
      (filter?.page_number === 1 || filter?.page_number === undefined) &&
      !DataKey &&
      !filter?.dive_center_id
    ) {
      setBranches(prevBranches);
    } else {
      const { res, error } =
        branchesCount !== 10 || DataKey
          ? await getBranchesList({
              page_size: 10,
              page_number: 1,
              dive_center_id: filter?.dive_center_id,
              ...filter,
            })
          : {};
      if (res) {
        filter?.page_number === 1 || filter?.page_number === undefined
          ? setBranches(res?.data?.branches)
          : setBranches((prev) => [...prev, ...res?.data?.branches]);
        setBranchesCount(res?.extra?.total_count);
      }
    }
  };

  //val == 'Invalid Date' ? null : val
  const handleApplyFilterMenu = () => {
    if (from < 0 || to < 0) {
      toast.error("Discount percentage can't be negative number");
    } else {
      handlePageChange(1, handleChange);
      setFilterObj({
        ...filterObj,
        page_number: 1,
        offer_type: offerType?.label?.toLowerCase().replaceAll(" ", "_"),
        owner_id: diveCenter?.value,
        owner_type: "DiveCenter",
        branch_id: branch?.value,
        status: accountStatus?.label?.toLowerCase(),
        discount_percentage_from: from,
        discount_percentage_to: to,
        date_from:
          !startDate || startDate == "Invalid Date"
            ? null
            : moment(new Date(startDate), "YYYY/MM/DD").format("YYYY-MM-DD"),
        date_to:
          !endDate || endDate == "Invalid Date"
            ? null
            : moment(new Date(endDate), "YYYY/MM/DD").format("YYYY-MM-DD"),
      });
      setPage(1);
      handleClose();
    }
  };

  const handleResetFilterMenu = () => {
    handleChange && handlePageChange(1, handleChange);
    setOfferType({});
    setDiveCenter({});
    setBranch({});
    setAccountStatus({});
    setFrom("");
    setTo("");
    setStartDate(null);
    setEndDate(null);
    setFilterObj({ page_number: 1 });
    setPage(1);
    handleClose();
  };

  useEffect(() => {
    const timeId = setTimeout(() => {
      setFilterObj &&
        searchKeywords?.type &&
        getKeywords(searchKeywords?.keyword, searchKeywords?.type);
    }, 500);
    return () => clearTimeout(timeId);
  }, [searchKeywords]);

  return (
    <Grid
      container
      item
      lg={12}
      style={{ borderBottom: "1px solid #d9d9d9" }}
      sx={{ pb: 1, mb: 2 }}
      gap={"16px 0px"}
      justifyContent={"space-between"}
      className="activity-offer-head"
    >
      {breadCrumb && (
        <Grid item sm={12}>
          <Breadcrumbs aria-label="breadcrumb" separator="›">
            <Link color="inherit" href={breadCrumb.path}>
              {breadCrumb.link}
            </Link>
            <Typography>{breadCrumb.text}</Typography>
          </Breadcrumbs>
        </Grid>
      )}

      <Grid
        container
        item
        xs={12}
        sm={"auto"}
        lg={"auto"}
        flexDirection="row"
        justifyContent="start"
        gap={"1rem 2rem"}
        marginRight={1}
        alignItems="center"
      >
        <Grid item lg={"auto"}>
          <Typography
            component="h1"
            style={{
              fontSize: "30px",
              fontWeight: "bold",
              textAlign: "left",
              color: "#333132",
            }}
          >
            {title}
          </Typography>
        </Grid>

        <Grid item lg={"auto"}>
          {searchInput && (
            <TextFieldInput
              type={searchInput.type}
              onChange={(e) => {
                handleChange && handlePageChange(1, handleChange);
                setSearchKeywords({ keyword: e?.target?.value, type: e?.type });
              }}
              placeholder={searchInput.placeholder}
              StartAdornment={<SearchIcon />}
              className={searchInput.className}
              value={searchKeywords?.keyword}
            />
          )}
        </Grid>
      </Grid>

      <Grid
        container
        item
        xs={12}
        sm={"auto"}
        lg={"auto"}
        // width={'330px'}
        alignItems={"center"}
      >
        <Grid item lg={"auto"} display={"flex"}>
          <Button
            aria-label="filter"
            id="filter-button"
            aria-controls={Boolean(anchorEl) ? "filter-menu" : undefined}
            aria-expanded={Boolean(anchorEl) ? "true" : undefined}
            aria-haspopup="true"
            variant="outlined"
            startIcon={<FilterAltIcon sx={{ color: "#bebebe" }} />}
            sx={{
              width: "100%",
              height: "auto",
              color: "#333132",
              borderRadius: "10px",
              border: "solid 1px #ececec",
              fontSize: "16px",
              fontWeight: 500,
              textTransform: "capitalize",
              lineHeight: "unset",
              margin: "auto",
              padding: "7px 15px 8px",
            }}
            onClick={handleClick}
          >
            Filter
          </Button>
          <Menu
            id="filter-menu"
            className="activity-offers-menu"
            MenuListProps={{
              "aria-labelledby": "filter-button",
            }}
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            PaperProps={{
              style: {
                width: "390px",
              },
            }}
          >
            <Typography
              sx={{
                paddingInlineStart: "16px",
                fontSize: "16px",
                fontWeight: 500,
                mt: 1,
              }}
            >
              Filter by
            </Typography>
            <Divider sx={{ width: "100%", margin: "12px 0" }} />
            <Grid container sx={{ padding: "0 16px" }}>
              <Grid item xs={12}>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: 500,
                  }}
                >
                  Offer type
                </Typography>
              </Grid>
              <Grid item xs={12} mb={2}>
                <SearchableSelect
                  name="offer_type_id"
                  options={offerTypes}
                  label="Select option"
                  className="searchable-select"
                  onChange={(val) => setOfferType(val)}
                  value={offerType}
                  onClear={() => setOfferType(null)}
                />
              </Grid>

              <Grid item xs={12}>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: 500,
                  }}
                >
                  Dive center
                </Typography>
              </Grid>
              <Grid item xs={12} mb={2}>
                <SearchableSelect
                  name="dive_center_id"
                  options={diveCenters}
                  getList={getDiveCenters}
                  count={diveCentersCount}
                  label="Select option"
                  className="searchable-select"
                  onChange={(val) => {
                    setDiveCenter(val);
                    setBranch(null);
                    setBranches([]);
                  }}
                  value={diveCenter}
                  onClear={() => {
                    setDiveCenter(null);
                    setBranch(null);
                    setBranches([]);
                  }}
                  onMenuOpen={() =>
                    diveCenters.length === 0 && getDiveCenters()
                  }
                />
              </Grid>

              <Grid item xs={12}>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: 500,
                  }}
                >
                  Branch
                </Typography>
              </Grid>
              <Grid item xs={12} mb={2}>
                <SearchableSelect
                  name="branch_id"
                  options={branches}
                  getList={getBranches}
                  count={branchesCount}
                  label="Select option"
                  className="searchable-select"
                  onChange={(val) => setBranch(val)}
                  value={branch}
                  onClear={() => setBranch(null)}
                  moreFilterData={{ dive_center_id: diveCenter?.value }}
                  onMenuOpen={() => {
                    branches.length === 0 &&
                      diveCenter?.value &&
                      getBranches({
                        page_number: 1,
                        dive_center_id: diveCenter.value,
                      });
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: 500,
                  }}
                >
                  Status
                </Typography>
              </Grid>
              <Grid item xs={12} mb={2}>
                <SearchableSelect
                  name="account_status_id"
                  options={accountStatusList}
                  label="Select option"
                  className="searchable-select"
                  onChange={(val) => setAccountStatus(val)}
                  value={accountStatus}
                  onClear={() => setAccountStatus(null)}
                />
              </Grid>

              <Grid item xs={12}>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: 500,
                  }}
                >
                  Date
                </Typography>
              </Grid>
              <Grid item xs={5.75} height={"fit-content"}>
                {/* <div style={{ position: "relative", display: "inline-block" }}> */}
                <BasicDatePicker
                  placeholder={"Start Date"}
                  value={startDate}
                  className="border-r-10px basic-date-picker"
                  setValue={setStartDate}
                />
                {/* {startDate && (
                  <IconButton
                    style={{
                      position: "absolute",
                      top: "0.5rem",
                      margin: "auto",
                      right: "1.8rem",
                    }}
                    onClick={() => setStartDate(null)}
                  >
                    <ClearIcon sx={{ color: "#faa100" }} />
                  </IconButton>
                )} */}
                {/* </div> */}
              </Grid>
              <Grid item xs={0.5}></Grid>
              <Grid item xs={5.75} height={"fit-content"}>
                {/* <div style={{ position: "relative", display: "inline-block" }}> */}
                <BasicDatePicker
                  placeholder={"End Date"}
                  value={endDate}
                  className="border-r-10px basic-date-picker"
                  setValue={setEndDate}
                />
                {/* {endDate && (
                  <IconButton
                    style={{
                      position: "absolute",
                      top: "0.5rem",
                      margin: "auto",
                      right: "1.8rem",
                    }}
                    onClick={() => setEndDate(null)}
                  >
                    <ClearIcon sx={{ color: "#faa100" }} />
                  </IconButton>
                )} */}
                {/* </div> */}
              </Grid>

              <Grid item xs={12}>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: 500,
                  }}
                >
                  Discount percentage
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <TextFieldInput
                  placeholder="From"
                  type="number"
                  className="reservation-search"
                  value={from}
                  onChange={(e) => setFrom(e.target.value)}
                  style={{ paddingInlineEnd: "4px" }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextFieldInput
                  placeholder="To"
                  type="number"
                  className="reservation-search"
                  value={to}
                  onChange={(e) => setTo(e.target.value)}
                  style={{ paddingInlineStart: "4px" }}
                />
              </Grid>

              <Grid
                item
                xs={6}
                mt={2}
                mb={2.25}
                sx={{ paddingInlineEnd: "10px" }}
              >
                <PrimaryButton
                  text="Reset"
                  classBtn="reset-btn"
                  click={handleResetFilterMenu}
                />
              </Grid>
              <Grid
                item
                xs={6}
                mt={2}
                mb={2.25}
                sx={{ paddingInlineStart: "10px" }}
              >
                <PrimaryButton
                  text="Apply"
                  classBtn="apply-btn"
                  click={handleApplyFilterMenu}
                />
              </Grid>
            </Grid>
          </Menu>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default ActivityOffersHeader;

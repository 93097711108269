import React, { useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import CCrud from "../../../../Components/Molecules/General/Crud/CCrud";
import "../../../../Components/Atoms/Shared/Styles/list-page.css";
import "../../../../Components/Atoms/Shared/Styles/form-page.css";
import "./OrganizationCertificates.css";
import { initialValues, structure, validationSchema } from "./structure";
import AddIcon from "@mui/icons-material/Add";
import LanguagesSelect from "../../../../Components/Molecules/Forms/LanguagesSelect";
import { Grid, Link, Stack, Typography } from "@mui/material";
import CustomSeparator from "../../../../Components/Atoms/BreadCrumb";
import Languages from "../../../../Components/Atoms/Shared/language/Language";

const OrganizationCertificatesTemplate = ({
  filterObj,
  setFilterObj,
  data,
  count,
  paginationPage,
  setPaginationPage,
  handlePageChange,
  openDeleteModal,
  setOpenDeleteModal,
  handleDeleteRequest,
  selectedRow,
  setSelectedRow,
  handleSubmit,
  rowData,
  certificateData,
  roles
}) => {
  const searchInputPlaceholder = "Search organization certificate";
  let [searchParams] = useSearchParams();
  const type = searchParams.get("type");
  const index = searchParams.get("index");
  const navigate = useNavigate();
  const organizationName = searchParams.get("orgName");
  const { organizationId } = useParams();
  const [supportedLang, setSupportedLang] = useState("en");

  const actionsList = ({ id, index, listingPath }) => {
    return [
      {
        condition: () => {
          if (roles?.can_read && roles?.can_update) {
            return true;
          } else {
            return false;
          }
        },
        click: () => {
          setSupportedLang("en");
          navigate(
            `?orgName=${organizationName}&type=form&id=${id}&index=${index}`
          );
        },
        text: "Edit",
      },
      {
        condition: () => {
          if (roles?.can_read && roles?.can_delete) {
            return true;
          } else {
            return false;
          }
        },
        click: () => {
          setOpenDeleteModal(true);
        },
        text: "Delete",
      },
    ];
  };

  const filterList = [
    {
      key: "is_professional",
      title: "Is professional",
      list: [
        { id: "true", name: "Yes" },
        { id: "false", name: "No" },
      ],
    },
    {
      key: "is_hidden",
      title: "Is hidden",
      list: [
        { id: "true", name: "Yes" },
        { id: "false", name: "No" },
      ],
    },
  ];

  const handleApplyFilterMenu = (obj) => {
    setFilterObj((prev) => {
      return { ...prev, ...obj, page_number: 1 };
    });
    setPaginationPage(1);
  };

  const handleResetFilterMenu = () => {
    setFilterObj({
      ...filterObj,
      page_number: 1,
      is_professional: null,
      is_hidden: null,
    });
    setPaginationPage(1);
  };
  const breadcrumbs = [
    {
      path:
        index === null && type !== "form"
          ? "/organizations"
          : `/organizations/${organizationId}/certificates?orgName=${organizationName}`,
      active: true,
      title:
        index === null && type !== "form"
          ? "Organizations"
          : `${organizationName} certificates`,
    },

    {
      active: false,
      title:
        index === null && type !== "form"
          ? `${organizationName} certificates`
          : index === null && type === "form"
          ? "Add new organization certificate"
          : index !== null && type === "form"
          ? "Edit organization certificate"
          : null,
    },
  ];
  return (
    <>
      <Stack
        direction="row"
        alignSelf="start"
        justifyContent="start"
        gap={2}
        width="100%"
        mb={1}
      >
        <CustomSeparator breadcrumbs={breadcrumbs} />
      </Stack>
      <CCrud
        table={{
          list: data,
          openDeleteModal: openDeleteModal,
          setOpenDeleteModal: setOpenDeleteModal,
          selectedRow: selectedRow,
          setSelectedRow: setSelectedRow,
          listTitle: `${organizationName} certificates`,
          emptyListMsg: "There are no certificates",
          listingPath: "/organizations/:organizationId/certificates",
          actionsList: actionsList,
          columns: [
            { accessor: "id", Header: "Id" },
            { accessor: "name", Header: "Certificate name" },
            { accessor: "is_professional", Header: "Is professional" },
            { accessor: "is_hidden", Header: "Is hidden" },
            { accessor: "Actions", Header: "Actions" },
          ],
          isFilter: true,
          isSearch: true,
          isChecked: false,
          isSort: false,
          total_count: count,
          filterList,
          setFilterObj,
          createBtnTitle: "Add  new organization certificate",
          createBtnFun: () => {
            setSupportedLang("en");
            navigate(`?orgName=${organizationName}&type=form`);
          },
          CreateBtnIcon: AddIcon,
          hideCreateBtn: roles?.can_read && roles?.can_create ? null : true,
          createBtnClass: "add-organization-button",
          className: "organization-table organization-certificates-table",
          paginationPage: paginationPage,
          setPaginationPage: setPaginationPage,
          searchInputPlaceholder: searchInputPlaceholder,
          handleApplyFilterMenu,
          handleResetFilterMenu,
          handlePageChange,
          openDeleteModal: openDeleteModal,
          setOpenDeleteModal: setOpenDeleteModal,
          handleDeleteRequest,
          rowData: rowData,
        }}
        form={{
          structure: () => structure(supportedLang, organizationName),
          initialValues: !index ? {} : initialValues(certificateData),
          validationSchema: validationSchema(),
          onSubmit: handleSubmit,
          setSupportedLang: setSupportedLang,
          submitTitle: index === null ? "Add" : "Save Changes",
          title:
            index === null
              ? "Add new organization certificate"
              : "Edit organization certificate",
          className: {
            form: "organization-form flow-form",
            actionButton: "organization-form-buttons flow-form-buttons",
          },
          handleLanguage: <Languages setLanguage={setSupportedLang} />,
        }}
      />
    </>
  );
};

export default OrganizationCertificatesTemplate;

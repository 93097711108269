import { Box, Grid, Rating, Stack, Typography } from "@mui/material";
import React, { useEffect } from "react";
import CustomSeparator from "../../Atoms/BreadCrumb";
import { Link, useNavigate } from "react-router-dom";
import FormHead from "../../Atoms/FormHead/FormHead";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import CircleIcon from "@mui/icons-material/Circle";
import CheckIcon from "@mui/icons-material/Check";
import BranchDetailsHeader from "../../Molecules/Branches/BranchDetailsHeader/BranchDetailsHeader";
import { styled } from "@mui/material/styles";
import Carousel from "../../Atoms/Carousel/Carousel";
import Map from "../../Molecules/Maps/GoogleMap";
import { useState } from "react";
import "./BranchDetails.css";
import { icons } from "../../../Assets/AssetHelper";
import moment from "moment";

function BranchDetails(props) {
  const { branchData, getBranch, roles } = props;
  const branchType = branchData?.branch_type;
  const navigate = useNavigate();

  const StyledRating = styled(Rating)({
    "& .MuiRating-iconFilled": {
      color: "#3ed181",
    },
    "& .MuiRating-iconHover": {
      color: "#3ed181",
    },
  });

  const ImagesResponsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 7,
      partialVisibilityGutter: 50,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 6,
      partialVisibilityGutter: 0,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
      partialVisibilityGutter: 20,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      partialVisibilityGutter: 20,
    },
  };

  const [features, setFeature] = useState([]);

  useEffect(() => {
    if (branchData?.branch_features?.length > 0) {
      // Map over each feature and transform it into the desired structure
      const updatedFeatures = branchData?.branch_features?.map(feature => ({
        title: feature?.name,
        status: feature?.enabled, 
      }));
      setFeature(updatedFeatures);
    } else {
      setFeature([]);
    }
  }, [branchData]);

  const info =
    branchType === "safari_boat"
      ? [
          { title: "built year/Renovated", val: `${moment(branchData?.boat?.year_built).format("DD-MM-YYYY")} / ${moment(branchData?.boat?.year_renovated).format("DD-MM-YYYY")}` },
          { title: "No. of cabins", val: branchData?.boat?.total_cabins },
          { title: "Length", val: branchData?.boat?.length },
          { title: "Width", val: branchData?.boat?.width },
        ]
      : null;

  const licensArr = [ 
    {
      url: branchData?.local_gov_license?.url,
      title: "Governmental license",
      name: branchData?.local_gov_license?.file_name,
    },
  ];

  branchData?.branch_organizations?.map(val => (
    licensArr.push(
      {
        url: val?.license_url,
        title: "License",
        name: val?.license_number,
      }
    )
  ))

  const [location, setLocation] = useState({
    lat: "",
    lng: "",
  });
  const getCurrentLocation = (lat, lng) => {
    setLocation({
      lat: lat,
      lng: lng,
    });
  };

  const branchesImages = branchData?.media?.filter(
    (img) => img?.media_option == "default"
  );

  const breadcrumbs = [
    { path: "/operators/main", active: true, title: "Operators" },
    {
      path: `/operators/branches`,
      active: true,
      title: `Branches`,
    },
    { active: false, title: branchData?.name + "details" },
  ];
  return (
    <Stack gap={3}>
      <Grid container item sm={12}>
        <CustomSeparator breadcrumbs={breadcrumbs} />
      </Grid>

      <BranchDetailsHeader
        data={branchData}
        getBranch={getBranch}
        branchType={branchType}
        roles={roles}
      />

      <Stack
        mt="-25px"
        bgcoloe="white"
        padding="53.8px"
        className="details-panel"
        flexDirection="row"
        justifyContent="space-between"
        gap="1rem"
        boxShadow="0 2px 15px 0 rgba(0, 0, 0, 0.1)"
        overflow="auto"
      >
        <Stack gap={2} width="70%">
          <Stack bgcolor="rgba(0, 0, 0, 0.01)" borderRadius="10px" p={2}>
            <Box position="relative">
              <Grid container gap={1}>
                <Grid item md={12}>
                  <FormHead text={"Rating and review"} />
                </Grid>
                <Grid item md={5.5}>
                  <Stack
                    flexDirection="column"
                    gap={1}
                    sx={{ cursor: "pointer" }}
                    onClick={() =>
                      navigate(
                        `/operators/branches/${branchData?.id}/reviews?type=Customer`
                      )
                    }
                  >
                    <Typography
                      fontSize="18px"
                      fontWeight="bold"
                      color="#bfbfbf"
                    >
                      Customer rating and review
                    </Typography>
                    <Stack flexDirection="row" gap={2} alignItems="center">
                      <StyledRating
                        name="customized-color"
                        value={branchData?.customer_avg_rate}
                        readOnly={true}
                        icon={
                          <CircleIcon
                            fontSize="inherit"
                            style={{ fontSize: "12px" }}
                          />
                        }
                        emptyIcon={
                          <CircleOutlinedIcon
                            fontSize="inherit"
                            style={{ fontSize: "12px", color: "#3ed181" }}
                          />
                        }
                      />
                      <Typography
                        fontSize="20px"
                        fontWeight="600"
                        color="#3ed181"
                      >{`(  ${branchData?.customer_reviews_count} 
                                            ${
                                              branchData?.customer_reviews_count ===
                                              1
                                                ? "  review  "
                                                : "  reviews  "
                                            })`}</Typography>
                    </Stack>
                  </Stack>
                </Grid>
                <Grid item md={5.5}>
                  <Stack
                    flexDirection="column"
                    gap={1}
                    sx={{ cursor: "pointer" }}
                    onClick={() =>
                      navigate(
                        `/operators/branches/${branchData?.id}/reviews?type=DiveExpert`
                      )
                    }
                  >
                    <Typography
                      fontSize="18px"
                      fontWeight="bold"
                      color="#bfbfbf"
                    >
                      Freelancer : rating and review
                    </Typography>
                    <Stack flexDirection="row" gap={2} alignItems="center">
                      <StyledRating
                        name="customized-color"
                        value={branchData?.dive_expert_avg_rate}
                        readOnly={true}
                        icon={
                          <CircleIcon
                            fontSize="inherit"
                            style={{ fontSize: "12px" }}
                          />
                        }
                        emptyIcon={
                          <CircleOutlinedIcon
                            fontSize="inherit"
                            style={{ fontSize: "12px", color: "#3ed181" }}
                          />
                        }
                      />
                      <Typography
                        fontSize="20px"
                        fontWeight="600"
                        color="#3ed181"
                      >{`(  ${branchData?.dive_expert_reviews_count}
                                            ${
                                              branchData?.dive_expert_reviews_count ===
                                              1
                                                ? "  review  "
                                                : "  reviews  "
                                            })`}</Typography>
                    </Stack>
                  </Stack>
                </Grid>
              </Grid>
            </Box>
          </Stack>

          <Stack bgcolor="rgba(0, 0, 0, 0.01)" borderRadius="10px" p={2}>
            <FormHead text={"Branch images"} />

            {branchesImages?.length >= 1 ? (
              <Carousel
                swipeable={true}
                draggable={true}
                showDots={false}
                responsive={ImagesResponsive}
                autoPlaySpeed={1000}
                containerClass={"carousel-branch"}
                itemClass={"carousel-branch-item"}
                partialVisible={true}
                arrows={false}
              >
                {
                  // [...branchData?.media, ...branchData?.media, ...branchData?.media].map((data ,index) => {
                  branchesImages?.map((data, index) => {
                    return (
                      <Box
                        position="relative"
                        width="100%"
                        height="100%"
                        key={index}
                      >
                        <img
                          src={data?.url}
                          width="100%"
                          height="100%"
                          style={{ borderRadius: "15px" }}
                          draggable={false}
                        />
                      </Box>
                    );
                  })
                }
              </Carousel>
            ) : null}
          </Stack>

          <Stack bgcolor="rgba(0, 0, 0, 0.01)" borderRadius="10px" p={2}>
            <FormHead text={"Certificates"} />
            <Stack flexDirection="row" gap={2}>
              {licensArr?.map((val, index) => {
                if (val?.url)
                  return (
                    <a
                      href={val?.url}
                      download
                      style={{ textDecoration: "none", width: "158px" }}
                    >
                      <Stack width="100%" gap={1} key={index}>
                        <Stack
                          border="1px solid #f6b119"
                          gap={1}
                          height="124px"
                          borderRadius="6px"
                          alignItems="center"
                          justifyContent="center"
                        >
                          <img
                            src={icons?.Download}
                            width="40.8px"
                            height="40.8px"
                          />
                          <Typography color="#f6b119" fontSize="14px">
                            {" "}
                            Download{" "}
                          </Typography>
                        </Stack>

                        <Typography
                          textAlign="center"
                          color="#333132"
                          fontSize="14px"
                          fontWeight="bold"
                        >
                          {val?.title}
                        </Typography>
                        <Typography
                          textAlign="center"
                          color="#bebebe"
                          fontSize="14px"
                        >
                          {val?.name}
                        </Typography>
                      </Stack>
                    </a>
                  );
              })}

              {
                licensArr?.filter(item => item?.url == null)?.length == licensArr?.length && (
                  <Typography
                    textAlign="center"
                    color="#333132"
                    fontSize="14px"
                    fontWeight="bold"
                  >
                    No Certificates
                  </Typography>
                )
              }
            </Stack>
          </Stack>

          <Stack bgcolor="rgba(0, 0, 0, 0.01)" borderRadius="10px" p={2}>
            <Box position="relative">
              <Grid container gap={1}>
                <Grid item md={branchType === "office" ? 12 : 5.5}>
                  <FormHead
                    text={
                      branchType === "office"
                        ? "Branch features"
                        : "Safari boat features"
                    }
                  />
                  {features?.map((data, index) => {
                    return (
                      <Stack
                        flexDirection="row"
                        gap={1}
                        my={1}
                        key={index}
                        className="details-info"
                      >
                        {data?.status ? (
                          <CheckIcon
                            fontSize="1.1rem"
                            style={{ color: "#f6b119" }}
                          />
                        ) : (
                          <CloseOutlinedIcon
                            fontSize="1.1rem"
                            style={{ color: "#e10007" }}
                          />
                        )}
                        <Typography className="details-text">
                          {data?.title}
                        </Typography>
                      </Stack>
                    );
                  })}
                </Grid>
                {branchType === "safari_boat" && (
                  <Grid item md={5.5}>
                    <FormHead text={"Safari boat information"} />
                    {info !== null &&
                      info.map((data, index) => {
                        return (
                          <Grid
                            container
                            flexDirection="row"
                            justifyContent={"space-between"}
                            my={1}
                            key={index}
                            gap={0.5}
                          >
                            <Grid item md={5.0}>
                              <Typography
                                color="#333132"
                                className="details-text"
                              >
                                {data?.title}
                              </Typography>
                            </Grid>
                            <Grid item md={6.0}>
                              <Typography
                                color="#bebebe"
                                className="details-text"
                              >
                                {data?.val}
                              </Typography>
                            </Grid>
                          </Grid>
                        );
                      })}
                  </Grid>
                )}
              </Grid>
            </Box>
          </Stack>

          {branchType === "safari_boat" && (
            <Stack bgcolor="rgba(0, 0, 0, 0.01)" borderRadius="10px" p={2}>
              <FormHead text={"Additional information"} />
              <Typography fontSize="18px" lineHeight="1.67" color="#333132">
                {branchData?.about_us}
              </Typography>
            </Stack>
          )}

          {/* <Stack flexDirection='row' gap='1rem 3rem' padding='1rem 0rem 2rem' className='details-time details-time-lg'>
                    <Stack flexDirection='row' gap={1}>
                        <Typography color='#333132' className='details-text-bold'>Created at : </Typography>                    
                        {created != 'Invalid Date' ? <Typography color='#333132' className='details-text-bold'>{`${created?.getFullYear()}-${handleDigits(created?.getMonth()+1)}-${handleDigits(created?.getDate()+1)} ${handleDigits(created?.getHours()+1)}:${handleDigits(created?.getMinutes()+1)}`}</Typography> : null}
                    </Stack>
                    <Stack flexDirection='row' gap={1}>
                        <Typography color='#333132' className='details-text-bold'>Updated at : </Typography>                    
                        {updated != 'Invalid Date' ? <Typography color='#333132' className='details-text-bold'>{`${updated?.getFullYear()}-${handleDigits(updated?.getMonth()+1)}-${handleDigits(updated?.getDate()+1)} ${handleDigits(updated?.getHours()+1)}:${handleDigits(updated?.getMinutes()+1)}`}</Typography> : null}
                    </Stack>
                    <Stack flexDirection='row' gap={1}>
                        <Typography color='#333132' className='details-text-bold'>approved at : </Typography>                    
                        {approved != 'Invalid Date' ? <Typography color='#333132' className='details-text-bold'>{`${approved?.getFullYear()}-${handleDigits(approved?.getMonth()+1)}-${handleDigits(approved?.getDate()+1)} ${handleDigits(approved?.getHours()+1)}:${handleDigits(approved?.getMinutes()+1)}`}</Typography> : null}
                    </Stack>
                </Stack> */}
        </Stack>

        <Stack gap={2} width="30%">
          <Stack bgcolor="rgba(0, 0, 0, 0.01)" borderRadius="10px" p={2}>
            <FormHead text={"branch name in different languages"} />

            <Stack flexDirection="row" gap={1} mb={2}>
              <Typography color="#333132" className="details-text">
                English name :{" "}
              </Typography>
              <Typography color="#333132" className="details-text">
                {branchData?.localized_data?.en?.name
                  ? branchData?.localized_data?.en?.name
                  : "Empty"}
              </Typography>
            </Stack>
            <Stack flexDirection="row" gap={1} mb={2}>
              <Typography color="#333132" className="details-text">
                German name :{" "}
              </Typography>
              <Typography color="#333132" className="details-text">
                {branchData?.localized_data?.de?.name
                  ? branchData?.localized_data?.de?.name
                  : "Empty"}
              </Typography>
            </Stack>
            <Stack flexDirection="row" gap={1} mb={2}>
              <Typography color="#333132" className="details-text">
                French :{" "}
              </Typography>
              <Typography color="#333132" className="details-text">
                {branchData?.localized_data?.fr?.name
                  ? branchData?.localized_data?.fr?.name
                  : "Empty"}
              </Typography>
            </Stack>
            <Stack flexDirection="row" gap={1} mb={2}>
              <Typography color="#333132" className="details-text">
                Italian name :{" "}
              </Typography>
              <Typography color="#333132" className="details-text">
                {branchData?.localized_data?.it?.name
                  ? branchData?.localized_data?.it?.name
                  : "Empty"}
              </Typography>
            </Stack>
            <Stack flexDirection="row" gap={1} mb={2}>
              <Typography color="#333132" className="details-text">
                Dutch name :{" "}
              </Typography>
              <Typography color="#333132" className="details-text">
                {branchData?.localized_data?.nl?.name
                  ? branchData?.localized_data?.nl?.name
                  : "Empty"}
              </Typography>
            </Stack>
          </Stack>

          <Stack bgcolor="rgba(0, 0, 0, 0.01)" borderRadius="10px" p={2}>
            <FormHead text={"Branch information"} />

            <Grid
              container
              flexDirection="row"
              justifyContent={"space-between"}
              mb={2}
            >
              <Grid item md={5.5}>
                <Typography color="#333132" className="details-text">
                  Insurance name
                </Typography>
              </Grid>
              <Grid item md={5.5}>
                <Typography color="#bebebe" className="details-text">
                  {branchData?.insurance_name}
                </Typography>
              </Grid>
            </Grid>

            <Grid
              container
              flexDirection="row"
              justifyContent={"space-between"}
              mb={2}
            >
              <Grid item md={5.5}>
                <Typography color="#333132" className="details-text">
                  Insurance policy No.
                </Typography>
              </Grid>
              <Grid item md={5.5}>
                <Typography color="#bebebe" className="details-text">
                  {branchData?.policy_number}
                </Typography>
              </Grid>
            </Grid>

            <Grid
              container
              flexDirection="row"
              justifyContent={"space-between"}
              mb={2}
            >
              <Grid item md={5.5}>
                <Typography color="#333132" className="details-text">
                  Branch Manager
                </Typography>
              </Grid>
              <Grid item md={5.5}>
                <Typography color="#bebebe" className="details-text">
                  {branchData?.manager_name}
                </Typography>
              </Grid>
            </Grid>

            <Grid
              container
              flexDirection="row"
              justifyContent={"space-between"}
              mb={2}
            >
              <Grid item md={5.5}>
                <Typography color="#333132" className="details-text">
                  Branch number
                </Typography>
              </Grid>
              <Grid item md={5.5}>
                <Typography color="#bebebe" className="details-text">
                  {branchData?.branch_number}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              flexDirection="row"
              justifyContent={"space-between"}
              mb={2}
            >
              <Grid item md={5.5}>
                <Typography color="#333132" className="details-text">
                  Lat
                </Typography>
              </Grid>
              <Grid item md={5.5}>
                <Typography color="#bebebe" className="details-text">
                  {branchData?.lat}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              flexDirection="row"
              justifyContent={"space-between"}
              mb={2}
            >
              <Grid item md={5.5}>
                <Typography color="#333132" className="details-text">
                  Long
                </Typography>
              </Grid>
              <Grid item md={5.5}>
                <Typography color="#bebebe" className="details-text">
                  {branchData?.long}
                </Typography>
              </Grid>
            </Grid>
          </Stack>

          <Stack
            bgcolor="rgba(0, 0, 0, 0.01)"
            borderRadius="10px"
            p={2}
            className="map-wrap"
          >
            <Map
              getCurrentLocation={getCurrentLocation}
              lat={branchData?.lat}
              lng={branchData?.long}
              type="view"
            />
            {branchData?.full_address?.length !== 0 && (
              <Typography className="map-wrap-address">
                <img src={icons.Location} alt="location" />
                {branchData?.full_address}
              </Typography>
            )}
          </Stack>

          <Stack
            bgcolor="rgba(0, 0, 0, 0.01)"
            borderRadius="10px"
            p={2}
            className="organizations-wrap"
          >
            <FormHead text={"Organizations"} />

            {branchData?.organizations?.map((org, index) => {
              return (
                <Typography
                  color="#333132"
                  className="details-text"
                  key={index}
                >
                  {org.name}
                </Typography>
              );
            })}
          </Stack>

          {/* <Stack flexDirection='row' gap='1rem 3rem' padding='1rem 0rem 2rem' className='details-time details-time-sm'>
                    <Stack flexDirection='row' gap={1}>
                        <Typography color='#333132' className='details-text-bold'>Created at : </Typography>                    
                        {created != 'Invalid Date' ? <Typography color='#333132' className='details-text-bold'>{`${created?.getFullYear()}-${handleDigits(created?.getMonth()+1)}-${handleDigits(created?.getDate()+1)} ${handleDigits(created?.getHours()+1)}:${handleDigits(created?.getMinutes()+1)}`}</Typography> : null}
                    </Stack>
                    <Stack flexDirection='row' gap={1}>
                        <Typography color='#333132' className='details-text-bold'>Updated at : </Typography>                    
                        {updated != 'Invalid Date' ? <Typography color='#333132' className='details-text-bold'>{`${updated?.getFullYear()}-${handleDigits(updated?.getMonth()+1)}-${handleDigits(updated?.getDate()+1)} ${handleDigits(updated?.getHours()+1)}:${handleDigits(updated?.getMinutes()+1)}`}</Typography> : null}
                    </Stack>
                    <Stack flexDirection='row' gap={1}>
                        <Typography color='#333132' className='details-text-bold'>approved at : </Typography>                    
                        {approved != 'Invalid Date' ? <Typography color='#333132' className='details-text-bold'>{`${approved?.getFullYear()}-${handleDigits(approved?.getMonth()+1)}-${handleDigits(approved?.getDate()+1)} ${handleDigits(approved?.getHours()+1)}:${handleDigits(approved?.getMinutes()+1)}`}</Typography> : null}
                    </Stack>
                </Stack> */}
        </Stack>
      </Stack>
    </Stack>
  );
}

export default BranchDetails;

import React from 'react'
import JobOfferDetails from '../../../Components/Organisms/JobOffers/JobOfferDetails'

function JobOfferDetailsTemplate({jobOfferData, supportedLanguages, updateSupportedLang, supportLang, roles}) {
  return (
    <JobOfferDetails 
        jobOfferData={jobOfferData}
        supportedLanguages={supportedLanguages}
        updateSupportedLang={updateSupportedLang}
        supportLang={supportLang}
        roles={roles}
    />
  )
}

export default JobOfferDetailsTemplate
import { Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import PrimaryButton from "../../../../Atoms/Buttons/Primary/PrimaryButton";
import { icons } from "../../../../../Assets/AssetHelper";
import DeleteModal from "../../../General/Models/DeleteModal";
import "./RolesList.css";
import { deleteRole } from "../../../../../Contexts/APIs/Users/Roles";
import CustomPagination from "../../../../Atoms/Pagination/Pagination";

function RolesList({
  rolesData,
  setRolesData,
  setOpenLoader,
  count,
  page,
  handlePageChange,
  usersPrivilages,
}) {
  const [openDeleteModel, setOpenDeleteModel] = useState(false);
  const [selectedRole, setSelectedRole] = useState(false);
  const navigate = useNavigate();
  const userType = localStorage.getItem("userType");

  const handelDeleteRole = async () => {
    const { res, err } = await deleteRole(selectedRole?.role?.id);
    setOpenLoader(true);
    if (res) {
      let newData = rolesData?.filter(
        (val) => val?.id != selectedRole?.role?.id
      );
      setRolesData(newData);
      setOpenLoader(false);
    }
  };

  return (
    <Grid container>
      <Grid container className="table-header mb-20px">
        <Grid item md={0.5}></Grid>
        <Grid item md={9.5}>
          User role
        </Grid>
        {!usersPrivilages?.can_read &&
        !usersPrivilages?.can_update &&
        !usersPrivilages?.can_delete ? null : (
          <Grid item sm={2} sx={{ pl: 1 }}>
            Action
          </Grid>
        )}
      </Grid>

      {rolesData?.length === 0 && (
        <Grid container display="block" textAlign="center">
          <img src={icons.Roles} />
          <Typography fontSize="30px" fontWeight="bold" color="#333132">
            No roles addded yet
          </Typography>
        </Grid>
      )}

      {rolesData?.length >= 1 && (
        <>
          {rolesData?.map((item, index) => {
            return (
              <Grid
                key={index}
                container
                item
                lg={12}
                className="role-item mb-20px"
                onClick={() => setSelectedRole({ role: item, index: index })}
              >
                <Grid item className="role-item-no" md={0.5} textAlign="center">
                  {item.id}
                </Grid>
                <Grid item className="role-item-name" md={9.5}>
                  {item.name}
                </Grid>
                <Grid item container sm={2} columnGap={1}>
                  <Grid item xs={3} textAlign="center">
                    {userType !== "descendant_user" && (
                      <PrimaryButton
                        classBtn="role-item-button"
                        btnType="icon"
                        startIcon={
                          <img width="35" src={icons.EditIcon} alt="edit" />
                        }
                        click={() =>
                          navigate("/users/roles/edit/" + `${item.id}`)
                        }
                      />
                    )}
                    {userType === "descendant_user" &&
                      usersPrivilages?.can_read &&
                      usersPrivilages?.can_update && (
                        <PrimaryButton
                          classBtn="role-item-button"
                          btnType="icon"
                          startIcon={
                            <img width="35" src={icons.EditIcon} alt="edit" />
                          }
                          click={() =>
                            usersPrivilages?.can_read &&
                            usersPrivilages?.can_update
                              ? navigate("/users/roles/edit/" + `${item.id}`)
                              : null
                          }
                        />
                      )}
                  </Grid>
                  <Grid item xs={3} textAlign="center">
                    {userType !== "descendant_user" && (
                      <PrimaryButton
                        classBtn="role-item-button"
                        btnType="icon"
                        startIcon={
                          <img width="35" src={icons.DeleteIcon} alt="delete" />
                        }
                        click={() => setOpenDeleteModel(true)}
                      />
                    )}
                    {userType === "descendant_user" &&
                      usersPrivilages?.can_read &&
                      usersPrivilages?.can_delete && (
                        <PrimaryButton
                          classBtn="role-item-button"
                          btnType="icon"
                          startIcon={
                            <img
                              width="35"
                              src={icons.DeleteIcon}
                              alt="delete"
                            />
                          }
                          click={() =>
                            usersPrivilages?.can_read &&
                            usersPrivilages?.can_delete
                              ? setOpenDeleteModel(true)
                              : null
                          }
                        />
                      )}
                  </Grid>
                </Grid>
              </Grid>
            );
          })}
          {count > 10 && (
            <Grid
              container
              item
              justifyContent={"center"}
              xs={12}
              mb={7.5}
              mt={5}
            >
              <CustomPagination
                count={Math.ceil(count / 10)}
                size="large"
                shape="rounded"
                page={page}
                handleChange={handlePageChange}
                className="pagination"
              />
            </Grid>
          )}
        </>
      )}

      {openDeleteModel && (
        <DeleteModal
          open={openDeleteModel}
          setOpen={setOpenDeleteModel}
          handelDeleteRequest={() => {
            handelDeleteRole(selectedRole?.role?.id);
            setOpenDeleteModel(false);
          }}
          text={[
            `Delete ${selectedRole?.role?.name}`,
            `Are you sure that, you want to delete ${selectedRole?.role?.name}?`,
          ]}
        />
      )}
    </Grid>
  );
}
export default RolesList;

import { Box, FormControl, IconButton, InputLabel, MenuItem, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import CFormErrors from "./CFormErrors";
import Select from "react-select";
import ClearIcon from '@mui/icons-material/Clear';

const SelectOptions = ({
  options,
  changeAction,
  onChange,
  onClear,
  label,
  formik,
  name,
  index,
  value,
  count,
  getList,
  moreFilterData,
  moreResetData,
  onOpen,
  ...rest
}) => {
  const errorsMsg = formik?.errors[name];
  const [keyword, setKeyword] = useState(null);
  const [useEffectTog , setUseEffectTog] = useState('default');  

  const handelOptions = options?.map((val) => {
    return {
      label: val?.name,
      value: val?.id,
      color: val?.color,
    };
  });
  const valueData =
    typeof formik?.values[name] != "object"
      ? handelOptions?.find((val) => val?.value == formik?.values[name])
      : formik?.values[name];
  const indexs = {
    0: 10,
    1: 9,
    2: 8,
    3: 7,
    4: 6,
    5: 5,
    6: 4,
    7: 3,
    8: 2,
    9: 1,
    10: 0,
  };
  const textfield = {
    position: "absolute",
    top: "-12px",
    fontSize: "13px",
    margin: "0 20px",
    background: "#fff",
    zIndex: "99",
    padding: "0 10px",
    color: "black",
  };

  const ClearIndicator = () => {
    return(  
      <IconButton
        btnType="icon"
        onClick={() => {
          setUseEffectTog('clear');
          setKeyword(null);
          onClear && onClear();
        }}
        fullwidth="true"
        style={{ position: 'absolute', top: 'calc(50% - 10px)', right: '45px', padding: '0', zIndex: '99', width: '20px' }}
      >
        <ClearIcon sx={{
          color: 'hsl(0, 0%, 80%) !important',
          fontSize: '20px !important',
          '&:hover':{color: 'hsl(0, 0%, 60%) !important'},
          '&:focus':{color: 'hsl(0, 0%, 20%) !important'}
        }} />
      </IconButton>
    )
  }

  const handleMenuScrollToBottom = () => {
    if (options?.length < count) {
      getList({
        ...moreFilterData,
        page_number: Math?.floor(options?.length / 10) + 1,
      });
    }
  };
  
  useEffect(() => {
    const timeId = setTimeout(() => {
    if (getList && useEffectTog !== 'default'){
      getList({ keyword: keyword, page_number: 1, ...moreFilterData });
      setUseEffectTog('default');
    }
  }, 700);
  
  return () => {
    clearTimeout(timeId);
  }      
  }, [keyword, moreFilterData]);

  return (
    <Stack
      w={"100%"}
      p="0"
      justifyContent="center"
      flexDirection={"column"}
      position="relative"
      zIndex={`${100 + indexs[index]} !important`}
      // loadingMessage={() => <p>loading...</p>}
      onKeyDown={(e) => e.stopPropagation()}
    >
      <FormControl fullWidth>
        {valueData || value
          ? label && <span style={textfield}>{label}</span>
          : null}

        <Select
          id={name}
          name={name}
          options={handelOptions}
          onChange={(value) => {
            changeAction(value);
            onChange && onChange(value);
          }}
          onInputChange={(e) => {
            if(e.length !== 0) {
              setUseEffectTog('search');
              setKeyword(e);
            }
          }}
          value={
            value ? value 
            : (valueData && value === undefined) ? valueData 
            : null
          }
          placeholder={label}
          
          className="search-select"
          onMenuScrollToBottom={handleMenuScrollToBottom}
          onMenuOpen={() => onOpen && onOpen()}
          {...rest}
        />
        { value ? <ClearIndicator /> : null }
      </FormControl>
      <CFormErrors errorsMsg={errorsMsg} />
    </Stack>
  );
};

export default SelectOptions;

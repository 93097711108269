import { Grid, Typography } from '@mui/material'
import React from 'react'
import { icons } from '../../../../Assets/AssetHelper'

const AuthLayout = ({ title, subTitle, children }) => {
    return (
        <Grid container className="login-container">
            <Grid item className="mb-20px" xs={12}>
                <img src={icons.LoginLogo} alt="Logo" />
            </Grid>
            <Grid item className="mb-30px" xs={12}>
                <Typography component="h1" style={{ fontSize: "18px", color: "var(--secound-f-color)" }}>
                    {title}
                </Typography>
                {subTitle && <Typography style={{ fontSize: "30px", color: "#333132", fontWeight: 'bold', marginTop: '10px' }}>
                    {subTitle}
                </Typography>}
            </Grid>

            {children}
        </Grid>

    )
}

export default AuthLayout
import React from "react";
import { useNavigate } from "react-router-dom";
import OperatorForm from "../../Molecules/Forms/OperatorForm";
import { Grid } from "@mui/material";
import PagesHead from "../../Molecules/Users/PagesHead";
import { useRef } from "react";
import FormHead from "../../Atoms/FormHead/FormHead";

function OperatorFormWrap({ formik }) {
  const navigate = useNavigate();
  const navigateToOperators = () => {
    navigate("/operators/main");
  };
  const handleFormSubmit = useRef(null);

  return (
    <Grid container className={"add-operator"}>
      <PagesHead
        title="Add new Operator"
        breadCrumb={{
          link: "Operators",
          path: "/operators/main",
          text: "Add new operators",
        }}
        primaryButtons={[
          {
            fontSize: "14px",
            text: "Cancel",
            classBtn: "primary r-25px primary-text",
            style: { padding: "5px 20px", height: "48px", marginRight: "1rem" },
            click: navigateToOperators,
          },
          {
            fontSize: "14px",
            text: "Add",
            classBtn: "primary r-25px",
            style: { padding: "5px 20px", height: "48px" },
            btnType: "submit",
          },
        ]}
        handelSubmit={() => handleFormSubmit.current()}
      />
      <Grid
        item
        xs={12}
        md={4}
        style={{
          boxShadow: "rgba(0, 0, 0, 0.1) 0px 2px 15px 0px",
          padding: "30px 20px",
        }}
      >
        <FormHead text={"Operator  information"} />
        <OperatorForm handleFormSubmit={handleFormSubmit} formik={formik} />
      </Grid>
    </Grid>
  );
}

export default OperatorFormWrap;

import React from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import CCrud from "../../../../Components/Molecules/General/Crud/CCrud";
import "../../../../Components/Atoms/Shared/Styles/list-page.css";
import { Stack, Typography } from "@mui/material";
import CustomSeparator from "../../../../Components/Atoms/BreadCrumb";

const SalesmanTemplate = ({
  setFilterObj,
  data,
  count,
  paginationPage,
  setPaginationPage,
  handlePageChange,
  rowData,
}) => {
  let [searchParams, setSearchParams] = useSearchParams();
  const index = searchParams.get("index");
  const navigate = useNavigate();
  const breadcrumbs = [
    { active: true, path: "/operators/main", title: "Operators" },
    {
      active: false,
      title: "Salesmen",
    },
  ];
  return (
    <Stack>
      <CustomSeparator breadcrumbs={breadcrumbs} />

      <CCrud
        table={{
          list: data,
          openDeleteModal: false,
          setOpenDeleteModal: () => {},
          rowData: rowData,
          listTitle: <Typography width="400px">Salesmen</Typography>,
          emptyListMsg: "There are no Salesmen",

          columns: [
            { accessor: "id", Header: "Id" },
            { accessor: "image", Header: "Image" },
            { accessor: "name", Header: "Name" },
            { accessor: "email", Header: "Email address" },
            { accessor: "phone", Header: "Mobile phone" },
            { accessor: "status", Header: "Status" },
            { accessor: "action", Header: "Actions" },
          ],
          isFilter: false,
          isSearch: true,
          isChecked: false,
          isSort: false,
          total_count: count,
          setFilterObj,
          createBtnFun: () => {},
          className: "organization-table auto-height",
          paginationPage: paginationPage,
          setPaginationPage: setPaginationPage,
          handlePageChange,
          searchInputPlaceholder: "Search Salesman",
          tableContainerOverflow:'auto'
          // searchAttribute: "salesman_name",
        }}
      />
    </Stack>
  );
};

export default SalesmanTemplate;

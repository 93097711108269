import React from "react";
import LogHistoryTemplate from "../../../Templates/Main/Users/LogHistory.template.jsx";
import { useEffect } from "react";
import { useState } from "react";
import { getUsersList } from "../../../Contexts/APIs/Users/UsersList";
import { getRoleList } from "../../../Contexts/APIs/Users/Roles";
import { downloadLogHistoryCSV, getLogHistory } from "../../../Contexts/APIs/Users/LogHistory.js";
import { useSearchParams } from "react-router-dom";
import usePageFilter from "../../../Components/Atoms/Hooks/usePageFilter.js";

const LogHistoryPage = ({ usersPrivilages, rolesPrivilages }) => {
  const [openLoader, setOpenLoader] = useState(false);
  const [logHistoryList, setLogHistoryList] = useState([]);
  const [rolesData, setRolesData] = useState([]);

  const [count, setCount] = useState(0);
  // const [page, setPage] = useState(1);

  // const [filterObj, setFilterObj] = useState(null);
  const [rolesCount, setRolesCount] = useState(0);
  const defaultFilterObj = {
    page_size: 10,
    page_number: 1,
    keyword: "",
    sort_by: "id"
  };

  const { page, filterObj, handlePageChange ,setFilterObj,setPage} = usePageFilter();

  const getUsers = async (filterObj) => {
    setOpenLoader(true);
    const { res, error } = await getLogHistory({
      ...defaultFilterObj,
      ...filterObj,
    });
    if (res) {
      setOpenLoader(false);
      setLogHistoryList(res?.data?.user_log_histories);
      setCount(res?.extra?.total_count);
    }
    if (error) {
      setOpenLoader(false);
    }
  };

  const getCSV = async () => {
    setOpenLoader(true); // Start the loader

    try {
      const { res, error } = await downloadLogHistoryCSV({
        ...defaultFilterObj,
        ...filterObj,
      });
  
      if (error) {
        console.error('Download failed:', error); // Handle error appropriately
      } else {
        // Create a URL for the downloaded file
        const url = window.URL.createObjectURL(new Blob([res]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'log_history.csv'); // Specify the default file name
  
        // Trigger the download
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (err) {
      console.error('An unexpected error occurred:', err); // Handle unexpected errors
    } finally {
      setOpenLoader(false); // Stop the loader
    }
  };


  useEffect(() => {
    const timeId = setTimeout(() => {
      getUsers(filterObj);
    }, 500);
    return () => clearTimeout(timeId);
  }, [filterObj]);

  const getRolesData = async(data) => {
    setOpenLoader(true);
    const prevRoles = rolesData;
    const DataKey = data?.keyword;
    if((data?.page_number === 1 || data?.page_number === undefined) && !DataKey && rolesData.length === 10){
      setRolesData(prevRoles);      
    }else{
      const { res, error } = (rolesCount !== 10 || DataKey)? await getRoleList({ page_size: 10, ...data }) : {};
      if (res) {      
        (data?.page_number == 1 || data?.page_number == undefined)
        ? setRolesData(res?.data?.roles)
        : setRolesData((s) => [...s, ...res?.data?.roles]);
        setRolesCount(res?.extra?.total_count);
      }
    }
    setOpenLoader(false);
  };
  // useEffect(() => {
  //   getRolesData();
  // }, []);
  return (
    <LogHistoryTemplate
      filterObj={filterObj}
      setFilterObj={setFilterObj}
      logHistoryList={logHistoryList}
      setLogHistoryList={setLogHistoryList}
      openLoader={openLoader}
      setOpenLoader={setOpenLoader}
      count={count}
      page={page}
      setPage={setPage}
      handlePageChange={handlePageChange}
      usersPrivilages={usersPrivilages}
      rolesPrivilages={rolesPrivilages}
      rolesData={rolesData}
      getRolesData={getRolesData}
      rolesCount={rolesCount}
      getCSV={getCSV}
    />
  );
};

export default LogHistoryPage;

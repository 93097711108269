import * as React from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Link } from "react-router-dom";
import { Box } from "@mui/material";

export default function CustomSeparator({ breadcrumbs }) {
  return (
    <Stack
      spacing={2}
      sx={{
        borderBottom: "0 !important",
      }}
    >
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
      >
        {breadcrumbs?.map((data, index) => {
          return (
            <Box key={index}>
              {data?.active ? (
                <Link
                  style={{
                    textDecoration: "none",
                    color: "#bebebe",
                  }}
                  to={data?.path}
                >
                  {data?.title}
                </Link>
              ) : (
                <Typography color="#bebebe"> {data?.title}</Typography>
              )}
            </Box>
          );
        })}
      </Breadcrumbs>
    </Stack>
  );
}

import { Box, Grid, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
// import { checkToken } from '../../../Contexts/APIs/Auth/Login'
import Navbar from "./Navbar";
import SideBar from "./SideBar";

const MainLayout = ({ children, profileData, roles, open, setOpen }) => {
  return (
    <Stack flexDirection="row" width="100%">
      <Box width={open ? "18%" : "5%"} position="relative">
        <SideBar roles={roles} open={open} setOpen={setOpen} />
      </Box>
      <Box
        width={open ? "82%" : "95%"}
        style={{ background: "#fcfcfc"}}
      >
        <Navbar profileData={profileData} />
        <Grid
          sx={{
            minHeight: "90vh",
            padding: "40px 0",
          }}
        >
          {children}
        </Grid>
      </Box>
    </Stack>
  );
};

export default MainLayout;

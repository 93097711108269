import { Box, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import CustomSeparator from "../../Atoms/BreadCrumb";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import FormHead from "../../Atoms/FormHead/FormHead";
import "./OrganizationDetails.css";
import OrganizationDetailsHeader from "../../Molecules/Organizations/OrganizationDetailsHeader";
import LanguagesSelect from "../../Molecules/Forms/LanguagesSelect";
import moment from "moment";

function OrganizationDetails(props) {
  const {
    organizationId,
    organizationData,
    supportedLanguages,
    updateSupportedLang,
    supportLang,
    setOpenDeleteModal,
    roles
  } = props;
  const created = organizationData?.created_at ? moment(organizationData?.created_at).format("DD-MM-YYYY HH:mm") : 'N/A',
    updated = organizationData?.updated_at ? moment(organizationData?.updated_at).format("DD-MM-YYYY HH:mm") : 'N/A',
    navigate = useNavigate();
  let [searchParams] = useSearchParams();
  const index = searchParams.get("index");


  const breadcrumbs = [
    { path: "/organizations", active: true, title: "Organizations" },

    {
      active: false,
      title: organizationData?.localized_data
        ? organizationData?.localized_data[`${supportLang}`]["name"]
        : organizationData?.name + "details",
    },
  ];
  return (
    <Stack gap={3}>
      <Grid container item sm={12}>
        <CustomSeparator breadcrumbs={breadcrumbs} />
      </Grid>

      <Grid container item sm={12} className="select-lang-wrap">
        <LanguagesSelect
          value={supportLang}
          supportedLanguages={supportedLanguages}
          onChange={(e) => updateSupportedLang(e, supportedLanguages)}
          LanguagesSelectHead={"Data Language"}
          LanguagesSelectTip={false}
        />
      </Grid>

      <OrganizationDetailsHeader
        data={organizationData}
        supportLang={supportLang}
        handleEdit={() =>
          navigate(
            `/organizations?type=form&id=${organizationId}&index=${index}`
          )
        }
        handleDelete={() => setOpenDeleteModal(true)}
        roles={roles}
      />
        <Stack
          mt="-25px"
          bgcoloe="white"
          padding="53.8px"
          className="details-panel"
          flexDirection="row"
          justifyContent="space-between"
          gap="1rem"
          boxShadow="0 2px 15px 0 rgba(0, 0, 0, 0.1)"
          overflow="auto"
        >
          <Stack gap={2} width="70%">
            <Stack bgcolor="rgba(0, 0, 0, 0.01)" borderRadius="10px" p={2}>
              <FormHead text={"Customer info"} />
              <Box position="relative">
                <Grid container gap={1}>
                  <Grid item xs={12}>
                    <Grid container gap={"8px 0px"} mb="21px">
                      <Grid item xs={12} md={4}>
                        <Typography
                          fontSize="18px"
                          fontWeight="500"
                          color="#333132"
                        >
                          ID number
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={8}>
                        <Typography className="details-text-bold">
                          {organizationData?.id}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container gap={"8px 0px"} mb="21px">
                      <Grid item xs={12} md={4}>
                        <Typography
                          fontSize="18px"
                          fontWeight="500"
                          color="#333132"
                        >
                          Organization name
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={8}>
                        <Typography className="details-text-bold">
                          {organizationData?.localized_data? organizationData?.localized_data[`${supportLang}`]['name'] : organizationData?.name}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container gap={"8px 0px"} mb="21px">
                      <Grid item xs={12} md={4}>
                        <Typography
                          fontSize="18px"
                          fontWeight="500"
                          color="#333132"
                        >
                          Website
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={8}>
                        <Typography className="details-text-bold">
                          {organizationData?.website}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Stack>

            <Stack bgcolor="rgba(0, 0, 0, 0.01)" borderRadius="10px" p={2}>
              <FormHead text={"Description"} />
              <Typography fontSize="18px" fontWeight="500" color="#333132">
                {organizationData?.description}
              </Typography>
            </Stack>

            <Stack
              flexDirection="row"
              gap="1rem 3rem"
              padding="1rem 0rem 2rem"
              className="details-time details-time-lg"
            >
              <Stack flexDirection="row" gap={1}>
                <Typography color="#333132" className="details-text-bold"> {`Created at : ${created}`} </Typography>            
              </Stack>
              <Stack flexDirection="row" gap={1}>
                <Typography color="#333132" className="details-text-bold"> {`Updated at : ${updated}`} </Typography>
              </Stack>

            </Stack>
          </Stack>

          <Stack gap={2} width="30%">
            <Stack
              flexDirection="row"
              gap="1rem 3rem"
              padding="1rem 0rem 2rem"
              className="details-time details-time-sm"
            >
              {/* <Stack flexDirection="row" gap={1}>
                <Typography color="#333132" className="details-text-bold">
                  Created at :{" "}
                </Typography>
                {created != "Invalid Date" ? (
                  <Typography
                    color="#333132"
                    className="details-text-bold"
                  >{`${created?.getFullYear()}-${handleDigits(
                    created?.getMonth() + 1
                  )}-${handleDigits(created?.getDate() + 1)} ${
                    "  " + handleDigits(created?.getHours() + 1)
                  }:${handleDigits(created?.getMinutes() + 1)}`}</Typography>
                ) : null}
              </Stack>
              <Stack flexDirection="row" gap={1}>
                <Typography color="#333132" className="details-text-bold">
                  Updated at :{" "}
                </Typography>
                {updated != "Invalid Date" ? (
                  <Typography
                    color="#333132"
                    className="details-text-bold"
                  >{`${updated?.getFullYear()}-${handleDigits(
                    updated?.getMonth() + 1
                  )}-${handleDigits(updated?.getDate() + 1)} ${
                    "  " + handleDigits(updated?.getHours() + 1)
                  }:${handleDigits(updated?.getMinutes() + 1)}`}</Typography>
                ) : null}
              </Stack> */}
              <Stack flexDirection="row" gap={1}>
                <Typography color="#333132" className="details-text-bold"> {`Created at : ${created}`} </Typography>            
              </Stack>
              <Stack flexDirection="row" gap={1}>
                <Typography color="#333132" className="details-text-bold"> {`Updated at : ${updated}`} </Typography>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
    </Stack>
  );
}

export default OrganizationDetails;

import { Grid } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import PagesHead from '../../../Molecules/Users/PagesHead';
import SearchIcon from '@mui/icons-material/Search';
import RolesList from '../../../Molecules/Users/Roles/RolesList/RolesList';
import Loader from '../../../Atoms/Loader/Loader';

function Roles(props) {
    const { filterObj, setFilterObj, rolesData, setRolesData, openLoader, setOpenLoader, count, page, handlePageChange, usersPrivilages } = props;
    const navigate = useNavigate();
    const userType = localStorage.getItem("userType")

    return (
        <>
            <Loader open={openLoader} />
            <Grid container>
                <PagesHead
                    title={"Roles"}
                    searchInput={{
                        type: "search",
                        placeholder: "Search-roles",
                        StartAdornment: { SearchIcon },
                        className: "Search-roles",
                    }}
                    primaryButtons={(userType !== 'descendant_user') ||
                        (userType === 'descendant_user' && usersPrivilages?.can_read && usersPrivilages?.can_create) ? [
                        {
                            fontSize: '14px',
                            startIcon: true,
                            text: "Add New Role",
                            classBtn: "primary r-25px",
                            style: { padding: "5px 20px", height: "48px" },
                            click: () => navigate('/users/roles/add')
                        }
                    ] : []}
                    filterObj={filterObj}
                    setFilterObj={setFilterObj}
                />
                <RolesList rolesData={rolesData}
                    setRolesData={setRolesData} setOpenLoader={setOpenLoader}
                    count={count} page={page}
                    handlePageChange={handlePageChange}
                    usersPrivilages={usersPrivilages}
                />
            </Grid>
        </>
    )
}

export default Roles
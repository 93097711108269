import * as Yup from "yup";
export const initialValues = (data, lang) => ({
  ...data,
});

export const structure = (lang) => [
  {
    head: "Document  information",
    list: [
      {
        name: `name`,
        kind: "input",
        type: "text",
        label: "Clients terms of use",
        width: "60%",
        disabled: true,
      },
      {
        name: `content`,
        kind: "editorInput",
        type: "text",
        label: "Document content",
        width: "100%",
      },
    ],
  },
];

export const validationSchema = () =>
  Yup.object({
    content: Yup.string().required("required"),
  });

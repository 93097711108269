import React from 'react'
import { Stack, Typography } from '@mui/material'
import './JobOfferDetailsHeader.css';
import PrimaryButton from '../../../Atoms/Buttons/Primary/PrimaryButton';
import { useNavigate } from 'react-router';

function JobOfferDetailsHeader({data, supportLang, roles}) {
    const navigate = useNavigate();
  return (
    <Stack alignItems='center' bgcolor='white' boxShadow='0 2px 15px 0 rgba(0, 0, 0, 0.1)' 
        borderRadius='10px' padding={2} flexDirection='row' justifyContent='space-between'>
        <Stack flexDirection='row' gap={2} width='100%'>            
            <Stack flexDirection='row' justifyContent='space-between' width='100%' className='details-header-content'>
                <Stack flexDirection='column' justifyContent='space-evenly'>
                    <Stack flexDirection='row' gap={2} alignItems='center'>
                        <Stack flexDirection='row' gap={'50px'} alignItems='center'>
                            <Typography fontSize='30px' fontWeight='bold' color='#333132' textTransform='capitalize'>{data?.localized_data? data?.localized_data[`${supportLang}`]['title'] : data?.title} - {data?.dive_expert_professional_level?.replaceAll('_', ' ')}</Typography>
                        </Stack>
                    </Stack>

                    <Stack flexDirection='row' alignItems='center' gap='5px'>
                        <Typography fontSize='20px' fontWeight='bold' color='#333132' textTransform='capitalize'>{
                            `${data?.activity_type === 'live_aboard' ? 'liveaboard' : data?.activity_type?.replaceAll('_', ' ')} , ${data?.job_type?.replaceAll('_', ' ')} , ${data?.location?.area?.name?.replaceAll('_', ' ')} ,`
                        }</Typography>
                        <Typography fontSize='20px' fontWeight='bold' color='#f6b119' textTransform='capitalize'> {`${data?.rate} ${data?.currency?.lookup_key}/day`}</Typography>
                    </Stack>
                </Stack>
                <Stack flexDirection='row' gap={2}>
                    <Stack flexDirection='row' py='4px' gap={'32px'} justifyContent='space-between' alignItems="center" flexWrap="wrap">
                        <Stack flexDirection='column' gap={'4px'} alignItems='left'>
                            {
                                (data?.status === 'hired' || data?.status === 'qr_scanned' || data?.status === 'completed') 
                                    ? <><Stack flexDirection='row' py='4px' gap='5px' justifyContent='space-between'>
                                        <Typography fontSize='20px' fontWeight='bold' color='#333132'>Job Status : </Typography>
                                        <Typography sx={{fontSize: '20px', fontWeight: 'bold', textTransform: 'capitalize', color: `${
                                            (data?.status === 'hired' || data?.status === 'qr_scanned' || data?.status === 'completed') ? '#00b906'
                                            : data?.status === 'pending' ? '#f6b119'
                                            : data?.status === 'deleted' ? '#e10007'
                                            : '#333132'
                                            }`, display: 'flex', flexDirection: 'column'}}
                                        >
                                            { data?.status === 'qr_scanned' ? 'Scanned QR' : data?.status }
                                        </Typography>
                                    </Stack>
                                        <Typography fontSize='20px' fontWeight='bold' color='#333132'>
                                            {`${data?.accepted_job_request?.dive_expert?.firstname} ${data?.accepted_job_request?.dive_expert?.lastname}`}
                                        </Typography>
                                    </>
                                    :<Stack flexDirection='row' py='4px' justifyContent='space-between'>
                                        <Typography fontSize='20px' fontWeight='bold' color='#333132'>Job Status : </Typography>
                                        <Typography sx={{fontSize: '20px', fontWeight: 'bold', textTransform: 'capitalize', color: `${
                                            (data?.status === 'hired' || data?.status === 'qr_scanned' || data?.status === 'completed') ? '#00b906'
                                            : data?.status === 'pending' ? '#f6b119'
                                            : data?.status === 'deleted' ? '#e10007'
                                            : '#333132'
                                            }`, display: 'flex', flexDirection: 'column'}}
                                        >
                                            { data?.status === 'qr_scanned' ? 'Scanned QR' : data?.status }
                                        </Typography>
                                    </Stack>
                            }
                        </Stack>
                        <PrimaryButton 
                            text={`${data?.number_of_applicants} applicants`}
                            classBtn={'navigate-button'}
                            click={() => 
                                data?.number_of_applicants > 0 && roles?.can_read ? navigate('/operators/job-offers/' + data?.id + '/applicants') : null
                            }
                        />
                    </Stack>
                </Stack>
            </Stack>
        </Stack>
    </Stack>

  )
}

export default JobOfferDetailsHeader
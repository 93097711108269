import SearchIcon from "@mui/icons-material/Search";
import { Grid } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { icons } from "../../../Assets/AssetHelper.js";
import CustomSeparator from "../../Atoms/BreadCrumb/index.jsx";
import Loader from "../../Atoms/Loader/Loader";
import LogHistoryPageHead from "../../Molecules/Users/LogHistoryPageHead.jsx";
import LogHistoryList from "../../Molecules/Users/UsersList/LogHistoryList.jsx";

const LogHistory = (props) => {
  const {
    filterObj,
    setFilterObj,
    logHistoryList,
    setLogHistoryList,
    openLoader,
    setOpenLoader,
    count,
    page,
    handlePageChange,
    usersPrivilages,
    rolesPrivilages,
    handleApplyFilterMenu,
    handleResetFilterMenu,
    filterList,
    getCSV
  } = props;
  const navigate = useNavigate();
  const userType = localStorage.getItem("userType");
  const breadcrumbs = [
    { path: "/users", active: true, title: "Users" },

    {
      active: false,
      title: "Log history",
    },
  ];
  const primaryButtonsArray = [];

  if (
    userType !== "descendant_user" ||
    (userType === "descendant_user" &&
      usersPrivilages?.can_read &&
      rolesPrivilages?.can_read)
  ) {
    primaryButtonsArray.push({
      fontSize: "14px",
      text: "Extract login time (CSV)",
      classBtn: "primary r-25px primary-outlined",
      style: {
        padding: "5px 20px",
        height: "48px",
        marginRight: "1rem",
      },
      click:getCSV,
      startIcon:icons.Extract
    });
  }

  return (
    <>
      <Loader open={openLoader} />
      <Grid container>
        <Grid container item sm={12}>
          <CustomSeparator breadcrumbs={breadcrumbs} />
        </Grid>
        
        <LogHistoryPageHead
          title="Log history"
          searchInput={{
            type: "search",
            placeholder: "Search-users",
            StartAdornment: { SearchIcon },
            className: "Search-users",
          }}
          primaryButtons={primaryButtonsArray}
          filterList={filterList}
          filterObj={filterObj}
          setFilterObj={setFilterObj}
          handleApplyFilterMenu={handleApplyFilterMenu}
          getCSV={getCSV}
        />
        <LogHistoryList
          logHistoryList={logHistoryList}
          setLogHistoryList={setLogHistoryList}
          setOpenLoader={setOpenLoader}
          count={count}
          page={page}
          handlePageChange={handlePageChange}
          usersPrivilages={usersPrivilages}
        />
      </Grid>
    </>
  );
};

export default LogHistory;

import React, { useEffect, useState } from "react";
import BranchesReviewsListTemplate from "../../../Templates/Main/Branches/BranchesReviewsList.Template";
import {
  getBranchDetails,
  getBranchReviews,
} from "../../../Contexts/APIs/Branches/Branches";
import { useParams, useSearchParams } from "react-router-dom";

const BranchesReviewsListPage = () => {
  const [list, setList] = useState([]);
  const [count, setCount] = useState(0);
  const { branchId } = useParams();
  const [branchData, setBranchData] = useState({});
  const [reviewsDetails, setReviewsDetails] = useState({});
  const [page, setPage] = useState(1);

  const handleChange = (key, value) => {
    setPage(value);
  };
  const [serachParams] = useSearchParams();
  const getReviewsList = async () => {
    const { res, err } = await getBranchReviews(
      { page_number: page, page_size: 10 },
      {
        review: {
          reviewee_id: branchId,
          reviewee_type: "Branch",
          reviewer_type: serachParams.get("type"),
        },
      }
    );
    if (res) {
      setList(res.data?.reviews);
      setReviewsDetails(res?.data?.reviews_details);
      setCount(res?.extra?.total_count);
    }
  };
  useEffect(() => {
    getReviewsList();
  }, [page, serachParams.get("type")]);
  const getBranch = async (id) => {
    const { res, err } = await getBranchDetails(id);
    if (res) {
      setBranchData(res?.data?.branch);
    }
  };

  useEffect(() => {
    branchId && getBranch(branchId);
  }, [branchId]);
  return (
    <BranchesReviewsListTemplate
      count={count}
      reviewsList={list}
      reviewsDetails={reviewsDetails}
      data={branchData}
      handleChange={handleChange}
      page={page}
    />
  );
};

export default BranchesReviewsListPage;

import { useState } from "react";
import { PropTypes } from "prop-types";
import { TextField, InputAdornment, IconButton } from "@mui/material";
import "./TextFieldInput.css";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Grid } from "@mui/material";
function TextFieldInput({
  type = "text",
  placeholder = "",
  StartAdornment = null,
  EndAdornment = null,
  multiline = false,
  rows,
  className,
  onChange,
  error,
  value,
  labelStyle,
  label,
  clickEnter,
  InputProps,
  disabled,
  justifyContent,
  helperText,
  gridClass,
  style = { width: '100%' },
  defaultValue,
  ...props
}) {
  const [showPassword, setshowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setshowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  }

  return (
    <Grid container sx={{ position: "relative", width: 'fit-content', ...style }} justifyContent={justifyContent} className={gridClass}>
      {label && (
        <span className={labelStyle}>{label}</span>
      )}
      <TextField
        defaultValue={defaultValue}
        disabled={disabled}
        fullWidth
        multiline={multiline}
        rows={rows}
        type={type === "password" ? (showPassword ? "text" : "password") : type}
        className={className}
        error={error ? true : false}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onReset={()=>console.log('first')}
        onKeyDown={(e) => {
          if (e.key === "Enter") {clickEnter && clickEnter();}
          // to prevent typing character e on input type number
          if(type === "number" && ['e', 'E'].includes(e.key)){ e.preventDefault() }
        }}
        helperText={helperText}
        InputProps={InputProps ? InputProps : {
          startAdornment: (
            <InputAdornment position="start" style={{ height: "auto" }}>
              <>{StartAdornment}</>
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end" style={{ height: "auto" }}>
              {(type === "password" && EndAdornment !== false) ? (
                <>
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <Visibility className="password-icon" /> : <VisibilityOff className="password-icon" />}
                  </IconButton>
                </>
              ) : (
                EndAdornment
              )}
            </InputAdornment>
          ),
        }}
        {...props}
      />
    </Grid>
  );
}

TextFieldInput.propTypes = {
  type: PropTypes.string,
  placeholder: PropTypes.string,
  StartAdornment: PropTypes.element,
  EndAdornment: PropTypes.element,
  className: PropTypes.string,
};
export default TextFieldInput;

import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
} from "@mui/material";
import React from "react";
import Select from "@mui/material/Select";
import "./Select.css";

function SelectBox({
  items,
  title,
  value,
  onchange,
  id,
  name,
  error,
  helperText,
  labelStyle,
  defaultValue,
  style,
  disabled,
  className,
  placeholder,
  icon,
  onclick = () => {},
  selectBoxStyle,
}) {
  // const [value, setValue] = React.useState("");
  // const handleChange = (event) => {
  //   setValue(event.target.value);
  // };
  return (
    <FormControl
      disabled={disabled}
      fullWidth
      style={{ ...style, color: "red" }}
      error={error ? true : false}
    >
      <InputLabel style={{ color: "gray", fontSize: "18px" }}>
        {title}
      </InputLabel>

      <Select
        labelId="demo-simple-select-label"
        defaultValue={defaultValue}
        placeholder={placeholder}
        id={id}
        name={name}
        className={className}
        defaultChecked=""
        style={{ color: "black" }}
        value={value}
        label={title}
        onChange={onchange}
        IconComponent={icon && icon}
        sx={{
          "& .MuiSelect-select": {
            ...selectBoxStyle,
          },
        }}
      >
        <MenuItem value="" disabled>
          {title}
        </MenuItem>
        {items?.map((item) => (
          <MenuItem
            value={item.id}
            key={item.id}
            onClick={(e) => onclick(e, item)}
            style={{ color: item.color }}
          >
            {item.icon ? (
              <img
                style={{ margin: "0 10px" }}
                src={item.icon}
                alt="item icon"
              />
            ) : (
              ""
            )}{" "}
            {item.name}
          </MenuItem>
        ))}
      </Select>
      {error ? <FormHelperText>{helperText}</FormHelperText> : ""}
    </FormControl>
  );
}

export default SelectBox;

import React, { useCallback, useEffect, useState } from "react";
import ApplicantsTemplate from "../../../Templates/Main/JobOffers/Applicants.template";
import {
  getApplicantsList,
  getJobOfferDetails,
} from "../../../Contexts/APIs/JobOffers/JobOffers";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import Loader from "../../../Components/Atoms/Loader/Loader";
import usePageFilter from "../../../Components/Atoms/Hooks/usePageFilter";

function ApplicantsPage({ roles }) {
  const { jobOfferId } = useParams();
  const [count, setCount] = useState();
  const [openLoader, setOpenLoader] = useState(false);
  const [applicants, setApplicants] = useState([]);
  const [jobOfferData, setJobOfferData] = useState({});
  const { page, filterObj, handlePageChange, setPage } = usePageFilter();

  const getApplicants = async (id) => {
    setOpenLoader(true);
    const { res, err } = await getJobOfferDetails(id);
    if (res) {
      setCount(res?.data?.job_offer?.job_requests?.length);
      setApplicants(
        res?.data?.job_offer?.job_requests.filter(
          (item, index) =>
            index >= 0 + (page - 1) * 10 && index <= 9 + (page - 1) * 10
        )
      );
      setJobOfferData(res?.data?.job_offer);
    }
    if (err) toast.error(err);
    setOpenLoader(false);
  };

  let flag = true;
  useEffect(() => {
    if (jobOfferId && jobOfferId) {
      flag && getApplicants(jobOfferId);
      flag = false;
    }
  }, [jobOfferId, flag]);

  return (
    <>
      <Loader open={openLoader} />
      <ApplicantsTemplate
        filterObj={filterObj}
        applicantsList={applicants}
        setApplicants={setApplicants}
        count={count}
        setCount={setCount}
        page={page}
        setPage={setPage}
        handlePageChange={handlePageChange}
        jobOfferData={jobOfferData}
        openLoader={openLoader}
        setOpenLoader={setOpenLoader}
        roles={roles}
      />
    </>
  );
}

export default ApplicantsPage;

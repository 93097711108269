import React from "react";
import { Grid } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import OperatorsList from "../../Molecules/Operators/OperatorsList/OperatorsList";
import PagesHead from "../../Molecules/General/PagesHead/PagesHead";
import Loader from "../../Atoms/Loader/Loader";
import { useNavigate } from "react-router-dom";
import { handlePageChange } from "../../../Contexts/APIs/Helper/Helper";

function Operators(props) {
  const {
    filterObj,
    setFilterObj,
    operatorsList,
    openLoader,
    count,
    page,
    setPage,
    handleChange,
    getOperators,
    roles,
    salesman_roles,
    setOperatorsList,
  } = props;

  const navigate = useNavigate();
  const AccountStatusMenu = ["All", "active", "suspended", "deactivated"],
    ApprovalStatusMenu = [
      "All",
      "approved",
      "pending",
      "incompleted",
      "rejected",
    ];

  const handleAccountStatusFilter = (value) => {
    setFilterObj({ ...filterObj, page_number: 1, status: value });
    setPage(1);
  };
  const handleApprovalStatusFilter = (value) => {
    handleChange && handlePageChange(1, handleChange);
    setFilterObj({
      ...filterObj,
      page_number: 1,
      profile_status:
        value === "incompleted"
          ? "incomplete"
          : value === "pending"
          ? "pending_approval"
          : value,
    });
    setPage(1);
  };

  const primaryButtonsArray = [];

  if (roles?.can_read && roles?.can_create) {
    primaryButtonsArray.push({
      fontSize: "14px",
      startIcon: true,
      text: "Add New operator",
      classBtn: "primary r-25px",
      style: { padding: "5px 20px", height: "48px" },
      click: () => navigate("/operators/add"),
    });
  }

  return (
    <>
      <Loader open={openLoader} />
      <Grid container className="operators-container">
        <PagesHead
          title="Operators"
          searchInput={{
            type: "search",
            placeholder: "Search Dive center by name or email",
            StartAdornment: { SearchIcon },
            className: "Search-dive-center",
          }}
          primaryButtons={primaryButtonsArray}
          filterObj={filterObj}
          setFilterObj={setFilterObj}
          filterMenus={[
            {
              head: "Account status",
              dataList: AccountStatusMenu,
              handleFilter: handleAccountStatusFilter,
            },
            {
              head: "Approval status",
              dataList: ApprovalStatusMenu,
              handleFilter: handleApprovalStatusFilter,
            },
          ]}
          setPage={setPage}
          handleChange={handleChange}
        />
        <OperatorsList
          operatorsList={operatorsList}
          count={count}
          page={page}
          setPage={setPage}
          handlePageChange={handleChange}
          getOperators={getOperators}
          filterObj={filterObj}
          roles={roles}
          salesman_roles={salesman_roles}
          setOperatorsList={setOperatorsList}
        />
      </Grid>
    </>
  );
}

export default Operators;

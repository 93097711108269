import React from 'react'
import { Stack, Typography } from '@mui/material'
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { useNavigate } from 'react-router-dom';

function ActivityDetailsHeader({data, supportLang}) {
    const navigate = useNavigate();
  return (
    <Stack alignItems='center' bgcolor='white' boxShadow='0 2px 15px 0 rgba(0, 0, 0, 0.1)' 
        borderRadius='10px' padding={2} flexDirection='row' justifyContent='space-between'>
        <Stack flexDirection='row' gap={2} width='100%'>
            <Stack flexDirection='row' justifyContent='space-between' width='100%' className='details-header-content'>
                <Stack flexDirection='column' justifyContent='space-evenly'>
                    <Stack flexDirection='row' gap={2} alignItems='center'>
                        <Stack flexDirection='row' gap={'30px'} alignItems='center' style={{ cursor: 'pointer' }}
                            onClick={() => navigate(`/operators/activities/${data?.id}/reviews`)}>
                            <Typography fontSize='30px' fontWeight='bold' color='#333132' textTransform='capitalize'>
                                {data?.localized_data? data?.localized_data[`${supportLang}`]['name'] : data?.name}
                            </Typography>                                                        
                            <Typography fontSize='18px' color='#bebebe' textTransform='capitalize'>{
                                data?.activity_type === 'live_aboard' ? 'liveaboard' : data?.activity_type?.replace('_', ' ')
                            }</Typography>
                            <Stack flexDirection='row' gap={'5px'} alignItems='center'>
                                <Typography fontSize='20px' fontWeight='600' color='#3ed181'>
                                    Rating and review
                                </Typography>
                                <Typography fontSize='20px' fontWeight='600' color='#3ed181'> {data?.reviews_count} </Typography>
                                <Typography fontSize='20px' fontWeight='600' color='#3ed181'>
                                    {data?.reviews_count === 1 ? ' review' : ' reviews'}
                                </Typography>
                            </Stack>
                        </Stack>
                    </Stack>
                    <Stack flexDirection='row' gap={'50px'} alignItems='center'>
                        <Typography fontSize='20px' fontWeight='500' color='#333132'>Branch ID : {data?.branch?.id}</Typography>
                        <Typography fontSize='20px' fontWeight='bold' color='#f6b119'>{`${data?.price} ${data?.currency?.lookup_key}`}</Typography>
                        <Stack flexDirection='row' gap={'4px'} alignItems='center'>
                            <Typography fontSize='14px' fontWeight='500' color='#848383'>
                                <AccessTimeIcon fontSize="inherit" style={{ fontSize: '15px', color: '#848383', position: 'relative', top: '3px', marginRight: '8px' }} />
                                {data?.duration_days} days</Typography>                            
                            <Typography fontSize='30px' fontWeight='500' color='#848383' lineHeight='0.8' pb='7px' m='0px 5px'>.</Typography>
                            <Typography fontSize='14px' fontWeight='500' color='#848383'>{data?.no_of_dives} dives</Typography>
                            <Typography fontSize='30px' fontWeight='500' color='#848383' lineHeight='0.8' pb='7px' m='0px 5px'>.</Typography>
                            <Typography fontSize='14px' fontWeight='500' color='#848383'>{data?.reservations_count} reservitions</Typography>
                        </Stack>
                    </Stack>
                </Stack>
                <Stack flexDirection='row' gap={2}>
                    <Typography fontSize='16px' fontWeight='bold' color='#333132' display='block' textTransform='capitalize'>Status : {data?.is_available ? 'active' : 'deactive'}</Typography>
                </Stack>
            </Stack>
        </Stack>
    </Stack>
  )
}

export default ActivityDetailsHeader